import { API_DATA, ERROR_MSG, FIELD_LABELS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { getLanguageOptions, getOptionByValue, getTestLanguagesString, isPotentials } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import CustomInput from '../customInput/CustomInput'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'

const AssessmentAdvancedOptionsTile = ({ assessment, canEdit }) => {
  const t = useTranslate()
  const configType = assessment.relatedConfig.configType
  const availableLanguages = assessment.relatedConfig.availableLanguages
  const languageOptions = t(getLanguageOptions(availableLanguages, assessment))
  const testLanguagesString = getTestLanguagesString(languageOptions, assessment.languageIds)

  return (
    <Tile width="200px">
      <TileHeadline content="additionalOptions" />
      <CustomInputList>
        <CustomInput
          label={FIELD_LABELS.inHub}
          content={getOptionByValue(SELECT_OPTIONS.inHub, assessment.inHub).label || ERROR_MSG}
          value={assessment.inHub}
          dataKey="inHub"
          type="select"
          options={SELECT_OPTIONS.inHub}
          apiData={API_DATA.assessments}
          idValue={assessment.assessmentUuid}
          disabled={!canEdit}
        />

        {isPotentials(configType) && (
          <CustomInput
            label={FIELD_LABELS.isSupervised}
            content={getOptionByValue(SELECT_OPTIONS.isSupervised, assessment.isSupervised).label || ERROR_MSG}
            value={assessment.isSupervised}
            dataKey="isSupervised"
            type="select"
            options={SELECT_OPTIONS.isSupervised}
            idValue={assessment.assessmentUuid}
            apiData={API_DATA.assessments}
            disabled={!canEdit}
          />
        )}

        <CustomInput
          label={FIELD_LABELS.testLanguages}
          content={testLanguagesString || ERROR_MSG}
          value={assessment.languageIds}
          dataKey="languageIds"
          type="languageSelect"
          options={languageOptions}
          apiData={API_DATA.assessments}
          idValue={assessment.assessmentUuid}
          disabled={!canEdit}
        />
      </CustomInputList>
    </Tile>
  )
}

export default AssessmentAdvancedOptionsTile
