const filterTypes = {
  exact: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
          return rowValue.some((val) => isEqual(val, filterValue))
        } else {
          return isEqual(rowValue, filterValue)
        }
      })
    })
  },
  exactNot: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
          return rowValue.every((val) => !isEqual(val, filterValue))
        } else {
          return !isEqual(rowValue, filterValue)
        }
      })
    })
  },
  like: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return isLike(rowValue, filterValue)
      })
    })
  },
  notLike: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return !isLike(rowValue, filterValue)
      })
    })
  },
  isInDateRange: (rows, ids, filterValue) => {
    const { from, until } = filterValue

    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return rowValue > from && rowValue <= until
      })
    })
  },
  isNotInDateRange: (rows, ids, filterValue) => {
    const { from, until } = filterValue

    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return !(rowValue > from) || !(rowValue <= until)
      })
    })
  },
  laterThan: (rows, ids, filterValue) => {
    const { until } = filterValue

    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return rowValue > until
      })
    })
  },
  before: (rows, ids, filterValue) => {
    const { from } = filterValue

    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return rowValue < from
      })
    })
  },
  betweenDates: (rows, ids, filterValue) => {
    const { from } = filterValue[0]
    const { until } = filterValue[1]

    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return (rowValue >= from && rowValue <= until) || (rowValue >= until && rowValue <= from)
      })
    })
  },
  gt: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
          return rowValue.some((val) => isGreaterThan(val, filterValue) && !isEmpty(val))
        } else {
          return isGreaterThan(rowValue, filterValue) && !isEmpty(rowValue)
        }
      })
    })
  },
  gte: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
          return rowValue.some((val) => isGreaterOrEqual(val, filterValue) && !isEmpty(val))
        } else {
          return isGreaterOrEqual(rowValue, filterValue) && !isEmpty(rowValue)
        }
      })
    })
  },
  lt: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
          return rowValue.some((val) => isSmallerThan(val, filterValue) && !isEmpty(val))
        } else {
          return isSmallerThan(rowValue, filterValue) && !isEmpty(rowValue)
        }
      })
    })
  },
  lte: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
          return rowValue.some((val) => isSmallerOrEqual(val, filterValue) && !isEmpty(val))
        } else {
          return isSmallerOrEqual(rowValue, filterValue) && !isEmpty(rowValue)
        }
      })
    })
  },
  betweenNumbers: (rows, ids, filterValue) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
          return rowValue.some((val) => isBetween(val, filterValue) && !isEmpty(val))
        } else {
          return isBetween(rowValue, filterValue) && !isEmpty(rowValue)
        }
      })
    })
  },
  empty: (rows, ids) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return isEmpty(rowValue)
      })
    })
  },
  notEmpty: (rows, ids) => {
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return !isEmpty(rowValue)
      })
    })
  },
  includes: (rows, ids, filterValues) => {
    const valuesArray = makeArray(filterValues)
    return rows.filter((row) => {
      return ids.some((id) => {
        const rowValue = row.values[id]
        return valuesArray.includes(rowValue)
      })
    })
  }
}

export default filterTypes

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0 || value.every((val) => val === null || val === undefined || val === '')
  }
  return value === null || value === undefined || value === ''
}
const isSmallerThan = (rowValue, filterValue) => Number(rowValue) < Number(filterValue)
const isGreaterThan = (rowValue, filterValue) => Number(rowValue) > Number(filterValue)
const isSmallerOrEqual = (rowValue, filterValue) => !isGreaterThan(rowValue, filterValue)
const isGreaterOrEqual = (rowValue, filterValue) => !isSmallerThan(rowValue, filterValue)
const isEqual = (rowValue, filterValue) => String(rowValue).toLowerCase() === String(filterValue).toLowerCase()

const isLike = (rowValue, filterValue) => {
  const wordList = String(filterValue).toLowerCase().split(' ')
  const stringValue = String(rowValue).toLowerCase()
  return wordList.every((word) => stringValue.includes(word))
}

const isBetween = (rowValue, filterValue) => {
  return Number(rowValue) >= Number(filterValue[0]) && Number(rowValue) <= Number(filterValue[1])
}

const makeArray = (input) => (Array.isArray(input) ? input : [input])
