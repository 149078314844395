import AssessmentSelection from '../components/assessmentSelection/AssessmentSelection'
import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import Select from 'react-select'
import { getProcessSelectOptions, setState } from '../utils/helper/Helper'
import {
  Label,
  OptionDescription,
  OptionEntry,
  PageHeadline,
  WizardForm,
  selectStyles
} from '../utils/elements/miscElements'
import { useContext } from 'react'
import { AppContext } from '../utils/context/AppContext'
import useTranslate from '../utils/hooks/useTranslate'

const SelectProcessAndAssessmentsPage = ({ data, setData, selectedParticipants, optionDescriptions }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const { pts, processes } = context.completeDataSet

  const handleProcessChange = (selectedOption) => {
    selectAssessments(selectedOption)
    setState(setData, 'processUuid', selectedOption.uuid)
  }

  const selectAssessments = (selectedOption) => {
    const assessmentList =
      processes.find((process) => process.processUuid === selectedOption.uuid)?.relatedAssessments || []
    if (assessmentList.length === 1 && !assessmentList[0].cannotAddToTest) {
      setState(setData, 'assessmentUuids', [assessmentList[0].assessmentUuid])
    } else {
      setState(setData, 'assessmentUuids', [])
    }
  }

  const processSelectOptions = getProcessSelectOptions({ processList: processes, t: t })
  const assessmentList = getAssessmentList(processes, data, pts, selectedParticipants)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <>
      <PageHeadline>{t('processesAndTests')}</PageHeadline>
      <ScrollContainer>
        <WizardForm>
          <OptionEntry hasHint>
            <Label htmlFor="process" fullWidth>
              {t('processes')} *
            </Label>
            <Select
              menuPosition="fixed"
              options={processSelectOptions}
              id="process"
              onChange={handleProcessChange}
              value={processSelectOptions.find((option) => option.uuid === data.processUuid)}
              noOptionsMessage={() => t('noProcesses')}
              placeholder={t('selectProcess')}
              styles={selectStyles}
              isOptionDisabled={(option) => option.disabled}
              onKeyDown={handleKeyDown}
            />
            <OptionDescription description={optionDescriptions.process} />
          </OptionEntry>
          {assessmentList.length > 0 && (
            <OptionEntry hasHint>
              <Label fullWidth>Tests *</Label>
              <AssessmentSelection
                assessmentList={assessmentList}
                selectedAssessments={data.assessmentUuids}
                setData={setData}
              />
              <OptionDescription description={optionDescriptions.assessments} />
            </OptionEntry>
          )}
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default SelectProcessAndAssessmentsPage

const getAssessmentList = (processes, data, pts, selectedParticipants) => {
  const assessmentList = processes.find((process) => process.processUuid === data.processUuid)?.relatedAssessments || []

  return assessmentList.map((ass) => {
    const relatedPts = pts.filter((pt) => pt.assessmentUuid === ass.assessmentUuid)
    const relatedParticipantNumbers = relatedPts.map((pt) => pt.pNr)

    if (selectedParticipants) {
      const hasOverlap = selectedParticipants.some((selectedParticipant) => {
        return relatedParticipantNumbers.includes(selectedParticipant.pNr)
      })
      ass.cannotAddToTest = hasOverlap ? true : false
    } else {
      ass.cannotAddToTest = false
    }

    return Object.assign(ass, { relatedPts }, { relatedParticipantNumbers })
  })
}
