import ProcessSettingsTab from '../../components/tabs/ProcessSettingsTab'
import TabGroup from '../../components/tabGroup/TabGroup'
import ActionsTab from '../../components/tabs/ActionsTab'
import { ENTITIES } from '../../utils/constants/constants'

const ProcessRowSubComponent = (row) => (
  <TabGroup
    tabs={[
      {
        label: 'settings',
        id: 'processSettings',
        content: <ProcessSettingsTab process={row.original} />
      },
      {
        label: 'actions',
        id: 'actions',
        content: <ActionsTab row={row.original} entity={ENTITIES.processes} />
      }
    ]}
  />
)

export default ProcessRowSubComponent
