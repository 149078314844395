import { STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { fetchData, setStatusBar } from '../../../utils/helper/Helper'

export const createStripePaymentIntent = async (orderData, context) => {
  setStatusBar({
    controller: context.statusBarController,
    type: STATUS_BAR_TYPES.loading,
    text: 'paymentInProgress',
    setVisible: true
  })

  const payload = {
    numberCredits: orderData.numberCredits,
    payMethodId: orderData.stripePayMethodId
  }

  const responseData = await fetchData(payload, 'create_payment_intent', context, 'errorOccured', false)

  try {
    return responseData.response.clientSecret
  } catch (e) {
    console.error(e)
  }
}

export const getStripeOptions = (language) => ({
  mode: 'payment',
  amount: 100, // required by stripe API, has no effect
  captureMethod: 'automatic',
  currency: 'eur',
  paymentMethodCreation: 'manual',
  locale: language,
  appearance: getAppearance(),
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans' }]
})

const getAppearance = () => {
  const colors = getColors()
  return {
    theme: 'stripe',
    variables: {
      fontFamily: 'Open Sans',
      colorTextPlaceholder: '#00000000' // hide placeholder
    },
    rules: {
      '.Input:focus': {
        border: `1px solid ${colors.colorFocusOutline}`,
        boxShadow: 'none'
      },
      '.Input': {
        transition: 'none',
        boxShadow: 'none',
        border: `1px solid ${colors.borderGrey}`,
        padding: '10px',
        backgroundColor: colors.inputBgColor,
        color: colors.textColorPrimary
      },
      '.Label': {
        color: colors.textColorSecondary,
        fontSize: '14px'
      },
      '.Error': {
        color: colors.errorMessageColor,
        fontSize: '12px'
      },
      '.Input--invalid': {
        border: `1px solid ${colors.errorMessageColor}`,
        backgroundColor: colors.inputErrorBgColor,
        boxShadow: 'none',
        color: colors.errorMessageColor
      },
      '.Tab': {
        backgroundColor: colors.inputBgColor,
        border: `1px solid ${colors.borderGrey}`
      }
    }
  }
}

const getColors = () => {
  const isDark = document.body.classList.contains('dark')
  const element = isDark ? document.querySelector('.dark') : document.body

  const getComputedStyleValue = (property) => getComputedStyle(element).getPropertyValue(property).trim()

  return {
    inputBgColor: getComputedStyleValue('--input-bg-color'),
    colorFocusOutline: getComputedStyleValue('--color-focus-outline'),
    textColorPrimary: getComputedStyleValue('--text-color-primary'),
    textColorSecondary: getComputedStyleValue('--text-color-secondary'),
    borderGrey: getComputedStyleValue('--border-grey'),
    errorMessageColor: getComputedStyleValue('--error-message-color'),
    inputErrorBgColor: getComputedStyleValue('--input-error-bg-color')
  }
}

export const createPaymentMethod = async (stripe, elements, context) => {
  if (!stripe || !elements) {
    return Promise.reject(new Error('Stripe or elements not available'))
  }

  try {
    const { error: submitError } = await elements.submit()

    if (submitError) {
      return Promise.reject(submitError)
    }

    const payMethod = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: context.completeDataSet.billing.billingCustomer
        }
      }
    })

    return payMethod
  } catch (error) {
    return Promise.reject(error)
  }
}
