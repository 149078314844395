import React from 'react'
import { Tile, TileContainer } from '../table/tableElements'
import RowActionTile from '../tiles/RowActionTile'

const ActionsTab = ({ row, entity }) => {
  return (
    <TileContainer style={{ maxWidth: '950px' }}>
      <Tile maxWidth="240px">
        <RowActionTile row={row} entity={entity} />
      </Tile>
    </TileContainer>
  )
}
export default ActionsTab
