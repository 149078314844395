import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const ChildItemsHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <ChildItemsHelpEnglish />
  } else {
    return <ChildItemsHelpGerman />
  }
}

export default ChildItemsHelp

const ChildItemsHelpGerman = () => (
  <div>
    <p>
      Wenn Sie die Funktion <em>Zufällige Aufgabenwahl</em> aktivieren, bearbeiten die Teilnehmer unterschiedliche, aber
      vergleichbare Aufgaben.
    </p>
    <p>
      Für viele Aufgabentypen stehen sogenannte Parallelversionen zur Verfügung. Diese sind hinsichtlich Aufbau,
      Lösungsweg und Schwierigkeit vergleichbar, unterscheiden sich jedoch beispielsweise in den verwendeten Zahlen oder
      Begriffen. Wenn Parallelversionen vorliegen, wird die Aufgabe zufällig gezogen, so dass die Teilnehmer
      unterschiedliche Versionen derselben Aufgabe erhalten.
    </p>
    <p>Vorteile:</p>
    <ul>
      <li>Teilnehmer, die den Test mehrmals bearbeiten, haben weniger Vorteile.</li>
      <li>Täuschungsmöglichkeiten wie Abschreiben oder gemeinsame Bearbeitung werden erschwert.</li>
      <li>Das Testmaterial verbreitet sich weniger schnell.</li>
    </ul>
  </div>
)

const ChildItemsHelpEnglish = () => (
  <div>
    <p>
      If you activate the <em>Random Item Selection</em> feature, participants will work on different but comparable
      items.
    </p>
    <p>
      For many types of tasks, so-called parallel versions are available. These are comparable in terms of structure,
      solution path, and difficulty but differ, for example, in the numbers or terms used. If parallel versions are
      available, the item is selected randomly so that participants receive different versions of the same item.
    </p>
    <p>Advantages:</p>
    <ul>
      <li>Participants who take the test multiple times have fewer advantages.</li>
      <li>Cheating methods such as copying or collaborating are made more difficult.</li>
      <li>The test material spreads less quickly.</li>
    </ul>
  </div>
)
