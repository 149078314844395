import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'
import { useContext } from 'react'
import { FilterContext } from '../../../utils/context/FilterContext'
import { FilterLabel, Separator, usingEmptyRelation } from '../../filter/filterUtils'
import { CONTENT_TYPES } from '../../../utils/constants/constants'
import DateDisplay from '../../dateDisplay/DateDisplay'
import React from 'react'

const ActiveFilter = ({ filter, setEditFilterId, editFilterId, setShowNewFilter }) => {
  const t = useTranslate()
  const { removeFilter } = useContext(FilterContext)

  const togleEditMode = (id) => {
    setEditFilterId(editFilterId ? null : id)
    setShowNewFilter(false)
  }

  return (
    <div style={{ display: 'grid' }}>
      <FilterLabel>{t('activeFilter')}</FilterLabel>
      <ActiveFilterElement>
        <LabelContainer onClick={() => togleEditMode(filter.id)}>
          <LabelContent {...{ filter }} />
        </LabelContainer>
        <ButtonContainer>
          <ActiveFilterButton onClick={() => removeFilter(filter.id)}>
            <span className="svg-icon icon-x-noborder-mask" />
          </ActiveFilterButton>
          <Separator />
          <ActiveFilterButton onClick={() => togleEditMode(filter.id)}>
            <span style={{ transform: 'scale(.8)' }} className="svg-icon icon-pen" />
          </ActiveFilterButton>
        </ButtonContainer>
      </ActiveFilterElement>
    </div>
  )
}

export default ActiveFilter

const LabelContent = ({ filter }) => {
  const t = useTranslate()
  const fieldLabel = filter.field.label || filter.field.value
  const valueLabel = getValueLabel(filter)

  const appendix = valueLabel[1] ? (
    <>
      {t('and')} {t(valueLabel[1])}
    </>
  ) : (
    ''
  )
  return (
    <>
      {t(fieldLabel)} {t(filter.relation.label)} {t(valueLabel[0])} {appendix}
    </>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-2);
  margin-left: var(--space-3);
`
const LabelContainer = styled.div`
  display: inline-flex;
  line-height: var(--lh-2);
  hyphens: auto;
  -webkit-hyphens: auto;
  cursor: pointer;
  &:hover + div .icon-pen {
    background-color: var(--small-action-icon-hover-color);
  }
  @media (max-width: 600px) {
    word-break: break-word;
  }
`

const ActiveFilterButton = styled.button`
  width: 16px;
  height: 16px;
  padding: 0;
  background-color: transparent;
  mask-position: right;
  .svg-icon {
    background-color: var(--small-action-icon-color);
    transition: background-color var(--hover-duration);
    &:hover {
      background-color: var(--small-action-icon-hover-color);
      transition: background-color var(--hover-duration);
    }
  }
`

const ActiveFilterElement = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: var(--input-bg-color);
  padding: 5px 8px;
  border-radius: var(--bdr-2);
  border: 1px solid var(--border-grey);
  font-size: 13px;
  user-select: none;
`

const getValueLabel = (filter) => {
  if (usingEmptyRelation(filter.relation)) {
    return ''
  }

  const { filterOptions } = filter.field
  const label1 = getSingleLabel(filter.value, filterOptions)
  const label2 = getSingleLabel(filter.value2, filterOptions)

  return [label1, label2]
}

const getSingleLabel = (value, filterOptions) => {
  if (value.value === 'undefined' || value.value === null || value.value === '') {
    return null
  }

  if (React.isValidElement(value.label)) {
    const offset = filterOptions?.labelOffset || 0
    return <span style={{ marginLeft: offset + 'px', lineHeight: 'var(--lh-2)' }}>{value.label}</span>
  }

  const contentType = filterOptions?.contentType
  const useExactDate = value.useExactDate

  if (contentType === CONTENT_TYPES.date && useExactDate) {
    return <DateDisplay key="value.label" date={value.label} />
  }
  return value.label || value.value
}
