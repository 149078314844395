import Config from '../../utils/constants/config'
import { STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { fetchData, setStatusBar } from '../../utils/helper/Helper'

export const createPayPalOrder = async (orderData, context) => {
  const payload = { numberCredits: orderData.numberCredits }
  const r = await fetchData(payload, 'create_order', context, 'paypalConnectionFailed', false)

  try {
    return r.response.orderId
  } catch (e) {
    console.error(e)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.error,
      text: 'paypalConnectionFailed',
      setVisible: true
    })
  }
}

export const approvePayPalOrder = async (data, context) => {
  const payload = { orderID: data.orderID }
  const r = await fetchData(payload, 'capture_order', context, 'paypalConnectionFailed', false)

  try {
    return r.response.status
  } catch (e) {
    console.error(e)
  }
}

export const paypalOptions = {
  clientId: Config.paypal_client_id,
  currency: 'EUR',
  intent: 'capture'
}
