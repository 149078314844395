import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import DataTable from '../../../components/table/DataTable'
import {
  capabilitiesPreviewColumn,
  getContactCols,
  Tile,
  TileContainer,
  TileHeadline
} from '../../../components/table/tableElements'
import { ERROR_MSG, SELECT_OPTIONS } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { InfoElement, InfoList, PageHeadline } from '../../../utils/elements/miscElements'
import { getOptionByValue } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getSpacerCol } from '../../../components/table/tableCols/tableCols'

const CheckInput = ({ data, designOptions }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const chosenDesign = getOptionByValue(designOptions, data.elektryonTemplate).label || t(ERROR_MSG)
  const userList = context.completeDataSet.contacts.filter((contact) => contact.isVisible())
  const columns = getContactCols(t)
  columns.push(capabilitiesPreviewColumn, getSpacerCol())

  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer>
          <Tile>
            <TileHeadline content="baseInfos" />
            <InfoList>
              <ProcessName {...{ data }} />
              <Design {...{ chosenDesign }} />
            </InfoList>
          </Tile>
          <Tile>
            <TileHeadline content="permissions" />
            <DataTable data={addPreview(userList, data, t)} columns={columns} hideResultCount hideArchiveButton />
          </Tile>
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default CheckInput

const addPreview = (rows, data, t) => {
  rows.forEach((row) => {
    const capabilitiesId = data.contactCapabilities.find((cap) => cap.contactUuid === row.contactUuid).capabilities
    const capabilitiesLabel = getOptionByValue(t(SELECT_OPTIONS.userCapabilities), capabilitiesId).label || t(ERROR_MSG)
    row.capabilitiesPreview = capabilitiesLabel
  })

  return rows
}

const ProcessName = ({ data }) => <InfoElement label="newProcessName" value={data.processName} />
const Design = ({ chosenDesign }) => <InfoElement label="testDesign" value={chosenDesign} />
