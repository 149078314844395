import LiveDataTable from './dashboard/LiveDataTable'
import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import { ContentContainer } from '../components/table/tableElements'
import { useContext } from 'react'
import { AppContext } from '../utils/context/AppContext'
import useTranslate from '../utils/hooks/useTranslate'

const LiveDataFullScreen = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const liveDataCount = context.completeDataSet.pts.filter((pt) => pt.active).length
  const liveDataHeadline = (
    <>
      <span>{t('liveData')}</span>
      {liveDataCount > 0 && <span style={{ fontWeight: 'var(--fw-1)' }}> ({liveDataCount})</span>}
    </>
  )

  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <h2>{liveDataHeadline}</h2>
        <LiveDataTable />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default LiveDataFullScreen
