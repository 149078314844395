import ReactTooltip from 'react-tooltip'
import { InfoIcon, SmallNotice, TileHeadlineContainer } from '../../utils/elements/miscElements'
import { getHubLink } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import AccessDataDisplay from '../accessDataDisplay/AccessDataDisplay'
import { Tile, TileHeadline } from '../table/tableElements'

const ParticipantAccessDataTile = ({ participant }) => {
  const t = useTranslate()
  const ptsInHub = participant.ptList.filter((pt) => pt.relatedAssessment.inHub)
  const hasTestsInHub = ptsInHub.length > 0
  const disabledNotice = getDisabledNotice(ptsInHub)
  const accessData = {
    link: getHubLink(participant.pnrHash),
    token: participant.pNr
  }

  return (
    <Tile width="250px">
      <TileHeadlineContainer className="headline-container">
        <TileHeadline content="testOverview" />
        <InfoIcon
          data-tip={<ToolTipText />}
          data-for="timeSettingsInfo"
          className="svg-icon icon-info"
          style={{ position: 'absolute', right: '0', bottom: '20px' }}
        />
      </TileHeadlineContainer>
      <ReactTooltip
        id="timeSettingsInfo"
        aria-haspopup="true"
        effect="solid"
        place="bottom"
        backgroundColor="var(--c-tooltip-bg)"
        textColor="var(--tooltip-text-color)"
        getContent={() => <ToolTipText />}
      />
      {hasTestsInHub ? (
        <AccessDataDisplay {...{ accessData, disabledNotice }} />
      ) : (
        <SmallNotice style={{ marginTop: 0 }}>{t('noTestsInHubNotice')}</SmallNotice>
      )}
    </Tile>
  )
}

export default ParticipantAccessDataTile

const getDisabledNotice = (ptsInHub) => {
  const finishedPtsInHub = ptsInHub.filter((pt) => pt.isFinished())
  const expiredPtsInHub = ptsInHub.filter((pt) => pt.isExpired())
  const deactivatedPtsInHub = ptsInHub.filter((pt) => pt.isDeactivated())

  const allTestsInHubFinished = ptsInHub.length === finishedPtsInHub.length
  const allTestsInHubExpired = ptsInHub.length === expiredPtsInHub.length
  const allTestsInHubDeactivated = ptsInHub.length === deactivatedPtsInHub.length

  if (allTestsInHubFinished) return 'allTestsInHubFinishedNotice'
  if (allTestsInHubExpired) return 'allTestsInHubExpiredNotice'
  if (allTestsInHubDeactivated) return 'allTestsInHubDeactivatedNotice'

  return null
}

const ToolTipText = () => {
  const t = useTranslate()
  return <div style={{ width: '200px', lineHeight: 'var(--lh-2)' }}>{t('accessCredentialsLeadToTestOverview')}</div>
}
