import styled from 'styled-components'
import PageNumberDisplay from './PageNumberDisplay'
import PagerButtons from './PagerButtons'
import ResultsPerPageController from './ResultsPerPageController'

const TablePagination = () => (
  <PageControllerWrapper>
    <PageController>
      <PagerButtons />
      <PageNumberDisplay />
    </PageController>
    <ResultsPerPageController />
  </PageControllerWrapper>
)

export default TablePagination

const PageControllerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-auto-flow: column;
  justify-content: flex-end;
`

const PageController = styled.div`
  margin-right: var(--space-5);
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: var(--space-3);
`
