import { useState } from 'react'
import { mailIsValid } from '../helper/Helper'
import { FORM_ERRORS } from '../constants/constants'

const useEmailValidation = (initialEmail = '') => {
  const initialError = mailErrors.find((e) => e.condition(initialEmail))
  const [email, setEmail] = useState(initialEmail)
  const [showMailError, setShowMailError] = useState(false)
  const [mailError, setMailError] = useState(initialError)

  const handleChange = (e) => {
    const mail = e.target.value
    if (showMailError && mailError.type === 'empty') {
      setShowMailError(false)
    }
    setEmail(mail)
    const error = mailErrors.find((e) => e.condition(mail))
    setMailError(error)
    if (!error || mail.length === 0) {
      setShowMailError(false)
    }
  }

  const handleBlur = () => {
    if (showMailError) {
      return
    }
    // make mail invalid error message visible
    setShowMailError(mailError?.type === 'invalid')
  }

  return { email, showMailError, handleChange, mailError, setShowMailError, handleBlur }
}

export default useEmailValidation

const mailErrors = [
  {
    type: 'empty',
    errorMsg: FORM_ERRORS.fieldEmpty,
    condition: (mail) => mail.length === 0
  },
  {
    type: 'invalid',
    errorMsg: FORM_ERRORS.emailInvalid,
    errorMsgShort: FORM_ERRORS.emailInvalidShort,
    condition: (mail) => !mailIsValid(mail) && mail.length > 0
  }
]
