import { useContext, useEffect } from 'react'
import { MODAL_TYPES, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary, selectStyles } from '../../../utils/elements/miscElements'
import { fetchData, getOptionByValue, setState, setStatusBar } from '../../../utils/helper/Helper'
import Select from 'react-select'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'
import SaveTemplateModal from './SaveTemplateModal'
import { getDefaultNewTitle } from '../sendEmailUtils'

const TemplateManager = ({ data, setData }) => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const options = getOptions(data)
  const buttonStyles = { flexGrow: 0, minHeight: '32px', minWidth: '80px', fontSize: 'var(--fs-2)' }
  const saveButtonLabel = t('save') + '…'
  const deleteButtonLabel = t('delete') + '…'

  const selectedTemplate = data.availableTemplates.find((t) => t.publicKey === data.templateKey)

  useEffect(() => {
    const newTemplateTitle = getDefaultNewTitle(data.templateTitle, data.saveMethod, t)
    setState(setData, 'newTemplateTitle', newTemplateTitle)
  }, [data.templateTitle, data.saveMethod])

  useEffect(() => {
    updateModalProps(context, data, setData)
  }, [data.saveMethod, data.newTemplateTitle])
  return (
    <Container>
      <Select
        options={options}
        onChange={(selectedOption) => changeTemplate(selectedOption, data, setData)}
        value={getOptionByValue(options, data.templateKey)}
        styles={selectStyles}
        isSearchable={false}
      />
      <ButtonContainer>
        <ButtonSecondary
          disabled={selectedTemplate.disabled}
          style={buttonStyles}
          content={deleteButtonLabel}
          onClick={(e) => openConfirmDeleteModal(data, setData, context, e)}
        />
        <ButtonSecondary
          style={buttonStyles}
          content={saveButtonLabel}
          onClick={(e) => openSaveTemplateModal(data, setData, context, e)}
        />
      </ButtonContainer>
    </Container>
  )
}

export default TemplateManager

const changeTemplate = (selectedOption, data, setData) => {
  setState(setData, 'templateKey', selectedOption.value)
  const optionChanged = data.templateKey !== selectedOption.value
  if (optionChanged) {
    setState(setData, 'isDirty', false)
  }
}

const openSaveTemplateModal = (data, setData, context, e) => {
  e.preventDefault()
  setState(setData, 'saveMethod', 'add')
  updateModalProps(context, data, setData)
  context.setModalOpen(true)
}

const updateModalProps = (context, data, setData) => {
  const nameEmpty = data.newTemplateTitle.length === 0
  context.setModalProps({
    headline: 'saveTemplate',
    content: <SaveTemplateModal {...{ data, setData }} />,
    buttonPrimary: (
      <ButtonPrimary
        disabled={nameEmpty}
        onClick={(e) => saveTemplate(data, setData, context, e)}
        modalButton
        content="save"
      />
    ),
    icon: 'icon-email modal',
    type: MODAL_TYPES.alert
  })
}

const Container = styled.div`
  display: grid;
  gap: var(--space-3);
`

const ButtonContainer = styled.div`
  display: flex;
  gap: var(--space-3);
  justify-content: flex-end;
`

const getOptions = (data) => {
  return data.availableTemplates.map((template) => {
    const dirty = data.isDirty && template.publicKey === data.templateKey
    return {
      label: <TemplateTitle title={template.title} dirty={dirty} />,
      value: template.publicKey
    }
  })
}

const TemplateTitle = ({ title, dirty }) => {
  const t = useTranslate()
  return (
    <>
      <span>{title}</span>
      {dirty && <span style={{ color: 'var(--text-color-secondary)' }}> ({t('changed')})</span>}
    </>
  )
}

const openConfirmDeleteModal = (data, setData, context, e) => {
  e.preventDefault()
  context.setModalProps({
    headline: 'deleteTemplate',
    content: `deleteTemplateConfirmation`,
    buttonPrimary: (
      <ButtonPrimary
        warning
        modalButton
        content="delete"
        onClick={() => {
          context.setModalOpen(false)
          deleteTemplate(data, setData, context, e)
        }}
      />
    ),
    icon: 'icon-delete modal',
    type: MODAL_TYPES.alert
  })
  context.setModalOpen(true)
}

const saveTemplate = async (data, setData, context, e) => {
  e.preventDefault()
  context.setModalOpen(false)

  const payload = {
    title: data.newTemplateTitle,
    public_key: data.saveMethod === 'edit' ? data.templateKey : null,
    template_data: {
      salutationType: data.salutationType,
      subject: data.emailSubject,
      topText: data.topText,
      bottomText: data.bottomText
    }
  }

  const responseData = await fetchData(payload, 'save_invitation_template', context, 'dataChangeFailed', false)

  try {
    setState(context.setCompleteDataSet, 'ptInvitationTemplates', responseData.response.templates)
    setData((prev) => ({
      ...prev,
      templateKey: responseData.response.newPublicKey,
      isDirty: false
    }))
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'templateSavedSuccessfully',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}

const deleteTemplate = async (data, setData, context, e) => {
  e.preventDefault()
  const payload = { public_key: data.templateKey }
  const responseData = await fetchData(payload, 'delete_invitation_template', context, 'dataChangeFailed', false)

  try {
    setState(context.setCompleteDataSet, 'ptInvitationTemplates', responseData.response.templates)
    setData((prev) => ({
      ...prev,
      templateKey: 'default_invitation',
      isDirty: false
    }))
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'templateDeletedSuccessfully',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}
