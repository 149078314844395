export const sortTypes = {
  alphanumeric: (row1, row2, columnName, desc) => {
    const row1Value = row1.values[columnName]
    const row2Value = row2.values[columnName]

    const val1 = getValueForSort(row1Value)
    const val2 = getValueForSort(row2Value)

    if (!val1 && !val2) {
      return 0
    }

    if (!val1) {
      return desc ? -1 : 1
    }

    if (!val2) {
      return desc ? 1 : -1
    }

    if (isNaN(row1Value) || isNaN(row2Value)) {
      const tmp1 = isNaN(row1Value) ? val1.toUpperCase() : val1
      const tmp2 = isNaN(row2Value) ? val2.toUpperCase() : val2
      return tmp1 > tmp2 ? 1 : -1
    }
    return Number(val1) > Number(val2) ? 1 : -1
  }
}

const getValueForSort = (value) => {
  if (!value) return ''
  return Array.isArray(value) ? value[0] : value
}
