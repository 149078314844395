import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import { ContentContainer } from '../components/table/tableElements'
import { Emphasized, InfoList, InfoListElement, PageHeadline } from '../utils/elements/miscElements'
import useTranslate from '../utils/hooks/useTranslate'

const Support = () => {
  const t = useTranslate()
  const styles = {
    container: {
      maxWidth: '450px'
    },
    infoList: {
      backgroundColor: 'var(--box-background)',
      padding: 'var(--space-5)',
      borderRadius: 'var(--bdr-2)'
    },
    infoListElement: {
      gridTemplateColumns: '100px 200px'
    }
  }

  return (
    <ScrollContainer type="main">
      <ContentContainer style={{ maxWidth: '600px' }}>
        <PageHeadline>Support</PageHeadline>
        <div style={styles.container}>
          <p>{t('supportNotice')}</p>
          <InfoList style={styles.infoList}>
            <InfoListElement style={styles.infoListElement}>
              <Emphasized>{t('email')}</Emphasized>
              <span>support@perseo.hr</span>
            </InfoListElement>
            <InfoListElement style={styles.infoListElement}>
              <Emphasized>{t('phone')}</Emphasized>
              <span>+49 211 97 63 398-0</span>
            </InfoListElement>
          </InfoList>
        </div>
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Support
