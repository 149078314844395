import { SettingsContainer } from './sharedElements/sharedElements'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import useTranslate from '../../utils/hooks/useTranslate'
import NormEditor from '../scoringEditors/normEditor/NormEditor'

const ScoringSettings = () => {
  const t = useTranslate()
  return (
    <SettingsContainer>
      <ScrollContainer type="settings">
        <h2>{t('gradingRules')}</h2>
        <NormEditor />
      </ScrollContainer>
    </SettingsContainer>
  )
}

export default ScoringSettings
