import React, { useState } from 'react'

export const FilterContext = React.createContext(null)

export const FilterContextWrapper = (props) => {
  const [activeFilters, setActiveFilters] = useState([])
  const SPECIAL_FILTER_IDS = ['freeText']
  const regularFilters = activeFilters.filter((af) => !SPECIAL_FILTER_IDS.includes(af.field.value))
  const generateUniqueFilterId = () => Math.random().toString(16).slice(2) + activeFilters.length

  const addFilter = (filter) => setActiveFilters([...activeFilters, filter])

  const removeFilter = (id) => setActiveFilters(activeFilters.filter((af) => af.id !== id))

  const updateFilter = (filter) => {
    const updatedFilters = activeFilters.map((af) => {
      if (af.id === filter.id) {
        af.id = filter.id
        af.value = filter.value
        af.value2 = filter.value2
        af.relation = filter.relation
        af.tableName = filter.tableName
        af.field = filter.field
      }

      return af
    })
    setActiveFilters([...updatedFilters])
  }

  const addLinkFilters = (linkFilters) => {
    const linkArray = Array.isArray(linkFilters) ? linkFilters : [linkFilters]
    const tmp = linkArray.map((f) => ({
      ...f,
      id: generateUniqueFilterId(),
      tableLink: true,
      value2: { value: '' },
      relation: {
        value: 'exact',
        label: 'is'
      }
    }))
    setActiveFilters(tmp)
  }

  const setGlobalFilter = (filter) => {
    const tmp = {
      ...filter,
      tableLink: true,
      value2: { value: '' },
      relation: {
        value: 'exact',
        label: 'is'
      }
    }
    setActiveFilters([tmp])
  }

  const deleteOtherTableFilters = (tableName) => {
    const tmp = activeFilters.filter((af) => af.tableName === tableName)
    setActiveFilters(tmp)
  }

  const addNewFilter = (filter) => {
    filter.id = generateUniqueFilterId()
    addFilter(filter)
  }

  const updateFreeTextFilter = (freeTextValue, tableName) => {
    const field = { value: 'freeText' }
    const relation = { value: 'like' }
    const value = { value: freeTextValue }
    if (!activeFilters.find((af) => af.id === 'freeText')) {
      addFilter({
        id: 'freeText',
        field: field,
        relation: relation,
        value: value,
        tableName: tableName,
      })
      return
    }
    if (freeTextValue === '') {
      removeFilter('freeText')
    } else {
      const updatedFilters = activeFilters.map((af) => (af.id === 'freeText' ? { ...af, value: value } : af))
      setActiveFilters(updatedFilters)
    }
  }

  return (
    <FilterContext.Provider
      value={{
        activeFilters,
        regularFilters,
        updateFilter,
        removeFilter,
        addFilter,
        addLinkFilters,
        updateFreeTextFilter,
        deleteOtherTableFilters,
        setGlobalFilter,
        addNewFilter
      }}>
      {props.children}
    </FilterContext.Provider>
  )
}
