import { MainEntity } from './MainEntity'

export class Contact extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'Contact'
    this.contactEmail = props.contactEmail
    this.contactFirstName = props.contactFirstName
    this.contactGender = props.contactGender
    this.contactIsAdmin = props.contactIsAdmin
    this.contactLastName = props.contactLastName
    this.contactPhone = props.contactPhone
    this.contactTitle = props.contactTitle
    this.contactUuid = props.contactUuid
    this.lastLogin = props.lastLogin
    this.loggedInContact = props.loggedInContact
    this.newsletter = props.newsletter
    this.notificationStatus = props.notificationStatus
    this.visibility = props.visibility
  }
  getContactLabel() {
    if (this.contactFirstName && this.contactLastName) {
      return this.contactFirstName + ' ' + this.contactLastName
    }
    if (this.contactEmail) {
      return this.contactEmail
    }
    return ''
  }
}
