import { B, Image, Table } from '../userManualElements'

const Chapter1 = () => (
  <>
    <h2 id="chapter-1">1 Getting Started</h2>
    <p>
      Welcome to the PERSEO Customer Area. The Customer Area is a web application for managing tests and participants.
      It offers various functions, including:
    </p>
    <ul>
      <li>Creating, editing, and deleting processes, tests, and participants</li>
      <li>Generating anonymous or non-anonymous login codes or test links</li>
      <li>Retrieving test results as PDF reports or CSV tables (e.g., for Excel)</li>
      <li>Real-time tracking of ongoing tests</li>
      <li>Settings such as test periods or accommodations</li>
      <li>Email notifications for newly completed tests</li>
      <li>Statistics on test participation and results</li>
      <li>Sorting and filtering options, e.g., by testing status and results</li>
      <li>User and permission management</li>
      <li>Help and support</li>
    </ul>
    <p>
      You can use the Customer Area with any operating system, such as Microsoft Windows, macOS, or Linux. You
      don&apos;t need to install any software on your computer. You only need a current web browser. We recommend one of
      the following browsers:
    </p>
    <ul>
      <li>Google Chrome</li>
      <li>Mozilla Firefox</li>
      <li>Microsoft Edge</li>
    </ul>
    <div className="textbox important">
      <p>Please note:</p>
      <ul>
        <li>Internet Explorer is not supported</li>
        <li>Browser extensions that block JavaScript or cookies must be turned off</li>
      </ul>
    </div>
    <p>
      To open the Customer Area, enter{' '}
      <a className="textlink" rel="noreferrer" target="_blank" href="https://perseo-assessment.de/">
        https://perseo-assessment.de/
      </a>{' '}
      into your browser&apos;s address bar and open the page.
    </p>
    <h3 id="chapter-1-1">1.1 Login</h3>
    <p>
      If you already have login credentials, enter them and click <B>Log In</B>.
    </p>
    <p>
      If you don&apos;t have login credentials yet, click on <B>Request Access</B>, enter the name of your company /
      authority and your email address, then click on <B>Request Access</B>. After a brief review by a staff member, you
      will receive access data via email.
    </p>
    <Image src="user_manual_images/customer-area-login.png" caption="Fig. 1: Login Screen" width="300" />
    <p>
      If you have forgotten your password, click on <B>Forgot Password</B>, enter your email address, and click{' '}
      <B>Submit</B>. You will receive an email with further steps to set a new password.
    </p>
    <h3 id="chapter-1-2">1.2 Navigation</h3>
    <h4 id="chapter-1-2-1">1.2.1 Menu Bar</h4>
    <Image src="user_manual_images/menu-bar.png" caption="Fig. 2: Menu Bar" />
    <p>At the top of the browser window, you will find the menu bar (Fig. 2). Here you will find:</p>
    <ol>
      <li>the current path as navigation aid</li>
      <li>the global search</li>
      <li>the menu</li>
    </ol>
    <p>
      With the global search, you can search for processes, tests, participant names, participant numbers, and TANs.
      When you click on a search result, you will be taken to the respective table, and the searched element will be
      selected as a filter.
    </p>
    <h4 id="chapter-1-2-2">1.2.2 Navigation Bar</h4>
    <p>
      Use the navigation bar on the left side to access the main pages of the Customer Area. The currently selected page
      is highlighted in dark. The following menu items are available:
    </p>
    <NavbarDescriptionTable />
    <h4 id="chapter-1-2-3">1.2.3 Menu</h4>
    <p>
      To open the menu, click on <B>Menu</B> in the top right corner of the menu bar. The following menu items are
      available:
    </p>
    <MenuDescriptionTable />
    <h3 id="chapter-1-3">1.3 Structure of the Testing System</h3>
    <p>
      The four main elements in the PERSEO testing system are <em>Processes</em>, <em>Tests</em>, <em>Participants</em>, and{' '}
      <em>Testings</em>. The following diagram illustrates how these four elements are connected.
    </p>
    <Image src="user_manual_images/perseo-structure.svg" caption="Fig. 3: Structure of the PERSEO Testing System" />
    <p>
      Let&apos;s assume that commercial apprentices are to be selected. For this purpose, a process{' '}
      <em>Commercial Training (m/f/d)</em> is created.
    </p>
    <p>
      The applicants are required to take two tests: the <em>Performance Test for Commercial Professions</em> and the{' '}
      <em>Personality Test for Apprentices</em>. Both tests are clearly associated with the process.
    </p>
    <p>
      Two people have applied: Max Mustermann and Martina Musterfrau. These are the <em>Participants</em>. Each
      participant is only present in the system once and has a 10-digit participant number starting with P (e.g.,
      P-12345-67890).
    </p>
    <p>
      Since both participants need to take both tests, there are four <em>Testings</em>. Each testing has a 10-digit
      number starting with PAS (e.g., PAS-ABCDE-12345), a status (e.g., Started or Completed), and - after completion -
      a result.
    </p>
  </>
)

export default Chapter1

const NavbarDescriptionTable = () => {
  const header = ['Menu Item', 'Description', 'Restrictions']
  const data = [
    [
      'Dashboard',
      'Opens the dashboard. Here you can find the latest test results, system statistics, and track ongoing tests in real time.'
    ],
    [
      'Processes',
      <span key="NavbarDescriptionTable-processes">
        Opens the <em>Processes</em> table{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-1">
          (see 3.1)
        </span>
      </span>
    ],
    [
      'Tests',
      <span key="NavbarDescriptionTable-tests">
        Opens the <em>Tests</em> table{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-2">
          (see 3.2)
        </span>
      </span>
    ],
    [
      'Participants',
      <span key="NavbarDescriptionTable-participants">
        Opens the <em>Participants</em> table{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3">
          (see 3.3)
        </span>
      </span>
    ],
    [
      'Testings',
      <span key="NavbarDescriptionTable-pts">
        Opens the <em>Testings</em> table{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-4">
          (see 3.4)
        </span>
      </span>
    ],
    [
      'Bookings',
      <span key="NavbarDescriptionTable-bookings">
        Opens the <em>Bookings</em> table
      </span>,
      'Administrator'
    ],
    [
      'Credit Shop',
      <span key="NavbarDescriptionTable-shop">
        Opens the <em>Credit Top-Up</em> page{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-4-2">
          (see 4.2)
        </span>
      </span>,
      'Administrator'
    ],
    [
      'Help',
      <span key="NavbarDescriptionTable-help">
        Opens the <em>Help</em> page
      </span>
    ],
    [
      'Support',
      <span key="NavbarDescriptionTable-support">
        Opens the <em>Support</em> page
      </span>
    ]
  ]
  return <Table name="navbar-description" {...{ header, data }} />
}

const MenuDescriptionTable = () => {
  const header = ['Menu Item', 'Description']
  const data = [
    [
      'Settings',
      <span key="MenuDescriptionTable-settings">
        Displays the <em>Settings</em> sidebar and opens the first subpage
      </span>
    ],
    [
      'Help',
      <span key="MenuDescriptionTable-help">
        Opens the <em>Help</em> page
      </span>
    ],
    [
      'Support',
      <span key="MenuDescriptionTable-support">
        Opens the <em>Support</em> page
      </span>
    ],
    [
      'Invoices',
      <span key="MenuDescriptionTable-invoices">
        Opens the <em>Invoices</em> page. Here you can access and download your invoices.
      </span>
    ],
    [
      'Documents',
      <span key="MenuDescriptionTable-documents">
        Opens the <em>Documents</em> page. Here you can download various documents, such as the price list or terms and
        conditions.
      </span>
    ],
    [
      'Data Security',
      <span key="MenuDescriptionTable-dataSecurity">
        Opens the <em>Data Security</em> page
      </span>
    ],
    [
      'Legal Notice',
      <span key="MenuDescriptionTable-imprint">
        Opens the <em>Legal Notice</em> page
      </span>
    ],
    ['Logout', 'Ends the current session and redirects to the login page']
  ]
  return <Table name="menu-description" {...{ header, data }} />
}
