import useTranslate from '../hooks/useTranslate'

export const getDesignOptions = (initialDesignOptions) => {
  const designOptions = initialDesignOptions.map((option) => ({
    label: option.title,
    value: option.id
  }))

  const designPreview = {
    label: <DesignPreviewLabel />,
    value: 0,
    isDisabled: true
  }

  if (designOptions.length === 1) designOptions.push(designPreview)

  return designOptions
}

const DesignPreviewLabel = () => {
  const t = useTranslate()
  return (
    <span>
      {t('customDesign')} <br />({t('notAvailable')})
    </span>
  )
}

export const getDefaultDesign = (designOptions) => {
  if (designOptions.filter((d) => !d.isDisabled).length > 1) {
    return designOptions[1]
  }
  return designOptions[0]
}
