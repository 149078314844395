import { PT_STATUS } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'
import PieDiagram, { Circle, DataLabel, Legend } from '../charts/PieDiagram'
import { getPieLabelColor } from '../charts/chartUtils'
import { Tile, TileHeadline } from '../table/tableElements'

const ParticipationStatsTile = ({ stats }) => {
  const t = useTranslate()
  const participationStats = stats.filter(
    (stat) =>
      [
        PT_STATUS.waiting,
        PT_STATUS.ready,
        PT_STATUS.started,
        PT_STATUS.finished,
        PT_STATUS.expired,
        PT_STATUS.deactivated
      ].includes(stat.id) && stat.value > 0
  )

  return (
    <Tile width="300px">
      <TileHeadline content="participation" />
      <div style={{ height: '250px' }}>
        <PieDiagram data={t(participationStats)} showTotal innerRadius={0.7} content="participationStats" />
      </div>
      <Legend row>
        {participationStats.map((stat, i) => (
          <DataLabel row key={i}>
            <Circle color={getPieLabelColor(participationStats.length, i)} />
            <span style={{ marginLeft: '8px' }}>{t(stat.label)}</span>
          </DataLabel>
        ))}
      </Legend>
    </Tile>
  )
}

export default ParticipationStatsTile
