import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'

const EmailConfirmationModalContent = ({ count, emailLabel, showLoadingMessage = false }) => {
  const t = useTranslate()
  return (
    <>
      {!showLoadingMessage && <>{t('confirmMailing', count, t(emailLabel))}</>}

      {showLoadingMessage && (
        <LoadingMessageContainer>
          <span style={{ width: '25px', height: '25px' }} className="svg-icon spinner" />
          <span>{t('emailsBeingSent')}...</span>
        </LoadingMessageContainer>
      )}
    </>
  )
}

const LoadingMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-3);
  height: 45px;
`

export default EmailConfirmationModalContent
