import { API_DATA, ERROR_MSG, FIELD_LABELS, MAX_CHARS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { SmallNotice } from '../../utils/elements/miscElements'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import { getOptionByValue } from '../../utils/helper/Helper'
import { getDesignOptions } from '../../utils/helper/designUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import CustomInput from '../customInput/CustomInput'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'

const ProcessBaseSettingsTile = ({ process }) => {
  const t = useTranslate()
  const canEdit = CapabilitiesHelper.canEditProcess(process)
  const disableNotice = getDisabledNotice(process)
  const selectOptions = {
    design: getDesignOptions(process.designOptions)
  }

  return (
    <Tile width="250px">
      <TileHeadline content="baseSettings" />
      <CustomInputList>
        <CustomInput
          label={FIELD_LABELS.elementName}
          content={process.processName}
          dataKey="processName"
          type="input"
          idValue={process.processUuid}
          apiData={API_DATA.processes}
          disabled={!canEdit}
          maxChars={MAX_CHARS.processName}
          cannotBeEmpty
        />

        <CustomInput
          label={FIELD_LABELS.description}
          content={process.processDescription}
          dataKey="processDescription"
          type="textarea"
          idValue={process.processUuid}
          apiData={API_DATA.processes}
          disabled={!canEdit}
          maxChars={MAX_CHARS.processDescription}
        />

        <CustomInput
          label={FIELD_LABELS.testDesign}
          content={getOptionByValue(selectOptions.design, process.elektryonTemplate).label || ERROR_MSG}
          dataKey="elektryonTemplate"
          type="select"
          value={process.elektryonTemplate}
          options={selectOptions.design}
          idValue={process.processUuid}
          apiData={API_DATA.processes}
          disabled={!canEdit}
        />

        <CustomInput
          label="capabilities"
          content={getOptionByValue(t(SELECT_OPTIONS.userCapabilities), process.capabilities).label || ERROR_MSG}
          disabled
        />
      </CustomInputList>

      {disableNotice && <SmallNotice>{t(disableNotice)}</SmallNotice>}
    </Tile>
  )
}

export default ProcessBaseSettingsTile

const getDisabledNotice = (process) => {
  if (process.isArchived()) return 'processSettingsCannotBeEditedInArchive'
  if (!process.isEditor()) return 'processNoEditorPermissions'
  return ''
}
