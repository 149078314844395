import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import DataTable from '../../../components/table/DataTable'
import { capabilitiesPreviewColumn, Tile, TileContainer, TileHeadline } from '../../../components/table/tableElements'
import { ERROR_MSG, FIELD_LABELS, NOT_SET_TEXT, SELECT_OPTIONS } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { InfoElement, InfoList, PageHeadline } from '../../../utils/elements/miscElements'
import { getOptionByValue } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getProcessCol } from '../addUserUtils'
import { getSpacerCol } from '../../../components/table/tableCols/tableCols'

const AddUserCheckInputPage = ({ data }) => {
  const t = useTranslate()
  const columns = getProcessCol()
  columns.push(capabilitiesPreviewColumn, getSpacerCol())
  const context = useContext(AppContext)
  const { processes } = context.completeDataSet

  const addPreview = (rows, data) => {
    rows.forEach((row) => {
      const capabilitiesId = data.contactCapabilities.find((cap) => cap.processUuid === row.processUuid).capabilities
      const capabilitiesLabel = getOptionByValue(t(SELECT_OPTIONS.userCapabilities), capabilitiesId).label
      row.capabilitiesPreview = capabilitiesLabel
    })

    return rows
  }

  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer>
          <Tile maxWidth="600px">
            <TileHeadline content="baseInfos" />
            <InfoList>
              <InfoElement label={FIELD_LABELS.email} value={data.contactEmail} />
              <InfoElement
                label={FIELD_LABELS.salutation}
                value={
                  data.contactGender
                    ? getOptionByValue(t(SELECT_OPTIONS.contactSalutations), data.contactGender).label || ERROR_MSG
                    : t(NOT_SET_TEXT)
                }
              />
              <InfoElement
                label={FIELD_LABELS.firstName}
                value={data.contactFirstName ? data.contactFirstName : t(NOT_SET_TEXT)}
              />
              <InfoElement
                label={FIELD_LABELS.lastName}
                value={data.contactLastName ? data.contactLastName : t(NOT_SET_TEXT)}
              />
              <InfoElement
                label={FIELD_LABELS.contactIsAdmin}
                value={getOptionByValue(t(SELECT_OPTIONS.yesNo), data.contactIsAdmin).label || t(ERROR_MSG)}
              />
            </InfoList>
          </Tile>
          <Tile>
            <TileHeadline content="permissions" />
            <DataTable
              data={addPreview(processes, data)}
              columns={columns}
              hideResultCount
              hideArchiveButton
              tableName="add-user-check-input"
            />
          </Tile>
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default AddUserCheckInputPage
