import {
  Label,
  OptionDescription,
  OptionEntryWide,
  PageHeadline,
  WizardForm
} from '../../../utils/elements/miscElements'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import UploadPreviewTable from '../subComponents/UploadPreviewTable'
import HeaderMapping from '../subComponents/HeaderMapping'
import UploadDropZone from '../subComponents/UploadDropZone'
import AnonymizationNotice from '../subComponents/AnonymizationNotice'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'
import { OptionDescriptionUpload } from '../uploadUtils'

const UploadParticipants = ({ data, setData, headerMap, setHeaderMap }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const showAnonymizationNotice = context.completeDataSet.customer.anonymizeTimeSpan > 0

  return (
    <>
      <PageHeadline>{t('uploadParticipantList')}</PageHeadline>
      <ScrollContainer>
        <WizardForm>
          <OptionEntryWide hasHint>
            <Label fullWidth>Upload</Label>
            <UploadDropZone {...{ data, setData }} />
            <OptionDescription description={<OptionDescriptionUpload />} />
          </OptionEntryWide>
          {data.parsedCsvData.length > 0 && (
            <>
              <OptionEntryWide hasHint>
                <Label fullWidth>{t('assignColumns')}</Label>
                <HeaderMapping {...{ data, setData, headerMap, setHeaderMap }} />
                <OptionDescription description="rearrangeColumnsInfo" />
              </OptionEntryWide>
              <OptionEntryWide hasHint>
                <Label fullWidth>{t('preview')}</Label>
                <UploadPreviewTable {...{ data, headerMap }} />
                {showAnonymizationNotice && <OptionDescription description={<AnonymizationNotice />} />}
              </OptionEntryWide>
            </>
          )}
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default UploadParticipants
