import Cookies from 'universal-cookie'
import { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import { translateParentConfigs } from '../helper/Helper'

const useContactSettings = () => {
  const cookies = new Cookies()
  const context = useContext(AppContext)
  useEffect(() => {
    document.body.classList.toggle('dark', context.displayMode === 'dark')
    document.documentElement.lang = context.language
    cookies.set('perseo-displayMode', context.displayMode, { path: '/' })
    cookies.set('perseo-language', context.language, { path: '/' })
  }, [context.displayMode, context.language])

  useEffect(() => {
    translateParentConfigs(context?.completeDataSet?.parentConfigs, context.language)
  }, [context.language])
}

export default useContactSettings
