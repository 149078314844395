import { useCallback, useContext, useEffect, useState } from 'react'
import { API_DATA } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import { isParticipant, isProcess, trapFocus } from '../../utils/helper/Helper'
import useEventListener from '../../utils/hooks/useEventListener'
import useTranslate from '../../utils/hooks/useTranslate'
import ContextMenu from '../contextMenu/ContextMenu'
import {
  getActivatePtButton,
  getAddParticipantToOtherTestButton,
  getAddPtButton,
  getAddTestButton,
  getDeactivatePtButton,
  getDeleteButton,
  getDownloadCsvButton,
  getDownloadFeedbackButton,
  getDownloadReportButton,
  getDownloadResultPdfZipButton,
  getDuplicateProcessButton,
  getResetTimerButton,
  getRestoreButton,
  getStartTimerButton,
  getArchiveButton,
  getEmailButton,
  getDeleteContactButton,
  getChangeCapabilitiesButton,
  getCopyLinkButton
} from '../contextMenu/contextMenuButtons'
import { RowActionsToggleButton, RowActionsToggleButtonIcon } from './tableElements'
import useRedirect from '../../utils/hooks/useRedirect'

const RowActions = ({ row, entity }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const redirect = useRedirect()
  const [contextMenuVisible, setContextMenuVisible] = useState(false)
  const [target, setTarget] = useState(null)
  const entries = getEntries(row, entity, context, t, redirect)
  const idKey = API_DATA[entity].idKey
  const id = row[idKey]

  const hideMenu = () => setContextMenuVisible(false)

  useEffect(() => {
    handleFakeHoverEffectForRow(target, contextMenuVisible)
  }, [contextMenuVisible, target])

  useEffect(() => {
    setTimeout(() => {
      if (contextMenuVisible) {
        const focusableElements = document.querySelectorAll('.top-menu-list-item button')
        focusableElements[0]?.focus()
        trapFocus(focusableElements)
      } else {
        document.body.classList.remove('focus-trapped')
      }
    }, 50)
  }, [contextMenuVisible])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        event.preventDefault()
        setContextMenuVisible(false)
      }
    },
    [setContextMenuVisible]
  )

  useEventListener('keydown', handleKeyDown)

  return (
    <>
      {entries.flat().length > 0 && (
        <RowActionsToggleButton
          contextMenuVisible={contextMenuVisible}
          onClick={(e) => {
            setTarget(e.target)
            setContextMenuVisible(!contextMenuVisible)
          }}
          id={id}
          className="row-actions-toggle-button">
          <RowActionsToggleButtonIcon contextMenuVisible={contextMenuVisible} id={id} />
          <ContextMenu
            isVisible={contextMenuVisible}
            hideMenu={hideMenu}
            width="250px"
            entries={entries}
            position="table"
            id={id}
            target={target}
          />
        </RowActionsToggleButton>
      )}
    </>
  )
}

export default RowActions

export const getEntries = (row, entity, context, t, redirect) => {
  const buttonVisibility = getButtonVisibility(row, entity, context)

  const downloadEntries = []
  const miscEntries = []
  const addEntries = []
  const deleteEntries = []

  if (buttonVisibility.downloadCsv) {
    const params = isProcess(entity) ? { processUuid: row.processUuid } : { assessmentUuid: row.assessmentUuid }
    const downloadCsvButton = getDownloadCsvButton(params)
    downloadEntries.push(downloadCsvButton)
  }

  if (buttonVisibility.changeCapabilities) {
    const changeCapabilitiesButton = getChangeCapabilitiesButton(row, redirect)
    miscEntries.push(changeCapabilitiesButton)
  }

  if (buttonVisibility.duplicateProcess) {
    const duplicateProcessButton = getDuplicateProcessButton(row, context)
    addEntries.push(duplicateProcessButton)
  }

  if (buttonVisibility.addParticipantsToProcess) {
    const assessmentUuid = row.relatedAssessments.length === 1 ? row.relatedAssessments[0].assessmentUuid : null
    const addPtButton = getAddPtButton({
      processUuid: row.processUuid,
      assessmentUuid: assessmentUuid,
      redirect: redirect
    })
    addEntries.push(addPtButton)
  }

  if (buttonVisibility.addTestToProcess) {
    const addTestButton = getAddTestButton(row, redirect)
    addEntries.push(addTestButton)
  }

  if (buttonVisibility.downloadPdfReportZip) {
    const downloadResultPdfZipButton = getDownloadResultPdfZipButton(row.assessmentUuid, context)
    downloadEntries.push(downloadResultPdfZipButton)
  }

  if (buttonVisibility.addPtToTest) {
    const addPtButton = getAddPtButton({
      processUuid: row.relatedProcess.processUuid,
      assessmentUuid: row.assessmentUuid,
      redirect: redirect
    })
    addEntries.push(addPtButton)
  }

  if (buttonVisibility.addParticipantToOtherTest) {
    const addParticipantToOtherTestButton = getAddParticipantToOtherTestButton(row, redirect)
    miscEntries.push(addParticipantToOtherTestButton)
  }

  if (buttonVisibility.startTimer) {
    const data = isParticipant(entity) ? row.ptList : [row]
    const startTimerButton = getStartTimerButton(data, context)
    miscEntries.push(startTimerButton)
  }

  if (buttonVisibility.resetTimer) {
    const data = isParticipant(entity) ? row.ptList : [row]
    const resetTimerButton = getResetTimerButton(data, context)
    miscEntries.push(resetTimerButton)
  }

  if (buttonVisibility.sendEmail) {
    const sendEmailButton = getEmailButton(row, entity, redirect)
    miscEntries.push(sendEmailButton)
  }

  if (buttonVisibility.downloadReport) {
    const downloadReportButton = getDownloadReportButton(row, entity, context)
    downloadEntries.push(downloadReportButton)
  }

  if (buttonVisibility.downloadFeedback) {
    const downloadFeedbackButton = getDownloadFeedbackButton(row.relatedResult.resultNr)
    downloadEntries.push(downloadFeedbackButton)
  }

  if (buttonVisibility.activatePt) {
    const activatePtButton = getActivatePtButton(row, context)
    miscEntries.push(activatePtButton)
  }

  if (buttonVisibility.deactivatePt) {
    const deactivatePtButton = getDeactivatePtButton(row, context, t)
    miscEntries.push(deactivatePtButton)
  }

  if (buttonVisibility.delete) {
    const deleteButton = getDeleteButton(row, entity, context, t)
    deleteEntries.push(deleteButton)
  }

  if (buttonVisibility.unarchive) {
    const unarchiveButton = getRestoreButton(row, entity, context, t)
    miscEntries.push(unarchiveButton)
  }

  if (buttonVisibility.deleteContacts) {
    const deleteContactButton = getDeleteContactButton(row, context, t)
    deleteEntries.push(deleteContactButton)
  }

  if (buttonVisibility.copyLink) {
    const copyLinkButton = getCopyLinkButton(row, entity, context, t)
    miscEntries.push(copyLinkButton)
  }

  if (buttonVisibility.archive) {
    const archiveButton = getArchiveButton(row, entity, context, t)
    miscEntries.push(archiveButton)
  }

  return [downloadEntries, addEntries, miscEntries, deleteEntries]
}

const getButtonVisibility = (row, entity, context) => ({
  archive: CapabilitiesHelper.canArchiveEntities([row], context.loggedInAsAdmin, entity),
  delete: CapabilitiesHelper.canDeleteEntities(entity, context.loggedInAsAdmin),
  unarchive: CapabilitiesHelper.canRestoreEntities([row], context.loggedInAsAdmin),
  addParticipantsToProcess: CapabilitiesHelper.canAddParticipantsToProcess(entity, row),
  addTestToProcess: CapabilitiesHelper.canAddTestToProcess(entity, row),
  duplicateProcess: CapabilitiesHelper.canDuplicateProcess(entity, row, context.loggedInAsAdmin),
  downloadCsv: CapabilitiesHelper.canDownloadCsv(entity),
  downloadPdfReportZip: CapabilitiesHelper.canDownloadPdfReportZip(entity, row),
  addPtToTest: CapabilitiesHelper.canAddPtToTest(entity, row),
  addParticipantToOtherTest: CapabilitiesHelper.canAddParticipantsToOtherTest(entity, [row]),
  startTimer: CapabilitiesHelper.canBulkStartTimer(entity, [row]),
  resetTimer: CapabilitiesHelper.canBulkResetTimer(entity, [row]),
  deactivatePt: CapabilitiesHelper.canDeactivatePts(entity, [row]),
  activatePt: CapabilitiesHelper.canActivatePts(entity, [row]),
  downloadReport: CapabilitiesHelper.canDownloadReports(entity, [row]),
  downloadFeedback: CapabilitiesHelper.canDownloadFeedback(entity, row),
  sendEmail: CapabilitiesHelper.canSendEmails(entity, [row]),
  changeCapabilities: CapabilitiesHelper.canSetCapabilities(
    entity,
    context.loggedInAsAdmin,
    context.completeDataSet.contacts
  ),
  deleteContacts: CapabilitiesHelper.canDeleteContacts(
    entity,
    context.loggedInAsAdmin,
    context.completeDataSet.contacts.length
  ),
  copyLink: CapabilitiesHelper.canCopyLink(entity, row)
})

const handleFakeHoverEffectForRow = (target, contextMenuVisible) => {
  if (!target) return
  if (contextMenuVisible) {
    const activeRow = target.closest('tr')
    activeRow.classList.add('hover')
  } else {
    const activeRow = document.getElementsByClassName('hover')[0]
    if (activeRow) activeRow.classList.remove('hover')
  }
}
