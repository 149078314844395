import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'

const ErrorFallback = () => {
  const t = useTranslate()
  return (
    <OuterContainer>
      <div>
        <Icon className="svg-icon icon-sad-face" />
        <h2>{t('errorHeadline')}</h2>
        <ErrorMessage>{t('errorBody')}</ErrorMessage>
      </div>
    </OuterContainer>
  )
}

export default ErrorFallback

const OuterContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  padding: 0 var(--inset);
  display: flex;
  justify-content: center;
  text-align: center;
`

const ErrorMessage = styled.p`
  color: var(--text-color-secondary);
  max-width: 75ch;
`

const Icon = styled.span`
  width: 60px;
  height: 60px;
  margin-bottom: var(--space-5);
`
