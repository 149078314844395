import { TabHintContainer } from '../../utils/elements/miscElements'
import { CopyIconTooltip } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import PtAccessDataTile from '../tiles/PtAccessDataTile'

const PtAccessDataTab = ({ pt }) => {
  const t = useTranslate()
  const INVITE_LINK_HELP_TEXT_PT = (
    <span>
      {t('sendLinkOrLoginCode')}
      <a className="textlink" rel="noreferrer" target="_blank" href="https://app.perseo-assessment.de">
        https://app.perseo-assessment.de
      </a>
      {t('toStartThisTest')}
    </span>
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TabHintContainer>{INVITE_LINK_HELP_TEXT_PT}</TabHintContainer>
      <PtAccessDataTile {...{ pt }} />
      <CopyIconTooltip />
    </div>
  )
}
export default PtAccessDataTab
