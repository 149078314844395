import styled from 'styled-components'
const MatchingScale = ({ rowIndex, matchPoints, setData, editMode }) => {
  const handleChange = (value, colIndex, rowIndex) => {
    const updatedMatchPoints = [...matchPoints]
    updatedMatchPoints[colIndex] = value
    setData((prev) => prev.map((dim, i) => (i === rowIndex ? { ...dim, matchPoints: updatedMatchPoints } : dim)))
  }

  return (
    <ScaleContainer className="matching-norm-scale-container">
      {matchPoints.map((value, colIndex) => {
        const color = getMatchColor(parseInt(value))
        return (
          <Section key={`section${colIndex}`} color={color} value={parseInt(value)} editMode={editMode}>
            <select
              className="match-point-select"
              tabIndex={editMode ? '' : '-1'}
              value={value}
              onChange={(e) => handleChange(parseInt(e.target.value), colIndex, rowIndex)}>
              <option value={0}>–</option>
              <option value={1}>o</option>
              <option value={2}>+</option>
            </select>
          </Section>
        )
      })}
    </ScaleContainer>
  )
}

export default MatchingScale

export const ScaleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  width: 100%;
  height: 100%;
  gap: 1px;
  border-radius: var(--bdr-2);
  overflow: hidden;
`

const Section = styled.div`
  background-color: ${(props) => props.color};
  display: inline-flex;
  align-items: center;
  select {
    font-family: ${(props) => (props.value === 2 ? 'Open Sans' : '')};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    text-align: center;
    color: var(--grey-900);
    font-size: var(--fs-2);
    pointer-events: ${(props) => (props.editMode ? 'initial' : '')};
  }
`

export const getMatchColor = (value) => {
  switch (value) {
    case 0:
      return 'var(--matching-tile-0-color)'
    case 1:
      return 'var(--matching-tile-1-color)'
    default:
      return 'var(--matching-tile-2-color)'
  }
}
