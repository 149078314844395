import styled from 'styled-components'

export const PageGroupFooter = styled.div`
  display: grid;
  max-width: var(--wizard-max-width);
  grid-template-columns: ${(props) => (props.largeButtons ? '1fr 300px' : '1fr 250px')};
  padding-top: var(--space-3);
  border-top: 1px solid var(--border-grey);
  justify-content: 'space-between';
  align-items: flex-start;
  gap: var(--space-7);
  @media screen and (max-width: 600px) {
    grid-template-columns: unset;
  }
`

export const PageGroupContentContainer = styled.div`
  max-width: var(--wizard-max-width);
  height: 100%;
  display: grid;
  grid-template-rows: ${(props) => (props.isInNotification ? '' : 'auto 1fr 49px')};
  align-content: start;
  @media screen and (max-width: 768px) {
    overflow: auto;
  }
  .scroll-container {
    &:after {
      bottom: 49px;
    }
  }
`

export const PageGroupOuterContainer = styled.div`
  position: relative;
  margin: ${(props) => (props.isInNotification || props.isInSettings ? 0 : 'var(--inset)')};
  width: 100%;
  height: ${(props) => (props.isInSettings ? '100%' : '')};
`
