import React, { useContext, useEffect, useState } from 'react'
import {
  Form,
  FieldWrapper,
  Label,
  SmallLink,
  SmallExternalLink,
  LoginMessage
} from '../../../utils/elements/FormElements'
import { ButtonPrimary, ErrorMessage } from '../../../utils/elements/miscElements'
import { fetchData } from '../../../utils/helper/Helper'
import sessionHandler from '../../../utils/helper/sessionHandler'
import { FIELD_LABELS, FORM_ERRORS, loginMessages } from '../../../utils/constants/constants'
import { PAGES } from '../../../utils/constants/pages'
import { AppContext } from '../../../utils/context/AppContext'
import Config from '../../../utils/constants/config'
import PasswordVisibilityToggle from '../../../components/passwordVisibilityToggle/PasswordVisibilityToggle'
import { CenteredFlexDiv } from '../../../components/modal/modalStyles'
import LanguageSelection from '../../../components/languageSelection/LanguageSelection'
import useTranslate from '../../../utils/hooks/useTranslate'
import useEmailValidation from '../../../utils/hooks/useEmailValidation'
import CapslockIndicator from '../../../components/capslockIndicator/CapslockIndicator'
import usePageUrl from '../../../utils/hooks/usePageUrl'

const Login = () => {
  const context = useContext(AppContext)
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [showPasswordEmptyError, setShowPasswordEmptyError] = useState(false)
  const [capslockOn, setCapslockOn] = useState(false)

  const { email, showMailError, handleChange, mailError, setShowMailError, handleBlur } = useEmailValidation()

  useEffect(() => {
    sessionHandler.deleteSessionCookie()
  }, [])

  const getUrlFromPage = usePageUrl()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (mailError) {
      setShowMailError(true)
      document.getElementById('login-mail').focus()
    }

    if (password.length === 0) {
      setShowPasswordEmptyError(true)
      if (!mailError) document.getElementById('login-password').focus()
    }

    if (mailError || password.length === 0) {
      return
    }

    const payload = {
      username: email,
      password: password,
      language: context.language
    }

    const handleError = (error) => {
      console.error(error)
      setError(true)
      context.setLoginMessage(loginMessages.wrongAccessData)
    }

    const data = await fetchData(payload, 'login', context, null, false)

    try {
      if (isUnauthorized(data)) {
        handleError(data.message)
      } else {
        setError(false)
        sessionHandler.setSessionCookie(data.response['session-id'])
        context.setToken(data.response['session-id'])
      }
    } catch (e) {
      handleError(e)
    }
  }

  const handleKeyDown = (e) => {
    setCapslockOn(e.getModifierState && e.getModifierState('CapsLock'))
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  const handleKeyUp = (e) => {
    setCapslockOn(e.getModifierState && e.getModifierState('CapsLock'))
  }

  const t = useTranslate()
  const elektryonUrlLangSuffix = context.language === 'en' ? '?lang=en' : ''
  const forgotPasswordQueryParam = email.length > 0 ? `?mail=${email}` : ''

  return (
    <>
      <div className="header-container" style={{ display: 'flex', alignItems: 'baseline' }}>
        <h1>{t('customerLogin')}</h1>
        <LanguageSelection />
      </div>
      <Form onSubmit={handleSubmit} noValidate>
        {context.loginMessage && <LoginMessage isWarning={error}>{t(context.loginMessage)}</LoginMessage>}

        <FieldWrapper>
          <Label htmlFor="login-mail">{t(FIELD_LABELS.email)}</Label>
          <input
            id="login-mail"
            type="email"
            autoComplete="username"
            disabled={context.maintenanceMode}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            className={showMailError ? 'has-error' : ''}
            onBlur={handleBlur}
          />
          <ErrorMessage visible={showMailError}>{t(mailError?.errorMsg)}</ErrorMessage>
        </FieldWrapper>

        <FieldWrapper>
          <Label htmlFor="login-password">{t(FIELD_LABELS.password)}</Label>

          <div style={{ display: 'grid', gap: 'var(--space-2)' }}>
            <CenteredFlexDiv style={{ position: 'relative' }}>
              <input
                id="login-password"
                disabled={context.maintenanceMode}
                style={{ width: '100%' }}
                type={passwordVisible ? 'text' : 'password'}
                onKeyDown={handleKeyDown}
                value={password}
                onKeyUp={handleKeyUp}
                onChange={(e) => {
                  setPassword(e.target.value)
                  setShowPasswordEmptyError(false)
                }}
                autoComplete="current-password"
                className={showPasswordEmptyError ? 'has-error' : ''}
              />
              <CapslockIndicator {...{ capslockOn }} />
              <PasswordVisibilityToggle {...{ passwordVisible, setPasswordVisible }} />
            </CenteredFlexDiv>
            <ErrorMessage visible={showPasswordEmptyError}>{t(FORM_ERRORS.enterPassword)}</ErrorMessage>
          </div>
        </FieldWrapper>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SmallLink to={getUrlFromPage(PAGES.restorePassword) + forgotPasswordQueryParam}>
            <span>{t('forgotPassword')}</span>
          </SmallLink>
          <SmallLink to={getUrlFromPage(PAGES.signup)}>
            <span>{t('getAccess')}</span>
          </SmallLink>
        </div>
        <ButtonPrimary disabled={context.maintenanceMode} position="login" type="submit" content="login" />
      </Form>
      <SmallExternalLink
        style={{
          display: 'block',
          textAlign: 'center',
          width: '100%',
          marginTop: 'var(--space-4)',
          transform: 'translateY(10px)'
        }}
        href={Config.elektryonUrl + elektryonUrlLangSuffix}>
        <span>{t('elektryonRedirectText')}</span>
      </SmallExternalLink>
    </>
  )
}

export default Login

const isUnauthorized = (response) => [401, 403].includes(response.code)
