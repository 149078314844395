import React, { useState, useEffect, useContext } from 'react'
import PageGroup from '../../components/pageGroup/PageGroup'
import AssessmentNameForm from './subPages/AssessmentNameForm'
import TestVariantForm from './subPages/TestVariantForm'
import SelectParentConfigForm from './subPages/SelectParentConfigForm'
import TimeSettingsForm from './subPages/TimeSettingsForm'
import AdvancedSettingsForm from './subPages/AdvancedSettingsForm'
import SuccessPage from './subPages/SuccessPage'
import CheckInput from './subPages/CheckInput'
import ReportConfigForm from './subPages/ReportConfigForm'

import {
  addAssessment,
  clearReportModules,
  getOptionDescriptions,
  getProcessFromUrlParams,
  getSelectedRowData,
  lazyLoadDimensionData,
  setTraitsTargetGroup,
  validateWizard
} from './addAssessmentUtils'
import { STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { useLocation } from 'react-router-dom'
import { setStatusBar, getTestTypeName, isPotentials } from '../../utils/helper/Helper'
import { PAGES } from '../../utils/constants/pages'
import { addProcess, getInitialCapabilitiesForContacts } from '../addProcessWizard/addProcessUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDefaultDesign, getDesignOptions } from '../../utils/helper/designUtils'
import useRedirect from '../../utils/hooks/useRedirect'

const AddAssessmentWizard = () => {
  const t = useTranslate()
  const redirect = useRedirect()

  const [selectedRows, setSelectedRows] = useState({})
  const [isValid, setIsValid] = useState({})
  const [startedDurationIsValid, setStartedDurationIsValid] = useState(true)
  const [activePageId, setActivePageId] = useState(0)
  const [newData, setNewData] = useState({})
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet

  const designOptions = getDesignOptions(completeDataSet.designOptions)
  const defaultDesign = getDefaultDesign(designOptions)

  const [data, setData] = useState({
    assessmentName: '',
    assessmentDescription: '',
    processUuid: '',
    parentConfig: '',
    configUuid: '',
    configVariant: '',
    configType: '',
    targetGroup: '',
    showValidFrom: false,
    showValidUntil: false,
    showInvitedDuration: false,
    showStartedDuration: false,
    validFrom: '',
    validUntil: '',
    invitedDuration: '',
    startedDuration: '',
    isSupervised: false,
    useChildItems: true,
    inHub: true,
    languageIds: [1],
    reportModulesArray: [],
    jobType: null,
    referenceAssessmentUuid: null,
    assessmentNameIsValid: true,
    createNewProcess: false,
    processName: '',
    newProcessNameIsValid: false,
    processDescription: '',
    useProcessResult: false,
    elektryonTemplate: defaultDesign.value,
    weight: 0,
    contactCapabilities: getInitialCapabilitiesForContacts(completeDataSet.contacts),
    loadedDimensions: [],
    useCameraSupervision: 0,
    tabTracking: true
  })

  useEffect(() => {
    clearReportModules(setData)
    setTraitsTargetGroup(data.parentConfig, setData)
    lazyLoadDimensionData(data, setData, context)
  }, [data.parentConfig])

  useEffect(() => {
    const weight = getWeight()
    setData((prev) => ({
      ...prev,
      weight: weight,
      useProcessResult: weight > 0
    }))
  }, [data.createNewProcess, data.processUuid, data.configType])

  const getWeight = () => {
    if (data.createNewProcess) {
      return isPotentials(data.configType) ? 100 : 0
    } else {
      const process = completeDataSet.processes.find((process) => process.processUuid === data.processUuid)
      const weightableTestCount = process?.relatedAssessments.filter((ass) => ass.producesNumericResult()).length
      return weightableTestCount === 0 && isPotentials(data.configType) ? 100 : 0
    }
  }

  const parentConfigs = completeDataSet.parentConfigs
  const parentConfigsFiltered = parentConfigs
    .filter((pc) => pc.availableConfigs !== null && pc?.availableConfigs.find((config) => config.isLatest))
    .sort((a, b) => a.name.localeCompare(b.name))

  parentConfigsFiltered.forEach((config) => (config.testTypeName = getTestTypeName(config.testType)))

  const location = useLocation()
  const optionDescriptions = getOptionDescriptions(t)

  useEffect(() => {
    getProcessFromUrlParams(location, setData)
  }, [location])

  useEffect(() => {
    const selectedRowData = getSelectedRowData(selectedRows, parentConfigsFiltered)[0]
    setData((prev) => ({
      ...prev,
      parentConfig: selectedRowData,
      languageIds: [selectedRowData?.availableLanguages[0].value]
    }))
  }, [selectedRows])

  useEffect(() => {
    const fieldsToValidate = {
      parentConfig: data.parentConfig,
      processUuid: data.processUuid,
      validFrom: data.validFrom,
      validUntil: data.validUntil,
      assessmentName: data.assessmentName,
      referenceAssessmentUuid: data.referenceAssessmentUuid,
      assessmentNameIsValid: data.assessmentNameIsValid,
      newProcessNameIsValid: data.newProcessNameIsValid,
      createNewProcess: data.createNewProcess,
      testLanguages: data.languageIds
    }
    validateWizard(fieldsToValidate, setIsValid, data.configVariant)
  }, [
    data.parentConfig,
    data.processUuid,
    data.validFrom,
    data.validUntil,
    data.assessmentName,
    data.referenceAssessmentUuid,
    data.configVariant,
    data.assessmentNameIsValid,
    data.newProcessNameIsValid,
    data.createNewProcess,
    data.languageIds.length
  ])

  return (
    <PageGroup
      pages={[
        {
          content: (
            <SelectParentConfigForm
              parentConfigs={parentConfigs}
              parentConfigsFiltered={parentConfigsFiltered}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          ),
          title: 'Test',
          buttonSecondary: <ButtonSecondary content="cancel" onClick={() => redirect(PAGES.assessments)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              onClick={() => setActivePageId(activePageId + 1)}
              disabled={!isValid.SelectParentConfigForm}
            />
          )
        },
        {
          content: <TestVariantForm data={data} setData={setData} />,
          title: t('variant'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              onClick={() => setActivePageId(activePageId + 1)}
              disabled={!isValid.SelectParentConfigForm}
            />
          )
        },
        {
          content: <AssessmentNameForm data={data} setData={setData} optionDescriptions={optionDescriptions} />,
          title: t('testNameAndProcess'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              onClick={() => setActivePageId(activePageId + 1)}
              disabled={!isValid.AssessmentNameForm}
            />
          )
        },
        {
          content: <TimeSettingsForm {...{ data, setData, optionDescriptions, isValid, setStartedDurationIsValid }} />,
          title: t('timePeriod'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              disabled={!isValid.startAndEndDates || !startedDurationIsValid}
              onClick={() => setActivePageId(activePageId + 1)}
            />
          )
        },
        {
          content: <AdvancedSettingsForm data={data} setData={setData} optionDescriptions={optionDescriptions} />,
          title: t('additionalOptions'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              onClick={() => setActivePageId(activePageId + 1)}
              disabled={!isValid.testLanguages}
            />
          )
        },
        {
          content: <ReportConfigForm data={data} setData={setData} />,
          title: t('reportModules'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />
        },
        {
          content: <CheckInput data={data} />,
          title: t('checkInputs'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="createTest"
              onClick={() => {
                setStatusBar({
                  controller: context.statusBarController,
                  type: STATUS_BAR_TYPES.loading,
                  text: 'processingInProgress',
                  setVisible: true
                })
                if (data.createNewProcess) {
                  addProcess(data, context)
                    .then((response) => {
                      const newProcessUuid = response.response.data.process.processUuid
                      addAssessment(data, context, newProcessUuid)
                        .then((responseData) => {
                          setActivePageId(activePageId + 1)
                          setNewData(responseData.response.data)
                          setStatusBar({
                            controller: context.statusBarController,
                            type: STATUS_BAR_TYPES.success,
                            text: 'processAndTestCreated'
                          })
                        })
                        .catch((e) => console.error(e))
                    })
                    .catch((e) => console.error(e))
                } else {
                  addAssessment(data, context)
                    .then((responseData) => {
                      setActivePageId(activePageId + 1)
                      setNewData(responseData.response.data)
                      setStatusBar({
                        controller: context.statusBarController,
                        type: STATUS_BAR_TYPES.success,
                        text: 'testSuccessfullyCreated'
                      })
                    })
                    .catch((e) => console.error(e))
                }
              }}
            />
          )
        },
        {
          content: <SuccessPage newData={newData} />,
          hidePageIndicator: true,
          buttonSecondary: (
            <ButtonSecondary
              content="viewTest"
              onClick={() => {
                const params = [
                  { id: 'processName', value: newData.processes[0].processName },
                  { id: 'assessmentName', value: data.assessmentName }
                ]
                redirect(PAGES.assessments, params)
              }}
            />
          ),
          buttonPrimary: (
            <ButtonPrimary
              content="addParticipants"
              onClick={() => {
                const params = [
                  { id: 'processUuid', value: newData.processes[0].processUuid },
                  { id: 'assessmentUuid', value: newData.assessment.assessmentUuid }
                ]
                redirect(PAGES.newParticipant, params)
              }}
            />
          )
        }
      ]}
      activePageId={activePageId}
    />
  )
}

export default AddAssessmentWizard
