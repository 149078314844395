import { useEffect } from "react"

const useTabTracking = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        document.body.classList.add('using-keyboard')
      }
    }

    const handleClick = () => document.body.classList.remove('using-keyboard')

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('mousedown', handleClick)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('mousedown', handleClick)
    }
  }, [])
}

export default useTabTracking
