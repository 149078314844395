import React from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import styled from 'styled-components'

const CreditCardForm = () => {
  const paymentElementOptions = {
    layout: 'tabs',
    wallets: {
      applePay: 'never',
      googlePay: 'never'
    }
  }
  return (
    <PaymentForm id="payment-form">
      <PaymentElement id="payment-element" options={paymentElementOptions} />
    </PaymentForm>
  )
}

export default CreditCardForm

const PaymentForm = styled.div`
  background: var(--box-background);
  padding: var(--space-5);
  border-radius: var(--bdr-2);
`
