import styled from 'styled-components'
import { PT_STATUS } from '../../utils/constants/constants'

const Badge = (props) => {
  return <Circle {...props} />
}

export default Badge

const getBadgeColor = (props) => {
  switch (props.status) {
    case PT_STATUS.expired:
    case PT_STATUS.deactivated:
      return 'var(--red-400)'
    case PT_STATUS.ready:
      return 'var(--yellow-400)'
    case PT_STATUS.finished:
      return 'var(--green-400)'
    default:
      return 'var(--border-grey)'
  }
}

const Circle = styled.span`
  background-color: ${(props) => getBadgeColor(props)};
  width: 8px;
  height: 8px;
  border-radius: var(--bdr-round);
  display: inline-flex;
`
