import CustomInput from '../customInput/CustomInput'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import { useContext } from 'react'
import { API_DATA } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { getCustomFieldOptions, getCustomFieldContent } from '../../utils/helper/Helper'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'

const ParticipantCustomFieldsTile = ({ participant }) => {
  const context = useContext(AppContext)
  const canEditParticipantCustomField = CapabilitiesHelper.canEditParticipant(participant)

  return (
    <Tile minWidth="200px">
      <TileHeadline content="customFields" />
      <CustomInputList>
        {context.completeDataSet.customFieldDefinitions.map((cfd) => {
          const customFieldContent = getCustomFieldContent(participant, cfd)
          return (
            <CustomInput
              key={cfd.publicKey}
              label={cfd.label}
              content={customFieldContent}
              value={customFieldContent}
              dataKey={cfd.publicKey}
              options={getCustomFieldOptions(cfd.options)}
              type={cfd.type === 'select' ? 'select' : 'input'}
              idValue={participant.pNr}
              apiData={API_DATA.participants}
              disabled={!canEditParticipantCustomField}
              isCustomField
              isClearable
            />
          )
        })}
      </CustomInputList>
    </Tile>
  )
}

export default ParticipantCustomFieldsTile
