import { useEffect } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children, setCoords, coords }) => {
  const mount = document.getElementById('portal-root')
  const el = document.createElement('div')
  el.className = 'portal-container'

  useEffect(() => {
    const placeMenuAboveToggleButton = (menuRect) => {
      setCoords({
        left: coords.left,
        top: coords.top - menuRect.height - 30
      })
    }

    mount.appendChild(el)
    const menuElement = document.getElementsByClassName('Menu')[0]
    const rootElement = document.documentElement
    const menuRect = menuElement ? menuElement.getBoundingClientRect() : null
    const rootRect = rootElement.getBoundingClientRect()

    if (menuRect.bottom > rootRect.height) {
      placeMenuAboveToggleButton(menuRect)
    }

    return () => {
      mount.removeChild(el)
    }
  }, [el, mount, coords, setCoords])

  return createPortal(children, el)
}

export default Portal
