import React, { useContext } from 'react'
import sessionHandler from '../../utils/helper/sessionHandler'
import Config from '../../utils/constants/config'
import {
  TableLink,
  SmallDescription,
  TableContentContainer,
  EntityCountDisplay
} from '../../components/table/tableElements'
import {
  CONTENT_TYPES,
  ENTITIES,
  FIELD_CATEGORIES,
  FIELD_LABELS,
  FILTER_TYPES,
  MODAL_TYPES,
  SELECT_OPTIONS,
  STATUS_BAR_TYPES
} from '../../utils/constants/constants'
import { BulletList, ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import {
  getArchivedClassName,
  getEntityLabel,
  getTestTypeName,
  getTableLinkParams,
  getOptionByValue,
  downloadFileFromUrl,
  getPtCount,
  getParticipantCount,
  setStatusBar
} from '../../utils/helper/Helper'

import { PAGES } from '../../utils/constants/pages'
import { addProcess } from '../../wizards/addProcessWizard/addProcessUtils'
import { addAssessment } from '../../wizards/addAssessmentWizard/addAssessmentUtils'
import { getFilterLabels } from '../../components/filter/filterUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import {
  getActionsCol,
  getCreatedByCol,
  getDateCreatedCol,
  getSpacerCol,
  getVisibilityCol
} from '../../components/table/tableCols/tableCols'
import { FilterContext } from '../../utils/context/FilterContext'
import useRedirect from '../../utils/hooks/useRedirect'

export const downloadProcessResultCSV = (processUuid) => {
  const session = sessionHandler.getSessionId()
  const url = `${Config.baseUrl}/${Config.prefix}/results/process_participants_results_csv/${processUuid}?perseo-session=${session}`
  downloadFileFromUrl(url)
}

export const getProcessTableCols = () => [
  {
    Header: 'freeTextFilter',
    id: 'freeText',
    accessor: (row) => row.processName + row.relatedAssessments.map((a) => a.assessmentName).join(),
    filterOptions: { type: FILTER_TYPES.textInput },
    canExport: false,
    show: false
  },
  {
    Header: 'process',
    id: 'processName',
    accessor: 'processName',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, label: 'processName' },
    canExport: true,
    Cell: ({ row }) => {
      return (
        <TableContentContainer>
          <span className={row.original.isArchived() ? 'archived' : ''}>{row.original.processName}</span>
          {row.original.processDescription && <SmallDescription>{row.original.processDescription}</SmallDescription>}
        </TableContentContainer>
      )
    }
  },
  {
    Header: 'relatedTests',
    id: 'assessmentName',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'relatedTest' },
    accessor: (row) => row.relatedAssessments.map((ass) => ass.assessmentName),
    show: true,
    Cell: ({ row }) => {
      const relatedAssessments = [].concat(row.original.relatedAssessments)
      const sortedAssessments = getSortedAssessments(relatedAssessments)
      const t = useTranslate()
      const redirect = useRedirect()
      const { addLinkFilters } = useContext(FilterContext)

      return (
        <BulletList>
          {sortedAssessments.map((ass, i) => {
            const className = getArchivedClassName(ass)
            const ptCount = getPtCount([ass])
            const testTypeName = t(getTestTypeName(ass.relatedConfig.configType))

            const assessmentFilter = {
              field: { value: 'assessmentName' },
              value: { value: ass.assessmentName },
              tableName: 'assessments'
            }

            const processFilter = {
              field: { value: 'processName' },
              value: { value: row.original.processName },
              tableName: 'assessments'
            }

            const assessmentFilterForPtTable = {
              field: { value: 'assessmentName' },
              value: { value: ass.assessmentName },
              tableName: 'pts'
            }

            const processFilterForPtTable = {
              field: { value: 'processName' },
              value: { value: row.original.processName },
              tableName: 'pts'
            }

            return (
              <li
                style={{ listStyle: 'none' }}
                className={className}
                key={row.original.processName + '_' + ass.assessmentName + i}>
                <TableLink
                  onClick={() => {
                    addLinkFilters([assessmentFilter, processFilter])
                    redirect(PAGES.assessments)
                  }}>
                  {ass.assessmentName}
                </TableLink>
                <SmallDescription style={{ whiteSpace: 'unset' }}>
                  {t(testTypeName)} {t('with')}{' '}
                  <TableLink
                    onClick={() => {
                      addLinkFilters([assessmentFilterForPtTable, processFilterForPtTable])
                      redirect(PAGES.pts)
                    }}>
                    <EntityCountDisplay visible={ptCount.visible} archived={ptCount.archived} />{' '}
                    <span>{t(getEntityLabel(ENTITIES.pts, ptCount.visible + ptCount.archived))}</span>
                  </TableLink>
                </SmallDescription>
              </li>
            )
          })}
        </BulletList>
      )
    },
    disableSortBy: true
  },
  {
    Header: 'capabilities',
    id: 'capabilities',
    accessor: 'capabilities',
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      dataLabels: getFilterLabels(SELECT_OPTIONS.userCapabilities),
      category: FIELD_CATEGORIES.process
    },
    show: false,
    Cell: ({ row }) => {
      const t = useTranslate()
      const capabilities = getOptionByValue(t(SELECT_OPTIONS.userCapabilities), row.original.capabilities).label || ''
      return <TableContentContainer>{capabilities}</TableContentContainer>
    }
  },
  {
    Header: FIELD_LABELS.testDesign,
    id: 'elektryonTemplate',
    accessor: (row) => row.designOptions.find((d) => d.id === row.elektryonTemplate)?.title || '',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process },
    canExport: true,
    show: false,
    Cell: ({ value }) => <TableContentContainer>{value}</TableContentContainer>
  },
  {
    Header: 'participantCount',
    id: 'participantCount',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, contentType: CONTENT_TYPES.number },
    canExport: true,
    accessor: (row) => {
      const participantCount = getParticipantCount(row.relatedAssessments)
      return participantCount.archived + participantCount.visible
    },
    textAlign: 'right',
    Cell: ({ row }) => {
      const redirect = useRedirect()
      const { addLinkFilters } = useContext(FilterContext)
      const process = row.original
      const participantCount = getParticipantCount(row.original.relatedAssessments)
      const tableLinkParams = getTableLinkParams(process, 'processName', 'participants')
      return (
        <div style={{ textAlign: 'right' }}>
          <TableLink
            onClick={() => {
              addLinkFilters(tableLinkParams.filterParams)
              redirect(PAGES.participants)
            }}>
            <EntityCountDisplay visible={participantCount.visible} archived={participantCount.archived} />
          </TableLink>
        </div>
      )
    }
  },
  {
    Header: 'ptCount',
    id: 'ptCount',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, contentType: CONTENT_TYPES.number },
    canExport: true,
    accessor: (row) => {
      const ptCount = getPtCount(row.relatedAssessments)
      return ptCount.archived + ptCount.visible
    },
    textAlign: 'right',
    Cell: ({ row }) => {
      const redirect = useRedirect()
      const { addLinkFilters } = useContext(FilterContext)
      const process = row.original
      const ptCount = getPtCount(process.relatedAssessments)
      const tableLinkParams = getTableLinkParams(process, 'processName', 'pts')

      return (
        <div style={{ textAlign: 'right' }}>
          <TableLink
            onClick={() => {
              addLinkFilters(tableLinkParams.filterParams)
              redirect(PAGES.pts)
            }}>
            <EntityCountDisplay visible={ptCount.visible} archived={ptCount.archived} />
          </TableLink>
        </div>
      )
    }
  },
  getCreatedByCol('processCreatedBy', FIELD_CATEGORIES.process),
  getDateCreatedCol('processCreated', FIELD_CATEGORIES.process),
  getVisibilityCol(FIELD_CATEGORIES.process),
  getSpacerCol(),
  getActionsCol()
]

const getLatestConfig = (parentConfigs, ass) => {
  const relatedParentConfig = parentConfigs.find((pc) => pc.uuid === ass.relatedConfig.parentConfig.uuid)

  if (relatedParentConfig) {
    const latestConfig = relatedParentConfig.availableConfigs.find(
      (config) => config.isLatest && config.configVariant === ass.relatedConfig.configVariant
    )
    return latestConfig
  }
  return ass.relatedConfig
}

export const duplicateProcessAndTests = async (oldProcess, context) => {
  const oldCapabilities = context.completeDataSet.contactProcesses.filter(
    (cp) => cp.processUuid === oldProcess.processUuid
  )

  const processData = {
    processName: oldProcess.processName + ' Kopie',
    processDescription: oldProcess.processDescription,
    elektryonTemplate: oldProcess.elektryonTemplate,
    contactCapabilities: oldCapabilities,
    useProcessResult: oldProcess.useProcessResult
  }

  await waitForUpdateModalAnswers(oldProcess, context)
  const reponseData = await addProcess(processData, context)

  const newProcessUuid = reponseData.response.data.process.processUuid

  const assessmentData = []
  oldProcess.relatedAssessments.forEach((ass) => {
    if (ass.isVisible()) {
      const tmp = {
        assessmentName: ass.assessmentName.trim(),
        assessmentUuid: ass.assessmentUuid,
        assessmentDescription: ass.assessmentDescription ? ass.assessmentDescription.trim() : '',
        processUuid: newProcessUuid,
        configUuid: ass.useLatest
          ? getLatestConfig(context.completeDataSet.parentConfigs, ass).configUuid
          : ass.configUuid,
        invitedDuration: ass.invitedDuration ? ass.invitedDuration : 0,
        startedDuration: ass.startedDuration ? ass.startedDuration : 0,
        isSupervised: ass.isSupervised,
        useChildItems: ass.useChildItems,
        languageIds: ass.languageIds,
        inHub: ass.inHub,
        reportModulesAndro: ass.reportModules,
        jobType: ass.jobType,
        targetGroup: ass.targetGroup,
        referenceAssessmentUuid: ass.referenceAssessmentUuid,
        weight: ass.weight,
        normNr: ass.normNr,
        dimLogicNr: ass.dimLogicNr
      }
      assessmentData.push(tmp)
    }
  })

  const sortedAssessments = getSortedAssessments(assessmentData)

  const loopOverAssessments = async (i) => {
    if (i >= sortedAssessments.length) {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: 'processSuccessfullyDuplicated'
      })
      return
    }
    const response = await addAssessment(sortedAssessments[i], context)
    try {
      const hasNext = sortedAssessments[i + 1]
      if (hasNext) {
        const nextIsCrosstest = sortedAssessments[i + 1].referenceAssessmentUuid
        const newAssessmentUuid = response.response.data.assessment.assessmentUuid
        if (nextIsCrosstest) {
          sortedAssessments[i + 1].referenceAssessmentUuid = newAssessmentUuid
        }
      }
    } catch (e) {
      console.error(e)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: 'errorOccured'
      })
      return
    }
    loopOverAssessments(i + 1)
  }

  loopOverAssessments(0)
}

const waitForUpdateModalAnswers = (oldProcess, context) => {
  const usingLatestVersions = oldProcess.relatedAssessments.map((ass) => {
    const currentConfig = ass.relatedConfig
    const latestConfig = getLatestConfig(context.completeDataSet.parentConfigs, ass)
    const isLatest = isLatestConfigVersion(currentConfig, latestConfig)
    return isLatest
  })

  const updateCount = usingLatestVersions.filter((elem) => elem === false).length
  const testLabel = getEntityLabel(ENTITIES.assessments, updateCount)
  const updateText = getUpdateText(updateCount)

  if (usingLatestVersions.every((elem) => elem)) {
    return new Promise((resolve) => resolve(true))
  } else {
    return new Promise((resolve) => {
      context.setModalProps({
        headline: `${testLabel} aktualisieren?`,
        content: updateText,
        buttonSecondary: (
          <ButtonSecondary
            modalButton
            content="Beibehalten"
            onClick={() => {
              oldProcess.relatedAssessments.forEach((ass) => (ass.useLatest = false))
              context.setModalOpen(false)
              resolve(true)
            }}
          />
        ),
        buttonPrimary: (
          <ButtonPrimary
            modalButton
            content="Aktualisieren"
            onClick={() => {
              oldProcess.relatedAssessments.forEach((ass) => (ass.useLatest = true))
              context.setModalOpen(false)
              resolve(true)
            }}
          />
        ),
        icon: 'icon-alert modal',
        type: MODAL_TYPES.alert
      })
      context.setModalOpen(true)
    })
  }
}

const getUpdateText = (count) => {
  return count === 1
    ? 'Für dieses Verfahren liegt ein aktualisierter Test vor. Möchten Sie den bisherigen Test beibehalten oder aktualisieren?'
    : 'Für dieses Verfahren liegen aktualisierte Tests vor. Möchten Sie die bisherigen Tests beibehalten oder aktualisieren?'
}

const isLatestConfigVersion = (currentConfig, latestConfig) => {
  if (!currentConfig || !latestConfig) return true
  return parseFloat(currentConfig.configVersion) === parseFloat(latestConfig.configVersion)
}

const getSortedAssessments = (assessments) => {
  let sortedAssessments = []
  assessments.forEach((ass) => {
    if (!ass.referenceAssessmentUuid) {
      sortedAssessments.push(ass)
      const uuid = ass.assessmentUuid
      const refTest = assessments.find((a) => a.referenceAssessmentUuid === uuid)
      if (refTest) {
        sortedAssessments.push(refTest)
      }
    }
  })
  return sortedAssessments
}
