import React from 'react'
import { ContentContainer } from '../components/table/tableElements'
import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import UserManual from '../components/userManual/UserManual'
import styled from 'styled-components'
import TableOfContents from '../components/tableOfContents/tableOfContents'

const Help = () => (
  <OuterContainer>
    <TocContainer id="toc-container">
      <TableOfContents />
    </TocContainer>
    <ScrollContainer type="main" id="user-manual-container">
      <ContentContainer>
        <UserManual />
      </ContentContainer>
    </ScrollContainer>
  </OuterContainer>
)

export default Help

const OuterContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const TocContainer = styled.div`
  position: sticky;
  top: 0;
  width: 250px;
  flex-shrink: 0;
  background-color: var(--box-background);
  border-right: 1px solid var(--header-border-color);
  overflow: scroll;
  @media screen and (max-width: 768px) {
    width: 100%;
    border-right: none;
    box-shadow: var(--sh-1);
  }
`
