import React, { useContext } from 'react'
import sessionHandler from '../../utils/helper/sessionHandler'
import CustomerArea from '../customerArea/CustomerArea'
import Modal from '../modal/Modal'
import { AppContext } from '../../utils/context/AppContext'
import Config from '../../utils/constants/config'
import LoginArea from '../../pages/loginArea/LoginArea'
import useDocumentTitle from '../../utils/hooks/useDocumentTitle'
import OnboardingTour from '../onboardingTour/OnboardingTour'
import useTabTracking from '../../utils/hooks/useTabTracking'
import useContactSettings from '../../utils/hooks/useContactSettings'
import useMaintenanceMode from '../../utils/hooks/useMaintenanceMode'
import { FilterContextWrapper } from '../../utils/context/FilterContext'
import { disableMouseWheelOnNumberInput } from '../../utils/helper/Helper'

const App = () => {
  const context = useContext(AppContext)
  const sessionId = sessionHandler.getSessionId()

  let isLoading = false
  useDocumentTitle()

  if (!context.token && sessionId) {
    isLoading = sessionHandler.checkSession(context)
  }

  useContactSettings()
  useTabTracking()
  useMaintenanceMode()
  disableMouseWheelOnNumberInput()

  return (
    <FilterContextWrapper>
      <Modal />
      <OnboardingTour />
      <div id="portal-root" className="portal-root" />

      {!context.token && !isLoading && <LoginArea />}
      {context.token && !isLoading && <CustomerArea Config={Config} />}
    </FilterContextWrapper>
  )
}

export default App
