import Select from 'react-select'
import { SALUTATION_TYPES } from '../../../utils/constants/constants'
import { selectStyles } from '../../../utils/elements/miscElements'
import { getOptionByValue, setState } from '../../../utils/helper/Helper'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'

const SalutationSelection = ({ data, setData }) => {
  const t = useTranslate()
  const salutationOptions = getSalutationOptions(t)
  return (
    <div style={{ maxWidth: '270px' }}>
      <Select
        options={salutationOptions}
        onChange={(selectedOption) => changeSalutation(selectedOption, data, setData)}
        styles={selectStyles}
        value={getOptionByValue(salutationOptions, data.salutationType)}
        isSearchable={false}
      />
    </div>
  )
}

export default SalutationSelection

const changeSalutation = (selectedOption, data, setData) => {
  setState(setData, 'salutationType', selectedOption.value)
  const optionChanged = data.salutationType !== selectedOption.value
  if (optionChanged) {
    setState(setData, 'isDirty', true)
  }
}

const getSalutationOptions = (t) => {
  return [
    {
      label: t('politeLastName'),
      value: SALUTATION_TYPES.politeLastName
    },
    {
      label: t('casualLastName'),
      value: SALUTATION_TYPES.casualLastName
    },
    {
      label: t('casualFirstName'),
      value: SALUTATION_TYPES.casualFirstName
    },
    {
      label: t('helloFirstName'),
      value: SALUTATION_TYPES.helloFirstName
    },
    {
      label: t('none'),
      value: 'none'
    }
  ]
}

export const TemplatePlaceholder = styled.span`
  text-transform: uppercase;
  &:before {
    content: '{';
  }
  &:after {
    content: '}';
  }
`

export const getSalutation = (participant, type, t) => {
  switch (type) {
    case SALUTATION_TYPES.politeLastName:
      return getLastNameSalutation(participant, 'polite', t)
    case SALUTATION_TYPES.casualLastName:
      return getLastNameSalutation(participant, 'casual', t)
    case SALUTATION_TYPES.casualFirstName:
      return getCasualFirstNameSalutation(participant, t)
    case SALUTATION_TYPES.helloFirstName:
      return getHelloFirstNameSalutation(participant, t)
    default:
      return ''
  }
}

const salutations = {
  m: {
    casual: 'salutationCasualMale',
    polite: 'salutationPoliteMale'
  },
  f: {
    casual: 'salutationCasualFemale',
    polite: 'salutationPoliteFemale'
  },
  d: {
    casual: 'salutationCasualDiverse',
    polite: 'salutationPoliteDiverse'
  },
  n: {
    casual: 'salutationCasualDiverse',
    polite: 'salutationPoliteDiverse'
  }
}

const getDefaultName = (participant, t) => {
  switch (participant.pGender) {
    case 'm':
      return t('participantMale')
    case 'f':
      return t('participantFemale')
    default:
      return t('participantDiverse')
  }
}

const getPrefixedLastName = (participant, t) => {
  if (!participant.pLastName) {
    return ''
  }
  switch (participant.pGender) {
    case 'm':
      return `${t('mrSalutation')} ${participant.pLastName}`
    case 'f':
      return `${t('mrsSalutation')} ${participant.pLastName}`
    default:
      return `${t('mrSalutation')}/${t('mrsSalutation')} ${participant.pLastName}`
  }
}

const getLastNameSalutation = (participant, type, t) => {
  const preFixedLastName = getPrefixedLastName(participant, t)
  const salutation = salutations[participant.pGender || 'd'][type]
  const nameDefault = getDefaultName(participant, t)
  return `${t(salutation)} ${preFixedLastName || nameDefault},`
}

const getCasualFirstNameSalutation = (participant, t) => {
  const salutation = salutations[participant.pGender || 'd'].casual
  const nameDefault = getDefaultName(participant, t)
  return `${t(salutation)} ${participant.pFirstName || nameDefault},`
}

const getHelloFirstNameSalutation = (participant, t) => {
  if (participant.pFirstName) {
    return `${t('hello')} ${participant.pFirstName},`
  }
  return `${t('hello')},`
}
