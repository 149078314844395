import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import {
  PageHeadline,
  SuccessMessageContainer,
  SuccessMessageHeadline,
  SuccessMessageText,
  SuccessMessageIcon
} from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'

const SendEmailSuccessPage = ({ response }) => {
  const t = useTranslate()
  const successCount = response.data.stats.emailSuccessCount
  const mailCount = response.data.stats.emailCount
  const successMessage = getSuccessMessage(successCount, mailCount)

  return (
    <>
      <PageHeadline style={{ visibility: 'hidden' }}>{t('emailsSent')}</PageHeadline>
      <ScrollContainer>
        <SuccessMessageContainer>
          <SuccessMessageContainer>
            <SuccessMessageHeadline>
              {successCount}/{mailCount} {t('emailsSent')}
            </SuccessMessageHeadline>
            <SuccessMessageText style={{ textAlign: 'center' }}>{t(successMessage)}</SuccessMessageText>
            <SuccessMessageIcon style={{ borderRadius: 0 }} className="svg-icon icon-email" />
          </SuccessMessageContainer>
        </SuccessMessageContainer>
      </ScrollContainer>
    </>
  )
}

export default SendEmailSuccessPage

const getSuccessMessage = (successCount, mailCount) => {
  if (successCount === mailCount) return 'wishingYouGoodResults'
  return 'notAllEmailsSent'
}
