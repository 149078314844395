import {
  PAYPAL_SANDBOX_CLIENT_ID,
  PAYPAL_LIVE_CLIENT_ID,
  STRIPE_API_TEST_KEY,
  STRIPE_API_LIVE_KEY
} from '../constants/constants'

const envConfigs = {
  dev: {
    publicLabel: 'DEV',
    baseUrl: 'https://dev-andromeda.perseo.hr',
    prefix: 'api',
    version: 'v2',
    suffix: 'customer_area',
    elektryonUrl: 'https://dev-app.perseo.hr',
    showLog: true,
    paypal_client_id: PAYPAL_SANDBOX_CLIENT_ID,
    stripe_key: STRIPE_API_TEST_KEY
  },
  dev2: {
    publicLabel: 'DEV2',
    baseUrl: 'https://dev2-andromeda.perseo.hr',
    prefix: 'api',
    version: 'v2',
    suffix: 'customer_area',
    elektryonUrl: 'https://dev2-app.perseo.hr',
    showLog: true,
    paypal_client_id: PAYPAL_SANDBOX_CLIENT_ID,
    stripe_key: STRIPE_API_TEST_KEY
  },
  staging: {
    publicLabel: 'STAGING',
    baseUrl: 'https://staging-andromeda.perseo.hr',
    prefix: 'api',
    version: 'v2',
    suffix: 'customer_area',
    elektryonUrl: 'https://staging-app.perseo.hr',
    showLog: true,
    paypal_client_id: PAYPAL_SANDBOX_CLIENT_ID,
    stripe_key: STRIPE_API_TEST_KEY
  },
  live: {
    publicLabel: '',
    baseUrl: 'https://andromeda.perseo.hr',
    prefix: 'api',
    version: 'v2',
    suffix: 'customer_area',
    elektryonUrl: 'https://app.perseo-assessment.de',
    showLog: false,
    paypal_client_id: PAYPAL_LIVE_CLIENT_ID,
    stripe_key: STRIPE_API_LIVE_KEY
  }
}

const getConfig = (stage) => {
  switch (stage) {
    case 'live':
      return envConfigs.live
    case 'staging':
      return envConfigs.staging
    case 'dev2':
      return envConfigs.dev2
    case 'dev':
    default:
      return envConfigs.dev
  }
}

const Config = getConfig(process.env.REACT_APP_STAGE)

export default Config
