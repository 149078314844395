import { API_DATA, FORM_ERRORS, MAX_CHARS, USER_CAPABILITIES } from '../../utils/constants/constants'
import { allContactsAreAdmins, fetchData, hasSpecialChars, updateEntities } from '../../utils/helper/Helper'

export const addProcess = async (data, context) => {
  const payload = {
    processName: data.processName.trim(),
    processDescription: data.processDescription.trim(),
    elektryonTemplate: data.elektryonTemplate,
    capabilities: data.contactCapabilities,
    useProcessResult: data.useProcessResult
  }

  const responseData = await fetchData(payload, API_DATA.processes.endPointAdd, context, 'processAdditionFailed')

  try {
    console.log('responseData add process', responseData)
    const updates = {
      contactProcesses: responseData.response.data.contactProcesses,
      processes: [responseData.response.data.process]
    }
    updateEntities(updates, context)
    return responseData
  } catch (e) {
    console.error(e)
  }
}

export const getInitialCapabilitiesForContacts = (contacts) => {
  return contacts
    .filter((contact) => contact.isVisible())
    .map((contact) => ({
      contactUuid: contact.contactUuid,
      capabilities: USER_CAPABILITIES.edit
    }))
}

export const getFilteredPages = (pages, contacts) => {
  if (allContactsAreAdmins(contacts)) {
    pages.processNameAndDesign.hidePageIndicator = true
    pages.processNameAndDesign.buttonPrimary = pages.checkInput.buttonPrimary
    return [pages.processNameAndDesign, pages.addProcessSuccessPage]
  }
  return [pages.processNameAndDesign, pages.setCapabilitiesForProcess, pages.checkInput, pages.addProcessSuccessPage]
}

export const validateProcessNameInput = (input, setErrorMsg, setSubmitButtonDisabled, t) => {
  const errorMessages = {
    processNameEmpty: t(FORM_ERRORS.enterProcessName),
    noSpecialChars: t(FORM_ERRORS.forbiddenCharacter),
    tooManyChars: t('maxCharsPolite', MAX_CHARS.processName)
  }

  if (input.trim().length === 0) {
    setErrorMsg(errorMessages.processNameEmpty)
    if (setSubmitButtonDisabled) setSubmitButtonDisabled(true)
    return false
  }

  if (input.length > MAX_CHARS.processName) {
    setErrorMsg(errorMessages.tooManyChars)
    if (setSubmitButtonDisabled) setSubmitButtonDisabled(true)
    return false
  }

  if (hasSpecialChars(input)) {
    setErrorMsg(errorMessages.noSpecialChars)
    if (setSubmitButtonDisabled) setSubmitButtonDisabled(true)
    return false
  }

  setErrorMsg('')
  if (setSubmitButtonDisabled) setSubmitButtonDisabled(false)
  return true
}
