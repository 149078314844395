import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { getLoggedInContact } from '../../utils/helper/Helper'
import './notification.scss'
import { displayNotification, getNotificationToDisplay, saveNotificationAsRead } from './startupNotificationUtils'
import useRedirect from '../../utils/hooks/useRedirect'

const StartupNotification = () => {
  const redirect = useRedirect()
  const [activePageId, setActivePageId] = useState(0)
  const [notificationIsOpen, setNotificationIsOpen] = useState(false)

  const context = useContext(AppContext)
  const contact = getLoggedInContact(context.completeDataSet)
  const isFirstLogin = !contact.lastLogin

  const notifications = context.completeDataSet.notifications
  const notificationToDisplay = getNotificationToDisplay(notifications, isFirstLogin)

  const props = {
    notification: notificationToDisplay,
    context: context,
    activePageId: activePageId,
    setActivePageId: setActivePageId,
    redirect: redirect
  }

  useEffect(() => {
    if (notificationToDisplay) {
      displayNotification(props)
      setNotificationIsOpen(true)
    }
  }, [activePageId])

  useEffect(() => {
    if (notificationIsOpen && context.modalOpen === false) {
      saveNotificationAsRead(notificationToDisplay, context)
      setNotificationIsOpen(false)
    }
  }, [context.modalOpen])

  return <></>
}

export default StartupNotification
