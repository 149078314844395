import { useContext } from 'react'
import CreditsFaq from '../../pages/creditsFaq'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'
import { Tile, TileHeadline } from '../table/tableElements'
import { AgbLink } from '../../pages/agb'

const BillingHintTile = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  return (
    <Tile width="300px">
      <TileHeadline content="importantNote" />
      <>
        <p className="small" style={{ marginTop: 0 }}>
          {t('creditSystemInfo')}
        </p>
        <p className="small">
          {t('creditsFAQ')}{' '}
          <button
            className="textlink"
            onClick={(e) => {
              e.preventDefault()
              context.setModalProps({
                headline: 'Credits FAQ',
                content: <CreditsFaq />,
                showCloseButton: true,
                hideFooter: true
              })
              context.setModalOpen(true)
            }}>
            Credits FAQ
          </button>
          .
        </p>
        <AgbLink />
      </>
    </Tile>
  )
}

export default BillingHintTile
