import styled from 'styled-components'
import Select from 'react-select'
import { selectStyles } from '../../utils/elements/miscElements'
import { FIELD_LABELS, NO_CHANGE_LABEL, SELECT_OPTIONS } from '../../utils/constants/constants'
import { setState } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import { getVisibilityCol } from './tableCols/tableCols'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

export const TableLink = styled.span`
  z-index: 99;
  line-height: var(--lh-2);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const AddButton = styled.button`
  font-weight: var(--fw-2);
  font-size: var(--fs-3);
  padding: var(--space-3) var(--space-4);
  border-radius: var(--bdr-2);
  background-color: ${(props) => (props.disabled ? 'var(--button-disabled-bg-color)' : 'var(--add-button-bg-color)')};
  color: #fff;
  white-space: nowrap;
  &:hover {
    background-color: var(--green-600);
  }
`

const TileHeadlineH3 = styled.h3`
  font-size: var(--fs-4);
  font-weight: 600;
  margin-top: 0;
  border-bottom: 2px solid var(--thick-underline-color);
  padding-bottom: var(--space-3);
  margin-bottom: var(--space-3);
  width: 100%;
`

export const TileHeadline = ({ content }) => {
  const t = useTranslate()
  return <TileHeadlineH3>{t(content)}</TileHeadlineH3>
}

export const BaseInfo = styled.li`
  padding: 0;
  display: grid;
  align-items: baseline;
  grid-template-columns: ${(props) => getGridTemplateCols(props)};
  @media (max-width: 900px) {
    grid-template-columns: ${(props) => (props.position === 'tab' ? '' : '1fr 1fr')};
  }
`
const getGridTemplateCols = (props) => {
  if (props.horizontal) return '1fr 1fr 20px'
  if (props.position === 'addAssessmentWizard') return '150px 1fr'
  return ''
}

export const InfoContent = styled.span`
  font-size: var(--fs-3);
  line-height: var(--lh-2);
`

export const ConfigLabel = styled.span`
  font-weight: ${(props) => (props.position === 'addAssessmentWizard' ? '500' : '')};
  font-size: ${(props) => (props.position === 'addAssessmentWizard' ? 'var(--fs-3)' : '')};
`

export const ContentContainer = styled.div`
  margin: var(--inset) var(--inset) 0 var(--inset);
`

export const Tile = styled.div.attrs({
  className: 'tile'
})`
  width: ${(props) => props.width || 'auto'};
  max-width: ${(props) => props.maxWidth || ''};
  min-width: ${(props) => props.minWidth || '150px'};
`

export const TileContainer = styled.div`
  display: flex;
  gap: var(--space-7);
  flex-wrap: wrap;
`

export const capabilitiesColumn = {
  Header: 'permissions',
  disableSortBy: true,
  style: { minWidth: '200px' },
  Cell: ({ row }) => {
    const t = useTranslate()
    const handleCapabilitiesChange = (row, selectedOption) => {
      const tmp = row.stateData
      const value = selectedOption ? selectedOption.value : null

      tmp[row.entity].find((cap) => cap[row.key] === row[row.key]).capabilities = value
      setState(row.setData, row.entity, tmp[row.entity])
    }

    return (
      <>
        {row.original.selectDisabled ? (
          <span>Editor</span>
        ) : (
          <Select
            options={t(SELECT_OPTIONS.userCapabilities)}
            onChange={(selectedOption) => handleCapabilitiesChange(row.original, selectedOption)}
            value={t(SELECT_OPTIONS.userCapabilities).find((option) => option.value === row.original.newCapabilities)}
            styles={selectStyles}
            placeholder={t(NO_CHANGE_LABEL)}
            isClearable={row.original.entity === 'contacts'}
            menuPortalTarget={document.body}
            isSearchable={false}
          />
        )}
      </>
    )
  }
}

export const capabilitiesPreviewColumn = {
  Header: 'permissionsPreview',
  accessor: 'capabilitiesPreview'
}

export const getContactCols = (t) => [
  {
    Header: FIELD_LABELS.email,
    accessor: 'contactEmail',
    Cell: ({ value }) => <span style={{ wordWrap: 'break-word' }}>{value}</span>
  },
  {
    Header: FIELD_LABELS.lastName,
    accessor: 'contactLastName'
  },
  {
    Header: FIELD_LABELS.firstName,
    accessor: 'contactFirstName'
  },
  {
    Header: FIELD_LABELS.role,
    accessor: (row) => (row.contactIsAdmin ? 'Administrator' : t('user'))
  },
  getVisibilityCol()
]

export const SmallDescription = styled.div.attrs({ className: 'text-secondary' })`
  hyphens: auto;
  line-height: var(--lh-2);
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`

export const RowActionsToggleButton = styled.button`
  background-color: ${(props) =>
    props.contextMenuVisible ? `var( --row-action-button-active-bg-color) !important` : 'transparent'};
  z-index: ${(props) => (props.contextMenuVisible ? 9999 : 1)};
  position: relative;
  user-select: none;
  width: 30px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color var(--hover-duration);
  border-radius: var(--bdr-2);
  padding: 0;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.contextMenuVisible ? 'var( --row-action-button-active-bg-color)' : 'var(--row-action-button-hover-color)'};
    transition: background-color var(--hover-duration);
  }
`

export const RowActionsToggleButtonIcon = ({ contextMenuVisible, id }) => (
  <span
    className="svg-icon icon-ellipsis toggle-button-icon"
    style={{
      backgroundColor: contextMenuVisible ? 'var(--row-action-button-icon-active)' : 'var(--row-action-button-icon)',
      width: '13px',
      pointerEvents: 'none',
      transform: 'scale(1.2)'
    }}
    id={id}
  />
)

export const TableContentContainer = styled.div.attrs({
  className: 'table-content-container'
})`
  position: relative;
  z-index: 1;
  display: inline;
  * {
    line-height: var(--lh-2);
  }
  &:hover {
    cursor: text;
  }
`

export const CustomTableCheckbox = styled.div.attrs(() => ({
  tabIndex: 0
}))`
  border-radius: ${(props) => (props.type === 'radio' ? 'var(--bdr-round)' : 'var(--bdr-2)')};
  background-color: ${(props) => getCustomTableCheckboxBgColor(props)};
  border-width: ${(props) => (props.type === 'radio' ? '1.5px' : '1px')};
  border-style: solid;
  border-color: ${(props) => getCustomTableCheckboxBorderColor(props)};
  transition: background-color var(--hover-duration), box-shadow var(--hover-duration);
  width: 18px;
  height: 18px;
  justify-self: center;
  &:hover {
    cursor: pointer;
  }
  box-shadow: ${(props) => (props.disabled ? 'none !important' : 'inherit')};
`
export const getCustomTableCheckboxBgColor = (props) => {
  if (props.disabled) {
    return 'transparent'
  }
  if (props.type === 'radio') {
    return 'var(--checkbox-bg-color)'
  }
  if (props.checked || props.indeterminate) {
    return 'var(--active-checkbox-bg-color)'
  }
  return 'var(--input-bg-color)'
}

export const getCustomTableCheckboxBorderColor = (props) => {
  if (props.disabled) {
    return 'var(--checkbox-border-color)'
  }
  if (props.checked || props.indeterminate) {
    return 'var(--active-checkbox-border-color)'
  }
  return 'var(--checkbox-border-color)'
}

export const CustomInputList = styled.ul`
  padding: 0;
  display: grid;
  gap: var(--space-4);
  margin-top: var(--space-3);
  li {
    list-style-type: none;
  }
`

export const EntityCountDisplay = ({ visible, archived }) => {
  const { showArchivedItems } = useContext(AppContext)

  if (visible === 0 && archived > 0 && showArchivedItems) {
    return <span style={{ color: 'var(--text-color-secondary)' }}>{archived}</span>
  }
  return (
    <>
      <span>{visible}</span>{' '}
      {archived > 0 && showArchivedItems && <span style={{ color: 'var(--text-color-secondary)' }}>({archived})</span>}
    </>
  )
}
