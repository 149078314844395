import TabGroup from '../../components/tabGroup/TabGroup'
import ActionsTab from '../../components/tabs/ActionsTab'
import ParticipantSettingsTab from '../../components/tabs/ParticipantSettingsTab'
import { ENTITIES } from '../../utils/constants/constants'
import { getPtAccessDataTab, getPtTimingAndSettingsTab, getPtResultTab } from './ptUtils'

const PtRowSubComponent = (row) => {
  const pt = row.original
  const ptTimingAndSettingsTab = getPtTimingAndSettingsTab(pt)
  const PtAccessDataTab = getPtAccessDataTab(pt)
  const participantTab = {
    label: 'participant',
    id: 'participantInfos',
    content: <ParticipantSettingsTab participant={pt.relatedParticipant} />
  }

  const actionsTab = {
    label: 'actions',
    id: 'actions',
    content: <ActionsTab row={row.original} entity={ENTITIES.pts} />
  }

  const tabs = [ptTimingAndSettingsTab, PtAccessDataTab, participantTab, actionsTab]

  if (pt.hasResult()) {
    const ptResultTab = getPtResultTab(pt)
    tabs.unshift(ptResultTab)
  }

  return <TabGroup tabs={tabs} />
}

export default PtRowSubComponent
