import { CUSTOMER_VISIBILITY_STATUS } from '../utils/constants/constants'

export class MainEntity {
  isArchived() {
    return this?.visibility === CUSTOMER_VISIBILITY_STATUS.archived
  }
  isVisible() {
    return this?.visibility === CUSTOMER_VISIBILITY_STATUS.visible
  }
}
