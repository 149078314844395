import styled from 'styled-components'
import { MODAL_TYPES } from '../../utils/constants/constants'

export const ModalBackgroundColor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--modal-bg-color);
  z-index: 99999;
  user-select: none;
`

export const ModalOuterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  user-select: none;
  display: flex;
  align-items: ${(props) => (props.type === 'editor' ? 'flex-start' : 'center')};
  justify-content: center;
  p,
  .alert-modal span {
    font-weight: var(--fw-1);
  }
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: var(--modal-container-bg-color);
  border-radius: var(--bdr-4);
  transform: ${(props) => (props.type === 'editor' ? 'translateY(60px)' : 'translateY(-50px)')};
  max-width: ${(props) => getMaxWidth(props)};
  font-size: ${(props) => props.fontSize || 'initial'};
  max-height: ${(props) => getMaxHeight(props)};
  height: ${(props) => getHeight(props)};
  padding: var(--space-6);
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px #0000004d;
  .user-manual-content {
    padding-bottom: 0;
  }
`

const getHeight = (props) => {
  if (props.isWelcomeNotification) return 'auto'
  if (props.type === MODAL_TYPES.notification) return '430px'
  return 'initial'
}

const getMaxWidth = (props) => {
  if (props.isWelcomeNotification || props.type === MODAL_TYPES.deleteAccount) {
    return 'var(--modal-large)'
  }
  if (props.type === MODAL_TYPES.payment || props.type === MODAL_TYPES.alert) {
    return 'var(--modal-small)'
  }
  return 'var(--modal-medium)'
}

const getMaxHeight = (props) => {
  if (props.isWelcomeNotification || props.type === MODAL_TYPES.deleteAccount) return '500px'
  if (props.type === MODAL_TYPES.payment) return '450px'
  if (props.type === MODAL_TYPES.alert) return ''
  if (props.type === MODAL_TYPES.notification) return '430px'
  return '400px'
}

export const CloseButton = ({ type, setModalOpen }) => {
  return (
    <CloseButtonContainer type={type} onClick={() => setModalOpen(false)}>
      <CenteredFlexDiv>
        <CloseIcon type={type} className="svg-icon icon-x-noborder-mask" />
      </CenteredFlexDiv>
    </CloseButtonContainer>
  )
}

const CloseIcon = styled.span`
  background-color: var(--text-color-secondary);
  transition: background-color var(--hover-duration);
  width: ${(props) => (props.type === MODAL_TYPES.notification ? '' : 'var(--icon-2)')};
  height: ${(props) => (props.type === MODAL_TYPES.notification ? '' : 'var(--icon-2)')};
`

export const CenteredFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseButtonContainer = styled.button`
  width: ${(props) => (props.type === MODAL_TYPES.notification ? '20px' : '32px')};
  height: ${(props) => (props.type === MODAL_TYPES.notification ? '20px' : '32px')};
  border: none;
  background-color: transparent;
  overflow: hidden;
  padding: 0;
  border-radius: var(--bdr-2);
  transform: ${(props) =>
    props.type === MODAL_TYPES.notification ? 'translate(var(--space-3), -2px)' : 'translateX(var(--space-3))'};
  &:hover .svg-icon {
    background-color: var(--close-button-hover-bg-color);
  }
`

export const ContentContainer = styled.div`
  overflow: auto;
  margin-bottom: var(--space-5);
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: var(--space-5);
`

export const ModalIcon = styled.span`
  width: var(--icon-3);
  height: var(--icon-3);
  mask-position: center;
`

export const AlertModalContent = styled.div`
  font-size: var(--fs-4);
  line-height: var(--lh-3);
  text-align: center;
`

export const HeaderContainer = styled.div`
  display: ${(props) => (props.type === MODAL_TYPES.alert ? 'grid' : 'flex')};
  gap: ${(props) => (props.type === MODAL_TYPES.alert ? 'var(--space-5)' : 0)};
  justify-content: ${(props) => (props.type === MODAL_TYPES.alert ? 'center' : 'space-between')};
  justify-items: center;
  padding: 0;
  border-bottom: ${(props) =>
    props.type === MODAL_TYPES.alert || props.type === MODAL_TYPES.notification
      ? ''
      : '2px solid var(--thick-underline-color)'};
`

export const SmallHeadline = styled.span`
  color: var(--text-color-secondary);
  font-weight: var(--fw-3);
  font-size: var(--fs-3);
  top: var(--modal-inset);
  left: var(--modal-inset);
`
