import styled from 'styled-components'
import { AppContext } from '../../utils/context/AppContext'
import { useContext, useEffect, useState } from 'react'
import { STATUS_BAR_TYPES, STATUS_BAR_DURATION } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'

const StatusBar = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const isVisible = context.statusBarController.statusBarVisible
  const type = context.statusBarController.statusBarType
  const content = context.statusBarController.statusBarContent
  const [timerRunning, setTimerRunning] = useState(false)
  const [isFading, setIsFading] = useState(false)

  useEffect(() => {
    const type = context.statusBarController.statusBarType
    if (type && type !== STATUS_BAR_TYPES.loading) {
      if (!timerRunning) {
        setTimerRunning(true)
        setTimeout(() => {
          context.statusBarController.setStatusBarVisible(false)
          setTimerRunning(false)
          setIsFading(true)
          setTimeout(() => {
            setIsFading(false)
            context.statusBarController.setStatusBarType(null)
          }, 500)
        }, STATUS_BAR_DURATION)
      }
    }
  }, [context.statusBarController.statusBarVisible, context.statusBarController.statusBarType])

  return (
    <OuterWrapper
      visible={isVisible}
      className={`statusbar ${isVisible ? 'status-bar-visible' : ''} ${isFading ? 'is-fading' : ''}`}
    >
      <InnerWrapper type={type}>
        <Icon className={`svg-icon ${getIcon(type)}`} style={{ userSelect: 'none' }} />
        <span style={{ lineHeight: 'var(--lh-2)' }}>{t(content)}</span>
      </InnerWrapper>
    </OuterWrapper>
  )
}

export default StatusBar

const getIcon = (type) => {
  switch (type) {
    case STATUS_BAR_TYPES.success:
      return 'icon-check'
    case STATUS_BAR_TYPES.loading:
      return 'spinner'
    case STATUS_BAR_TYPES.error:
    case STATUS_BAR_TYPES.notice:
    default:
      return 'icon-alert'
  }
}

const getBgColor = (type) => {
  switch (type) {
    case STATUS_BAR_TYPES.error:
      return 'var(--status-bar-error-bg-color)'
    case STATUS_BAR_TYPES.success:
      return 'var(--status-bar-success-bg-color)'
    default:
      return 'var(--status-bar-neutral-bg-color)'
  }
}

const getBorderColor = (type) => {
  switch (type) {
    case STATUS_BAR_TYPES.error:
      return 'var(--status-bar-error-border-color)'
    case STATUS_BAR_TYPES.success:
      return 'var(--status-bar-success-border-color)'
    default:
      return 'var(--status-bar-neutral-border-color)'
  }
}

const Icon = styled.span`
  width: var(--icon-2);
  height: var(--icon-2);
`

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--space-4);
  align-items: center;
  width: 100%;
  background-color: ${(props) => getBgColor(props.type)};
  padding: var(--space-4);
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => getBorderColor(props.type)};
  border-radius: var(--bdr-2);
  font-size: var(--fs-3);
  box-shadow: 5px 5px 8px 0px var(--status-bar-box-shadow-color);
`

const OuterWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: var(--inset);
  margin-left: calc(var(--main-nav-width) + var(--inset));
  left: 0;
  right: 0;
  max-width: 400px;
  z-index: 9999;
  pointer-events: none;
  transform: translateY(100px);
  @media screen and (max-width: 768px) {
    margin-left: 70px;
    margin-right: var(--inset);
  }
  @media screen and (max-width: 600px) {
    max-width: unset;
  }
`
