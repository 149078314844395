import {
  WizardForm,
  Label,
  ButtonPrimary,
  Modalfooter,
  OptionEntry,
  OptionDescription,
  ErrorMessage
} from '../../utils/elements/miscElements'
import { setStatusBar, setState, getLoggedInContact, fetchData } from '../../utils/helper/Helper'
import {
  API_DATA,
  FIELD_LABELS,
  FORM_ERRORS,
  PASSWORD_MIN_LENGTH,
  STATUS_BAR_TYPES
} from '../../utils/constants/constants'
import { useState, useContext, useRef } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import { CenteredFlexDiv } from '../modal/modalStyles'
import { PasswordVisibilityToggle } from '../passwordVisibilityToggle/PasswordVisibilityToggle'
import useTranslate from '../../utils/hooks/useTranslate'
import CapslockIndicator from '../capslockIndicator/CapslockIndicator'

const ChangePasswordComponent = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contact = getLoggedInContact(context.completeDataSet)

  const [data, setData] = useState({
    contactUuid: contact.contactUuid,
    contactOldPassword: '',
    contactNewPassword: ''
  })

  const [passwordNotValid, setPasswordNotValid] = useState(false)
  const [oldPasswordIncorrect, setOldPasswordIncorrect] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [capslockOn, setCapslockOn] = useState(false)

  const submitButtonRef = useRef(null)

  const handleOldPasswordChange = (e) => {
    setOldPasswordIncorrect(false)
    setState(setData, 'contactOldPassword', e.target.value)
  }

  const handleNewPasswordChange = (e) => {
    setState(setData, 'contactNewPassword', e.target.value)
    setFormValid(data.contactNewPassword.length >= PASSWORD_MIN_LENGTH)
  }

  const validateNewPassword = () => {
    if (data.contactNewPassword.length > 0 && data.contactNewPassword.length < PASSWORD_MIN_LENGTH) {
      setPasswordNotValid(true)
      setFormValid(false)
    } else {
      setPasswordNotValid(false)
    }
  }

  const handleKeyDown = (e) => {
    setCapslockOn(e.getModifierState && e.getModifierState('CapsLock'))
    if (data.contactNewPassword.length >= PASSWORD_MIN_LENGTH) {
      setPasswordNotValid(false)
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      saveNewPassword()
    }
  }

  const handleKeyUp = (e) => {
    setCapslockOn(e.getModifierState && e.getModifierState('CapsLock'))
  }

  const saveNewPassword = async () => {
    if (!formValid) {
      return
    }
    submitButtonRef.current.focus()

    const payload = {
      contactUuid: data.contactUuid,
      contactOldPass: data.contactOldPassword,
      contactNewPass: data.contactNewPassword
    }

    const responseData = await fetchData(
      payload,
      API_DATA.contacts.endPointChangePassword,
      context,
      'passwordChangeFailed',
      false
    )

    try {
      if (responseData.response.success) {
        setData((prev) => ({
          ...prev,
          contactNewPassword: '',
          contactOldPassword: ''
        }))
        setOldPasswordVisible(false)
        setNewPasswordVisible(false)
        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: 'passwordSuccessfullyChanged',
          setVisible: true
        })
      }
      if (responseData.response.errorCodes.includes(3)) {
        setOldPasswordIncorrect(true)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ScrollContainer type="settings">
      <WizardForm>
        <OptionEntry hasHint>
          <Label key="oldPassword" htmlFor="oldPassword" fullWidth>
            {t(FIELD_LABELS.oldPassword)}
          </Label>
          <div style={{ display: 'grid' }}>
            <CenteredFlexDiv style={{ position: 'relative' }}>
              <input
                id="oldPassword"
                style={{ width: '100%' }}
                type={data.contactOldPassword.length > 0 && !oldPasswordVisible ? 'password' : 'text'}
                value={data.contactOldPassword}
                onChange={handleOldPasswordChange}
                className={oldPasswordIncorrect ? 'has-error' : ''}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              />
              <CapslockIndicator {...{ capslockOn }} />
              <PasswordVisibilityToggle
                passwordVisible={oldPasswordVisible}
                setPasswordVisible={setOldPasswordVisible}
              />
            </CenteredFlexDiv>
            <ErrorMessage visible={oldPasswordIncorrect}>{t(FORM_ERRORS.oldPasswordIncorrent)}</ErrorMessage>
          </div>
        </OptionEntry>
        <OptionEntry hasHint>
          <Label key="newPassword" htmlFor="newPassword" fullWidth>
            {t(FIELD_LABELS.newPassword)}
          </Label>
          <div style={{ display: 'grid' }}>
            <CenteredFlexDiv style={{ position: 'relative' }}>
              <input
                id="newPassword"
                style={{ width: '100%' }}
                type={data.contactNewPassword.length && !newPasswordVisible > 0 ? 'password' : 'text'}
                value={data.contactNewPassword}
                onChange={handleNewPasswordChange}
                onBlur={validateNewPassword}
                className={passwordNotValid ? 'has-error' : ''}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              />
              <CapslockIndicator {...{ capslockOn }} />
              <PasswordVisibilityToggle
                passwordVisible={newPasswordVisible}
                setPasswordVisible={setNewPasswordVisible}
              />
            </CenteredFlexDiv>
            <ErrorMessage visible={passwordNotValid}>{t(FORM_ERRORS.newPasswordTooShort)}</ErrorMessage>
          </div>
          <OptionDescription description={t('minChars', PASSWORD_MIN_LENGTH)} />
        </OptionEntry>
      </WizardForm>
      <Modalfooter type="settings">
        <ButtonPrimary ref={submitButtonRef} content="changePassword" onClick={saveNewPassword} disabled={!formValid} />
      </Modalfooter>
    </ScrollContainer>
  )
}

export default ChangePasswordComponent
