import styled from 'styled-components'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'

const ProgressBar = ({ progressPercent, options }) => {
  const containerRef = useRef()
  const [scaledWidth, setScaledWidth] = useState()

  const recalculateWidth = useCallback(() => {
    const width = getProgressBarWidth(containerRef, progressPercent, options.showProgressValue)
    setScaledWidth(width)
  }, [progressPercent, options.showProgressValue])

  useLayoutEffect(() => {
    recalculateWidth()
    window.addEventListener('resize', recalculateWidth)
    return () => {
      window.removeEventListener('resize', recalculateWidth)
    }
  }, [recalculateWidth])

  return (
    <BarContainer ref={containerRef} options={options}>
      <BarLeft options={options} />
      <BarCenter width={scaledWidth} options={options} />
      <BarRight width={scaledWidth} options={options} />
      {options.showProgressValue && (
        <ProgressValue width={scaledWidth} options={options}>
          {progressPercent} %
        </ProgressValue>
      )}
    </BarContainer>
  )
}

const getProgressBarWidth = (ref, progressPercent, showProgressValue) => {
  const percentLabelWidth = showProgressValue ? 30 : 0
  const containerWidth = ref.current.getBoundingClientRect().width - percentLabelWidth
  return (containerWidth * progressPercent) / 100
}

export default ProgressBar

const BarContainer = styled.div`
  height: ${(props) => (props.options.isSmall ? '12px' : '16px')};
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  border-radius: var(--bdr-2);
  overflow: hidden;
  background-color: ${(props) => (props.options.showBackground ? 'var(--progress-bar-bg-color)' : '')};
`

const BarLeft = styled.div`
  background-color: ${(props) => props.options.color || 'var(--progress-bar-default-color)'};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
`

const BarCenter = styled.div`
  background-color: ${(props) => props.options.color || 'var(--progress-bar-default-color)'};
  transform: scaleX(${(props) => props.width});
  transform-origin: left;
  position: absolute;
  left: 3px;
  width: 1px;
  top: 0;
  bottom: 0;
  transition-duration: ${(props) => (props.options.showAnimation ? '500ms' : 0)};
`

const BarRight = styled.div`
  position: absolute;
  background-color: ${(props) => props.options.color || 'var(--progress-bar-default-color)'};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 3px;
  transform: translateX(${(props) => props.width}px);
  top: 0;
  bottom: 0;
  left: 3px;
  transition-duration: ${(props) => (props.options.showAnimation ? '500ms' : 0)};
  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.options.color || 'var(--progress-bar-default-color)'};
    left: -1px;
  }
`

const ProgressValue = styled.div`
  transform: translateX(${(props) => props.width}px);
  top: 0;
  bottom: 0;
  margin-left: var(--space-4);
  transition-duration: ${(props) => (props.options.showAnimation ? '500ms' : 0)};
`
