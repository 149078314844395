import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Table, WizardStep, WizardStepContainer } from '../userManualElements'

const Chapter4 = () => (
  <>
    <h2 id="chapter-4">4 Credit-System</h2>
    <h3 id="chapter-4-1">4.1 Allgemeine Informationen</h3>
    <h4 id="chapter-4-1-1">4.1.1 Wofür benötigt man Credits?</h4>
    <p>
      Mit PERSEO Credits rechnen wir die Durchführung von Tests ab. Credits können für alle Tests und Zusatzmodule
      genutzt werden. Auch ein individuelles Test-Design kann mit Credits bezahlt werden. Der Vorteil: Sie sind flexibel
      und brauchen sich im Vorfeld nicht auf bestimmte Tests oder Berufsgruppen festzulegen.
    </p>
    <h4 id="chapter-4-1-2">4.1.2 Was kosten Credits?</h4>
    <CreditPricingTable />
    <h4 id="chapter-4-1-3">4.1.3 Muss ein bestimmtes Credit-Paket abgenommen werden?</h4>
    <p>Nein, Sie können auch die exakt benötigte Menge Credits abnehmen und erhalten den jeweiligen Mengenrabatt.</p>
    <h4 id="chapter-4-1-4">4.1.4 Verfallen Credits irgendwann?</h4>
    <p>
      Nein, Credits sind zeitlich unbegrenzt gültig und müssen nicht innerhalb eines bestimmten Zeitraum verbraucht
      werden. Bei Löschung des Kunden-Accounts können übrige Credits allerdings nicht zurückerstattet werden.
    </p>
    <h4 id="chapter-4-1-5">4.1.5 Wann werden Credits abgebucht?</h4>
    <p>
      Credits werden abgebucht, sobald eine Testung abgeschlossen wurde. Für abgebrochene und abgelaufene Testungen
      werden keine Credits abgebucht. Auch beim Anlegen von Tests und Teilnehmern entstehen keine Kosten.
    </p>
    <h4 id="chapter-4-1-6">4.1.6 Welche Abrechnungsoptionen gibt es?</h4>
    <p>Credits können vorab oder nachträglich abgerechnet werden.</p>
    <h5>Abrechnung vorab</h5>
    <p>
      Sie können Ihr Credit-Konto jederzeit im Shop aufladen und mit dem vorhandenen Guthaben Testungen durchführen.
      Diese Option ist sinnvoll, wenn Sie viel testen und sich Mengenrabatt sichern möchten.
    </p>
    <h5>Nachträgliche Abrechnung</h5>
    <p>
      Wenn Sie wenig testen oder nicht wissen, wie viele Tests Sie durchführen, können Sie unsere nachträgliche
      Abrechnung nutzen. Hierbei führen Sie Testungen durch, ohne vorher ein Guthaben aufgeladen zu haben. Das dabei
      entstehende negative Guthaben wird jeweils zum Anfang eines Monats automatisch ausgeglichen, indem der ausstehende
      Betrag dem Kundenkonto gutgeschrieben und die entsprechende Anzahl an Credits in Rechnung gestellt wird.
    </p>
    <h3 id="chapter-4-2">4.2 Credits aufladen</h3>
    <p>
      Öffnen Sie die Seite <em>Credit Shop</em>, indem Sie in der linken Seitenleiste auf <B>Credit Shop</B> oder oberhalb
      der Buchungstabelle auf <B>Credits aufladen</B> klicken.
    </p>
    <TimeLineContainer className="wizard">
      <Entry>
        <WizardStepContainer>
          <WizardStep>1. Anzahl Credits ermitteln</WizardStep>
          <ul>
            <li>
              Wenn Sie nicht wissen, wie viele Credits Sie benötigen, können Sie den Credit-Rechner nutzen: Wählen Sie
              unter <em>Produkt</em> Ihr gewünschtes Produkt aus und tragen unter <em>Anzahl</em> die benötigte Anzahl ein.
              Die Summe benötigter Credits wird automatisch ermittelt und erscheint als zusätzliche Auswahloption unter{' '}
              <em>Credit-Pakete</em>.
            </li>
            <li>
              Wählen Sie aus, wie viele Credits Sie aufladen möchten, indem Sie die jeweilige Schaltfläche anklicken.
              Wenn Sie eine individuelle Zahl an Credits aufladen möchten, können Sie die Zahl in das Feld{' '}
              <em>Anzahl Credits</em> unter <em>Zusammenfassung</em> manuell eintragen.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>2. Zahlungsart und Rechnungsdaten</WizardStep>
          <ul>
            <li>Wählen Sie eine Zahlungsart.</li>
            <li>
              Wenn Sie noch keine Rechnungsdaten eingetragen haben, klicken Sie unter <em>Rechnungsdaten</em> auf
              <B>Hinzufügen</B>. Tragen Sie Ihre Rechnungsdaten in das Formular ein und klicken Sie auf <B>Speichern</B>
              . Sollten bereits Rechnungsdaten im System hinterlegt sein, können Sie diese ändern, indem Sie auf
              <B>Ändern</B> klicken.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>3. Bestellung überprüfen</WizardStep>
          <ul>
            <li>Überprüfen Sie Ihre Bestellung.</li>
            <li>
              Klicken Sie auf <B>Kaufen</B>, um den Kauf abzuschließen. Die Credits werden Ihrem Konto sofort
              gutgeschrieben. Die Rechnung erhalten Sie automatisch per E-Mail.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
    </TimeLineContainer>
  </>
)

export default Chapter4

const CreditPricingTable = () => {
  const header = ['Anzahl', 'Preis / Credit', 'Paketpreis']
  const data = [
    ['1', '3,80 €'],
    ['100', '3,20 €', '320 €'],
    ['500', '2,85 €', '1.425 €'],
    ['1.000', '2,65 €', '2.650 €'],
    ['2.000', '2,40 €', '4.800 €'],
    ['5.000', '2,20 €', '11.000 €'],
    ['10.000', '', 'auf Anfrage']
  ]
  return <Table name='credit-pricing' {...{ header, data }} />
}
