import styled from 'styled-components'
import { CUSTOMER_VISIBILITY_STATUS } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'
import TestCard from '../testCard/TestCard'

const ProcessTestsPreview = ({ processName, existingTests, newTestName, newConfig, referenceAssessmentUuid }) => {
  const newTest = {
    assessmentName: newTestName,
    visibility: CUSTOMER_VISIBILITY_STATUS.visible,
    relatedPts: []
  }

  return (
    <OuterContainer>
      <ProcessName {...{ processName }} />
      <TestList {...{ existingTests, newTest, newConfig, referenceAssessmentUuid }} />
    </OuterContainer>
  )
}

export default ProcessTestsPreview

const OuterContainer = styled.div`
  background-color: var(--box-background);
  padding: var(--space-5);
  border-radius: var(--bdr-3);
`

const ProcessName = ({ processName }) => {
  const t = useTranslate()

  return (
    <ProcessNameContainer>
      <span className="uppercase-label">{t('process')}</span>
      <span style={{ lineHeight: 'var(--lh-2)' }}>{processName}</span>
    </ProcessNameContainer>
  )
}

const TestList = ({ existingTests, newTest, newConfig, referenceAssessmentUuid }) => {
  const t = useTranslate()

  return (
    <TestContainer>
      {existingTests.map((existingTest) => {
        const isReference = existingTest.assessmentUuid === referenceAssessmentUuid
        return (
          <TestElement key={existingTest.assessmentUuid}>
            <TestCard
              label={isReference && t('reference')}
              assessment={existingTest}
              config={existingTest.relatedConfig}
            />
          </TestElement>
        )
      })}
      <TestElement key="newtest">
        <TestCard label="new" assessment={newTest} config={newConfig} />
      </TestElement>
    </TestContainer>
  )
}

const ProcessNameContainer = styled.div`
  font-size: var(--fs-5);
  margin-bottom: var(--space-5);
  line-height: var(--lh-3);
  display: grid;
  min-height: 36px;
`

const TestContainer = styled.ul`
  padding: 0;
  display: grid;
  gap: var(--space-4);
`

const TestElement = styled.li`
  list-style-type: none;
  background-color: var(--test-info-card-bg-color);
  border-radius: var(--bdr-2);
  overflow: hidden;
`
