import { B, Table } from '../userManualElements'

const Chapter0 = () => (
  <>
    <h2 id="chapter-0">0 Kurzanleitung</h2>
    <p>
      In dieser Kurzanleitung zeigen wir Ihnen, wie Sie Ihren ersten Test anlegen, die Zugangsdaten versenden und die
      Ergebnisse abrufen.
    </p>
    <p>
      Bitte beachten Sie, dass Sie ggf. nicht alle Schritte durchführen können, falls Sie nicht die benötigten Rechte
      besitzen (siehe Spalte <em>Beschränkungen</em>).
    </p>
    <QuickStartTable />
  </>
)

export default Chapter0

const QuickStartTable = () => {
  const header = ['#', 'Schritt', 'Beschreibung', 'Beschränkungen']
  const data = [
    [
      1,
      'Verfahren anlegen',
      <span key="QuickStartTable-addProcess">
        Klicken Sie in der Seitenleiste auf <B large>Erstellen</B> und dann auf <B large>Neues Verfahren</B>. Geben Sie
        dem Verfahren einen Namen, klicken auf <B large>Weiter</B> und dann auf <B large>Verfahren anlegen</B>. Klicken
        Sie nun auf <B large>Test hinzufügen</B>.
      </span>,
      'Administrator'
    ],
    [
      2,
      'Test anlegen',
      <span key="QuickStartTable-addTest">
        Wählen Sie den passenden Test für Ihre Berufsgruppe aus. Blättern Sie die folgenden Seiten mit{' '}
        <B large>Weiter</B> durch und klicken dann auf <B large>Test anlegen</B>. Klicken Sie nun auf{' '}
        <B large>Teilnehmer hinzufügen</B>.
      </span>,
      'Editor'
    ],
    [
      3,
      'Teilnehmer anlegen',
      <span key="QuickStartTable-addParticipant">
        Wählen Sie die Option <em>Blanko Teilnehmer anlegen</em>, klicken auf <B>Weiter</B> und legen die Teilnehmerzahl
        fest. Blättern Sie zur nächsten Seite und geben – falls nötig – Ihre Zahlungsdaten an. Klicken Sie nun auf{' '}
        <B large>Hinzufügen</B>.
      </span>,
      'Editor'
    ],
    [
      4,
      'Zugangsdaten herunterladen',
      <span key="QuickStartTable-getLink">
        Klicken Sie auf <B large>Zugangsdaten</B>, um eine Liste mit Zugangsdaten herunterzuladen.
      </span>
    ],
    [5, 'Teilnehmer einladen', 'Senden Sie Ihren Bewerbern je einen Testlink.	'],
    [
      6,
      'Ergebnisse abrufen',
      <span key="QuickStartTable-downloadResult">
        Die Testergebnisse liegen sofort nach Abschluss des Tests vor. Sie finden sie u.a. in den Sektionen{' '}
        <em>Teilnehmer</em>,<em>Testungen</em> und <em>Dashboard</em>.
      </span>
    ]
  ]
  return <Table name="quick-start" {...{ header, data }} />
}
