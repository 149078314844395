import React from 'react'
import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import { ContentContainer } from '../components/table/tableElements'
import { PageHeadline } from '../utils/elements/miscElements'
import useTranslate from '../utils/hooks/useTranslate'

const Imprint = ({ showInModal }) => (
  <>
    {showInModal ? (
      <Content showInModal={showInModal} />
    ) : (
      <ScrollContainer type="main">
        <Content showInModal={showInModal} />
      </ScrollContainer>
    )}
  </>
)

export default Imprint

const Content = ({ showInModal }) => {
  const t = useTranslate()
  return (
    <ContentContainer className="legal" style={{ maxWidth: '600px', margin: showInModal ? 0 : '', padding: 0 }}>
      {!showInModal && <PageHeadline>{t('imprint')}</PageHeadline>}
      <h3 style={{ marginTop: 0 }}>{t('imprintTitle')}</h3>

      <p>
        Perseo GmbH <br />
        Graf-Adolf-Str. 41
        <br />
        40210 Düsseldorf
        <br />
        {t('germany')}
      </p>

      <h3>{t('representativeCEO')}</h3>

      <p>Dr. Benjamin Haarhaus</p>

      <h3>{t('contact')}</h3>

      <p>
        {t('phone')}: +49 211 97 63 398-0
        <br />
        {t('email')}:{' '}
        <a className="textlink" href="mailto:info@perseo.hr">
          info@perseo.hr
        </a>
      </p>

      <h3>{t('registerEntry')}</h3>

      <p>
        {t('court')}: Düsseldorf
        <br />
        {t('registerNumber')}: HRB 88485
      </p>

      <h3>{t('vatID')}</h3>

      <p>{t('vatIDLong')}: DE306305704</p>

      <h3>{t('responsibleContent')}</h3>

      <p style={{ margin: 0 }}>
        Perseo GmbH
        <br />
        {t('representedBy')} Dr. Benjamin Haarhaus
        <br />
        Graf-Adolf-Str. 41
        <br />
        40210 Düsseldorf
        <br />
        {t('germany')}
      </p>
    </ContentContainer>
  )
}
