import { useContext, useEffect } from 'react'
import { scrollToElement } from '../../utils/helper/Helper'
import './userManual.scss'
import useCurrentRect from '../../utils/hooks/useCurrentRect'
import { AppContext } from '../../utils/context/AppContext'

import Chapter0 from './de/Chapter-0'
import Chapter1 from './de/Chapter-1'
import Chapter2 from './de/Chapter-2'
import Chapter3 from './de/Chapter-3'
import Chapter4 from './de/Chapter-4'
import Chapter5 from './de/Chapter-5'

import Chapter0English from './en/Chapter-0-en'
import Chapter1English from './en/Chapter-1-en'
import Chapter2English from './en/Chapter-2-en'
import Chapter3English from './en/Chapter-3-en'
import Chapter4English from './en/Chapter-4-en'
import Chapter5English from './en/Chapter-5-en'

const useInternalLinks = () => {
  const width = useCurrentRect().width
  useEffect(() => {
    const elements = document.querySelectorAll('.internal-link')
    const container = document.getElementById('user-manual-container')

    elements.forEach((element) =>
      element.addEventListener('click', (e) => {
        scrollToElement(container, e.target.dataset.scrollTo, width)
      })
    )
  }, [])
}

const UserManual = () => {
  const context = useContext(AppContext)
  const lang = context.language
  useInternalLinks()
  return (
    <div className="user-manual-content">
      {lang === 'en' ? (
        <>
          <Chapter0English />
          <Chapter1English />
          <Chapter2English />
          <Chapter3English />
          <Chapter4English />
          <Chapter5English />
        </>
      ) : (
        <>
          <Chapter0 />
          <Chapter1 />
          <Chapter2 />
          <Chapter3 />
          <Chapter4 />
          <Chapter5 />
        </>
      )}
    </div>
  )
}

export default UserManual
