export const getFilterStyles = (props) => ({
  container: (provided) => ({
    ...provided,
    width: props.size,
    transitionDuration: '0s',
    fontSize: '13px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%',
    backgroundColor: 'transparent',
    paddingLeft: 'var(--space-3)'
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
    lineHeight: 'var(--lh-2)',
    color: 'var(--text-color-primary)',
    width: props.position === 'globalFilter' ? 'calc(var(--global-filter-width) - 50px)' : 'initial'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
    marginTop: 'var(--space-2)',
    border: 'none',
    boxShadow: 'var(--sh-dropdown)',
    backgroundColor: 'var(--filter-menu-color)',
    borderRadius: 'var(--bdr-2)',
    minWidth: '100%',
    width: 'auto'
  }),
  menuList: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap'
  }),
  control: (provided, state) => ({
    ...provided,
    height: '32px',
    minHeight: '24px',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all 0s',
    backgroundColor: 'var(--input-bg-color)',
    borderRadius: 'var(--bdr-2)',
    transitionDuration: 'var(--hover-duration)',
    boxShadow: '',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: state.isFocused ? 'var(--color-focus-outline) !important' : 'var(--border-grey) !important'
  }),
  option: (provided, state) => ({
    ...provided,
    position: 'relative',
    padding: 'var(--space-3) var(--space-4)',
    fontSize: '13px',
    backgroundColor: state.isFocused ? 'var(--react-select-option-hover-color)' : 'transparent',
    cursor: 'pointer',
    fontWeight: state.isSelected ? '600' : '',
    color: state.isDisabled ? 'var(--text-color-secondary)' : 'var(--text-color-primary)',
    pointerEvents: state.isDisabled ? 'none' : 'initial',
    marginBottom: state.data.hasDivider ? '8px' : '',
    overflow: state.data.hasDivider ? '' : 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'var(--react-select-option-hover-color)'
    },
    '&:after': {
      content: '""',
      borderBottom: state.data.hasDivider ? '1px solid var(--border-grey)' : '',
      width: 'calc(100% - 24px)',
      height: '1px',
      position: 'absolute',
      left: '12px',
      bottom: '-4px'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: state.hasValue && props.isClearable ? 'block' : 'none',
    backgroundColor: 'var(--small-action-icon-color)'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '100%',
    backgroundColor: 'var(--input-bg-color)',
    display: props.position === 'globalFilter' ? 'none' : 'flex'
  }),
  input: (provided) => ({
    ...provided,
    height: 'auto',
    color: 'var(--text-color-primary)'
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: 'var(--text-color-secondary)',
    letterSpacing: '.3px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 8px 8px 0',
    color: 'var(--small-action-icon-color)',
    '&:hover': {
      color: 'var(--small-action-icon-hover-color)'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--text-color-secondary)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: 'var(--text-color-secondary)',
    fontSize: '13px',
    textAlign: 'left'
  })
})
