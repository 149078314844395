import { API_DATA, ERROR_MSG, FIELD_LABELS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { getOptionByValue } from '../../utils/helper/Helper'
import CustomInput from '../customInput/CustomInput'
import CameraHelp from '../helpModal/CameraHelp'
import ChildItemsHelp from '../helpModal/ChildItemsHelp'
import TabTrackingHelp from '../helpModal/TabTrackingHelp'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'

const AssessmentSecuritySettingsTile = ({ assessment, canEdit }) => {
  return (
    <Tile width="210px" style={{ position: 'relative' }}>
      <TileHeadline content="testSecurity" />
      <CustomInputList>
        <CustomInput
          label={FIELD_LABELS.useChildItems}
          content={getOptionByValue(SELECT_OPTIONS.useChildItems, assessment.useChildItems).label || ERROR_MSG}
          value={assessment.useChildItems}
          dataKey="useChildItems"
          type="select"
          options={SELECT_OPTIONS.useChildItems}
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          helpContent={<ChildItemsHelp />}
        />

        <CustomInput
          label={FIELD_LABELS.cameraSupervision}
          content={
            getOptionByValue(SELECT_OPTIONS.cameraSupervision, assessment.useCameraSupervision || 0).label || ERROR_MSG
          }
          value={assessment.useCameraSupervision || 0}
          dataKey="useCameraSupervision"
          type="select"
          options={SELECT_OPTIONS.cameraSupervision}
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          helpContent={<CameraHelp />}
        />

        <CustomInput
          label={FIELD_LABELS.tabTracking}
          content={getOptionByValue(SELECT_OPTIONS.tabTracking, assessment.useTabTracking).label || ERROR_MSG}
          value={assessment.useTabTracking}
          dataKey="useTabTracking"
          type="select"
          options={SELECT_OPTIONS.tabTracking}
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          helpContent={<TabTrackingHelp />}
        />
      </CustomInputList>
    </Tile>
  )
}

export default AssessmentSecuritySettingsTile
