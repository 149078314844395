import './Table.scss'
import RowActions from './RowActions'

export const toggleExpanded = (row, e, hasSubrows) => {
  const selection = window.getSelection().toString()
  const clickedElement = e.nativeEvent.target
  const excludedElementClassNames = [
    'row-actions-toggle-button',
    'toggle-button-icon',
    'menu-button',
    'menu-icon',
    'menu-label',
    'custom-checkbox',
    'hidden-checkbox',
    'table-content-container'
  ]
  const clickedExcluded = excludedElementClassNames.some((el) => clickedElement.classList.contains(el))
  const clickedTableContent = clickedElement.matches('.table-content-container > *, .table-content-container > * > *')

  if (selection.length > 0 || clickedExcluded || clickedTableContent || !hasSubrows) return
  row.toggleRowExpanded()
}

export const getTrClassName = (row) => {
  const canArchive = typeof row.original.isArchived === 'function'
  const isSelectedAppendix = row.isSelected ? 'selected ' : ''
  const isArchivedAppendix = canArchive && row.original.isArchived() ? 'archived' : ''
  const className = isSelectedAppendix + isArchivedAppendix
  return className
}

export const getCellClassName = (row, cell) => {
  const expanded = row.isExpanded ? 'expanded' : 'closed'
  const columnsClass = cell.column.className || ''
  return `${expanded} ${columnsClass}`
}

export const getHiddenColumns = (columns, customTableSettings, tableName) => {
  const alwaysHiddenIds = columns.filter((column) => column.alwaysHidden).map((column) => column.id)
  const defaultHiddenIds = columns.filter((column) => column.show === false).map((column) => column.id)
  const customHiddenIds = customTableSettings?.[tableName]
    ? customTableSettings[tableName].filter((column) => !column.isVisible).map((column) => column.id)
    : []
  const customVisibleIds = customTableSettings?.[tableName]
    ? customTableSettings[tableName].filter((column) => column.isVisible).map((column) => column.id)
    : []
  const defaultHiddenIdsWithoutOverwrite = defaultHiddenIds.filter((id) => !customVisibleIds.includes(id))

  return [...alwaysHiddenIds, ...customHiddenIds, ...defaultHiddenIdsWithoutOverwrite]
}

export const addTableButton = (row, entity) => {
  row.actions = <RowActions row={row} entity={entity} />
  return row
}

export const getTableClassName = (tableName, hasSubrows, hasShadow) => {
  const hasActionsColShadow = hasShadow.actionsCol
  const hasCheckboxColShadow = hasShadow.checkboxCol

  let className = tableName

  className += hasSubrows ? ' has-subrows ' : ''
  className += hasActionsColShadow ? ' has-action-col-shadow ' : ''
  className += hasCheckboxColShadow ? ' has-checkbox-col-shadow' : ''

  return className
}

export const reorderColumns = (columns, customTableSettings, tableName) => {
  if (!customTableSettings[tableName]) {
    return columns
  }

  const sortedColumnIds = customTableSettings?.[tableName].map((col) => col.id)

  const columnMap = {}
  const orderedColumns = []
  const unorderedColumns = []

  columns.forEach((column) => {
    if (sortedColumnIds.includes(column.id)) {
      columnMap[column.id] = column
    } else {
      unorderedColumns.push(column)
    }
  })

  sortedColumnIds.forEach((columnId) => {
    if (columnMap[columnId]) {
      orderedColumns.push(columnMap[columnId])
    }
  })

  return [...orderedColumns, ...unorderedColumns]
}
