import { useContext } from 'react'
import ReactTooltip from 'react-tooltip'
import { DEFAULT_LANGUAGE, UNPERMITTED_AIDS_RESULTS } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'

const UnpermittedAidsLabel = ({ index, rowData }) => {
  const t = useTranslate()
  const style = {
    container: {
      display: 'inline-flex',
      alignItems: 'center'
    },
    icon: {
      backgroundColor: 'var(--error-message-color)',
      marginLeft: 'var(--space-2)',
      cursor: 'initial'
    }
  }

  const labelId = `aidsInfo_${rowData?.ptNumber || null}`
  const text = getUnpermittedAidsText(index)
  const usedUnpermittedAid = index === UNPERMITTED_AIDS_RESULTS.usedUnpermittedAids

  return (
    <>
      <div style={style.container}>
        <span>{t(text)}</span>
        {usedUnpermittedAid && (
          <span data-tip="" data-for={labelId} className={`svg-icon icon-exclamation`} style={style.icon} />
        )}
      </div>

      {rowData && (
        <ReactTooltip
          id={labelId}
          aria-haspopup="true"
          effect="solid"
          place="bottom"
          backgroundColor="var(--c-tooltip-bg)"
          textColor="var(--tooltip-text-color)"
          getContent={() => <InfoText aids={rowData?.relatedResult?.unpermittedAids} />}
        />
      )}
    </>
  )
}

export const getUnpermittedAidsLabels = ({ rowData }) => ({
  [UNPERMITTED_AIDS_RESULTS.didNotUseUnpermittedAids]: (
    <UnpermittedAidsLabel index={UNPERMITTED_AIDS_RESULTS.didNotUseUnpermittedAids} {...{ rowData }} />
  ),
  [UNPERMITTED_AIDS_RESULTS.usedUnpermittedAids]: (
    <UnpermittedAidsLabel index={UNPERMITTED_AIDS_RESULTS.usedUnpermittedAids} {...{ rowData }} />
  )
})

export const getUnpermittedAidsText = (index) => {
  switch (index) {
    case UNPERMITTED_AIDS_RESULTS.didNotUseUnpermittedAids:
      return 'none'
    case UNPERMITTED_AIDS_RESULTS.usedUnpermittedAids:
      return 'yes'
    default:
      return ''
  }
}

const InfoText = ({ aids }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const localizedAids = aids[context.language].length > 0 ? aids[context.language] : aids[DEFAULT_LANGUAGE]
  if (!aids[DEFAULT_LANGUAGE]) return ''
  return (
    <div>
      <span>{t('unpermittedAidsUsed')}:</span>
      <br />
      <ul style={{ paddingLeft: 'var(--space-4)' }}>
        {localizedAids.map((aid, i) => (
          <li key={aid + i}>{aid}</li>
        ))}
      </ul>
    </div>
  )
}
