import { API_DATA } from '../../../../../utils/constants/constants'
import { fetchData, setState } from '../../../../../utils/helper/Helper'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const saveTableColumns = async ({ data, context, tableName }) => {
  const currentCustomTableSettings = context.completeDataSet.customTableSettings
  currentCustomTableSettings[tableName] = data.map((col) => ({ id: col.id, isVisible: col.isVisible }))
  setState(context.setCompleteDataSet, 'customTableSettings', currentCustomTableSettings)

  const currentCustomTableSettingsObject = Object.assign({}, currentCustomTableSettings)

  const payload = { customTableSettings: currentCustomTableSettingsObject }

  try {
    await fetchData(payload, API_DATA.tableColumns.endPointEdit, context, 'settingsNotSaved', false)
  } catch (e) {
    console.error(e)
  }
}

export const computeInitialState = (columns) => {
  const EXCLUDED_COLUMN_IDS = ['actions', 'expander', 'spacer', 'freeText']
  return columns
    .filter((column) => {
      return !EXCLUDED_COLUMN_IDS.includes(column.id) && !column.alwaysHidden
    })
    .map((col) => ({
      id: col.id,
      isVisible: col.isVisible,
      header: col.Header
    }))
}
