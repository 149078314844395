import { useLocation } from 'react-router-dom'
import TopMenu from '../topMenu/TopMenu'
import styled from 'styled-components'
import { getActivePage } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import Globalfilter from '../filter/GlobalFilter'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

function Header(props) {
  const location = useLocation()
  const { language } = useContext(AppContext)
  const t = useTranslate()
  const activePage = getActivePage(location, language)
  const navLevels = props.isLoadingData ? [''] : activePage?.navLevels

  return (
    <HeaderOuterWrapper>
      <HeaderInnerWrapper>
        <h1 className="in-app" tabIndex="-1">
          <Breadcrumbs {...{ navLevels }} />
        </h1>
        <RightSideWrapper>
          {props.isLoadingData ? (
            <GlobalFilterPlaceholder className="global-filter-placeholder">
              <span>{`${t('search')}…`}</span>
            </GlobalFilterPlaceholder>
          ) : (
            <Globalfilter />
          )}
          <TopMenu />
        </RightSideWrapper>
      </HeaderInnerWrapper>
    </HeaderOuterWrapper>
  )
}

export default Header

const HeaderOuterWrapper = styled.header`
  height: var(--header-height);
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 9999;
  background-color: var(--header-bg-color);
  box-shadow: var(--sh-1);
  border-bottom: 1px solid var(--header-border-color);
`

const HeaderInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 var(--inset);
`

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media screen and (max-width: 600px) {
    justify-content: space-between;
    width: 100%;
  }
`

const Breadcrumbs = ({ navLevels }) => {
  if (!navLevels) return <></>
  return navLevels.map((navLevel, i) => {
    const isLastLevel = i === navLevels.length - 1
    return <NavLevelEntry navLevel={navLevel} isLastLevel={isLastLevel} key={'nav' + i} />
  })
}

const NavLevelEntry = ({ navLevel, isLastLevel }) => {
  const t = useTranslate()
  const spacer = ' > '
  const style = {
    color: isLastLevel ? 'var(--text-color-primary)' : 'var(--text-color-secondary)'
  }
  return (
    <span style={style}>
      {t(navLevel)}
      {!isLastLevel && spacer}
    </span>
  )
}

const GlobalFilterPlaceholder = styled.div`
  position: relative;
  width: var(--global-filter-width);
  height: 32px;
  border-radius: var(--bdr-2);
  border: 1px solid var(--border-grey);
  background-color: var(--input-bg-color);
  display: flex;
  align-items: center;
  span {
    color: var(--text-color-secondary);
    font-size: 13px;
    margin-left: 10px;
  }
`
