import styled from 'styled-components'

export const Icon = styled.span`
  width: var(--icon-2);
  height: var(--icon-2);
  background-color: ${(props) => (props.red ? 'var(--warning-color)' : '#fff')};
`

export const EditDiscardButton = styled.button`
  background-color: var(--button-edit-discard-bg-color);
  border-radius: var(--bdr-2);
  width: 32px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-1) var(--space-2);
  &:hover {
    background-color: var(--button-edit-discard-hover-color);
  }
`

export const EditSaveButton = styled.button`
  background-color: var(--green-500);
  border-radius: var(--bdr-2);
  color: #fff;
  width: 32px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-1) var(--space-2);
  &:disabled {
    background-color: var(--button-disabled-bg-color);
  }
  &:hover {
    background-color: var(--green-600);
  }
`

export const ButtonContainer = styled.div`
  grid-row: ${(props) => (props.position === 'timeline' ? 3 : 3)};
  grid-column: ${(props) => (props.position === 'timeline' ? '1/3' : 2)};
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space-2);
  justify-content: flex-end;
  margin-top: var(--space-2);
`

export const CustomInputContainer = styled.li`
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  display: grid;
  grid-template-columns: ${(props) => (props.position === 'timeline' ? '1fr var(--space-6)' : '1fr auto')};
  padding: var(--space-2);
  gap: 0 var(--space-2);
  margin: -4px 0 -4px -4px;
  border-radius: var(--bdr-2);
  .secondary-button {
    display: ${(props) => (props.editMode ? 'block' : '')};
  }
  &:hover .secondary-button {
    display: block;
  }
  &:hover {
    background-color: ${(props) => (props.editMode ? '' : 'var(--tertiary-button-hover-color)')};
    .pen-button {
      display: ${(props) => (props.editMode ? '' : 'block')};
    }
  }
`

export const CustomInputLabel = styled.span`
  grid-row: 1;
  grid-column: ${(props) => (props.editMode ? '1/3' : 1)};
`

export const CustomInputContent = styled.span`
  line-height: var(--lh-2);
  white-space: nowrap;
  grid-column: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const CalendarWrapper = styled.div`
  grid-row: ${(props) => (props.position === 'timeline' ? 2 : '')};
`
