import { useEffect, useRef, useState } from 'react'
import { PageHeadline } from '../../utils/elements/miscElements'
import styled from 'styled-components'
import './tableOfContents.scss'
import { scrollToElement } from '../../utils/helper/Helper'
import useCurrentRect from '../../utils/hooks/useCurrentRect'

const TableOfContents = () => {
  const [activeId, setActiveId] = useState()
  useIntersectionObserver(setActiveId)
  useTocScrolling(activeId)
  const headings = useHeadings()
  const container = document.getElementById('user-manual-container')
  return (
    <div className="table-of-contents">
      <PageHeadline className="toc-headline">Inhalt</PageHeadline>
      <Headings {...{ headings, container, activeId }} />
    </div>
  )
}

export default TableOfContents

const useHeadings = () => {
  const [headings, setHeadings] = useState([])
  useEffect(() => {
    const elements = Array.from(document.querySelectorAll('h2, h3, h4, h5, h6'))
      .filter((element) => element.id)
      .map((element) => ({
        id: element.id,
        text: element.textContent ?? '',
        level: Number(element.tagName.substring(1))
      }))
    setHeadings(elements)
  }, [])
  return headings
}

const useTocScrolling = (activeId) => {
  const width = useCurrentRect().width
  useEffect(() => {
    const tocEntryId = 'toc-' + activeId
    const el = document.getElementById(tocEntryId)
    if (!el) return
    const container = document.getElementById('toc-container')
    if (!isVisible(el)) {
      scrollToElement(container, tocEntryId, width)
    }
  }, [activeId, width])
}

const Headings = ({ headings, container, activeId }) => {
  const width = useCurrentRect().width

  return (
    <TocWrapper>
      {headings.map((heading) => {
        const [chapter, ...rest] = heading.text.split(' ')
        const title = rest.join(' ')

        return (
          <TocEntry
            id={`toc-${heading.id}`}
            className={`toc-entry ${heading.id === activeId ? 'active' : ''} level-${heading.level - 1}`}
            key={heading.id}
            onClick={(e) => {
              e.preventDefault()
              scrollToElement(container, heading.id, width)
            }}
          >
            <span className="chapter-no">{chapter}</span>
            <span className="chapter-title">{title}</span>
          </TocEntry>
        )
      })}
    </TocWrapper>
  )
}

const TocWrapper = styled.ul`
  padding: 0 0 var(--space-8) 0;
  margin: 0;
  list-style: none;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: baseline;
    padding: var(--inset);
  }
`

const TocEntry = styled.li`
  display: flex;
  cursor: pointer;
  padding: var(--space-2) 0;
  padding-right: var(--space-7);
  &:hover {
    background-color: var(--toc-hover-color);
  }
  &.active {
    background-color: var(--toc-active-color);
  }
  @media screen and (max-width: 768px) {
    padding: var(--space-2) !important;
    margin: 0 var(--space-3) 0 0 !important;
    border-radius: var(--bdr-2);
    white-space: nowrap;
    &.active,
    &:hover {
      background-color: transparent;
    }
    .chapter-no {
      width: var(--space-4) !important;
    }
  }
`

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = useRef({})

  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)

      const visibleHeadings = []
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key]
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
      })

      const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id)

      if (visibleHeadings.length === 1) {
        const id = visibleHeadings[0].target.id
        setActiveId(id)
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        )
        setActiveId(sortedVisibleHeadings[0].target.id)
      }
    }
    const observer = new IntersectionObserver(callback, {
      rootMargin: '-40px 0px -40% 0px'
    })

    const headingElements = Array.from(document.querySelectorAll('h2, h3, h4, h5, h6')).filter((element) => element.id)
    headingElements.forEach((element) => observer.observe(element))

    return () => observer.disconnect()
  }, [setActiveId])
}

const isVisible = (element) => {
  const rect = element.getBoundingClientRect()
  return rect.top >= 100 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
}
