import {
  API_DATA,
  GERMAN_VAT_TAX_RATE,
  MANDATORY_BILLING_FIELDS,
  STATUS_BAR_TYPES
} from '../../utils/constants/constants'
import { fetchData, mailIsValid, setState, setStatusBar, updateEntities } from '../../utils/helper/Helper'

export const getPriceSingle = (credits, creditPrices) => {
  const filteredCredits = creditPrices.filter((entry) => credits >= entry.count)
  const priceSingle = filteredCredits[filteredCredits.length - 1]?.price || null
  return priceSingle
}

export const getPriceTotal = (credits, creditPrices) => {
  const priceSingle = getPriceSingle(credits, creditPrices)
  const priceTotal = priceSingle * credits || 0
  return priceTotal
}

export const getQuantityDiscount = (price, creditPrices) => {
  const discount = 100 - (price / creditPrices[0].price) * 100
  const discountRounded = discount.toFixed()
  const discountLabel = `-${discountRounded} %`
  const inverted = `${discountRounded} %`
  return { discount: discount, discountLabel: discountLabel, inverted: inverted, rounded: discountRounded }
}

export const validateBillingData = (billingData) => {
  const fieldsWithErrors = MANDATORY_BILLING_FIELDS.filter((field) => !billingData[field])

  if (!mailIsValid(billingData.billingEmail)) {
    fieldsWithErrors.push('billingEmail')
  }

  if (fieldsWithErrors.length > 0) return false

  return true
}

export const addCredits = async (orderData, context) => {
  const payload = {
    numberCredits: orderData.numberCredits,
    paymentMethod: orderData.paymentMethod
  }

  const responseData = await fetchData(
    payload,
    API_DATA.creditBookings.endPointAdd,
    context,
    'unableToChargeCredits',
    false
  )

  try {
    console.log('responseData add credits', responseData)

    const updates = {
      invoices: [responseData.response.data.invoice],
      creditBookings: [responseData.response.data.creditBooking]
    }
    updateEntities(updates, context)
    return responseData
  } catch (e) {
    console.error(e)
  }
}

export const getTaxRateByCountry = (countryCode) => {
  if (countryCode !== 'DE') {
    return 0
  }
  return GERMAN_VAT_TAX_RATE
}

export const saveBillingFormData = async ({ data, context }) => {
  const payload = {
    billingCustomer: data.billingCustomer,
    billingStreet: data.billingStreet,
    billingAddressAddition: data.billingAddressAddition,
    billingZipCode: data.billingZipCode,
    billingCity: data.billingCity,
    billingCountry: data.billingCountry,
    billingSalutation: data.billingSalutation,
    billingTitle: data.billingTitle,
    billingFirstName: data.billingFirstName,
    billingLastName: data.billingLastName,
    billingEmail: data.billingEmail,
    billingEmailCc: data.billingEmailCc,
    billingCustomField1Label: data.billingCustomField1Label,
    billingCustomField1Content: data.billingCustomField1Content,
    billingCustomField2Label: data.billingCustomField2Label,
    billingCustomField2Content: data.billingCustomField2Content
  }

  const responseData = await fetchData(payload, API_DATA.billing.endPointEdit, context, 'dataChangeFailed', false)

  try {
    console.log('responseData', responseData)
    setState(context.setCompleteDataSet, 'billing', responseData.response.billing_info)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'dataSuccessfullyChanged',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}
