import { useContext, useEffect, useState } from 'react'
import {
  COUNTRIES,
  MODAL_TYPES,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_OPTIONS,
  SELECT_OPTIONS
} from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { getOptionByValue } from '../../../utils/helper/Helper'
import { ButtonPrimary, InfoElement, InfoList } from '../../../utils/elements/miscElements'
import BillingDataForm from './BillingDataForm'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'
import { saveBillingFormData } from '../shopUtils'

const BillingOverview = ({ billingData, orderData, isOnConfirmationPage, billingDataValid }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const initialData = {
    billingCustomer: completeDataSet.billing?.billingCustomer,
    billingStreet: completeDataSet.billing?.billingStreet,
    billingAddressAddition: completeDataSet?.billing.billingAddressAddition,
    billingZipCode: completeDataSet.billing?.billingZipCode,
    billingCity: completeDataSet.billing?.billingCity,
    billingCountry: completeDataSet.billing?.billingCountry,
    billingSalutation: completeDataSet.billing?.billingSalutation,
    billingTitle: completeDataSet.billing?.billingTitle,
    billingFirstName: completeDataSet.billing?.billingFirstName,
    billingLastName: completeDataSet.billing?.billingLastName,
    billingEmail: completeDataSet.billing?.billingEmail,
    billingEmailCc: completeDataSet.billing?.billingEmailCc,
    billingCustomField1Label: completeDataSet?.billing.billingCustomField1Label,
    billingCustomField1Content: completeDataSet?.billing.billingCustomField1Content,
    billingCustomField2Label: completeDataSet?.billing.billingCustomField2Label,
    billingCustomField2Content: completeDataSet?.billing.billingCustomField2Content
  }
  const [formValid, setFormValid] = useState(false)
  const [formData, setFormData] = useState(initialData)

  const resetFormData = () => {
    setFormData(initialData)
  }

  useEffect(() => {
    updateModalProps(context, setFormValid, formValid, formData, setFormData)
  }, [formValid, formData])

  const AddBillingInfoButton = () => (
    <ButtonPrimary
      content={'+ ' + t('add')}
      style={{ width: '120px' }}
      onClick={(e) => {
        e.preventDefault()
        resetFormData()
        updateModalProps(context, setFormValid, formValid, formData, setFormData)
        context.setModalOpen(true)
      }}
    />
  )

  const ChangeBillingInfoButon = () => (
    <button
      style={{ backgroundColor: 'transparent', display: 'flex', padding: 0 }}
      onClick={(e) => {
        e.preventDefault()
        resetFormData()
        updateModalProps(context, setFormValid, formValid, formData, setFormData)
        context.setModalOpen(true)
      }}>
      <span
        className="svg-icon icon-pen"
        style={{
          justifySelf: 'end',
          alignSelf: 'start',
          cursor: 'pointer'
        }}
      />
    </button>
  )

  return (
    <>
      {!billingDataValid ? (
        <AddBillingInfoButton />
      ) : (
        <Container isOnConfirmationPage={isOnConfirmationPage}>
          <InfoList>
            <InfoElement
              label="address"
              value={
                <>
                  <BillingName {...{ billingData }} />
                  <BillingContact {...{ billingData }} />
                  <BillingStreet {...{ billingData }} />
                  <BillingAddressAddition {...{ billingData }} />
                  <BillingZipCode {...{ billingData }} />
                  <BillingCountry {...{ billingData }} />
                </>
              }
            />
            <BillingCustomFields {...{ billingData }} />
            <BillingEmailAndPaymentMethod {...{ billingData, isOnConfirmationPage, orderData }} />
          </InfoList>
          {context.loggedInAsAdmin && (
            <div>
              <ChangeBillingInfoButon />
            </div>
          )}
        </Container>
      )}
    </>
  )
}

export default BillingOverview

const updateModalProps = (context, setFormValid, formValid, formData, setFormData) => {
  context.setModalProps({
    headline: 'invoiceData',
    content: <BillingDataForm setFormValid={setFormValid} formData={formData} setFormData={setFormData} showInModal />,
    type: MODAL_TYPES.payment,
    buttonPrimary: (
      <ButtonPrimary
        modalButton
        content="save"
        disabled={!formValid}
        onClick={() => {
          saveBillingFormData({ data: formData, context: context })
          context.setModalOpen(false)
        }}
      />
    ),
    showCloseButton: true,
    hideFooter: false
  })
}

const BillingName = ({ billingData }) => (
  <span>
    {billingData?.billingCustomer || ''}
    <br />
  </span>
)

const BillingContact = ({ billingData }) => {
  const t = useTranslate()
  return billingData?.billingLastName ? (
    <span>
      {getOptionByValue(t(SELECT_OPTIONS.contactSalutations), billingData?.billingSalutation).label || ''}{' '}
      {billingData?.billingFirstName} {billingData?.billingLastName}
      <br />
    </span>
  ) : (
    <></>
  )
}

const BillingStreet = ({ billingData }) => (
  <span>
    {billingData?.billingStreet} <br />
  </span>
)

const BillingAddressAddition = ({ billingData }) => {
  return billingData?.billingAddressAddition ? (
    <span>
      {billingData?.billingAddressAddition} <br />
    </span>
  ) : (
    <></>
  )
}

const BillingZipCode = ({ billingData }) => (
  <span>
    {billingData?.billingZipCode} {billingData?.billingCity}
    <br />
  </span>
)

const BillingCountry = ({ billingData }) => (
  <span>
    {getOptionByValue(COUNTRIES, billingData?.billingCountry).label || ''} <br />
  </span>
)

const BillingCustomFields = ({ billingData }) => {
  const showCustomFields = billingData?.billingCustomField1Label || billingData?.billingCustomField2Label
  if (!showCustomFields) return <></>
  return (
    <>
      {billingData?.billingCustomField1Label && (
        <InfoElement label={billingData?.billingCustomField1Label} value={billingData?.billingCustomField1Content} />
      )}
      {billingData?.billingCustomField2Label && (
        <InfoElement label={billingData?.billingCustomField2Label} value={billingData?.billingCustomField2Content} />
      )}
    </>
  )
}

const BillingEmailAndPaymentMethod = ({ billingData, isOnConfirmationPage, orderData }) => {
  const paymentMethod = PAYMENT_OPTIONS.find((opt) => opt.title === orderData?.paymentMethod)?.content || 'invoice'
  const payMethodLabel = <PayMethodLabel {...{ paymentMethod, orderData }} />

  return (
    <>
      <InfoElement label="sendByEmailTo" value={billingData?.billingEmail} />
      {billingData?.billingEmailCc && <InfoElement label="emailCc" value={billingData?.billingEmailCc} />}
      {isOnConfirmationPage && <InfoElement label="paymentMethodLabel" value={payMethodLabel} />}
    </>
  )
}

const PayMethodLabel = ({ paymentMethod, orderData }) => {
  const t = useTranslate()
  const creditCard = orderData?.creditCard
  if (paymentMethod === PAYMENT_METHOD_CREDIT_CARD && creditCard) {
    return <CreditCardInfos {...{ creditCard }} />
  }
  return t(paymentMethod)
}

const CreditCardInfos = ({ creditCard }) => {
  const t = useTranslate()
  return (
    <>
      <span>{t('creditCard')}</span> (<span className="capitalizeFirstLetter">{creditCard.brand}</span>)
      <br />
      <span>
        <span style={{ letterSpacing: '1.5px' }}>•••• •••• ••••</span> {creditCard.last4}
      </span>
      <br />
      <span>
        {t('cardValidUntil')} {creditCard.exp_month}/{creditCard.exp_year}
      </span>
    </>
  )
}

const Container = styled.div`
  background-color: ${(props) => (props.isOnConfirmationPage ? 'none' : 'var(--box-background)')};
  padding: ${(props) => (props.isOnConfirmationPage ? '' : 'var(--space-5)')};
  border-radius: var(--bdr-2);
  display: grid;
  grid-template-columns: 1fr auto;
  & p:last-child {
    margin: 0;
  }
`
