import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getParamsJson } from '../../utils/helper/urlParamsHelper'
import { TabContainer, Tab, TabLabel } from './tabGroupStyles'
import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'
import Select from 'react-select'
import { selectStyles } from '../../utils/elements/miscElements'

const TabGroup = ({ tabs, position }) => {
  const t = useTranslate()
  const location = useLocation()

  const params = getParamsJson(location)
  const tab = params.find((param) => param.id === 'tab')

  const [active, setActive] = useState(tab?.value || tabs[0].id)
  const activeTabContent = tabs.find((tab) => tab.id === active)?.content
  const showSelectDropdown = tabs.length > 5

  const handleClick = (tab) => {
    setActive(tab.id)
  }

  const options = tabs.map((tab) => ({ value: tab.id }))

  return (
    <InnerContainer className={`tab-group-container ${position || ''}`}>
      <TabContainer className="tab-container">
        {showSelectDropdown ? (
          <Select
            options={t(options)}
            onChange={handleClick}
            styles={selectStyles}
            value={t(options).filter((tab) => tab.id === active)}
          />
        ) : (
          tabs.map((tab, i) => (
            <Tab
              className={active === tab.id ? 'active' : 'inactive'}
              key={i}
              active={active === tab.id}
              onClick={() => handleClick(tab)}>
              <TabLabel>{t(tab.label)}</TabLabel>
            </Tab>
          ))
        )}
      </TabContainer>
      {activeTabContent}
    </InnerContainer>
  )
}

export default TabGroup

const InnerContainer = styled.div`
  background-color: var(--tabgroup-bg-color);
  padding: var(--space-6);
  border-radius: var(--bdr-2);
  box-shadow: var(--sh-1);
  display: inline-flex;
  flex-direction: column;
`
