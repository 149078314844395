import { ERROR_MSG, FIELD_LABELS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { InfoElement, InfoList } from '../../utils/elements/miscElements'
import { getOptionByValue } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import { Tile, TileHeadline } from '../table/tableElements'

const AddAssessmentSecuritySettingsTile = ({ data }) => {
  const t = useTranslate()

  return (
    <Tile width="200px">
      <TileHeadline content="testSecurity" />
      <InfoList>
        <InfoElement
          label={FIELD_LABELS.useChildItems}
          value={getOptionByValue(t(SELECT_OPTIONS.useChildItems), data.useChildItems).label || ERROR_MSG}
        />
        <InfoElement
          label={FIELD_LABELS.tabTracking}
          value={getOptionByValue(t(SELECT_OPTIONS.tabTracking), data.tabTracking).label || ERROR_MSG}
        />
        <InfoElement
          label={FIELD_LABELS.cameraSupervision}
          value={getOptionByValue(t(SELECT_OPTIONS.cameraSupervision), data.useCameraSupervision).label || ERROR_MSG}
        />
      </InfoList>
    </Tile>
  )
}

export default AddAssessmentSecuritySettingsTile
