import ReactTooltip from 'react-tooltip'
import { TAB_TRACKING_INDEX } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'

const TabTrackingLabel = ({ index, rowData }) => {
  const t = useTranslate()
  const data = getTabTrackingLabelData(index)
  const tabTrackingInfoText = getTabTrackingInfoText({
    windowChangeCount: rowData?.relatedResult?.windowChangeCount,
    windowChangeTime: rowData?.relatedResult?.windowChangeTime,
    t: t
  })

  const style = {
    container: {
      display: 'inline-flex',
      alignItems: 'center'
    },
    icon: {
      backgroundColor: data.color,
      marginLeft: 'var(--space-2)',
      cursor: 'initial'
    }
  }

  return (
    <>
      <div style={style.container}>
        <span>{t(data.text)}</span>
        {data.icon && (
          <span
            data-tip=""
            data-for={`cheatingInfo_${rowData?.ptNumber || null}`}
            className={`svg-icon ${data.icon}`}
            style={style.icon}
          />
        )}
      </div>

      {rowData && (
        <ReactTooltip
          id={`cheatingInfo_${rowData?.ptNumber || null}`}
          aria-haspopup="true"
          effect="solid"
          place="left"
          backgroundColor="var(--c-tooltip-bg)"
          textColor="var(--tooltip-text-color)"
          getContent={() => tabTrackingInfoText}
        />
      )}
    </>
  )
}

export default TabTrackingLabel

const getTabTrackingInfoText = ({ windowChangeCount, windowChangeTime, t }) => {
  if (!windowChangeCount) return ''
  return <>{t('tabTrackingInfo', windowChangeCount, windowChangeTime)}</>
}

export const getTabTrackingLabelData = (index) => {
  switch (index) {
    case TAB_TRACKING_INDEX.noWindowChanges:
    default:
      return { text: 'none' }
    case TAB_TRACKING_INDEX.someWindowChanges:
      return { text: 'few', color: 'var(--yellow-600)', icon: 'icon-exclamation' }
    case TAB_TRACKING_INDEX.manyWindowChanges:
      return { text: 'frequent', color: 'var(--error-message-color)', icon: 'icon-exclamation' }
  }
}

export const getTabTrackingLabels = ({ rowData }) => ({
  [TAB_TRACKING_INDEX.noWindowChanges]: (
    <TabTrackingLabel index={TAB_TRACKING_INDEX.noWindowChanges} rowData={rowData} />
  ),
  [TAB_TRACKING_INDEX.someWindowChanges]: (
    <TabTrackingLabel index={TAB_TRACKING_INDEX.someWindowChanges} rowData={rowData} />
  ),
  [TAB_TRACKING_INDEX.manyWindowChanges]: (
    <TabTrackingLabel index={TAB_TRACKING_INDEX.manyWindowChanges} rowData={rowData} />
  )
})
