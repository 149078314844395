import { useEffect, useState } from 'react'

const getScrollPosition = (elem) => elem?.scrollLeft || 0

const useScrollPosition = (elem) => {
  const [scrollPosition, setScrollPosition] = useState(getScrollPosition(elem))

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(getScrollPosition(elem))
    }
    elem?.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => elem?.removeEventListener('scroll', updatePosition)
  }, [elem])

  return scrollPosition
}

export default useScrollPosition
