import React, { useContext, useState } from 'react'
import { getProcessSelectOptions, hasSpecialChars, isPotentialsCrossTest, setState } from '../../../utils/helper/Helper'
import Select from 'react-select'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import {
  WizardForm,
  Label,
  OptionEntry,
  OptionDescription,
  PageHeadline,
  selectStyles,
  ErrorMessage
} from '../../../utils/elements/miscElements'
import useReferenceTestSelectOptions from '../../../utils/hooks/useReferenceTestSelectOptions'
import { AppContext } from '../../../utils/context/AppContext'
import { FORM_ERRORS, MAX_CHARS, SELECT_OPTIONS } from '../../../utils/constants/constants'
import ToggleButton from '../../../components/toggleButton/ToggleButton'
import { validateProcessNameInput } from '../../addProcessWizard/addProcessUtils'
import ProcessTestsPreview from '../../../components/processTestsPreview/ProcessTestsPreview'
import useTranslate from '../../../utils/hooks/useTranslate'

const AssessmentNameForm = ({ data, setData, optionDescriptions }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const { processes } = context.completeDataSet
  const processSelectOptions = getProcessSelectOptions({ processList: processes, allowEmpty: true, t: t })

  const selectedProcess = getSelectedProcess(data, processes)
  const processName = getProcessName(data, selectedProcess)
  const relatedAssessments = data.createNewProcess ? [] : selectedProcess?.relatedAssessments || []
  const config = data?.parentConfig?.availableConfigs?.find(
    (config) => config.configUuid === data.configUuid && config.isLatest
  )

  const referenceTestSelectOptions = useReferenceTestSelectOptions(data.processUuid)

  const handleProcessSelectChange = (selectedOption) => {
    setData((prev) => ({
      ...prev,
      processUuid: selectedOption.uuid,
      referenceAssessmentUuid: null
    }))
  }

  return (
    <>
      <PageHeadline>{t('testNameAndProcess')}</PageHeadline>
      <ScrollContainer>
        <WizardForm style={{ gap: 'var(--space-6)' }}>
          <OptionEntry hasHint>
            <Label fullWidth>{t('newTestName')} *</Label>
            <AssessmentNameInput {...{ data, setData }} />
            <OptionDescription description={optionDescriptions.assessmentName} />
          </OptionEntry>

          {!isPotentialsCrossTest(data.configType) && context.loggedInAsAdmin && (
            <OptionEntry hasHint>
              <Label fullWidth>{t('assignmentToProcess')}</Label>
              <ToggleButton
                options={SELECT_OPTIONS.createNewProcess}
                data={data}
                setData={setData}
                dataKey="createNewProcess"
              />
              <OptionDescription description={optionDescriptions.process} />
            </OptionEntry>
          )}

          <OptionEntry hasHint>
            {data.createNewProcess ? (
              <>
                <Label fullWidth>{t('newProcessName')} *</Label>
                <NewProcessNameInput {...{ data, setData }} />
              </>
            ) : (
              <>
                <Label fullWidth>{t('processes')} *</Label>
                <Select
                  options={processSelectOptions}
                  id="process"
                  onChange={handleProcessSelectChange}
                  value={processSelectOptions.find((option) => option.uuid === data.processUuid)}
                  noOptionsMessage={() => t('noProcesses')}
                  placeholder={t('selectProcess')}
                  styles={selectStyles}
                  isOptionDisabled={(option) => option.disabled}
                />
                {!context.loggedInAsAdmin && <OptionDescription description={optionDescriptions.processShort} />}
              </>
            )}
          </OptionEntry>

          {isPotentialsCrossTest(data.configType) && (
            <OptionEntry hasHint>
              <Label fullWidth>{t('referenceTest')} *</Label>
              <Select
                options={referenceTestSelectOptions}
                id="referenceTest"
                onChange={(selectedOption) => setState(setData, 'referenceAssessmentUuid', selectedOption.value)}
                value={referenceTestSelectOptions.find((option) => option.value === data.referenceAssessmentUuid) || ''}
                noOptionsMessage={() => t('noReferenceTests')}
                placeholder={t('selectReferenceTest')}
                styles={selectStyles}
                isOptionDisabled={(option) => option.disabled}
              />
              <OptionDescription description={optionDescriptions.referenceAssessment} />
            </OptionEntry>
          )}

          <OptionEntry hasHint>
            <Label fullWidth>{t('preview')}</Label>
            <ProcessTestsPreview
              processName={processName}
              existingTests={relatedAssessments}
              newTestName={data.assessmentName}
              newConfig={config}
              referenceAssessmentUuid={data.referenceAssessmentUuid}
            />
          </OptionEntry>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default AssessmentNameForm

const AssessmentNameInput = ({ data, setData }) => {
  const t = useTranslate()
  const [errorMsg, setErrorMsg] = useState('')

  const handleNameChange = (e, setData, setErrorMsg) => {
    const input = e.target.value
    validateAssessmentName(input, setErrorMsg, setData, t)
    setState(setData, 'assessmentName', input)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <div style={{ display: 'grid' }}>
      <input
        id="assessmentName"
        type="text"
        onChange={(e) => handleNameChange(e, setData, setErrorMsg)}
        onKeyDown={handleKeyDown}
        value={data.assessmentName}
        className={errorMsg && 'has-error'}
      />
      <ErrorMessage visible={errorMsg}>{errorMsg}</ErrorMessage>
    </div>
  )
}

const NewProcessNameInput = ({ data, setData }) => {
  const t = useTranslate()
  const [errorMsg, setErrorMsg] = useState('')

  const handleNewProcessNameChange = (e, setData, setErrorMsg) => {
    const input = e.target.value
    const isValid = validateProcessNameInput(input, setErrorMsg, false, t)
    setData((prev) => ({
      ...prev,
      newProcessNameIsValid: isValid,
      processName: input
    }))
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <div>
      <input
        style={{ width: '100%' }}
        id="newProcessName"
        type="text"
        onChange={(e) => handleNewProcessNameChange(e, setData, setErrorMsg)}
        value={data.processName}
        className={errorMsg && 'has-error'}
        onKeyDown={handleKeyDown}
      />
      <ErrorMessage visible={errorMsg}>{errorMsg}</ErrorMessage>
    </div>
  )
}

const validateAssessmentName = (name, setErrorMsg, setData, t) => {
  const errorMessages = {
    nameEmpty: t(FORM_ERRORS.testNameCannotBeEmpty),
    tooManyChars: t('maxCharsPolite', MAX_CHARS.testName),
    noSpecialChars: t(FORM_ERRORS.forbiddenCharacter)
  }
  if (name.trim().length === 0) {
    setErrorMsg(errorMessages.nameEmpty)
    setState(setData, 'assessmentNameIsValid', false)
    return
  }
  if (name.length > MAX_CHARS.testName) {
    setErrorMsg(errorMessages.tooManyChars)
    setState(setData, 'assessmentNameIsValid', false)
    return
  }
  if (hasSpecialChars(name)) {
    setErrorMsg(errorMessages.noSpecialChars)
    setState(setData, 'assessmentNameIsValid', false)
    return
  }
  setErrorMsg('')
  setState(setData, 'assessmentNameIsValid', true)
}

const getSelectedProcess = (data, processes) => {
  return processes.find((process) => process.processUuid === data.processUuid) || null
}

const getProcessName = (data, process) => {
  if (data.createNewProcess) {
    return data.processName
  }
  if (process) {
    return process.processName
  }
  return ''
}
