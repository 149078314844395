import styled from 'styled-components'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FilterContext } from '../../../utils/context/FilterContext'
import FieldSelector from './FieldSelector'
import RelationSelector from './RelationSelector'
import ValueSelector from './ValueSelector'
import { useSecondValue, usingEmptyRelation } from '../filterUtils'
import { TableContext } from '../../table/DataTable'
import { EditDiscardButton, EditSaveButton, Icon } from '../../customInput/customInputElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import useTrapFocus from '../../../utils/hooks/useTrapFocus'
import useEventListener from '../../../utils/hooks/useEventListener'

const FilterBuilder = ({ columns, setShowNewFilter, filter, setEditFilterId, hasOverflow, buttonRef }) => {
  const { updateFilter, activeFilters, addNewFilter } = useContext(FilterContext)
  const { tableName, gotoPage } = useContext(TableContext)
  const t = useTranslate()
  const filterBuilderRef = useRef(null)
  const updateFocusableElements = useTrapFocus()

  const currentFilter = activeFilters.find((af) => af.id === filter?.id)

  const [field, setField] = useState({
    value: currentFilter?.field?.value || '',
    label: currentFilter?.field?.label || '',
    filterOptions: currentFilter?.field?.filterOptions || {}
  })

  const [relation, setRelation] = useState({
    value: currentFilter?.relation?.value || '',
    label: currentFilter?.relation?.label || ''
  })

  const [value, setValue] = useState({
    value: currentFilter?.value?.value,
    label: currentFilter?.value?.label,
    useExactDate: currentFilter?.value?.useExactDate || false
  })

  const [value2, setValue2] = useState({
    value: currentFilter?.value2?.value,
    label: currentFilter?.value2?.label,
    useExactDate: currentFilter?.value2?.useExactDate || false
  })

  const isValid = validateInput(field, relation, value, value2)
  
  useEffect(updateFocusableElements, [isValid])

  const filterOptions = columns.find((col) => col.id === field.value)?.filterOptions

  const resetValue = (setterFn) => {
    setterFn({
      value: null,
      label: null,
      useExactDate: false
    })
  }

  useEffect(() => {
    if (usingEmptyRelation(relation)) {
      resetValue(setValue)
    }
    if (!useSecondValue(relation)) {
      resetValue(setValue2)
    }
  }, [relation.value])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        event.preventDefault()
        currentFilter ? setEditFilterId(null) : setShowNewFilter(false)
      }
    },
    [currentFilter]
  )

  useEventListener('keydown', handleKeyDown)

  const applyFilter = () => {
    gotoPage(0)
    if (currentFilter) {
      setEditFilterId(null)
      updateFilter({
        id: currentFilter.id,
        field: field,
        value: value,
        value2: value2,
        relation: relation,
        state: 'active',
        tableName: tableName
      })
    } else {
      buttonRef.current.style.transitionDuration = '0s'
      filterBuilderRef.current.style.transitionDuration = '0s'
      setShowNewFilter(false)
      addNewFilter({
        field: field,
        value: value,
        value2: value2,
        relation: relation,
        tableName: tableName
      })
    }
  }

  return (
    <FilterBuilderContainer
      className="modal"
      ref={filterBuilderRef}
      adjustSpacing={!currentFilter}
      hasOverflow={hasOverflow}>
      <h3>{currentFilter ? t('editFilter') : t('addFilter')}</h3>
      <div style={{ display: 'grid', gap: '8px' }}>
        <FieldSelector {...{ field, setField, columns, setValue, setRelation }} />
        <RelationSelector {...{ relation, setRelation, filterOptions }} />
        <ValueSelector
          {...{
            value,
            setValue,
            field,
            relation,
            applyFilter,
            currentFilter
          }}
        />
        {useSecondValue(relation) && (
          <ValueSelector
            value={value2}
            setValue={setValue2}
            field={field}
            relation={relation}
            applyFilter={applyFilter}
            currentFilter={currentFilter}
          />
        )}
      </div>
      <ButtonContainer className="filter-builder-button-container">
        {currentFilter && <DeleteButton {...{ currentFilter }} />}
        <CancelButton {...{ currentFilter, setShowNewFilter, setEditFilterId }} />
        <ApplyButton {...{ applyFilter, isValid }} />
      </ButtonContainer>
    </FilterBuilderContainer>
  )
}

export default FilterBuilder

const DeleteButton = ({ currentFilter }) => {
  const { removeFilter } = useContext(FilterContext)
  const clear = () => {
    removeFilter(currentFilter.id)
  }
  return (
    <EditDiscardButton style={{ marginRight: 'auto' }} onClick={clear}>
      <Icon
        style={{ transform: 'scale(0.6)', backgroundColor: 'var(--icon-color)' }}
        className="svg-icon icon-trash"
      />
    </EditDiscardButton>
  )
}

const CancelButton = ({ currentFilter, setShowNewFilter, setEditFilterId }) => (
  <EditDiscardButton onClick={() => (currentFilter ? setEditFilterId(null) : setShowNewFilter(false))}>
    <Icon
      style={{ transform: 'scale(0.7)', backgroundColor: 'var(--icon-color)' }}
      className="svg-icon icon-x-noborder-mask"
    />
  </EditDiscardButton>
)

const ApplyButton = ({ applyFilter, isValid }) => {
  return (
    <EditSaveButton disabled={!isValid} onClick={() => applyFilter()}>
      <Icon className="svg-icon icon-check-noborder-mask" />
    </EditSaveButton>
  )
}

const FilterBuilderContainer = styled.div`
  position: absolute;
  min-width: 200px;
  width: 100%;
  z-index: 9;
  top: ${(props) => (props.adjustSpacing ? '40px' : '58px')};
  right: ${(props) => (props.hasOverflow ? '0' : '')};
  box-shadow: var(--shadow-default);
  display: grid;
  flex-wrap: wrap;
  gap: var(--space-4);
  align-items: center;
  padding: var(--space-4);
  border-radius: var(--bdr-3);
  background-color: var(--filter-builder-bg-color);
  h3 {
    margin: 0;
    line-height: 1;
    font-size: var(--fs-4);
  }
`

const ButtonContainer = styled.div`
  display: flex;
  gap: var(--space-2);
  align-items: center;
  transform: translateY(1px);
  justify-content: end;
`

const valueIsValid = (value) => value !== null && value !== undefined && value !== ''

const validateInput = (field, relation, value, value2) => {
  if (usingEmptyRelation(relation)) {
    return field.value && relation.value
  }
  if (useSecondValue(relation)) {
    return field.value && relation.value && valueIsValid(value.value) && valueIsValid(value2.value)
  }
  return field.value && relation.value && valueIsValid(value.value)
}
