import { API_DATA, DEFAULT_DIM_LOGIC_NR, ERROR_MSG } from '../../../utils/constants/constants'
import { CustomInputList, Tile, TileHeadline } from '../../table/tableElements'
import CustomInput from '../../customInput/CustomInput'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { getOptionByValue } from '../../../utils/helper/Helper'
import MatchingNormHelp from '../../helpModal/MatchingNormHelp'
import { getOptions } from './PotentialsWeightTile'
import useTranslate from '../../../utils/hooks/useTranslate'
import DimensionDisplay from './DimensionDisplay'

const MatchingTile = ({ assessment }) => {
  const { completeDataSet } = useContext(AppContext)
  const t = useTranslate()
  const options = getOptions(completeDataSet.dimensionLogics, assessment, t)

  const currentDimLogicNr = assessment.dimLogicNr || DEFAULT_DIM_LOGIC_NR
  const config = assessment.relatedConfig
  const canApplyMatchingNorms = assessment.relatedProcess.isEditor()

  return (
    <Tile width="500px">
      <TileHeadline content="jobMatching" />
      <CustomInputList>
        <CustomInput
          label="targetProfile"
          content={getOptionByValue(options, currentDimLogicNr).label || ERROR_MSG}
          options={options}
          value={currentDimLogicNr}
          apiData={API_DATA.assessments}
          idValue={assessment.assessmentUuid}
          dataKey="dimLogicNr"
          type="select"
          isDimensionLogic
          helpContent={<MatchingNormHelp />}
          disabled={!canApplyMatchingNorms}
          DimensionDisplay={DimensionDisplay}
          config={config}
          assessment={assessment}
        />
      </CustomInputList>
    </Tile>
  )
}

export default MatchingTile
