import { useContext, useRef, useState } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import { getLoggedInContact } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { CustomTableCheckbox } from '../../table/tableElements'
import { NewsletterContainer, Label } from './notificationSettingsElements'
import { saveData } from './notificationSettingsUtils'

const Newsletter = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contact = getLoggedInContact(context.completeDataSet)
  const [newsletterSubscribed, setNewsletterSubscribed] = useState(contact?.newsletter || false)
  const submitButtonRef = useRef(null)

  return (
    <div>
      <p>{t('newsletterExplanation')}</p>
      <NewsletterContainer>
        <Label onClick={() => setNewsletterSubscribed(!newsletterSubscribed)}>
          <CustomTableCheckbox
            onKeyPress={() => setNewsletterSubscribed(!newsletterSubscribed)}
            style={{ justifySelf: 'left', transform: 'unset' }}
            className={`custom-checkbox ' ${newsletterSubscribed ? 'svg-icon icon-check-noborder' : ''}`}
            checked={newsletterSubscribed}
          />
          <span>{t('subscribeToNewsletter')}</span>
        </Label>
      </NewsletterContainer>
      <ButtonPrimary
        ref={submitButtonRef}
        style={{ marginTop: 'var(--space-6)', marginBottom: 'var(--space-7)' }}
        content="save"
        onClick={() => saveData({ key: 'newsletter', value: newsletterSubscribed }, context)}
      />
    </div>
  )
}

export default Newsletter
