import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'
import GradeLabel from '../gradeLabel/GradeLabel'
import { getFormattedNumber, getOptionByValue } from './../../utils/helper/Helper'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'
import { useContext } from 'react'
import CustomInput from '../customInput/CustomInput'
import { API_DATA, DEFAULT_NORM_NR, ERROR_MSG } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import useRedirect from '../../utils/hooks/useRedirect'
import { PAGES } from '../../utils/constants/pages'
import { getNormOptions } from '../scoringEditors/normEditor/normUtils'
import NormsHelp from '../helpModal/NormsHelp'

const NormsTile = ({ assessment }) => {
  const context = useContext(AppContext)
  const { customNorms } = context.completeDataSet
  const currentNormNr = assessment.normNr || DEFAULT_NORM_NR
  const allNorms = [{ name: 'PERSEO Standard', normNr: DEFAULT_NORM_NR }, ...customNorms]

  const options = getNormOptions(allNorms)
  const canApplyNorms = assessment.relatedProcess.isEditor()

  return (
    <Tile width="190px">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TileHeadline content="grading" />
      </div>
      <CustomInputList style={{ marginTop: 0 }}>
        <CustomInput
          label="gradingRule"
          content={getOptionByValue(options, currentNormNr).label || ERROR_MSG}
          options={options}
          value={currentNormNr}
          apiData={API_DATA.assessments}
          idValue={assessment.assessmentUuid}
          dataKey="normNr"
          type="select"
          isNorm
          helpContent={<NormsHelp />}
          disabled={!canApplyNorms}
          NormsTable={NormsTable}
          assessment={assessment}
        />
      </CustomInputList>
    </Tile>
  )
}

export default NormsTile

export const EditorLink = styled.div`
  margin-top: var(--space-3);
  font-size: var(--fs-2);
  color: var(--text-color-secondary);
  line-height: var(--lh-2);
`

export const LabelIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    background-color: transparent;
    padding: 0;
    border-radius: 2px;
    z-index: 9;
  }
  .icon-pen {
    transform: scale(0.8);
  }
  .textlink {
    font-size: var(--fs-1);
    border: none;
    line-height: var(--lh-2);
    background-color: transparent;
    padding: 0;
    z-index: 9;
    border-radius: var(--bdr-1);
  }
`

const NormsTable = ({ norm }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const redirect = useRedirect()
  const { language, loggedInAsAdmin } = context
  const canEditNorms = loggedInAsAdmin

  return (
    <div>
      {' '}
      <div className="table-outer-container norm-table">
        <div className="table-inner-container">
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>{t('from')}</th>
                <th>{t('upTo')}</th>
                <th>
                  <GradeLabel label={norm.gradeLabel} />
                </th>
              </tr>
            </thead>
            <tbody>
              {norm.normTable.map((row, i) => (
                <tr key={row.grade + i}>
                  <td className="closed">{row.lower_limit}</td>
                  <td className="closed">{Math.min(row.upper_limit, 100)}</td>
                  <td className="closed">{getFormattedNumber(row.grade, language)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {canEditNorms && <EditorLink>{t('changeGradingInSettings', () => redirect(PAGES.scoring))}</EditorLink>}
    </div>
  )
}
