import { TABLE_PAGE_SIZE_OPTIONS } from '../../../../utils/constants/constants'
import styled from 'styled-components'
import useTranslate from '../../../../utils/hooks/useTranslate'
import { useContext } from 'react'
import { PaginationContext } from '../../DataTable'

const ResultsPerPageController = () => {
  const { pageSize, setPageSize, gotoPage } = useContext(PaginationContext)
  const t = useTranslate()

  return (
    <ResultPerPageContainer>
      <span>{t('resultPerPage')}:</span>
      <SelectResultsPerPage
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value))
          gotoPage(0)
        }}>
        {TABLE_PAGE_SIZE_OPTIONS.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </SelectResultsPerPage>
    </ResultPerPageContainer>
  )
}

export default ResultsPerPageController

const ResultPerPageContainer = styled.div`
  display: flex;
  align-items: center;
`

const SelectResultsPerPage = styled.select`
  margin-left: var(--space-2);
  font-family: 'Open Sans';
  font-size: var(--fs-2);
  appearance: none;
  border: none;
  font-size: var(--fs-3);
  color: var(--text-color-primary);
  cursor: pointer;
  background-color: transparent;
`
