import styled from 'styled-components'

export const Trashbutton = styled.button`
  height: 32px;
  background-color: transparent;
  align-self: flex-start;
  margin-top: 25px;
  display: flex;
  padding: var(--space-2);
`

export const CustomFieldContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 34px;
  align-items: flex-start;
  &:last-child {
    margin-bottom: 10px;
  }
`
