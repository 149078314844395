import styled from 'styled-components'

export const SettingsContainer = styled.div.attrs({
  className: 'settings-container'
})`
  margin: var(--inset);
  background-color: var(--header-bg-color);
  padding: ${(props) => (props.containsPageGroup ? 'var(--inset)' : 'var(--inset) var(--inset) 0 var(--inset)')};
  border: 1px solid var(--settings-container-border-color);
  border-radius: var(--bdr-4);
  width: 100%;
  overflow: hidden;
  position: relative;
  container-name: settings-container;
  container-type: inline-size;
  p {
    font-size: var(--fs-3);
  }
  h3 {
    font-size: var(--fs-5);
    margin-bottom: var(--space-4);
  }
  @media screen and (max-width: 600px) {
    width: auto;
  }
  .scroll-container {
    padding: 0 var(--space-2) var(--space-2) var(--space-2);
    margin: 0 calc(-1 * var(--space-2));
  }
  .page-group .scroll-container {
    padding-bottom: var(--space-8);
    &:after {
      background: linear-gradient(to bottom, #f3f5f600, var(--header-bg-color));
    }
  }
  .settings-nav {
    &.tab-group-container {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      width: 100%;
    }
    .tab-container {
      transform: translateX(0);
      border-bottom: 2px solid var(--thick-underline-color);
      padding-bottom: var(--space-3);
      flex-wrap: wrap;
      button:not(.active):hover {
        background-color: var(--settings-nav-tab-hover-color);
      }
      .active {
        background-color: var(--settings-nav-active-tab-bg-color);
      }
    }
  }
`
