import { Tile, TileHeadline } from '../table/tableElements'
import { FIELD_LABELS } from '../../utils/constants/constants'
import { InfoElement, InfoList } from '../../utils/elements/miscElements'
import useTranslate from '../../utils/hooks/useTranslate'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const AddToTestCheckInputTile = ({ data, selectedParticipants, maximumCreditSum }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const { processes, assessments } = context.completeDataSet
  return (
    <Tile>
      <TileHeadline content="yourSelection" />
      <InfoList>
        <InfoElement
          label="processes"
          value={processes.find((process) => process.processUuid === data.processUuid)?.processName}
        />

        <InfoElement
          label={data.assessmentUuids.length > 1 ? 'Tests' : 'Test'}
          value={
            <ul style={{ padding: 0, listStyle: 'none', margin: 0 }}>
              {data.assessmentUuids.map((assessmentUuid) => (
                <li key={assessmentUuid}>
                  {assessments.find((assessment) => assessment.assessmentUuid === assessmentUuid).assessmentName}
                </li>
              ))}
            </ul>
          }
        />

        <InfoElement label="numberOfParticipants" value={selectedParticipants.length} />

        <InfoElement
          label={FIELD_LABELS.maxCredits}
          value={
            <span>
              {maximumCreditSum} {maximumCreditSum === 0 ? `(${t('free')})` : ''}
            </span>
          }
        />
      </InfoList>
    </Tile>
  )
}

export default AddToTestCheckInputTile
