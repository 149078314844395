import AssessmentDashboardTab from '../../components/tabs/AssessmentDashboardTab'
import AssessmentSettingsTab from '../../components/tabs/AssessmentSettingsTab'
import TabGroup from '../../components/tabGroup/TabGroup'
import ActionsTab from '../../components/tabs/ActionsTab'
import { ENTITIES } from '../../utils/constants/constants'
import AssessmentScoringTab from '../../components/tabs/AssessmentScoringTab'
import AssessmentDetailsTab from '../../components/tabs/AssessmentDetailsTab'

export const AssessmentRowSubComponent = (row) => {
  const assessment = row.original
  const tabs = [
    {
      label: 'statistics',
      id: 'dashboard',
      content: <AssessmentDashboardTab assessment={assessment} />
    },
    {
      label: 'infos',
      id: 'information',
      content: <AssessmentDetailsTab assessment={assessment} />
    },
    {
      label: 'scoring',
      id: 'scoring',
      content: <AssessmentScoringTab assessment={row.original} />
    },
    {
      label: 'settings',
      id: 'settings',
      content: <AssessmentSettingsTab assessment={assessment} />
    },
    {
      label: 'actions',
      id: 'actions',
      content: <ActionsTab row={assessment} entity={ENTITIES.assessments} />
    }
  ]

  const tabsWithoutScoring = tabs.filter((tab) => tab.id !== 'scoring')

  return <TabGroup tabs={assessment.producesNumericResult() ? tabs : tabsWithoutScoring} />
}

export default AssessmentRowSubComponent
