import ProgressBar from '../progressBar/ProgressBar'
import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'

const LoadingIndicator = ({ progress, loadingMessage }) => {
  const t = useTranslate()
  const progressBarOptions = {
    showProgressValue: false,
    showBackground: true,
    isSmall: true,
    color: 'var(--progress-bar-color)',
    showAnimation: true
  }

  return (
    <LoadingBarOuterContainer>
      <LoadingBarInnerContainer>
        <LoadingNotice>{t(loadingMessage)}</LoadingNotice>
        <ProgressBar progressPercent={progress * 100} options={progressBarOptions} />
      </LoadingBarInnerContainer>
    </LoadingBarOuterContainer>
  )
}

export default LoadingIndicator

const LoadingNotice = styled.span`
  margin-bottom: var(--space-3);
  color: var(--text-color-primary);
`
const LoadingBarInnerContainer = styled.div`
  height: 100px;
  width: 206px;
  background-color: var(--main-bg-color);
  padding: var(--space-3);
  border-radius: var(--bdr-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const LoadingBarOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  transform: translateY(-50px);
`
