import styled from 'styled-components'

export const OuterContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: var(--space-3) var(--space-5);
`

export const Data = styled.span`
  line-height: var(--lh-3);
  font-size: var(--fs-3);
  font-weight: var(--fw-2);
`

export const TestInfoElement = styled.li`
  list-style: none;
  margin-right: var(--space-3);
`

export const TestInfos = styled.ul`
  padding: 0;
  display: flex;
`

export const TestIcon = styled.div`
  grid-row: 1 / 3;
  width: var(--icon-2);
  height: var(--icon-2);
  background-color: ${(props) => props.color};
`

export const ColorBar = styled.div`
  background-color: ${(props) => props.color};
  position: absolute;
  width: 8px;
  left: 0;
  top: 0;
  bottom: 0;
`

export const ErrorNotice = styled.div`
  color: var(--text-color-highlight);
  margin-top: var(--space-2);
  grid-column: 2/3;
  line-height: var(--lh-1);
  font-size: var(--fs-2);
  text-align: left;
`

export const NameContainer = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  align-items: center;
  gap: 0 var(--space-5);
`

export const TestName = styled.div`
  font-size: var(--fs-4);
  line-height: var(--lh-3);
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  text-align: left;
`

export const NewFlag = styled.span`
  font-size: var(--fs-2);
  font-weight: var(--fw-3);
  color: var(--test-info-card-new-flag-color);
  margin-left: var(--space-3);
  text-transform: uppercase;
`
