import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const StartedDurationHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <StartedDurationHelpEnglish />
  } else {
    return <StartedDurationHelpGerman />
  }
}

export default StartedDurationHelp

const StartedDurationHelpGerman = () => (
  <div>
    <p>
      Mit der Funktion <em>Zeit ab Teststart</em> können Sie die Zeit begrenzen, die Ihre Teilnehmer insgesamt für den
      Test zur Verfügung haben (inklusive Instruktionen, Pausen und Beispielen). Sobald der Test gestartet wurde, wird
      das Feld <em>Verfügbar bis</em> auf den aktuellen Zeitpunkt plus die eingestellte Dauer gesetzt.
    </p>
    <p>
      Die Teilnehmer werden vor Beginn des Tests gesondert auf die Zeitbegrenzung hingewiesen, damit sie den Test erst
      dann starten, wenn genügend Zeit zur Verfügung steht und alle Materialien bereit stehen.
    </p>
    <div className="textbox important">
      <p>Nach Ablauf der Zeit wird der Test automatisch abgebrochen und es wird kein Ergebnis erzeugt.</p>
    </div>
    <p>Wir raten dazu, diese Funktion möglichst sparsam zu nutzen und Zeitlimits großzügig zu bemessen:</p>
    <ul>
      <li>Aufgaben im Leistungstest sind bereits mit einem passenden Zeitlimit versehen.</li>
      <li>Wird der Test wegen Zeitmangel abgebrochen, kann dies für den Teilnehmer frustrierend sein.</li>
      <li>Ein abgebrochener Test liefert kein Ergebnis, da nicht alle Aufgaben bearbeitet wurden.</li>
    </ul>
  </div>
)

const StartedDurationHelpEnglish = () => (
  <div>
    <p>
      The <em>Time from Test Start</em> feature allows you to limit the overall time available to your participants for
      the test (including instructions, breaks, and examples). Once the test has started, the <em>Available until</em>{' '}
      field is set to the current time plus the specified duration.
    </p>
    <p>
      Participants will be specifically informed about the time limit before starting the test, so they only start when
      they have enough time available and all materials are ready.
    </p>
    <div className="textbox important">
      <p>After the time expires, the test will automatically be terminated and no result will be generated.</p>
    </div>
    <p>We advise using this feature sparingly and setting generous time limits:</p>
    <ul>
      <li>Tasks in performance tests are already provided with an appropriate time limit.</li>
      <li>If the test is terminated due to a lack of time, it can be frustrating for the participant.</li>
      <li>An aborted test provides no result, as not all tasks were completed.</li>
    </ul>
  </div>
)
