import { useEffect, useRef, useState } from 'react'
import { EditDiscardButton, EditSaveButton, Icon } from '../../customInput/customInputElements'
import styled from 'styled-components'
import { NAME_MAX_CHARS, shakeElement } from '../normEditor/normUtils'

const RenameDialog = ({ initialName, onCancel, onOk }) => {
  const [newName, setNewName] = useState(initialName)
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleNameChange = (target) => {
    const value = target.value
    if (value.length > NAME_MAX_CHARS) {
      shakeElement(target)
      return
    }
    setNewName(value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onCancel()
    }
    if (e.key === 'Enter') {
      onOk(newName)
    }
  }

  return (
    <RenameDialogContainer>
      <input
        style={{ paddingLeft: '10px', height: '48px' }}
        ref={inputRef}
        type="text"
        value={newName}
        onChange={(e) => handleNameChange(e.target)}
        onKeyDown={handleKeyDown}
      />
      <EditDiscardButton onClick={onCancel}>
        <Icon red style={{ transform: 'scale(0.7)' }} className="svg-icon icon-x-noborder-mask" />
      </EditDiscardButton>
      <EditSaveButton onClick={() => onOk(newName)}>
        <Icon className="svg-icon icon-check-noborder-mask" />
      </EditSaveButton>
    </RenameDialogContainer>
  )
}

export default RenameDialog

const RenameDialogContainer = styled.div`
  display: grid;
  gap: var(--space-2);
  grid-template-columns: auto 40px 40px;
  align-items: flex-end;
  button {
    width: 100% !important;
  }
`
