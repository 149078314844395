import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'
import { EditFieldsContainer } from './BulkEditStyles'
import { getEditableFields } from './BulkUtils'
import EditFields from './EditFields'

const BulkSettings = ({
  bulkData,
  setterFunctions,
  rowData,
  entity,
  fromAndUntilDatesValid,
  setStartedDurationValid
}) => {
  const context = useContext(AppContext)
  const t = useTranslate()

  const editableFields = getEditableFields(entity, rowData, context)
  const archivedEntitiesSelected = rowData.some((entity) => entity?.isArchived())

  if (editableFields.length === 0 || archivedEntitiesSelected) {
    return <></>
  }

  return (
    <div>
      <h3>{t('settings')}</h3>
      <EditFieldsContainer>
        <EditFields
          {...{
            bulkData,
            setterFunctions,
            rowData,
            context,
            fromAndUntilDatesValid,
            setStartedDurationValid,
            editableFields
          }}
        />
      </EditFieldsContainer>
    </div>
  )
}

export default BulkSettings
