import { useContext, useState } from 'react'
import { ButtonPrimary, LargeGreyText, SmallNotice } from '../../../utils/elements/miscElements'
import { Tile } from '../../table/tableElements'
import styled from 'styled-components'
import { AppContext } from '../../../utils/context/AppContext'
import TestWeightSelection from './TestWeightSelection'
import { getInitialTestWeights, openModal, weightsAddUpTo100 } from './processResultUtils'
import ProcessResultToggle from './ProcessResultToggle'
import ProcessResultSettingsHeadline from './ProcessResultSettingsHeadline'
import useTranslate from '../../../utils/hooks/useTranslate'

const ProcessResultSettingsTile = ({ process }) => {
  const t = useTranslate()
  const [useProcessResult, setUseProcessResult] = useState(process.useProcessResult)
  const [testWeights, setTestWeights] = useState(getInitialTestWeights(process))
  const [weightsAreValid, setWeightsAreValid] = useState(weightsAddUpTo100(testWeights))
  const [showSaveButton, setShowSaveButton] = useState(false)
  const context = useContext(AppContext)
  const noAssessmentsInProcess = process.relatedAssessments.length === 0
  const hasWeightableAssessments = process.relatedAssessments.some((ass) => ass.producesNumericResult())

  const smallButtonStyle = {
    width: '80px',
    minHeight: 'unset',
    minWidth: 'unset',
    fontSize: 'var(--fs-2)',
    padding: 'var(--space-3)'
  }

  return (
    <Tile width="400px">
      <ProcessResultSettingsHeadline />

      {hasWeightableAssessments ? (
        <Container>
          <ProcessResultToggle {...{ process, useProcessResult, setUseProcessResult, setShowSaveButton }} />

          {useProcessResult && (
            <TestWeightSelection
              {...{ process, testWeights, setTestWeights, weightsAreValid, setWeightsAreValid, setShowSaveButton }}
            />
          )}

          {showSaveButton && (
            <ButtonPrimary
              onClick={() => openModal(testWeights, useProcessResult, process.processUuid, context)}
              disabled={useProcessResult && !weightsAreValid}
              style={smallButtonStyle}
              content="save"
            />
          )}
        </Container>
      ) : noAssessmentsInProcess ? (
        <NoTestsNotice />
      ) : (
        <NoWeightableTestsNotice />
      )}
      {!process.isEditor() && <SmallNotice>{t('processNoEditorPermissions')}</SmallNotice>}
    </Tile>
  )
}

export default ProcessResultSettingsTile

const Container = styled.div`
  display: grid;
  gap: var(--space-6);
  margin-top: 6px;
`

const NoTestsNotice = () => {
  const t = useTranslate()
  return <LargeGreyText>{t('noTestsInProcess')}</LargeGreyText>
}

const NoWeightableTestsNotice = () => {
  const t = useTranslate()
  return <span className="text-secondary">{t('unableToCalculateTotalResult')}</span>
}
