import CustomInput from '../customInput/CustomInput'
import { useContext } from 'react'
import { API_DATA, ERROR_MSG, SELECT_OPTIONS } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { getOptionByValue } from '../../utils/helper/Helper'
import { CustomInputList, Tile } from '../table/tableElements'
import useTranslate from '../../utils/hooks/useTranslate'

const EditCapabilitiesTile = ({ contact }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contactProcesses = context.completeDataSet.contactProcesses

  return (
    <Tile width="300px">
      <p className="text-secondary" style={{ fontSize: 'var(--fs-2)' }}>
        {t('editCapabilitiesExplanation', contact.getContactLabel())}
      </p>
      <CustomInputList>
        {context.completeDataSet.processes.map((process, i) => {
          const thisCapabilities = contactProcesses.find(
            (cp) => cp.processUuid === process.processUuid && cp.contactUuid === contact.contactUuid
          ).capabilities
          return (
            <CustomInput
              key={process.processName + '_' + i}
              label={process.processName}
              content={getOptionByValue(t(SELECT_OPTIONS.userCapabilities), thisCapabilities).label || ERROR_MSG}
              value={thisCapabilities}
              options={t(SELECT_OPTIONS.userCapabilities)}
              dataKey="capabilities"
              type="select"
              idValue={contact.contactUuid}
              idValueSecondary={process.processUuid}
              apiData={API_DATA.contactProcesses}
              disabled={contact.contactIsAdmin}
            />
          )
        })}
      </CustomInputList>
    </Tile>
  )
}

export default EditCapabilitiesTile
