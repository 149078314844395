import { EMAIL_FROM_ADDRESS, EMAIL_FROM_NAME, FIELD_LABELS } from '../../../utils/constants/constants'
import styled from 'styled-components'
import { getPtLink, setState } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { OptionDescription, OptionEntryWide, PageHeadline, WizardForm } from '../../../utils/elements/miscElements'
import { getPreviewData, detectEmailType } from '../sendEmailUtils'
import PreviewSelector from '../subComponents/PreviewSelector'
import PreviewMailSender from '../subComponents/PreviewMailSender'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { getSalutation } from '../subComponents/SalutationSelection'
import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../../utils/context/AppContext'

const EmailPreview = ({ data, setData }) => {
  const t = useTranslate()

  useEffect(() => {
    const emailType = detectEmailType(data)
    setState(setData, 'emailType', emailType)
  }, [])

  const sideBarContent = (
    <div>
      <PreviewSelector {...{ data, setData }} />
      <PreviewMailSender {...{ data }} />
    </div>
  )

  return (
    <>
      <PageHeadline>{t('preview')}</PageHeadline>
      <ScrollContainer>
        <WizardForm style={{ gap: 'var(--space-6)' }}>
          <OptionEntryWide hasHint>
            <EmailPreviewBody {...{ data }} />
            <OptionDescription description={sideBarContent} />
          </OptionEntryWide>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default EmailPreview

const EmailPreviewBody = ({ data }) => {
  const previewData = getPreviewData(data)
  const previewParticipant = data.participants.find((p) => p.pNr === data.previewParticipantNr)
  const t = useTranslate()
  const context = useContext(AppContext)
  const salutation = getSalutation(previewParticipant, data.salutationType, t)
  return (
    <OuterContainer>
      <Header>
        <Entry>
          <span>{t('sender')}:</span>
          <span>
            {EMAIL_FROM_NAME[context.language]} {'<'}
            {EMAIL_FROM_ADDRESS}
            {'>'}
          </span>
        </Entry>
        <Entry>
          <span>{t('to')}:</span> <span>{previewParticipant.pMail}</span>
        </Entry>
        <Entry style={{ fontWeight: 600 }}>
          <span>{t('subject')}:</span> <span>{data.emailSubject}</span>
        </Entry>
      </Header>
      <Body>
        {salutation && <Salutation>{salutation}</Salutation>}
        <TextContainer>
          <FormattedText string={data.topText} />
        </TextContainer>
        <TestInfos {...{ previewData }} />
        <TextContainer>
          <FormattedText string={data.bottomText} />
        </TextContainer>
      </Body>
    </OuterContainer>
  )
}

const FormattedText = ({ string }) => {
  const splitString = string.split('\n')
  if (splitString.length === 0) {
    return string
  }
  return splitString
    .filter((line) => line !== '')
    .map((line, index) => (
      <Line key={index}>
        {line}
        <br />
      </Line>
    ))
}

const Line = styled.div`
  margin-bottom: var(--space-3);
`
const Salutation = styled.div`
  margin-bottom: var(--space-3);
`
const TextContainer = styled.div`
  margin-top: var(--space-3);
`
const Header = styled.div`
  border-bottom: 1px solid var(--border-grey);
  padding-bottom: var(--space-5);
`
const Body = styled.div``
const Entry = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: var(--space-3);
`

const OuterContainer = styled.div`
  background: var(--email-preview-bg-color);
  padding: var(--space-5);
  border-radius: var(--bdr-3);
  border: 1px solid var(--border-grey);
  display: grid;
  gap: var(--space-5);
  * {
    line-height: var(--lh-3);
  }
`

export const TestInfos = ({ previewData }) => {
  return (
    <TestInfosUl>
      {previewData.map((previewTest, i) => (
        <li key={previewTest.token + i} style={{ listStyleType: 'none' }}>
          <TestInfoBlock {...{ previewTest }} />
        </li>
      ))}
    </TestInfosUl>
  )
}

const TestInfoBlock = ({ previewTest }) => {
  const t = useTranslate()
  const materialsList = previewTest.requiredMaterials.map((entry) => t(entry)).join(', ')
  return (
    <TestInfoBlockUl>
      <InfoContainer label={t('title')} content={previewTest.title} />
      <InfoContainer label={t(FIELD_LABELS.ptNumber)} content={previewTest.ptNumber} />
      <InfoContainer label={t(FIELD_LABELS.validFrom)} content={previewTest.validFrom} />
      <InfoContainer label={t(FIELD_LABELS.validUntil)} content={previewTest.validUntil} />
      <InfoContainer label={t('requiredMaterials')} content={materialsList} />
      <InfoContainer
        label={t('testlink')}
        content={getPtLink(previewTest.token)}
        contentStyle={{ wordBreak: 'break-word' }}
      />
    </TestInfoBlockUl>
  )
}

const InfoContainer = ({ label, content, contentStyle }) => (
  <>
    {content && (
      <InfoContainerLi>
        <span style={{ fontWeight: 500 }}>{label}</span>
        <span style={contentStyle}>{content}</span>
      </InfoContainerLi>
    )}
  </>
)

const TestInfosUl = styled.ul`
  padding: var(--space-3);
  display: grid;
  gap: var(--space-5);
  background-color: var(--box-background);
  border-radius: var(--bdr-2);
  margin: var(--space-4) 0;
`

const InfoContainerLi = styled.li`
  display: grid;
  grid-template-columns: 110px 1fr;
  list-style-type: none;
`

const TestInfoBlockUl = styled.ul`
  padding: 0;
  display: grid;
  gap: var(--space-2);
`
