import { CustomCheckmark, CheckboxElement, HoverConnector } from '../../../utils/elements/miscElements'
import { setState } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { Description, Icon, InnerContainer, Title, OuterContainer } from './SelectElements'

const DataSecuritySelection = ({ data, setData }) => {
  const options = [
    {
      pAnon: false,
      title: 'namedExecution',
      icon: 'icon-user-tag',
      iconStyle: { transform: 'scale(1.2)' },
      description: 'participantNameEntry'
    },
    {
      pAnon: true,
      title: 'anonymousExecution',
      icon: 'icon-user-secret',
      description: 'participantIdentification'
    }
  ]

  return (
    <OuterContainer>
      {options.map((option) => (
        <SelectOption key={option.title} {...{ option, data, setData }} />
      ))}
    </OuterContainer>
  )
}

export default DataSecuritySelection

const handleClick = (e, setData, option) => {
  e.preventDefault()
  setState(setData, 'pAnon', option.pAnon)
}

const SelectOption = ({ option, data, setData }) => {
  const t = useTranslate()
  const checked = option.pAnon === data.pAnon
  return (
    <HoverConnector>
      <InnerContainer>
        <CheckboxElement
          checked={checked}
          onClick={(e) => handleClick(e, setData, option)}
          style={{ gap: 'var(--space-3)', padding: 'var(--space-6)', justifyItems: 'center' }}
        >
          <Icon style={option.iconStyle} className={`svg-icon ${option.icon}`}></Icon>
          <Title>{t(option.title)}</Title>
          <Description>{t(option.description)}</Description>
        </CheckboxElement>
        <CustomCheckmark
          style={{ marginTop: 'var(--space-4)' }}
          type="radio"
          onClick={(e) => handleClick(e, setData, option)}
          checked={checked}
        />
      </InnerContainer>
    </HoverConnector>
  )
}
