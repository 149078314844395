import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { buildUrlParamString } from '../helper/urlParamsHelper'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { DEFAULT_LANGUAGE } from '../constants/constants'

const useRedirect = () => {
  const history = useHistory()
  const { language } = useContext(AppContext)
  const redirect = (page, optionalParams) => {
    const slug = page.urlSlugs[language] || page.urlSlugs[DEFAULT_LANGUAGE]
    const urlParamString = buildUrlParamString(optionalParams)
    const url = `${slug}${urlParamString}`
    history.push(url)
  }

  return redirect
}

export default useRedirect
