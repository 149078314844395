import { useEffect, useRef } from 'react'
import { trapFocus } from '../helper/Helper'

const useTrapFocus = () => {
  const ref = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      const headlines = document.querySelectorAll('.modal h2, .modal h3')
      headlines.forEach((h) => (h.tabIndex = '-1'))
      headlines[0]?.focus()
      const focusableElements = getFocusableElements()
      ref.current = trapFocus(focusableElements)
    }, 200)
  }, [])

  const updateFocusableElements = () => {
    if (ref.current) {
      ref.current.removeEventListeners()
      const focusableElements = getFocusableElements()
      ref.current = trapFocus(focusableElements)
    }
  }

  return updateFocusableElements
}

export default useTrapFocus

const getFocusableElements = () => {
  return document.querySelectorAll(
    '.modal select, .modal input, .modal button:not([disabled]), .modal .custom-checkbox'
  )
}
