import { CheckboxElement, CustomCheckmark, HoverConnector } from '../../../utils/elements/miscElements'
import { setState } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { Description, InnerContainer, OuterContainer, Title, Icon } from './SelectElements'

const SelectUploadOption = ({ data, setData }) => {
  const t = useTranslate()
  const options = [
    {
      id: 'blank',
      useUploadValue: false,
      title: 'createBlankParticipants',
      icon: 'icon-add-pt-fa',
      iconStyle: { transform: 'scale(1.2)' },
      description: 'blankParticipantsExplanaation'
    },
    {
      id: 'upload',
      useUploadValue: true,
      title: 'uploadParticipantList',
      icon: 'icon-file-upload',
      description: 'uploadParticipantListExplanation'
    }
  ]

  const handleClick = (e, option) => {
    e.preventDefault()
    setState(setData, 'useFileUpload', option.useUploadValue)
  }

  return (
    <OuterContainer>
      {options.map((option) => {
        const checked = data.useFileUpload === option.useUploadValue
        return (
          <HoverConnector key={option.id}>
            <InnerContainer>
              <CheckboxElement
                checked={checked}
                onClick={(e) => handleClick(e, option)}
                style={{ gap: 'var(--space-5)', padding: 'var(--space-5)', justifyItems: 'center' }}
              >
                <Icon style={option.iconStyle} className={`svg-icon ${option.icon}`}></Icon>
                <Title>{t(option.title)}</Title>
                <Description>{t(option.description)}</Description>
              </CheckboxElement>
              <CustomCheckmark
                style={{ marginTop: 'var(--space-4)' }}
                type="radio"
                onClick={(e) => handleClick(e, option)}
                checked={checked}
              />
            </InnerContainer>
          </HoverConnector>
        )
      })}
    </OuterContainer>
  )
}

export default SelectUploadOption
