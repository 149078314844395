import DatePicker from 'react-datepicker'
import { useContext, useRef, useState } from 'react'
import { handleDatePickerKeyDown, setState } from '../../utils/helper/Helper'
import { ErrorMessage } from '../../utils/elements/miscElements'
import './datepicker.scss'
import useCurrentRect from '../../utils/hooks/useCurrentRect'
import { BREAKPOINT_MOBILE, FORM_ERRORS } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'
import { AppContext } from '../../utils/context/AppContext'

const Calendar = ({ value, setData, controls, autoFocus, fieldName, hasError, hideErrorMessage }) => {
  const context = useContext(AppContext)
  const [useEndOfDay, setUseEndOfDay] = useState(fieldName === 'validUntil' ? true : false)
  const datePickerRef = useRef(null)
  const t = useTranslate()

  const handleChange = (date) => {
    if (date && useEndOfDay) {
      date.setHours(23, 59, 59, 999)
      setUseEndOfDay(false)
    }

    if (controls) {
      setState(setData, controls, date)
    } else {
      setData(date)
    }
  }

  const className = getClassName(hasError, value)
  const width = useCurrentRect().width
  const isMobile = width < BREAKPOINT_MOBILE

  const handleWrapperClick = (e) => {
    const isTextInput = (e) => e.target.tagName.toLowerCase() === 'input' && e.target.type === 'text'

    if (datePickerRef.current && datePickerRef.current.isCalendarOpen() && isTextInput(e)) {
      datePickerRef.current.setOpen(false)
    }
  }

  return (
    <div onClick={handleWrapperClick} className={`datepicker-wrapper ${value && 'has-value'}`}>
      <DatePicker
        preventOpenOnFocus={true}
        ref={datePickerRef}
        onKeyDown={(e) => handleDatePickerKeyDown(e, datePickerRef)}
        className={className}
        selected={value}
        locale={context.language}
        timeCaption={t('time')}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="Pp"
        autoFocus={autoFocus}
        calendarStartDay={1}
        placeholderText={t('dateAndTime')}
        isClearable
        onChange={(date) => {
          handleChange(date)
        }}
        popperPlacement={isMobile ? 'auto' : 'left'}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 0]
            }
          }
        ]}
      />
      {!hideErrorMessage && <ErrorMessage visible={hasError}>{t(FORM_ERRORS.endTimeBeforeStartTime)}</ErrorMessage>}
    </div>
  )
}

export default Calendar

const getClassName = (hasError, value) => {
  let className = ''
  className += hasError ? 'has-error ' : ''
  className += value ? 'has-value ' : ''
  return className
}
