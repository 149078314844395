import {
  WizardForm,
  Label,
  ButtonPrimary,
  Modalfooter,
  selectStyles,
  ErrorMessage,
  OptionEntry,
  OptionDescription
} from '../../utils/elements/miscElements'
import { FIELD_LABELS, SELECT_OPTIONS, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import Select from 'react-select'
import { useState, useContext, useRef } from 'react'
import { API_DATA, FORM_ERRORS } from '../../utils/constants/constants'
import {
  mailIsUnique,
  mailIsValid,
  setStatusBar,
  fetchData,
  setState,
  getOptionByValue,
  getLoggedInContact,
  updateEntities
} from '../../utils/helper/Helper'
import { AppContext } from '../../utils/context/AppContext'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import useTranslate from '../../utils/hooks/useTranslate'

const PersonalDataComponent = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const contact = getLoggedInContact(completeDataSet)
  const submitButtonRef = useRef(null)

  const [mailIsDuplicate, setMailIsDuplicate] = useState(false)
  const [showError, setShowError] = useState(false)
  const [data, setData] = useState({
    contactUuid: contact.contactUuid,
    contactGender: contact.contactGender || '',
    contactTitle: contact.contactTitle || '',
    contactFirstName: contact.contactFirstName || '',
    contactLastName: contact.contactLastName || '',
    contactEmail: contact.contactEmail || '',
    contactPhone: contact.contactPhone || ''
  })

  const mailIsInvalid = !mailIsValid(data.contactEmail)
  const formValid = !mailIsDuplicate && !mailIsInvalid && data.contactEmail.length > 0

  const content = (
    <ScrollContainer type="settings">
      <WizardForm>
        <OptionEntry hasHint>
          <div style={{ display: 'grid', gap: 'var(--space-5)' }}>
            <Label key="contactGender" style={{ width: '100px' }}>
              <span>{t(FIELD_LABELS.salutation)}</span>
              <Select
                options={t(SELECT_OPTIONS.contactSalutations)}
                onChange={(selectedOption) => setState(setData, 'contactGender', selectedOption.value)}
                value={getOptionByValue(t(SELECT_OPTIONS.contactSalutations), data.contactGender)}
                styles={selectStyles}
                placeholder=""
              />
            </Label>
            <Label key="contactFirstname">
              <span>{t(FIELD_LABELS.firstName)}</span>
              <input
                type="text"
                value={data.contactFirstName}
                onChange={(e) => setState(setData, 'contactFirstName', e.target.value)}
              />
            </Label>

            <Label key="contactLastname">
              <span>{t(FIELD_LABELS.lastName)}</span>
              <input
                type="text"
                value={data.contactLastName}
                onChange={(e) => setState(setData, 'contactLastName', e.target.value)}
              />
            </Label>
          </div>
        </OptionEntry>

        <OptionEntry hasHint>
          <Label key="contactEmail" fullWidth>
            <span>{t(FIELD_LABELS.email)} *</span>
          </Label>
          <div style={{ display: 'grid' }}>
            <input
              type="email"
              value={data.contactEmail}
              onChange={(e) => {
                setState(setData, 'contactEmail', e.target.value)
                if (mailIsValid(e.target.value)) {
                  setShowError(false)
                }
              }}
              className={showError && (mailIsDuplicate || mailIsInvalid) ? 'has-error' : ''}
              onBlur={() => setShowError(true)}
            />
            <ErrorMessage visible={mailIsDuplicate && showError}>{t(FORM_ERRORS.emailAlreadyInUse)}</ErrorMessage>
            <ErrorMessage visible={mailIsInvalid && showError}>{t(FORM_ERRORS.emailInvalid)}</ErrorMessage>
          </div>
          <OptionDescription description="emailAsLogin" />
        </OptionEntry>
        <OptionEntry hasHint>
          <Label key="contactPhone" fullWidth>
            <span>{t(FIELD_LABELS.phoneNumber)}</span>{' '}
          </Label>

          <input
            type="text"
            value={data.contactPhone}
            onChange={(e) => setState(setData, 'contactPhone', e.target.value)}
          />
          <OptionDescription description="contactForQuestions" />
        </OptionEntry>
      </WizardForm>
      <Modalfooter type="settings">
        <ButtonPrimary
          ref={submitButtonRef}
          disabled={!formValid}
          content="save"
          onClick={() => handleSubmit(data, setMailIsDuplicate, context, t)}
        />
      </Modalfooter>
    </ScrollContainer>
  )

  return content
}

export default PersonalDataComponent

const handleSubmit = (data, setMailIsDuplicate, context, t) => {
  validateInputForm(data, setMailIsDuplicate, context)
    .then((isUnique) => (isUnique ? saveContactData(data, context, t) : null))
    .catch((e) => console.error(e))
}

const saveContactData = (data, context) => {
  const payload = {
    contactUuid: [data.contactUuid],
    contactGender: data.contactGender,
    contactTitle: data.contactTitle,
    contactFirstName: data.contactFirstName.trim(),
    contactLastName: data.contactLastName.trim(),
    contactEmail: data.contactEmail.trim(),
    contactPhone: data.contactPhone?.trim() || ''
  }

  const endPoint = API_DATA.contacts.endPointEdit

  fetchData(payload, endPoint, context).then((responseData) => {
    updateEntities(responseData.response.data, context)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'fieldsSuccessfullyChanged'
    })
  })
}

const validateInputForm = (data, setMailIsDuplicate, context) => {
  return new Promise((resolve, reject) => {
    mailIsUnique(data.contactEmail, true)
      .then((isUnique) => {
        if (isUnique) {
          setMailIsDuplicate(false)
          resolve(isUnique)
        } else {
          setMailIsDuplicate(true)
          reject(isUnique)
        }
      })
      .catch((e) => {
        console.error(e)
        context.statusBarController.setStatusBarType(STATUS_BAR_TYPES.error)
        context.statusBarController.setStatusBarContent(<span>Verbindungsfehler</span>)
        context.statusBarController.setStatusBarVisible(true)
      })
  })
}
