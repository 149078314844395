import { useEffect, useRef } from 'react'

const OutsideAlerter = (props) => {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, props.hideMenu)

  return (
    <div className="wrapper" id={props.id} ref={wrapperRef}>
      {props.children}
    </div>
  )
}

export default OutsideAlerter

const useOutsideAlerter = (ref, hideMenu) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && event.target.id !== ref.current.id) {
        hideMenu()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, hideMenu])
}
