import React, { useContext } from 'react'
import Badge from '../../components/badge/Badge'
import { TableLink, SmallDescription, TableContentContainer } from '../../components/table/tableElements'
import {
  getArchivedClassName,
  getTableLinkParams,
  getGlobalStatusByKey,
  getOptionByValue,
  isPotentials,
  isPotentialsCrossTest,
  isTraitsOrGoals,
  lowerCaseFirstLetter,
  getFormattedNumber
} from '../../utils/helper/Helper'

import sessionHandler from '../../utils/helper/sessionHandler'
import Config from '../../utils/constants/config'
import {
  PT_STATUS_NAMES,
  FIELD_LABELS,
  GLOBAL_STATUS_KEYS,
  SELECT_OPTIONS,
  INVITATION_STATUS,
  DEFAULT_LANGUAGE,
  FILTER_TYPES,
  FIELD_CATEGORIES,
  CONTENT_TYPES,
  PARTICIPANT_SPECIAL_NAMES,
  genderLabels
} from '../../utils/constants/constants'
import PtAccessDataTab from '../../components/tabs/PtAccessDataTab'
import PtResultTab from '../../components/tabs/PtResultTab'
import PtTimingAndSettingsTab from '../../components/tabs/PtTimingAndSettingsTab'
import { PAGES } from '../../utils/constants/pages'
import { createDate, secondsToDHM } from '../../utils/helper/dateTimeHelper'
import { ParticipantNameTableCell } from '../../components/table/tableCells/ParticipantNameTableCell'
import { getCustomCols } from '../../components/table/tableCols/customCols'
import { getFilterLabels } from '../../components/filter/filterUtils'
import { getParticipantNumberCol } from '../dashboard/dashboardUtils'
import { getTabTrackingLabels } from '../../components/tabTrackingLabel/TabTrackingLabel'
import { getUnpermittedAidsLabels } from '../../components/unpermittedAidsLabel/UnpermittedAidsLabel'
import useTranslate from '../../utils/hooks/useTranslate'
import { AppContext } from '../../utils/context/AppContext'
import DateDisplay from '../../components/dateDisplay/DateDisplay'
import {
  getActionsCol,
  getCreatedByCol,
  getDateCreatedCol,
  getPtLinkCol,
  getSpacerCol,
  getVisibilityCol
} from '../../components/table/tableCols/tableCols'
import { FilterContext } from '../../utils/context/FilterContext'
import CameraAnomaliesLabel from '../../components/cameraAnomaliesLabel/CameraAnomaliesLabel'
import useRedirect from '../../utils/hooks/useRedirect'

export const getPtTableCols = (customFieldDefinitions, usingCrosstests, locale, t) => {
  const defaultCols = [
    {
      Header: 'freeTextFilter',
      id: 'freeText',
      accessor: (row) =>
        row.ptNumber +
        row.relatedParticipant.pFullName +
        row.relatedParticipant.pNr +
        (row.relatedParticipant.pMail || '') +
        row.relatedProcess.processName +
        row.relatedAssessment.assessmentName +
        row.relatedParticipant.customFields.map((cf) => cf.content).join() +
        t(PT_STATUS_NAMES[row.ptStatus]),
      filterOptions: { type: FILTER_TYPES.textInput },
      canExport: false,
      show: false
    },
    {
      Header: FIELD_LABELS.ptNumber,
      id: 'ptNumber',
      accessor: 'ptNumber',
      canExport: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt },
      Cell: ({ row }) => {
        return (
          <TableContentContainer className={row.original.isArchived() ? 'archived' : ''}>
            {row.original.ptNumber}
          </TableContentContainer>
        )
      }
    },
    getParticipantNumberCol(),
    {
      Header: FIELD_LABELS.participantName,
      id: 'pFullName',
      accessor: (row) => row.relatedParticipant.pFullName,
      show: false,
      canExport: true,
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.participant,
        excludedValues: PARTICIPANT_SPECIAL_NAMES
      },
      Cell: ({ value }) => <ParticipantNameTableCell pFullName={value} />
    },
    {
      Header: FIELD_LABELS.email,
      id: 'pMail',
      canExport: true,
      accessor: (row) => row.relatedParticipant.pMail,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.participant },
      show: false,
      Cell: ({ value }) => <span style={{ wordWrap: 'break-word' }}>{value}</span>
    },
    {
      Header: FIELD_LABELS.gender,
      id: 'pGender',
      accessor: (row) => row.relatedParticipant.pGender,
      show: false,
      canExport: true,
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.participant,
        dataLabels: genderLabels
      },
      Cell: ({ row }) => {
        const t = useTranslate()
        const participant = row.original.relatedParticipant
        return (
          <TableContentContainer>
            {participant.pAnon ? '' : getOptionByValue(t(SELECT_OPTIONS.gender), participant.pGender).label || ''}
          </TableContentContainer>
        )
      }
    },
    {
      Header: 'processes',
      id: 'processName',
      canExport: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, label: 'processName' },
      accessor: (row) => row.relatedProcess.processName,
      show: false,
      Cell: ({ row }) => {
        const redirect = useRedirect()
        const { addLinkFilters } = useContext(FilterContext)
        const process = row.original.relatedProcess
        const tableLinkParams = getTableLinkParams(process, 'processName', 'processes')

        return (
          <TableLink
            className={tableLinkParams.className}
            onClick={() => {
              addLinkFilters(tableLinkParams.filterParams)
              redirect(PAGES.processes)
            }}>
            {process.processName}
          </TableLink>
        )
      }
    },
    {
      Header: 'Test',
      id: 'assessmentName',
      canExport: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'testName' },
      accessor: (row) => row.relatedAssessment.assessmentName,
      show: true,
      Cell: ({ row }) => {
        const redirect = useRedirect()
        const { addLinkFilters } = useContext(FilterContext)
        const assessment = row.original.relatedAssessment
        const process = row.original.relatedProcess
        const className = getArchivedClassName(assessment)

        const assessmentFilter = {
          field: { value: 'assessmentName' },
          value: { value: assessment.assessmentName },
          tableName: 'assessments'
        }

        const processFilter = {
          field: { value: 'processName' },
          value: { value: process.processName },
          tableName: 'assessments'
        }

        return (
          <TableLink
            className={className}
            onClick={() => {
              addLinkFilters([assessmentFilter, processFilter])
              redirect(PAGES.assessments)
            }}>
            {assessment.assessmentName}
          </TableLink>
        )
      }
    },
    {
      Header: 'Status',
      id: 'ptStatus',
      accessor: 'ptStatus',
      canExport: true,
      filterOptions: {
        showInFieldSelector: true,
        dataLabels: getPtStatusLabelsForFilter(),
        labelOffset: 8,
        category: FIELD_CATEGORIES.pt
      },
      Cell: ({ row }) => {
        const t = useTranslate()
        const showProgress = row.original.ptProgress > 0 && row.original.ptProgress < 100
        const styles = {
          container: {
            display: 'grid',
            width: 'fit-content',
            gridTemplateColumns: '15px 1fr',
            alignItems: 'center'
          },
          smallDescription: { gridRow: 2, gridColumn: 2, whiteSpace: 'nowrap', overflow: 'visible' }
        }
        return (
          <TableContentContainer>
            <div style={styles.container}>
              <Badge status={row.original.ptStatus} />
              <span>{t(PT_STATUS_NAMES[row.original.ptStatus])}</span>
              {showProgress && (
                <SmallDescription style={styles.smallDescription}>
                  <ProgressDisplay progress={row.original.ptProgress} />
                </SmallDescription>
              )}
            </div>
          </TableContentContainer>
        )
      }
    },
    getPtResultCol(),
    {
      id: 'resultPoints',
      Header: 'resultPoints',
      accessor: (row) => getNumericResult(row),
      canExport: false,
      show: false,
      alwaysHidden: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, contentType: CONTENT_TYPES.number }
    },
    {
      id: 'resultGrade',
      Header: 'resultGrade',
      accessor: (row) => getGradeResult(row),
      canExport: false,
      show: false,
      alwaysHidden: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, contentType: CONTENT_TYPES.number }
    },
    {
      id: 'resultCrosstest',
      Header: 'resultCrosstest',
      accessor: (row) => getCrosstestResult(row, locale),
      canExport: false,
      show: false,
      alwaysHidden: true,
      filterOptions: { showInFieldSelector: usingCrosstests, category: FIELD_CATEGORIES.pt }
    },
    {
      Header: 'windowChanges',
      id: 'tabTrackingIndex',
      canExport: true,
      accessor: (row) => row?.relatedResult?.tabTrackingIndex || '',
      show: 'true',
      Cell: ({ row }) => {
        const tabTrackingLabels = getTabTrackingLabels({ rowData: row.original })
        const configType = row.original.relatedConfig.configType
        const showTabTrackingResults = isPotentials(configType) || isPotentialsCrossTest(configType)
        const pt = row.original
        return (
          <>
            {pt.isFinished() && showTabTrackingResults && (
              <TableContentContainer style={{ position: 'relative', zIndex: 99 }}>
                {tabTrackingLabels[row.original.relatedResult.tabTrackingIndex]}
              </TableContentContainer>
            )}
          </>
        )
      }
    },
    {
      Header: 'windowChangeCount',
      id: 'windowChangeCount',
      canExport: false,
      alwaysHidden: true,
      accessor: (row) => {
        const configType = row.relatedConfig.configType
        const useTabTracking = isPotentials(configType) || isPotentialsCrossTest(configType)
        return useTabTracking ? row?.relatedResult?.windowChangeCount : null
      },
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.pt,
        contentType: CONTENT_TYPES.number
      },
      show: false
    },
    {
      Header: 'unpermittedAids',
      id: 'unpermittedAidsIndex',
      canExport: true,
      accessor: (row) => row?.relatedResult?.unpermittedAidsIndex || '',
      filterOptions: {
        showInFieldSelector: true,
        dataLabels: getUnpermittedAidsLabels({ rowData: null }),
        labelOffset: 4,
        category: FIELD_CATEGORIES.pt
      },
      show: 'true',
      Cell: ({ row }) => {
        const unpermittedAidsIndex = row.original?.relatedResult?.unpermittedAidsIndex
        const unpermittedAidsLabels = getUnpermittedAidsLabels({ rowData: row.original })
        const configType = row.original.relatedConfig.configType
        const showUnpermittedAidsResults = isPotentials(configType) || isPotentialsCrossTest(configType)
        const zIndex = 999 - row.index // upper rows have higher zindex to fix tooltip visibility issues
        const pt = row.original
        return (
          <>
            {pt.isFinished() && showUnpermittedAidsResults && (
              <TableContentContainer style={{ position: 'relative', zIndex: zIndex }}>
                {unpermittedAidsLabels[unpermittedAidsIndex]}
              </TableContentContainer>
            )}
          </>
        )
      }
    },
    {
      Header: 'cameraAnomalies',
      id: 'cameraAnomalies',
      canExport: true,
      accessor: (row) => (row?.relatedResult?.cameraAnomalies > 0 ? 'yes' : ''),
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.pt,
        contentType: CONTENT_TYPES.text,
        labelOffset: 4,
        dataLabels: {
          yes: <CameraAnomaliesLabel />
        }
      },
      show: false,
      Cell: ({ value }) => (value ? <CameraAnomaliesLabel /> : <></>)
    },
    getPtLinkCol(),
    {
      Header: 'startedAt',
      id: 'datePtStarted',
      accessor: (row) => createDate(row.datePtStarted),
      canExport: true,
      show: false,
      Cell: ({ row }) => {
        const datePtStarted = row.original.datePtStarted ? createDate(row.original.datePtStarted) : null
        return (
          <TableContentContainer>
            <DateDisplay date={datePtStarted} format={'dateAndTime'} />
          </TableContentContainer>
        )
      },
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.pt,
        contentType: CONTENT_TYPES.date,
        excludeFutureDates: true
      }
    },
    {
      Header: 'dateCompleted',
      id: 'datePtFinished',
      accessor: (row) => createDate(row.datePtFinished),
      canExport: true,
      show: false,
      Cell: ({ row }) => {
        const datePtFinished = row.original.datePtFinished ? createDate(row.original.datePtFinished) : null
        return (
          <TableContentContainer>
            <DateDisplay date={datePtFinished} format={'dateAndTime'} />
          </TableContentContainer>
        )
      },
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.pt,
        contentType: CONTENT_TYPES.date,
        excludeFutureDates: true
      }
    },
    {
      Header: FIELD_LABELS.validFrom,
      id: 'ptValidFrom',
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, contentType: CONTENT_TYPES.date },
      accessor: (row) => createDate(row.ptValidFrom),
      canExport: true,
      show: false,
      Cell: ({ row }) => {
        const validFrom = row.original.ptValidFrom ? createDate(row.original.ptValidFrom) : ''
        return (
          <TableContentContainer>
            {validFrom ? <DateDisplay date={validFrom} format={'dateAndTime'} /> : ''}
          </TableContentContainer>
        )
      }
    },
    {
      Header: FIELD_LABELS.validUntil,
      id: 'ptValidUntil',
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, contentType: CONTENT_TYPES.date },
      accessor: (row) => createDate(row.ptValidUntil),
      show: false,
      canExport: true,
      Cell: ({ row }) => {
        const validUntil = row.original.ptValidUntil ? createDate(row.original.ptValidUntil) : ''
        return (
          <TableContentContainer>
            {validUntil ? <DateDisplay date={validUntil} format={'dateAndTime'} /> : ''}
          </TableContentContainer>
        )
      }
    },
    {
      Header: FIELD_LABELS.invitedDuration,
      id: 'timer',
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.pt,
        contentType: CONTENT_TYPES.time,
        excludedValues: [0]
      },
      show: false,
      canExport: true,
      accessor: (row) => row.ptInvitedDuration,
      Cell: ({ row }) => {
        const t = useTranslate()
        const invitedStatus = row.original.datePtInvited ? 'started' : 'notStarted'
        const invitedDuration = row.original.ptInvitedDuration

        return (
          <TableContentContainer>
            <span>{invitedDuration ? t('dhmString', ...secondsToDHM(invitedDuration)) : ''}</span>
            <SmallDescription style={{ whiteSpace: 'nowrap' }}>
              {invitedDuration > 0 && lowerCaseFirstLetter(t(invitedStatus))}
            </SmallDescription>
          </TableContentContainer>
        )
      }
    },
    {
      Header: FIELD_LABELS.startedDuration,
      id: 'ptStartedDuration',
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, excludedValues: [0] },
      show: false,
      canExport: true,
      accessor: (row) => row.ptStartedDuration,
      Cell: ({ row }) => {
        const t = useTranslate()
        const ptStartedDuration = row.original.ptStartedDuration
        return (
          <TableContentContainer>
            <span>{ptStartedDuration ? t('dhmString', ...secondsToDHM(ptStartedDuration)) : ''}</span>
          </TableContentContainer>
        )
      }
    },
    {
      Header: FIELD_LABELS.credits,
      id: 'ptCredits',
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, contentType: CONTENT_TYPES.number },
      show: false,
      canExport: true,
      accessor: (row) => row.ptCredits,
      Cell: ({ value }) => (
        <TableContentContainer style={{ display: 'block', textAlign: 'right' }}>{value}</TableContentContainer>
      )
    },
    {
      Header: FIELD_LABELS.invitationStatus,
      id: GLOBAL_STATUS_KEYS.invitationStatus,
      accessor: (row) => getGlobalStatusByKey(row.globalStatus, GLOBAL_STATUS_KEYS.invitationStatus).statusValue || '',
      show: false,
      canExport: true,
      filterOptions: {
        showInFieldSelector: true,
        columnId: GLOBAL_STATUS_KEYS.invitationStatus,
        label: FIELD_LABELS.invitationStatus,
        dataLabels: getFilterLabels(INVITATION_STATUS),
        category: FIELD_CATEGORIES.pt
      },
      Cell: ({ row }) => {
        const t = useTranslate()
        const invitationStatus = getGlobalStatusByKey(row.original.globalStatus, GLOBAL_STATUS_KEYS.invitationStatus)

        const timestamp = invitationStatus?.modified ? createDate(invitationStatus.modified) : ''
        const label = getOptionByValue(SELECT_OPTIONS.invitationStatus, invitationStatus?.statusValue).label
        const showInvitationStatus = invitationStatus.statusValue !== INVITATION_STATUS.notSet.value
        const showTimestamp =
          invitationStatus.statusValue === INVITATION_STATUS.invited.value ||
          invitationStatus.statusValue === INVITATION_STATUS.reminded.value
        return (
          <>
            {showInvitationStatus && (
              <TableContentContainer>
                <span>{t(label)}</span>
                {showTimestamp && (
                  <SmallDescription>
                    <DateDisplay date={timestamp} format="dd_month_yyyy" />
                  </SmallDescription>
                )}
              </TableContentContainer>
            )}
          </>
        )
      }
    },
    getCreatedByCol('ptCreatedBy', FIELD_CATEGORIES.pt),
    getDateCreatedCol('ptCreated', FIELD_CATEGORIES.pt)
  ]
  const customCols = getCustomCols(customFieldDefinitions, false, 'relatedParticipant')
  const systemCols = [getVisibilityCol(FIELD_CATEGORIES.pt), getSpacerCol(), getActionsCol()]
  return [...defaultCols, ...customCols, ...systemCols]
}

export const downloadReport = ({ key, type }) => {
  const session = sessionHandler.getSessionId()
  const endPoint = getEndPoint(type)
  const url = `${Config.baseUrl}/${Config.prefix}/${endPoint}/${key}?perseo-session=${session}`
  const win = window.open(url, '_blank')
  win.focus()
}

const getEndPoint = (type) => {
  switch (type) {
    case 'feedback':
      return 'feedback_report'
    case 'participant-report':
      return 'participant_report'
    default:
      return 'report'
  }
}

const ProgressDisplay = ({ progress }) => {
  const t = useTranslate()
  return (
    <span>
      {t('progress')}: {progress || 0}%
    </span>
  )
}

export const getPtResultCol = (disableSortBy) => ({
  id: 'result',
  Header: 'result',
  accessor: (row) => getResultAccessor(row),
  width: '50px',
  textAlign: 'right',
  canExport: true,
  disableSortBy: disableSortBy,
  Cell: ({ row }) => {
    const pt = row.original
    const t = useTranslate()
    const context = useContext(AppContext)
    const normResult = pt.relatedResult?.normResult
    const showResult = pt.resultIsVisible() && normResult !== undefined ? true : false
    const resultSummary = getResultSummary(pt, context.language)

    return showResult ? (
      <TableContentContainer style={{ display: 'block', textAlign: 'right' }}>
        <span>{t(resultSummary)}</span>
      </TableContentContainer>
    ) : (
      <></>
    )
  }
})

const getResultSummary = (pt, locale) => {
  if (pt.hasCrosstestResult()) {
    const crosstestResult =
      pt.relatedResult?.crosstestResult[locale] || pt.relatedResult?.crosstestResult[DEFAULT_LANGUAGE]
    return crosstestResult?.peculiarityText
  } else {
    const normResult = pt.relatedResult?.normResult
    const grade = getFormattedNumber(pt.relatedResult?.grade, locale)
    return `${normResult} (${grade})`
  }
}

const getResultAccessor = (pt) => {
  if (pt.hasCrosstestResult()) {
    return pt.relatedResult.crosstestResult[DEFAULT_LANGUAGE].peculiarityLevel || 0
  }
  if (isTraitsOrGoals(pt.relatedConfig.configType)) {
    return pt.relatedAssessment.hasMatching() ? pt?.relatedResult?.normResult || 0 : 0
  }
  if (pt.hasResult()) {
    return pt?.relatedResult?.normResult
  }
  return 0
}

export const getNumericResult = (pt) => {
  if (pt.hasCrosstestResult()) {
    return null
  }
  if (isTraitsOrGoals(pt.relatedConfig.configType)) {
    return pt.relatedAssessment.hasMatching() ? pt?.relatedResult?.normResult : null
  }
  if (pt.hasResult()) {
    return pt?.relatedResult?.normResult
  }
  return null
}

export const getGradeResult = (pt) => {
  if (pt.hasCrosstestResult()) {
    return null
  }
  if (isTraitsOrGoals(pt.relatedConfig.configType)) {
    return pt.relatedAssessment.hasMatching() ? pt?.relatedResult?.grade : null
  }
  if (pt.hasResult()) {
    return pt?.relatedResult?.grade
  }
  return null
}

export const getCrosstestResult = (pt, locale) => {
  if (pt.hasCrosstestResult()) {
    return pt.relatedResult.crosstestResult[locale].peculiarityText
  }
  return null
}

export const getPtTimingAndSettingsTab = (pt) => ({
  label: 'timesAndSettings',
  id: 'ptTimings',
  size: '190px',
  content: <PtTimingAndSettingsTab pt={pt} />
})

export const getPtAccessDataTab = (pt) => ({
  label: 'accessData',
  id: 'ptInviteLink',
  content: <PtAccessDataTab pt={pt} />
})

export const getPtResultTab = (pt) => ({
  label: 'result',
  size: '100px',
  id: 'result',
  content: <PtResultTab pt={pt} config={pt.relatedConfig} />
})

export const getPtStatusLabelsForFilter = () => {
  const ids = Object.keys(PT_STATUS_NAMES)
  return ids.map((id) => <PtStatusLabelForFilter key={id} id={id} />)
}

const PtStatusLabelForFilter = ({ id }) => {
  const t = useTranslate()
  const style = {
    display: 'inline-grid',
    width: 'fit-content',
    gridTemplateColumns: '15px 1fr',
    alignItems: 'baseline'
  }
  return (
    <div style={style}>
      <Badge style={{ transform: 'translateY(-1px)' }} status={parseInt(id)} />
      <span>{t(PT_STATUS_NAMES[id])}</span>
    </div>
  )
}
