import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Table, WizardStep, WizardStepContainer } from '../userManualElements'

const Chapter4English = () => (
  <>
    <h2 id="chapter-4">4 Credit System</h2>
    <h3 id="chapter-4-1">4.1 General Information</h3>
    <h4 id="chapter-4-1-1">4.1.1 What are Credits Used For?</h4>
    <p>
      With PERSEO Credits, we charge for the administration of tests. Credits can be used for all tests and additional
      modules. You can also use credits to pay for individual test designs. The advantage is that they are flexible, and
      you don&apos;t need to commit to specific tests groups in advance.
    </p>
    <h4 id="chapter-4-1-2">4.1.2 What is the Cost of Credits?</h4>
    <CreditPricingTable />
    <h4 id="chapter-4-1-3">4.1.3 Do I Need to Purchase a Specific Credit Package?</h4>
    <p>No, you can purchase the exact amount of credits you need and receive the corresponding volume discount.</p>
    <h4 id="chapter-4-1-4">4.1.4 Do Credits Expire?</h4>
    <p>
      No, credits have unlimited validity and do not need to be used within a specific time frame. However, if the
      customer account is deleted, remaining credits cannot be refunded.
    </p>
    <h4 id="chapter-4-1-5">4.1.5 When are Credits Deducted?</h4>
    <p>
      Credits are deducted once a testing is completed. No credits are deducted for aborted and expired testings.
      Creating tests and participants also does not incur any costs.
    </p>
    <h4 id="chapter-4-1-6">4.1.6 What Billing Options Are Available?</h4>
    <p>Credits can be billed in advance or retrospectively.</p>
    <h5>Billing in Advance</h5>
    <p>
      You can top up your credit account in the shop at any time and conduct testings using the available balance. This
      option is useful if you conduct a lot of tests and want to secure volume discounts.
    </p>
    <h5>Retrospective Billing</h5>
    <p>
      If you conduct fewer tests or don&apos;t know how many tests you&apos;ll conduct, you can use our retrospective
      billing. In this case, you conduct testings without having loaded credit in advance. The resulting negative
      balance will be automatically balanced at the beginning of each month by crediting the outstanding amount to the
      customer account and invoicing the corresponding number of credits.
    </p>
    <h3 id="chapter-4-2">4.2 Top Up Credits</h3>
    <p>
      Open the <em>Credit Shop</em> page by clicking on <B>Credit Shop</B> in the left sidebar or by clicking on{' '}
      <B>Charge Credits</B> above the booking table.
    </p>
    <TimeLineContainer className="wizard">
      <Entry>
        <WizardStepContainer>
          <WizardStep>1. Determine Number of Credits</WizardStep>
          <ul>
            <li>
              If you&apos;re unsure how many credits you need, you can use the credit calculator: Select your desired
              product under <em>Product</em> and enter the required quantity under <em>Quantity</em>. The total needed
              credits will be automatically calculated and displayed as an additional option under{' '}
              <em>Credit Packages</em>.
            </li>
            <li>
              Choose how many credits you want to top up by clicking the respective button. If you want to top up with a
              custom number of credits, you can manually enter the number in the <em>Credit Quantity</em> field under{' '}
              <em>Summary</em>.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>2. Payment Method and Invoice Data</WizardStep>
          <ul>
            <li>Select a payment method.</li>
            <li>
              If you haven&apos;t added invoice data yet, click <B>Add</B> under <em>Invoice Data</em>. Enter your
              invoice data in the form and click <B>Save</B>. If your invoice data are already saved in the system, you
              can modify them by clicking <B>Edit</B>.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>3. Review Order</WizardStep>
          <ul>
            <li>Review your order.</li>
            <li>
              Click <B>Buy</B> to complete the purchase. The credits will be added to your account immediately. The
              invoice will be automatically sent to you via email.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
    </TimeLineContainer>
  </>
)

export default Chapter4English

const CreditPricingTable = () => {
  const header = ['Quantity', 'Price / Credit', 'Package Price']
  const data = [
    ['1', '3.80 €'],
    ['100', '3.20 €', '320 €'],
    ['500', '2.85 €', '1,425 €'],
    ['1,000', '2.65 €', '2,650 €'],
    ['2,000', '2.40 €', '4,800 €'],
    ['5,000', '2.20 €', '11,000 €'],
    ['10,000', '', 'upon request']
  ]
  return <Table name="credit-pricing" {...{ header, data }} />
}
