import { CustomCheckmark, CheckboxElement, HoverConnector } from '../../utils/elements/miscElements'
import styled from 'styled-components'
import TestCard from '../testCard/TestCard'

const TestSelectElement = ({ assessment, config, isSelected, updateState }) => {
  const uuid = assessment.assessmentUuid

  const isDisabled = !assessment.isVisible() || assessment.cannotAddToTest

  const handleSelection = (e, uuid, isDisabled) => {
    e.preventDefault()
    if (!isDisabled) {
      updateState(uuid)
    }
    return
  }

  return (
    <HoverConnector>
      <OuterContainer key={uuid}>
        <>
          <CheckboxElement
            onClick={(e) => handleSelection(e, uuid, isDisabled)}
            disabled={isDisabled}
            checked={isSelected}
            style={{ padding: 0, borderRadius: 'var(--bdr-2)', overflow: 'hidden' }}>
            <TestCard assessment={assessment} config={config} isSelectable />
          </CheckboxElement>
          {!isDisabled && (
            <CustomCheckmark
              style={{ justifySelf: 'right' }}
              type="checkbox"
              key={`assessment${uuid}`}
              onClick={(e) => handleSelection(e, uuid, isDisabled)}
              checked={isSelected}
              disabled={isDisabled}
            />
          )}
        </>
      </OuterContainer>
    </HoverConnector>
  )
}

export default TestSelectElement

const OuterContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 50px;
`
