import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { AppContext } from '../context/AppContext'
import { getActivePage } from '../helper/Helper'
import useTranslate from './useTranslate'

const useDocumentTitle = () => {
  const t = useTranslate()
  const location = useLocation()
  const context = useContext(AppContext)
  const activePage = getActivePage(location, context.language)

  useEffect(() => {
    setDocumentTitle(activePage?.documentTitle, t)
    const h2 = document.querySelector('h2')
    const h1 = document.querySelector('h1')

    if (h2) {
      h2.tabIndex = -1
      h2.focus()
    } else if (h1) {
      h1.tabIndex = -1
      h1.focus()
    }
  }, [activePage, context.language])
}

export default useDocumentTitle

const setDocumentTitle = (prefix, t) => {
  const appendix = t('customerArea')
  const spacer = '|'
  document.title = `${t(prefix) || t('loading')} ${spacer} ${appendix}`
}
