import { SettingsContainer } from './sharedElements/sharedElements'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import TabGroup from '../tabGroup/TabGroup'
import BillingDataSettingsComponent from './BillingDataSettingsComponent'
import CustomerSettingsComponent from './CustomerSettingsComponent'
import DeleteAccountComponent from './deleteAccount/DeleteAccountComponent'
import useTranslate from '../../utils/hooks/useTranslate'

const AccountSettingsComponent = () => {
  const t = useTranslate()

  const content = (
    <SettingsContainer>
      <ScrollContainer type="settings">
        <h2>{t('accountSettings')}</h2>
        <TabGroup
          position="settings-nav"
          tabs={[
            {
              label: 'companyData',
              id: 'userData',
              content: <CustomerSettingsComponent />
            },
            {
              label: 'invoiceData',
              id: 'capabilities',
              content: <BillingDataSettingsComponent />
            },
            {
              label: 'account',
              id: 'account',
              content: <DeleteAccountComponent />
            }
          ]}
        />
      </ScrollContainer>
    </SettingsContainer>
  )

  return content
}

export default AccountSettingsComponent
