import { useContext } from 'react'
import { ENTITIES, ERROR_MSG, FIELD_LABELS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { InfoList, InfoElement } from '../../utils/elements/miscElements'
import { getEntityLabel, getOptionByValue } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import { Tile, TileHeadline } from '../table/tableElements'
import { AppContext } from '../../utils/context/AppContext'

const AddParticipantCheckInputTile = ({ data, assessments, maximumCreditSum }) => {
  const testCount = data.assessmentUuids.length

  return (
    <Tile width="300px">
      <TileHeadline content="yourSelection" />
      <InfoList>
        <SelectedProcess {...{ data }} />
        <SelectedAssessments {...{ testCount, data, assessments }} />
        <DataSource {...{ data }} />
        <NumberTests {...{ testCount, data }} />
        <MaxCredits {...{ maximumCreditSum }} />
        <Anonymous {...{ data }} />
      </InfoList>
    </Tile>
  )
}
export default AddParticipantCheckInputTile

const SelectedProcess = ({ data }) => {
  const context = useContext(AppContext)
  const { completeDataSet } = context
  return (
    <InfoElement
      label="processes"
      value={
        completeDataSet.processes.find((process) => process.processUuid === data.processUuid)?.processName || ERROR_MSG
      }
    />
  )
}

const SelectedAssessments = ({ testCount, data, assessments }) => (
  <InfoElement
    label={getEntityLabel('assessments', testCount)}
    value={
      <ul style={{ padding: 0, listStyle: 'none', margin: 0 }}>
        {data.assessmentUuids.map((assessmentUuid, i) => (
          <li key={assessmentUuid + '_' + i}>
            {assessments.find((assessment) => assessment.assessmentUuid === assessmentUuid)?.assessmentName ||
              ERROR_MSG}
          </li>
        ))}
      </ul>
    }
  />
)

const NumberTests = ({ testCount, data }) => {
  const t = useTranslate()
  const testLabel = getEntityLabel(ENTITIES.assessments, testCount)
  const totalTans = testCount * data.participantCount
  const tanLabel = getEntityLabel(ENTITIES.pts, totalTans)
  const participantLabel = getEntityLabel(ENTITIES.participants, data.participantCount)

  return (
    <InfoElement
      label="numberOfPts"
      value={`${data.participantCount} ${t(participantLabel)} x ${testCount} ${testLabel} = ${totalTans} ${t(
        tanLabel
      )}`}
    />
  )
}

const MaxCredits = ({ maximumCreditSum }) => {
  const t = useTranslate()
  return (
    <InfoElement
      label={FIELD_LABELS.maxCredits}
      value={`${maximumCreditSum} ${maximumCreditSum === 0 ? '(' + t('free') + ')' : ''}`}
    />
  )
}

const Anonymous = ({ data }) => {
  const t = useTranslate()
  return (
    <InfoElement
      label={FIELD_LABELS.isAnonymous}
      value={getOptionByValue(t(SELECT_OPTIONS.isAnonymous), data.pAnon).label || ERROR_MSG}
    />
  )
}

const DataSource = ({ data }) => {
  const t = useTranslate()
  return (
    <InfoElement
      label={FIELD_LABELS.useFileUpload}
      value={getOptionByValue(t(SELECT_OPTIONS.useFileUploadShort), data.useFileUpload).label || ERROR_MSG}
    />
  )
}
