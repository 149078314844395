import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useContext, useState } from 'react'
import Config from '../../utils/constants/config'
import { AppContext } from '../../utils/context/AppContext'
import Shop from './Shop'
import { getStripeOptions } from './stripe/stripeUtils'

const ShopWrapper = () => {
  loadStripe.setLoadParameters({ advancedFraudSignals: false })
  const [stripePromise] = useState(() => loadStripe(Config.stripe_key))

  const context = useContext(AppContext)
  const language = context.language
  const options = getStripeOptions(language)

  return (
    <Elements options={options} stripe={stripePromise}>
      <Shop />
    </Elements>
  )
}

export default ShopWrapper
