import React, { useContext } from 'react'
import DataTable from '../../components/table/DataTable'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { PageHeadline } from '../../utils/elements/miscElements'
import { ENTITIES, FIELD_LABELS, SUCCESS_PAGE_TYPES } from '../../utils/constants/constants'
import { allParticipantsHaveMailAddress, allPtsInHub } from '../../utils/helper/Helper'
import CsvDownload from '../../components/csvDownload/CsvDownload'
import styled from 'styled-components'
import { getParticipantNumberCol } from '../../pages/dashboard/dashboardUtils'
import PtListForTable from '../../components/ptListForTable/PtListForTable'
import useTranslate from '../../utils/hooks/useTranslate'
import { getHubLinkCol, getSpacerCol } from '../../components/table/tableCols/tableCols'
import { AppContext } from '../../utils/context/AppContext'

const AddPtSuccessPage = ({ newData, type }) => {
  const context = useContext(AppContext)
  const { completeDataSet } = context

  const newParticipants = completeDataSet.participants.filter((p) => newData.participantNumbers.includes(p.pNr))
  const newPts = completeDataSet.pts.filter((pt) => newData.ptNumbers.includes(pt.ptNumber))

  const showMailColumn = newParticipants.some((p) => p.pMail)
  const t = useTranslate()

  const getCols = () => [
    {
      Header: '#',
      Cell: ({ row }) => <span>{row.index + 1}</span>,
      disableSortBy: true
    },
    getParticipantNumberCol(),
    {
      Header: 'pts',
      id: 'assessmentName',
      canExport: true,
      disableSortBy: true,
      show: true,
      accessor: (row) => row.ptList.map((pt) => pt.relatedAssessment.assessmentName),
      Cell: ({ row }) => (
        <PtListForTable ptList={row.original.ptList.filter((pt) => newData.ptNumbers.includes(pt.ptNumber))} />
      )
    },
    {
      Header: FIELD_LABELS.email,
      accessor: (row) => row.pMail,
      Cell: ({ value }) => <span style={{ wordWrap: 'break-word' }}>{value}</span>,
      disableSortBy: true,
      show: showMailColumn,
      id: 'pmail'
    },
    getHubLinkCol({ disableSortBy: true, isHidden: !allPtsInHub(newPts) }),
    getSpacerCol()
  ]

  const columns = React.useMemo(() => getCols(), [])
  const headline = getHeadline(newParticipants, newPts, type, t)

  return (
    <>
      {newParticipants && (
        <>
          <PageHeadline>{headline}</PageHeadline>
          <ScrollContainer>
            <h3 style={{ marginTop: 0 }}>{t('nextSteps')}</h3>
            <NextSteps {...{ newPts, newParticipants }} />
            <h3>{t('createdParticipantsAndTestings')}</h3>
            <div style={{ maxWidth: '700px', overflow: 'scroll' }}>
              <DataTable data={newParticipants} columns={columns} hideResultCount hideArchiveButton />
            </div>
          </ScrollContainer>
        </>
      )}
    </>
  )
}

export default AddPtSuccessPage

const getHeadline = (newParticipants, newPts, type, t) => {
  if (!newParticipants) return t('noParticipantsCreated')

  const entityCount = newPts.length / newParticipants.length

  switch (type) {
    case SUCCESS_PAGE_TYPES.ptsOnly:
      return t('ptAddedHeadline', newParticipants.length, entityCount)
    case SUCCESS_PAGE_TYPES.newParticipants:
    default:
      return t('ptCreatedHeadline', newParticipants.length, entityCount)
  }
}

const NextSteps = ({ newPts, newParticipants }) => {
  const t = useTranslate()

  return (
    <NextStepsContainer>
      <NextStepsElement>
        <NextStepsHeadline>{t('downloadCredentials')}</NextStepsHeadline>
        {t(
          'accessTestInstructions',
          showParticipantNumber(newPts),
          <ElektryonLink />,
          <AccessDataLink {...{ newPts }} />
        )}
      </NextStepsElement>
      <NextStepsElement>
        <NextStepsHeadline>{t('sendTestInvitations')}</NextStepsHeadline>
        <span>
          {t('sendInvitationsInstructions')}{' '}
          {allParticipantsHaveMailAddress(newParticipants) && t('sendInvitationsFromDashboard')}
        </span>
      </NextStepsElement>
      <NextStepsElement>
        <NextStepsHeadline>{t('retrieveResults')}</NextStepsHeadline>
        <span>{t('retrieveResultsInstructions')}</span>
      </NextStepsElement>
    </NextStepsContainer>
  )
}

const showParticipantNumber = (newPts) => allPtsInHub(newPts)

const ElektryonLink = () => (
  <a
    className="textlink"
    style={{ hyphens: 'none' }}
    target="_blank"
    rel="noreferrer"
    href="https://app.perseo-assessment.de">
    app.perseo-assessment.de
  </a>
)

const AccessDataLink = ({ newPts }) => {
  const t = useTranslate()
  return (
    <span className="textlink" style={{ position: 'relative' }}>
      <CsvDownload rowData={newPts} entity={allPtsInHub(newPts) ? ENTITIES.accessDataHub : ENTITIES.accessData} />
      {t('here')}
    </span>
  )
}

const NextStepsContainer = styled.ul`
  display: grid;
  gap: var(--space-6);
  list-style: none;
  background-color: var(--box-background);
  padding: var(--space-5);
  border-radius: var(--bdr-2);
  max-width: 700px;
`

const NextStepsElement = styled.li`
  line-height: var(--lh-3);
  font-size: var(--fs-3);
`

const NextStepsHeadline = styled.h4`
  margin: 0;
  font-size: var(--fs-3);
  line-height: var(--lh-3);
`
