import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const CameraHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <CameraHelpEnglish />
  } else {
    return <CameraHelpGerman />
  }
}

export default CameraHelp

const CameraHelpGerman = () => (
  <div>
    <p>
      Wenn Sie diese Option aktivieren, müssen die Teilnehmer zu Beginn des Tests ihre Kamera freigeben. Die Kamera wird
      während der Aufgabenbearbeitung automatisch eingeschaltet und zwischen den Aufgabenblöcken ausgeschaltet.
    </p>
    <div className="textbox important">
      <p>
        Bei diesem Feature handelt es sich um eine reine Abschreckungsmaßnahme. Es werden keine Bilder an PERSEO übertragen.
      </p>
    </div>
    <p>
      Unsere Software erkennt, wenn die Kamera abgeklebt ist oder das Bild stillsteht (z.B., wenn die Kamera auf eine
      Wand zeigt). Hierzu wird <strong>keine</strong> künstliche Intelligenz oder Ähnliches eingesetzt. Die
      Bildkontrolle wird auf dem Endgerät des Teilnehmers durchgeführt. Bei abgeklebter Kamera oder stillstehendem Bild
      wird dem Teilnehmer ein Hinweis angezeigt. Als Kunde erhalten Sie hierüber einen Hinweis bei den Testergebnissen.
    </p>
    <p>
      Sie können die Kameraüberwachung mit oder ohne Identitätsnachweis durchführen. Wenn Sie die Option{' '}
      <em>Mit Identitätsnachweis</em> aktivieren, werden die Teilnehmer gebeten, ein Webcam-Foto aufzunehmen, das sie
      gemeinsam mit einem Lichtbildausweis zeigt.
    </p>
    <div className="textbox important">
      <p>
        Auch hier werden keine Daten an PERSEO übertragen. Das Bild verbleibt auf dem Endgerät des Teilnehmers und wird in
        der Regel automatisch gelöscht.
      </p>
    </div>
    <p>Wenn Sie die Kameraüberwachung nutzen möchten, bedenken Sie bitte die folgenden Aspekte:</p>
    <ul>
      <li>
        Eine Kamera kann als Eingriff in die Privatsphäre wahrgenommen werden. Dies könnte manche Bewerber von der
        Testteilnahme abschrecken.
      </li>
      <li>
        Manche Teilnehmer haben keine Kamera zur Verfügung. Überlegen Sie im Vorfeld, wie Sie mit etwaigen Anfragen zu
        diesem Thema umgehen möchten.
      </li>
      <li>
        Kommunizieren Sie die Kamera-Nutzung im Vorfeld und weisen darauf hin, dass keine automatisierte Auswertung
        durch künstliche Intelligenz erfolgt.
      </li>
    </ul>
  </div>
)

const CameraHelpEnglish = () => (
  <div>
    <p>
      If you enable this option, participants will need to share their camera at the start of the test. The camera will
      automatically turn on during the task and turn off between task blocks.
    </p>
    <div className="textbox important">
      <p>This feature is purely a deterrent measure. No images are transmitted to PERSEO.</p>
    </div>
    <p>
      Our software detects if the camera is covered or if the image is still (e.g., if the camera is pointing at a
      wall). No artificial intelligence or similar technology is used for this. The image check is performed on the
      participant’s device. If the camera is covered or the image is still, a notification will be displayed to the
      participant. As a customer, you will be notified of this in the test results.
    </p>
    <p>
      You can conduct camera monitoring with or without ID check. If you enable the{' '}
      <em>with ID Check</em> option, participants will be asked to take a webcam photo showing them
      together with a photo ID.
    </p>
    <div className="textbox important">
      <p>
        Again, no data is transmitted to PERSEO. The image remains on the participant’s device and is usually deleted
        automatically.
      </p>
    </div>
    <p>If you wish to use camera monitoring, please consider the following aspects:</p>
    <ul>
      <li>
        A camera can be perceived as an invasion of privacy. This may discourage some applicants from participating in
        the test.
      </li>
      <li>
        Some participants may not have a camera available. Think in advance about how you will handle any inquiries on
        this topic.
      </li>
      <li>
        Communicate the use of the camera in advance and point out that no automated evaluation by artificial
        intelligence is performed.
      </li>
    </ul>
  </div>
)
