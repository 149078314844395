import styled from 'styled-components'

const News = ({ text }) => (
  <>
    {text && (
      <NewsContainer>
        <NewsContent>
          <span className="svg-icon icon-info" />
          <span className="news-text">{text}</span>
        </NewsContent>
      </NewsContainer>
    )}
  </>
)

export default News

export const NewsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--space-6);
  width: 90%;
  max-width: var(--modal-medium);
`

export const NewsContent = styled.div`
  width: 100%;
  background-color: var(--textbox-important-bg-color);
  padding: var(--space-5) var(--space-7);
  border-radius: var(--bdr-2);
  font-size: var(--fs-3);
  line-height: var(--lh-2);
  display: grid;
  align-items: flex-start;
  grid-template-columns: 30px 1fr;
  .svg-icon {
    width: 20px;
    height: 20px;
    margin-top: var(--space-1);
  }
  .news-text {
    line-height: var(--lh-2);
  }
`
