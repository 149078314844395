import { API_DATA, MODAL_TYPES, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import { fetchData, setStatusBar, updateEntities } from '../../../utils/helper/Helper'

export const getInitialTestWeights = (process) => {
  return process.relatedAssessments.reduce(
    (obj, { assessmentUuid, weight }) => ({
      ...obj,
      [assessmentUuid]: weight || 0
    }),
    {}
  )
}

export const openModal = (testWeights, useProcessResult, processUuid, context) => {
  context.setModalProps({
    headline: 'changeCalculationMethod',
    content: 'confirmCalculationMethodChange',
    buttonPrimary: (
      <ButtonPrimary
        modalButton
        content="change"
        onClick={() => {
          context.setModalOpen(false)
          saveProcessResultSettings(testWeights, useProcessResult, processUuid, context)
        }}
      />
    ),
    icon: 'icon-bell modal',
    type: MODAL_TYPES.alert
  })
  context.setModalOpen(true)
}

const saveProcessResultSettings = (testWeights, useProcessResult, processUuid, context) => {
  const assessmentPromises = editAssessments(testWeights, context)
  const processPromise = editProcess(useProcessResult, processUuid, context)
  const recalculatePromise = recalculateProcessResults(processUuid, context)
  const allPromises = [...assessmentPromises, processPromise, recalculatePromise]

  Promise.all(allPromises).then((values) => {
    const updates = {
      assessments: values.flatMap((value) => value.response.data.assessments).filter((value) => value),
      processes: values.find((value) => value.response.data.processes).response.data.processes,
      processResults: values.find((value) => value.response.data.processResults).response.data.processResults
    }

    updateEntities(updates, context)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'changesSaved'
    })
  })
}

const recalculateProcessResults = (processUuid, context) => {
  const endPoint = API_DATA.processResults.endPointRecalculate
  const payload = { [API_DATA.processes.idKey]: processUuid }
  return fetchData(payload, endPoint, context)
}

const editAssessments = (testWeights, context) => {
  const assessmentUuids = Object.keys(testWeights)
  const endPoint = API_DATA.assessments.endPointEdit

  return assessmentUuids.map((assessmentUuid) => {
    const payload = {
      [API_DATA.assessments.idKey]: [assessmentUuid],
      weight: testWeights[assessmentUuid] || 0
    }

    return fetchData(payload, endPoint, context)
  })
}

const editProcess = (useProcessResult, processUuid, context) => {
  const endPoint = API_DATA.processes.endPointEdit
  const payload = {
    [API_DATA.processes.idKey]: [processUuid],
    useProcessResult: useProcessResult
  }

  return fetchData(payload, endPoint, context)
}

export const weightsAddUpTo100 = (testWeights) => getWeightsSum(testWeights) === 100

export const getWeightsSum = (testWeights) => {
  const weights = Object.values(testWeights)
  const sum = weights.reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0)
  if (isNaN(sum)) {
    return ''
  }

  return sum
}
