import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import DataTable from '../../../components/table/DataTable'
import { capabilitiesColumn } from '../../../components/table/tableElements'
import { AppContext } from '../../../utils/context/AppContext'
import { PageHeadline } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getProcessCol, prepareTableData } from '../addUserUtils'

const UserCapabilitiesSelectionPage = ({ data, setData }) => {
  const context = useContext(AppContext)
  const t = useTranslate()

  const tableData = prepareTableData(context.completeDataSet.processes, data, setData)

  const columns = getProcessCol()
  columns.push(capabilitiesColumn)

  return (
    <>
      <PageHeadline>{t('setPermissions')}</PageHeadline>
      <ScrollContainer>
        <div>
          <div>
            <DataTable
              data={tableData}
              columns={columns}
              selectedRows={{}}
              showRowSelection={false}
              hideResultCount
              hideArchiveButton
              tableName="user-capabilities-selection"
            />
          </div>
        </div>
      </ScrollContainer>
    </>
  )
}

export default UserCapabilitiesSelectionPage
