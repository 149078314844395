import styled from 'styled-components'

export const CreditButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-5);
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const CheckboxElement = styled.button`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  padding: var(--space-4);
  flex-grow: 1;
  border-radius: var(--border-radius-checkbox-element);
  transition: background-color var(--hover-duration);
  background-color: ${(props) => getBgColor(props)};
  color: ${(props) => (props.checked ? '#fff' : '')};
  cursor: pointer;
  border-color: ${(props) => (props.checked ? 'var(--filled-button-checked-border-color)' : 'transparent')};
  border-width: 1px;
  border-style: solid;
  &:hover {
    background-color: ${(props) => getBgHoverColor(props)};
  }
`

const getBgColor = (props) => {
  if (props.isCalculated) {
    return props.checked ? 'var(--filled-button-checked-bg-color)' : 'var(--credit-button-calculated-bg-color)'
  }
  return props.checked ? 'var(--filled-button-checked-bg-color)' : 'var(--box-background)'
}

const getBgHoverColor = (props) => {
  if (props.isCalculated) {
    return props.checked ? 'var(--filled-button-checked-bg-color)' : 'var(--credit-button-calculated-hover-color)'
  }
  return props.checked ? 'var(--filled-button-checked-bg-color)' : 'var(--filled-button-hover-color)'
}

export const PriceSummaryContainer = styled.div`
  display: grid;
  gap: var(--space-3);
  justify-items: flex-end;
  background-color: var(--box-background);
  padding: var(--space-5);
  border-radius: var(--border-radius-checkbox-element);
  grid-column: 3/5;
  * {
    line-height: var(--lh-3);
  }

  @media screen and (max-width: 1100px) {
    grid-column: 2/4;
  }
  @media screen and (max-width: 900px) {
    grid-column: 1/4;
  }
  @media screen and (max-width: 768px) {
    grid-column: 1/3;
  }
`

export const PriceEntry = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: baseline;
  width: 100%;
  justify-items: flex-end;
`

export const PriceTag = styled.span`
  font-weight: ${(props) => (props.highlight ? 600 : 400)};
  font-size: ${(props) => (props.highlight ? 'var(--fs-5)' : '')};
  text-align: right;
  color: ${(props) => (props.red ? 'var(--price-tag-discount-color)' : 'var(--text-color-primary)')};
`

export const NumberCredits = styled.div`
  font-size: var(--fs-7);
  font-weight: var(--fw-2);
  line-height: var(--lh-3);
`

export const Price = styled.span`
  line-height: var(--lh-3);
`

export const DiscountLabel = styled.span`
  color: ${(props) => (props.checked ? 'var(--credit-button-checked-label-color) ' : '')};
  line-height: var(--lh-3);
`

export const Container = styled.div`
  display: grid;
  text-align: center;
  align-items: center;
  width: 100%;
  gap: var(--space-1);
  transform: translateY(2px);
`

export const Label = styled.span`
  line-height: var(--lh-2);
  color: ${(props) => (props.checked ? 'var(--credit-button-checked-label-color) ' : '')};
`
