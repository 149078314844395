import { MenuGroup } from '../navbar/NavbarElements'
import { useContext, useLayoutEffect, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { MenuContainer, MenuLink, Icon, MenuLabel, MenuButton, MenuInnerContainer } from './contextMenuElements'
import styled from 'styled-components'
import Portal from '../../hoc/Portal'
import React from 'react'
import useCurrentRect from '../../utils/hooks/useCurrentRect'
import useTranslate from '../../utils/hooks/useTranslate'
import OutsideAlerter from '../outsideAlerter/OutsideAlerter'
import { AppContext } from '../../utils/context/AppContext'
import usePageUrl from '../../utils/hooks/usePageUrl'

const ContextMenu = ({ isVisible, entries, hideMenu, width, position, id, target }) => {
  const [coords, setCoords] = useState({})
  const { loggedInAsAdmin } = useContext(AppContext)
  const windowWidth = useCurrentRect().width
  const menuOffsetRight = windowWidth < 768 ? 16 : 32
  const t = useTranslate()

  useLayoutEffect(() => {
    if (position === 'table') {
      const toggleButtonRect = target ? target.getBoundingClientRect() : null
      if (toggleButtonRect) {
        setCoords({
          left: toggleButtonRect.x - 220,
          top: toggleButtonRect.y + 25
        })
      }
    } else {
      const MENU_COORDS = { top: 48, right: menuOffsetRight, left: '', bottom: '' }
      setCoords(MENU_COORDS)
    }
  }, [position, isVisible, target, menuOffsetRight])

  const getUrlFromPage = usePageUrl()

  return (
    <>
      {isVisible && (
        <Portal rootId="context-menu" setCoords={setCoords} coords={coords}>
          <TransitionGroup>
            <CSSTransition key="contextMenu" in={isVisible} timeout={300} classNames="fade" unmountOnExit>
              <OutsideAlerter hideMenu={hideMenu} id={id}>
                <MenuContainer position={position} style={{ width: width }} className="Menu" coords={coords}>
                  {entries
                    .filter((group) => group.length > 0)
                    .map((group, groupIndex) => (
                      <MenuGroup key={`entryGroup${groupIndex}`}>
                        <MenuInnerContainer>
                          {group
                            .filter((entry) => (entry.adminOnly && loggedInAsAdmin) || !entry.adminOnly)
                            .map((entry) => (
                              <li key={entry.label} className="top-menu-list-item" onClick={hideMenu}>
                                {entry.type === 'link' && (
                                  <MenuLink to={getUrlFromPage(entry.to)} onClick={entry.clickHandler}>
                                    <Icon style={entry.iconStyle} className={`svg-icon ${entry.icon}`} />
                                    <MenuLabel>{t(entry.label)}</MenuLabel>
                                  </MenuLink>
                                )}

                                {entry.type === 'button' && (
                                  <MenuButton
                                    isWarning={entry.isWarning}
                                    onClick={entry.clickHandler}
                                    className="menu-button">
                                    <ButtonContent>
                                      <Icon style={entry.iconStyle} className={`svg-icon ${entry.icon} menu-icon`} />
                                      <MenuLabel style={{ color: entry.color }} className="menu-label">
                                        {t(entry.label)}
                                        {entry.hasEllipsis && '…'}
                                      </MenuLabel>
                                    </ButtonContent>
                                  </MenuButton>
                                )}

                                {entry.type === 'display' && (
                                  <MenuLink
                                    to="/"
                                    style={{
                                      pointerEvents: 'none',
                                      alignItems: 'baseline',
                                      transform: 'translateY(4px)'
                                    }}>
                                    <Icon style={entry.iconStyle} className={`svg-icon ${entry.icon} menu-icon`} />
                                    <MenuLabel style={{ color: entry.color }} className="menu-label">
                                      {t(entry.label)}
                                    </MenuLabel>
                                  </MenuLink>
                                )}
                              </li>
                            ))}
                        </MenuInnerContainer>
                      </MenuGroup>
                    ))}
                </MenuContainer>
              </OutsideAlerter>
            </CSSTransition>
          </TransitionGroup>
        </Portal>
      )}
    </>
  )
}

export default ContextMenu

const ButtonContent = styled.span`
  display: inline-grid;
  grid-template-columns: auto 200px;
  justify-items: flex-start;
  align-items: center;
`
