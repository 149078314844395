import { API_DATA, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { fetchData, setState, setStatusBar } from '../../../utils/helper/Helper'

export const saveCustomFieldDefinitions = async ({ fieldDefinitions, context }) => {
  const payload = { fieldDefinitions: fieldDefinitions }
  const responseData = await fetchData(
    payload,
    API_DATA.customFieldDefinitions.endPointEdit,
    context,
    'dataChangeFailed',
    false
  )

  try {
    setState(context.setCompleteDataSet, 'customFieldDefinitions', responseData.response.data.customFieldDefinitions)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'dataSuccessfullyChanged',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}

export const deleteCustomFieldDefinition = async ({ fieldDefinition, context }) => {
  const payload = { fieldDefinition: fieldDefinition }
  const responseData = await fetchData(
    payload,
    API_DATA.customFieldDefinitions.endPointDelete,
    context,
    'fieldDeletionFailed',
    false
  )

  try {
    setState(context.setCompleteDataSet, 'customFieldDefinitions', responseData.response.data.customFieldDefinitions)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'fieldSuccessfullyDeleted',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}

export const getDuplicateLabels = (fields) => {
  return new Promise((resolve) => {
    const duplicateLabels = []
    fields.forEach((field, index) => {
      for (let i = index + 1; i < fields.length; i++) {
        if (field.label && field.label === fields[i].label) {
          if (!duplicateLabels.includes(field.label)) {
            duplicateLabels.push(field.label)
          }
        }
      }
    })
    resolve(duplicateLabels)
  })
}
