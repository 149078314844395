import { API_DATA, ERROR_MSG, FIELD_LABELS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { getOptionByValue } from '../../utils/helper/Helper'
import CustomInput from '../customInput/CustomInput'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'

const UserDataTile = ({ contact }) => (
  <Tile>
    <TileHeadline content="personalData" />
    <CustomInputList>
      <CustomInput
        label={FIELD_LABELS.salutation}
        content={getOptionByValue(SELECT_OPTIONS.contactSalutations, contact.contactGender).label || ''}
        value={contact.contactGender}
        dataKey="contactGender"
        type="select"
        options={SELECT_OPTIONS.contactSalutations}
        idValue={contact.contactUuid}
        apiData={API_DATA.contacts}
      />
      <CustomInput
        label={FIELD_LABELS.lastName}
        content={contact.contactLastName}
        dataKey="contactLastName"
        type="input"
        idValue={contact.contactUuid}
        apiData={API_DATA.contacts}
        disabled={contact.pAnon}
      />
      <CustomInput
        label={FIELD_LABELS.firstName}
        content={contact.contactFirstName}
        dataKey="contactFirstName"
        type="input"
        idValue={contact.contactUuid}
        apiData={API_DATA.contacts}
        disabled={contact.pAnon}
      />
      <CustomInput
        label={FIELD_LABELS.email}
        content={contact.contactEmail}
        dataKey="contactEmail"
        type="email"
        idValue={contact.contactUuid}
        apiData={API_DATA.contacts}
        disabled={contact.pAnon}
      />
      <CustomInput
        label={FIELD_LABELS.phoneNumber}
        content={contact.contactPhone}
        dataKey="contactPhone"
        type="input"
        idValue={contact.contactUuid}
        apiData={API_DATA.contacts}
        disabled={contact.pAnon}
      />
      <CustomInput
        label={FIELD_LABELS.contactIsAdmin}
        content={getOptionByValue(SELECT_OPTIONS.yesNo, contact.contactIsAdmin).label || ERROR_MSG}
        value={contact.contactIsAdmin}
        dataKey="contactIsAdmin"
        type="select"
        options={SELECT_OPTIONS.yesNo}
        idValue={contact.contactUuid}
        apiData={API_DATA.contacts}
      />
    </CustomInputList>
  </Tile>
)

export default UserDataTile
