import styled from 'styled-components'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { getCreditBalance, getFormattedNumber } from '../../utils/helper/Helper'
import { PAGES } from '../../utils/constants/pages'
import useRedirect from '../../utils/hooks/useRedirect'

const CreditsCounter = ({ position }) => {
  const context = useContext(AppContext)
  const creditBookings = context.completeDataSet.creditBookings
  const creditBalance = getCreditBalance(creditBookings)
  const formattedBalance = getFormattedNumber(creditBalance, context.language)
  const balanceColor = getBalanceColor(creditBalance, position)
  const labelColor = getLabelColor(position)
  const redirect = useRedirect()

  return (
    <Container isAdmin={context.loggedInAsAdmin} onClick={() => redirect(PAGES.credits)}>
      <Balance color={balanceColor} large={position === 'dashboard'}>
        {formattedBalance}
      </Balance>
      <Label color={labelColor} className="uppercase-label">
        Credits
      </Label>
    </Container>
  )
}

const getBalanceColor = (creditBalance, position) => {
  if (position === 'navbar') {
    return 'var(--navbar-credit-balance-color)'
  }
  if (creditBalance > 0) {
    return 'var(--positive-booking-color)'
  }
  return ''
}

const getLabelColor = (position) => (position === 'navbar' ? 'var(--navbar-credit-balance-label-color)' : '')

export default CreditsCounter

const Label = styled.span`
  margin-left: var(--space-2);
  color: ${(props) => props.color};
`

const Container = styled.div`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  pointer-events: ${(props) => (props.isAdmin ? 'all' : 'none')};
`

const Balance = styled.span`
  font-size: ${(props) => (props.large ? 'var(--fs-9)' : 'var(--fs-7)')};
  font-weight: var(--fw-1);
  color: ${(props) => props.color};
`

