import React, { useContext, useState } from 'react'
import {
  WizardForm,
  Label,
  OptionDescription,
  OptionEntryWide,
  PageHeadline,
  GreyButton
} from '../../../utils/elements/miscElements'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import CreditSelectButtons from '../subcomponents/CreditSelectButtons'
import CreditCalculator from '../subcomponents/CreditCalculator'
import PriceSummary from '../subcomponents/PriceSummary'
import { AppContext } from '../../../utils/context/AppContext'
import CreditsFaq from '../../creditsFaq'
import AnimateHeight from 'react-animate-height'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'

const CreditSelectionPage = ({ orderDataState, selectedButtonState, productsState, totalState }) => {
  const t = useTranslate()
  const [calculatedSum, setCalculatedSum] = useState(0)
  const [calculatorOpen, setCalculatorOpen] = useState(false)

  const { orderData, setOrderData } = orderDataState

  const context = useContext(AppContext)
  const optionDescriptions = getOptionDescriptions(context, calculatorOpen, setCalculatorOpen, t)

  return (
    <>
      <PageHeadline>{t('creditCount')}</PageHeadline>
      <ScrollContainer>
        <WizardForm style={{ gridGap: 0 }}>
          <OptionEntryWide hasHint style={{ marginBottom: 'var(--space-8)' }}>
            <Label fullWidth htmlFor="creditsCount">
              {t('creditPackages')}
            </Label>
            <CreditSelectButtons
              numberCredits={orderData.numberCredits}
              setOrderData={setOrderData}
              calculatedSum={calculatedSum}
              selectedButtonState={selectedButtonState}
            />
            <OptionDescription description={optionDescriptions.creditAmount} />
          </OptionEntryWide>

          <div>
            <AnimateHeight duration={300} animateOpacity height={calculatorOpen ? 'auto' : 0}>
              <OptionEntryWide hasHint style={{ marginBottom: 'var(--space-8)' }}>
                <Label fullWidth htmlFor="creditsCount">
                  {t('creditCalculator')}
                </Label>
                <CreditCalculator
                  setCalculatedSum={setCalculatedSum}
                  productsState={productsState}
                  totalState={totalState}
                />
                <OptionDescription description={optionDescriptions.creditCalculator} />
              </OptionEntryWide>
            </AnimateHeight>
          </div>

          <OptionEntryWide hasHint>
            <Label fullWidth style={{ textAlign: 'right', gridColumn: 1 }}>
              {t('summary')}
            </Label>
            <PriceSummary orderDataState={orderDataState} />
            <OptionDescription description={optionDescriptions.creditSummary} />
          </OptionEntryWide>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default CreditSelectionPage

const getOptionDescriptions = (context, calculatorOpen, setCalculatorOpen, t) => ({
  creditCalculator: t('calculatedAmountField'),
  creditAmount: (
    <>
      <CreditCalculatorHint>{t('useCreditCalculator')}</CreditCalculatorHint>
      <GreyButton
        className={`calculator-toggle-button ${calculatorOpen ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault()
          setCalculatorOpen(!calculatorOpen)
        }}
        style={{ marginTop: 'var(--space-5)' }}>
        <span className="svg-icon icon-calculator" style={{ marginRight: 'var(--space-3)' }} />
        <span style={{ whiteSpace: 'nowrap' }}>{t('creditCalculator')}</span>
      </GreyButton>
    </>
  ),
  creditSummary: (
    <span>
      {t('enterCustomCreditAmount')}
      <br />
      <br />
      {t('creditsFAQ')}{' '}
      <button
        className="textlink"
        onClick={(e) => {
          e.preventDefault()
          context.setModalProps({
            headline: 'Credits FAQ',
            content: <CreditsFaq />,
            showCloseButton: true,
            hideFooter: true
          })
          context.setModalOpen(true)
        }}>
        Credits FAQ
      </button>
      .
    </span>
  )
})

const CreditCalculatorHint = styled.span`
  display: inline-block;
  line-height: var(--lh-3);
`
