import { getEntityLabel } from '../../utils/helper/Helper'
import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'
import { TableContext } from './DataTable'
import { useContext } from 'react'

const EmptyTableMessage = () => {
  const { tableName } = useContext(TableContext)
  const t = useTranslate()
  const msg = getEmptyTableMessage(tableName, t)
  return (
    <Container>
      <Content>{msg}</Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`

const Content = styled.span`
  font-size: var(--fs-8);
  line-height: var(--lh-4);
  color: var(--grey-500);
  text-align: center;
  letter-spacing: var(--letter-spacing-headline);
`

const getEmptyTableMessage = (tableName, t) => {
  const entity = getEntityLabel(tableName, 2)
  return t('noEntityFound', t(entity))
}

export default EmptyTableMessage
