import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`
export const FieldWrapper = styled.div`
  display: grid;
  gap: var(--space-2);
`

export const Label = styled.label`
  color: var(--text-color-secondary);
  font-size: var(--fs-3);
`

export const Form = styled.form`
  display: grid;
  gap: var(--space-4);
  width: 100%;
`

export const SmallLink = styled(Link)`
  font-size: var(--fs-2);
  text-decoration: none;
  color: var(--link-color);
  width: fit-content;
  &:hover {
    text-decoration: underline;
    color: var(--link-color);
  }
`

export const SmallExternalLink = styled.a`
  font-size: var(--fs-2);
  text-decoration: none;
  color: var(--link-color);
  width: fit-content;
  &:hover {
    text-decoration: underline;
    color: var(--link-color);
  }
`

export const LoginMessage = styled.span`
  font-size: var(--fs-3);
  background-color: ${(props) => (props.isWarning ? 'var(--login-message-warning-bg-color)' : 'var(--login-message-default-bg-color)')};
  padding: var(--space-3);
  border-radius: var(--bdr-2);
  line-height: var(--lh-2);
`
