import React, { useState, useContext } from 'react'
import {
  FormWrapper,
  Form,
  FieldWrapper,
  Label,
  SmallLink,
  SmallExternalLink
} from '../../../utils/elements/FormElements'
import { ButtonPrimary, ErrorMessage, selectStyles } from '../../../utils/elements/miscElements'
import { mailIsValid, getOptionByValue, fetchData } from '../../../utils/helper/Helper'
import { AppContext } from '../../../utils/context/AppContext'
import { FIELD_LABELS, FORM_ERRORS, PRIVATE_MAIL_DOMAINS, SELECT_OPTIONS } from '../../../utils/constants/constants'
import { PAGES } from '../../../utils/constants/pages'
import styled from 'styled-components'
import Select from 'react-select'
import useTranslate from '../../../utils/hooks/useTranslate'
import usePageUrl from '../../../utils/hooks/usePageUrl'

const Signup = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const getUrlFromPage = usePageUrl()

  const [email, setEmail] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [contactFirstname, setContactFirstname] = useState('')
  const [contactLastname, setContactLastname] = useState('')
  const [contactGender, setContactGender] = useState('')

  const [showErrors, setShowErrors] = useState(false)
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [isPrivateMail, setIsPrivateMail] = useState(false)

  const mailIsInvalid = !mailIsValid(email)

  const [contactErrors, setContactErrors] = useState([
    {
      id: 2,
      text: FORM_ERRORS.fieldEmpty,
      show: false
    },
    {
      id: 3,
      text: FORM_ERRORS.emailAlreadyInUse,
      show: false
    },
    {
      id: 4,
      text: FORM_ERRORS.emailAlreadyRequested,
      show: false
    }
  ])

  const [customerErrors, setCustomerErrors] = useState([
    {
      id: 1,
      text: FORM_ERRORS.fieldEmpty,
      show: false
    },
    {
      id: 5,
      text: FORM_ERRORS.nameAlreadyInUse,
      show: false
    },
    {
      id: 6,
      text: FORM_ERRORS.nameAlreadyRequested,
      show: false
    }
  ])

  const handleSubmit = async (e) => {
    e.preventDefault()
    handleErrorVisibility()

    if (customerName.length === 0) {
      handleErrors([1])
    }

    if (email.length === 0) {
      handleErrors([2])
    }

    if (email.length === 0 || customerName.length === 0 || mailIsInvalid) {
      return
    }

    const payload = {
      customerName: customerName.trim(),
      contactMail: email.trim(),
      contactGender: contactGender.trim(),
      contactFirstname: contactFirstname.trim(),
      contactLastname: contactLastname.trim()
    }

    const data = await fetchData(payload, 'add_customer_request1', context, null, false)

    try {
      if (data.response.errorCodes.length > 0) {
        handleErrors(data.response.errorCodes)
      } else {
        setShowSuccessScreen(true)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleErrors = (errorCodes) => {
    const tmpCustomerErrors = customerErrors
    tmpCustomerErrors.forEach((error) => {
      if (errorCodes.includes(error.id)) error.show = true
    })
    setCustomerErrors([...tmpCustomerErrors])

    const tmpContactErrors = contactErrors
    tmpContactErrors.forEach((error) => {
      if (errorCodes.includes(error.id)) error.show = true
    })
    setContactErrors([...tmpContactErrors])
  }

  const handleMailChange = (e) => {
    resetMailErrors()
    setShowErrors(false)
    setEmail(e.target.value)
    setIsPrivateMail(() => checkPrivateMail(e.target.value))
  }

  const handleCustomerNameChange = (e) => {
    resetCustomerErrors()
    setCustomerName(e.target.value)
  }

  const resetMailErrors = () => {
    const tmpContactErrors = contactErrors
    tmpContactErrors.forEach((error) => (error.show = false))
    setContactErrors([...tmpContactErrors])
  }

  const resetCustomerErrors = () => {
    const tmpCustomerErrors = customerErrors
    tmpCustomerErrors.forEach((error) => (error.show = false))
    setCustomerErrors([...tmpCustomerErrors])
  }

  const handleErrorVisibility = () => {
    email.length > 0 ? setShowErrors(true) : setShowErrors(false)
  }

  const hasContactError = contactErrors.find((error) => error.show)
  const hasCustomerError = customerErrors.find((error) => error.show)

  return (
    <FormWrapper>
      {showSuccessScreen ? (
        <>
          <h1>{t('accessRequested')}</h1>
          <SuccessScreen email={email} />
        </>
      ) : (
        <>
          <h1>{t('getAccess')}</h1>
          <div>
            <p>{t('signUpText')}</p>
            <Form onSubmit={handleSubmit} noValidate>
              <ContactDataWrapper>
                <FieldWrapper>
                  <Label>{t(FIELD_LABELS.salutation)}</Label>
                  <Select
                    options={t(SELECT_OPTIONS.contactSalutations)}
                    onChange={(selectedOption) => setContactGender(selectedOption.value)}
                    styles={selectStyles}
                    isSearchable={false}
                    value={getOptionByValue(t(SELECT_OPTIONS.contactSalutations), contactGender)}
                    isSignUp
                    placeholder=""
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <Label>{t('firstName')}</Label>
                  <input type="text" value={contactFirstname} onChange={(e) => setContactFirstname(e.target.value)} />
                </FieldWrapper>

                <FieldWrapper>
                  <Label>{t('lastName')}</Label>
                  <input type="text" value={contactLastname} onChange={(e) => setContactLastname(e.target.value)} />
                </FieldWrapper>
              </ContactDataWrapper>
              <FieldWrapper>
                <Label>
                  {t('company')} / {t('authority')} *
                </Label>
                <input
                  type="text"
                  value={customerName}
                  onChange={handleCustomerNameChange}
                  className={hasCustomerError ? 'has-error' : ''}
                />
                {customerErrors.map((error) => (
                  <ErrorMessage key={'customer' + error.text} visible={error.show}>
                    {t(error.text)}
                  </ErrorMessage>
                ))}
              </FieldWrapper>

              <FieldWrapper>
                <Label>{t('businessEmail')} *</Label>
                <input
                  type="email"
                  onBlur={handleErrorVisibility}
                  onChange={handleMailChange}
                  value={email}
                  className={(mailIsInvalid && showErrors) || hasContactError || isPrivateMail ? 'has-error' : ''}
                />
                {contactErrors.map((error) => (
                  <ErrorMessage key={'contact' + error.text} visible={error.show}>
                    {t(error.text)}
                  </ErrorMessage>
                ))}
                <ErrorMessage visible={mailIsInvalid && showErrors}>{t(FORM_ERRORS.emailInvalid)}</ErrorMessage>
                <ErrorMessage visible={isPrivateMail}>{t('noAccessForPreparation')}</ErrorMessage>
              </FieldWrapper>

              <ButtonPrimary
                type="submit"
                position="login"
                content="getAccess"
                disabled={isPrivateMail || context.maintenanceMode}
              />
            </Form>
          </div>
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 'var(--space-4)' }}>
        <SmallLink to={getUrlFromPage(PAGES.login)}>
          <span>{t('backToLogin')}</span>
        </SmallLink>
        <SmallExternalLink href="https://perseo.hr" to={getUrlFromPage(PAGES.login)}>
          <span>{t('websiteLink')}</span>
        </SmallExternalLink>
      </div>
    </FormWrapper>
  )
}

export default Signup

const ContactDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 88px 1fr 1fr;
  gap: var(--space-3);
`

const SuccessScreen = ({ email }) => {
  const t = useTranslate()
  return (
    <div>
      <p style={{ marginTop: 0 }}>{t('thankYouForRequest', email)}</p>
    </div>
  )
}

const checkPrivateMail = (input) => PRIVATE_MAIL_DOMAINS.some((domain) => input.indexOf(`@${domain}.`) >= 0)
