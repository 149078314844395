import { FIELD_LABELS, REPORT_MODULES } from '../../utils/constants/constants'
import { InfoElement, InfoList } from '../../utils/elements/miscElements'
import { checkDemoStatus, getDurationString, getTranslatedReferenceGroupInfos } from '../../utils/helper/Helper'
import TextWithInfoTooltip from '../textWithInfoTooltip/TextWithInfoTooltip'
import { Tile, TileHeadline } from '../table/tableElements'
import useTranslate from '../../utils/hooks/useTranslate'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const AddAssessmentSelectedTestTile = ({ data }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const ptPrice = getPtPrice(data.parentConfig, data.configUuid, data.reportModulesArray)
  const selectedConfig = data?.parentConfig?.availableConfigs?.find((config) => config.configUuid === data?.configUuid)
  const { referenceGroupTitle, referenceGroupDescription } = getTranslatedReferenceGroupInfos(
    selectedConfig,
    context.language
  )

  return (
    <Tile width="200px">
      <TileHeadline content="selectedTest" />
      <InfoList>
        <InfoElement label="testName" value={data?.parentConfig?.name} />
        <InfoElement label="variant" value={selectedConfig && selectedConfig.configVariant} />
        <InfoElement
          label="referenceGroup"
          value={<TextWithInfoTooltip text={referenceGroupTitle} info={referenceGroupDescription} />}
        />
        <InfoElement
          label="duration"
          value={selectedConfig && getDurationString(selectedConfig.configDuration, t).long}
        />
        <InfoElement
          label="reportModules"
          value={
            <div>
              {data.reportModulesArray.map((moduleKey, i) => {
                const headline = REPORT_MODULES.find((module) => module.key === moduleKey)?.headline || ''
                return (
                  <span key={moduleKey}>
                    {t(headline)}
                    {i < data.reportModulesArray.length - 1 ? ', ' : ''}
                  </span>
                )
              })}
            </div>
          }
        />
        <InfoElement label={FIELD_LABELS.credits} value={ptPrice} />
      </InfoList>
    </Tile>
  )
}

export default AddAssessmentSelectedTestTile

const getPtPrice = (parentConfig, configUuid, reportModulesArray) => {
  const isDemo = checkDemoStatus(parentConfig, configUuid)
  if (isDemo) return 0

  const configPrice =
    parentConfig.availableConfigs.find((config) => config.configUuid === configUuid)?.configCreditsCost || 0
  const chosenModules = REPORT_MODULES.filter((module) => reportModulesArray.includes(module.key))
  const prices = chosenModules.map((module) => (module.price > 0 ? module.price : 0))
  const additionalCredits = prices.reduce((prev, curr) => prev + curr, 0)

  return configPrice + additionalCredits
}
