import ParticipantInfosTile from '../tiles/ParticipantInfosTile'
import { TileContainer } from '../table/tableElements'
import ParticipantCustomFieldsTile from '../tiles/ParticipantCustomFieldsTile'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const ParticipantSettingsTab = ({ participant }) => {
  const context = useContext(AppContext)
  return (
    <TileContainer>
      <ParticipantInfosTile {...{ participant }} />
      {context.completeDataSet.customFieldDefinitions.length > 0 && (
        <ParticipantCustomFieldsTile {...{ participant }} />
      )}
    </TileContainer>
  )
}

export default ParticipantSettingsTab
