import './ToggleSwitch.scss'

const ToggleSwitch = ({ isChecked, handleClick }) => {
  if (isChecked === undefined) return <></>
  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        checked={isChecked}
        onClick={handleClick}
        className="toggle-switch-checkbox"
        id="toggleSwitch"
        readOnly
      />
      <label
        onKeyDown={(e) => handleKeyDown(e, handleClick)}
        tabIndex={0}
        className="toggle-switch-label"
        htmlFor="toggleSwitch">
        <span className="toggle-switch-switch" />
      </label>
    </div>
  )
}

export default ToggleSwitch

const handleKeyDown = (e, handleClick) => {
  if (e.key === 'Enter') {
    handleClick()
  }
}
