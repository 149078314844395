import { getConfigStyle, getEntityLabel, getTotalTestPrice } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import {
  ColorBar,
  Data,
  ErrorNotice,
  NameContainer,
  NewFlag,
  OuterContainer,
  TestIcon,
  TestInfoElement,
  TestInfos,
  TestName
} from './testCardElements'

const TestCard = ({ assessment, config, label, isSelectable }) => {
  const name = assessment.assessmentName
  const ptCount = assessment?.relatedPts.filter((pt) => pt.isVisible()).length || 0
  const errorNotice = getErrorNotice(assessment, isSelectable)
  const t = useTranslate()

  const price = getTotalTestPrice(assessment, config)
  const configStyle = getConfigStyle(config)

  return (
    <OuterContainer>
      <ColorBar className="test-card-color-bar" color={configStyle.color} />
      <NameContainer>
        <TestIcon className={configStyle.icon} color={configStyle.color} />
        <TestName>
          {name} {label && <NewFlag>{t(label)}</NewFlag>}
        </TestName>
        <TestInfos>
          <TestInfoElement>
            <Data>{config.getModuleCount()} </Data>
            <span className="uppercase-label">{t('modules')}</span>
          </TestInfoElement>
          <TestInfoElement>
            <Data>{price} </Data>
            <span className="uppercase-label">Credits</span>
          </TestInfoElement>
          <TestInfoElement>
            <Data>{ptCount} </Data>
            <span className="uppercase-label">{t(getEntityLabel('pts', ptCount))}</span>
          </TestInfoElement>
        </TestInfos>
        {errorNotice && <ErrorNotice>{t(errorNotice)}</ErrorNotice>}
      </NameContainer>
    </OuterContainer>
  )
}

export default TestCard

const getErrorNotice = (assessment, isSelectable) => {
  if (!isSelectable) return ''
  if (assessment.isArchived()) return 'cannotSelectTestInArchive'
  if (assessment.cannotAddToTest) return 'cannotSelectTestDoubleAssignment'
  return ''
}
