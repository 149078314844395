import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'

const GradeLabel = ({ label }) => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const gradeLabel = label[context.language] || t('grade')
  return <span>{gradeLabel}</span>
}

export default GradeLabel
