import React, { useContext } from 'react'
import PtListForTable from '../../components/ptListForTable/PtListForTable'
import { TableContentContainer } from '../../components/table/tableElements'
import { getOptionByValue, capitalizeFirstLetter, isTraitsOrGoals, getFormattedNumber } from '../../utils/helper/Helper'
import {
  CONTENT_TYPES,
  DEFAULT_LANGUAGE,
  FIELD_CATEGORIES,
  FIELD_LABELS,
  FILTER_TYPES,
  PT_STATUS,
  PT_STATUS_NAMES,
  SELECT_OPTIONS,
  genderLabels
} from '../../utils/constants/constants'
import { getCrosstestResult, getGradeResult, getNumericResult, getPtStatusLabelsForFilter } from '../pts/ptUtils'
import { createDate } from '../../utils/helper/dateTimeHelper'
import { ParticipantNameTableCell } from '../../components/table/tableCells/ParticipantNameTableCell'
import { getCustomCols } from '../../components/table/tableCols/customCols'
import { getParticipantNumberCol } from '../dashboard/dashboardUtils'
import ProcessResultListForTable from '../../components/processResultListForTable/ProcessResultListForTable'
import ProcessListForTable from '../../components/processListForTable/ProcessListForTable'
import useTranslate from '../../utils/hooks/useTranslate'
import GradeLabel from '../../components/gradeLabel/GradeLabel'
import { AppContext } from '../../utils/context/AppContext'
import DateDisplay from '../../components/dateDisplay/DateDisplay'
import {
  getActionsCol,
  getCreatedByCol,
  getDateCreatedCol,
  getHubLinkCol,
  getSpacerCol,
  getVisibilityCol
} from '../../components/table/tableCols/tableCols'

export const getParticipantTableCols = (customFieldDefinitions, locale, usingCrosstests, t) => {
  const defaultCols = [
    {
      Header: 'freeTextFilter',
      id: 'freeText',
      accessor: (row) =>
        row.pFullName +
        (row.pMail || '') +
        row.pNr +
        row.ptList.map((pt) => pt.relatedProcess.processName).join() +
        row.ptList.map((pt) => pt.ptNumber).join() +
        row.ptList.map((pt) => pt.relatedAssessment.assessmentName).join() +
        row.ptList.map((pt) => t(PT_STATUS_NAMES[pt.ptStatus])).join() +
        row.customFields.map((cf) => cf.content).join(),
      filterOptions: { type: FILTER_TYPES.textInput },
      canExport: false,
      show: false
    },
    getParticipantNumberCol(),
    {
      Header: 'participantName',
      id: 'pFullName',
      accessor: 'pFullName',
      canExport: true,
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.participant,
        dataLabels: { ANONYM: 'anonym' }
      },
      show: false,
      Cell: ({ value }) => <ParticipantNameTableCell pFullName={value} />
    },
    {
      Header: FIELD_LABELS.gender,
      id: 'pGender',
      canExport: true,
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.participant,
        dataLabels: genderLabels
      },
      show: false,
      accessor: (row) => row.pGender || '',
      Cell: ({ row }) => {
        const t = useTranslate()
        return (
          <TableContentContainer>
            {row.original.pAnon ? '' : getOptionByValue(t(SELECT_OPTIONS.gender), row.original.pGender).label || ''}
          </TableContentContainer>
        )
      }
    },
    {
      Header: FIELD_LABELS.email,
      id: 'pMail',
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.participant },
      canExport: true,
      accessor: (row) => row.pMail || '',
      show: false,
      Cell: ({ value }) => <span style={{ wordWrap: 'break-word' }}>{value}</span>
    },
    {
      Header: 'process',
      id: 'processName',
      canExport: true,
      show: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, label: 'processName' },
      accessor: (row) => row.ptList.map((pt) => pt.relatedProcess.processName),
      Cell: ({ row }) => <ProcessListForTable ptList={row.original.ptList} />
    },
    {
      Header: 'assessment',
      id: 'assessment',
      canExport: true,
      show: false,
      alwaysHidden: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'assessmentName' },
      accessor: (row) => row.ptList.map((pt) => pt.relatedAssessment.assessmentName)
    },
    {
      id: 'resultPoints',
      Header: 'resultPoints',
      accessor: (row) => row.ptList.map((pt) => getNumericResult(pt)),
      canExport: false,
      show: false,
      alwaysHidden: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, contentType: CONTENT_TYPES.number }
    },
    {
      id: 'resultGrade',
      Header: 'resultGrade',
      accessor: (row) => row.ptList.map((pt) => getGradeResult(pt)),
      canExport: false,
      show: false,
      alwaysHidden: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt, contentType: CONTENT_TYPES.number }
    },
    {
      id: 'resultCrosstest',
      Header: 'resultCrosstest',
      accessor: (row) => row.ptList.map((pt) => getCrosstestResult(pt, locale)),
      canExport: false,
      show: false,
      alwaysHidden: true,
      filterOptions: { showInFieldSelector: usingCrosstests, category: FIELD_CATEGORIES.pt }
    },
    {
      Header: 'ptsAndResults',
      id: 'assessmentName',
      canExport: true,
      show: true,
      accessor: (row) => row.ptList.map((pt) => pt.relatedAssessment.assessmentName),
      Cell: ({ row }) => <PtListForTable ptList={row.original.ptList} />
    },
    {
      Header: 'totalScore',
      textAlign: 'right',
      id: 'processResult',
      canExport: true,
      show: true,
      filterOptions: {
        showInFieldSelector: true,
        category: FIELD_CATEGORIES.participant,
        contentType: CONTENT_TYPES.number
      },
      accessor: (row) => {
        const processResult = row.relatedProcessResults.map((processResult) => processResult.processResult)[0]
        return !processResult ? '' : processResult
      },
      Cell: ({ row }) => {
        return <ProcessResultListForTable participant={row.original} />
      }
    },
    {
      Header: 'testingStatus',
      canExport: true,
      id: 'ptStatus',
      filterOptions: {
        showInFieldSelector: true,
        dataLabels: getPtStatusLabelsForFilter(),
        category: FIELD_CATEGORIES.pt,
        labelOffset: 8
      },
      accessor: (row) => row.ptList.map((pt) => pt.ptStatus),
      alwaysHidden: true
    },
    getHubLinkCol(),
    getCreatedByCol('participantCreatedBy', FIELD_CATEGORIES.participant),
    getDateCreatedCol('participantCreated', FIELD_CATEGORIES.participant)
  ]

  const customCols = getCustomCols(customFieldDefinitions, true)
  const systemCols = [getVisibilityCol(FIELD_CATEGORIES.participant), getSpacerCol(), getActionsCol()]
  return [...defaultCols, ...customCols, ...systemCols]
}

export const SmallDescriptionContent = ({ pt }) => {
  const t = useTranslate()
  const invitedDuration = pt.ptInvitedDuration
  const timerStatus = pt.datePtInvited ? 'timerStarted' : 'timerNotStarted'

  const validFrom = pt.ptValidFrom ? createDate(pt.ptValidFrom) : null
  const validUntil = pt.ptValidUntil ? createDate(pt.ptValidUntil) : null

  switch (pt.ptStatus) {
    case PT_STATUS.expired:
      return (
        <>
          {t('expiredOn')} <DateDisplay date={validUntil} />
        </>
      )
    case PT_STATUS.waiting:
      return (
        <>
          {t('availableFrom')} <DateDisplay date={validFrom} />
        </>
      )
    case PT_STATUS.ready:
      if (invitedDuration > 0) {
        return `${t('ready')}, ${t(timerStatus)}`
      }
      return <>{t('ready')}</>
    case PT_STATUS.finished:
      return <PtFinishedDescription pt={pt} />
    case PT_STATUS.started:
      return `${t('started')}, ${pt.ptProgress} %`
    case PT_STATUS.deactivated:
      return (
        <>
          {t('deactivated')}
          {pt.hasResult() && (
            <>
              {', '}
              <PtFinishedDescription pt={pt} />
            </>
          )}
        </>
      )
    default:
      return t(PT_STATUS_NAMES[pt.ptStatus]) || ''
  }
}

const PtFinishedDescription = ({ pt }) => {
  const t = useTranslate()
  if (!pt.hasResult() || !pt.resultIsVisible()) return t('finished')

  return pt.hasCrosstestResult() ? <CrosstestResult result={pt.relatedResult} /> : <RegularResult pt={pt} />
}

const RegularResult = ({ pt }) => {
  const t = useTranslate()
  const { language } = useContext(AppContext)
  const result = pt.relatedResult
  const label = isTraitsOrGoals(pt.relatedConfig.configType) ? 'Matching' : t('result')
  const suffix = isTraitsOrGoals(pt.relatedConfig.configType) ? '%' : ''
  return (
    <span>
      {label}: {result.normResult}
      {suffix}, <GradeLabel label={result.gradeLabel} />: {getFormattedNumber(result.grade, language)}
    </span>
  )
}

const CrosstestResult = ({ result }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const crosstestResult = result?.crosstestResult[context.language] || result?.crosstestResult[DEFAULT_LANGUAGE]
  return (
    <span>
      {t('result')}: {capitalizeFirstLetter(crosstestResult?.peculiarityText)}
    </span>
  )
}
