import dict from '../../../utils/constants/translations'
import useTranslate from '../../../utils/hooks/useTranslate'
import MatchingNormHelp from '../../helpModal/MatchingNormHelp'
import WeightsHelp from '../../helpModal/WeightsHelp'
import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Table, WizardStep, WizardStepContainer } from '../userManualElements'

const Chapter3 = () => {
  const t = useTranslate()
  return (
    <>
      <h2 id="chapter-3">3 Haupttabellen</h2>
      <h3 id="chapter-3-1">3.1 Verfahren</h3>
      <div className="textbox">
        <p>Ein Verfahren...</p>
        <ul>
          <li>
            ist ein Ordner für zusammengehörige Tests. Sie können z.B. ein Verfahren je Stellenausschreibung oder
            Berufsgruppe erstellen.
          </li>
          <li>kann mehrere Tests und Testarten enthalten.</li>
          <li>kann nur von Administratoren erstellt werden.</li>
        </ul>
      </div>
      <h4 id="chapter-3-1-1">3.1.1 Einstellungen, Aktionen und Gesamtergebnis</h4>

      <h5>Einstellungen</h5>

      <p>
        Unter <em>Basis-Einstellungen</em> können Sie den Namen und die Beschreibung des Verfahrens sowie das
        Test-Design ändern. Das ausgewählte Test-Design wird für alle diesem Verfahren zugeordneten Tests genutzt.
      </p>
      <h5>Aktionen</h5>
      <ProcessActionsTable />
      <h5>Verrechnung zu Gesamtergebnis</h5>
      <p>
        Tests, die eine Note o.ä. als Ergebnis liefern, können zu einem Gesamtergebnis pro Teilnehmer verrechnet werden.
        Um die Verrechnung zu nutzen, wählen Sie <B>Tests verrechnen</B>, andernfalls <B>Tests nicht verrechnen</B>.
        Wenn die Verrechnung aktiviert ist, können Sie die Gewichtung der einzelnen Tests anpassen. Die Summe der
        Einzelgewichte muss dabei 100 % ergeben. Das Gesamtergebnis ist der gewichtete Mittelwert aller Testergebnisse
        des Teilnehmers.
      </p>
      <div className="textbox important">
        <p>Das Gesamtergebnis wird erst berechnet, wenn der Teilnehmer alle gewichteten Tests abgeschlossen hat.</p>
      </div>
      <p>
        Klicken Sie auf <B>Speichern</B>, um Ihre Einstellungen zu übernehmen. Die Gesamtergebnisse werden daraufhin
        aktualisiert.
      </p>
      <h4 id="chapter-3-1-2">3.1.2 Verfahren anlegen</h4>

      <p>
        Öffnen Sie den Prozess <em>Verfahren anlegen</em>. Nutzen Sie dazu eine der folgenden Möglichkeiten:
      </p>
      <ul>
        <li>
          In der linken Seitenleiste auf <B>Erstellen</B> klicken und anschließend auf <B>Neues Verfahren</B>
        </li>
        <li>
          In der Verfahrensübersicht auf <B>Neues Verfahren anlegen</B> klicken
        </li>
      </ul>
      <div className="textbox important">
        <p>
          Wenn alle Nutzer Administrator-Rechte besitzen, wird der Schritt <em>Berechtigungen festlegen</em>{' '}
          übersprungen.
        </p>
      </div>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Name und Design</WizardStep>
            <ul>
              <li>Geben Sie dem neuen Verfahren einen Namen.</li>
              <li>Wenn mehrere Test-Designs verfügbar sind, wählen Sie ein Test-Design aus.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Berechtigungen festlegen</WizardStep>
            <ul>
              <li>Legen Sie die Nutzer-Berechtigungen für das neue Verfahren fest.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Eingaben überprüfen</WizardStep>
            <ul>
              <li>Überprüfen Sie Ihre Eingaben.</li>
              <li>
                Klicken Sie auf <B>Verfahren anlegen</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-3-1-3">3.1.3 Zugriffsrechte für Verfahren ändern</h4>
      <div className="textbox important">
        <p>Wenn alle Nutzer Administrator-Rechte besitzen, steht diese Funktion nicht zur Verfügung.</p>
      </div>
      <p>
        So öffnen Sie den Prozess <em>Zugriffsrechte ändern</em>: Markieren Sie mindestens ein Verfahren und klicken in
        der Stapelverarbeitung auf <B>Zugriffsrechte ändern</B>.
      </p>

      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Zugriffsrechte für x Nutzer ändern</WizardStep>
            <ul>
              <li>
                Ändern Sie die Zugriffsrechte je Nutzer, indem Sie unter <em>Rechte</em> die gewünschte Option
                auswählen.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Eingaben prüfen</WizardStep>
            <ul>
              <li>Überprüfen Sie Ihre Eingaben.</li>
              <li>
                Klicken Sie auf <B>Rechte ändern</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h3 id="chapter-3-2">3.2 Tests</h3>
      <div className="textbox">
        <p>Ein Test...</p>
        <ul>
          <li>ist eine Zusammenstellung von Testmodulen, die gewichtet und normiert sind.</li>
          <li>ist immer einem Verfahren zugeordnet.</li>
          <li>kann ein Start- und ein End-Datum besitzen. </li>
          <li>kann mehrere zugeordnete Teilnehmer besitzen.</li>
        </ul>
      </div>
      <h4 id="chapter-3-2-1">3.2.1 Einstellungen und Aktionen</h4>
      <h5>Einstellungen</h5>
      <TestSettingsTable />

      <h5>Aktionen</h5>
      <TestActionsTable />

      <h4 id="chapter-3-2-2">3.2.2 Tests anlegen</h4>

      <p>
        Öffnen Sie den Prozess <em>Test anlegen</em>. Nutzen Sie hierzu eine der folgenden Möglichkeiten:
      </p>
      <ul>
        <li>
          In der linken Seitenleiste auf <B>Erstellen</B> und anschließend auf <B>Neuer Test</B> klicken
        </li>
        <li>
          In der Tabelle <em>Testungen</em> auf <B>Neuen Test anlegen</B> klicken
        </li>
        <li>
          In der Aktionsliste eines Verfahrens auf <B>Test hinzufügen</B> klicken
        </li>
      </ul>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Test wählen</WizardStep>
            <ul>
              <li>
                Wählen Sie den gewünschten Test aus der Liste aus und markieren diesen auf der linken Seite. Nutzen Sie
                die Filter oben, um den richtigen Test zu finden.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Variante</WizardStep>
            <ul>
              <li>Wählen Sie eine der verfügbaren Testvarianten aus.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Verfahren und Testname</WizardStep>
            <ul>
              <li>Wählen Sie ein Verfahren aus, in das der neue Test integriert werden soll.</li>
              <li>Geben Sie dem neuen Test einen Namen.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>4. Testzeitraum und Dauer</WizardStep>
            <ul>
              <li>
                Wenn Sie einen Startzeitpunkt festlegen möchten, klicken Sie auf <B>Startzeitpunkt festlegen...</B> und
                anschließend auf
                <B>Datum/Uhrzeit wählen</B>.
              </li>
              <li>
                Wenn Sie einen Endzeitpunkt festlegen möchten, klicken Sie auf <B>Endzeitpunkt festlegen...</B> und
                anschließend auf
                <B>Datum/Uhrzeit wählen</B>.
              </li>
              <li>
                Wenn Sie einen Timer einstellen möchten, klicken Sie auf <B>Timer einstellen...</B> und wählen
                anschließend den Zeitraum in Tagen.
              </li>
              <li>
                Wenn Sie die Zeit ab Teststart begrenzen möchten, klicken Sie auf <B>Zeit ab Teststart begrenzen...</B>{' '}
                und wählen anschließend die gewünschte Dauer in Stunden und Minuten.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>5. Weitere Optionen</WizardStep>
            <ul>
              <li>Wählen Sie die Test-Sprache aus.</li>
              <li>
                Geben Sie an, ob der Test über die Testübersicht gestartet werden kann{' '}
                <span className="textlink internal-link" data-scroll-to="chapter-2-2-1">
                  (siehe 2.2.1)
                </span>
                .
              </li>
              <li>
                Wenn Sie die zufällige Aufgabenwahl nutzen möchten, wählen Sie <B>aktiviert (empfohlen)</B>. Wenn Sie
                die Option <em>Zufällige Aufgabenwahl</em> aktivieren, bearbeiten die Kandidaten nicht dieselben, aber
                vergleichbare Aufgaben. Dies erschwert Täuschungsversuche. {dict.performanceTestsOnly.de}.
              </li>

              <li>Geben Sie an, ob der Test unter Aufsicht vor Ort durchgeführt wird. (nur Leistungstests)</li>
              <li>Geben Sie an, ob Sie Fenstwechsel während des Tests erfassen möchten. (nur Leistungstests)</li>
              <li>Geben Sie an, ob Sie die Kamera-Überwachung nutzen möchten. (nur Leistungstests)</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>6. Reportmodule auswählen</WizardStep>
            <ul>
              <li>Wenn Sie optionale Reportmodule nutzen möchten, wählen Sie diese hier aus.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>7. Eingaben prüfen</WizardStep>{' '}
            <ul>
              <li>Überprüfen Sie Ihre Eingaben.</li>
              <li>
                Klicken Sie auf <B>Test anlegen</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-3-2-3">3.2.3 Reportmodule</h4>
      <p>
        Zu jedem Test wird automatisch ein PDF-Report erstellt. Die Reports bestehen aus einzelnen Reportmodulen. Je
        nach Testart und Anwendungsfall können Sie verschiedene Reportmodule nutzen. Welche Module Sie nutzen möchten,
        legen Sie beim Anlegen des Tests fest.
      </p>
      <ReportModuleTable />
      <h4 id="chapter-3-2-4">3.2.4 {t('scoring')}</h4>
      <h5>{t('weighting')}</h5>
      <WeightsHelp />
      <h5>{t('jobMatching')}</h5>
      <MatchingNormHelp />
      <h3 id="chapter-3-3">3.3 Teilnehmer</h3>

      <div className="textbox">
        <p>Ein Teilnehmer...</p>
        <ul>
          <li>ist eine natürliche Person</li>
          <li>wird über die Teilnehmernummer eindeutig identifiziert (z.B. P-12345-67890)</li>
          <li>sollte nur ein Mal im System vorhanden sein</li>
          <li>hat eine oder mehrere zugeordnete Testungen</li>
        </ul>
      </div>
      <h4 id="chapter-3-3-1">3.3.1 Einstellungen und Aktionen</h4>
      <h5>Einstellungen</h5>
      <p>
        Unter <em>Einstellungen</em> {'>'} <em>Teilnehmer Infos</em> können Sie die personenbezo­genen Daten des
        Teilnehmers (Vorname, Nachname, Geschlecht, E-Mail-Adresse) ändern. Unter dem Punkt <em>Datenschutz</em> können
        Sie zwischen <em>Durchführung mit Namen</em> und <em>Anonyme Durchführung</em> wählen{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-2">
          (siehe 3.3.2)
        </span>
        .
      </p>
      <h5>Aktionen</h5>
      <ParticipantActionsTable />

      <h4 id="chapter-3-3-2">3.3.2 Anonyme Testdurchführung</h4>

      <p>
        Um optimalen Datenschutz zu gewährleisten, bietet PERSEO anonyme Testdurchführungen an. Bei anonymen Testungen
        werden die Teilnehmer allein über die Teilnehmernummer identifiziert. PERSEO erhält in diesem Fall keine
        personenbezogenen Daten der Teilnehmer. Anonyme Testungen laufen wie folgt ab:
      </p>
      <ul>
        <li>
          Sie legen im Kundenbereich die gewünschte Zahl anonymer Teilnehmer an. Jeder Teilnehmer hat eine eindeutige
          Teilnehmernummer.
        </li>
        <li>
          Die Zuordnung von Teilnehmernummern und Personen halten Sie selbständig nach, z.B. in Form einer Excel-Liste.
          Die Teilnehmernamen verbleiben bei Ihnen. PERSEO kann keine Zuordnung vornehmen.
        </li>
        <li>
          Nach Abschluss des Tests können Sie die Testergebnisse den jeweiligen Personen über die Teilnehmernummer
          zuordnen.
        </li>
      </ul>

      <h4 id="chapter-3-3-3">3.3.3 Teilnehmer anlegen</h4>

      <p>
        Öffnen Sie den Prozess <em>Teilnehmer anlegen</em>. Nutzen Sie hierzu eine folgenden Möglichkeiten:
      </p>
      <ul>
        <li>
          In der linken Seitenleiste auf <B>Erstellen</B> klicken und anschließend auf <B>Neue Teilnehmer</B>
        </li>
        <li>
          In der Teilnehmerübersicht auf <B>Neue Teilnehmer anlegen</B> klicken
        </li>
        <li>
          In der Aktionsliste eines Verfahrens auf <B>Teilnehmer hinzufügen</B> klicken
        </li>
        <li>
          In der Aktionsliste eines Tests auf <B>Teilnehmer hinzufügen</B> klicken
        </li>
      </ul>
      <div className="textbox important">
        <p>
          Wenn Sie den Prozess aus dem Aktionsmenü eines Tests starten, wird die Seite <em>Verfahren und Tests</em>{' '}
          übersprungen.
        </p>
      </div>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Verfahren und Tests</WizardStep>
            <ul>
              <li>Wählen Sie das gewünschte Verfahren aus.</li>
              <li>Wählen Sie einen oder mehrere Tests aus, denen Sie Teilnehmer hinzufügen möchten.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Datenquelle</WizardStep>{' '}
            <ul>
              <li>
                Wählen Sie aus, ob Sie blanko Teilnehmer anlegen (A) oder eine bestehende Teilnehmerliste hochladen
                möchten (B). Der nächste Prozessschritt wird entsprechend gewählt.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3A. Teilnehmerzahl und Datenschutz</WizardStep>{' '}
            <ul>
              <li>Bestimmen Sie, wie viele blanko Teilnehmer Sie hinzufügen möchten.</li>
              <li>Wählen Sie, ob Sie die Tests mit Namen oder anonym durchführen möchten.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3B. Teilnehmerliste</WizardStep>{' '}
            <ul>
              <li>
                Laden Sie Ihre Teilnehmerliste hoch, indem Sie die Liste entweder in den dafür vorgesehenen Bereich
                ziehen (drag n drop) oder auf den Bereich klicken und anschließend eine Datei auswählen. Sollte die
                Zuordnung der Spalten falsch sein, können Sie die Zuordnung manuell korrigieren.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>4. Eingaben prüfen</WizardStep>{' '}
            <ul>
              <li>Überprüfen Sie Ihre Eingaben.</li>
              <li>
                Wenn Sie Teilnehmer zu kostenpflichtigen Tests hinzufügen möchten und noch keine Rechnungsdaten im
                System gespeichert sind, müssen Sie zunächst Ihre Rechnungsdaten eingeben. Klicken Sie hierzu unter{' '}
                <em>Rechnungsdaten</em> auf <B>Hinzufügen</B> und füllen das Formular aus.
              </li>
              <li>
                Klicken Sie auf <B>Hinzufügen</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-3-3-4">3.3.4 Bestehende Teilnehmer weiteren Tests zuweisen</h4>

      <p>
        Öffnen Sie den Prozess <em>Weitere Tests zuweisen</em>. Nutzen Sie hierzu eine der folgenden Möglichkeiten:
      </p>
      <ul>
        <li>
          Wählen Sie einen Teilnehmer aus, öffnen das Aktionsmenü und klicken auf <B>Weitere Tests zuweisen</B>.
        </li>
        <li>
          Markieren Sie mindestens einen Teilnehmer und klicken in der Stapelverarbeitung auf{' '}
          <B>Weitere Tests zuweisen</B>.
        </li>
      </ul>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Verfahren und Tests</WizardStep>
            <ul>
              <li>Wählen Sie das gewünschte Verfahren aus.</li>
              <li>Wählen Sie einen oder mehrere Tests aus, denen Sie Teilnehmer hinzufügen möchten.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Eingaben prüfen</WizardStep>
            <ul>
              <li>Überprüfen Sie Ihre Eingaben.</li>
              <li>
                Wenn Sie Teilnehmer zu kostenpflichtigen Tests hinzufügen möchten und noch keine Rechnungsdaten im
                System gespeichert sind, müssen Sie zunächst Ihre Rechnungsdaten eingeben. Klicken Sie hierzu unter{' '}
                <em>Rechnungsdaten</em> auf <B>Hinzufügen</B> und füllen das Formular aus.
              </li>
              <li>
                Klicken Sie auf <B>Hinzufügen</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h3 id="chapter-3-4">3.4 Testungen</h3>

      <div className="textbox">
        <ul>
          <li>
            Jeder Teilnehmer kann einen oder mehrere Tests durch­führen. Die Teilnahme an einem Test nennen wir Testung.
            Eine Testung ist also die Kombination aus einem Test und einem Teilnehmer.
          </li>
          <li> Sobald Sie einen Teilnehmer zu einem Test hinzufügen, wird automatisch eine neue Testung erstellt.</li>
          <li> Eine Testung wird eindeutig über die TAN identifiziert (z.B. PAS-ABCDE-12345). </li>
        </ul>
      </div>

      <h4 id="chapter-3-4-1">3.4.1 Einstellungen und Aktionen</h4>

      <h5>Einstellungen</h5>
      <PtSettingsTable />
      <h5>Aktionen</h5>
      <PtActionsTable />
      <h4 id="chapter-3-4-2">3.4.2 Fensterwechsel</h4>

      <p>
        Hier wird aufgeführt, ob ein Teilnehmer während der Testdurchführung die{' '}
        <span style={{ hyphens: 'none' }}>Testoberfläche</span> verlassen hat. Ein Fenster-Wechsel-Ereignis wird
        erfasst, wenn
      </p>
      <ul>
        <li>das Fenster oder der Browser-Tab verlassen und später wieder betreten wird,</li>
        <li>zwischen Verlassen und Betreten mindestens 2 Sekunden liegen und</li>
        <li>eine Aufgabe mit Zeitbegrenzung läuft. </li>
      </ul>
      <p>
        Wird die Testumgebung bis zu 5 Mal verlassen, erscheint der Hinweis <em>wenig</em>, bei mehr als 5 Mal{' '}
        <em>häufig</em>. Wir empfehlen, Teilnehmer mit häufigen Fensterwechseln aus dem weiteren Auswahlprozess
        auszuschließen.
      </p>
    </>
  )
}

export default Chapter3

const ProcessActionsTable = () => {
  const header = ['Aktion', 'Beschreibung', 'Beschränkungen']
  const data = [
    [
      'CSV-Tabelle downloaden',
      'Lädt eine Tabelle mit Teilnehmerdaten und Ergebnissen herunter, die nach Teilnehmern aufgeschlüsselt ist. Jede Zeile entspricht einem Teilnehmer.'
    ],
    [
      'Verfahren duplizieren',
      'Erstellt eine Kopie des Verfahrens sowie aller zugehöriger Tests, aber ohne Teilnehmer und Testungen',
      'Administrator'
    ],
    [
      'Teilnehmer hinzufügen',
      <span key="ProcessActionsTable-addParticipant">
        Führt zur Seite <em>Teilnehmer anlegen</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-3">
          (siehe 3.3.3)
        </span>
      </span>,
      'Editor'
    ],
    [
      'Test hinzufügen',
      <span key="ProcessActionsTable-addTest">
        Führt zur Seite <em>Test anlegen</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-2-2">
          (siehe 3.2.2)
        </span>
      </span>,
      'Editor'
    ],
    ['Archivieren', 'Archiviert das Verfahren sowie alle zugehörigen Tests, Teilnehmer und Testungen', 'Administrator'],
    [
      'Dearchivieren',
      'Dearchiviert das Verfahren sowie alle zugehörigen Tests, Teilnehmer und Testungen',
      'Administrator'
    ],
    [
      'Verfahren löschen',
      'Löscht das Verfahren sowie alle zugehörigen Tests, Teilnehmer und Testungen',
      'Administrator'
    ]
  ]
  return <Table name="process-actions" {...{ header, data }} />
}

const TestSettingsTable = () => {
  const header = ['Einstellung', 'Beschreibung', 'Standardwert']
  const data = [
    ['Name', 'Der Name des Tests; sichtbar für die Kandidaten'],
    ['Kommentar', 'Weitere Infos zum Test; nur für Kunden sichtbar'],
    [
      dict.validFrom.de,
      <span key="TestSettingsTable-validFrom">
        Startzeitpunkt des Tests{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'sofort'
    ],
    [
      dict.validUntil.de,
      <span key="TestSettingsTable-validUntil">
        Endzeitpunkt des Tests{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'unbegrenzt'
    ],
    [
      dict.invitedDuration.de,
      <span key="TestSettingsTable-timer">
        Individuelle Begrenzung der Testzeit{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'nicht gesetzt'
    ],
    [
      dict.startedDuration.de,
      <span key="TestSettingsTable-startedDuration">
        Zeit, die ein Teilnehmer ab Start zur Verfügung hat{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'nicht gesetzt'
    ],
    [
      dict.useChildItems.de,
      <span key="TestSettingsTable-usechildItems">
        {dict.useChildItemsExplanation.de} {dict.performanceTestsOnly.de}{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-5-1">
          (siehe 2.5.1)
        </span>
      </span>,
      'aktiviert'
    ],
    [
      dict.tabTracking.de,
      <span key="TestSettingsTable-tabTracking">
        {dict.tabTrackingExplanation.de} {dict.performanceTestsOnly.de}{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-5-2">
          (siehe 2.5.2)
        </span>
      </span>,
      'aktiviert'
    ],
    [
      dict.cameraSupervision.de,
      <span key="TestSettingsTable-cameraSupervision">
        {dict.cameraSupervisionExplanation.de} {dict.performanceTestsOnly.de}{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-5-3">
          (siehe 2.5.3)
        </span>
      </span>,
      'deaktiviert'
    ],
    [dict.inHub.de, dict.hubExplanation.de, 'anzeigen'],
    [dict.isSupervised.de, 'Angabe dient nur statistischen Zwecken', '	ohne Aufsicht']
  ]
  return <Table name="test-settings" {...{ header, data }} />
}

const TestActionsTable = () => {
  const header = ['Aktion', 'Beschreibung', 'Beschränkungen']
  const data = [
    [
      'CSV-Tabelle downloaden',
      'Lädt eine Tabelle mit Teilnehmerdaten und Ergebnissen herunter. Jede Zeile entspricht einer Testung.'
    ],
    ['Reports downloaden', 'Lädt einen Zip-Ordner mit allen PDF-Reports herunter.'],
    [
      'Teilnehmer hinzufügen',
      <span key="TestActionsTable-addParticipant">
        Führt zur Seite Teilnehmer anlegen{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-3">
          (siehe 2.3.3)
        </span>
      </span>,
      'Editor'
    ],
    ['Archivieren', 'Archiviert den Test sowie alle zugehörigen Teilnehmer und Testungen.', 'Administrator'],
    [
      'Dearchivieren',
      'Dearchiviert den Test sowie alle zugehörigen Teilnehmer und Testungen. Sollten zugeordnete Verfahren archiviert sein, werden diese ebenfalls dearchiviert.',
      'Administrator'
    ],
    ['Test löschen', 'Löscht den Test sowie alle zugehörigen Teilnehmer und Testungen.', 'Administrator']
  ]
  return <Table name="test-actions" {...{ header, data }} />
}

const ParticipantActionsTable = () => {
  const header = ['Einstellungen', 'Beschreibung', 'Standardwert']
  const data = [
    [
      'Weitere Tests zuweisen',
      <span key="ParticipantActionsTable-addToTest">
        Fügt den Teilnehmer einem weiteren Test hinzu{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-5">
          (siehe 3.3.5)
        </span>
      </span>,
      'Editor'
    ],
    [
      'Timer starten / zurücksetzen',
      <span key="ParticipantActionsTable-startTimer">
        Startet den Timer oder setzt ihn zurück{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4-2">
          (siehe 2.4.2)
        </span>
      </span>,
      'Editor'
    ],
    [
      'E-Mail senden',
      <span key="ParticipantActionsTable-sendMail">
        Führt zur Seite <em>E-Mails senden</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-2-2">
          (siehe 2.2.2)
        </span>
      </span>,
      'Editor'
    ],
    ['Archivieren', 'Archiviert den Teilnehmer sowie alle zugehörigen Testungen.', 'Administrator'],
    [
      'Dearchivieren',
      'Dearchiviert den Teilnehmer sowie alle zugehörigen Testungen. Sollten zugeordnete Tests oder Verfahren archiviert sein, werden diese ebenfalls dearchiviert.',
      'Administrator'
    ],
    ['Teilnehmer löschen', 'Löscht den Teilnehmer sowie alle zugehörigen Testungen.', 'Administrator']
  ]
  return <Table name="participant-actions" {...{ header, data }} />
}

const PtSettingsTable = () => {
  const header = ['Aktion', 'Beschreibung', 'Standardwert']
  const data = [
    [
      dict.validFrom.de,
      <span key="PtSettingsTable-validFrom">
        Startzeitpunkt des Tests{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'sofort'
    ],
    [
      dict.validUntil.de,
      <span key="PtSettingsTable-validUntil">
        Endzeitpunkt des Tests{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'unbegrenzt'
    ],
    [
      dict.invitedDuration.de,
      <span key="PtSettingsTable-timer">
        Individuelle Begrenzung der Testzeit{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'nicht gesetzt'
    ],
    [
      dict.startedDuration.de,
      <span key="PtSettingsTable-startedDuration">
        Zeit, die ein Teilnehmer ab Start zur Verfügung hat{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (siehe 2.4)
        </span>
      </span>,
      'nicht gesetzt'
    ],
    [
      'Zeitverlängerung',
      'Hier können Sie eine prozentuale Zeitverlängerung als Nachteilsausgleich für schwerbehinderte Teilnehmer einstellen.',
      'keine'
    ],
    [
      'Einladungsstatus',
      'Hier können Sie nachhalten, ob Sie bereits eine Einladung oder Erinnerung für diese Testung verschickt haben. Wenn Sie eine E-Mail aus dem Kundenbereich verschickt haben, wird der Einladungsstatus automatisch aktualisiert. Sie können den Status allerdings auch manuell einstellen.',
      'nicht gesetzt'
    ]
  ]
  return <Table name="pt-settings" {...{ header, data }} />
}

const PtActionsTable = () => {
  const header = ['Aktion', 'Beschreibung', 'Beschränkungen']
  const data = [
    [
      'Timer starten / zurücksetzen',
      <span key="PtActionsTable-startTimer">
        Startet den Timer oder setzt ihn zurück{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4-2">
          (siehe 2.4.2)
        </span>
      </span>,
      'Editor'
    ],
    [
      'E-Mail senden',
      <span key="PtActionsTable-sendMails">
        Führt zur Seite <em>E-Mails senden</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-2-2">
          (siehe 2.2.2)
        </span>
      </span>,
      'Editor'
    ],
    [
      'Deaktivieren / Aktivieren',
      'Deaktiviert / aktiviert die Testung. Deaktivierte Testungen können nicht mehr durchgeführt werden.',
      'Editor'
    ],
    ['Archivieren', 'Archiviert die Testung', 'Administrator'],
    ['Dearchivieren', 'Dearchiviert die Testung und ggf. übergeordnete Elemente', 'Administrator'],
    ['Testung löschen', 'Löscht die Testung', 'Administrator']
  ]
  return <Table name="pt-settings" {...{ header, data }} />
}

const ReportModuleTable = () => {
  const header = ['Modul', 'Test-Typen', 'Beschreibung']
  const data = [
    [
      'Ergebnisübersicht',
      'Potentials, Traits, Goals',
      'Liefert ein aussagekräftiges Profil mit allen Messergebnissen. In jedem Test standardmäßig enthalten.'
    ],
    [
      'Teilnehmer-Feedback',
      'Potentials, Traits',
      'Ergebnis-Rückmeldung, die Sie an Ihre Teilnehmer schicken können. Enthält Begleittext zur Interpretation und keine Details zu Auswahlkriterien.'
    ],
    ['Traits, Goals', 'Ergebnisbeschreibung', 'Liefert kurze Erklärungen zu den einzelnen Messergebnissen.'],
    [
      'Job-Matching',
      'Traits, Goals',
      'Vergleichen Sie Ihre Kandidaten anhand der Übereinstimmung mit Ihrem Anforderungsprofil.'
    ],
    [
      'Potenziale und Risiken',
      'Traits',
      'Gibt detaillierte Hinweise auf zu erwartendes Verhalten im beruflichen Alltag.'
    ],
    [
      'Interviewleitfaden',
      'Traits',
      'Liefert passende Interviewfragen, mit denen Sie den Testergebnissen auf den Grund gehen.'
    ]
  ]
  return <Table name="pt-settings" {...{ header, data }} />
}
