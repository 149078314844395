import React, { useContext, useMemo } from 'react'
import DataTable from '../../components/table/DataTable'
import { ContentContainer } from '../../components/table/tableElements'
import { ENTITIES, TEST_TYPE_POTENTIALS_CROSS } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { getPtTableCols } from './ptUtils'
import PtRowSubComponent from './PtRowSubComponent'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import useTranslate from '../../utils/hooks/useTranslate'

const Pts = () => {
  const { completeDataSet, language } = useContext(AppContext)
  const t = useTranslate()
  const customFieldDefinitions = completeDataSet.customFieldDefinitions
  const customTableSettings = completeDataSet.customTableSettings.pts
  const usingCrosstests = completeDataSet.assessments.some(
    (ass) => ass.relatedConfig.configType === TEST_TYPE_POTENTIALS_CROSS
  )

  const columns = useMemo(
    () => getPtTableCols(customFieldDefinitions, usingCrosstests, language, t),
    [customFieldDefinitions, customTableSettings, usingCrosstests, language]
  )
  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <DataTable
          data={completeDataSet.pts}
          columns={columns}
          RowSubComponent={PtRowSubComponent}
          showRowSelection
          showFilters
          tableName={ENTITIES.pts}
          hasSubrows
          selectedRows={{}}
          showColumnController
        />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Pts
