import Header from '../header/Header'
import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import StatusBar from '../statusBar/StatusBar'
import { AppContext } from '../../utils/context/AppContext'
import { UPDATE_ALL_DATA_SECONDS } from '../../utils/constants/constants'
import { handleSettingsNavBarVisibility, fetchUpdates } from '../../utils/helper/Helper'
import StartupNotification from '../startupNotification/StartupNotification'
import Routes from '../routes/Routes'
import LoadingIndicator from '../loadingIndicator/LoadingIndicator'
import Navbar from '../navbar/Navbar'
import SettingsNavBar from '../navbar/SettingsNavbar'
import useTranslate from '../../utils/hooks/useTranslate'
import { checkMaintenanceMode } from '../../utils/hooks/useMaintenanceMode'
import { addRemainingData, loadInitialData } from '../../utils/helper/loginUtils'

const CustomerArea = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const { loadingMessage, setLoadingMessage, setShowArchivedItems, setArchiveLoaded, updatesArePaused } = context

  const [showSettingsNavBar, setShowSettingsNavBar] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [progress, setProgress] = useState(0)

  const location = useLocation()

  if (!isLoading) {
    console.log(context.completeDataSet)
  }

  useEffect(() => {
    loadInitialData(context, setIsLoading).then((data) => {
      addRemainingData(data, context, setIsLoading, setProgress, setLoadingMessage)
    })
    setShowArchivedItems(false)
    setArchiveLoaded(false)
  }, [])

  useEffect(() => {
    if (!isLoading && !updatesArePaused) {
      const timeOutId = setInterval(() => {
        checkMaintenanceMode(context)
        fetchUpdates(context)
      }, UPDATE_ALL_DATA_SECONDS * 1_000)
      return () => {
        clearInterval(timeOutId)
      }
    }
  }, [isLoading, updatesArePaused])

  useEffect(() => {
    handleSettingsNavBarVisibility(location, setShowSettingsNavBar)
  }, [location])

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <StatusBar />
      <Navbar {...{ isLoading }} />

      <div className="header-content-container">
        <Header isLoadingData={isLoading} />
        <main className={showSettingsNavBar ? 'settings-nav-visible' : ''}>
          {showSettingsNavBar && <SettingsNavBar />}
          {isLoading ? <LoadingIndicator progress={progress} loadingMessage={t(loadingMessage)} /> : <Routes />}
        </main>
      </div>
      {!isLoading && <StartupNotification />}
    </div>
  )
}

export default CustomerArea
