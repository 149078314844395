import { useCallback, useEffect, useState } from 'react'

const useFilterOverflowDetection = (buttonRef, dependency) => {
  const [hasOverflow, setHasOverflow] = useState(false)

  function debounce(fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    }
  }

  const debouncedCalculateDistances = useCallback(
    debounce(() => {
      const NEW_FILTER_WIDTH = 200
      const container = document.querySelector('#filter-actions-container')

      if (container && buttonRef.current) {
        const containerRect = container.getBoundingClientRect()
        const buttonRect = buttonRef.current.getBoundingClientRect()

        const rightDistance = containerRect.right - buttonRect.right
        const hasOverflow = rightDistance < NEW_FILTER_WIDTH

        setHasOverflow(hasOverflow)
      }
    }, 250),
    []
  )

  useEffect(() => {
    debouncedCalculateDistances()
    window.addEventListener('resize', debouncedCalculateDistances)

    return () => {
      window.removeEventListener('resize', debouncedCalculateDistances)
    }
  }, [debouncedCalculateDistances, dependency])

  return hasOverflow
}

export default useFilterOverflowDetection
