import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { mailIsValid } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getUploadHeaders } from '../uploadUtils'

const UploadPreviewTable = ({ data, headerMap }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const uploadHeaders = getUploadHeaders(context.completeDataSet.customFieldDefinitions)
  return (
    <div style={{ overflow: 'auto' }}>
      <div className="table-outer-container upload-preview">
        <div className="table-inner-container">
          <table>
            <thead>
              <tr>
                {uploadHeaders.map((header) => (
                  <th className={header.androName} key={header.androName}>
                    {t(header.label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.parsedCsvData &&
                data.parsedCsvData.map((row, index) => (
                  <tr key={index}>
                    {uploadHeaders.map((header, i) => {
                      const contentIsValid = checkIfContentValid(row, headerMap, header)
                      return (
                        <td
                          className={contentIsValid ? 'valid' : 'invalid'}
                          style={{ overflow: 'scroll' }}
                          key={row + i}
                        >
                          {getContent(row, headerMap, header)}
                        </td>
                      )
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default UploadPreviewTable

const getContent = (row, headerMap, header) => row[headerMap?.[header.androName]]

const checkIfContentValid = (row, headerMap, header) => {
  const content = getContent(row, headerMap, header)
  if (header.androName === 'email') {
    return mailIsValid(content)
  }
  return true
}
