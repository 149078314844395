import { API_DATA, FIELD_LABELS, MAX_CHARS } from '../../utils/constants/constants'
import { getOptionByValue, isPotentialsCrossTest } from '../../utils/helper/Helper'
import useReferenceTestSelectOptions from '../../utils/hooks/useReferenceTestSelectOptions'
import CustomInput from '../customInput/CustomInput'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'

const AssessmentBaseSettingsTile = ({ assessment, canEdit }) => {
  const referenceTestOptions = useReferenceTestSelectOptions(assessment.relatedProcess.processUuid)
  return (
    <Tile width="190px">
      <TileHeadline content="baseSettings" />
      <CustomInputList>
        <CustomInput
          label={FIELD_LABELS.elementName}
          content={assessment.assessmentName}
          dataKey="assessmentName"
          type="input"
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          maxChars={MAX_CHARS.testName}
          cannotBeEmpty
        />

        <CustomInput
          label={FIELD_LABELS.description}
          content={assessment.assessmentDescription}
          dataKey="assessmentDescription"
          type="textarea"
          idValue={assessment.assessmentUuid}
          apiData={API_DATA.assessments}
          disabled={!canEdit}
          maxChars={MAX_CHARS.testDescription}
        />
        {isPotentialsCrossTest(assessment.relatedConfig.configType) && (
          <CustomInput
            label={FIELD_LABELS.referenceAssessment}
            content={getOptionByValue(referenceTestOptions, assessment.referenceAssessmentUuid).label || ''}
            value={assessment.referenceAssessmentUuid}
            dataKey="referenceAssessmentUuid"
            type="select"
            options={referenceTestOptions}
            idValue={assessment.assessmentUuid}
            apiData={API_DATA.assessments}
            disabled
          />
        )}
      </CustomInputList>
    </Tile>
  )
}

export default AssessmentBaseSettingsTile
