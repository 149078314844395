import { useContext, useEffect, useState, useMemo } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { Emphasized } from '../../../utils/elements/miscElements'
import { createDate } from '../../../utils/helper/dateTimeHelper'
import { displayNotification, saveNotificationAsRead } from '../../startupNotification/startupNotificationUtils'
import styled from 'styled-components'
import DataTable from '../../table/DataTable'
import { translateNotifications } from '../../../utils/helper/Helper'
import { handleWelcomeNotification } from './notificationSettingsUtils'
import useTranslate from '../../../utils/hooks/useTranslate'
import DateDisplay from '../../dateDisplay/DateDisplay'
import { getSpacerCol } from '../../table/tableCols/tableCols'
import useRedirect from '../../../utils/hooks/useRedirect'

const StartupNotificationList = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const columns = useMemo(() => getCols(t), [])
  const rowData = context.completeDataSet.notifications
  const filteredNotifications = handleWelcomeNotification(rowData)

  translateNotifications(filteredNotifications, context.language)

  return (
    <div className="startup-notifications">
      <p>{t('startupNotificationsExplanation')}</p>
      <DataTable
        data={filteredNotifications}
        columns={columns}
        tableName="startup-notifications"
        hideArchiveButton
        RowSubComponent={{}}
        hideResultCount
      />
    </div>
  )
}

export default StartupNotificationList

const getCols = (t) => [
  {
    Header: 'title',
    accessor: 'localizedTitle',
    Cell: ({ value }) => (
      <div style={{ minWidth: '280px', whiteSpace: 'normal', lineHeight: 'var(--lh-2)' }}>{value}</div>
    ),
    disableSortBy: true
  },
  {
    Header: 'date',
    accessor: 'dateCreated',
    Cell: ({ row }) => {
      const date = row.original.isWelcomeNotification
        ? createDate(row.original.addedDate)
        : createDate(row.original.created)
      return <DateDisplay date={date} />
    },
    disableSortBy: true
  },
  {
    Header: 'Status',
    accessor: 'seen',
    Cell: ({ value }) => <span>{value === true ? t('read') : <Emphasized>{t('unread')}</Emphasized>}</span>,
    disableSortBy: true
  },
  getSpacerCol(),
  {
    Header: 'actions',
    accessor: 'open',
    Cell: ({ row }) => <OpenNotificationButton notification={row.original} />,
    disableSortBy: true
  }
]

const OpenNotificationButton = ({ notification }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const redirect = useRedirect()
  const [activePageId, setActivePageId] = useState(0)
  const [notificationIsOpen, setNotificationIsOpen] = useState(false)

  const props = {
    notification: notification,
    context: context,
    activePageId: activePageId,
    setActivePageId: setActivePageId,
    redirect: redirect
  }

  useEffect(() => {
    if (notificationIsOpen) {
      displayNotification(props)
    }
  }, [props.activePageId])

  return (
    <OpenButton
      onClick={() => {
        setActivePageId(0)
        displayNotification(props)
        setNotificationIsOpen(true)
        if (!notification.seen) {
          saveNotificationAsRead(notification, context)
        }
      }}>
      {t('open')}
    </OpenButton>
  )
}

const OpenButton = styled.button`
  background-color: var(--small-button-bg-color);
  border-radius: var(--bdr-2);
  padding: var(--space-2) var(--space-3);
  font-weight: var(--fw-2);
  font-size: var(--fs-2);
  &:hover {
    background-color: var(--small-button-hover-color);
  }
`
