import { BULK_EDITABLE_FIELDS, FIELD_LABELS, INVITATION_STATUS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { Label, selectStyles } from '../../utils/elements/miscElements'
import { getCustomFieldOptions, getOptionByValue, setState } from '../../utils/helper/Helper'
import Calendar from '../calendar/Calendar'
import TimePicker from '../timePicker/TimePicker'
import ToggleButton from '../toggleButton/ToggleButton'
import { EditableField } from './BulkEditStyles'
import Select from 'react-select'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDesignOptions } from '../../utils/helper/designUtils'

const EditFields = ({
  bulkData,
  setterFunctions,
  rowData,
  context,
  fromAndUntilDatesValid,
  setStartedDurationValid,
  editableFields
}) => {
  if (rowData.length === 0) return <></>
  const allEditableFields = []

  if (editableFields.includes(BULK_EDITABLE_FIELDS.elektryonTemplates)) {
    allEditableFields.push(
      <BulkEditElektryonTemplate key="BulkEditElektryonTemplate" {...{ bulkData, setterFunctions, context }} />
    )
  }

  if (editableFields.includes(BULK_EDITABLE_FIELDS.validFrom)) {
    allEditableFields.push(
      <BulkEditValidFrom key="BulkEditValidFrom" {...{ bulkData, setterFunctions, fromAndUntilDatesValid }} />
    )
  }

  if (editableFields.includes(BULK_EDITABLE_FIELDS.validUntil)) {
    allEditableFields.push(
      <BulkEditValidUntil key="BulkEditValidUntil" {...{ bulkData, setterFunctions, fromAndUntilDatesValid }} />
    )
  }

  if (editableFields.includes(BULK_EDITABLE_FIELDS.invitedDuration)) {
    allEditableFields.push(
      <BulkdEditInvitedDuration key="BulkdEditInvitedDuration" {...{ bulkData, setterFunctions }} />
    )
  }

  if (editableFields.includes(BULK_EDITABLE_FIELDS.startedDuration)) {
    allEditableFields.push(
      <BulkdEditStartedDuration
        key="BulkdEditStartedDuration"
        {...{ bulkData, setterFunctions, rowData, setStartedDurationValid }}
      />
    )
  }

  if (editableFields.includes(BULK_EDITABLE_FIELDS.invitationStatus)) {
    allEditableFields.push(
      <BulkEditInvitationStatus key="BulkEditInvitationStatus" {...{ bulkData, setterFunctions }} />
    )
  }
  const customFieldDefinitions = context.completeDataSet.customFieldDefinitions
  const customFieldKeys = customFieldDefinitions.map((cfd) => cfd.publicKey)

  editableFields.forEach((ef) => {
    if (customFieldKeys.includes(ef)) {
      const customFieldPublicKey = ef
      const customFieldDefinition = customFieldDefinitions.find((cfd) => cfd.publicKey === customFieldPublicKey)
      allEditableFields.push(
        <BulkEditCustomField
          key={`BulkEditCustomField_${customFieldPublicKey}`}
          {...{ bulkData, setterFunctions, customFieldDefinition }}
        />
      )
    }
  })

  return allEditableFields
}

export default EditFields

const BulkEditElektryonTemplate = ({ bulkData, setterFunctions, context }) => {
  const designOptions = context.completeDataSet.designOptions
  const options = getDesignOptions(designOptions)
  const t = useTranslate()

  return (
    <EditableField key="elektryonTemplates">
      <Label>{t(FIELD_LABELS.testDesign)}</Label>
      <ToggleButton
        options={t(SELECT_OPTIONS.bulkEdit)}
        data={bulkData.elektryonTemplate}
        setData={setterFunctions.setElektryonTemplate}
        dataKey="visible"
      />
      <div className={bulkData.elektryonTemplate?.visible ? 'slide-down' : 'slide-up'}>
        <Select
          options={options}
          onChange={(selectedOption) => setState(setterFunctions.setElektryonTemplate, 'value', selectedOption.value)}
          isDisabled={designOptions.isDisabled}
          styles={selectStyles}
          isSearchable={false}
          value={getOptionByValue(options, bulkData.elektryonTemplate.value)}
          menuPortalTarget={document.body}
        />
      </div>
    </EditableField>
  )
}

const BulkEditValidFrom = ({ bulkData, setterFunctions, fromAndUntilDatesValid }) => {
  const t = useTranslate()

  return (
    <EditableField key="validFrom">
      <Label>{t(FIELD_LABELS.validFrom)}</Label>
      <ToggleButton
        options={t(SELECT_OPTIONS.bulkEdit)}
        data={bulkData.validFrom}
        setData={setterFunctions.setValidFrom}
        dataKey="visible"
      />
      <div className={bulkData.validFrom.visible ? 'slide-down' : 'slide-up'}>
        <Calendar
          controls="value"
          type="text"
          setData={setterFunctions.setValidFrom}
          value={bulkData.validFrom.value}
          fieldName="validFrom"
          hasError={!fromAndUntilDatesValid}
          hideErrorMessage
        />
      </div>
    </EditableField>
  )
}

const BulkEditValidUntil = ({ bulkData, setterFunctions, fromAndUntilDatesValid }) => {
  const t = useTranslate()

  return (
    <EditableField key="validUntil">
      <Label>{t(FIELD_LABELS.validUntil)}</Label>
      <ToggleButton
        options={t(SELECT_OPTIONS.bulkEdit)}
        data={bulkData.validUntil}
        setData={setterFunctions.setValidUntil}
        dataKey="visible"
      />
      <div className={bulkData.validUntil.visible ? 'slide-down' : 'slide-up'}>
        <Calendar
          controls="value"
          type="text"
          setData={setterFunctions.setValidUntil}
          value={bulkData.validUntil.value}
          fieldName="validUntil"
          hasError={!fromAndUntilDatesValid}
          hideErrorMessage
        />
      </div>
    </EditableField>
  )
}

const BulkdEditInvitedDuration = ({ bulkData, setterFunctions }) => {
  const t = useTranslate()

  return (
    <EditableField key="invitedDuration">
      <Label>{t(FIELD_LABELS.invitedDuration)}</Label>
      <ToggleButton
        options={t(SELECT_OPTIONS.bulkEdit)}
        data={bulkData.invitedDuration}
        setData={setterFunctions.setInvitedDuration}
        dataKey="visible"
      />
      <div style={{ zIndex: 10 }} className={bulkData.invitedDuration.visible ? 'slide-down' : 'slide-up'}>
        <TimePicker
          seconds={bulkData.invitedDuration.value}
          setData={setterFunctions.setInvitedDuration}
          controls="value"
          flexDirection="column"
          field="invitedDuration"
          useMenuPortal
        />
      </div>
    </EditableField>
  )
}

const BulkdEditStartedDuration = ({ bulkData, setterFunctions, rowData, setStartedDurationValid }) => {
  const configDurations = rowData.map((row) => row.relatedConfig.configDuration)
  const maxConfigDuration = Math.max(...configDurations)
  const t = useTranslate()

  return (
    <EditableField key="startedDuration">
      <Label>{t(FIELD_LABELS.startedDuration)}</Label>
      <ToggleButton
        options={t(SELECT_OPTIONS.bulkEdit)}
        data={bulkData.startedDuration}
        setData={setterFunctions.setStartedDuration}
        dataKey="visible"
      />
      <div className={bulkData.startedDuration.visible ? 'large slide-down' : 'slide-up'}>
        <TimePicker
          seconds={bulkData.startedDuration.value}
          setData={setterFunctions.setStartedDuration}
          controls="value"
          flexDirection="column"
          field="startedDuration"
          useMenuPortal
          minDuration={maxConfigDuration}
          setIsValid={setStartedDurationValid}
        />
      </div>
    </EditableField>
  )
}

const BulkEditInvitationStatus = ({ bulkData, setterFunctions }) => {
  const t = useTranslate()

  const options = t([
    INVITATION_STATUS.notSet,
    INVITATION_STATUS.notInvited,
    INVITATION_STATUS.invited,
    INVITATION_STATUS.reminded
  ])
  return (
    <EditableField key="invitationStatus">
      <Label>{t(FIELD_LABELS.invitationStatus)}</Label>
      <ToggleButton
        options={t(SELECT_OPTIONS.bulkEdit)}
        data={bulkData.invitationStatus}
        setData={setterFunctions.setInvitationStatus}
        dataKey="visible"
      />
      <div className={bulkData.invitationStatus.visible ? 'large slide-down' : 'slide-up'}>
        <Select
          options={options}
          onChange={(selectedOption) => setState(setterFunctions.setInvitationStatus, 'value', selectedOption.value)}
          value={getOptionByValue(options, bulkData.invitationStatus.value)}
          styles={selectStyles}
          isSearchable={false}
          menuPortalTarget={document.body}
        />
      </div>
    </EditableField>
  )
}

const BulkEditCustomField = ({ bulkData, setterFunctions, customFieldDefinition }) => {
  const t = useTranslate()
  const cfdIndex = bulkData.customFields.findIndex((cfd) => cfd.publicKey === customFieldDefinition.publicKey)
  const customField = bulkData.customFields[cfdIndex]
  const options = getCustomFieldOptions(customFieldDefinition.options)

  const handleInputChange = (value) => {
    bulkData.customFields[cfdIndex].value = value
    setterFunctions.setCustomFields([...bulkData.customFields])
  }

  return (
    <EditableField key={customFieldDefinition.slug}>
      <Label>{customFieldDefinition.label}</Label>
      <ToggleButton
        options={t(SELECT_OPTIONS.bulkEdit)}
        data={bulkData.customFields}
        setData={setterFunctions.setCustomFields}
        dataKey="visible"
        customFieldDefinition={customFieldDefinition}
      />
      <div className={customField?.visible ? 'slide-down' : 'slide-up'}>
        {customFieldDefinition.options ? (
          <Select
            options={options}
            onChange={(selectedOption) => handleInputChange(selectedOption.value)}
            value={getOptionByValue(options, customField.value)}
            styles={selectStyles}
            isSearchable={false}
            menuPortalTarget={document.body}
          />
        ) : (
          <input
            type="text"
            style={{ width: '100%' }}
            value={customField.value || ''}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        )}
      </div>
    </EditableField>
  )
}
