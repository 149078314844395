import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'
import { B } from '../userManual/userManualElements'

const WeightsHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <WeightsHelpEnglish />
  } else {
    return <WeightsHelpGerman />
  }
}

export default WeightsHelp

const WeightsHelpGerman = () => {
  const t = useTranslate()
  return (
    <div>
      <p>
        Die in den verschiedenen Testbereichen (Dimensionen) erzielten Punktwerte werden zu einem gewichteten Mittelwert
        (Gesamtpunktwert oder Match-Score) zusammengefasst. Die Gewichtung der Dimensionen können Sie in der
        Test-Detailansicht unter{' '}
        <em>
          {t('scoring')} {'>'} {t('weighting')}
        </em>{' '}
        einsehen und verändern.
      </p>
      <p>Um die Gewichtung anzupassen, gehen Sie wie folgt vor: </p>
      <ul>
        <li>
          Klicken Sie auf <B>Gewichtung bearbeiten</B> unter der Gewichtungstabelle, um den Bearbeitungsmodus zu
          aktivieren.
        </li>
        <li>
          Tragen Sie die Gewichtungen als ganze Zahlen in die Eingabefelder ein. Die Gewichte müssen sich zu 100
          summieren.
        </li>
        <li>Um Ihre Eingaben zu bestätigen, klicken Sie auf die Schaltfläche mit dem Haken-Symbol.</li>
        <li>
          Das geänderte Gewichtungsschema wird unter <em>{t('customScheme')}</em> gespeichert und dem Test automatisch
          zugewiesen.
        </li>
      </ul>{' '}
      <div className="textbox important">
        <p>Bitte beachten Sie:</p>{' '}
        <ul>
          <li>
            Bei Änderung oder Neuzuordnung eines Gewichtungsschemas werden zugeordnete Testergebnisse neu berechnet.
          </li>
          <li>
            Pro Test kann nur 1 eigene Gewichtung gespeichert werden. Nochmaliges Speichern überschreibt die bisherige
            Gewichtung.
          </li>
          <li>Wenn Sie einen Test duplizieren, wird die eigene Gewichtung ebenfalls dupliziert.</li>
        </ul>
      </div>
      <p>
        Wenn Sie die Gewichtung ändern, werden die Rohdaten der Teilnehmer nicht verändert. Sie können die Gewichtung
        daher gefahrlos ändern und jederzeit zur voreingestellten Gewichtung zurückkehren.
      </p>
    </div>
  )
}

const WeightsHelpEnglish = () => {
  const t = useTranslate()
  return (
    <div>
      <p>
        The scores achieved in the different test areas (dimensions) are combined into a weighted average (total score
        or match score). You can view and change the weighting of the dimensions in the test detail view under{' '}
        <em>
          {t('scoring')} {'>'} {t('weighting')}
        </em>
        .
      </p>
      <p>To adjust the weighting, follow these steps: </p>
      <ul>
        <li>
          Click on <B>Edit Weights</B> below the weighting table to activate the edit mode.
        </li>
        <li>Enter the weights as whole numbers in the input fields. The weights must add up to 100.</li>
        <li>To confirm your entries, click on the button with the checkmark icon.</li>
        <li>
          The modified weighting scheme will be saved under <em>{t('customScheme')}</em> and automatically assigned to
          the test.
        </li>
      </ul>
      <div className="textbox important">
        <p>Please note:</p>
        <ul>
          <li>If a weighting scheme is changed or reassigned, the assigned test results will be recalculated.</li>
          <li>Only one custom scheme can be saved per test. Saving again will overwrite the previous scheme.</li>
          <li>If you duplicate a test, the custom scheme will also be duplicated.</li>
        </ul>
      </div>
      <p>
        Changing the weighting scheme will not affect the raw data of the participants. Therefore, you can safely modify
        the scheme and revert to the default scheme at any time.
      </p>
    </div>
  )
}
