import styled from 'styled-components'
import VERSION from '../../utils/constants/version'
import Config from '../../utils/constants/config'

const VersionDisplay = () => (
  <VersionNumberContainer>
    v {VERSION} {Config.publicLabel}
  </VersionNumberContainer>
)

export default VersionDisplay

export const VersionNumberContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: 10px;
  font-size: var(--fs-0);
  color: var(--border-grey);
`
