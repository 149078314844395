import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import DataTable from '../../../components/table/DataTable'
import { capabilitiesColumn, getContactCols } from '../../../components/table/tableElements'
import { PageHeadline } from '../../../utils/elements/miscElements'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getSpacerCol } from '../../../components/table/tableCols/tableCols'

const SetCapabilitiesForProcess = ({ data, setData }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const userList = context.completeDataSet.contacts.filter((contact) => contact.isVisible())
  const tableData = prepareTableData(userList, data, setData)
  const columns = getContactCols(t)
  columns.push(capabilitiesColumn, getSpacerCol())

  return (
    <>
      <PageHeadline>{t('setPermissions')}</PageHeadline>
      <ScrollContainer>
        <p style={{ marginTop: 0 }}>{t('defineAccessAndEditing')}</p>
        <DataTable
          data={tableData}
          columns={columns}
          selectedRows={{}}
          showRowSelection={false}
          hideResultCount
          hideArchiveButton
        />
      </ScrollContainer>
    </>
  )
}

export default SetCapabilitiesForProcess

const prepareTableData = (rowData, data, setData) => {
  rowData.forEach((row) => {
    const newCapabilitiesForRow = data.contactCapabilities.find(
      (entry) => entry.contactUuid === row.contactUuid
    ).capabilities

    row.newCapabilities = newCapabilitiesForRow
    row.setData = setData
    row.stateData = data
    row.entity = 'contactCapabilities'
    row.key = 'contactUuid'
    row.selectDisabled = row.contactIsAdmin
  })

  return rowData
}
