import { SmallDescription } from '../table/tableElements'
import styled from 'styled-components'
import { getFormattedNumber, getUniqueProcessesFromPtList } from '../../utils/helper/Helper'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const ProcessResultListForTable = ({ participant }) => {
  const processResults = participant.relatedProcessResults
  const relatedProcesses = getUniqueProcessesFromPtList(participant.ptList)
  const showProcessName = relatedProcesses.length > 1
  const { language, completeDataSet } = useContext(AppContext)

  return (
    <Container>
      {processResults.map((processResult) => {
        const process = completeDataSet.processes.find((process) => process.processUuid === processResult.processUuid)
        if (!process.useProcessResult) {
          return <></>
        }
        return (
          <div key={processResult.resultNr} style={{ lineHeight: 'var(--lh-2)' }}>
            <ProcessResultContainer>{getFormattedNumber(processResult.processResult, language)}</ProcessResultContainer>
            {showProcessName && (
              <SmallDescription style={{ whiteSpace: 'unset' }}>{processResult.processName}</SmallDescription>
            )}
          </div>
        )
      })}
    </Container>
  )
}

export default ProcessResultListForTable

const ProcessResultContainer = styled.div`
  font-size: var(--fs-4);
  line-height: var(--lh-2);
`
const Container = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: var(--space-3);
  text-align: right;
`
