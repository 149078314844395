import PageGroup from '../../components/pageGroup/PageGroup'
import UserCapabilitiesSelectionPage from './subPages/UserCapabilitiesSelectionPage'
import AddUserCheckInputPage from './subPages/AddUserCheckInputPage'
import UserDataInputForm from './subPages/UserDataInputForm'
import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { mailIsValid } from '../../utils/helper/Helper'
import { AppContext } from '../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { addUser, getInitialCapabilities, getPageArray, setCapabilitiesToEdit, validateInputForm } from './addUserUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDesignOptions } from '../../utils/helper/designUtils'
import useRedirect from '../../utils/hooks/useRedirect'
import { SettingsContainer } from '../../components/settings/sharedElements/sharedElements'

const AddUserWizard = () => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const history = useHistory()
  const redirect = useRedirect()

  const completeDataSet = context.completeDataSet
  const designOptions = getDesignOptions(completeDataSet.designOptions)

  const optionDescriptions = {
    contactEmail: t('invitationAddress'),
    contactName: t('userFieldCompletion'),
    contactIsAdmin: t('adminAccessExplanation')
  }

  const [mailIsDuplicate, setMailIsDuplicate] = useState(false)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [activePageId, setActivePageId] = useState(0)
  const [data, setData] = useState({
    contactEmail: '',
    contactFirstName: '',
    contactGender: '',
    contactTitle: '',
    contactLastName: '',
    contactIsAdmin: false,
    contactCapabilities: getInitialCapabilities(completeDataSet.processes)
  })

  const mailIsInvalid = !mailIsValid(data.contactEmail)
  const isValid =
    !mailIsDuplicate && !mailIsInvalid && data.contactEmail.length > 0 ? { InputForm: true } : { InputForm: false }

  useEffect(() => {
    if (data.contactIsAdmin) {
      setCapabilitiesToEdit(completeDataSet.processes, setData)
    }
  }, [data.contactIsAdmin, completeDataSet.processes])

  const handleAddUser = () => {
    setSubmitButtonDisabled(true)
    addUser(data, context, redirect)
  }

  const handleInputFormSubmit = () =>
    validateInputForm(data).then((isValid) => {
      if (isValid) {
        setActivePageId(activePageId + 1)
      } else {
        setMailIsDuplicate(true)
      }
    })

  const pages = {
    userData: {
      content: (
        <UserDataInputForm
          data={data}
          setData={setData}
          optionDescriptions={optionDescriptions}
          designOptions={designOptions}
          mailIsDuplicate={mailIsDuplicate}
          mailIsInvalid={mailIsInvalid}
          setMailIsDuplicate={setMailIsDuplicate}
        />
      ),
      title: t('userData'),
      buttonSecondary: <ButtonSecondary content="cancel" onClick={() => history.goBack()} />,
      buttonPrimary: (
        <ButtonPrimary content="next" onClick={() => handleInputFormSubmit()} disabled={!isValid.InputForm} />
      )
    },
    setCapabilities: {
      content: <UserCapabilitiesSelectionPage data={data} setData={setData} />,
      title: t('permissions'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />
    },
    checkInput: {
      content: <AddUserCheckInputPage data={data} />,
      title: t('checkInputs'),
      largeButtons: true,
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: <ButtonPrimary disabled={submitButtonDisabled} content="invite" onClick={() => handleAddUser()} />
    }
  }

  const pageArray = getPageArray(data, pages)

  return (
    <SettingsContainer containsPageGroup>
      <PageGroup pages={pageArray} activePageId={activePageId} isInSettings />
    </SettingsContainer>
  )
}

export default AddUserWizard
