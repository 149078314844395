import React from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import AddAssessmentBaseInfoTile from '../../../components/tiles/AddAssessmentBaseInfoTile'
import AddAssessmentSelectedTestTile from '../../../components/tiles/AddAssessmentSelectedTestTile'
import AddAssessmentTimeSettingsTile from '../../../components/tiles/AddAssessmentTimeSettingsTile'
import AddAssessmentAdvancedOptionsTile from '../../../components/tiles/AddAssessmentAdvancedOptionsTile'
import { PageHeadline } from '../../../utils/elements/miscElements'
import { TileContainer } from '../../../components/table/tableElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import AddAssessmentSecuritySettingsTile from '../../../components/tiles/AddAssessmentSecuritySettingsTile'
import { isPotentials, isPotentialsCrossTest } from '../../../utils/helper/Helper'

const CheckInput = ({ data }) => {
  const t = useTranslate()
  const configType = data.parentConfig.testType
  const showSecurityTile = isPotentials(configType) || isPotentialsCrossTest(configType)
  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer style={{ padding: 0 }}>
          <AddAssessmentBaseInfoTile {...{ data }} />
          <AddAssessmentSelectedTestTile {...{ data }} />
          <AddAssessmentTimeSettingsTile {...{ data }} />
          {showSecurityTile && <AddAssessmentSecuritySettingsTile {...{ data }} />}
          <AddAssessmentAdvancedOptionsTile {...{ data }} />
        </TileContainer>
      </ScrollContainer>
    </>
  )
}
export default CheckInput
