import React from 'react'
import { TileContainer } from '../table/tableElements'
import { TabHintContainer } from '../../utils/elements/miscElements'
import ParticipantAccessDataTile from '../tiles/ParticipantAccessDataTile'
import PtAccessDataTile from '../tiles/PtAccessDataTile'
import { CopyIconTooltip } from '../../utils/helper/Helper'
import useAdjustHeadlineHeight from '../../utils/hooks/useAdjustHeadlineHeight'
import useTranslate from '../../utils/hooks/useTranslate'

const ParticipantAccessDataTab = ({ participant }) => {
  useAdjustHeadlineHeight()
  const t = useTranslate()

  const INVITE_LINK_HELP_TEXT_PARTICIPANT = (
    <span>
      {t('sendLinkOrLoginCode')}
      <a className="textlink" rel="noreferrer" target="_blank" href="https://app.perseo-assessment.de">
        https://app.perseo-assessment.de
      </a>
      {t('toStartTheTests')}
    </span>
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TabHintContainer>{INVITE_LINK_HELP_TEXT_PARTICIPANT}</TabHintContainer>
      <TileContainer>
        <ParticipantAccessDataTile {...{ participant }} />
        {participant.ptList.map((pt) => (
          <PtAccessDataTile key={pt.ptNumber} pt={pt} />
        ))}
      </TileContainer>
      <CopyIconTooltip />
    </div>
  )
}

export default ParticipantAccessDataTab
