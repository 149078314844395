import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { isPotentials } from '../helper/Helper'
import useTranslate from './useTranslate'

const useReferenceTestSelectOptions = (processUuid) => {
  const t = useTranslate()
  const [referenceTestSelectOptions, setReferenceTestSelectOptions] = useState([])
  const context = useContext(AppContext)
  const processes = context.completeDataSet.processes

  useEffect(() => {
    const options = getReferenceTestSelectOptions({
      processList: processes,
      processUuid: processUuid,
      t: t
    })
    setReferenceTestSelectOptions(options)
  }, [processes, processUuid])

  return referenceTestSelectOptions
}

export default useReferenceTestSelectOptions

const getReferenceTestSelectOptions = ({ processList, processUuid, t }) => {
  if (!processUuid) return []
  const referenceTestSelectOptions = []
  const process = processList.find((process) => process.processUuid === processUuid)

  process?.relatedAssessments?.map((ass) => {
    const isInArchiveNotice = !ass.isVisible() ? `[${t('inTheArchive')}]` : ''
    const isNotPotentialsNotice = !isPotentials(ass.relatedConfig.configType) ? `[${t('noPotentialsTest')}]` : ''

    const isDisabled = !ass.isVisible() || !isPotentials(ass.relatedConfig.configType)
    return referenceTestSelectOptions.push({
      label: `${ass.assessmentName} ${isInArchiveNotice} ${isNotPotentialsNotice}`,
      value: ass.assessmentUuid,
      disabled: isDisabled
    })
  })

  return referenceTestSelectOptions
}
