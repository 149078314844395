import styled from 'styled-components'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { SmallNotice } from '../../../utils/elements/miscElements'
import { updateModalProps } from './deleteAccountUtils'
import { getCreditBalance, getLoggedInContact } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'

const DeleteAccountComponent = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contact = getLoggedInContact(context.completeDataSet)
  const customerName = context.completeDataSet.customer.customerName
  const [confirmationText, setConfirmationText] = useState('')
  const canDelete = contact.contactEmail === confirmationText
  const canOpenDeleteDialog = getCreditBalance(context.completeDataSet.creditBookings) >= 0 && contact.contactIsAdmin
  const noticeText = getNoticeText(context)

  useEffect(() => {
    updateModalProps(context, confirmationText, setConfirmationText, canDelete)
  }, [confirmationText])

  return (
    <div style={{ paddingBottom: 'var(--space-7)' }}>
      <p>{t('deleteAccountNotice', customerName, contact.contactMail)}</p>
      {canOpenDeleteDialog ? (
        <DeleteAccountButton
          onClick={() => {
            setConfirmationText('')
            updateModalProps(context, confirmationText, setConfirmationText, canDelete)
            context.setModalOpen(true)
          }}
        >
          {t('deleteCompanyAccount')}...
        </DeleteAccountButton>
      ) : (
        <SmallNotice style={{ marginTop: 0 }}>{t(noticeText)}</SmallNotice>
      )}
    </div>
  )
}

export default DeleteAccountComponent

const DeleteAccountButton = styled.button`
  background-color: var(--delete-account-button-bg-color);
  border-radius: var(--bdr-2);
  padding: var(--space-2) var(--space-3);
  font-weight: var(--fw-2);
  font-size: var(--fs-2);
  &:hover {
    background-color: var(--delete-account-button-hover-color);
  }
`

const getNoticeText = (context) => {
  if (!getLoggedInContact(context.completeDataSet).contactIsAdmin) {
    return 'adminOnlyFunction'
  }
  if (getCreditBalance(context.completeDataSet.creditBookings) < 0) {
    return 'cannotDeleteAccountDueToNegativeCredits'
  }
}
