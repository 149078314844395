import styled from 'styled-components'
import { useContext } from 'react'
import { ENTITIES, FIELD_LABELS } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { getEntityLabel } from '../../../utils/helper/Helper'
import { getStats } from './deleteAccountUtils'
import { Label } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import ScrollContainer from '../../scrollContainer/ScrollContainer'

const DeleteAccountWarning = ({ confirmationText, setConfirmationText }) => {
  const context = useContext(AppContext)
  const customerName = context.completeDataSet.customer.customerName
  const t = useTranslate()

  return (
    <div style={{ position: 'relative' }}>
      <ScrollContainer style={{ height: '250px' }} type="modal">
        <div style={{ lineHeight: 'var(--lh-3)', fontSize: 'var(--fs-3)', marginTop: 'var(--space-5)' }}>
          {t('confirmDeleteCompanyAccount', customerName)}
          <StuffYouLose />
          <span>{t('finalDeletionInstructions')} </span>
          <Label style={{ width: '100%', marginTop: 'var(--space-6)' }}>
            <span style={{ textAlign: 'left' }}>{t(FIELD_LABELS.email)}</span>
            <input type="email" value={confirmationText} onChange={(e) => setConfirmationText(e.target.value)} />
          </Label>
        </div>
      </ScrollContainer>
    </div>
  )
}

export default DeleteAccountWarning

const StuffYouLose = () => {
  const context = useContext(AppContext)
  const stats = getStats(context)

  return (
    <Container>
      <Entry counter={stats.numberTests} label={getEntityLabel(ENTITIES.assessments, stats.numberTests)} />
      <Entry counter={stats.numberPts} label={getEntityLabel(ENTITIES.pts, stats.numberPts)} />
      <Entry counter={stats.numberContacts} label={getEntityLabel(ENTITIES.users, stats.numberContacts)} />
      <Entry counter={stats.numberCredits} label="Credits" />
    </Container>
  )
}

const Container = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-3);
  padding: 0;
  margin: var(--space-6) 0;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Entry = ({ counter, label }) => {
  const t = useTranslate()
  return (
    <InnerContainer>
      <Counter>{counter}</Counter>
      <EntityLabel className="uppercase-label">{t(label)}</EntityLabel>
    </InnerContainer>
  )
}

const InnerContainer = styled.li`
  display: grid;
  text-align: center;
  background-color: var(--box-background);
  padding: var(--space-4);
  border-radius: var(--bdr-2);
  align-items: center;
  gap: var(--space-3);
`

const Counter = styled.span`
  font-size: var(--fs-5);
  font-weight: var(--fw-2);
`

const EntityLabel = styled.span`
  margin-bottom: -4px;
`
