import { BULK_ACTIONS, BULK_PARAMS, ENTITIES, MODAL_TYPES } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { downloadProcessResultCSV, duplicateProcessAndTests } from '../../pages/processes/processUtils'
import { downloadReport } from '../../pages/pts/ptUtils'
import {
  downloadResultCSV,
  downloadResultPdfZip,
  isPt,
  copyToClipboard,
  getPtLink,
  getHubLink
} from '../../utils/helper/Helper'
import { ButtonPrimary } from '../../utils/elements/miscElements'
import { AlertModalText, getAlertModalHeadline, getPrimaryButtonText } from '../modal/modalUtils'
import { applyBulkEdit } from '../bulkEdit/BulkUtils'
import { deleteContacts, getDeleteContactModalText } from '../../utils/helper/deleteContactUtils'

export const getArchiveButton = (row, entity, context, t) => {
  const modalTexts = {
    headline: getAlertModalHeadline(entity, 1, BULK_ACTIONS.archive, t),
    content: <AlertModalText entity={entity} count={1} action={BULK_ACTIONS.archive} />,
    button: getPrimaryButtonText(BULK_ACTIONS.archive)
  }
  return {
    type: 'button',
    clickHandler: () => {
      context.setModalProps({
        headline: modalTexts.headline,
        content: modalTexts.content,
        buttonPrimary: (
          <ButtonPrimary
            modalButton
            content={modalTexts.button}
            onClick={() => {
              context.setModalOpen(false)
              applyBulkEdit(BULK_PARAMS.archived, 'endPointArchiveDelete', entity, [row], context)
            }}
          />
        ),
        icon: 'icon-archive modal',
        type: MODAL_TYPES.alert
      })
      context.setModalOpen(true)
    },
    icon: 'icon-archive',
    label: 'putInArchive',
    hasEllipsis: true
  }
}

export const getDeleteButton = (row, entity, context, t) => {
  const modalTexts = {
    headline: getAlertModalHeadline(entity, 1, BULK_ACTIONS.delete, t),
    content: <AlertModalText entity={entity} count={1} action={BULK_ACTIONS.delete} />,
    button: getPrimaryButtonText(BULK_ACTIONS.delete)
  }
  return {
    type: 'button',
    clickHandler: () => {
      context.setModalProps({
        headline: modalTexts.headline,
        content: modalTexts.content,
        buttonPrimary: (
          <ButtonPrimary
            warning
            modalButton
            content={modalTexts.button}
            onClick={() => {
              context.setModalOpen(false)
              applyBulkEdit(BULK_PARAMS.deleted, 'endPointArchiveDelete', entity, [row], context)
            }}
          />
        ),
        icon: 'icon-delete modal',
        type: MODAL_TYPES.alert
      })
      context.setModalOpen(true)
    },
    icon: 'icon-delete',
    iconStyle: { transform: 'translateX(-1px) scale(1.1)', backgroundColor: 'var(--warning-color)' },
    color: 'var(--warning-color)',
    label: 'delete',
    isWarning: true,
    hasEllipsis: true
  }
}

export const getRestoreButton = (row, entity, context, t) => {
  const modalTexts = {
    headline: getAlertModalHeadline(entity, 1, BULK_ACTIONS.unarchive, t),
    content: <AlertModalText entity={entity} count={1} action={BULK_ACTIONS.unarchive} />,
    button: getPrimaryButtonText(BULK_ACTIONS.unarchive)
  }
  return {
    type: 'button',
    clickHandler: () => {
      context.setModalProps({
        headline: modalTexts.headline,
        content: modalTexts.content,
        buttonPrimary: (
          <ButtonPrimary
            modalButton
            content={modalTexts.button}
            onClick={() => {
              context.setModalOpen(false)
              applyBulkEdit(BULK_PARAMS.visible, 'endPointRestore', entity, [row], context)
            }}
          />
        ),
        icon: 'icon-unarchive modal',
        type: MODAL_TYPES.alert
      })
      context.setModalOpen(true)
    },
    icon: 'icon-unarchive',
    label: 'unarchive',
    hasEllipsis: true
  }
}

export const getDeactivatePtButton = (pt, context, t) => {
  const modalTexts = {
    headline: getAlertModalHeadline(ENTITIES.pts, 1, BULK_ACTIONS.deactivate, t),
    content: <AlertModalText entity={ENTITIES.pts} count={1} action={BULK_ACTIONS.deactivate} />,
    button: getPrimaryButtonText(BULK_ACTIONS.deactivate)
  }
  return {
    type: 'button',
    clickHandler: () => {
      context.setModalProps({
        headline: modalTexts.headline,
        content: modalTexts.content,
        buttonPrimary: (
          <ButtonPrimary
            modalButton
            content={modalTexts.button}
            onClick={() => {
              context.setModalOpen(false)
              applyBulkEdit(BULK_PARAMS.deactivate, 'endPointEdit', ENTITIES.pts, [pt], context)
            }}
          />
        ),
        icon: 'icon-deactivate modal',
        type: MODAL_TYPES.alert
      })
      context.setModalOpen(true)
    },
    icon: 'icon-deactivate',
    iconStyle: { transform: 'scale(0.95) translateX(-1px)' },
    label: 'deactivateTesting',
    hasEllipsis: true
  }
}

export const getAddPtButton = ({ processUuid, assessmentUuid, redirect }) => {
  const urlParams = []

  if (processUuid) urlParams.push({ id: 'processUuid', value: processUuid })
  if (assessmentUuid) urlParams.push({ id: 'assessmentUuid', value: assessmentUuid })

  return {
    type: 'button',
    clickHandler: () => redirect(PAGES.newParticipant, urlParams),
    icon: 'icon-add-pt',
    iconStyle: { transform: 'scale(1.2) translateX(2px)' },
    label: 'addParticipants',
    hasEllipsis: true
  }
}

export const getActivatePtButton = (pt, context) => ({
  type: 'button',
  clickHandler: () => applyBulkEdit(BULK_PARAMS.activate, 'endPointEdit', ENTITIES.pts, [pt], context),
  icon: 'icon-activate',
  label: 'activateTesting'
})

export const getDownloadCsvButton = ({ assessmentUuid, processUuid }) => {
  const downloadFunction = assessmentUuid
    ? () => downloadResultCSV(assessmentUuid)
    : () => downloadProcessResultCSV(processUuid)
  return {
    type: 'button',
    clickHandler: downloadFunction,
    icon: 'icon-csv',
    label: 'downloadCsv'
  }
}

export const getDownloadResultPdfZipButton = (assessmentUuid, context) => ({
  type: 'button',
  clickHandler: () => downloadResultPdfZip(assessmentUuid, context),
  icon: 'icon-pdf',
  label: 'downloadReports'
})

export const getDownloadReportButton = (row, entity) => {
  const type = isPt(entity) ? 'default' : 'participant-report'
  const key = isPt(entity) ? row.relatedResult.resultNr : row.pNr
  return {
    type: 'button',
    clickHandler: () => downloadReport({ type: type, key: key }),
    icon: 'icon-pdf',
    label: 'openReport'
  }
}

export const getDownloadFeedbackButton = (resultNr) => ({
  type: 'button',
  clickHandler: () =>
    downloadReport({
      type: 'feedback',
      key: resultNr
    }),
  icon: 'icon-pdf',
  label: 'downloadFeedback'
})

export const getAddParticipantToOtherTestButton = (participant, redirect) => {
  const urlParams = [{ id: 'pid', value: participant.pNr }]
  return {
    type: 'button',
    clickHandler: () => redirect(PAGES.addExisting, urlParams),
    icon: 'icon-add-test',
    iconStyle: { transform: 'scale(1.2) translateX(2px)' },
    label: 'addParticipantsToTests',
    hasEllipsis: true
  }
}

export const getStartTimerButton = (pts, context) => {
  const bulkStartTimerParams = [[{ name: 'ptIsInvited', value: true }], 'endPointEdit', ENTITIES.pts, pts, context]

  return {
    type: 'button',
    clickHandler: () => applyBulkEdit(...bulkStartTimerParams),
    icon: 'icon-clock',
    iconStyle: { transform: 'scale(0.95) translateX(-1px)' },
    label: 'startTimer'
  }
}

export const getResetTimerButton = (pts, context) => {
  const bulkResetTimerParams = [[{ name: 'ptIsInvited', value: false }], 'endPointEdit', ENTITIES.pts, pts, context]

  return {
    type: 'button',
    clickHandler: () => applyBulkEdit(...bulkResetTimerParams),
    icon: 'icon-clock-reverse',
    label: 'resetTimer'
  }
}

export const getAddTestButton = (process, redirect) => {
  const urlParams = [{ id: 'processUuid', value: process.processUuid }]
  return {
    type: 'button',
    clickHandler: () => redirect(PAGES.newTest, urlParams),
    icon: 'icon-add-test',
    iconStyle: { transform: 'scale(1.2) translateX(2px)' },
    label: 'addTest',
    hasEllipsis: true
  }
}

export const getDuplicateProcessButton = (process, context) => ({
  type: 'button',
  clickHandler: () => {
    context.setModalProps({
      headline: 'duplicateProcess',
      content: 'confirmDuplicateProcessAndTests',
      buttonPrimary: (
        <ButtonPrimary
          modalButton
          content="duplicate"
          onClick={() => {
            context.setModalOpen(false)
            duplicateProcessAndTests(process, context)
          }}
        />
      ),
      icon: 'icon-duplicate modal',
      type: MODAL_TYPES.alert
    })
    context.setModalOpen(true)
  },
  icon: 'icon-duplicate',
  label: 'duplicateProcess',
  hasEllipsis: true
})

export const getEmailButton = (row, entity, redirect) => {
  const value = isPt(entity) ? row.ptNumber : row.ptList.map((pt) => pt.ptNumber)
  const urlParams = [{ id: 'ptNumber', value: value }]

  return {
    type: 'button',
    icon: 'icon-email',
    clickHandler: () => redirect(PAGES.sendEmails, urlParams),
    label: 'sendMail',
    hasEllipsis: true
  }
}

export const getChangeCapabilitiesButton = (process, redirect) => {
  const urlParams = [{ id: 'processUuid', value: process.processUuid }]
  return {
    type: 'button',
    icon: 'icon-key',
    clickHandler: () => redirect(PAGES.changeCapabilities, urlParams),
    label: 'changeCapabilities',
    hasEllipsis: true
  }
}

export const getDeleteContactButton = (contact, context, t) => {
  const modalText = getDeleteContactModalText(contact, t)
  const buttonPrimary = (
    <ButtonPrimary
      modalButton
      warning
      content={contact.loggedInContact ? 'deleteMyAccount' : 'deleteUser'}
      onClick={() => {
        deleteContacts(contact, context, t)
        context.setModalOpen(false)
      }}
    />
  )

  const modalProps = {
    headline: 'deleteUser',
    content: modalText,
    buttonPrimary: buttonPrimary,
    icon: 'icon-delete modal',
    type: MODAL_TYPES.alert
  }

  return {
    type: 'button',
    clickHandler: () => {
      context.setModalProps(modalProps)
      context.setModalOpen(true)
    },
    icon: 'icon-delete',
    iconStyle: { transform: 'translateX(-1px) scale(1.1)', backgroundColor: 'var(--warning-color)' },
    color: 'var(--warning-color)',
    label: 'deleteUser',
    hasEllipsis: true,
    isWarning: true
  }
}

export const getCopyLinkButton = (row, entity, context, t) => {
  const content = isPt(entity) ? getPtLink(row.referenceToken) : getHubLink(row.pnrHash)
  return {
    type: 'button',
    icon: 'icon-link',
    clickHandler: () => copyToClipboard(content, t('link'), context, t),
    label: 'copyLink'
  }
}
