import DataTable from '../table/DataTable'
import { FIELD_LABELS } from '../../utils/constants/constants'
import { ParticipantNameTableCell } from '../table/tableCells/ParticipantNameTableCell'
import { Tile, TileHeadline } from '../table/tableElements'
import { getSpacerCol } from '../table/tableCols/tableCols'

const SelectedParticipantsTile = ({ selectedParticipants }) => {
  const columns = getColumns()
  columns.push(getSpacerCol())

  return (
    <Tile>
      <TileHeadline content="selectedParticipants" />
      <div style={{ maxWidth: '600px', maxHeight: '300px', overflow: 'scroll' }}>
        <DataTable data={selectedParticipants} columns={columns} hideResultCount hideArchiveButton />
      </div>
    </Tile>
  )
}
export default SelectedParticipantsTile

const getColumns = () => [
  {
    Header: FIELD_LABELS.pNumber,
    accessor: 'pNr'
  },
  {
    Header: FIELD_LABELS.personalName,
    accessor: 'pFullName',
    Cell: ({ value }) => <ParticipantNameTableCell pFullName={value} />
  }
]
