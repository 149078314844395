import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'

const SecondaryButton = ({ label, onClick }) => {
  const t = useTranslate()
  return (
    <Button className="secondary-button" onClick={onClick}>
      <span>{t(label)}</span>
    </Button>
  )
}

export default SecondaryButton

const Button = styled.button`
  width: fit-content;
  justify-self: end;
  display: none;
  grid-row: 1;
  grid-column: 2;
  font-size: var(--fs-2);
  background-color: var(--tabgroup-bg-color);
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: var(--sh-1);
`
