import { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../../utils/context/AppContext'
import { getFormattedNumber } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { SmallDescription } from '../../table/tableElements'
import { getWeightsSum, weightsAddUpTo100 } from './processResultUtils'

const TestWeightSelection = ({
  process,
  testWeights,
  setTestWeights,
  weightsAreValid,
  setWeightsAreValid,
  setShowSaveButton
}) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const handleWeightChange = (e, assessment) => {
    const value = parseInt(e.target.value) || 0
    if (value > 100 || value < 0) return
    testWeights[assessment.assessmentUuid] = value
    setTestWeights({ ...testWeights })
    setWeightsAreValid(weightsAddUpTo100(testWeights))
    setShowSaveButton(true)
  }

  return (
    <div className="table-outer-container test-weight-selection">
      <div className="table-inner-container">
        <table style={{ width: '100%', whiteSpace: 'normal' }}>
          <thead>
            <tr>
              <th>Test</th>
              <th style={{ width: '100px', textAlign: 'right' }}>{t('weight')}</th>
            </tr>
          </thead>
          <tbody>
            {process.relatedAssessments.map((assessment) => {
              const canAggregate = assessment.producesNumericResult()
              return (
                <tr key={assessment.assessmentUuid}>
                  <td style={{ lineHeight: 'var(--lh-2)' }}>
                    <span>{assessment.assessmentName}</span>
                    {!canAggregate && <SmallDescription>{t('testCannotBeAggregated')}</SmallDescription>}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {canAggregate && (
                      <div>
                        <WeightInput
                          disabled={!process.isEditor()}
                          type="number"
                          pattern="\d*"
                          value={getFormattedNumber(testWeights[assessment.assessmentUuid], context.language)}
                          onChange={(e) => handleWeightChange(e, assessment)}
                          className={!weightsAreValid && 'has-error'}
                        />
                        <WeightUnit>%</WeightUnit>
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>{t('sum')}</td>
              <td style={{ textAlign: 'right' }}>
                <div>
                  <WeightSumDisplay hasError={!weightsAreValid}>{getWeightsSum(testWeights)}</WeightSumDisplay>
                  <WeightUnit>%</WeightUnit>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default TestWeightSelection

const WeightInput = styled.input`
  width: 48px;
  height: var(--space-7);
  text-align: right;
  &:disabled {
    color: var(--text-color-secondary);
  }
`

const WeightSumDisplay = styled.span`
  padding: var(--space-3);
  text-align: right;
  border: 1px solid transparent;
  color: ${(props) => (props.hasError ? 'var(--warning-color)' : 'inherit')};
`

const WeightUnit = styled.span`
  margin-left: var(--space-2);
  color: var(--text-color-secondary);
`
