import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { PageHeadline } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import SelectUploadOption from '../subComponents/SelectUploadOption'

const DataSource = ({ data, setData }) => {
  const t = useTranslate()
  return (
    <>
      <PageHeadline>{t('dataSource')}</PageHeadline>
      <ScrollContainer>
        <SelectUploadOption {...{ data, setData }} />
      </ScrollContainer>
    </>
  )
}
export default DataSource
