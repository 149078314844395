import { DEFAULT_LANGUAGE, ENTITIES } from '../../utils/constants/constants'
import { DownloadPdfButton, GreyButton } from '../../utils/elements/miscElements'
import {
  capitalizeFirstLetter,
  getFormattedNumber,
  isPotentialsCrossTest,
  isTraitsOrGoals,
  replaceLatexChars
} from '../../utils/helper/Helper'
import CsvDownload from '../csvDownload/CsvDownload'
import { Tile, TileHeadline } from '../table/tableElements'
import { Result, ResultLabel, ResultsInnerContainer, ResultsOuterContainer } from './ResultsTile'
import styled from 'styled-components'
import { PECULIARITY_LEVELS } from '../../utils/constants/constants'
import { useContext, useRef } from 'react'
import PtNotes from '../ptNotes/PtNotes'
import useTranslate from '../../utils/hooks/useTranslate'
import GradeLabel from '../gradeLabel/GradeLabel'
import { AppContext } from '../../utils/context/AppContext'

const PtResultSummaryTile = ({ pt }) => (
  <Tile width="300px">
    <TileHeadline content="summary" />
    <OuterContainer>
      <ResultSummary {...{ pt }} />
      <DownloadButtons {...{ pt }} />
    </OuterContainer>
  </Tile>
)

export default PtResultSummaryTile

const ResultSummary = ({ pt }) => {
  if (!pt.resultIsVisible()) return <></>

  return isPotentialsCrossTest(pt.relatedConfig.configType) && pt.hasCrosstestResult() ? (
    <CrossTestResultSummary {...{ pt }} />
  ) : (
    <PotentialsTraitsResultSummary {...{ pt }} />
  )
}

const CrossTestResultSummary = ({ pt }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const crosstestResult =
    pt.relatedResult?.crosstestResult[context.language] || pt.relatedResult?.crosstestResult[DEFAULT_LANGUAGE]
  const color = crosstestResult.peculiarityLevel === PECULIARITY_LEVELS.high ? 'var(--red-500)' : ''

  return (
    <>
      <ResultsInnerContainer>
        <ResultLabel>{t('result')}</ResultLabel>
        <Result style={{ color: color }}>{capitalizeFirstLetter(crosstestResult.peculiarityText)}</Result>
      </ResultsInnerContainer>
      <Recommendation>{replaceLatexChars(crosstestResult.description)}</Recommendation>
      <Recommendation>{crosstestResult.interpretation}</Recommendation>
    </>
  )
}

export const PotentialsTraitsResultSummary = ({ pt }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const hasMatch = isTraitsOrGoals(pt.relatedConfig.configType)
  const resultLabel = hasMatch ? 'Match' : t('points')
  const resultSuffix = hasMatch ? '%' : ''
  const result = pt.relatedResult
  const hasNotes = result?.notes?.de?.length > 0
  const rank = pt.getRank()
  const { language } = context

  return (
    <>
      <ResultsOuterContainer>
        <ResultElement label={resultLabel} content={result.normResult} suffix={resultSuffix} />
        <ResultElement
          label={<GradeLabel label={result.gradeLabel} />}
          content={getFormattedNumber(result.grade, language)}
        />
        <ResultElement label={t('rank')} content={rank.current} suffix={`/ ${rank.max}`} />
      </ResultsOuterContainer>
      <Recommendation>
        {result.recommendation[context.language] || result.recommendation[DEFAULT_LANGUAGE]}
      </Recommendation>
      {hasNotes && <PtNotes result={result} />}
    </>
  )
}

const ResultElement = ({ label, content, suffix }) => {
  return (
    <ResultsInnerContainer>
      <ResultLabel>{label}</ResultLabel>
      <Result>
        {content}
        {suffix && <ResultSuffixContainer>{suffix}</ResultSuffixContainer>}
      </Result>
    </ResultsInnerContainer>
  )
}

const ResultSuffixContainer = styled.span`
  color: var(--text-color-secondary);
  font-size: var(--fs-4);
  font-weight: var(--fw-1);
  margin-left: var(--space-2);
`

const Recommendation = styled.div`
  line-height: var(--lh-3);
`

const DownloadButtons = ({ pt }) => {
  const t = useTranslate()
  const reportAvailable = pt.relatedAssessment.reportAvailable
  const childRef = useRef(null)

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      childRef.current.handleClick()
    }
  }

  return (
    <div style={{ display: 'grid', gap: 'var(--space-3)', gridTemplateColumns: '1fr 1fr' }}>
      {reportAvailable ? <DownloadPdfButton resultNr={pt.relatedResult.resultNr} /> : <></>}
      <GreyButton onKeyDown={handleKeyPress}>
        <span className="svg-icon icon-csv" style={{ marginRight: 'var(--space-3)' }} />
        <span>{t('csvRawdata')}</span>
        <CsvDownload rowData={[pt]} entity={ENTITIES.ptResults} ref={childRef} />
      </GreyButton>
    </div>
  )
}

const OuterContainer = styled.div`
  display: grid;
  gap: var(--space-5);
`
