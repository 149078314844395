import React, { useEffect } from 'react'
import { isDemo, setState } from '../../../utils/helper/Helper'
import { CONFIG_VARIANTS } from '../../../utils/constants/constants'
import {
  CustomCheckmark,
  CardHeadline,
  CardDescription,
  CreditAmountHighlight,
  CardPriceContainer,
  HoverConnector,
  TestVariantCheckboxElement
} from '../../../utils/elements/miscElements'
import PriceInfoButton from '../../../components/priceInfoButton/PriceInfoButton'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'

const ConfigVariantSelection = ({ parentConfig, setData, data }) => {
  const t = useTranslate()
  const availableConfigVariants = parentConfig.availableConfigs.filter(
    (variant) => variant !== undefined && variant.isLatest
  )
  const availableConfigUuids = availableConfigVariants.map((variant) => variant.configUuid)

  // select first option if needed
  useEffect(() => {
    const firstConfigUuid = availableConfigVariants[0].configUuid
    if (data.configUuid === '' || !availableConfigUuids.includes(data.configUuid)) {
      setState(setData, 'configUuid', firstConfigUuid)
    }
  }, [availableConfigVariants, setData, data.configUuid, availableConfigUuids])

  useSetConfigVariantAndTestName(data, setData)

  const handleVariantChange = (availableVariant) => {
    const update = availableVariant.isCrossTest()
      ? {
          configUuid: availableVariant.configUuid,
          inHub: false,
          isSupervised: true,
          createNewProcess: false
        }
      : {
          configUuid: availableVariant.configUuid,
          inHub: true,
          isSupervised: false,
          referenceAssessmentUuid: null
        }
    setData((prev) => ({
      ...prev,
      ...update
    }))
  }

  const variantOrder = ['Standard', 'QuickScan', 'Cross-Test', 'Demo']
  const orderedVariants = availableConfigVariants.sort(
    (a, b) => variantOrder.indexOf(a.configVariant) - variantOrder.indexOf(b.configVariant)
  )

  return (
    <OuterContainer>
      {orderedVariants.map((availableVariant, i) => {
        const thisVariant = CONFIG_VARIANTS.find((variant) => variant.id === availableVariant.configVariant)
        const configPrice = availableVariant.configCreditsCost

        return (
          <HoverConnector key={'checkbox' + i}>
            <InnerContainer>
              <TestVariantCheckboxElement
                onClick={() => handleVariantChange(availableVariant)}
                checked={availableVariant.configUuid === data.configUuid}>
                <CardHeadline>{thisVariant.headline}</CardHeadline>
                <CardDescription className="hide-on-mobile">{t(thisVariant.description)}</CardDescription>
                <CardPriceContainer className="card-price-container">
                  <ConfigPriceTag price={configPrice} />
                  {configPrice > 0 && <PriceInfoButton productPrice={configPrice} />}
                </CardPriceContainer>
              </TestVariantCheckboxElement>
              <CustomCheckmark
                type="radio"
                onClick={() => handleVariantChange(availableVariant)}
                checked={availableVariant.configUuid === data.configUuid}
              />
            </InnerContainer>
          </HoverConnector>
        )
      })}
    </OuterContainer>
  )
}

export default ConfigVariantSelection

const ConfigPriceTag = ({ price }) => (
  <>
    <CreditAmountHighlight className="credit-amount-highlight">{price}</CreditAmountHighlight>{' '}
    <span className="uppercase-label">Credits</span>
  </>
)

const useSetConfigVariantAndTestName = (data, setData) => {
  const t = useTranslate()
  useEffect(() => {
    const selectedConfig = data.parentConfig.availableConfigs.find((config) => config.configUuid === data.configUuid)
    const configVariant = selectedConfig?.configVariant
    const configType = selectedConfig?.configType
    setData((prev) => ({
      ...prev,
      configVariant: configVariant,
      configType: configType,
      assessmentName: addPrefixOrSuffix(data.parentConfig.name, configVariant, t)
    }))
  }, [data.configUuid, data.parentConfig, setData, data.assessmentName])
}

const getTestNamePrefix = (configVariant, t) => {
  switch (configVariant) {
    case 'Demo':
      return 'Demotest'
    case 'QuickScan':
      return t('shorttest')
    case 'Cross-Test':
      return 'Crosstest'
    case 'Standard':
    default:
      return null
  }
}

const getTestNameSuffix = (configVariant) => {
  if (isDemo(configVariant)) return '(Demo)'
  return null
}

const addPrefixOrSuffix = (parentConfigName, configVariant, t) => {
  if (parentConfigName.includes('Test')) {
    const prefix = getTestNamePrefix(configVariant, t)
    return prefix ? parentConfigName.replace('Test', prefix) : parentConfigName
  } else {
    const suffix = getTestNameSuffix(configVariant)
    return suffix ? `${parentConfigName} ${suffix}` : parentConfigName
  }
}

const OuterContainer = styled.div`
  display: grid;
  grid-gap: var(--space-3);
`

const InnerContainer = styled.div`
  display: grid;
  grid-gap: var(--space-3);
  grid-template-columns: 1fr 50px;
  align-items: center;
  @media (max-width: 600px) {
    .custom-checkmark {
      width: 24px;
      height: 24px;
    }
  }
`
