import PageGroup from '../../components/pageGroup/PageGroup'
import CheckInput from './subPages/CheckInput'
import SendEmailSuccessPage from './subPages/SendEmailSuccessPage'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { getLoggedInContact, mailIsValid } from '../../utils/helper/Helper'
import { PAGES } from '../../utils/constants/pages'
import { AppContext } from '../../utils/context/AppContext'
import {
  getDefaultNewTitle,
  getDefaultTemplates,
  getParticipantsFromUrlParams,
  handleEmailModal
} from './sendEmailUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import MailSettings from './subPages/MailSettings'
import useTemplateUpdates from './useTemplateUpdates'
import EmailPreview from './subPages/EmailPreview'
import EmailTemplateWrapper from './subPages/EmailTemplateWrapper'
import useRedirect from '../../utils/hooks/useRedirect'

const SendEmailWizard = () => {
  const t = useTranslate()
  const history = useHistory()
  const redirect = useRedirect()
  const [activePageId, setActivePageId] = useState(0)
  const location = useLocation()
  const context = useContext(AppContext)
  const customerName = context.completeDataSet.customer.customerName
  const initialData = getParticipantsFromUrlParams(location, context.completeDataSet.participants)
  const defaultTemplates = getDefaultTemplates(customerName, t)
  const contact = getLoggedInContact(context.completeDataSet)

  useEffect(() => {
    if (initialData.length === 0) {
      redirect(PAGES.dashboard)
    }
  }, [initialData])

  const [data, setData] = useState({
    availableTemplates: [...defaultTemplates, ...context.completeDataSet.ptInvitationTemplates],
    participants: initialData,
    emailType: 'invitation',
    emailSubject: defaultTemplates[0].templateData.subject,
    startTimerOnSend: true,
    topText: defaultTemplates[0].templateData.topText,
    bottomText: defaultTemplates[0].templateData.bottomText,
    templateTitle: defaultTemplates[0].title,
    templateKey: defaultTemplates[0].publicKey,
    previewParticipantNr: initialData?.[0]?.pNr,
    salutationType: defaultTemplates[0].templateData.salutationType,
    saveMethod: 'add',
    newTemplateTitle: getDefaultNewTitle(defaultTemplates[0].title, 'add', t),
    isDirty: false,
    replyToName: customerName,
    replyToEmail: getLoggedInContact(context.completeDataSet).contactEmail,
    formErrorsVisible: false,
    contactEmail: contact.contactEmail
  })

  const [response, setReponse] = useState({})
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false)

  useTemplateUpdates(data, setData, defaultTemplates)

  useEffect(() => {
    if (submitButtonClicked) {
      handleEmailModal(
        context,
        data,
        setActivePageId,
        activePageId,
        setReponse,
        submitButtonClicked,
        setSubmitButtonClicked,
        t
      )
    }
  }, [submitButtonClicked])

  const subjectEmpty = data.emailSubject.length === 0
  const emailIsValid = mailIsValid(data.replyToEmail) || data.replyToEmail === ''

  const pages = {
    templates: {
      content: <EmailTemplateWrapper {...{ data, setData }} />,
      title: t('emailTemplates'),
      buttonSecondary: <ButtonSecondary content="cancel" onClick={history.goBack} />,
      buttonPrimary: (
        <ButtonPrimary disabled={subjectEmpty} content="next" onClick={() => setActivePageId(activePageId + 1)} />
      ),
      largeButtons: true
    },
    preview: {
      content: <EmailPreview {...{ data, setData }} />,
      title: t('preview'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />,
      largeButtons: true
    },
    mailSettings: {
      content: <MailSettings {...{ data, setData, emailIsValid }} />,
      title: t('settings'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          disabled={!emailIsValid && data.formErrorsVisible}
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
        />
      ),
      largeButtons: true
    },
    checkInput: {
      content: <CheckInput {...{ data }} />,
      title: t('checkInputs'),
      largeButtons: true,
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          content={t('send') + '…'}
          onClick={() =>
            handleEmailModal(
              context,
              data,
              setActivePageId,
              activePageId,
              setReponse,
              submitButtonClicked,
              setSubmitButtonClicked,
              t
            )
          }
        />
      )
    },
    sendEmailSuccessPage: {
      content: <SendEmailSuccessPage {...{ response }} />,
      hidePageIndicator: true,
      buttonPrimary: (
        <ButtonPrimary content="goToOverview" style={{ gridColumn: 2 }} onClick={() => redirect(PAGES.dashboard)} />
      )
    }
  }

  return (
    <>
      {initialData.length > 0 && (
        <PageGroup
          pages={[pages.templates, pages.preview, pages.mailSettings, pages.checkInput, pages.sendEmailSuccessPage]}
          activePageId={activePageId}
        />
      )}
    </>
  )
}

export default SendEmailWizard
