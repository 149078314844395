import { CONTENT_TYPES } from '../../../utils/constants/constants'
import { CopyIcon } from '../../../utils/elements/miscElements'
import { getHubLink, getPtLink } from '../../../utils/helper/Helper'
import { createDate } from '../../../utils/helper/dateTimeHelper'
import Arrow from '../../arrow/Arrow'
import DateDisplay from '../../dateDisplay/DateDisplay'
import { TableContentContainer } from '../tableElements'

export const getHubLinkCol = (options) => ({
  Header: 'linkToTestOverview',
  accessor: (row) => row.referenceToken,
  id: 'hubLink',
  canExport: true,
  show: !options?.isHidden,
  disableSortBy: options?.disableSortBy,
  Cell: ({ row }) => {
    const hubLink = getHubLink(row.original.pnrHash)
    return (
      <TableContentContainer className="has-fading-edge" style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div style={{ width: '100px', overflow: 'scroll' }}>{hubLink.replace('https://', '')}</div>
        <CopyIcon content={hubLink} elementLabel="Link" position="table" />
      </TableContentContainer>
    )
  }
})

export const toggleCol = {
  Header: '',
  accessor: 'detailsToggle',
  id: 'expander',
  className: 'details-toggle-col',
  disableSortBy: true,
  show: true,
  Cell: ({ row }) => (
    <button className="expander-container" style={{ background: 'none', padding: 0, borderRadius: 'var(--bdr-1)' }}>
      {row.isExpanded ? <Arrow direction="up" /> : <Arrow direction="down" />}
    </button>
  )
}

export const getPtLinkCol = () => ({
  Header: 'testLink',
  accessor: (row) => row.referenceToken,
  id: 'ptLink',
  canExport: true,
  show: 'true',
  Cell: ({ row }) => {
    const ptLink = getPtLink(row.original.referenceToken)
    return (
      <TableContentContainer className="has-fading-edge" style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div style={{ width: '100px', overflow: 'scroll' }}>{ptLink.replace('https://', '')}</div>
        <CopyIcon content={ptLink} elementLabel="Link" position="table" />
      </TableContentContainer>
    )
  }
})

export const getVisibilityCol = (category) => ({
  Header: 'Visibility',
  accessor: 'visibility',
  id: 'visibility',
  alwaysHidden: true,
  filterOptions: {
    showInFieldSelector: true,
    contentType: CONTENT_TYPES.text,
    category: category,
    label: 'archived',
    dataLabels: {
      1: 'no',
      2: 'yes'
    }
  }
})

export const getActionsCol = () => ({
  Header: 'actions',
  accessor: 'actions',
  className: 'actions-col',
  id: 'actions',
  show: true,
  disableSortBy: true,
  Cell: ({ value }) => <div style={{ display: 'flex', justifyContent: 'center' }}>{value}</div>
})

export const getSpacerCol = () => ({
  Header: '',
  width: '0',
  id: 'spacer',
  className: 'spacer-col',
  show: true
})

export const getDateCreatedCol = (id = '', category) => ({
  Header: 'dateCreated',
  accessor: (row) => createDate(row.created),
  id: id,
  show: true,
  canExport: true,
  Cell: ({ row }) => {
    const timestamp = row.original.created ? createDate(row.original.created) : null
    return (
      <TableContentContainer>
        <DateDisplay date={timestamp} format={'dateAndTime'} />
      </TableContentContainer>
    )
  },
  filterOptions: {
    showInFieldSelector: true,
    category: category,
    contentType: CONTENT_TYPES.date,
    excludeFutureDates: true
  }
})

export const getCreatedByCol = (id = '', category) => ({
  Header: 'createdBy',
  id: id,
  canExport: true,
  show: true,
  accessor: (row) => row.createdBy,
  filterOptions: { showInFieldSelector: true, category: category }
})
