import React, { useContext } from 'react'
import { AppContext } from '../utils/context/AppContext'
import { PageHeadline } from '../utils/elements/miscElements'
import useTranslate from '../utils/hooks/useTranslate'

const AGB = ({ showInModal }) => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') return <AGBEnglish {...{ showInModal }} />
  return <AGBGerman {...{ showInModal }} />
}

const AGBGerman = ({ showInModal }) => (
  <div className="legal">
    {!showInModal && <PageHeadline>Allgemeine Geschäftsbedingungen</PageHeadline>}
    <h3 style={{ marginTop: 0 }}>1. Anwendungsbereich</h3>
    <p>
      Die Perseo GmbH („Perseo“) erbringt sämtliche Leistungen ausschließlich unter Zugrundelegung dieser Allgemeinen
      Geschäftsbedingungen (AGB). AGB des Kunden finden keine Anwendung.
    </p>
    <p>Von diesen AGB abweichende Absprachen zwischen den Vertragsparteien sind nur in schriftlicher Form gültig.</p>
    <h3>2. Beschränkung auf Geschäftskunden</h3>
    <p>
      Vertragspartner von Perseo sind ausschließlich Unternehmer im Sinne des § 14 BGB. Mit einer Bestellung oder einer
      Beauftragung erklärt der Kunde, dass er ein geschäftsfähiger Geschäftskunde und kein Verbraucher im Sinne des § 13
      BGB ist.
    </p>
    <h3>3. Vertragspartner, Vertragsabschluss</h3>
    <p>Der Vertrag kommt zustande mit der Perseo GmbH, Graf-Adolf-Str. 41, 40210 Düsseldorf.</p>
    <p>
      Ein Vertrag kommt erst durch schriftliche Bestätigung der Beauftragung seitens Perseo zustande. Erfolgt die
      Leistung durch Perseo, ohne dass dem Kunden vorher eine Auftragsbestätigung zuging, so kommt der Vertrag mit
      Beginn der Ausführung der Leistung zustande.
    </p>
    <h3>4. Leistungen</h3>
    <p>Perseo bietet digitale und nicht-digitale Dienstleistungen an.</p>
    <p>
      Digitale Dienstleistungen umfassen eignungsdiagnostische Testverfahren zur Personalauswahl, die über eine
      Web-Anwendung unter https://app.perseo-assessment.de („Testplattform“) durchgeführt werden, die automatische
      Generierung von Ergebnisberichten sowie die Bereitstellung eines Kundenbereichs zur Verwaltung von Tests und
      Teilnehmern.
    </p>
    <p>
      Nicht-digitale Dienstleistungen umfassen die Durchführung von Assessment-Center-Verfahren, Einstellungsinterviews
      sowie Lehr- und Fortbildungsveranstaltungen, die Entwicklung von Test- und Fragebogenitems sowie sonstige
      Beratungsleistungen.
    </p>
    <h3>5. Registrierung der Nutzer im Kundenbereich</h3>
    <p>
      Zur Verwaltung von Tests und Teilnehmern stellt Perseo allen Kunden unter https://perseo-assessment.de/ eine
      kostenfreie Web-Anwendung („Kundenbereich“) zur Verfügung. Der Zugang zum Kundenbereich ist passwortgeschützt und
      nur registrierten Nutzern möglich. Nutzer sind alle Mitarbeiter des Kunden sowie alle weiteren Mitarbeiter, die
      namens und im Auftrag des Kunden handeln. Der Kunde trägt Sorge dafür, dass die in seinem Namen und Auftrag
      registrierten Nutzer den in diesen AGB vereinbarten Regeln Folge leisten.
    </p>
    <p>
      Auf eine Registrierung besteht kein Rechtsanspruch. Perseo ist jederzeit berechtigt, die Zugangsberechtigung
      bestimmter Nutzer durch Sperrung der Zugangsdaten zu widerrufen, ohne dass es der Angabe von Gründen bedarf.
    </p>
    <p>
      Der Nutzer ist verpflichtet, bei der Registrierung wahrheitsgemäße Angaben zu machen. Änderungen des Namens oder
      der E-Mail-Adresse hat der Nutzer Perseo unverzüglich mitzuteilen. Der Nutzer muss ferner dafür Sorge tragen, dass
      ihm die E-Mails zugehen, die an die vom ihm angegebene E-Mail-Adresse gesendet werden.
    </p>
    <h3>6. Nutzung von Tests, Credit-System</h3>
    <p>
      Zur Durchführung digitaler Leistungs- und Persönlichkeitstests werden PERSEO Credits („Credits“) benötigt. Credits
      können per E-Mail, Telefon und im Kundenbereich zu den in der Preisliste angegebenen bzw. den individuell
      vereinbarten Preisen bestellt werden. Bestellte Credits werden dem Kundenkonto gutgeschrieben, das im
      Kundenbereich einsehbar ist.
    </p>
    <p>
      Der Kunde kann Tests und Zugangskennungen im Kundenbereich selbständig und kostenlos anlegen. Erst nach
      Durchführung eines Tests wird die dem jeweiligen Test zugeordnete Anzahl an Credits vom Kundenkonto abgebucht.
    </p>
    <p>
      Credits können für alle im Kundenbereich verfügbaren Tests sowie verschiedene Zusatzleistungen (Reportmodule,
      Designanpassungen) zeitlich unbegrenzt genutzt werden.{' '}
    </p>
    <p>
      Werden mehr Credits verbraucht als Guthaben auf dem Kundenkonto vorhanden ist, entsteht ein negatives Guthaben.
      Sofern nichts anderes vereinbart wurde, werden negative Guthaben jeweils zum Anfang eines Monats automatisch
      ausgeglichen, indem der ausstehende Betrag dem Kundenkonto gutgeschrieben und die entsprechende Anzahl an Credits
      in Rechnung gestellt wird.
    </p>
    <h3>7. Kosten und Zahlung</h3>
    <p>
      Zahlungen sind grundsätzlich innerhalb von 14 Kalendertagen nach Rechnungsdatum fällig. Eine Zahlung gilt erst
      dann als geleistet, wenn sie auf einem der Bankkonten von Perseo gutgeschrieben ist.
    </p>
    <p>
      Soweit im Einzelfall nichts anderes vereinbart wird, verstehen sich die Preise „Netto“ zuzüglich Umsatzsteuer von
      z.Z. 19 %.
    </p>
    <p>
      Bei Verzug ist Perseo berechtigt, Zinsen in Höhe von 8 Prozentpunkten über dem Basiszinssatz zu verlangen. Das
      Recht von Perseo, einen höheren Schaden geltend zu machen, bleibt unberührt.
    </p>
    <h3>8. Stornierung / Rücktritt</h3>
    <p>
      Erworbene Credits können bis zu 14 Tage nach Erwerb zurückerstattet werden, sofern noch keine Credits des
      erworbenen Pakets genutzt wurden. Eine nachträgliche Rückerstattung oder Auszahlung von Credits ist nicht möglich.
    </p>
    <p>
      Alle weiteren Dienstleistungen können bis zu 14 Tage vor dem vereinbarten Termin der Leistungserbringung
      kostenfrei storniert werden. Bei Stornierung bis zu 7 Tage vor dem vereinbartem Termin berechnen wir ein
      Ausfallhonorar von 50 % des vereinbarten Preises. Bei späterer Stornierung wird der volle Preis fällig.
    </p>
    <h3>9. Urheberrecht und gewerbliche Schutzrechte</h3>
    <p>
      Alle Urheber-, Marken- und sonstigen gewerblichen Schutzrechte an den Web-Anwendungen, Testverfahren und
      Ergebnisreports stehen ausschließlich Perseo zu.
    </p>
    <p>
      Dem Kunden ist es nicht gestattet, die Testinhalte zu vervielfältigen, zu speichern, auszudrucken oder an Dritte
      weiterzugeben. Ferner ist es dem Kunden untersagt, die Testverfahren in anderen Umgebungen als der Testplattform
      umzusetzen oder umsetzen zu lassen.
    </p>
    <h3>10. Gewährleistung und Haftungsbeschränkung</h3>
    <p>
      Perseo hat keine Kontrolle darüber, wie die im Angebot dargestellten Informationen vom Kunden verwendet werden.
      Perseo kann daher auch nicht für entgangene Vorteile, Verträge oder sonstige unmittelbare oder mittelbare Schäden,
      die aus diesen Informationen entstehen könnten, haftbar gemacht werden. Es wird ferner darauf hingewiesen, dass
      kein bestimmter Erfolg oder bestimmte (Test-)Ergebnisse geschuldet sind.
    </p>
    <p>
      Perseo gewährleistet eine Verfügbarkeit der Testplattform sowie des Kundenbereichs in Höhe von 98% im
      Jahresmittel. Es wird jedoch darauf hingewiesen, dass es z.B. wegen Wartungsarbeiten zu zeitweiligen
      Unterbrechungen der Verfügbarkeit kommen kann. Vorstehende Gewährleistung gilt nicht, wenn es aus Gründen
      außerhalb der Kontrolle von Perseo (z.B. durch Stromausfall oder Unterbrechungen des öffentlichen
      Kommunikationsnetzes) zu Unterbrechungen der Verfügbarkeit der Plattformen kommt.
    </p>
    <p>
      Perseo haftet dem Kunden gegenüber für eventuelle Schadensersatzansprüche wegen Verletzung von Leben, Körper oder
      Gesundheit, für alle Schadensersatzansprüche nach dem Produkthaftungsgesetz, bei Vorsatz und grober
      Fahrlässigkeit, soweit Perseo einen Mangel arglistig verschwiegen hat oder eine Beschaffenheitsgarantie im Sinne
      des §444 BGB übernommen hat sowie bei der Verletzung wesentlicher Vertragspflichten in einer den Vertragszweck
      gefährdenden Weise (sogenannte Kardinalpflichten).{' '}
    </p>
    <p>
      Im Übrigen ist die Haftung von Perseo – gleich aus welchem Rechtsgrund – ausgeschlossen. Sofern Perseo für die
      Verletzung von Kardinalpflichten haftet, ohne dass Vorsatz oder grobe Fahrlässigkeit gegeben sind, ist die Haftung
      von Perseo auf denjenigen Schadensumfang begrenzt, mit dessen Eintritt bei Vertragsschluss entsprechend der zu
      diesem Zeitpunkt bekannten Umstände üblicherweise zu rechnen war.
    </p>
    <h3>11. Änderungsvorbehalt</h3>
    <p>
      Perseo ist zu Änderungen dieser allgemeinen Geschäftsbedingungen und sonstiger Bedingungen berechtigt. Perseo wird
      diese Änderungen nur aus triftigen Gründen durchführen, insbesondere aufgrund neuer technischer Entwicklungen,
      Änderungen der Rechtsprechung oder sonstigen gleichwertigen Gründen. Wird durch die Änderung das vertragliche
      Gleichgewicht zwischen den Parteien erheblich gestört, so unterbleibt die Änderung. Im Übrigen bedürfen Änderungen
      der Zustimmung des Kunden.
    </p>
    <h3>12. Sonstiges</h3>
    <p style={{ margin: 0 }}>
      Es gilt ausschließlich deutsches Recht. Gerichtsstand für alle Rechtsstreitigkeiten aus dem Vertragsverhältnis der
      Parteien ist Düsseldorf.
    </p>
  </div>
)

export default AGB

const AGBEnglish = ({ showInModal }) => (
  <div className="legal">
    {!showInModal && <PageHeadline>General Terms and Conditions</PageHeadline>}
    <h3 style={{ marginTop: 0 }}>1. Scope of Application</h3>
    <p>
      Perseo GmbH (“Perseo”) provides all services exclusively based on these General Terms and Conditions. The
      customer&apos;s terms and conditions do not apply.
    </p>
    <p>
      Deviation from these terms and conditions by mutual agreement between the contracting parties is only valid in
      written form.
    </p>
    <h3>2. Limitation to Business Customers</h3>
    <p>
      Contractual partners of Perseo are exclusively entrepreneurs as defined in § 14 of the German Civil Code (BGB). By
      placing an order or commission, the customer declares that they are a legally capable business customer and not a
      consumer as defined in § 13 BGB.
    </p>
    <h3>3. Contractual Parties, Conclusion of Contract</h3>
    <p>The contract is concluded with Perseo GmbH, Graf-Adolf-Str. 41, 40210 Düsseldorf, Germany.</p>
    <p>
      A contract is only concluded upon written confirmation of the commission by Perseo. If Perseo performs the service
      without prior order confirmation to the customer, the contract is concluded at the start of the execution of the
      service.
    </p>
    <h3>4. Services</h3>
    <p>Perseo offers both digital and non-digital services.</p>
    <p>
      Digital services include suitability diagnostic testing procedures for personnel selection, conducted via a web
      application at https://app.perseo-assessment.de (“Test Platform”), automated generation of result reports, and
      provision of a customer area for test and participant management.
    </p>
    <p>
      Non-digital services include the implementation of assessment center procedures, hiring interviews, as well as
      teaching and training events, the development of test and questionnaire items, and other consulting services.
    </p>
    <h3>5. Registration of Users in the Customer Area</h3>
    <p>
      To manage tests and participants, Perseo provides all customers with a free web application (“Customer Area”) at
      https://perseo-assessment.de/. Access to the Customer Area is password-protected and only available to registered
      users. Users include all employees of the customer and all other employees acting on behalf of the customer. The
      customer ensures that registered users in their name and on their behalf comply with the rules agreed upon in
      these terms and conditions.
    </p>
    <p>
      There is no legal entitlement to registration. Perseo is entitled to revoke the access rights of certain users by
      blocking access data at any time without stating reasons.
    </p>
    <p>
      The user is obligated to provide truthful information during registration. The user must promptly inform Perseo of
      any changes to their name or email address. The user must also ensure that they receive emails sent to the email
      address provided by them.
    </p>
    <h3>6. Use of Tests, Credit System</h3>
    <p>
      To conduct digital performance and personality tests, Perseo Credits (“Credits”) are required. Credits can be
      ordered via email, telephone, and in the Customer Area at the prices specified in the price list or the
      individually agreed prices. Ordered credits are credited to the customer account, which is viewable in the
      Customer Area.
    </p>
    <p>
      The customer can independently and free of charge create tests and access credentials in the Customer Area. Only
      after conducting a test will the corresponding number of credits be debited from the customer account.
    </p>
    <p>
      Credits can be used indefinitely for all available tests in the Customer Area as well as various additional
      services (report modules, design customizations).
    </p>
    <p>
      If more credits are consumed than the balance available in the customer account, a negative balance is created.
      Unless otherwise agreed, negative balances will be automatically offset at the beginning of each month by
      crediting the outstanding amount to the customer account and invoicing the corresponding number of credits.
    </p>
    <h3>7. Costs and Payment</h3>
    <p>
      Payments are generally due within 14 calendar days after the invoice date. Payment is considered made only when
      credited to one of Perseo&apos;s bank accounts.
    </p>
    <p>
      Unless otherwise agreed on a case-by-case basis, prices are understood to be Net plus a value-added tax of
      currently 19%.
    </p>
    <p>
      In case of default, Perseo is entitled to demand interest at a rate of 8 percentage points above the base rate.
      Perseo&apos;s right to claim higher damages remains unaffected.
    </p>
    <h3>8. Cancellation / Withdrawal</h3>
    <p>
      Acquired credits can be refunded within 14 days of purchase if no credits from the purchased package have been
      used. Subsequent refund or payout of credits is not possible.
    </p>
    <p>
      All other services can be canceled free of charge up to 14 days before the agreed date of service provision. For
      cancellations up to 7 days before the agreed date, we charge a cancellation fee of 50% of the agreed price. For
      later cancellations, the full price will be charged.
    </p>
    <h3>9. Copyright and Industrial Property Rights</h3>
    <p>
      All copyrights, trademarks, and other industrial property rights to the web applications, testing procedures, and
      result reports are exclusively owned by Perseo.
    </p>
    <p>
      The customer is not allowed to duplicate, store, print, or share test content with third parties. Furthermore, the
      customer is prohibited from implementing or having the test procedures implemented in environments other than the
      Test Platform.
    </p>
    <h3>10. Warranty and Limitation of Liability</h3>
    <p>
      Perseo has no control over how the information presented in the offer is used by the customer. Therefore, Perseo
      cannot be held liable for lost benefits, contracts, or any direct or indirect damages that could arise from this
      information. It is also noted that no specific success or certain (test) results are guaranteed.
    </p>
    <p>
      Perseo guarantees an availability of the Test Platform and the Customer Area of 98% on average annually. However,
      it is noted that temporary interruptions in availability may occur due to maintenance work, for example. The
      aforementioned warranty does not apply if interruptions in availability of the platforms occur due to reasons
      beyond Perseo&apos;s control (e.g., power outages or interruptions in the public communication network).
    </p>
    <p>
      Perseo is liable to the customer for any claims for damages due to injury to life, body, or health, for all claims
      for damages under the Product Liability Act, for intentional and grossly negligent acts, to the extent Perseo has
      fraudulently concealed a defect or assumed a guarantee of condition within the meaning of §444 of the German Civil
      Code (BGB), and for the violation of essential contractual obligations in a manner that jeopardizes the purpose of
      the contract (so-called cardinal obligations).
    </p>
    <p>
      Otherwise, the liability of Perseo – regardless of the legal basis – is excluded. If Perseo is liable for the
      violation of cardinal obligations without intent or gross negligence, Perseo&apos;s liability is limited to the
      extent of damages that could typically be expected at the time of contract conclusion under the circumstances then
      known.
    </p>
    <h3>11. Reservation of Changes</h3>
    <p>
      Perseo is entitled to make changes to these general terms and conditions and other conditions. Perseo will only
      make these changes for compelling reasons, particularly due to new technical developments, changes in case law, or
      other equivalent reasons. If the change significantly disrupts the contractual balance between the parties, the
      change will not be implemented. Otherwise, changes require the customer&apos;s consent.
    </p>
    <h3>12. Miscellaneous</h3>
    <p style={{ margin: 0 }}>
      German law exclusively applies. The place of jurisdiction for all legal disputes arising from the contractual
      relationship between the parties is Düsseldorf, Germany.
    </p>
  </div>
)

export const AgbLink = () => {
  const t = useTranslate()
  return <span>{t('termsAndConditionsLink', <AgbButton />)}</span>
}

const AgbButton = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  return (
    <button
      className="textlink"
      onClick={(e) => {
        e.preventDefault()
        context.setModalProps({
          headline: 'termsAndConditions',
          content: <AGB showInModal />,
          showCloseButton: true,
          hideFooter: true
        })
        context.setModalOpen(true)
      }}>
      {t('termsAndConditions')}
    </button>
  )
}
