import { useState, useEffect } from 'react'

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

const useCurrentRect = () => {
  const [width, setWidth] = useState(getWidth())
  const [height, setHeight] = useState(getHeight())

  const EFFECT_FIRE_INTERVALL_MS = 150

  useEffect(() => {
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setWidth(getWidth())
        setHeight(getHeight())
      }, EFFECT_FIRE_INTERVALL_MS)
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return {
    width: width,
    height: height
  }
}

export default useCurrentRect
