import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { TileContainer } from '../../../components/table/tableElements'
import AddToTestCheckInputTile from '../../../components/tiles/AddToTestCheckInputTile'
import BillingAddressTile from '../../../components/tiles/BillingAddressTile'
import BillingHintTile from '../../../components/tiles/BillingHintTile'
import SelectedParticipantsTile from '../../../components/tiles/SelectedParticipantsTile'
import { AppContext } from '../../../utils/context/AppContext'
import { PageHeadline } from '../../../utils/elements/miscElements'
import { getMaximumCreditSum } from '../../../utils/helper/Helper'
import useAddParticipantSubmitButtonStatus from '../../../utils/hooks/useAddParticipantSubmitButtonStatus'
import useTranslate from '../../../utils/hooks/useTranslate'

const AddToTestCheckInputPage = ({
  data,
  selectedParticipants,
  billingDataValid,
  setBillingDataValid,
  setSubmitButtonDisabled
}) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const billingData = context.completeDataSet.billing
  const assessments = context.completeDataSet.assessments
  const filteredAssessments = assessments.filter((ass) => data.assessmentUuids.includes(ass.assessmentUuid))
  const maximumCreditSum = getMaximumCreditSum(filteredAssessments, context, data.participantList.length)
  const showBillingTiles = maximumCreditSum > 0

  useAddParticipantSubmitButtonStatus(billingData, setBillingDataValid, maximumCreditSum, setSubmitButtonDisabled)

  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer style={{ padding: 0 }}>
          <AddToTestCheckInputTile
            {...{
              data,
              selectedParticipants,
              maximumCreditSum
            }}
          />
          <SelectedParticipantsTile {...{ selectedParticipants }} />
          {showBillingTiles > 0 && (
            <>
              <BillingAddressTile
                billingData={billingData}
                billingDataValid={billingDataValid}
                setBillingDataValid={setBillingDataValid}
              />
              <BillingHintTile />
            </>
          )}
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default AddToTestCheckInputPage
