import React, { useContext, useMemo } from 'react'
import DataTable from '../../components/table/DataTable'
import { DashboardNotice, getParticipantNumberCol, getTestAndProcessCol } from './dashboardUtils'
import ProgressBar from '../../components/progressBar/ProgressBar'
import { AppContext } from '../../utils/context/AppContext'

const LiveDataTable = () => {
  const context = useContext(AppContext)
  const liveData = {
    tableData: getLiveTableData(context),
    columns: useMemo(() => getLiveDataCols(), [])
  }

  const showLiveData = liveData.tableData.length > 0

  return (
    <>
      {showLiveData ? (
        <DataTable
          data={liveData.tableData}
          columns={liveData.columns}
          showRowSelection={false}
          tableName="liveData"
          hideArchiveButton
          hideResultCount
        />
      ) : (
        <DashboardNotice offset={64} text="noActiveTests" />
      )}
    </>
  )
}

export default LiveDataTable

const getLiveTableData = (context) => {
  const activePts = context.completeDataSet.pts.filter((pt) => pt.active && pt.ptProgress < 100)
  return activePts.sort((a, b) => compareStrings(a.pNr, b.pNr))
}

const getLiveDataCols = () => [
  getParticipantNumberCol(),
  getTestAndProcessCol(),
  {
    Header: 'progress',
    accessor: 'progress',
    disableSortBy: true,
    Cell: ({ row }) => <ProgressBar progressPercent={row.original.ptProgress} options={{ showProgressValue: true }} />
  }
]

const compareStrings = (string1, string2) => {
  if (string1 < string2) {
    return -1
  }
  if (string1 > string2) {
    return 1
  }
  return 0
}
