import styled from 'styled-components'

export const Tab = styled.button`
  font-size: var(--fs-3);
  padding: var(--space-3);
  border-radius: var(--bdr-2);
  user-select: none;
  background-color: transparent;
  font-weight: 500;
  color: var(--tab-text-color);
  &:not(:last-child) {
    margin-right: var(--space-5);
  }
  &:not(.active):hover {
    background-color: var(--tab-hover-color);
  }
  &.active {
    color: var(--tab-active-text-color);
    background-color: var(--tab-active-bg-color);
  }
`

export const TabContent = styled.div`
  display: flex;
  margin-top: 0;
  position: relative;
`

export const TabContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: var(--space-6);
  gap: var(--space-4) 0;
`

export const TabLabel = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: var(--lh-2);
`
