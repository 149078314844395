import React, { useContext } from 'react'
import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import { ContentContainer } from '../components/table/tableElements'
import { AppContext } from '../utils/context/AppContext'
import { PageHeadline } from '../utils/elements/miscElements'

const Privacy = ({ showInModal }) => (
  <>
    {showInModal ? (
      <Content showInModal={showInModal} />
    ) : (
      <ScrollContainer type="main">
        <Content showInModal={showInModal} />
      </ScrollContainer>
    )}
  </>
)

export default Privacy

const Content = ({ showInModal }) => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') return <ContentEnglish {...{ showInModal }} />
  return <ContentGerman {...{ showInModal }} />
}
const ContentGerman = ({ showInModal }) => (
  <ContentContainer className="legal" style={{ maxWidth: '600px', margin: showInModal ? 0 : '', padding: 0 }}>
    {!showInModal && <PageHeadline>Datenschutz</PageHeadline>}
    <h3 style={{ marginTop: 0 }}>Verantwortlicher nach Art. 4 DS-GVO</h3>
    <p>
      PERSEO verarbeitet alle Daten, die in Rahmen von Auswahlverfahren erhoben werden, als Auftragsverarbeiter nach
      Art. 28 DS-GVO.
    </p>
    <p>
      Verantwortlicher nach §4 DS-GVO ist immer das Unternehmen / die Behörde, die das Verfahren in Auftrag gegeben hat.
      Ihre Betroffenenrechte, wie z.B. Auskunft, Berichtigung oder Löschung gespeicherter Daten, können daher
      ausschließlich über den Auftraggeber wahrgenommen werden.
    </p>

    <h3>Recht auf Beschwerde</h3>
    <p>
      Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige
      Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung.
      Hier können Sie eine{' '}
      <a
        className="textlink"
        rel="noreferrer"
        target="_blank"
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
      >
        Liste der Aufsichtsbehörden
      </a>{' '}
      (für den nichtöffentlichen Bereich) mit Anschrift einsehen.
    </p>

    <h3>Datensicherheit</h3>
    <p>
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik
      entsprechende Verschlüsselungsverfahren (SSL) über HTTPS. Alle Daten werden in einem nach ISO/IEC 27001:2013
      zertifiziertem Rechenzentrum in Deutschland gespeichert.
    </p>

    <h3>Kontaktformular</h3>
    <p>
      Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt, erteilen Sie uns zum
      Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen E-Mail-Adresse
      erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe
      weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie
      für mögliche Anschlussfragen gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene
      Daten gelöscht.
    </p>

    <h3>Verwendung von Scriptbibliotheken (Google Webfonts)</h3>
    <p>
      Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser
      Website Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts. Google Webfonts werden zur
      Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht
      unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.
    </p>
    <p>
      Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der
      Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken –
      dass Betreiber entsprechender Bibliotheken Daten erheben.
    </p>
    <p style={{ margin: 0 }}>
      Hier können Sie die{' '}
      <a className="textlink" rel="noreferrer" target="_blank" href="https://www.google.com/policies/privacy/">
        Datenschutzrichtlinie des Bibliothekbetreibers Google
      </a>{' '}
      einsehen.
    </p>
  </ContentContainer>
)

const ContentEnglish = ({ showInModal }) => (
  <ContentContainer className="legal" style={{ maxWidth: '600px', margin: showInModal ? 0 : '', padding: 0 }}>
    {!showInModal && <PageHeadline>Privacy</PageHeadline>}
    <h3 style={{ marginTop: 0 }}>Data Controller according to Art. 4 GDPR</h3>
    <p>
      PERSEO processes all data collected within the scope of selection procedures as a data processor according to Art.
      28 GDPR.
    </p>
    <p>
      The data controller according to §4 GDPR is always the company / authority that commissioned the procedure. Your
      rights as a data subject, such as access, rectification, or deletion of stored data, can only be exercised through
      the data controller.
    </p>

    <h3>Right to Lodge a Complaint</h3>
    <p>
      You have the right to lodge a complaint with the supervisory authority responsible for you at any time. The
      supervisory authority responsible for you is determined by the federal state of your residence, your work, or the
      suspected violation. Here you can find a{' '}
      <a
        className="textlink"
        rel="noreferrer"
        target="_blank"
        href="https://www.bfdi.bund.de/EN/Infothek/Anschriften_Links/anschriften_links-node.html"
      >
        list of supervisory authorities
      </a>{' '}
      (for the non-public sector) with addresses.
    </p>

    <h3>Data Security</h3>
    <p>
      To protect the security of your data during transmission, we use encryption methods (SSL) via HTTPS that
      correspond to the current state of the art. All data is stored in a data center in Germany that is certified
      according to ISO/IEC 27001:2013.
    </p>

    <h3>Contact Form</h3>
    <p>
      If you contact us via email or contact form regarding any questions, you voluntarily provide us with your consent
      for the purpose of contacting you. Providing a valid email address is required for this purpose. This serves to
      allocate the request and subsequently answer it. Providing additional data is optional. The information you
      provide will be stored for the purpose of processing the inquiry and possible follow-up questions. After
      completing the inquiry, personal data will be deleted.
    </p>

    <h3>Use of Script Libraries (Google Webfonts)</h3>
    <p>
      To display our content correctly and attractively across browsers, we use script libraries and font libraries on
      this website, such as Google Webfonts. Google Webfonts are transferred to your browser&apos;s cache to prevent multiple
      loads. If the browser does not support or blocks Google Webfonts, content will be displayed in a default font.
    </p>
    <p>
      Calling script libraries or font libraries automatically establishes a connection to the operator of the library.
      In theory, it is possible – currently unclear whether and for what purposes – that operators of such libraries
      collect data.
    </p>
    <p style={{ margin: 0 }}>
      You can view the{' '}
      <a className="textlink" rel="noreferrer" target="_blank" href="https://www.google.com/policies/privacy/">
        privacy policy of the library operator Google
      </a>{' '}
      here.
    </p>
  </ContentContainer>
)
