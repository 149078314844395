import styled from 'styled-components'

export const LinkContainer = styled.div`
  display: flex;
  padding: 0;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    max-width: 400px;
  }
`

export const LinkHeadline = styled.div`
  font-size: var(--fs-5);
  display: flex;
  align-items: center;
`

export const LinkDescription = styled.div`
  font-size: var(--fs-3);
  margin-bottom: var(--space-5);
  line-height: var(--lh-3);
`

export const WizardTileContainer = styled.div`
  background-color: var(--header-bg-color);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--space-5);
  align-items: flex-start;
  flex-basis: 280px;
  flex-shrink: 0;
  border-radius: var(--bdr-3);
  box-shadow: var(--sh-1);
  @media screen and (max-width: 1100px) {
    flex-basis: unset;
  }
`

export const TopContainer = styled.div`
  border-bottom: 1px solid var(--main-menu-spacer-border-color);
  box-shadow: 0 1px 0 0 var(--main-menu-spacer-shadow-color);
  width: 100%;
`

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
  border-left: 24px solid var(--wizard-arrow-bg-color);
  margin-top: 30px;
  margin-left: var(--space-5);
  margin-right: var(--space-5);
  @media screen and (max-width: 1100px) {
    transform: rotate(90deg);
    margin: -8px auto;
  }
`

export const DetailsContainer = styled.ul`
  padding-left: var(--space-5);
  margin: var(--space-5) 0;
`

export const DetailElement = styled.li`
  font-size: var(--fs-3);
  line-height: var(--lh-3);
  color: var(--text-color-secondary);
  &:not(:last-child) {
    margin-bottom: var(--space-5);
    @media screen and (max-width: 1100px) {
      margin-bottom: 0;
    }
  }
`
