import { Legend, DataLabel, Circle } from '../charts/PieDiagram'
import { isPotentials, isPotentialsCrossTest } from '../../utils/helper/Helper'
import styled from 'styled-components'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { DEFAULT_LANGUAGE } from '../../utils/constants/constants'
import { getPieLabelColor } from '../charts/chartUtils'

const ModuleOverview = ({ config }) => {
  const context = useContext(AppContext)
  const locale = context.language
  const showModules = isPotentials(config.configType) || isPotentialsCrossTest(config.configType)

  return (
    <Legend {...{ showModules }}>
      {getDimensionData(config.dimensions, locale).map((dimension, i) => (
        <DataLabel key={dimension.label}>
          <CircleLabelContainer>
            <Circle color={getPieLabelColor(config.dimensions.length, i)} />
            <DimLabel>{dimension.label}</DimLabel>
          </CircleLabelContainer>
          {showModules && (
            <ul style={{ paddingLeft: 'var(--space-5)', margin: 0 }}>
              {dimension.subtests.map((subtest, i) => {
                if (subtest) {
                  return (
                    <SubtestListItem key={subtest + i} className="subtest text-secondary">
                      {subtest}
                    </SubtestListItem>
                  )
                } else {
                  return ''
                }
              })}
            </ul>
          )}
        </DataLabel>
      ))}
    </Legend>
  )
}

export default ModuleOverview

const CircleLabelContainer = styled.div`
  display: grid;
  gap: var(--space-3);
  grid-template-columns: auto 1fr;
  align-items: baseline;
`

export const DimLabel = styled.span`
  line-height: var(--lh-2);
`

const SubtestListItem = styled.li`
  list-style-type: none;
`

export const getDimensionData = (dimensions, locale) => {
  const arr = []
  const visibleDimensions = dimensions.filter((dim) => dim.dimensionVisibility)

  visibleDimensions.map((dim) => {
    const dimensionName = dim.dimensionName[locale] || dim.dimensionName[DEFAULT_LANGUAGE]
    return arr.push({
      id: dimensionName,
      label: dim.dimensionAlias ? dim.dimensionAlias : dimensionName,
      value: parseFloat(parseFloat(dim.dimensionWeight).toFixed(2)),
      subtests: dim.subtests.map((subtest) => subtest[locale] || subtest[DEFAULT_LANGUAGE])
    })
  })

  return arr
}
