import CustomInput from '../customInput/CustomInput'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import { ANONYM_MSG, API_DATA, FIELD_LABELS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { SmallNotice } from '../../utils/elements/miscElements'
import { getOptionByValue } from '../../utils/helper/Helper'
import { CustomInputList, Tile, TileHeadline } from '../table/tableElements'
import useTranslate from '../../utils/hooks/useTranslate'

const ParticipantInfosTile = ({ participant }) => {
  const t = useTranslate()
  const canEdit = CapabilitiesHelper.canEditParticipantInfos(participant)
  const participantDisabledNotice = getParticipantDisabledNotice(participant)

  return (
    <Tile width="200px">
      <TileHeadline content="personalData" />
      <CustomInputList>
        <CustomInput
          label={FIELD_LABELS.lastName}
          content={participant.pAnon ? t(ANONYM_MSG) : participant.pLastName}
          dataKey="pLastName"
          type="input"
          idValue={participant.pNr}
          apiData={API_DATA.participants}
          disabled={!canEdit}
        />

        <CustomInput
          label={FIELD_LABELS.firstName}
          content={participant.pAnon ? t(ANONYM_MSG) : participant.pFirstName}
          dataKey="pFirstName"
          type="input"
          idValue={participant.pNr}
          apiData={API_DATA.participants}
          disabled={!canEdit}
        />

        <CustomInput
          label={FIELD_LABELS.email}
          content={participant.pAnon ? t(ANONYM_MSG) : participant.pMail}
          dataKey="pMail"
          type="email"
          idValue={participant.pNr}
          apiData={API_DATA.participants}
          disabled={!canEdit}
        />

        <CustomInput
          label={FIELD_LABELS.gender}
          content={
            participant.pAnon ? t(ANONYM_MSG) : getOptionByValue(SELECT_OPTIONS.gender, participant.pGender).label || ''
          }
          value={participant.pGender}
          dataKey="pGender"
          type="select"
          options={SELECT_OPTIONS.gender}
          idValue={participant.pNr}
          apiData={API_DATA.participants}
          disabled={!canEdit}
          isClearable
        />
      </CustomInputList>

      {participantDisabledNotice && <SmallNotice>{t(participantDisabledNotice)}</SmallNotice>}
    </Tile>
  )
}

export default ParticipantInfosTile

const getParticipantDisabledNotice = (participant) => {
  if (participant.isArchived()) return 'personalDataCannotBeEditedInArchive'
  if (participant.pAnon) return 'personalDataCannotBeEditedAnonymizedParticipant'
  return null
}
