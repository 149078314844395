import React, { useState, useContext } from 'react'
import { FormWrapper, Form, FieldWrapper, Label, SmallLink } from '../../../utils/elements/FormElements'
import { ButtonPrimary, ErrorMessage } from '../../../utils/elements/miscElements'
import { fetchData } from '../../../utils/helper/Helper'
import { AppContext } from '../../../utils/context/AppContext'
import { FIELD_LABELS } from '../../../utils/constants/constants'
import { PAGES } from '../../../utils/constants/pages'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getUrlParam } from '../../../utils/helper/urlParamsHelper'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import useEmailValidation from '../../../utils/hooks/useEmailValidation'
import usePageUrl from '../../../utils/hooks/usePageUrl'

const RestorePassword = () => {
  const t = useTranslate()
  const location = useLocation()
  const { mail } = getUrlParam(location) || ''

  const [showSuccessScreen, setShowSuccessScreen] = useState(false)

  const { email, showMailError, handleChange, mailError, setShowMailError, handleBlur } = useEmailValidation(mail)
  const context = useContext(AppContext)
  const getUrlFromPage = usePageUrl()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (mailError) {
      setShowMailError(true)
      document.getElementById('email').focus()
      return
    }

    const payload = { email_or_login: email }

    try {
      await fetchData(payload, 'password_recovery', context, null, false)
      setShowSuccessScreen(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <FormWrapper>
      <h1>{t('forgotPassword')}</h1>
      {!showSuccessScreen && (
        <>
          <p>{t('resetPasswordInstructions')}</p>
          <Form onSubmit={handleSubmit} noValidate>
            <FieldWrapper>
              <Label>{t(FIELD_LABELS.email)}</Label>
              <input
                id="email"
                type="email"
                value={email}
                disabled={context.maintenanceMode}
                onChange={handleChange}
                className={showMailError ? 'has-error' : ''}
                onBlur={handleBlur}
              />
              <ErrorMessage visible={showMailError}>{t(mailError?.errorMsg)}</ErrorMessage>
            </FieldWrapper>

            <ButtonPrimary position="login" type="submit" content="sendMail" disabled={context.maintenanceMode} />
            <SmallLink to={getUrlFromPage(PAGES.login)}>
              <span>{t('backToLogin')}</span>
            </SmallLink>
          </Form>
        </>
      )}
      {showSuccessScreen && (
        <>
          <p>{t('sentMailTo', email)}</p>
          <SmallLink to={getUrlFromPage(PAGES.login)} style={{ marginTop: 'var(--space-5)' }}>
            <span>{t('backToLogin')}</span>
          </SmallLink>
        </>
      )}
    </FormWrapper>
  )
}

export default RestorePassword
