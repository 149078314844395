import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import {
  PageHeadline,
  SuccessMessageHeadline,
  SuccessMessageText,
  SuccessMessageIcon,
  SuccessMessageContainer
} from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'

const AddProcessSuccessPage = () => {
  const t = useTranslate()
  return (
    <>
      <PageHeadline style={{ visibility: 'hidden' }}>{t('processSuccessfullyCreated')}</PageHeadline>
      <ScrollContainer>
        <SuccessMessageContainer>
          <SuccessMessageHeadline>{t('processSuccessfullyCreated')}</SuccessMessageHeadline>
          <SuccessMessageText>{t('addTestNow')}</SuccessMessageText>
          <SuccessMessageIcon className="svg-icon icon-check-noborder success-page" />
        </SuccessMessageContainer>
      </ScrollContainer>
    </>
  )
}

export default AddProcessSuccessPage
