import { fetchData } from '../../utils/helper/Helper'
import { getParamsJson } from '../../utils/helper/urlParamsHelper'
import { addParticipantsAndPts } from '../addParticipantWizard/addParticipantUtils'

export const addParticipantsToTest = async (data, context) => {
  const payload = {
    assessmentUuids: data.assessmentUuids,
    participantList: data.participantList
  }

  const responseData = await fetchData(payload, 'add_p_to_test', context, 'participantsNotAdded')

  try {
    return addParticipantsAndPts(responseData, context)
  } catch (e) {
    console.error(e)
  }
}

export const getSelectedParticipants = (participants, location) => {
  const urlParams = getParamsJson(location)
  const participantIds = urlParams.find((param) => param.id === 'pid')?.value || []
  return participants.filter((participant) => participantIds.includes(participant.pNr))
}
