import { ResponsiveBar } from '@nivo/bar'

const theme = {
  fontFamily: 'Open Sans',
  axis: {
    ticks: {
      text: {
        fill: 'var(--text-color-primary)'
      }
    },
    domain: {
      line: {
        stroke: 'var(--text-color-primary)',
        strokeWidth: 1
      }
    }
  },
  grid: {
    line: {
      stroke: 'var(--bar-chart-grid-color)'
    }
  }
}

const getBarColor = (bar) => {
  switch (bar.data.matchingPoints) {
    case '2':
      return 'var(--bar-chart-match-2-color)'
    case '1':
      return 'var(--bar-chart-match-1-color)'
    case '0':
      return 'var(--bar-chart-match-0-color)'
    default:
      return 'var(--bar-chart-default-color)'
  }
}

const getLabel = (bar, isResultPreview) => {
  if (bar.value < 3 && isResultPreview) {
    return (
      <tspan fill="var(--text-color-primary)" x="10">
        {bar.value}
      </tspan>
    )
  }
  return bar.value
}

const getLegend = (keys) => {
  if (keys.length === 1) return []
  return [
    {
      dataFrom: 'keys',
      anchor: 'bottom',
      direction: 'row',
      justify: false,
      translateX: 0,
      translateY: 48,
      itemsSpacing: 2,
      itemWidth: 80,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 12,
      symbolShape: 'circle'
    }
  ]
}

const BarDiagram = ({
  data,
  layout,
  enableGridX,
  enableGridY,
  maxValue,
  axisLeftFormat,
  margin,
  useDefaultColors,
  keys = ['value'],
  isResultPreview
}) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy="group"
    margin={margin}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    valueFormat={{ format: '', enabled: false }}
    colors={useDefaultColors ? ['var(--bar-chart-default-color)', 'var(--bar-chart-secondary-color)'] : (bar) => getBarColor(bar)}
    theme={theme}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    borderRadius={2}
    axisTop={null}
    axisRight={null}
    layout={layout}
    groupMode="grouped"
    innerPadding={2}
    layers={['grid', 'bars', 'axes', 'legends']}
    maxValue={maxValue || 'auto'}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: 40
    }}
    gridYValues={5}
    axisLeft={{
      tickSize: 10,
      tickPadding: 5,
      tickRotation: 0,
      legendPosition: 'middle',
      legendOffset: -40,
      tickValues: 5,
      format: axisLeftFormat
    }}
    label={(bar) => getLabel(bar, isResultPreview)}
    labelSkipWidth={0}
    labelSkipHeight={12}
    labelTextColor="#fff"
    enableGridX={enableGridX}
    enableGridY={enableGridY}
    isInteractive={false}
    legends={getLegend(keys)}
  />
)

export default BarDiagram
