import * as QueryString from 'query-string'

const getCleanParams = (params) => {
  params.forEach((param, i) => {
    if (Array.isArray(param.value)) {
      const values = param.value

      const subArray = values.map((value) => ({
        id: param.id,
        value: value
      }))

      params.splice(i, 1)
      params = params.concat(subArray)
    }
  })

  return params
}

export const getParamsJson = (location) => {
  const params = QueryString.parse(location.search)
  const paramsArray = Object.entries(params)

  const paramsJson = []
  paramsArray.forEach((param) => {
    paramsJson.push({
      id: param[0],
      value: param[1]
    })
  })

  return paramsJson
}

export const getUrlParam = (location) => {
  return QueryString.parse(location.search)
}

export const updateUrlParams = (urlParams, changedParams) => {
  let returnParams = urlParams

  changedParams.forEach((changedParam) => {
    const duplicateParam = returnParams.find((returnParam) => returnParam.id === changedParam.id)

    if (duplicateParam) {
      const uniqueUrlParams = returnParams.filter((param) => param.id !== duplicateParam.id)

      if (changedParam.value !== '') {
        returnParams = uniqueUrlParams.concat(changedParam)
      } else {
        returnParams = uniqueUrlParams
      }
    } else {
      if (changedParam.value !== '') {
        returnParams = returnParams.concat(changedParam)
      }
    }
  })

  return returnParams
}

export const buildUrlParamString = (urlParams) => {
  if (!urlParams) return ''

  let string = '?'
  const cleanParams = getCleanParams(urlParams)
  cleanParams.forEach((param, i) => {
    if (i > 0) string += '&'
    string += `${param.id}=${param.value}`
  })

  return string
}
