import styled from 'styled-components'
import { useEffect } from 'react'

const ScrollContainer = (props) => {
  useEffect(() => {
    const scrollContainer = document.getElementsByClassName('scroll-container')[0]
    if (scrollContainer) {
      scrollContainer.scrollTo(0, 0)
    }
  }, [])
  const stretch = props.type === 'settings' || props.type === 'bulk-edit-sidebar'
  return (
    <ScrollContainerDiv className="scroll-container" stretch={stretch} {...props}>
      {props.children}
    </ScrollContainerDiv>
  )
}

export default ScrollContainer

const ScrollContainerDiv = styled.div`
  overflow: auto;
  padding-bottom: var(--space-8);
  margin-bottom: ${(props) => props.marginBottom || 0};
  width: 100%;
  height: ${(props) => (props.stretch ? '100%' : '')};
  &:after {
    content: '';
    position: absolute;
    bottom: ${(props) => props.fadingEdgeBottomOffset || 0};
    left: 0;
    right: 0;
    background: ${(props) => getBackground(props.type)};
    height: 40px;
    z-index: 99;
    pointer-events: none;
  }
`

const getBackground = (type) => {
  if (type === 'bulk-edit-sidebar') {
    return 'linear-gradient(to bottom, #e7e7e700, var(--box-background))'
  }
  if (type === 'settings') {
    return 'linear-gradient(to bottom, #f3f5f600, var(--header-bg-color))'
  }
  if (type === 'modal') {
    return 'linear-gradient(to bottom, #f3f5f600, var(--modal-container-bg-color))'
  }
  return 'linear-gradient(to bottom, #fafafa00, var(--main-bg-color))'
}
