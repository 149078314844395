import React, { useContext, useMemo } from 'react'
import DataTable from '../../components/table/DataTable'
import ParticipantRowSubComponent from './ParticipantRowSubComponent'
import { ContentContainer } from '../../components/table/tableElements'
import { AppContext } from '../../utils/context/AppContext'
import { getParticipantTableCols } from './participantUtils'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { ENTITIES, TEST_TYPE_POTENTIALS_CROSS } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'

const Participants = () => {
  const { completeDataSet, language } = useContext(AppContext)
  const t = useTranslate()
  const customFieldDefinitions = completeDataSet.customFieldDefinitions
  const tableSettings = completeDataSet.customTableSettings.participants
  const usingCrosstests = completeDataSet.assessments.some(
    (ass) => ass.relatedConfig.configType === TEST_TYPE_POTENTIALS_CROSS
  )

  const columns = useMemo(
    () => getParticipantTableCols(customFieldDefinitions, language, usingCrosstests, t),
    [customFieldDefinitions, tableSettings, language]
  )

  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <DataTable
          data={completeDataSet.participants}
          columns={columns}
          RowSubComponent={ParticipantRowSubComponent}
          showRowSelection
          showFilters
          tableName={ENTITIES.participants}
          hasSubrows
          selectedRows={{}}
          showAddButton
          showColumnController
        />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Participants
