// color definitions copied from vars.scss because nivo has problems with css vars
const colorDefs = {
  green700: 'hsl(59, 65%, 19%)',
  green600: 'hsl(59, 65%, 24%)',
  green500: 'hsl(59, 65%, 29%)',
  green400matt: 'hsl(59, 50%, 44%)',
  green200matt: 'hsl(59, 40%, 74%)',
  primary600: 'hsl(270, 13%, 35%)',
  primary500: 'hsl(270, 13%, 40%)',
  primary400: 'hsl(270, 13%, 54%)',
  primary300: 'hsl(270, 13%, 67%)',
  primary200: 'hsl(270, 16%, 81%)',
  primary150: 'hsl(270, 19%, 87%)',
  primary100: 'hsl(270, 21%, 95%)'
}

export const pieChartColors = {
  colors2: [colorDefs.green500, colorDefs.primary500],
  colors4: [colorDefs.green600, colorDefs.green400matt, colorDefs.primary500, colorDefs.primary300],
  colors6: [
    colorDefs.green600,
    colorDefs.green500,
    colorDefs.green400matt,
    colorDefs.primary500,
    colorDefs.primary400,
    colorDefs.primary300
  ],
  colors8: [
    colorDefs.green600,
    colorDefs.green500,
    colorDefs.green400matt,
    colorDefs.green200matt,
    colorDefs.primary500,
    colorDefs.primary400,
    colorDefs.primary300,
    colorDefs.primary200
  ],
  colors12: [
    colorDefs.green700,
    colorDefs.green600,
    colorDefs.green500,
    colorDefs.green400matt,
    colorDefs.green200matt,
    colorDefs.primary600,
    colorDefs.primary500,
    colorDefs.primary400,
    colorDefs.primary300,
    colorDefs.primary200,
    colorDefs.primary150,
    colorDefs.primary100
  ]
}

export const getPieLabelColor = (length, i) => {
  const MAX_COLORS = pieChartColors.colors12.length
  if (length <= 2) {
    return pieChartColors.colors2[i]
  }
  if (length <= 4) {
    return pieChartColors.colors4[i]
  }
  if (length <= 6) {
    return pieChartColors.colors6[i]
  }
  if (length <= 8) {
    return pieChartColors.colors8[i]
  }
  return pieChartColors.colors12[i % MAX_COLORS]
}
