import { useContext, useRef, useState } from 'react'
import { EMAIL_NOTIFICATION_OPTIONS } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import { getLoggedInContact } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { CustomTableCheckbox } from '../../table/tableElements'
import { ListContainer, ListElement, Label } from './notificationSettingsElements'
import { saveData } from './notificationSettingsUtils'

const EmailNotifications = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contact = getLoggedInContact(context.completeDataSet)
  const submitButtonRef = useRef(null)
  const [notificationOption, setNotificationOption] = useState(contact?.notificationStatus || 2)

  return (
    <div>
      <p>{t('emailNotificationSettings', contact.contactEmail)}</p>
      <ListContainer>
        {EMAIL_NOTIFICATION_OPTIONS.map((option) => {
          const isChecked = option.id === notificationOption
          const className = isChecked ? 'svg-icon icon-circle' : ''
          return (
            <ListElement key={option.label}>
              <Label onClick={() => setNotificationOption(option.id)}>
                <CustomTableCheckbox
                  type="radio"
                  style={{ justifySelf: 'left', transform: 'none' }}
                  checked={isChecked}
                  onKeyPress={() => setNotificationOption(option.id)}
                  className={'custom-checkbox ' + className}
                />
                <span>{t(option.label)}</span>
              </Label>
            </ListElement>
          )
        })}
      </ListContainer>
      <ButtonPrimary
        ref={submitButtonRef}
        style={{ marginTop: 'var(--space-6)', marginBottom: 'var(--space-7)' }}
        content="save"
        onClick={() => saveData({ key: 'notificationStatus', value: notificationOption }, context)}
      />
    </div>
  )
}

export default EmailNotifications
