import React, { useContext } from 'react'
import { ContentContainer } from '../../components/table/tableElements'
import LiveDataTable from './LiveDataTable'
import NewResultsTable from './NewResultsTable'
import SystemInfosOverview from './SystemInfosOverview'
import FinishedPtsOverview from './FinishedPtsOverview'
import CreditsOverview from './CreditsOverview'
import {
  DashboardItem,
  DashboardHeadline,
  DashboardItemsContainer,
  HeadlineButtonContainer,
  OpenFullScreenButton,
  SubContainer
} from './dashboardUtils'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { AppContext } from '../../utils/context/AppContext'
import { PAGES } from '../../utils/constants/pages'
import { PageHeadline } from '../../utils/elements/miscElements'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'

const Dashboard = () => {
  const t = useTranslate()
  const redirect = useRedirect()
  const context = useContext(AppContext)
  const liveDataCount = context.completeDataSet.pts.filter((pt) => pt.active).length
  const liveDataHeadline = (
    <>
      <span>{t('liveData')}</span>
      {liveDataCount > 0 && <span style={{ fontWeight: 'var(--fw-1)' }}> ({liveDataCount})</span>}
    </>
  )

  return (
    <ScrollContainer type="main">
      <ContentContainer className="dashboard">
        <PageHeadline className="mobile-only">Dashboard</PageHeadline>
        <DashboardItemsContainer>
          <DashboardItem style={{ height: '348px', overflowY: 'hidden' }}>
            <DashboardHeadline>{t('newResults')}</DashboardHeadline>
            <NewResultsTable />
          </DashboardItem>
          <SubContainer>
            <DashboardItem>
              <DashboardHeadline>{t('balance')}</DashboardHeadline>
              <CreditsOverview />
            </DashboardItem>
            <DashboardItem style={{ overflow: 'hidden' }}>
              <DashboardHeadline>System</DashboardHeadline>
              <SystemInfosOverview />
            </DashboardItem>
          </SubContainer>
          <DashboardItem className="liveData" style={{ height: '290px' }}>
            <HeadlineButtonContainer>
              <DashboardHeadline>{liveDataHeadline}</DashboardHeadline>
              <OpenFullScreenButton onClick={() => redirect(PAGES.liveData)}>{t('enlargedView')}</OpenFullScreenButton>
            </HeadlineButtonContainer>
            <LiveDataTable />
          </DashboardItem>
          <DashboardItem style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <DashboardHeadline>{t('testsRun')}</DashboardHeadline>
            <FinishedPtsOverview />
          </DashboardItem>
        </DashboardItemsContainer>
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Dashboard
