import { useContext } from 'react'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AppContext } from '../../../utils/context/AppContext'
import { getLoggedInContact } from '../../../utils/helper/Helper'
import { DEFAULT_NORM_NR, NEW_NORM_NR } from '../../../utils/constants/constants'

const useNormsReducer = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contact = getLoggedInContact(context.completeDataSet)

  const EMPTY_NORM = {
    name: t('newRule'),
    normNr: NEW_NORM_NR,
    createdBy: contact.contactEmail,
    created: new Date(),
    normTable: [
      {
        lower_limit: 0,
        upper_limit: 50,
        grade: '',
        verbalisation: ''
      },
      {
        lower_limit: 50,
        upper_limit: 100,
        grade: '',
        verbalisation: ''
      }
    ]
  }

  function normsReducer(data, action) {
    const currentNorm = data.norms.find((n) => n.normNr === data.selectedNormNr)

    switch (action.type) {
      case 'added_row': {
        const insertAt = action.index + 1
        const updatedTable = [
          ...currentNorm.normTable.slice(0, insertAt),
          {
            lower_limit: currentNorm.normTable[action.index].upper_limit,
            upper_limit: '',
            grade: '',
            verbalisation: '',
            upperTooLow: true
          },
          ...currentNorm.normTable.slice(insertAt)
        ]

        const updatedNorms = data.norms.map((norm) => {
          if (norm.normNr === data.selectedNormNr) {
            return { ...norm, normTable: updatedTable }
          } else {
            return norm
          }
        })

        updatedNorms.find((n) => n.normNr === data.selectedNormNr).isDirty = true
        return { ...data, norms: updatedNorms }
      }

      case 'removed_row': {
        const updatedTable = currentNorm.normTable
          .map((row, i, orig) => {
            if (i === action.index + 1) {
              return { ...row, lower_limit: orig[action.index - 1].upper_limit }
            } else {
              return { ...row }
            }
          })
          .filter((_, i) => i !== action.index)

        const updatedNorms = data.norms.map((norm) => {
          if (norm.normNr === data.selectedNormNr) {
            return { ...norm, normTable: updatedTable }
          } else {
            return norm
          }
        })

        updatedNorms.find((n) => n.normNr === data.selectedNormNr).isDirty = true
        return { ...data, norms: updatedNorms }
      }

      case 'renamed_norm': {
        currentNorm.name = action.value
        currentNorm.isDirty = true
        return { ...data, renameFieldVisible: false, norms: [...data.norms] }
      }

      case 'updated_norms': {
        return {
          ...data,
          selectedNormNr: action.selectedNormNr,
          norms: action.norms
        }
      }

      case 'updated_table': {
        const { field, rowIndex } = action
        let { value } = action
        const MAX_INPUT = 90

        if (value < 0) {
          value = 0
        }

        if (field === 'upper_limit' && value > MAX_INPUT) {
          value = MAX_INPUT
        }

        if (field === 'upper_limit' && isNaN(value)) {
          value = ''
        }

        const updatedTable = currentNorm.normTable.map((row, i) => {
          if (field === 'upper_limit' && i === rowIndex + 1) {
            return { ...row, lower_limit: value }
          } else if (i === rowIndex) {
            return { ...row, [field]: value }
          } else {
            return { ...row }
          }
        })

        const validatedTable = updatedTable.map((row, i) => {
          const upperTooLow = i > 0 && updatedTable[i].upper_limit <= updatedTable[i].lower_limit
          return { ...row, upperTooLow: upperTooLow }
        })

        const updatedNorms = data.norms.map((norm) => {
          if (norm.normNr === data.selectedNormNr) {
            return { ...norm, normTable: validatedTable }
          } else {
            return norm
          }
        })

        updatedNorms.find((n) => n.normNr === data.selectedNormNr).isDirty = true
        return { ...data, norms: updatedNorms }
      }

      case 'empty_norm_added': {
        return { ...data, selectedNormNr: EMPTY_NORM.normNr, norms: [...data.norms, EMPTY_NORM] }
      }

      case 'norm_nr_set': {
        return { ...data, selectedNormNr: action.value }
      }

      case 'toggle_rename_field': {
        return { ...data, renameFieldVisible: !data.renameFieldVisible }
      }

      case 'save_as_dialog_visibility_set': {
        return { ...data, saveAsDialogVisible: action.value }
      }

      case 'removed_norm': {
        const { normNr } = action
        return { ...data, selectedNormNr: DEFAULT_NORM_NR, norms: data.norms.filter((n) => n.normNr !== normNr) }
      }

      default: {
        throw Error('Unknown action: ' + action.type)
      }
    }
  }

  return normsReducer
}

export default useNormsReducer
