import Select from 'react-select'
import { formatOptionLabel, removeDuplicatesByProp } from './filterUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import { createFilter } from 'react-select/dist/react-select.cjs.prod'
import { getFilterStyles } from './filterStyles'
import { useContext, useState } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { ENTITIES, FIELD_LABELS, PARTICIPANT_SPECIAL_NAMES } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { FilterContext } from '../../utils/context/FilterContext'
import MenuList from '../menuList/MenuList'
import useRedirect from '../../utils/hooks/useRedirect'

const Globalfilter = () => {
  const { completeDataSet, showArchivedItems } = useContext(AppContext)
  const { setGlobalFilter } = useContext(FilterContext)
  const [value, setValue] = useState(null)
  const redirect = useRedirect()

  const t = useTranslate()
  const options = getOptions(completeDataSet).filter((option) => (showArchivedItems ? option : !option.isArchived))
  const styles = getFilterStyles({ position: 'globalFilter', size: 'var(--global-filter-width)' })

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setValue(null)
      setGlobalFilter({
        field: { value: selectedOption.id, label: selectedOption.fieldLabel || selectedOption.id },
        value: { value: selectedOption.value },
        tableName: selectedOption.table,
        id: 'globalFilter'
      })
      redirect(selectedOption.page)
    }
  }

  return (
    <Select
      styles={styles}
      components={options.length > 100 ? { MenuList } : ''}
      onChange={handleChange}
      filterOption={createFilter({ ignoreAccents: false })}
      options={t(options)}
      value={value}
      isClearable
      placeholder={t('search') + '…'}
      classNamePrefix="react-select"
      className="react-select"
      blurInputOnSelect
      noOptionsMessage={() => t('noResults')}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export default Globalfilter

const getOptions = (completeDataSet) => {
  const uniqueAssessmentNames = removeDuplicatesByProp(completeDataSet.assessments, 'assessmentName')
  const assessmentNames = uniqueAssessmentNames.map((assessment) => ({
    table: ENTITIES.assessments,
    id: 'assessmentName',
    value: assessment.assessmentName,
    label: <GlobalFilterLabel isArchived={assessment.isArchived()} prefix="Test" data={assessment.assessmentName} />,
    isArchived: assessment.isArchived(),
    page: PAGES.assessments
  }))

  const participantFullNames = completeDataSet.participants
    .map((participant) => {
      return {
        table: ENTITIES.participants,
        fieldLabel: 'participantName',
        id: 'pFullName',
        value: participant.pFullName,
        label: (
          <GlobalFilterLabel isArchived={participant.isArchived()} prefix="participant" data={participant.pFullName} />
        ),
        isArchived: participant.isArchived(),
        page: PAGES.participants
      }
    })
    .filter((item) => !PARTICIPANT_SPECIAL_NAMES.includes(item.value))

  const uniqueProcessNames = removeDuplicatesByProp(completeDataSet.processes, 'processName')
  const processNames = uniqueProcessNames.map((process) => ({
    table: ENTITIES.processes,
    id: 'processName',
    value: process.processName,
    label: <GlobalFilterLabel isArchived={process.isArchived()} prefix="process" data={process.processName} />,
    isArchived: process.isArchived(),
    page: PAGES.processes
  }))

  const participantNumbers = completeDataSet.participants.map((participant) => ({
    table: ENTITIES.participants,
    id: 'pNr',
    value: participant.pNr,
    fieldLabel: FIELD_LABELS.pNumber,
    label: (
      <GlobalFilterLabel isArchived={participant.isArchived()} prefix={FIELD_LABELS.pNumber} data={participant.pNr} />
    ),
    isArchived: participant.isArchived(),
    page: PAGES.participants
  }))

  const ptNumbers = completeDataSet.pts.map((pt) => ({
    table: ENTITIES.pts,
    id: 'ptNumber',
    value: pt.ptNumber,
    label: <GlobalFilterLabel isArchived={pt.isArchived()} prefix={FIELD_LABELS.ptNumber} data={pt.ptNumber} />,
    isArchived: pt.isArchived(),
    page: PAGES.pts
  }))

  return [...assessmentNames, ...participantFullNames, ...processNames, ...participantNumbers, ...ptNumbers]
}

const GlobalFilterLabel = (props) => {
  const t = useTranslate()
  return (
    <>
      <span style={{ color: 'var(--text-color-secondary)' }}>{t(props.prefix)}: </span>
      <span>{props.data}</span>
      {props.isArchived && <span style={{ color: 'var(--text-color-secondary)' }}> [{t('inTheArchive')}]</span>}
    </>
  )
}
