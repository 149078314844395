import Cookies from 'universal-cookie'
import { fetchData } from './Helper'
import { loginMessages } from '../constants/constants'

const sessionHandler = {
  cookies: new Cookies(),

  getSessionId: () => {
    return sessionHandler.cookies.get('perseo-session')
  },

  deleteSessionCookie: () => {
    sessionHandler.cookies.remove('perseo-session', { path: '/' })
  },

  setSessionCookie: (value) => {
    sessionHandler.cookies.set('perseo-session', value, { path: '/' })
  },

  checkSession: async (context) => {
    const sessionId = sessionHandler.getSessionId()
    let isLoading = true

    const data = await fetchData({}, 'logincheck', context, 'connectionError', false)

    try {
      if (data.code === 401) {
        context.setLoginMessage(loginMessages.expelled)
        isLoading = false
        sessionHandler.logout(context)
      }
      if (data.response.status === 1) {
        context.setToken(sessionId)
        isLoading = false
      }
    } catch (e) {
      console.error(e)
      isLoading = false
    }

    return isLoading
  },

  logout: (context) => {
    sessionHandler.deleteSessionCookie()
    context.statusBarController.setStatusBarVisible(false)
    context.setToken(null)
  }
}

export default sessionHandler
