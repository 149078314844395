import { ERROR_MSG, FIELD_LABELS, SELECT_OPTIONS } from '../../utils/constants/constants'
import { InfoElement, InfoList } from '../../utils/elements/miscElements'
import { getOptionByValue, getTestLanguagesString, isPotentials } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import { Tile, TileHeadline } from '../table/tableElements'

const AddAssessmentAdvancedOptionsTile = ({ data }) => {
  const t = useTranslate()
  const testLanguagesString = getTestLanguagesString(data?.parentConfig?.availableLanguages, data.languageIds)

  return (
    <Tile width="200px">
      <TileHeadline content="additionalOptions" />
      <InfoList>
        <InfoElement
          label={FIELD_LABELS.inHub}
          value={getOptionByValue(t(SELECT_OPTIONS.inHub), data.inHub).label || ERROR_MSG}
        />
        {isPotentials(data.parentConfig.testType) && (
          <InfoElement
            label={FIELD_LABELS.isSupervised}
            value={getOptionByValue(t(SELECT_OPTIONS.isSupervised), data.isSupervised).label || ERROR_MSG}
          />
        )}
        <InfoElement label={FIELD_LABELS.testLanguages} value={testLanguagesString || ERROR_MSG} />
      </InfoList>
    </Tile>
  )
}

export default AddAssessmentAdvancedOptionsTile
