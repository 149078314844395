import { useContext, useRef } from 'react'
import CsvDownload from '../../components/csvDownload/CsvDownload'
import { ENTITIES } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { allParticipantsHaveMailAddress, allPtsInHub } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'
import { AppContext } from '../../utils/context/AppContext'

export const ButtonAfterPtAdd = ({ data, newData }) => {
  const redirect = useRedirect()
  const t = useTranslate()
  const { completeDataSet } = useContext(AppContext)

  const newParticipants = completeDataSet.participants.filter((p) => newData.participantNumbers.includes(p.pNr))
  const newPts = completeDataSet.pts.filter((pt) => newData.ptNumbers.includes(pt.ptNumber))

  const isEmailButton = allParticipantsHaveMailAddress(newParticipants)

  const ptNumbers = newPts.map((pt) => pt.ptNumber)
  if (isEmailButton) {
    const urlParams = [{ id: 'ptNumber', value: ptNumbers }]
    return (
      <ButtonPrimary
        content={t('sendMails') + '…'}
        style={{ gridColumn: 2 }}
        onClick={() => redirect(PAGES.sendEmails, urlParams)}
      />
    )
  } else {
    const urlParams = [{ id: 'processName', value: data.processName }]
    return (
      <ButtonPrimary
        content="goToParticipantList"
        style={{ gridColumn: 2 }}
        onClick={() => redirect(PAGES.participants, urlParams)}
      />
    )
  }
}

export const ButtonDownloadAccessData = ({ newData }) => {
  const childRef = useRef(null)
  const t = useTranslate()
  const { completeDataSet } = useContext(AppContext)

  const newPts = completeDataSet.pts.filter((pt) => newData.ptNumbers.includes(pt.ptNumber))

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      childRef.current.handleClick()
    }
  }

  return (
    <ButtonSecondary
      onKeyDown={handleKeyPress}
      content={
        <>
          <span className="svg-icon icon-arrow-down" style={{ marginRight: 'var(--space-3)' }} />
          <span>{t('accessData')}</span>
          <CsvDownload
            rowData={newPts}
            entity={allPtsInHub(newPts) ? ENTITIES.accessDataHub : ENTITIES.accessData}
            ref={childRef}
          />
        </>
      }
      style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
    />
  )
}
