import { useContext, useState } from 'react'
import { AppContext } from '../../../../../utils/context/AppContext'
import styled from 'styled-components'
import { GreyButton } from '../../../../../utils/elements/miscElements'
import useTranslate from '../../../../../utils/hooks/useTranslate'
import { TableContext } from '../../../DataTable'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import ColumnControllerModal from './ColumnControllerModal'
import { ButtonLabelContainer, FilterLabel } from '../../../../filter/filterUtils'

const ColumnSettingsButton = ({ allColumns }) => {
  const t = useTranslate()
  const { tableName } = useContext(TableContext)
  const context = useContext(AppContext)
  const [show, setShow] = useState(false)

  if (!show) {
    document.body.classList.remove('focus-trapped')
  }

  return (
    <ButtonModalWrapper>
      <ButtonLabelContainer>
        <FilterLabel>{t('table')}</FilterLabel>
        <GreyButton
          className={show ? 'active' : ''}
          data-tip={t('adjustTableColumns')}
          data-for="table-columns-tooltip"
          onClick={() => setShow(!show)}>
          <span className="svg-icon icon-table-columns" />
        </GreyButton>
      </ButtonLabelContainer>
      <TransitionGroup>
        {show && (
          <CSSTransition key="columnController" in={show} timeout={150} classNames="fade" unmountOnExit>
            <ColumnControllerModal {...{ allColumns, setShow, context, tableName }} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </ButtonModalWrapper>
  )
}
export default ColumnSettingsButton

const ButtonModalWrapper = styled.div`
  position: relative;
  @media (max-width: 576px) {
    position: static;
  }
`
