import styled from 'styled-components'
import { setState } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'

const ToggleButton = ({ options, data, setData, dataKey, controls, customFieldDefinition }) => {
  const t = useTranslate()
  const handleClick = (value) => {
    if (isCustomField(data)) {
      const cfdIndex = data.findIndex((cfd) => cfd.publicKey === customFieldDefinition.publicKey)
      data[cfdIndex][dataKey] = value
      setData([...data])
    } else {
      setState(setData, dataKey, value)
      if (value === false && controls) {
        setState(setData, controls, null)
      }
    }
  }

  const isActive = (option) => {
    if (isCustomField(data)) {
      const cfdIndex = data.findIndex((cfd) => cfd.publicKey === customFieldDefinition.publicKey)
      return option.value === (data[cfdIndex][dataKey] || false)
    }
    return option.value === (data[dataKey] || false)
  }

  return (
    <ToggleButtonWrapper>
      {t(options).map((option) => (
        <OptionButton
          className={isActive(option) ? 'active' : 'inactive'}
          key={option.label}
          isDisabled={option.isDisabled}
          onClick={(e) => {
            e.preventDefault()
            handleClick(option.value)
          }}
          active={isActive(option)}>
          {t(option.label)}
        </OptionButton>
      ))}
    </ToggleButtonWrapper>
  )
}

const OptionButton = styled.button.attrs({
  className: 'toggle-button'
})`
  padding: var(--space-3) var(--space-4);
  text-align: center;
  background-color: ${(props) => (props.active ? 'var(--toggle-button-active-bg-color)' : 'var(--button-bg-color)')};
  border: ${(props) => (props.active ? '1px solid var(--toggle-button-active-border-color)' : '')};
  color: ${(props) => getToggleButtonTextColor(props)};
  border-radius: var(--bdr-2);
  user-select: none;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'initial')};
  align-items: center;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${(props) =>
      props.active ? 'var(--toggle-button-active-bg-color)' : 'var(--button-hover-color)'};
  }
`

const getToggleButtonTextColor = (props) => {
  if (props.isDisabled) return 'var(--text-color-secondary)'
  if (props.active) return '#fff'
  return 'inherit'
}

const ToggleButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const isCustomField = (data) => Array.isArray(data)

export default ToggleButton
