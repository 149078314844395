import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { NUMBER_INPUT_ALLOWED_KEYS } from '../../../utils/constants/constants'
import { setState } from '../../../utils/helper/Helper'
import {
  OptionDescription,
  OptionEntryWide,
  PageHeadline,
  WizardForm,
  Label
} from '../../../utils/elements/miscElements'
import SelectParticipantCount from '../subComponents/SelectParticipantCount'
import DataSecuritySelection from '../subComponents/DataSecuritySelection'
import styled from 'styled-components'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import AnonymizationNotice from '../subComponents/AnonymizationNotice'
import useTranslate from '../../../utils/hooks/useTranslate'

const GenerateTans = ({ data, setData }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const showAnonymizationNotice = context.completeDataSet.customer.anonymizeTimeSpan > 0

  const optionDescriptions = {
    participantCount: t('participantTestingInfo')
  }

  const handleParticipantCountChange = (e) => {
    if (e.target.value > 1000) return
    const val = e.target.value
    const intVal = parseInt(val, 10)
    setState(setData, 'participantCount', intVal || 0)
  }

  const handleKeydown = (e) => {
    if (e.key === ',' || e.key === '.' || !NUMBER_INPUT_ALLOWED_KEYS.includes(e.key)) e.preventDefault()
  }

  return (
    <>
      <PageHeadline>{t('participantsAndPrivacy')}</PageHeadline>
      <ScrollContainer>
        <WizardForm>
          <OptionEntryWide hasHint>
            <Label fullWidth htmlFor="participantCount">
              {t('howManyParticipants')}
            </Label>
            <div>
              <SelectParticipantCount data={data} setData={setData} />
              <Container>
                <ParticipantCountInputLabel>{t('yourSelection')}:</ParticipantCountInputLabel>
                <ParticipantCountInput
                  id="participantCount"
                  type="number"
                  pattern="\d*"
                  onKeyDown={handleKeydown}
                  onChange={handleParticipantCountChange}
                  value={data.participantCount}
                />
              </Container>
            </div>
            <OptionDescription description={optionDescriptions.participantCount} />
          </OptionEntryWide>
          <OptionEntryWide hasHint>
            <Label fullWidth htmlFor="pAnon">
              {t('dataSecuritySettings')}
            </Label>
            <DataSecuritySelection {...{ data, setData }} />
            {showAnonymizationNotice && <OptionDescription description={<AnonymizationNotice />} />}
          </OptionEntryWide>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default GenerateTans

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-3);
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--space-3);
`

const ParticipantCountInput = styled.input`
  grid-column: 4;
  @media screen and (max-width: 900px) {
    grid-column: 4;
  }
  text-align: right;
  font-size: var(--fs-5) !important;
  height: 40px;
`

const ParticipantCountInputLabel = styled.span`
  grid-column: 3;
  @media screen and (max-width: 900px) {
    grid-column: 2/4;
  }
  text-align: right;
`
