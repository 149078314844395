import { useContext, useEffect, useReducer } from 'react'
import NormPreview from './NormPreview'
import Select from 'react-select'
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  Label,
  selectStyles
} from '../../../utils/elements/miscElements'
import { fetchData, getOptionByValue, setStatusBar, updateEntities, updateResults } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AppContext } from '../../../utils/context/AppContext'
import { getAllNorms, getNormOptionsWithDetails, isNewNorm, showLoadingIndicator } from './normUtils'
import { AddButton } from '../../table/tableElements'
import { DEFAULT_NORM_NR, MODAL_TYPES, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import RenameDialog from '../sharedComponents/RenameDialog'
import SaveAsDialog from '../sharedComponents/SaveAsDialog'
import NormEditorTable from './NormEditorTable'
import useNormsReducer from './useNormsReducer'
import {
  ButtonContainer,
  Container,
  Footer,
  HelpButton,
  InnerContainer,
  LabelRenameButtonContainer,
  LabelSelectContainer,
  MainButtonContainer,
  RenameButton
} from '../sharedComponents/normEditorElements'
import ReactTooltip from 'react-tooltip'
import NormsHelp from '../../helpModal/NormsHelp'

const NormEditor = () => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const normsReducer = useNormsReducer()
  const initial = {
    renameFieldVisible: false,
    saveAsDialogVisible: false,
    selectedNormNr: DEFAULT_NORM_NR,
    norms: getAllNorms(context.completeDataSet.customNorms, t)
  }

  const [data, dispatch] = useReducer(normsReducer, initial)
  const currentNorm = data.norms.find((n) => n.normNr === data.selectedNormNr)
  const options = getNormOptionsWithDetails(data.norms, t)

  useEffect(() => {
    const emptyUpperFields = document.querySelectorAll('.upper-limit .has-error')
    if (emptyUpperFields.length) {
      emptyUpperFields[0].focus()
    }
  }, [currentNorm.normTable.length])

  const saveAs = (newName) => {
    showLoadingIndicator(context)
    dispatch({ type: 'save_as_dialog_visibility_set', value: false })
    saveNorm(newName, null)
  }

  const save = () => {
    const numberRelatedTests = context.completeDataSet.assessments.filter((a) => a.normNr === currentNorm.normNr).length
    if (numberRelatedTests > 0) {
      openConfirmSaveModal(numberRelatedTests)
    } else {
      showLoadingIndicator(context)
      saveNorm(currentNorm.name, currentNorm.normNr)
    }
  }

  const saveNorm = async (name, normNr) => {
    const normNrSendValue = isNewNorm(normNr) ? null : normNr

    const payload = {
      normName: name,
      normNr: normNrSendValue,
      parameters: {
        row_count: currentNorm.normTable.length,
        grade_naming: 'Note',
        norm_table: currentNorm.normTable.map((row) => {
          const upperLimit = row.upper_limit === 100 ? 101 : row.upper_limit
          return {
            lower_limit: row.lower_limit,
            upper_limit: upperLimit,
            grade: row.grade,
            verbalisation: row.verbalisation
          }
        })
      }
    }

    const responseData = await fetchData(payload, 'save_custom_norm', context, 'ruleSaveError')

    try {
      const { resultNrs, processResultNrs } = responseData.response.resultsToUpdate
      if (responseData.response.status === 1) {
        updateEntities(responseData.response.data, context)
        await updateResults(resultNrs, processResultNrs, context, t)
        const filteredNorms = data.norms.filter((n) => n.normNr !== normNr)
        const returnedNorm = responseData.response.data.customNorms[0]

        dispatch({
          type: 'updated_norms',
          selectedNormNr: returnedNorm.normNr,
          norms: [...filteredNorms, returnedNorm]
        })

        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: 'ruleSavedSuccessfully',
          setVisible: true
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const hasBoundaryErrors = currentNorm.normTable.some((row) => row.upperTooLow)
  const hasEmptyFields = currentNorm.normTable.some((row) => row.grade === '' || row.verbalisation === '')
  const canSave = !hasBoundaryErrors && !hasEmptyFields && !currentNorm.disabled
  const canSaveAs = !hasBoundaryErrors && !hasEmptyFields
  const canDelete = !currentNorm.disabled
  const hasUnsavedNewNorm = data.norms.find((n) => isNewNorm(n.normNr))
  const addButtonDisabled = hasUnsavedNewNorm || data.renameFieldVisible

  const deleteNorm = async () => {
    if (isNewNorm(currentNorm.normNr)) {
      dispatch({
        type: 'removed_norm',
        normNr: currentNorm.normNr
      })
      return
    }

    const payload = { normNr: currentNorm.normNr }
    const responseData = await fetchData(payload, 'delete_custom_norm', context)

    try {
      const { resultNrs, processResultNrs } = responseData.response.resultsToUpdate
      if (responseData.response.status === 1) {
        updateEntities(responseData.response.data, context)
        await updateResults(resultNrs, processResultNrs, context, t)

        dispatch({
          type: 'removed_norm',
          normNr: currentNorm.normNr
        })

        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: 'fieldsSuccessfullyChanged',
          setVisible: true
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const openConfirmSaveModal = (numberRelatedTests) => {
    const text = t('deleteNormWarning', numberRelatedTests, 'overwrite')
    context.setModalProps({
      headline: 'overwriteNorm',
      content: text,
      buttonPrimary: (
        <ButtonPrimary
          modalButton
          content="overwrite"
          onClick={() => {
            showLoadingIndicator(context)
            saveNorm(currentNorm.name, currentNorm.normNr)
            context.setModalOpen(false)
          }}
        />
      ),
      icon: 'icon-save modal',
      type: MODAL_TYPES.alert
    })
    context.setModalOpen(true)
  }

  const openConfirmDeleteModal = () => {
    const numberRelatedTests = context.completeDataSet.assessments.filter((a) => a.normNr === currentNorm.normNr).length
    const text = numberRelatedTests > 0 ? t('deleteNormWarning', numberRelatedTests, 'delete') : 'confirmNormDelete'
    context.setModalProps({
      headline: 'deleteNorm',
      content: text,
      buttonPrimary: (
        <ButtonPrimary
          modalButton
          warning
          content="delete"
          onClick={() => {
            deleteNorm()
            context.setModalOpen(false)
          }}
        />
      ),
      icon: 'icon-delete modal',
      type: MODAL_TYPES.alert
    })
    context.setModalOpen(true)
  }

  return (
    <Container>
      <HelpButton content={<NormsHelp />} label="gradingRules" />
      <InnerContainer>
        <ButtonContainer>
          <LabelSelectContainer>
            <LabelRenameButtonContainer>
              <Label>{t('gradingRule')}</Label>
              {!data.renameFieldVisible && canDelete && (
                <RenameButton onClick={() => dispatch({ type: 'toggle_rename_field' })}>{t('rename')}…</RenameButton>
              )}
            </LabelRenameButtonContainer>
            {data.renameFieldVisible ? (
              <RenameDialog
                initialName={currentNorm.name}
                onOk={(value) => dispatch({ type: 'renamed_norm', value: value })}
                onCancel={() => dispatch({ type: 'toggle_rename_field' })}
              />
            ) : (
              <Select
                options={options}
                onChange={(selectedOption) => dispatch({ type: 'norm_nr_set', value: selectedOption.value })}
                value={getOptionByValue(options, currentNorm.normNr)}
                styles={selectStyles}
                isSearchable={false}
                height="48px"
              />
            )}
          </LabelSelectContainer>

          <div
            className="tooltip-absolute norm-editor-tooltip"
            data-for="addSchemeTipp"
            data-tip=""
            style={{ marginLeft: 'auto' }}>
            <AddButton
              style={{ position: 'relative' }}
              disabled={addButtonDisabled}
              onClick={() => dispatch({ type: 'empty_norm_added' })}>
              + {t('rule')}
              {hasUnsavedNewNorm && (
                <ReactTooltip
                  id="addSchemeTipp"
                  aria-haspopup="true"
                  effect="solid"
                  place="left"
                  backgroundColor="var(--c-tooltip-bg)"
                  textColor="var(--tooltip-text-color)"
                  getContent={() => <div style={{ width: '120px' }}>{t('saveNewSchemeFirst')}</div>}
                  overridePosition={() => ({ top: -16, left: -160 })}
                />
              )}
            </AddButton>
          </div>
        </ButtonContainer>

        <NormEditorTable {...{ currentNorm, dispatch }} />

        <NormPreview normTable={currentNorm.normTable} hasBoundaryErrors={hasBoundaryErrors} />

        <Footer>
          {canDelete && (
            <ButtonTertiary
              disabled={data.renameFieldVisible}
              style={{ flexGrow: 0 }}
              content={t('delete') + '…'}
              onClick={openConfirmDeleteModal}
            />
          )}
          <MainButtonContainer>
            <ButtonSecondary
              disabled={!canSaveAs || data.renameFieldVisible}
              onClick={() => dispatch({ type: 'save_as_dialog_visibility_set', value: true })}
              content={t('saveAs') + '…'}
            />
            {!currentNorm.disabled && (
              <ButtonPrimary onClick={save} disabled={!canSave || data.renameFieldVisible} content="save" />
            )}
          </MainButtonContainer>
          {data.saveAsDialogVisible && (
            <SaveAsDialog
              name={currentNorm.name}
              onCancel={() => dispatch({ type: 'save_as_dialog_visibility_set', value: false })}
              onOk={(newName) => saveAs(newName)}
            />
          )}
        </Footer>
      </InnerContainer>
    </Container>
  )
}

export default NormEditor
