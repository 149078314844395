import { API_DATA } from '../../utils/constants/constants'
import { fetchData, setState, updateEntities } from '../../utils/helper/Helper'
import { getParamsJson } from '../../utils/helper/urlParamsHelper'

export const addParticipants = async (data, context) => {
  const endPoint = data.useFileUpload ? API_DATA.pts.endPointAddJson : API_DATA.pts.endPointAdd

  const payload = {
    assessmentUuids: data.assessmentUuids,
    participantCount: data.participantCount,
    pAnon: data.pAnon,
    uploadedData: data.uploadedData
  }

  const responseData = await fetchData(payload, endPoint, context, 'participantsNotAdded')

  try {
    return addParticipantsAndPts(responseData, context)
  } catch (e) {
    console.error(e)
  }
}

export const addParticipantsAndPts = (responseData, context) => {
  const completeDataSet = context.completeDataSet
  const newPts = responseData.response.pts

  return new Promise((resolve) => {
    const newParticipants = newPts.map((pt) => pt.participant)

    const allpNrs = newParticipants.map((p) => p.pNr)
    const uniquepNrs = [...new Set(allpNrs)]
    const uniqueParticipants = uniquepNrs.map((uniquepNr) =>
      newParticipants.find((newParticipant) => newParticipant.pNr === uniquepNr)
    )

    const allPts = [...responseData.response.pts, ...completeDataSet.pts]
    const allParticipants = [...uniqueParticipants, ...completeDataSet.participants]

    const updates = {
      pts: allPts,
      participants: allParticipants
    }

    updateEntities(updates, context)
    resolve({
      ptNumbers: responseData.response.pts.map((pt) => pt.ptNumber),
      participantNumbers: uniqueParticipants.map((p) => p.pNr)
    })
  })
}

export const updateStateFromUrlParams = (location, setData, setActivePageId) => {
  const params = getParamsJson(location)

  if (params) {
    params.forEach((param) => {
      if (param.id === 'processUuid') {
        setState(setData, 'processUuid', param.value)
      }
      if (param.id === 'assessmentUuid') {
        setState(setData, 'assessmentUuids', [param.value])
        setActivePageId(1)
      }
    })
  }
}

export const validateForm = (data, setIsValid) => {
  const tmp = {}
  data.assessmentUuids.length > 0
    ? Object.assign(tmp, { selectAssessmentsPage: true })
    : Object.assign(tmp, { selectAssessmentsPage: false })
  data.participantCount > 0 && !data.useFileUpload
    ? Object.assign(tmp, { generateTansPage: true })
    : Object.assign(tmp, { generateTansPage: false })
  data.parsedCsvData.length > 0
    ? Object.assign(tmp, { csvUploadPage: true })
    : Object.assign(tmp, { csvUploadPage: false })
  setIsValid(tmp)
}
