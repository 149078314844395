import CreditsCounter from '../../components/creditsCounter/CreditsCounter'
import styled from 'styled-components'

const CreditsOverview = () => {
  return (
    <CreditsContainer>
      <MoneyBagIcon className="svg-icon icon-money-bag" />
      <div style={{ transform: 'translateY(4px)' }}>
        <CreditsCounter position="dashboard" />
      </div>
    </CreditsContainer>
  )
}

export default CreditsOverview

const CreditsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--space-5);
  align-items: flex-end;
`

const MoneyBagIcon = styled.span`
  width: var(--icon-2);
  height: var(--icon-2);
  background-color: var(--icon-color);
`
