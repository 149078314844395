import styled from 'styled-components'
import { setState } from '../../../utils/helper/Helper'

const SelectParticipantCount = ({ data, setData }) => {
  const options = [1, 5, 10, 25, 50, 100, 250, 500]

  const handleClick = (e, option) => {
    e.preventDefault()
    setState(setData, 'participantCount', option)
  }

  return (
    <Container>
      {options.map((option, i) => (
        <CheckboxElement
          key={i}
          onClick={(e) => handleClick(e, option)}
          count={option}
          checked={data.participantCount === option}
        >
          <AlignmentContainer>{option}</AlignmentContainer>
        </CheckboxElement>
      ))}
    </Container>
  )
}

export default SelectParticipantCount

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-3);
  @media (max-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const AlignmentContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-3);
  flex-grow: 1;
`

const CheckboxElement = styled.button`
  user-select: none;
  border-radius: var(--bdr-2);
  transition: background-color var(--hover-duration);
  height: 40px;
  background-color: ${(props) => (props.checked ? 'var(--filled-button-checked-bg-color)' : 'var(--box-background)')};
  color: ${(props) => (props.checked ? '#fff' : '')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-size: var(--fs-4);
  cursor: pointer;
  border-color: ${(props) => (props.checked ? 'var(--filled-button-checked-border-color)' : 'transparent')};
  border-width: 1px;
  border-style: solid;
  &:hover {
    background-color: ${(props) =>
      props.checked ? 'var(--filled-button-checked-bg-color)' : 'var(--box-background-hover)'};
  }
`
