import { languageSortingOrder } from '../../utils/constants/constants'
import { setState } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import { CustomTableCheckbox } from '../table/tableElements'
import styled from 'styled-components'

const TestLanguagesSelect = ({ languageIds, languageOptions, setterFunction, stateKey, setIsValid }) => {
  const t = useTranslate()
  const isValid = languageIds.length > 0

  const setLanguages = (val) => {
    let tmpLanguages = languageIds
    if (tmpLanguages.includes(val)) {
      tmpLanguages = tmpLanguages.filter((lang) => lang !== val)
    } else {
      tmpLanguages = [...tmpLanguages, val]
    }

    if (stateKey) {
      setState(setterFunction, stateKey, tmpLanguages)
    } else {
      setterFunction(tmpLanguages)
    }

    if (setIsValid) {
      setIsValid(tmpLanguages.length > 0)
    }
  }

  return (
    <OuterContainer {...{ isValid }}>
      {languageOptions
        .sort((a, b) => languageSortingOrder.indexOf(a.value) - languageSortingOrder.indexOf(b.value))
        .map((option) => {
          const isChecked = languageIds.includes(option.value)
          const className = isChecked ? 'svg-icon icon-check-noborder' : ''

          return (
            <Label key={option.label} onClick={() => setLanguages(option.value)}>
              <CustomTableCheckbox
                checked={isChecked}
                onKeyPress={() => setLanguages(option.value)}
                className={'custom-checkbox ' + className}
              />
              <span>{t(option.label)}</span>
            </Label>
          )
        })}
    </OuterContainer>
  )
}

export default TestLanguagesSelect

const OuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space-6);
  row-gap: var(--space-4);
  padding: var(--space-4);
  background-color: ${(props) => (props.isValid ? 'var(--box-background)' : 'var(--box-background-warning)')};
  border-radius: var(--bdr-2);
  transition-duration: var(--hover-duration);
`

const Label = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: var(--space-3);
  white-space: nowrap;
  user-select: none;
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
`
