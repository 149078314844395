import { CopyIcon, SmallNotice } from '../../utils/elements/miscElements'
import { BaseInfo } from '../table/tableElements'
import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'

const AccessDataDisplay = ({ accessData, disabledNotice }) => {
  const isDisabled = disabledNotice ? true : false
  const t = useTranslate()

  return (
    <>
      <AccessDataContainer>
        <BaseInfo>
          <span className="text-secondary">{t('invitationLink')}</span>
          <AccessDataElement content={accessData.link} isDisabled={isDisabled} elementLabel="Link" />
        </BaseInfo>
        <BaseInfo>
          <span className="text-secondary">{t('loginCode')}</span>
          <AccessDataElement content={accessData.token} isDisabled={isDisabled} elementLabel="loginCode" />
        </BaseInfo>
      </AccessDataContainer>
      {isDisabled && <SmallNotice>{t(disabledNotice)}</SmallNotice>}
    </>
  )
}

export default AccessDataDisplay

const AccessDataContainer = styled.ul`
  padding: 0;
  display: grid;
  gap: var(--space-5);
`

const AccessDataElement = ({ content, isDisabled, elementLabel }) => (
  <OuterContainer>
    <InnerContainer tabIndex="0" isDisabled={isDisabled}>
      {content}
    </InnerContainer>
    <CopyIcon {...{ content, elementLabel }} />
  </OuterContainer>
)

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--space-3);
  align-items: center;
`

const InnerContainer = styled.span`
  font-family: 'Droid Sans', monospace;
  color: ${(props) => (props.isDisabled ? 'var(--text-color-primary)' : '')};
  background-color: var(--box-background);
  padding: var(--space-3);
  border-radius: var(--bdr-2);
  font-size: var(--fs-2);
  white-space: nowrap;
  overflow: auto;
  line-height: 1;
  scrollbar-width: none;
`
