import styled from 'styled-components'

export const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
`

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--space-8);
  align-items: center;
  width: 100%;
`

export const InnerContainer = styled.div`
  width: 90%;
  max-width: var(--modal-medium);
  margin-top: var(--space-7);
  background-color: var(--login-container-bg-color);
  padding: var(--space-7);
  border-radius: var(--bdr-4);
  box-shadow: var(--shadow-default);
`

export const PerseoLogo = () => <span className="perseo-logo login-logo" />
