import ProcessBaseSettingsTile from '../tiles/ProcessBaseSettingsTile'
import { TileContainer } from '../table/tableElements'
import ProcessResultSettingsTile from '../tiles/processResultSettingsTile/ProcessResultSettingsTile'

const ProcessSettingsTab = ({ process }) => {
  return (
    <TileContainer>
      <ProcessBaseSettingsTile {...{ process }} />
      <ProcessResultSettingsTile {...{ process }} />
    </TileContainer>
  )
}

export default ProcessSettingsTab
