import React from 'react'
import Highlighter from 'react-highlight-words'
import { isString } from '../../utils/helper/Helper'
import { FILTER_TYPES } from '../../utils/constants/constants'
import { formatDate } from '../../utils/helper/dateTimeHelper'
import styled from 'styled-components'
import { EMPTY_RELATION_OPTIONS } from './FilterBuilder/RelationSelector'

export const removeDuplicates = (arr) => [...new Set(arr)]

export const removeDuplicatesByProp = (arr, prop) => {
  return arr.reduce((accumulator, current) => {
    if (!accumulator.some((item) => item[prop] === current[prop])) {
      accumulator.push(current)
    }
    return accumulator
  }, [])
}

export const formatOptionLabel = ({ label }, { inputValue }) => {
  if (typeof label !== 'string') return label
  return <Highlighter highlightClassName="filter-highlight" searchWords={[inputValue]} textToHighlight={label} />
}

export const getValue = (selection, type, dataLabels) => {
  switch (type) {
    case FILTER_TYPES.switch:
      return dataLabels[selection.value]
    case FILTER_TYPES.datePicker:
      if (selection === null) return ''
      return formatDate(selection).urlParamFormat
    case FILTER_TYPES.textInput:
      return selection
    case FILTER_TYPES.select:
    default:
      if (selection) return selection.value
      return ''
  }
}

export const getColumnValues = (filteredData, fieldId, filterOptions) => {
  const sortFunction = (a, b) => {
    if (isString(a) && isString(b)) {
      return a.localeCompare(b)
    } else {
      return a - b
    }
  }
  return filteredData
    .map((rowData) => {
      if (Array.isArray(rowData)) {
        return rowData.map((entry) => entry[fieldId])
      }

      return rowData?.[fieldId]
    })
    .flatMap((item) => item)
    .filter((item) => (filterOptions.excludedValues ? !filterOptions.excludedValues.includes(item) : true))
    .filter((item) => item !== '' && item !== undefined && item !== null)
    .sort(sortFunction)
}

export const getFilterLabels = (options) => {
  const labels = {}
  Object.entries(options).forEach((entry) => {
    const option = entry[1]
    const obj = { [option.value]: option.label }
    return Object.assign(labels, obj)
  })
  return labels
}

export const FilterLabel = styled.label`
  font-size: var(--fs-2);
  margin-bottom: var(--space-1);
  line-height: var(--lh-1);
  color: var(--text-color-secondary);
`

export const isSearchable = (options) => {
  return options.every((option) => isString(option.label) || typeof option.label === 'number')
}

export const LabelFilterContainer = styled.div`
  position: relative;
  width: ${(props) => props.width};
  display: grid;
  @media (max-width: 600px) {
    width: 100% !important;
  }
`

export const Separator = styled.span`
  width: 1px;
  height: 15px;
  background-color: var(--small-action-icon-color);
  margin: 2px;
`

export const usingEmptyRelation = (relation) => EMPTY_RELATION_OPTIONS.map((opt) => opt.value).includes(relation.value)

export const useSecondValue = (relation) => ['betweenNumbers', 'betweenDates'].includes(relation.value)

export const ButtonLabelContainer = styled.div.attrs({
  className: 'button-label-container'
})`
  display: grid;
  justify-items: center;
`
