import { useContext, useEffect, useRef, useState } from 'react'
import { getFormattedNumber, getOptionByValue, handleDatePickerKeyDown } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getFilterStyles } from '../filterStyles'
import {
  LabelFilterContainer,
  formatOptionLabel,
  getColumnValues,
  isSearchable,
  usingEmptyRelation
} from '../filterUtils'
import Select from 'react-select'
import { CONTENT_TYPES, CUSTOMER_VISIBILITY_STATUS } from '../../../utils/constants/constants'
import {
  adjustDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  secondsToDHM,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear
} from '../../../utils/helper/dateTimeHelper'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { AppContext } from '../../../utils/context/AppContext'
import DateDisplay from '../../dateDisplay/DateDisplay'
import { TableContext } from '../../table/DataTable'
import MenuList from '../../menuList/MenuList'

const ValueSelector = ({ value, setValue, field, relation, applyFilter, currentFilter }) => {
  const hideValueSelector = usingEmptyRelation(relation)

  if (hideValueSelector) {
    return <></>
  }

  const inputTypes = ['like', 'notLike', 'lt', 'gt', 'lte', 'gte', 'betweenNumbers']
  const showValueInput = inputTypes.includes(relation.value)

  return (
    <LabelFilterContainer>
      {showValueInput ? (
        <ValueInput {...{ value, setValue, applyFilter }} />
      ) : (
        <Input
          {...{
            value,
            setValue,
            field,
            currentFilter
          }}
        />
      )}
    </LabelFilterContainer>
  )
}

export default ValueSelector

const Input = ({ value, setValue, field, currentFilter }) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const { language } = useContext(AppContext)
  const datePickerRef = useRef(null)

  const { filterOptions } = field
  const showDateSwitcherButton = filterOptions.contentType === CONTENT_TYPES.date

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(showCalendar)
    }
  }, [showCalendar])

  const setDatePickerValue = (date) => {
    setValue({
      value: {
        from: startOfDay(date),
        until: endOfDay(date)
      },
      label: date,
      useExactDate: true
    })
  }

  const handleCalendarClose = () => {
    // workaround to avoid event handler conflicts
    setTimeout(() => {
      setShowCalendar(false)
    }, 5)
  }

  return (
    <div className={showCalendar ? 'show-calendar' : ''} style={{ position: 'relative' }}>
      {showCalendar && (
        <div
          className={`datepicker-wrapper filter ${value.value?.from ? 'has-value' : ''}`}
          style={{ fontSize: '13px', position: 'absolute' }}>
          <DatePicker
            className={value.value?.from && 'has-value'}
            selected={value.value?.from || ''}
            locale={language}
            dateFormat="dd.MM.yyy"
            calendarStartDay={1}
            preventOpenOnFocus={true}
            ref={datePickerRef}
            onKeyDown={(e) => handleDatePickerKeyDown(e, datePickerRef)}
            onChange={(date) => setDatePickerValue(date)}
            onCalendarClose={handleCalendarClose}
            placeholderText=""
          />
        </div>
      )}
      <ValueSelectorInput
        {...{
          currentFilter,
          field,
          value,
          setValue
        }}
      />
      {showDateSwitcherButton && (
        <DateSwitcherButton onClick={() => setShowCalendar(!showCalendar)}>
          <CalendarIcon className="svg-icon icon-calendar" />
        </DateSwitcherButton>
      )}
    </div>
  )
}

const getValueLabel = (value, filterOptions, t, language) => {
  switch (filterOptions?.contentType) {
    case CONTENT_TYPES.time:
      return t('dhmString', ...secondsToDHM(value))
    case CONTENT_TYPES.number:
      return getFormattedNumber(value, language)
    default:
      return value
  }
}

const ValueSelectorInput = ({ currentFilter, field, value, setValue }) => {
  const t = useTranslate()
  const { rows, preFilteredRows } = useContext(TableContext)
  const { showArchivedItems, language } = useContext(AppContext)
  const options = getOptions(currentFilter, field, rows, preFilteredRows, showArchivedItems, t, language)
  const displayValue = value.useExactDate
    ? { label: <DateDisplay date={value.label} format="ddmmyyyy" /> }
    : getOptionByValue(t(options), value.value)

  return (
    <Select
      options={t(options)}
      components={options.length > 100 ? { MenuList } : ''}
      onChange={(selectedOption) => setValue(selectedOption)}
      styles={getFilterStyles({ size: 'auto' })}
      value={displayValue}
      menuPortalTarget={document.body}
      placeholder={t('value')}
      classNamePrefix="react-select"
      className="react-select"
      blurInputOnSelect
      noOptionsMessage={() => t('noResults')}
      formatOptionLabel={formatOptionLabel}
      isSearchable={isSearchable(options)}
    />
  )
}

const getOptions = (currentFilter, field, rows, preFilteredRows, showArchivedItems, t, language) => {
  const { filterOptions } = field

  if (filterOptions.contentType === CONTENT_TYPES.date) {
    return t(getDateOptions(filterOptions))
  }

  const rowValuesForOptions = getRowValuesForOptions(currentFilter, rows, preFilteredRows, showArchivedItems)
  const valueOptions = getColumnValues(rowValuesForOptions, field.value, filterOptions)

  const uniqueValues = [...new Set(valueOptions)]
  const options = uniqueValues.map((value) => ({
    label: filterOptions.dataLabels
      ? filterOptions.dataLabels[value] || value
      : getValueLabel(value, filterOptions, t, language),
    value: value
  }))

  return options
}

const getRowValuesForOptions = (currentFilter, rows, preFilteredRows, showArchivedItems) => {
  const showAllOptions = currentFilter !== undefined

  if (!showAllOptions) {
    return rows.map((row) => row.values)
  }

  const preFilteredRowValue = preFilteredRows.map((row) => row.values)
  if (showArchivedItems) {
    return preFilteredRowValue
  }

  return preFilteredRowValue.filter((row) => row.visibility === CUSTOMER_VISIBILITY_STATUS.visible)
}

const ValueInput = ({ value, setValue, applyFilter }) => {
  const t = useTranslate()
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      applyFilter()
    }
  }
  return (
    <StyledInput
      type="text"
      placeholder={t('value')}
      value={value.value || ''}
      onChange={(e) => setValue({ value: e.target.value, label: e.target.value })}
      onKeyDown={handleKeyDown}
    />
  )
}

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid var(--border-grey);
  background-color: var(--input-bg-color);
  border-radius: var(--bdr-2);
  height: 32px;
  font-size: 13px !important;
  &:focus-within {
    border-color: var(--color-focus-outline);
  }
`
const getDateOptions = (filterOptions) => {
  const now = new Date()

  const dateOptions = [
    {
      label: 'lastYear',
      value: {
        from: startOfYear(adjustDays(startOfYear(now), -1)),
        until: endOfYear(adjustDays(startOfYear(now), -1))
      }
    },
    {
      label: 'lastMonth',
      value: {
        from: startOfMonth(adjustDays(startOfMonth(now), -1)),
        until: endOfMonth(adjustDays(startOfMonth(now), -1))
      }
    },

    {
      label: 'lastWeek',
      value: {
        from: adjustDays(startOfWeek(now), -7),
        until: endOfWeek(adjustDays(startOfWeek(now), -7))
      }
    },
    {
      label: 'yesterday',
      value: {
        from: startOfDay(adjustDays(now, -1)),
        until: endOfDay(adjustDays(now, -1))
      }
    },
    {
      label: 'today',
      value: {
        from: startOfDay(now),
        until: endOfDay(now)
      }
    },
    {
      label: 'thisWeek',
      value: {
        from: startOfWeek(now),
        until: endOfWeek(startOfWeek(now))
      }
    },
    {
      label: 'thisMonth',
      value: {
        from: startOfMonth(now),
        until: endOfMonth(now)
      }
    },
    {
      label: 'thisYear',
      value: {
        from: startOfYear(now),
        until: endOfYear(now)
      }
    },
    {
      label: 'tomorrow',
      value: {
        from: startOfDay(adjustDays(now, 1)),
        until: endOfDay(adjustDays(now, 1))
      },
      isFutureDate: true
    },

    {
      label: 'nextWeek',
      value: {
        from: adjustDays(startOfWeek(now), 7),
        until: endOfWeek(adjustDays(startOfWeek(now), 7))
      },
      isFutureDate: true
    },

    {
      label: 'nextMonth',
      value: {
        from: startOfMonth(adjustDays(endOfMonth(now), 1)),
        until: endOfMonth(adjustDays(endOfMonth(now), 1))
      },
      isFutureDate: true
    },

    {
      label: 'nextYear',
      value: {
        from: startOfYear(adjustDays(endOfYear(now), 1)),
        until: endOfYear(adjustDays(endOfYear(now), 1))
      },
      isFutureDate: true
    }
  ]
  return dateOptions
    .map((opt) => ({ ...opt, useExactDate: false }))
    .filter((opt) => (filterOptions.excludeFutureDates ? !opt.isFutureDate : opt))
}

const DateSwitcherButton = styled.button`
  position: absolute;
  z-index: 9;
  background-color: transparent;
  color: var(--text-color-primary);
  right: var(--space-7);
  top: 8px;
  padding: 0;
  .svg-icon {
    background-color: var(--small-action-icon-color);
    transition-duration: var(--hover-duration);
    &:hover {
      background-color: var(--small-action-icon-hover-color);
      transition-duration: var(--hover-duration);
    }
  }
`

const CalendarIcon = styled.span``
