import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const MatchingNormHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <MatchingNormHelpEnglish />
  } else {
    return <MatchingNormHelpGerman />
  }
}

export default MatchingNormHelp

const MatchingNormHelpGerman = () => {
  return (
    <div>
      <p>
        Um das Job-Matching nutzen zu können, benötigt Ihr Test ein Soll-Profil. Das Profil gibt für jedes gemessene
        Merkmal vor, in welchem Bereich das Testergebnis idealerweise liegen sollte. Die Passungen über alle Merkmale
        (Dimensionen) hinweg werden anschließend zu einem prozentualen Match-Score zusammengefasst.
      </p>
      <p>Ein Soll-Profil enthält für jede Dimension zwei Informationen:</p>
      <ul>
        <li>
          <strong style={{ fontWeight: 600 }}>Punktgrenzen:</strong> Hier wird festgelegt, ob die erfasste
          Merkmalsausprägung gut (+), mittelmäßig (o) oder schlecht (–) zur Stelle passt. Die Skala wird hierfür in 10
          Blöcke unterteilt, so dass jeder Block 10 Skalenpunkte umfasst. Die Blöcke an den Rändern der Skala stehen für
          niedrige bzw. hohe Ausprägungen, die Blöcke in der Mitte für moderate Ausprägungen.
        </li>
        <li>
          <strong style={{ fontWeight: 600 }}>Gewicht:</strong> Dieses bestimmt, wie stark sich die Passung auf dieser
          Dimension auf den Match-Score auswirkt.
        </li>
      </ul>
      <p>
        Beispiel: Wenn Sie die ersten zwei Blöcke einer Dimension als <em>schlecht passend</em> markieren, werden
        Punktwerte zwischen 0 und 20 als schlecht passend bewertet. Markieren Sie die nächsten drei Blöcke als{' '}
        <em>mittelmäßig passend</em>, werden Punktwerte zwischen 20 und 50 als mittelmäßig passend bewertet usw.
      </p>
      <div className="textbox important">
        <p>Bei Änderung oder Neuzuordnung eines Soll-Profils werden zugeordnete Testergebnisse neu berechnet.</p>
      </div>
      <p>
        Wenn Sie das Soll-Profil ändern, werden die Rohdaten der Teilnehmer nicht verändert. Sie können das Profil daher
        gefahrlos ändern und jederzeit zum voreingestellten Profil zurückkehren.
      </p>
    </div>
  )
}

const MatchingNormHelpEnglish = () => {
  return (
    <div>
      <p>
        To use job matching, your test needs a target profile. The profile specifies the ideal range for each measured
        trait where the test result should ideally fall. The matches across all traits (dimensions) are then combined
        into a percentage match score.
      </p>
      <p>A target profile contains two pieces of information for each dimension:</p>
      <ul>
        <li>
          <strong style={{ fontWeight: 600 }}>Score limits:</strong> This defines whether the measured characteristic is
          a good (+), moderate (o), or poor (–) fit for the position. The scale is divided into 10 blocks, with each
          block covering 10 scale points. The blocks at the edges of the scale represent low or high levels, while the
          blocks in the middle represent moderate levels.
        </li>
        <li>
          <strong style={{ fontWeight: 600 }}>Weight:</strong> This determines how much the fit in this dimension
          affects the match score.
        </li>
      </ul>
      <p>
        Example: If you mark the first two blocks of a dimension as <em>poor fit</em>, scores between 0 and 20 will be
        considered a poor fit. If you mark the next three blocks as <em>moderate fit</em>, scores between 20 and 50 will
        be considered a moderate fit, and so on.
      </p>
      <div className="textbox important">
        <p>If a target profile is changed or reassigned, the assigned test results will be recalculated.</p>
      </div>
      <p>
        Changing the target profile will not affect the raw data of the participants. Therefore, you can safely modify
        the profile and revert to the default profile at any time.
      </p>
    </div>
  )
}
