import { Tile, TileContainer, TileHeadline } from '../table/tableElements'
import PtSettings from '../ptSettings/PtSettings'
import TimeLine from '../timeline/TimeLine'

const PtTimingAndSettingsTab = ({ pt }) => (
  <TileContainer>
    <Tile width="220px" style={{ zIndex: 2 }}>
      <TileHeadline content="Zeiten" />
      <TimeLine pt={pt} />
    </Tile>
    <Tile width="200px">
      <TileHeadline content="settings" />
      <PtSettings pt={pt} />
    </Tile>
  </TileContainer>
)

export default PtTimingAndSettingsTab
