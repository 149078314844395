import React from 'react'
import styled from 'styled-components'

const PageIndicator = ({ pages, activePageId, isInNotification }) => {
  const visiblePages = pages.filter((page) => !page.hidePageIndicator)
  const isVisible = !pages[activePageId].hidePageIndicator

  return (
    <>
      {isInNotification ? (
        <PageIndicatorContainerNotification>
          {visiblePages.map((page, i) => (
            <React.Fragment key={page.title + 'notification' + i}>
              <PageDotNotification active={activePageId === i} />
            </React.Fragment>
          ))}
        </PageIndicatorContainerNotification>
      ) : (
        <PageIndicatorContainer className="page-indicator-container" isVisible={isVisible}>
          {visiblePages.map((page, i) => (
            <React.Fragment key={page.title + i}>
              <PageTitle active={activePageId === i}>{page.title}</PageTitle>
              <PageDot active={activePageId === i} />
              {i < visiblePages.length - 1 && <Separator>{'>'}</Separator>}
            </React.Fragment>
          ))}
        </PageIndicatorContainer>
      )}
    </>
  )
}

export default PageIndicator

const PageIndicatorContainer = styled.div`
  display: flex;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  align-items: center;
  gap: 0 var(--space-2);
  flex-wrap: wrap;
  height: 100%;
  @media screen and (max-width: 900px) {
    gap: 0 var(--space-3);
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const PageDot = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? 'var(--page-indicator-active-color)' : 'var(--page-indicator-color)')};
  border-radius: var(--bdr-round);
  height: 16px;
  width: 16px;
  @media screen and (max-width: 900px) {
    display: flex;
  }
`

const PageTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) => (props.active ? 'var(--page-indicator-bg-color)' : 'transparent')};
  color: ${(props) => (props.active ? '' : 'var(--text-color-secondary)')};
  border-radius: var(--bdr-2);
  height: 20px;
  font-size: var(--fs-2);
  padding: 0 var(--space-2);
  @media screen and (max-width: 900px) {
    display: none;
  }
`

const Separator = styled.span`
  color: var(--text-color-secondary);
  @media screen and (max-width: 900px) {
    display: none;
  }
`

const PageIndicatorContainerNotification = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 var(--space-2);
  height: 16px;
`

const PageDotNotification = styled.div`
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? 'var(--page-indicator-active-color)' : 'var(--page-indicator-color)')};
  border-radius: var(--bdr-round);
  height: 12px;
  width: 12px;
`
