import Config from '../../utils/constants/config'
import { API_DATA, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { setStatusBar } from '../../utils/helper/Helper'
import sessionHandler from '../../utils/helper/sessionHandler'

export const downloadInvoice = (invoiceNo, statusBarController) => {
  const session = sessionHandler.getSessionId()
  const endPoint = API_DATA.invoices.endPointGet
  const url = `${Config.baseUrl}/${Config.prefix}/${endPoint}/${invoiceNo}?perseo-session=${session}`
  fetch(url).then((response) => {
    if (response.status === 404) {
      console.error(response)
      setStatusBar({
        controller: statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: 'fileNotFound',
        setVisible: true
      })
    } else {
      const win = window.open(url, '_blank')
      win.focus()
    }
  })
}
