import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const TextWithInfoTooltip = ({ text, info }) => {
  return (
    <span>
      <span>{text}</span>
      {info && (
        <>
          <span
            className="svg-icon icon-info"
            tabIndex="-1"
            data-tip={info}
            data-for="normInfo"
            style={{ marginLeft: 'var(--space-2)', transform: 'translateY(1px)', width: '13px', height: '13px' }}
          />
          <ReactTooltip
            id="normInfo"
            aria-haspopup="true"
            effect="solid"
            place="right"
            backgroundColor="var(--c-tooltip-bg)"
            textColor="var(--tooltip-text-color)"
            getContent={(info) => <PopUpWrapper>{info}</PopUpWrapper>}
          />
        </>
      )}
    </span>
  )
}

export default TextWithInfoTooltip

const PopUpWrapper = styled.div`
  max-width: 160px;
  font-size: var(--fs-2);
  margin: 0 -4px;
  line-height: var(--lh-2);
`
