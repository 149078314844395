import { useContext } from 'react'
import useTranslate from '../../../../utils/hooks/useTranslate'
import { PaginationContext } from '../../DataTable'

const PageNumberDisplay = () => {
  const { pageIndex, pageOptions } = useContext(PaginationContext)
  const t = useTranslate()
  return (
    <div className="page-number-display">
      <span>{`${t('page')} ${pageIndex + 1} / ${pageOptions.length}`}</span>
    </div>
  )
}

export default PageNumberDisplay
