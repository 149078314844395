import { CustomTableCheckbox } from '../../../components/table/tableElements'
import { PAYMENT_OPTIONS } from '../../../utils/constants/constants'
import { setState } from '../../../utils/helper/Helper'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'

const PayMethodSelection = ({ orderDataState }) => {
  const { orderData, setOrderData } = orderDataState
  const t = useTranslate()
  
  const handleClick = (option) => {
    if (option.disabled) {
      return
    }
    setState(setOrderData, 'paymentMethod', option.title)
  }

  return (
    <PayMethodOptionsContainer>
      {PAYMENT_OPTIONS.map((option) => {
        const isChecked = option.title === orderData.paymentMethod
        const className = isChecked ? 'svg-icon icon-circle' : ''
        return (
          <li key={option.title}>
            <Label onClick={() => handleClick(option)} checked={isChecked}>
              <CustomTableCheckbox
                type="radio"
                checked={isChecked}
                className={'custom-checkbox ' + className}
                style={{ justifySelf: 'left', gridRow: '1/3' }}
                onKeyPress={() => handleClick(option)}
              />
              <PayMethodIcon className={option.icon} checked={isChecked} />
              <Content disabled={option.disabled}>{t(option.content)}</Content>
              <Hint>{t(option.hint)}</Hint>
            </Label>
          </li>
        )
      })}
    </PayMethodOptionsContainer>
  )
}

export default PayMethodSelection

const PayMethodOptionsContainer = styled.ul`
  display: grid;
  list-style: none;
  padding: var(--space-5);
  background-color: var(--box-background);
  border-radius: var(--bdr-2);
  gap: var(--space-4);
`

const Label = styled.label`
  display: inline-grid;
  grid-template-columns: 24px auto 1fr;
  align-items: center;
  gap: 0 var(--space-3);
`

const Content = styled.span`
  grid-column: 3;
  line-height: var(--lh-2);
  font-size: var(--fs-3);
  font-weight: var(--fw-2);
  color: ${(props) => (props.disabled ? 'var(--text-color-secondary)' : '')};
`

const Hint = styled.span`
  font-size: var(--fs-2);
  color: var(--text-color-secondary);
  grid-column: 3;
  line-height: var(--lh-2);
`

const PayMethodIcon = styled.span`
  width: 30px;
  height: 30px;
  grid-row: 1/3;
  transition-duration: var(--hover-duration);
  background-color: ${(props) => (props.checked ? 'var(--pay-method-icon-active-color)' : 'var(--grey-600)')};
`
