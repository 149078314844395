import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import ToggleButton from '../../../components/toggleButton/ToggleButton'
import { FORM_ERRORS, SELECT_OPTIONS } from '../../../utils/constants/constants'
import {
  ErrorMessage,
  Label,
  OptionDescription,
  OptionEntry,
  PageHeadline,
  WizardForm
} from '../../../utils/elements/miscElements'
import { setState, someParticipantsUsingTimer } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'

const MailSettings = ({ data, setData, emailIsValid }) => {
  const t = useTranslate()
  const usingTimer = someParticipantsUsingTimer(data.participants)

  return (
    <>
      <PageHeadline>{t('settings')}</PageHeadline>
      <ScrollContainer>
        <WizardForm style={{ gap: 'var(--space-7)' }}>
          <OptionEntry hasHint>
            <Label fullWidth>{t('emailType')}</Label>
            <ToggleButton options={SELECT_OPTIONS.emailTypes} data={data} setData={setData} dataKey="emailType" />
            <OptionDescription description="emailTypeDescription" />
          </OptionEntry>
          {usingTimer && (
            <OptionEntry hasHint>
              <Label fullWidth>{t('startTimerAutomaticallyAfterEmail')}</Label>
              <ToggleButton options={SELECT_OPTIONS.yesNo} data={data} setData={setData} dataKey="startTimerOnSend" />
              <OptionDescription description="activateOptionTimeStarts" />
            </OptionEntry>
          )}
          <OptionEntry hasHint>
            <div style={{ display: 'flex', gap: 'var(--space-3)' }}>
              <div>
                <Label fullWidth>{t('replyToName')}</Label>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  value={data.replyToName}
                  onChange={(e) => setState(setData, 'replyToName', e.target.value)}
                />
              </div>
              <div>
                <Label fullWidth>{t('replyToEmail')}</Label>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  value={data.replyToEmail}
                  onChange={(e) => setState(setData, 'replyToEmail', e.target.value)}
                  onBlur={() => setState(setData, 'formErrorsVisible', !emailIsValid)}
                  className={data.formErrorsVisible && !emailIsValid ? 'has-error' : ''}
                />
                <ErrorMessage visible={data.formErrorsVisible && !emailIsValid}>
                  {t(FORM_ERRORS.emailInvalid)}
                </ErrorMessage>
              </div>
            </div>
            <OptionDescription style={{ marginTop: '20px' }} description="replyToDescription" />
          </OptionEntry>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default MailSettings
