import { useContext, useState } from 'react'
import { GreyButton } from '../../../../utils/elements/miscElements'
import { TableContext } from '../../DataTable'
import useTranslate from '../../../../utils/hooks/useTranslate'
import { AppContext } from '../../../../utils/context/AppContext'
import { loadArchivedData, setStatusBar, updateEntities } from '../../../../utils/helper/Helper'
import { getPromises } from '../../../../utils/helper/loginUtils'
import { STATUS_BAR_TYPES } from '../../../../utils/constants/constants'
import { ButtonLabelContainer, FilterLabel } from '../../../filter/filterUtils'

const ArchiveButton = () => {
  const { gotoPage, toggleAllRowsSelected } = useContext(TableContext)
  const context = useContext(AppContext)
  const { showArchivedItems, setShowArchivedItems, setUpdatesArePaused } = context
  const t = useTranslate()
  const [icon, setIcon] = useState('icon-archive')

  const handleClick = async () => {
    gotoPage(0)
    toggleAllRowsSelected(false)

    if (context.archiveLoaded) {
      setShowArchivedItems(!showArchivedItems)
      return
    }

    setUpdatesArePaused(true)
    setIcon('spinner')
    const archiveIsEmpty = await loadArchive()
    if (!archiveIsEmpty) {
      setShowArchivedItems(!showArchivedItems)
    }
    setUpdatesArePaused(false)
    setIcon('icon-archive')
  }

  const loadArchive = async () => {
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.loading,
      text: 'loadingArchive',
      setVisible: true
    })

    const data = await loadArchivedData(context)

    if (!data) {
      return
    }

    const allAssessmentUuids = [...data.assessment_uuids.archived, ...data.assessment_uuids.visible]
    const promises = getPromises(allAssessmentUuids, context, null, null, true)
    const values = await Promise.all(promises)

    delete data.assessment_uuids

    let tmpParticipants = []
    values
      .filter((value) => value)
      .forEach((value) => {
        if (value.assessment) {
          data.assessments.push(value.assessment)
          data.usedConfigs.push(value.usedConfig)
        }
        data.pts = data.pts.concat(value.pts)
        data.results = data.results.concat(value.results)
        tmpParticipants = tmpParticipants.concat(value.participants)
      })

    const uniqueParticipants = [...new Map(tmpParticipants.map((p) => [p.pNr, p])).values()]
    data.participants = uniqueParticipants

    const responseData = { response: { data: data } }
    const archiveIsEmpty = Object.values(responseData.response.data).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    )

    updateEntities(responseData.response.data, context)

    context.setArchiveLoaded(true)
    setStatusBar({
      controller: context.statusBarController,
      type: archiveIsEmpty ? STATUS_BAR_TYPES.notice : STATUS_BAR_TYPES.success,
      text: archiveIsEmpty ? 'archiveIsEmpty' : 'archiveLoaded',
      setVisible: true
    })

    return archiveIsEmpty
  }

  return (
    <>
      <ButtonLabelContainer>
        <FilterLabel>{t('archive')}</FilterLabel>
        <GreyButton
          data-tip={t('showHideArchive')}
          data-for="archive-button-tooltip"
          className={showArchivedItems ? ' active' : ''}
          onClick={handleClick}>
          <span className={`svg-icon ${icon}`} />
        </GreyButton>
      </ButtonLabelContainer>
    </>
  )
}

export default ArchiveButton
