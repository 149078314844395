import React from 'react'
import AssessmentBaseSettingsTile from '../tiles/AssessmentBaseSettingsTile'
import AssessmentTimeSettingsTile from '../tiles/AssessmentTimeSettingsTile'
import AssessmentAdvancedOptionsTile from '../tiles/AssessmentAdvancedOptionsTile'
import { TileContainer } from '../table/tableElements'
import { isPotentials, isPotentialsCrossTest } from '../../utils/helper/Helper'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import useTranslate from '../../utils/hooks/useTranslate'
import AssessmentSecuritySettingsTile from '../tiles/AssessmentSecuritySettingsTile'

const AssessmentSettingsTab = ({ assessment }) => {
  const canEdit = CapabilitiesHelper.canEditAssessment(assessment)
  const disabledNotice = getDisabledNotice(assessment)
  const t = useTranslate()
  const configType = assessment.relatedConfig.configType
  const showSecurityTile = isPotentials(configType) || isPotentialsCrossTest(configType)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {disabledNotice && (
        <span className="text-secondary" style={{ marginBottom: 'var(--space-3)' }}>
          {t(disabledNotice)}
        </span>
      )}
      <TileContainer style={{ maxWidth: '900px' }}>
        <AssessmentBaseSettingsTile {...{ assessment, canEdit }} />
        <AssessmentTimeSettingsTile {...{ assessment, canEdit }} />
        {showSecurityTile && <AssessmentSecuritySettingsTile {...{ assessment, canEdit }} />}
        <AssessmentAdvancedOptionsTile {...{ assessment, canEdit }} />
      </TileContainer>
    </div>
  )
}

export default AssessmentSettingsTab

const getDisabledNotice = (assessment) => {
  if (assessment.isArchived()) return 'testSettingsCannotBeEditedInArchive'
  if (!assessment.relatedProcess.isEditor()) return 'noEditorRightsForTestSettings'
  return ''
}
