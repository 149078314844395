import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import InfoPopUp from './InfoPopUp'

const PriceInfoButton = ({ productPrice }) => (
  <div style={{ marginLeft: 'auto' }}>
    <Button className="svg-icon icon-info" tabIndex="-1" data-tip={productPrice} data-for="global" />
    <ReactTooltip
      id="global"
      aria-haspopup="true"
      effect="solid"
      place="right"
      backgroundColor="var(--c-tooltip-bg)"
      textColor="var(--tooltip-text-color)"
      getContent={(productPrice) => <InfoPopUp productPrice={productPrice} />}
    />
  </div>
)

export default PriceInfoButton

const Button = styled.span`
  padding: 0;
  background-color: var(--color-price-info-button);
  border-radius: var(--bdr-round);
  height: var(--icon-1);
  width: var(--icon-1);
  transform: translateY(2px);
  @media (max-width: 600px) {
    display: none;
  }
`
