import { PT_STATUS } from '../../utils/constants/constants'
import { TileHeadlineContainer } from '../../utils/elements/miscElements'
import { getPtLink } from '../../utils/helper/Helper'
import AccessDataDisplay from '../accessDataDisplay/AccessDataDisplay'
import { Tile, TileHeadline } from '../table/tableElements'

const PtAccessDataTile = ({ pt }) => {
  const headline = pt.relatedAssessment.assessmentName
  const disabledNotice = getPtDisabledNotice(pt)
  const accessData = {
    link: getPtLink(pt.referenceToken),
    token: pt.ptNumber
  }

  return (
    <Tile width="250px">
      <TileHeadlineContainer className="headline-container">
        <TileHeadline content={headline} />
      </TileHeadlineContainer>
      <AccessDataDisplay {...{ accessData, disabledNotice }} />
    </Tile>
  )
}

export default PtAccessDataTile

const getPtDisabledNotice = (pt) => {
  if (pt.isArchived()) {
    return 'archivedTestNotice'
  }
  switch (pt.ptStatus) {
    case PT_STATUS.finished:
      return 'finishedTestNotice'
    case PT_STATUS.expired:
      return 'expiredTestNotice'
    case PT_STATUS.deactivated:
      return 'deactivatedTestNotice'
    default:
      return ''
  }
}
