import { getFormattedPrice, getFormattedNumber, setState, getCreditPrices } from '../../../utils/helper/Helper'
import { getPriceSingle, getQuantityDiscount } from '../shopUtils'
import {
  CreditButtonContainer,
  CheckboxElement,
  NumberCredits,
  DiscountLabel,
  Label,
  Container,
  Price
} from '../shopElements'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'

const CreditSelectButtons = ({ setOrderData, calculatedSum, selectedButtonState, numberCredits }) => {
  const context = useContext(AppContext)
  const options = getOptions(calculatedSum, context.completeDataSet.customer)
  const { selectedButton, setSelectedButton } = selectedButtonState

  const handleButtonClick = (e, option, buttonIndex) => {
    e.preventDefault()
    setState(setOrderData, 'numberCredits', option.count)
    setSelectedButton(buttonIndex)
  }

  return (
    <CreditButtonContainer>
      {options.map((option, i) => (
        <CheckboxElement
          key={i}
          onClick={(e) => handleButtonClick(e, option, i)}
          count={option.count}
          checked={selectedButton === i && numberCredits === option.count}
          isCalculated={option.isCalculated}
          style={{ position: 'relative', height: '100px' }}
        >
          <CreditPackage
            count={option.count}
            price={option.price}
            checked={selectedButton === i && numberCredits === option.count}
            isCalculated={option.isCalculated}
          />
        </CheckboxElement>
      ))}
    </CreditButtonContainer>
  )
}

export default CreditSelectButtons

export const CreditPackage = ({ count, price, checked }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const customer = context.completeDataSet.customer
  const creditPrices = getCreditPrices(customer)
  const d = getQuantityDiscount(price, creditPrices)
  const discount = d.discount

  return (
    <Container>
      <NumberCredits>{getFormattedNumber(count, context.language)}</NumberCredits>
      <Price>
        {getFormattedPrice(price, context.language, 2)}{' '}
        <Label className="uppercase-label" {...{ checked }}>
          / {t('each')}
        </Label>
      </Price>
      {discount > 0 && (
        <span>
          <Label className="uppercase-label" {...{ checked }}>
            {t('youSave')}{' '}
          </Label>
          <DiscountLabel>{d.rounded}&nbsp;%</DiscountLabel>
        </span>
      )}
    </Container>
  )
}

const getOptions = (calculatedSum, customer) => {
  const hasCreditPrice1Euro = customer.hasCreditPrice1Euro
  const creditPrices = getCreditPrices(customer)
  const options = [...creditPrices]
  const optionCount = options.length
  if (calculatedSum > 0) {
    options[optionCount + 1] = {
      count: calculatedSum,
      price: getPriceSingle(calculatedSum, creditPrices),
      isCalculated: true
    }
  }

  if (hasCreditPrice1Euro) {
    options.map((option) => (option.price = 1))
  }

  return options
}
