import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const TabTrackingHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <TabTrackingHelpEnglish />
  } else {
    return <TabTrackingHelpGerman />
  }
}

export default TabTrackingHelp

const TabTrackingHelpGerman = () => (
  <div>
    <p>Hier können Sie einstellen, ob Sie Fensterwechsel während der Testung erfassen möchten.</p>
    <p>
      Ein Fenster-Wechsel-Ereignis wird erfasst, wenn das Fenster oder der Browser-Tab verlassen und später wieder
      betreten wird, zwischen Verlassen und Betreten mindestens 2 Sekunden liegen und eine Aufgabe mit Zeitbegrenzung
      läuft.
    </p>
    <p>
      Wird die Testumgebung bis zu 5 Mal verlassen, erscheint der Hinweis <em>wenig</em>, bei mehr als 5 Mal{' '}
      <em>häufig</em>. Wir empfehlen, Teilnehmer mit häufigen Fensterwechseln aus dem weiteren Auswahlprozess
      auszuschließen.
    </p>
  </div>
)

const TabTrackingHelpEnglish = () => (
  <div>
    <p>Here you can set whether you want to track window switches during the test.</p>
    <p>
      A window switch event is recorded when the window or browser tab is left and later re-entered, at least 2 seconds
      have passed between leaving and re-entering, and a time-limited task is running.
    </p>
    <p>
      If the test environment is left up to 5 times, the notice <em>infrequent</em> appears; if it is more than 5 times,
      the notice <em>frequent</em> appears. We recommend excluding participants with frequent window switches from the
      further selection process.
    </p>
  </div>
)
