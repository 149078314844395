import { useContext } from 'react'
import { getCustomCols } from '../../components/table/tableCols/customCols'
import { TableLink } from '../../components/table/tableElements'
import {
  CONTENT_TYPES,
  ENTITIES,
  FIELD_CATEGORIES,
  FIELD_LABELS,
  FILTER_TYPES,
  MissingReferenceNotice
} from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { AppContext } from '../../utils/context/AppContext'
import {
  getFormattedNumber,
  getTableLinkParams,
  showMissingReferenceNotice,
  showRelatedEntities
} from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDateCreatedCol } from '../../components/table/tableCols/tableCols'
import { FilterContext } from '../../utils/context/FilterContext'
import { DownloadInvoiceButtonLight } from '../../utils/elements/miscElements'
import useRedirect from '../../utils/hooks/useRedirect'

export const getColsForCreditsTable = (customFieldDefinitions) => {
  const defaultCols = [
    freeTextCol,
    bookingKeyDescriptionCol,
    creditsValueCol,
    processCol(),
    assessmentCol(),
    pNrCol(),
    ptNumberCol(),
    bookingNrCol,
    getDateCreatedCol('bookingCreated', FIELD_CATEGORIES.booking),
    invoiceNoCol,
    commentCol
  ]
  const customCols = getCustomCols(customFieldDefinitions, false, 'relatedParticipant', ENTITIES.bookings)
  return [...defaultCols, ...customCols]
}

const freeTextCol = {
  Header: 'freeTextFilter',
  id: 'freeText',
  accessor: (row) =>
    row.ptNumber +
      row.pNr +
      row.bookingNr +
      (row.comment || '') +
      row.relatedAssessment.assessmentName +
      row.relatedProcess.processName +
      row.relatedParticipant.pFullName +
      row.relatedParticipant?.customFields?.map((cf) => cf.content).join() || '',
  filter: 'like',
  filterOptions: { type: FILTER_TYPES.textInput },
  canExport: false,
  show: false
}

const bookingKeyDescriptionCol = {
  Header: 'bookingType',
  id: 'bookingKeyDescription',
  accessor: 'bookingKeyDescription',
  canExport: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.booking },
  Cell: ({ value }) => {
    const t = useTranslate()
    return t(value)
  }
}

const creditsValueCol = {
  Header: 'value',
  accessor: 'creditsValue',
  id: 'creditsValue',
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.booking, contentType: CONTENT_TYPES.number },
  canExport: true,
  textAlign: 'right',
  Cell: ({ value }) => {
    const context = useContext(AppContext)
    const lang = context.language
    return (
      <div style={{ textAlign: 'right' }}>
        {value > 0 ? (
          <span style={{ color: 'var(--positive-booking-color)' }}>+{getFormattedNumber(value, lang)}</span>
        ) : (
          <span style={{ color: 'var(--negative-booking-color)' }}>{getFormattedNumber(value, lang)}</span>
        )}
      </div>
    )
  }
}

const bookingNrCol = {
  Header: 'bookingNumber',
  id: 'bookingNr',
  accessor: 'bookingNr',
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.booking },
  canExport: true
}

const commentCol = {
  Header: 'comment',
  id: 'comment',
  canExport: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.booking },
  accessor: (row) => row.comment || ''
}

const invoiceNoCol = {
  Header: 'invoiceNo',
  id: 'invoiceNo',
  canExport: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.booking },
  accessor: (row) => row.invoiceNo,
  Cell: ({ row }) => {
    return row.original.invoiceNo ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span>{row.original.invoiceNo}</span>
        <DownloadInvoiceButtonLight invoiceNo={row.original.invoiceNo} showLabel={false} />
      </div>
    ) : (
      <></>
    )
  }
}

const pNrCol = () => ({
  Header: FIELD_LABELS.pNumber,
  accessor: (row) => row?.pNr || '',
  id: 'pNr',
  show: true,
  canExport: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.participant },
  Cell: ({ row }) => {
    const { addLinkFilters } = useContext(FilterContext)
    const redirect = useRedirect()

    const bookingKey = row.original.bookingKey
    if (!showRelatedEntities(bookingKey)) {
      return <></>
    }

    const participant = row.original.relatedParticipant
    const tableLinkParams = getTableLinkParams(participant, 'pNr', 'participants', FIELD_LABELS.pNumber)

    return (
      <>
        {showMissingReferenceNotice(participant, bookingKey) ? (
          <MissingReferenceNotice />
        ) : (
          <TableLink
            className={tableLinkParams.className}
            onClick={() => {
              addLinkFilters(tableLinkParams.filterParams)
              redirect(PAGES.participants)
            }}>
            {row.original.pNr}
          </TableLink>
        )}
      </>
    )
  }
})

const ptNumberCol = () => ({
  Header: FIELD_LABELS.ptNumber,
  accessor: (row) => row?.ptNumber || '',
  id: 'ptNumber',
  show: true,
  canExport: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.pt },
  Cell: ({ row }) => {
    const redirect = useRedirect()
    const { addLinkFilters } = useContext(FilterContext)

    const bookingKey = row.original.bookingKey
    if (!showRelatedEntities(bookingKey)) {
      return <></>
    }

    const pt = row.original.relatedPt
    const tableLinkParams = getTableLinkParams(pt, 'ptNumber', 'pts')

    return (
      <>
        {showMissingReferenceNotice(pt, bookingKey) ? (
          <MissingReferenceNotice />
        ) : (
          <TableLink
            className={tableLinkParams.className}
            onClick={() => {
              addLinkFilters(tableLinkParams.filterParams)
              redirect(PAGES.pts)
            }}>
            {pt.ptNumber}
          </TableLink>
        )}
      </>
    )
  }
})

const processCol = () => ({
  Header: 'process',
  canExport: true,
  accessor: (row) => row.relatedProcess.processName || '',
  id: 'processName',
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, label: 'processName' },
  show: true,
  Cell: ({ row }) => {
    const { addLinkFilters } = useContext(FilterContext)
    const redirect = useRedirect()

    const bookingKey = row.original.bookingKey
    if (!showRelatedEntities(bookingKey)) {
      return <></>
    }

    const process = row.original.relatedProcess
    const tableLinkParams = getTableLinkParams(process, 'processName', 'processes')

    return (
      <>
        {showMissingReferenceNotice(process, bookingKey) ? (
          <MissingReferenceNotice />
        ) : (
          <TableLink
            className={tableLinkParams.className}
            onClick={() => {
              addLinkFilters(tableLinkParams.filterParams)
              redirect(PAGES.processes)
            }}>
            {process.processName}
          </TableLink>
        )}
      </>
    )
  }
})

const assessmentCol = () => ({
  Header: 'doneTest',
  canExport: true,
  accessor: (row) => row.relatedAssessment.assessmentName || '',
  id: 'assessmentName',
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test },
  show: true,
  Cell: ({ row }) => {
    const redirect = useRedirect()
    const { addLinkFilters } = useContext(FilterContext)

    const bookingKey = row.original.bookingKey
    if (!showRelatedEntities(bookingKey)) {
      return <></>
    }

    const assessment = row.original.relatedAssessment

    const assessmentFilter = {
      field: { value: 'assessmentName' },
      value: { value: assessment?.assessmentName },
      tableName: 'assessments'
    }

    const processFilter = {
      field: { value: 'processName' },
      value: { value: assessment?.relatedProcess?.processName },
      tableName: 'assessments'
    }

    return (
      <>
        {showMissingReferenceNotice(assessment, bookingKey) ? (
          <MissingReferenceNotice />
        ) : (
          <TableLink
            className={assessment.isArchived() ? 'archived' : ''}
            onClick={() => {
              addLinkFilters([assessmentFilter, processFilter])
              redirect(PAGES.assessments)
            }}>
            {assessment.assessmentName}
          </TableLink>
        )}
      </>
    )
  }
})
