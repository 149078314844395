import { useStripe } from '@stripe/react-stripe-js'
import { useContext } from 'react'
import { STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import { setStatusBar } from '../../../utils/helper/Helper'
import { createStripePaymentIntent } from './stripeUtils'

const StripeButton = ({ orderData, checkout, buyButtonState }) => {
  const context = useContext(AppContext)
  const stripe = useStripe()
  const { buyButtonDisabled, setBuyButtonDisabled } = buyButtonState

  const handleSubmit = async (orderData, context) => {
    setBuyButtonDisabled(true)
    const clientSecret = await createStripePaymentIntent(orderData, context)

    if (clientSecret) {
      confirmPayment(clientSecret)
    } else {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: 'paymentFailed'
      })
    }
  }

  const confirmPayment = async (clientSecret) => {
    if (!stripe) {
      return
    }

    const { error } = await stripe.confirmPayment({
      clientSecret: clientSecret,
      confirmParams: {},
      redirect: 'if_required'
    })

    if (error) {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: 'paymentFailed'
      })
    } else {
      checkout()
    }
  }

  return <ButtonPrimary disabled={buyButtonDisabled} content="buy" onClick={() => handleSubmit(orderData, context)} />
}

export default StripeButton
