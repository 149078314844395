import { useContext } from 'react'
import { DEFAULT_LANGUAGE } from '../constants/constants'
import { AppContext } from '../context/AppContext'

const usePageUrl = () => {
  const { language } = useContext(AppContext)
  const getUrlFromPage = (page) => page.urlSlugs[language] || page.urlSlugs[DEFAULT_LANGUAGE]

  return getUrlFromPage
}

export default usePageUrl
