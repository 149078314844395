import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { InfoElement, InfoList } from '../../utils/elements/miscElements'
import { getFlatArray, isCrossTest } from '../../utils/helper/Helper'
import { Tile, TileHeadline } from '../table/tableElements'

const AddAssessmentBaseInfoTile = ({ data }) => {
  const context = useContext(AppContext)
  const { processes } = context.completeDataSet
  const assessmentArrays = processes.map((process) => process.relatedAssessments)
  const assessmentsFlat = getFlatArray(assessmentArrays)
  const referenceTest = assessmentsFlat.find((ass) => ass.assessmentUuid === data.referenceAssessmentUuid)
  const processName = getProcessName(data, processes)

  return (
    <Tile width="200px">
      <TileHeadline content="baseInfos" />
      <InfoList>
        <InfoElement label="processes" value={processName} />
        {isCrossTest(data.configVariant) && <InfoElement label="referenceTest" value={referenceTest.assessmentName} />}
        <InfoElement label="newTestName" value={data.assessmentName} />
      </InfoList>
    </Tile>
  )
}

export default AddAssessmentBaseInfoTile

const getProcessName = (data, processes) => {
  if (data.createNewProcess) {
    return data.processName
  }
  return processes?.find((process) => process.processUuid === data.processUuid)?.processName
}
