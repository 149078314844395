import { useContext } from 'react'
import { DEFAULT_LANGUAGE } from '../constants/constants'
import dict from '../constants/translations'
import { AppContext } from '../context/AppContext'
import { isString } from '../helper/Helper'

const useTranslate = () => {
  const context = useContext(AppContext)
  const lang = context.language

  const getTranslation = (key) => dict?.[key]?.[lang] || dict?.[key]?.[DEFAULT_LANGUAGE] || key

  const getTranslatedOptionsArray = (key) =>
    key.map((entry) => ({
      ...entry,
      label: getTranslation(entry.label)
    }))

  return (key, ...params) => {
    if (isOptionsArray(key)) {
      return getTranslatedOptionsArray(key)
    }

    const text = getTranslation(key)

    if (isString(text)) {
      return text.replace(/\{(\d)\}/g, (_, number) => params[number])
    }

    if (typeof text === 'function') {
      return text(...params)
    }

    return key
  }
}

export default useTranslate

const isOptionsArray = (key) => Array.isArray(key)
