import React, { useContext } from 'react'
import { ContentContainer, SmallDescription } from '../components/table/tableElements'
import DataTable from '../components/table/DataTable'
import styled from 'styled-components'
import { PageHeadline } from '../utils/elements/miscElements'
import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import useTranslate from '../utils/hooks/useTranslate'
import { AppContext } from '../utils/context/AppContext'
import { DEFAULT_LANGUAGE } from '../utils/constants/constants'

const Downloads = () => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const columns = React.useMemo(() => getCols(), [])
  const rowData = getRowData(t)

  return (
    <ScrollContainer type="main">
      <ContentContainer style={{ maxWidth: '600px' }}>
        <PageHeadline>{t('documents')}</PageHeadline>

        {context.language !== DEFAULT_LANGUAGE && <p>Please note that all documents are in German.</p>}
        <DataTable data={rowData} columns={columns} tableName="downloads" hideArchiveButton hideResultCount />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Downloads

const getCols = () => [
  {
    Header: '',
    accessor: 'documentName',
    Cell: ({ row }) => {
      const t = useTranslate()
      return (
        <div style={{ display: 'grid', gridTemplateColumns: '30px 1fr' }}>
          <PdfIcon className="svg-icon icon-pdf" />
          <span style={{ lineHeight: 'var(--lh-2)' }}>{t(row.original.documentName)}</span>
          {row.original.documentNote && (
            <SmallDescription style={{ maxWidth: '400px' }}>{t(row.original.documentNote)}</SmallDescription>
          )}
        </div>
      )
    },
    disableSortBy: true
  },
  {
    Header: '',
    accessor: 'documentUrl',
    Cell: ({ value }) => {
      const t = useTranslate()
      return (
        <DownloadButton rel="noreferrer" target="_blank" href={value}>
          <span className="svg-icon icon-arrow-down" style={{ marginRight: 'var(--space-2)' }} />
          <span>{t('download')}</span>
        </DownloadButton>
      )
    },
    disableSortBy: true
  }
]

const getRowData = (t) => [
  {
    documentName: 'priceList',
    documentUrl: '/downloads/Perseo_Preisliste.pdf',
    documentNote: `${t('lastUpdate')}: 04/2023`
  },
  {
    documentName: 'customerAreaManual',
    documentUrl: '/downloads/Kundenbereich_Manual.pdf',
    documentNote: 'customerAreaManualNote'
  },
  {
    documentName: 'termsAndConditions',
    documentUrl: '/downloads/Perseo_AGB.pdf',
    documentNote: `${t('lastUpdate')}: 01/2022`
  },
  {
    documentName: (
      <span>
        {t('interpretationNotesFor')} PERSEO <i>Potentials</i>
      </span>
    ),
    documentUrl: '/downloads/Interpretationshinweise_Potentials.pdf',
    documentNote: 'performanceTestResultNotes'
  },
  {
    documentName: 'orderProcessingContract',
    documentUrl: '/downloads/Perseo_AV-Vertrag.pdf',
    documentNote: 'sampleContractWithTOMs'
  },
  {
    documentName: 'bitvTestReport',
    documentUrl: '/downloads/BITV-Test.pdf',
    documentNote: 'accessibilityProof'
  },
  {
    documentName: 'hetzner27001Certificate',
    documentUrl: '/downloads/Hetzner_27001.pdf',
    documentNote: 'hetzner27001Proof'
  }
]

const DownloadButton = styled.a`
  display: inline-flex;
  text-decoration: none;
  color: inherit;
  padding: var(--space-3) var(--space-4);
  font-size: var(--fs-2);
  background-color: var(--small-button-bg-color);
  border-radius: var(--bdr-2);
  align-items: center;
  transition: background-color var(--hover-duration);
  &:hover {
    background-color: var(--small-button-hover-color);
    transition: background-color var(--hover-duration);
  }
`

const PdfIcon = styled.span`
  grid-row: 1/3;
  align-self: center;
  width: 20px;
  height: 20px;
  background-color: var(--icon-color);
`
