import styled from 'styled-components'

export const NewsletterContainer = styled.div`
  display: flex;
  background-color: var(--box-background);
  padding: var(--space-5);
  border-radius: var(--bdr-2);
  width: 220px;
`

export const ListContainer = styled.ul`
  display: grid;
  gap: var(--space-3);
  list-style: none;
  margin-top: 0;
  background-color: var(--box-background);
  padding: var(--space-5);
  border-radius: var(--bdr-2);
  width: 220px;
`

export const ListElement = styled.li`
  display: flex;
`

export const Label = styled.label`
  display: inline-grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  font-size: var(--fs-3);
`
