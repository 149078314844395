import styled from 'styled-components'
import ArchiveButton from './ArchiveButton'
import ColumnSettingsButton from './columnController/ColumnSettingsButton'

const TableActions = ({ hideArchiveButton, showColumnController, allColumns }) => {
  return (
    <TableActionsContainer>
      {showColumnController && !hideArchiveButton ? (
        <div className="double-button-wrapper">
          <ColumnSettingsButton {...{ allColumns }} />
          <ArchiveButton />
        </div>
      ) : (
        <>
          {showColumnController && <ColumnSettingsButton {...{ allColumns }} />}
          {!hideArchiveButton && <ArchiveButton />}
        </>
      )}
    </TableActionsContainer>
  )
}

export default TableActions

const TableActionsContainer = styled.div`
  display: flex;
  gap: var(--space-3);
  margin-bottom: var(--space-3);
`

export const TableActionButton = styled.button`
  background-color: transparent;
  text-align: left;
  padding: 0;
  &:hover {
    text-decoration: underline;
    color: var(--link-color);
  }
`

export const FilterActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--space-5);
  position: relative;
`
