import React from 'react'
import { TileContainer } from '../table/tableElements'
import PtResultSummaryTile from '../tiles/PtResultSummaryTile'
import PtResultPreviewTile from '../tiles/PtResultPreviewTile'

const PtResultTab = ({ pt, barChartWidth }) => {
  mergeConfigData(pt)

  return (
    <TileContainer>
      <PtResultSummaryTile {...{ pt }} />
      <PtResultPreviewTile {...{ pt, barChartWidth }} />
    </TileContainer>
  )
}

export default PtResultTab

const mergeConfigData = (pt) => {
  pt.relatedResult.dimensionScores.map((resultDim) => {
    const thisDimension = pt.relatedConfig.dimensions.find((configDim) => configDim.dimensionNr === resultDim.dimensionNr)

    const alias = thisDimension?.dimensionAlias ? thisDimension?.dimensionAlias : thisDimension?.dimensionName
    const visibility = thisDimension?.dimensionVisibility

    return Object.assign(resultDim, { alias: alias }, { visibility: visibility })
  })
}
