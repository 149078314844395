import { getEntityLabel } from '../../utils/helper/Helper'
import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'
import { useContext } from 'react'
import { TableContext } from './DataTable'
import TableAddButton from './tableBar/tableActions/TableAddButton'

const HeadlineTableActionsContainer = ({ showAddButton }) => {
  const { tableName } = useContext(TableContext)

  return (
    <OuterContainer {...{ tableName }}>
      <FilterResultHeadline>
        <FilterResultNotice />
      </FilterResultHeadline>
      {showAddButton && <TableAddButton />}
    </OuterContainer>
  )
}

export default HeadlineTableActionsContainer

const FilterResultNotice = () => {
  const { rows, tableName } = useContext(TableContext)
  const resultCount = rows.length
  const t = useTranslate()
  const entityLabel = getEntityLabel(tableName, 2)
  return (
    <span>
      {t(entityLabel)} <span style={{ fontWeight: 'var(--fw-1)' }}>({resultCount.toLocaleString()})</span>
    </span>
  )
}

const OuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.tableName === 'users' ? 'flex-end' : '')};
  margin-bottom: ${(props) => (props.tableName === 'users' ? 'var(--space-5)' : '')};
  @media (max-width: 600px) {
    position: relative;
  }
`

const FilterResultHeadline = ({ children }) => {
  const { tableName } = useContext(TableContext)
  if (tableName === 'users') {
    return <h3 style={{ margin: 0, lineHeight: 1, fontSize: 'var(--fs-5)' }}>{children}</h3>
  }
  return <h2>{children}</h2>
}
