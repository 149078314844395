import PageGroup from '../pageGroup/PageGroup'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { fetchData, getLoggedInContact, updateEntities } from '../../utils/helper/Helper'
import { API_DATA, MODAL_TYPES } from '../../utils/constants/constants'
import { createDate } from '../../utils/helper/dateTimeHelper'
import { PAGES } from '../../utils/constants/pages'
import DateDisplay from '../dateDisplay/DateDisplay'

const getPages = (content) => {
  return content.map((page) => ({
    content: <div className="notification" dangerouslySetInnerHTML={{ __html: page }} />
  }))
}

export const getNotificationToDisplay = (notifications, isFirstLogin) => {
  if (notifications.length === 0) return null

  const welcomeNotification = notifications.find((notification) => notification.isWelcomeNotification)
  if (welcomeNotification && isFirstLogin && !welcomeNotification.seen) {
    return welcomeNotification
  }

  const regularNotifications = notifications.filter((n) => !n.isWelcomeNotification)
  const latestNotification = regularNotifications[0]
  if (!latestNotification?.seen && latestNotification?.content) {
    return latestNotification
  }

  return null
}

export const displayNotification = ({ notification, context, activePageId, setActivePageId, redirect }) => {
  const isWelcomeNotification = notification.isWelcomeNotification
  const date = isWelcomeNotification ? new Date() : createDate(notification.created)
  const formattedDate = <DateDisplay date={date} format="dd_month_yyyy" />
  const pages = getPages(notification.localizedContent)
  const buttonSecondary = getButtonSecondary(activePageId, setActivePageId, isWelcomeNotification, context.setModalOpen)
  const buttonPrimary = getButtonPrimary(
    activePageId,
    setActivePageId,
    pages,
    context.setModalOpen,
    context.setOnboardingTourVisible,
    isWelcomeNotification,
    redirect
  )
  const content = <PageGroup activePageId={activePageId} pages={pages} margin="0" isInNotification />

  context.setModalProps({
    smallHeadline: formattedDate || 'Willkommen',
    hideCancelButton: true,
    content: content,
    buttonSecondary: buttonSecondary,
    buttonPrimary: buttonPrimary,
    showCloseButton: true,
    type: MODAL_TYPES.notification,
    isWelcomeNotification: isWelcomeNotification
  })
  context.setModalOpen(true)
}

export const saveNotificationAsRead = async (notification, context) => {
  const contact = getLoggedInContact(context.completeDataSet)
  const payload = {
    notificationUuids: [notification.notificationUuid],
    contact: contact.contactUuid,
    seen: true
  }

  const responseData = await fetchData(payload, API_DATA.notifications.endPointEdit, context, null, false)

  try {
    updateEntities(responseData.response.data, context)
  } catch (e) {
    console.error(e)
  }
}

const getButtonSecondary = (activePageId, setActivePageId, isWelcomeNotification, setModalOpen) => {
  if (isWelcomeNotification) return <ButtonSecondary content="getStarted" onClick={() => setModalOpen(false)} />
  const showBackButton = activePageId > 0
  if (showBackButton) return <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />
}

const getButtonPrimary = (
  activePageId,
  setActivePageId,
  pages,
  setModalOpen,
  setOnboardingTourVisible,
  isWelcomeNotification,
  redirect
) => {
  if (isWelcomeNotification) {
    return (
      <ButtonPrimary
        content="startIntroduction"
        onClick={() => {
          setModalOpen(false)
          redirect(PAGES.participants)
          setOnboardingTourVisible(true)
        }}
      />
    )
  }

  const showNextButton = activePageId < pages.length - 1
  if (showNextButton) return <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />
  return <ButtonPrimary content="close" onClick={() => setModalOpen(false)} />
}
