import de from 'date-fns/locale/de'
import PenButton from './PenButton'
import { useState, useContext, useEffect } from 'react'
import { registerLocale } from 'react-datepicker'
import { AppContext } from '../../utils/context/AppContext'
import {
  getValueToDisplay,
  saveCustomInput,
  validateDates,
  InputElement,
  getCopyToPtModalProps,
  getRelatedPtsForBulkInCustomInput
} from './customInputUtils'
import {
  CustomInputContainer,
  ButtonContainer,
  EditDiscardButton,
  Icon,
  EditSaveButton,
  CustomInputLabel,
  CustomInputContent
} from './customInputElements'
import { isDate, mailIsValid } from '../../utils/helper/Helper'
import { showCopyToPtModal } from '../modal/modalUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import SecondaryButton from './SecondaryButton'
import { MODAL_TYPES } from '../../utils/constants/constants'
import styled from 'styled-components'
import { getCorrectNorm } from '../scoringEditors/normEditor/normUtils'

registerLocale('de', de)

const CustomInput = (props) => {
  const context = useContext(AppContext)
  const { completeDataSet } = context
  const { assessments, pts, customNorms } = completeDataSet
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState(getValueToDisplay(props))
  const [isValid, setIsValid] = useState(true)
  const t = useTranslate()

  const openEditMode = () => setEditMode(true)

  useEffect(() => {
    setValue(getValueToDisplay(props))
  }, [editMode])

  useEffect(() => {
    if (isDate(value)) {
      const isValid = validateDates(value, props, assessments, pts)
      setIsValid(isValid)
    }
    if (props.type === 'email') {
      mailIsValid(value) ? setIsValid(true) : setIsValid(false)
    }
  }, [value, props, assessments, pts])

  const closeAndSave = async () => {
    if (!isValid) return
    const ptsForBulk = getRelatedPtsForBulkInCustomInput(pts, props, value)
    if (props.showCopyToPtAlert && ptsForBulk.validOnly.length > 0) {
      const modalProps = getCopyToPtModalProps(context, ptsForBulk, props, value, setEditMode, t)
      showCopyToPtModal(modalProps)
    } else {
      await saveCustomInput(props, value, context, t)
      setEditMode(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeAndDiscard()
    }
    if (e.key === 'Enter') {
      if (!editMode) setEditMode(true)
    }
  }

  const closeAndDiscard = () => {
    setEditMode(false)
  }

  const DimensionDisplay = props.DimensionDisplay
  const NormsTable = props.NormsTable

  return (
    <div>
      <CustomInputContainer
        tabIndex={editMode || props.disabled ? '' : 0}
        key={props.label}
        position={props.position}
        disabled={props.disabled}
        editMode={editMode}
        onKeyDown={handleKeyDown}>
        <CustomInputLabel editMode={editMode} className="text-secondary">
          {t(props.label)}
        </CustomInputLabel>

        {props.helpContent && <SecondaryButton label="help" onClick={() => openHelpModal(props, context)} />}

        {editMode ? (
          <>
            <div
              style={{ gridRow: 2, gridColumn: '1/3', marginTop: props.helpContent || props.normEditor ? '4px' : '' }}>
              <InputElement {...{ props, value, setValue, isValid, setIsValid, closeAndSave }} />
            </div>
            <ButtonContainer {...props}>
              <EditDiscardButton onClick={() => closeAndDiscard()}>
                <Icon red style={{ transform: 'scale(0.7)' }} className="svg-icon icon-x-noborder-mask" />
              </EditDiscardButton>
              <EditSaveButton disabled={!isValid} onClick={() => closeAndSave()}>
                <Icon className="svg-icon icon-check-noborder-mask" />
              </EditSaveButton>
            </ButtonContainer>
          </>
        ) : (
          <CustomInputContent {...props}>{props.content ? t(props.content) : t('empty')}</CustomInputContent>
        )}

        <PenButton onClick={openEditMode} />
      </CustomInputContainer>
      {DimensionDisplay && (
        <PreviewWrapper>
          <DimensionDisplay
            config={props.config}
            assessment={props.assessment}
            currentDimLogicNr={value}
            editBlocked={editMode}
          />
        </PreviewWrapper>
      )}
      {NormsTable && (
        <PreviewWrapper>
          <NormsTable norm={getCorrectNorm(props.assessment, value, customNorms)} />
        </PreviewWrapper>
      )}
    </div>
  )
}

export default CustomInput

const PreviewWrapper = styled.div`
  margin-top: var(--space-4);
`

const openHelpModal = (props, context) => {
  context.setModalProps({
    headline: props.label,
    content: props.helpContent,
    showCloseButton: true,
    hideFooter: true,
    type: MODAL_TYPES.help
  })
  context.setModalOpen(true)
}
