import { TableContentContainer } from '../tableElements'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'
import { PARTICIPANT_SPECIAL_NAMES } from '../../../utils/constants/constants'
import { getNameLabel } from '../../../entities/Participant'

export const ParticipantNameTableCell = ({ pFullName }) => {
  const isSpecialName = PARTICIPANT_SPECIAL_NAMES.includes(pFullName)
  const t = useTranslate()
  return (
    <TableContentContainer>
      <NameContainer {...{ isSpecialName }}>{t(getNameLabel(pFullName))}</NameContainer>
    </TableContentContainer>
  )
}

const NameContainer = styled.span`
  color: ${(props) => (props.isSpecialName ? 'var(--text-color-secondary)' : '')};
  font-style: ${(props) => (props.isSpecialName ? 'italic' : '')};
  hyphens: auto;
`
