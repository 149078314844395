import {
  PageHeadline,
  SuccessMessageContainer,
  SuccessMessageHeadline,
  SuccessMessageIcon,
  SuccessMessageBalance,
  NewBalanceLabel
} from '../../../utils/elements/miscElements'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { getCreditBalance } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'

const CreditShopSuccessPage = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const creditBookings = context.completeDataSet.creditBookings
  const credit_balance = getCreditBalance(creditBookings)

  return (
    <>
      <PageHeadline style={{ visibility: 'hidden' }}>{t('thankYouForOrder')}</PageHeadline>
      <ScrollContainer>
        <SuccessMessageContainer>
          <SuccessMessageHeadline>{t('thankYouForOrder')}</SuccessMessageHeadline>
          <NewBalanceLabel>{t('newAccountBalance')}</NewBalanceLabel>
          <SuccessMessageBalance balance={credit_balance} />
          <SuccessMessageIcon style={{ borderRadius: 0 }} className="svg-icon icon-money-bag" />
        </SuccessMessageContainer>
      </ScrollContainer>
    </>
  )
}

export default CreditShopSuccessPage
