import styled from 'styled-components'

export const ActionsContainer = styled.div`
  padding-bottom: var(--space-5);
`

export const ActionButtonContainer = styled.div`
  display: grid;
  gap: var(--space-3);
`

export const BulkActionButton = styled.button`
  display: inline-flex;
  align-items: center;
  text-align: left;
  padding: 0 var(--space-4);
  background-color: var(--button-bg-color);
  min-height: 36px;
  font-size: var(--fs-3);
  border-radius: var(--bdr-2);
  &:hover {
    background-color: var(--button-hover-color);
  }
`

export const BulkActionButtonContent = styled.span`
  display: inline-grid;
  grid-template-columns: 16px 1fr 0;
  justify-items: flex-start;
  align-items: center;
  gap: var(--space-3);
`

export const BulkActionIcon = styled.span``

export const EditableField = styled.div`
  display: grid;
  gap: var(--space-1);
  div:nth-child(2) {
    margin-bottom: var(--space-1);
  }
`

export const EditFieldsContainer = styled.div`
  display: grid;
  gap: var(--space-5);
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: var(--space-3);
  position: absolute;
  bottom: var(--space-6);
  background-color: var(--box-background);
`

export const OuterContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: var(--box-background);
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 999;
  width: var(--bulk-sidebar-width);
  flex-direction: column;
  transition: box-shadow 1s, transform 200ms ease-in;
  transform: ${(props) => (props.isVisible ? '' : 'translateX(var(--bulk-sidebar-width))')};
  box-shadow: ${(props) =>
    props.isVisible
      ? '0 0 2px 0 rgb(0 0 0 / 24%), 0 16px 24px 2px rgb(0 0 0 / 5%), 0 6px 30px 5px rgb(0 0 0 / 6%), 0 8px 10px -5px rgb(0 0 0 / 10%)'
      : 'none'};

  @media (max-width: 600px) {
    width: calc(100% - var(--main-nav-width));
    transform: ${(props) => `translateY(${getOffset(props)})`};
    box-shadow: none;
    border-top: 1px solid var(--border-grey);
  }
`

const getOffset = (props) => {
  if (props.isVisible) {
    return props.isExpandedMobileView ? 0 : 'calc(100% - var(--header-height))'
  }
  return '100%'
}

export const InnerContainer = styled.div`
  position: relative;
  margin: var(--space-4);
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 600px) {
    margin: var(--inset);
  }
`

export const HeaderButtonContainer = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--inset);
  }
`

export const BulkMenuVisibilityButton = styled.button`
  display: none;
  white-space: nowrap;
  background-color: var(--button-secondary-bg-darker);
  padding: var(--space-2) var(--space-3);
  border-radius: var(--bdr-2);
  font-size: var(--fs-2);
  font-weight: var(--fw-2);
  @media (max-width: 600px) {
    display: block;
  }
`
