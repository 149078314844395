import { SettingsContainer } from './sharedElements/sharedElements'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import TabGroup from '../tabGroup/TabGroup'
import PersonalDataComponent from './PersonalDataComponent'
import useTranslate from '../../utils/hooks/useTranslate'
import ChangePasswordComponent from './ChangePasswordComponent'

const UserSettingsComponent = () => {
  const t = useTranslate()

  const content = (
    <SettingsContainer>
      <ScrollContainer type="settings">
        <h2>{t('userSettings')}</h2>
        <TabGroup
          position="settings-nav"
          tabs={[
            {
              label: 'personalData',
              id: 'personalData',
              content: <PersonalDataComponent />
            },
            {
              label: 'password',
              id: 'password',
              content: <ChangePasswordComponent />
            }
          ]}
        />
      </ScrollContainer>
    </SettingsContainer>
  )

  return content
}

export default UserSettingsComponent
