import { PAGES } from '../../utils/constants/pages'
import { getTableLinkParams, getUniqueProcessesFromPtList } from '../../utils/helper/Helper'
import { TableLink } from '../table/tableElements'
import { useContext } from 'react'
import { FilterContext } from '../../utils/context/FilterContext'
import useRedirect from '../../utils/hooks/useRedirect'

const ProcessListForTable = ({ ptList }) => {
  const processes = getUniqueProcessesFromPtList(ptList)
  const redirect = useRedirect()
  return (
    <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
      {processes.map((process, i) => {
        const { addLinkFilters } = useContext(FilterContext)
        const tableLinkParams = getTableLinkParams(process, 'processName', 'processes')
        const key = `${process}_${i}`
        return (
          <li className={tableLinkParams.className} key={key}>
            <TableLink
              onClick={() => {
                addLinkFilters(tableLinkParams.filterParams)
                redirect(PAGES.processes)
              }}>
              {process.processName}
            </TableLink>
          </li>
        )
      })}
    </ul>
  )
}

export default ProcessListForTable
