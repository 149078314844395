import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import BillingOverview from '../subcomponents/BillingOverview'
import {
  PageHeadline,
  InfoList,
  InfoListElement,
  InfoElement,
  InfoElementBillPreview
} from '../../../utils/elements/miscElements'
import { getPriceSingle, getQuantityDiscount } from '../shopUtils'
import { getFormattedPrice, getFormattedNumber, getCreditPrices } from '../../../utils/helper/Helper'
import { TileHeadline, Tile, TileContainer } from '../../../components/table/tableElements'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AgbLink } from '../../agb'

const CreditShopCheckInput = ({ orderData, billingDataValid, billingData }) => {
  const t = useTranslate()
  return (
    <>
      <PageHeadline>{t('reviewOrder')}</PageHeadline>
      <ScrollContainer>
        <TileContainer style={{ padding: 0 }}>
          <Tile width="250px">
            <TileHeadline content="order" />
            <OrderInfos orderData={orderData} />
          </Tile>
          <Tile width="250px">
            <TileHeadline content="invoiceData" />
            <BillingOverview
              orderData={orderData}
              isOnConfirmationPage
              billingDataValid={billingDataValid}
              billingData={billingData}
            />
          </Tile>
          <Tile width="250px">
            <TileHeadline content="invoiceAmount" />
            <PriceInfos orderData={orderData} />
          </Tile>
          <Tile width="250px">
            <TileHeadline content="legalInformation" />
            <InfoList>
              <AgbLink />
            </InfoList>
          </Tile>
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default CreditShopCheckInput

const OrderInfos = ({ orderData }) => {
  const context = useContext(AppContext)
  const customer = context.completeDataSet.customer
  const creditPrices = getCreditPrices(customer)
  const creditPrice = getPriceSingle(orderData.numberCredits, creditPrices)
  const orderInfos = {
    numberCredits: getFormattedNumber(orderData.numberCredits, context.language),
    pricePerCredit: getFormattedPrice(creditPrice, context.language),
    quantityDiscount: getQuantityDiscount(creditPrice, creditPrices).inverted
  }
  return (
    <InfoList>
      <InfoElement label="creditCount" value={orderInfos.numberCredits} highlightValue />
      <InfoElement label="unitPrice" value={orderInfos.pricePerCredit} highlightValue />
      <InfoElement label="quantityDiscount" value={orderInfos.quantityDiscount} highlightValue />
    </InfoList>
  )
}

const PriceInfos = ({ orderData }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const lang = context.language
  const prices = {
    subTotal: getFormattedPrice(orderData.subTotal, lang),
    discount: orderData.discountRate * 100,
    subTotalMinusDiscount: getFormattedPrice(orderData.subTotal * orderData.discountRate * -1, lang),
    totalBeforeTaxes: getFormattedPrice(orderData.total, lang),
    taxRate: orderData.taxRate * 100,
    taxes: getFormattedPrice(orderData.total * orderData.taxRate, lang),
    totalAfterTaxes: getFormattedPrice(orderData.total * (1 + orderData.taxRate), lang)
  }

  return (
    <InfoList style={{ gap: 'var(--space-2)' }} type="billing">
      {orderData.discountRate > 0 && (
        <>
          <InfoElementBillPreview label="subtotal" value={prices.subTotal} />
          <InfoElementBillPreview
            label={`${t('minus')} ${prices.discount} % ${t('discount')}`}
            value={prices.subTotalMinusDiscount}
          />
          <Spacer />
        </>
      )}
      <InfoElementBillPreview label="netSum" value={prices.totalBeforeTaxes} />
      <InfoElementBillPreview label={`${t('vat')} ${prices.taxRate} %`} value={prices.taxes} />
      <Spacer />
      <InfoElementBillPreview large bold label="grossSum" value={prices.totalAfterTaxes} />
    </InfoList>
  )
}

const Spacer = () => (
  <InfoListElement style={{ gridTemplateColumns: '1fr 100px' }}>
    <div style={{ gridColumn: 2, borderBottom: '1px solid var(--grey-300)' }} />
  </InfoListElement>
)
