import dict from '../../../utils/constants/translations'
import useTranslate from '../../../utils/hooks/useTranslate'
import MatchingNormHelp from '../../helpModal/MatchingNormHelp'
import WeightsHelp from '../../helpModal/WeightsHelp'
import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Table, WizardStep, WizardStepContainer } from '../userManualElements'
const Chapter3English = () => {
  const t = useTranslate()
  return (
    <>
      <h2 id="chapter-3">3 Main Tables</h2>
      <h3 id="chapter-3-1">3.1 Processes</h3>
      <div className="textbox">
        <p>A process...</p>
        <ul>
          <li>
            is a folder for related tests. For example, you can create a process per job advertisement or professional
            group.
          </li>
          <li>can contain multiple tests and types of tests.</li>
          <li>can only be created by administrators.</li>
        </ul>
      </div>
      <h4 id="chapter-3-1-1">3.1.1 Settings, Actions, and Overall Result</h4>

      <h5>Settings</h5>

      <p>
        Under <em>Basic Settings</em>, you can change the name and description of the process, as well as the test
        design. The selected test design will be used for all tests associated with this process.
      </p>
      <h5>Actions</h5>
      <ProcessActionsTable />
      <h5>Combination of Test Scores</h5>
      <p>
        Tests that provide a grade or similar result can be combined into an overall result per participant. To use test
        score combination, choose <B>Combine Test Scores</B>; otherwise, choose <B>Do Not Combine Test Scores</B>. When
        score combination is enabled, you can adjust the weighting of individual tests. The sum of individual weights
        must add up to 100%. The overall result is the weighted average of all participant test results.
      </p>
      <div className="textbox important">
        <p>The overall result is calculated only when the participant has completed all weighted tests.</p>
      </div>
      <p>
        Click <B>Save</B> to apply your settings. The overall results will be updated accordingly.
      </p>
      <h4 id="chapter-3-1-2">3.1.2 Creating Processes</h4>

      <p>
        Open the process <em>Create Process</em>. Use one of the following methods:
      </p>
      <ul>
        <li>
          Click <B>Create</B> in the left sidebar, and then click <B>New Process</B>.
        </li>
        <li>
          In the process overview, click <B>Create New Process</B>.
        </li>
      </ul>
      <div className="textbox important">
        <p>
          If all users have administrator permissions, the step <em>Set Permissions</em> will be skipped.
        </p>
      </div>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Name and Design</WizardStep>
            <ul>
              <li>Give the new process a name.</li>
              <li>If multiple test designs are available, select a test design.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Set Permissions</WizardStep>
            <ul>
              <li>Set user permissions for the new process.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Review Inputs</WizardStep>
            <ul>
              <li>Review your inputs.</li>
              <li>
                Click <B>Create Process</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-3-1-3">3.1.3 Change Permissions for Processes</h4>
      <div className="textbox important">
        <p>This function is not available if all users have administrator permissions.</p>
      </div>
      <p>
        To open the process <em>Change Permissions</em>: Select at least one process and click <B>Change Permissions</B>{' '}
        in the batch processing.
      </p>

      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Change Permissions for x Users</WizardStep>
            <ul>
              <li>
                Change user permissions by selecting the desired option under <em>Permissions</em>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Review Inputs</WizardStep>
            <ul>
              <li>Review your inputs</li>
              <li>
                Click <B>Change Permissions</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h3 id="chapter-3-2">3.2 Tests</h3>
      <div className="textbox">
        <p>A test...</p>
        <ul>
          <li>is a collection of test modules.</li>
          <li>is always associated with a process.</li>
          <li>can have a start and end date.</li>
          <li>can have multiple associated participants.</li>
        </ul>
      </div>
      <h4 id="chapter-3-2-1">3.2.1 Settings and Actions</h4>
      <h5>Settings</h5>
      <TestSettingsTable />

      <h5>Actions</h5>
      <TestActionsTable />

      <h4 id="chapter-3-2-2">3.2.2 Creating Tests</h4>

      <p>
        Open the process <em>Create Test</em>. Use one of the following methods:
      </p>
      <ul>
        <li>
          Click <B>Create</B> in the left sidebar, and then click <B>New Test</B>.
        </li>
        <li>
          Click <B>Create New Test</B> in the <em>Tests</em> table.
        </li>
        <li>
          Click <B>Add Test</B> in the action list of a process.
        </li>
      </ul>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Select Test</WizardStep>
            <ul>
              <li>
                Select the desired test from the list and mark it on the left side. Use the filters above to find the
                correct test.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Variant</WizardStep>
            <ul>
              <li>Select one of the available test variants.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3. Process and Test Name</WizardStep>
            <ul>
              <li>Select a process in which the new test should be integrated.</li>
              <li>Give the new test a name.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>4. Test Period and Duration</WizardStep>
            <ul>
              <li>
                If you want to set a start time, click <B>Set Start Time...</B> and then click <B>Select Date/Time</B>.
              </li>
              <li>
                If you want to set an end time, click <B>Set End Time...</B> and then click <B>Select Date/Time</B>.
              </li>
              <li>
                If you want to set a timer, click <B>Set Timer...</B> and then select the duration in days.
              </li>
              <li>
                If you want to limit the time from test start, click <B>Limit Time from Test Start...</B> and then
                select the desired duration in hours and minutes.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>5. Additional Settings</WizardStep>{' '}
            <ul>
              <li>
                If you want to use random item selection, select <B>Activated (Recommended)</B>. Activating the{' '}
                <em>Random Item Selection</em> option ensures that candidates do not get the same but comparable items.
                This reduces cheating attempts. This option is available only for performance tests.
              </li>
              <li>
                Specify whether the test can be started via the test overview{' '}
                <span className="textlink internal-link" data-scroll-to="chapter-2-2-1">
                  (see 2.2.1)
                </span>
                .
              </li>
              <li>Specify whether the test will be supervised. This option is available only for performance tests.</li>
              <li>Select the test language.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>6. Select Report Modules</WizardStep>{' '}
            <ul>
              <li>If you want to use optional report modules, select them here.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>7. Review Inputs</WizardStep>{' '}
            <ul>
              <li>Review your inputs.</li>
              <li>
                Click <B>Create Test</B>.
              </li>
            </ul>{' '}
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-3-2-3">3.2.3 Report Modules</h4>
      <p>
        An automatic PDF report is generated for each test. The reports consist of individual report modules. Depending
        on the test type and use case, you can use different report modules. You specify which modules to use when
        creating the test.
      </p>
      <ReportModuleTable />

      <h4 id="chapter-3-2-4">3.2.4 {t('scoring')}</h4>
      <h5>{t('weighting')}</h5>
      <WeightsHelp />
      <h5>{t('jobMatching')}</h5>
      <MatchingNormHelp />

      <h3 id="chapter-3-3">3.3 Participants</h3>

      <div className="textbox">
        <p>A participant...</p>
        <ul>
          <li>is a natural person</li>
          <li>is uniquely identified by the participant number (e.g., P-12345-67890)</li>
          <li>should only be present in the system once</li>
          <li>has one or more associated testings</li>
        </ul>
      </div>
      <h4 id="chapter-3-3-1">3.3.1 Settings and Actions</h4>
      <h5>Settings</h5>
      <p>
        Under <em>Settings</em> {'>'} <em>Participant Info</em>, you can change the personal data of the participant
        (first name, last name, gender, email address). Under the <em>Privacy Settings</em> section, you can choose
        between <em>Named Testing</em> and <em>Anonymous Testing</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-2">
          (see 3.3.2)
        </span>
        .
      </p>
      <h5>Actions</h5>
      <ParticipantActionsTable />

      <h4 id="chapter-3-3-2">3.3.2 Anonymous Testing</h4>

      <p>
        To ensure optimal data privacy, PERSEO offers anonymous testings. In anonymous testings, participants are
        identified solely by their participant number. In this case, PERSEO does not receive any personally identifiable
        information of the participants. Anonymous testings proceed as follows:
      </p>
      <ul>
        <li>
          You create the desired number of anonymous participants in the customer area. Each participant has a unique
          participant number.
        </li>
        <li>
          You maintain the mapping of participant numbers to individuals independently, e.g., in the form of an Excel
          list. The participant names remain with you. PERSEO cannot make the association.
        </li>
        <li>
          After completing the test, you can assign the test results to the respective individuals using the participant
          number.
        </li>
      </ul>

      <h4 id="chapter-3-3-3">3.3.3 Creating Participants</h4>

      <p>
        Open the process <em>Create Participant</em>. Use one of the following methods:
      </p>
      <ul>
        <li>
          Click <B>Create</B> in the left sidebar, and then click <B>New Participant</B>.
        </li>
        <li>
          Click <B>Create New Participant</B> in the participant overview.
        </li>
        <li>
          Click <B>Add Participant</B> in the action list of a process.
        </li>
        <li>
          Click <B>Add Participant</B> in the action list of a test.
        </li>
      </ul>
      <div className="textbox important">
        <p>
          If you start the process from the action menu of a test, the <em>Process and Tests</em> page will be skipped.
        </p>
      </div>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Process and Tests</WizardStep>
            <ul>
              <li>Select the desired process.</li>
              <li>Select one or more tests to which you want to add participants.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Data Source</WizardStep>{' '}
            <ul>
              <li>
                Choose whether to create blank participants (A) or upload an existing participant list (B). The next
                process step will be selected accordingly.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3A. Number of Participants and Privacy</WizardStep>{' '}
            <ul>
              <li>Determine how many blank participants you want to add.</li>
              <li>Choose whether you want to conduct tests with names or anonymously.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>3B. Participant List</WizardStep>{' '}
            <ul>
              <li>
                Upload your participant list by either dragging the list into the designated area (drag and drop) or
                clicking on the area and then selecting a file. If the column mapping is incorrect, you can manually
                correct it.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>4. Review Inputs</WizardStep>{' '}
            <ul>
              <li>Review your inputs.</li>
              <li>
                If you want to add participants to paid tests and no invoice data is stored in the system yet, you must
                enter your invoice data first. To do this, click <B>Add</B> under <em>Invoice Data</em> and fill out the
                form.
              </li>
              <li>
                Click <B>Add</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h4 id="chapter-3-3-4">3.3.4 Assigning Existing Participants to Additional Tests</h4>

      <p>
        Open the process <em>Add to other Tests</em>. Use one of the following methods:
      </p>
      <ul>
        <li>
          Select a participant, open the action menu, and click <B>Add to other Tests</B>.
        </li>
        <li>
          Select at least one participant and click <B>Add to other Tests</B> in the batch processing.
        </li>
      </ul>
      <TimeLineContainer className="wizard">
        <Entry>
          <WizardStepContainer>
            <WizardStep>1. Process and Tests</WizardStep>
            <ul>
              <li>Select the desired process.</li>
              <li>Select one or more tests to which you want to add participants.</li>
            </ul>
          </WizardStepContainer>
        </Entry>
        <Entry>
          <WizardStepContainer>
            <WizardStep>2. Review Inputs</WizardStep>
            <ul>
              <li>Review your inputs.</li>
              <li>
                If you want to add participants to paid tests and no invoice data is stored in the system yet, you must
                enter your invoice data first. To do this, click <B>Add</B> under <em>Invoice Data</em> and fill out the
                form.
              </li>
              <li>
                Click <B>Add</B>.
              </li>
            </ul>
          </WizardStepContainer>
        </Entry>
      </TimeLineContainer>

      <h3 id="chapter-3-4">3.4 Testings</h3>

      <div className="textbox">
        <ul>
          <li>
            Each participant can complete one or more tests. Participating in a test is called testing. So, a testing is
            the combination of a test and a participant.
          </li>
          <li>As soon as you add a participant to a test, a new testing is automatically created.</li>
          <li>A testing is uniquely identified by the TAN (e.g., PAS-ABCDE-12345).</li>
        </ul>
      </div>

      <h4 id="chapter-3-4-1">3.4.1 Settings and Actions</h4>

      <h5>Settings</h5>
      <PtSettingsTable />
      <h5>Actions</h5>
      <PtActionsTable />
      <h4 id="chapter-3-4-2">3.4.2 Window Switches</h4>

      <p>
        This section indicates whether a participant has left the test interface during the test. A window change event
        is recorded when:
      </p>
      <ul>
        <li>the window or browser tab is exited and then re-entered,</li>
        <li>at least 2 seconds elapse between exiting and re-entering, and</li>
        <li>a timed task is running.</li>
      </ul>
      <p>
        If the testing environment is left up to 5 times, the <em>few</em> message will appear; for more than 5 times,
        the <em>frequent</em> message will appear. We recommend excluding participants with frequent window switches
        from further selection processes.
      </p>
    </>
  )
}

export default Chapter3English

const ProcessActionsTable = () => {
  const header = ['Action', 'Description', 'Restrictions']
  const data = [
    [
      'Download CSV Table',
      'Downloads a table with participant data and results, organized by participants. Each row corresponds to a participant.'
    ],
    [
      'Duplicate Process',
      'Creates a copy of the process along with all associated tests, but without participants and testings.',
      'Administrator'
    ],
    [
      'Add Participant',
      <span key="ProcessActionsTable-addParticipant">
        Takes you to the page to create participants <em> (see 3.3.3)</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-3">
          (see 3.3.3)
        </span>
      </span>,
      'Editor'
    ],
    [
      'Add Test',
      <span key="ProcessActionsTable-addTest">
        Takes you to the page to create a test <em> (see 3.2.2)</em>{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-2-2">
          (see 3.2.2)
        </span>
      </span>,
      'Editor'
    ],
    ['Archive', 'Archives the process and all associated tests, participants, and testings.', 'Administrator'],
    ['Unarchive', 'Unarchives the process and all associated tests, participants, and testings.', 'Administrator'],
    [
      'Delete Process',
      'Deletes the process along with all associated tests, participants, and testings.',
      'Administrator'
    ]
  ]
  return <Table name="process-actions" {...{ header, data }} />
}

const TestSettingsTable = () => {
  const header = ['Setting', 'Description', 'Default Value']
  const data = [
    ['Name', 'The name of the test; visible to candidates'],
    ['Comment', 'Additional information about the test; visible only to customers'],
    [
      dict.validFrom.en,
      <span key="TestSettingsTable-validFrom">
        Start time of the test{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'immediate'
    ],
    [
      dict.validUntil.en,
      <span key="TestSettingsTable-validUntil">
        End time of the test{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'unlimited'
    ],
    [
      dict.invitedDuration.en,
      <span key="TestSettingsTable-timer">
        Individual time limit for the test{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'not set'
    ],
    [
      dict.startedDuration.en,
      <span key="TestSettingsTable-startedDuration">
        Time available to a participant from the start{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'not set'
    ],
    [
      dict.useChildItems.en,
      <span key="TestSettingsTable-usechildItems">
        {dict.useChildItemsExplanation.en} {dict.performanceTestsOnly.en}{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-5-1">
          (see 2.5.1)
        </span>
      </span>,
      'activated'
    ],
    [
      dict.tabTracking.en,
      <span key="TestSettingsTable-tabTracking">
        {dict.tabTrackingExplanation.en} {dict.performanceTestsOnly.en}{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-5-2">
          (see 2.5.2)
        </span>
      </span>,
      'activated'
    ],
    [
      dict.cameraSupervision.en,
      <span key="TestSettingsTable-cameraSupervision">
        {dict.cameraSupervisionExplanation.en} {dict.performanceTestsOnly.en}{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-5-3">
          (see 2.5.3)
        </span>
      </span>,
      'deactivated'
    ],
    [dict.inHub.en, dict.hubExplanation.en, 'show'],
    [dict.isSupervised.en, 'Indication is for statistical purposes only', 'without supervision']
  ]
  return <Table name="test-settings" {...{ header, data }} />
}

const TestActionsTable = () => {
  const header = ['Action', 'Description', 'Restrictions']
  const data = [
    ['Download CSV Table', 'Downloads a table with participant data and results. Each row corresponds to a testing.'],
    ['Download Reports', 'Downloads a zip folder with all PDF reports.'],
    [
      'Add Participant',
      <span key="TestActionsTable-addParticipant">
        Takes you to the page to create participants{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-3">
          (see 2.3.3)
        </span>
      </span>,
      'Editor'
    ],
    ['Archive', 'Archive the test and all associated participants and testings.', 'Administrator'],
    [
      'Unarchive',
      'Unarchives the test and all associated participants and testings. If associated processes are archived, they are also unarchived.',
      'Administrator'
    ],
    ['Delete Test', 'Deletes the test along with all associated participants and testings.', 'Administrator']
  ]
  return <Table name="test-actions" {...{ header, data }} />
}

const ParticipantActionsTable = () => {
  const header = ['Settings', 'Description', 'Default Value']
  const data = [
    [
      'Add to other Tests',
      <span key="ParticipantActionsTable-addToTest">
        Adds the participant to another test{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-3-3-5">
          (see 3.3.5)
        </span>
      </span>,
      'Editor'
    ],
    [
      'Start / Reset Timer',
      <span key="ParticipantActionsTable-startTimer">
        Starts or resets the timer{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4-2">
          (see 2.4.2)
        </span>
      </span>,
      'Editor'
    ],
    [
      'Send Email',
      <span key="ParticipantActionsTable-sendMail">
        Takes you to the <em>Send Emails</em> page{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-2-2">
          (see 2.2.2)
        </span>
      </span>,
      'Editor'
    ],
    ['Archive', 'Archives the participant and all associated testings.', 'Administrator'],
    [
      'Unarchive',
      'Unarchives the participant and all associated testings. If associated tests or processes are archived, they are also unarchived.',
      'Administrator'
    ],
    ['Delete Participant', 'Deletes the participant along with all associated testings.', 'Administrator']
  ]
  return <Table name="participant-actions" {...{ header, data }} />
}

const PtSettingsTable = () => {
  const header = ['Action', 'Description', 'Default Value']
  const data = [
    [
      dict.validFrom.en,
      <span key="PtSettingsTable-validFrom">
        Start time of the test{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'immediate'
    ],
    [
      dict.validUntil.en,
      <span key="PtSettingsTable-validUntil">
        End time of the test{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'unlimited'
    ],
    [
      dict.invitedDuration.en,
      <span key="PtSettingsTable-timer">
        Individual time limit for the test{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'not set'
    ],
    [
      dict.startedDuration.en,
      <span key="PtSettingsTable-startedDuration">
        Time available to a participant from the start{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4">
          (see 2.4)
        </span>
      </span>,
      'not set'
    ],
    [
      'Time Extension',
      'Here you can set a percentage time extension as compensation for participants with disabilities.',
      'none'
    ],
    [
      'Invitation Status',
      'Here you can track whether you have already sent an invitation or reminder for this testing. If you have sent an email from the customer area, the invitation status will be updated automatically. However, you can also set the status manually.',
      'not set'
    ]
  ]
  return <Table name="pt-settings" {...{ header, data }} />
}

const PtActionsTable = () => {
  const header = ['Action', 'Description', 'Restrictions']
  const data = [
    [
      'Start / Reset Timer',
      <span key="PtActionsTable-startTimer">
        Starts or resets the timer{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-4-2">
          (see 2.4.2)
        </span>
      </span>,
      'Editor'
    ],
    [
      'Send Email',
      <span key="PtActionsTable-sendMails">
        Takes you to the <em>Send Emails</em> page{' '}
        <span className="textlink internal-link" data-scroll-to="chapter-2-2-2">
          (see 2.2.2)
        </span>
      </span>,
      'Editor'
    ],
    ['Disable / Enable', 'Disables / enables the testing. Disabled testings cannot be conducted.', 'Editor'],
    ['Archive', 'Archive the testing', 'Administrator'],
    ['Unarchive', 'Unarchives the testing and potentially parent elements', 'Administrator'],
    ['Delete Testing', 'Deletes the testing', 'Administrator']
  ]
  return <Table name="pt-settings" {...{ header, data }} />
}

const ReportModuleTable = () => {
  const header = ['Module', 'Test Types', 'Description']
  const data = [
    [
      'Result Overview',
      'Potentials, Traits, Goals',
      'Provides a comprehensive profile with all measurement results. Included in every test by default.'
    ],
    [
      'Participant Feedback',
      'Potentials, Traits',
      'Result feedback that you can send to your participants. Includes interpretive text and no details on selection criteria.'
    ],
    ['Traits, Goals', 'Result Description', 'Provides brief explanations for each measurement result.'],
    ['Job Matching', 'Traits, Goals', 'Compare your candidates based on their alignment with your job requirements.'],
    [
      'Potentials and Risks',
      'Traits',
      'Provides detailed indications of expected behavior in the professional environment.'
    ],
    ['Interview Questions', 'Traits', 'Provides relevant interview questions to delve into the test results.']
  ]
  return <Table name="pt-settings" {...{ header, data }} />
}
