import styled from 'styled-components'
import Arrow from '../../../arrow/Arrow'
import { useContext } from 'react'
import { PaginationContext } from '../../DataTable'

const PagerButtons = () => {
  const tableInnerContainer = document.getElementsByClassName('table-inner-container')[0]
  return (
    <PagerButtonContainer>
      <PrevPageButton {...{ tableInnerContainer }} />
      <NextPageButton {...{ tableInnerContainer }} />
    </PagerButtonContainer>
  )
}

export default PagerButtons

const PagerButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-3);
`

const PagerButton = styled.button`
  background-color: transparent;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:hover {
    .svg-icon {
      background-color: var(--small-action-icon-hover-color) !important;
    }
  }
`

const PrevPageButton = ({ tableInnerContainer }) => {
  const { canPreviousPage, previousPage } = useContext(PaginationContext)

  return (
    <PagerButton
      onClick={() => {
        tableInnerContainer.scrollTop = 0
        previousPage()
      }}
      disabled={!canPreviousPage}>
      <Arrow
        color={!canPreviousPage ? 'var(--small-action-icon-disabled-color)' : ''}
        direction="left"
        style={{ padding: 0, width: '14px' }}
      />
    </PagerButton>
  )
}

const NextPageButton = ({ tableInnerContainer }) => {
  const { canNextPage, nextPage } = useContext(PaginationContext)

  return (
    <PagerButton
      onClick={() => {
        tableInnerContainer.scrollTop = 0
        nextPage()
      }}
      disabled={!canNextPage}>
      <Arrow
        color={!canNextPage ? 'var(--small-action-icon-disabled-color)' : ''}
        direction="right"
        style={{ padding: 0, width: '14px' }}
      />
    </PagerButton>
  )
}
