import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { setState } from '../../utils/helper/Helper'
import TestSelectElement from './TestSelectElement'

const AssessmentSelection = ({ assessmentList, selectedAssessments, setData }) => {
  const context = useContext(AppContext)
  const usedConfigs = context.completeDataSet.usedConfigs
  
  const updateState = (selectedUuid) => {
    const selectedAssessmentUuids = getSelectedAssessmentUuids(selectedAssessments, selectedUuid)
    setState(setData, 'assessmentUuids', selectedAssessmentUuids)
  }

  return (
    <div style={{ display: 'grid', gap: 'var(--space-2)' }}>
      {assessmentList &&
        assessmentList.map((assessment, i) => {
          const isSelected = selectedAssessments.includes(assessment.assessmentUuid)
          const config = usedConfigs.find((config) => config.configUuid === assessment.configUuid)

          return (
            <TestSelectElement
              key={assessment + i}
              assessment={assessment}
              config={config}
              isSelected={isSelected}
              updateState={updateState}
            />
          )
        })}
    </div>
  )
}

export default AssessmentSelection

const getSelectedAssessmentUuids = (selectedAssessments, selectedUuid) => {
  if (selectedAssessments.includes(selectedUuid)) {
    return selectedAssessments.filter((ass) => ass !== selectedUuid)
  }
  return selectedAssessments.concat(selectedUuid)
}
