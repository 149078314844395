import dict from '../../../utils/constants/translations'
import CameraHelp from '../../helpModal/CameraHelp'
import ChildItemsHelp from '../../helpModal/ChildItemsHelp'
import TabTrackingHelp from '../../helpModal/TabTrackingHelp'
import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Image, Table, WizardStep, WizardStepContainer } from '../userManualElements'

const Chapter2 = () => (
  <>
    <h2 id="chapter-2">2 Grundfunktionen</h2>
    <h3 id="chapter-2-1">2.1 Mit Tabellen arbeiten</h3>
    <h4 id="chapter-2-1-1">2.1.1 Tabellen filtern und sortieren</h4>
    <h5>Filtern</h5>
    <p>
      Filter helfen Ihnen, den Überblick über große Mengen an Tests, Teilnehmern und Ergebnissen zu behalten. Um die
      Daten einer Tabelle zu filtern, können Sie entweder den Freitextfilter nutzen oder Filterabfragen erstellen.
    </p>
    <p> Um eine neue Filterabfrage hinzuzufügen, folgen Sie bitte diesen Schritten:</p>
    <ul>
      <li>
        Klicken Sie auf <B>+ Filter</B>, um die Filteroptionen anzuzeigen.
      </li>

      <li>
        Wählen Sie im Dropdown-Menü das <B>Feld</B> aus, das Sie filtern möchten. Das Feld bezieht sich auf die
        spezifische Datenkategorie, wie zum Beispiel <em>Teilnehmername</em> oder <em>Ergebnis</em>.
      </li>
      <li>
        Nachdem Sie das Feld ausgewählt haben, bestimmen Sie die <B>Beziehung</B>, die angewendet werden soll. Die
        Beziehung definiert, wie der Wert, den Sie eingeben, mit den Daten verglichen wird. Beispiele für Beziehungen
        sind <em>ist</em>, <em>enthält</em> und <em>ist später als</em>.
      </li>
      <li>
        Geben Sie im letzten Schritt den <B>Wert</B> ein, der für den Filter verwendet werden soll. Der Wert hängt vom
        gewählten Feld und der Beziehung ab. Wenn Sie zum Beispiel das Feld <em>Erstellt</em> mit der Beziehung{' '}
        <em>ist später als</em> ausgewählt haben, können Sie ein Datum eingeben, um alle Tests anzuzeigen, die nach
        diesem Datum erstellt wurden.
      </li>
      <li>
        Nachdem Sie Feld, Beziehung und Wert ausgewählt haben, bestätigen Sie den Filter, indem Sie auf den Haken
        klicken.
      </li>
    </ul>
    <p>
      Sie können mehrere Filter gleichzeitig anwenden, um Ihre Suche weiter zu verfeinern. Um einen angewendeten Filter
      zu entfernen, klicken Sie auf das X-Symbol neben dem Filternamen.
    </p>
    <Image src="user_manual_images/filter-bar.png" caption="Abb. 4: Filterleiste" />
    <h5>Sortieren</h5>
    <p>
      Um eine Tabelle nach einem Feld aufsteigend zu sortieren, klicken Sie die jeweilige Spaltenüberschrift einmal an.
      Klicken Sie die Spaltenüberschrift erneut an, wird die Tabelle absteigend sortiert.
    </p>
    <h4 id="chapter-2-1-2">2.1.2 Schnelle Navigation</h4>
    <p>
      Um schnell die Elemente zu sehen, die Sie gerade bearbeiten wollen, besitzen viele Tabelleneinträge eine
      Linkfunktion. Wenn Sie sich z.B. auf der Seite <em>Verfahren</em> befinden und alle Teilnehmer dieses Verfahren
      bearbeiten möchten, können Sie auf die Zahl in der Spalte Teilnehmer klicken. Es öffnet sich daraufhin die Seite{' '}
      <em>Teilnehmer</em> und der Filter <em>Verfahren</em> ist vorausgewählt. Tabelleneinträge mit Linkfunktion werden
      durch Farbe und Unterstreichung hervorgehoben, wenn Sie den Cursor darüber bewegen.
    </p>
    <h4 id="chapter-2-1-3">2.1.3 Detailansicht</h4>
    <p>
      Viele Tabellen besitzen eine Detailansicht. Um die Detailansicht einer Zeile ein- und auszublenden, klicken Sie
      die Zeile an. Die Informationen in der Detailansicht sind in Reitern (Tabs) angeordnet. Um die Reiter
      durch­zuschalten, klicken Sie den jeweiligen Reiter an.
    </p>
    <h4 id="chapter-2-1-4">2.1.4 Aktionsmenü</h4>
    <p>
      In den Haupttabellen <em>Verfahren</em>, <em>Tests</em>, <em>Teilnehmer</em> und <em>Testungen</em> finden Sie am
      rechten Tabellenrand die Spalte <em>Aktionen</em>. Diese enthält einen Knopf mit drei Punkten <B>...</B>, mit dem
      Sie das Aktionsmenü einer Zeile öffnen können. Die dort verfügbaren Aktionen beziehen sich nur auf die jeweilige
      Zeile. Die verfügbaren Aktionen finden sich auch in der Detailansicht unter <em>Einstellungen</em> {'>'}{' '}
      <em>Aktionen</em>.
    </p>
    <h4 id="chapter-2-1-5">2.1.5 Archivieren, dearchivieren und löschen</h4>
    <p>
      Sie können Verfahren, Tests, Teilnehmer und Testungen archivieren, dearchivieren oder endgültig löschen. Alle drei
      Aktionen lassen sich über das Aktionsmenü oder die Stapelverarbeitung durchführen.
    </p>
    <p>
      Um archivierte Elemente anzuzeigen, klicken Sie auf <B>Archiv</B> in den Tabellen-Aktionen. Archivierte Elemente
      sind standardmäßig ausgeblendet und werden erst beim Aufruf geladen und angezeigt. Verknüpfte Elemente im Archiv
      (z.B. ein Test im Archiv, der einem sichtbaren Verfahren zugeordnet ist) werden ausgegraut dargestellt.
    </p>
    <p>
      Sie können archivierte Elemente jederzeit wieder dearchivieren. Gelöschte Elemente lassen sich nicht
      wiederherstellen.
    </p>
    <p>
      Um Datenintegrität zu gewährleisten, werden verknüpfte Elemente ebenfalls archiviert, dearchiviert und gelöscht.
    </p>
    <h4 id="chapter-2-1-6">2.1.6 Tabellenspalten anpassen</h4>
    <p>
      Um die Tabellenspalten anzupassen, klicken Sie in den Tabellen-Aktionen auf <B>Tabellenspalten</B>. Es öffnet sich
      ein Dialogfenster, in dem Sie die Sichtbarkeit und die Reihenfolge der Spalten verändern können. Klicken Sie
      anschließend auf <B>Speichern</B>, um Ihre Auswahl zu bestätigen, oder auf <B>Abbrechen</B>, um Ihre Änderungen zu
      verwerfen.
    </p>
    <Image src="user_manual_images/column-settings.png" caption="Abb. 5: Tabellenspalten anpassen" width="300" />
    <h4 id="chapter-2-1-7">2.1.7 Stapelverarbeitung</h4>
    <p>
      In den Haupttabellen <em>Verfahren</em>, <em>Tests</em>, <em>Teilnehmer</em> und <em>Testungen</em> können Sie
      mehrere Elemente gleichzeitig bearbeiten. Markieren Sie hierzu die gewünschten Zeilen, indem Sie die Checkboxen am
      linken Tabellenrand anklicken. Sobald eine Zeile markiert wurde, öffnet sich die Stapelverarbeitung am rechten
      Bildschirmrand.
    </p>
    <p>
      Je nach Tabelle, Nutzer-Rechten und gewählten Elementen stehen Ihnen hier verschiedene Aktionen und Einstellungen
      zur Verfügung.
    </p>
    <div className="textbox important">
      <p>
        Beachten Sie, dass nur Aktionen und Einstellungen angezeigt werden, die auf alle gewählten Elemente anwendbar
        sind. Zum Beispiel lassen sich Testzeiten für abgeschlossene Testungen nicht mehr bearbeiten. Wenn Sie sowohl
        abgeschlossene als auch nicht abgeschlossene Testungen markiert haben, stehen die Einstellungen der Testzeiten
        daher nicht zur Verfügung.
      </p>
    </div>
    <p>
      Um eine Einstellung zu ändern, klicken Sie auf <B>Ändern...</B>. Es öffnet sich ein Eingabefeld, in dem Sie den
      Wert anpassen können. Klicken Sie anschließend auf <B>Übernehmen</B>. Es öffnet sich ein Dialogfenster, in dem Sie
      die Änderungen nochmal bestätigen müssen.
    </p>
    <Image src="user_manual_images/confirm-changes.png" caption="Abb. 6: Änderungen bestätigen" width="300" />
    <h3 id="chapter-2-2">2.2 Teilnehmer zum Test einladen</h3>
    <p>
      Damit Ihre Bewerber an einem Test teilnehmen können, benötigen sie Zugangsdaten. Die Zugangsdaten können Sie
      entweder aus dem Kundenbereich exportieren und selbst versenden (z.B. über Outlook oder ein
      Bewerbermanagementsystem) oder Sie versenden E-Mails mit den Zugangsdaten direkt aus dem Kundenbereich.
    </p>
    <h4 id="chapter-2-2-1">2.2.1 Zugangsdaten exportieren und manuell versenden</h4>
    <h5>Einladungslink und Login-Code</h5>
    <p>
      Ihre Teilnehmer können sich entweder mit einem Login-Code unter{' '}
      <a className="textlink" rel="noreferrer" target="_blank" href="app.perseo-assessment.de">
        app.perseo-assessment.de
      </a>{' '}
      einloggen (Abb. 7) oder einen Einla­dungs­link benutzen. Sie brauchen Ihren Teilnehmern nur eine der beiden
      Informationen zu schicken.
    </p>
    <p>
      Der Login-Code ist praktisch, wenn Sie einen Test vor Ort durchführen möchten. So brauchen Sie Ihren Teilnehmern
      nur den Code auf den Platz zu legen (z.B. auf einem Zettel) und müssen nicht mühevoll den Link auf jedes Endgerät
      kopieren.
    </p>
    <Image src="user_manual_images/participant-login.png" caption="Abb. 7: Login-Seite für Teilnehmer" />
    <h5>Die Testübersicht nutzen</h5>
    <p>
      Geben die Teilnehmer die Teilnehmernummer (z.B. P-12345-67890) als Login-Code ein, gelangen sie zur Seite{' '}
      <em>Testübersicht</em> (Abb. 8). Von dort können sie alle für sie vorgesehenen und sichtbaren Tests in beliebiger
      Reihenfolge starten. Hier erhalten sie zudem weitere Informationen zu den Tests, wie z.B. Dauer und Umfang. Den
      Einladungslink zur Testübersicht erkennen Sie daran, dass er die Zeichenkette <em>?hub=</em> enthält.
    </p>
    <Image src="user_manual_images/test-overview.png" caption="Abb. 8: Testübersicht" />
    <div className="textbox important" style={{ marginTop: 'var(--space-5)' }}>
      <p>
        Achten Sie darauf, dass auf der Testübersicht nur Tests zur Auswahl stehen, die die Option{' '}
        <em>Auf Testübersicht anzeigen</em> aktiviert haben.
      </p>
    </div>
    <h5>Zu einem einzelnen Test einladen</h5>
    <p>
      Wenn Sie möchten, dass Ihre Teilnehmer nach dem Login direkt zum Test geleitet werden, müssen sie die TAN (z.B.
      PAS-ABCDE-12345) als Login-Code benutzen. Den Einladungslink für einen einzelnen Test erkennen Sie daran, dass er
      die Zeichenkette <em>?token=</em> enthält.
    </p>
    <p>
      Diese Option ist sinnvoll, wenn mehrere Tests vorgesehen sind und Sie die Einladungen separat verschicken möchten.
      Zum Beispiel könnten Sie zunächst alle Teilnehmer zu einem Leistungstest einladen und die Ergebnisse abwarten.
      Anschließend laden Sie die besten Teilnehmer zu einem Persönlichkeitstest ein.
    </p>
    <h5>Zugangsdaten finden und exportieren</h5>
    <p>Die Zugangsdaten finden Sie an verschiedenen Stellen:</p>
    <ul>
      <li>
        Verfahren {'>'} Aktionen {'>'} CSV-Tabelle downloaden
      </li>
      <li>
        Tests {'>'} Aktionen {'>'} CSV-Tabelle downloaden
      </li>
      <li>
        Teilnehmer {'>'} Detailansicht {'>'} Zugangsdaten
      </li>
      <li>
        Teilnehmer {'>'} Teilnehmer markieren {'>'} Stapelverarbeitung {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Als CSV Exportieren</span>
      </li>
      <li>
        Testungen {'>'} Detailansicht {'>'} Zugangsdaten
      </li>
      <li>
        Testungen {'>'} Testungen markieren {'>'} Stapelverarbeitung {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Als CSV Exportieren</span>
      </li>
    </ul>
    <h4 id="chapter-2-2-2">2.2.2 Einladungen aus dem Kundenbereich versenden</h4>
    <p>
      Führen Sie einen der folgenden Schritte durch, um den Prozess <em>E-Mails senden</em> zu öffnen:
    </p>
    <ul>
      <li>
        Wählen Sie einen Teilnehmer mit einer E-Mail-Adresse aus, öffnen das Aktionsmenü und klicken auf{' '}
        <B>E-Mail senden</B>.
      </li>
      <li>
        Markieren Sie mindestens einen Teilnehmer mit einer E-Mail-Adresse und klicken in der Stapelverarbeitung auf der
        rechten Seite auf <B>E-Mail senden</B>.
      </li>
      <li>
        Wählen Sie eine Testung eines Teilnehmers mit einer E-Mail-Adresse aus, öffnen das Aktionsmenü und klicken auf
        <B>E-Mail senden</B>.
      </li>
      <li>
        Markieren Sie mindestens eine Testung eines Teilnehmers mit einer E-Mail-Adresse und klicken in der
        Stapelverarbeitung auf <B>E-Mail senden</B>.
      </li>
    </ul>
    <TimeLineContainer className="wizard">
      <Entry>
        <WizardStepContainer>
          <WizardStep>1. E-Mail-Vorlagen</WizardStep>
          <ul>
            <li>Wählen Sie eine bestehende E-Mail-Vorlage aus der Liste aus.</li>
            <li>Sie können den Betreff, die Anrede sowie zwei Freitextfelder anpassen.</li>
            <li>Die Zugangsdaten werden automatisch zwischen den beiden Freitextfeldern eingefügt.</li>
            <li>
              Ihre Änderungen können Sie als neue Vorlage speichern, indem Sie oben auf <B>Speichern...</B> klicken.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>2. Vorschau</WizardStep>
          <ul>
            <li>Auf dieser Seite wird Ihnen eine Vorschau Ihrer E-Mail angezeigt.</li>
            <li>
              Die Vorschaudaten können Sie unter <em>Daten für Vorschau</em> ändern.
            </li>
            <li>
              Wenn Sie eine Muster-E-Mail versenden möchten, tragen Sie eine E-Mail-Adresse in das entsprechende Feld in
              der Seitenleiste ein und drücken auf <B>Jetzt senden</B>.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>3. Einstellungen</WizardStep>
          <ul>
            <li>Wählen Sie aus, ob es sich um eine Einladungs- oder Erinnerungsmail handelt.</li>
            <li>
              Wenn ein Timer eingestellt ist, können Sie hier auswählen, ob der Timer nach E-Mail-Versand automatisch
              gestartet werden soll.
            </li>
            <li>
              Optional können Sie einen Namen und eine E-Mail-Adresse für das <em>Antwort an</em> Feld eingeben. Wenn
              der Empfänger auf <B>Antworten</B> klickt, wird die Antwort an die hier angegebene Adresse gesendet.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>4. Eingaben prüfen</WizardStep>
          <ul>
            <li>Überprüfen Sie Ihre Eingaben.</li>
            <li>
              Klicken Sie auf <B>Senden...</B>.
            </li>
            <li>
              Bestätigen Sie den Versand, indem Sie auf <B>Senden</B> klicken.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
    </TimeLineContainer>
    <h3 id="chapter-2-3">2.3 Ergebnisse abrufen und exportieren</h3>
    <h4 id="chapter-2-3-1">2.3.1 Ergebnisse als PDF-Report</h4>
    <p>
      Für jeden abgeschlossenen Test wird automatisch ein PDF-Report erstellt. Diesen können Sie an verschiedenen
      Stellen öffnen und herunterladen.
    </p>
    <h5>Einzelnen Report im Browser öffnen</h5>
    <ul>
      <li>
        Dashboard {'>'} Die neuesten Testergebnisse {'>'} PDF-Report
      </li>
      <li>
        Teilnehmer {'>'} Detailansicht {'>'} Ergebnis {'>'} PDF-Report
      </li>
      <li>
        Teilnehmer {'>'} Aktionen {'>'} Report öffnen
      </li>
      <li>
        Testungen {'>'} Detailansicht {'>'} Ergebnis {'>'} PDF-Report
      </li>
      <li>
        Testungen {'>'} Aktionen {'>'} Report öffnen
      </li>
    </ul>
    <h5>Mehrere Reports als Zip-Ordner downloaden</h5>
    <ul>
      <li>
        Tests {'>'} Aktionen {'>'} <span style={{ whiteSpace: 'nowrap' }}>Reports downloaden</span>
      </li>
      <li>
        Teilnehmer {'>'} Teilnehmer markieren {'>'} Stapelverarbeitung {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Reports downloaden</span>
      </li>
      <li>
        Testungen {'>'} Testung markieren {'>'} Stapelverarbeitung {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Reports downloaden</span>
      </li>
    </ul>
    <h4 id="chapter-2-3-2">2.3.2 Ergebnisse als CSV-Tabelle</h4>
    <p>
      Sie können die Testergebnisse im CSV-Format (comma separated values) herunterladen und z.B. in MS Excel oder Open
      Office Calc öffnen. Als Trennzeichen wird das Semikolon genutzt.
    </p>
    <ul>
      <li>
        Verfahren {'>'} Aktionen {'>'} CSV-Tabelle downloaden
      </li>
      <li>
        Tests {'>'} Aktionen {'>'} CSV-Tabelle downloaden
      </li>
      <li>
        Teilnehmer {'>'} Detailansicht {'>'} Ergebnis {'>'} CSV Rohdaten
      </li>
      <li>
        Testungen {'>'} Detailansicht {'>'} Ergebnis {'>'} CSV Rohdaten
      </li>
      <li>
        Testungen {'>'} Testung markieren {'>'} Stapelverarbeitung {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Als CSV Exportieren</span>
      </li>
    </ul>
    <h3 id="chapter-2-4">2.4 Testzeiten festlegen</h3>
    <h4 id="chapter-2-4-1">2.4.1 Übersicht</h4>
    <p>Sie haben verschiedene Möglichkeiten, Testzeiten festzulegen. </p>
    <p>
      Die folgenden Felder können Sie auf Test-Ebene und Testungs-Ebene festlegen. Werte auf Test-Ebene werden als
      Standardwerte für alle zugeordneten Testungen übernommen. Sie könne die Werte auf Testungs-Ebene allerdings mit
      spezifischeren Werten überschreiben (z.B. um einem einzelnen Teilnehmer eine Fristverlängerung einzuräumen).
    </p>
    <TimeLimitsTable />
    <h4 id="chapter-2-4-2">2.4.2 Timer starten und zurücksetzen</h4>
    <p>
      Wenn das Feld <em>Timer</em> gesetzt ist, stehen Ihnen die Funktionen <em>Timer starten</em> und{' '}
      <em>Timer zurücksetzen</em> zur Verfügung. Sie finden die Funktionen hier:
    </p>
    <ul>
      <li>Teilnehmer {'>'} Aktionsmenü</li>
      <li>Teilnehmer {'>'} Stapelverarbeitung</li>
      <li>Testungen {'>'} Aktionsmenü </li>
      <li>Testungen {'>'} Stapelverarbeitung</li>
    </ul>
    <p>
      Wenn Sie den Timer auf Teilnehmer-Ebene starten/zurücksetzen, werden die Timer für alle zugeordneten Testungen
      gestartet/zurückgesetzt.
    </p>
    <h3 id="chapter-2-5">2.5 {dict.testSecurity.de}</h3>
    <h4 id="chapter-2-5-1">2.5.1 {dict.useChildItems.de}</h4>
    <ChildItemsHelp />
    <h4 id="chapter-2-5-2">2.5.2 {dict.tabTracking.de}</h4>
    <TabTrackingHelp />
    <h4 id="chapter-2-5-3">2.5.3 {dict.cameraSupervision.de}</h4>
    <CameraHelp />
  </>
)

export default Chapter2

const TimeLimitsTable = () => {
  const header = ['Feld', 'Beschreibung', 'Standardwert']
  const data = [
    [
      dict.validFrom.de,
      'Datum und Uhrzeit, ab wann der Test durchgeführt werden kann. Wenn ein Datum, aber keine Uhrzeit ausgewählt ist, wird standardmäßig 00:00 Uhr gesetzt.',
      'sofort'
    ],
    [
      dict.validUntil.de,
      'Datum und Uhrzeit, bis wann der Test durchgeführt werden kann. Wenn ein Datum, aber keine Uhrzeit ausgewählt ist, wird standardmäßig 23:59 Uhr gesetzt.',
      'unbegrenzt'
    ],
    [
      dict.invitedDuration.de,
      <span key="TimeLimitsTable-timer">
        Mit der Timer-Funktion können Sie den Testzeitraum pro Teilnehmer individuell begrenzen. Setzen Sie hierzu den
        Timer auf die gewünschte Dauer (1 bis 30 Tage). Sobald Sie auf <B>Timer starten</B> klicken, wird das Feld{' '}
        <em>Verfügbar bis</em> auf den aktuellen Zeitpunkt plus die eingestellte Dauer gesetzt. Der Zeitraum gilt immer
        bis 23:59 Uhr.
      </span>,
      'nicht gesetzt'
    ],
    [
      dict.startedDuration.de,
      <span key="TimeLimitsTable-startedDuration">
        Mit der Funktion <em>Zeit ab Teststart</em> können Sie die Zeit begrenzen, die ein Teilnehmer insgesamt für den
        Test zur Verfügung hat. Sobald der Test gestartet wurde, wird das Feld <em>Verfügbar bis</em> auf den aktuellen
        Zeitpunkt plus die eingestellte Dauer gesetzt. Wenn Sie diese Funktion nutzen, wird der Teilnehmer vor Start des
        Tests gesondert auf die Zeitbegrenzung hingewiesen.
      </span>,
      'nicht gesetzt'
    ]
  ]
  return <Table name="time-limits" {...{ header, data }} />
}
