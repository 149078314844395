import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import DataTable from '../../../components/table/DataTable'
import { capabilitiesColumn, getContactCols } from '../../../components/table/tableElements'
import { AppContext } from '../../../utils/context/AppContext'
import { PageHeadline } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { prepareTableData } from '../changeCapabilitiesUtils'
import { getSpacerCol } from '../../../components/table/tableCols/tableCols'

const CapabilitiesSelectionPage = ({ data, setData }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contacts = context.completeDataSet.contacts.filter((contact) => contact.isVisible())
  const contactColumns = getContactCols(t)
  contactColumns.push(capabilitiesColumn, getSpacerCol())
  const tableData = prepareTableData(contacts, data, setData)

  return (
    <>
      <PageHeadline>{t('changePermissions', contacts.length)}</PageHeadline>
      <ScrollContainer>
        <DataTable data={tableData} columns={contactColumns} hideResultCount hideArchiveButton />
      </ScrollContainer>
    </>
  )
}

export default CapabilitiesSelectionPage
