import { useEffect } from 'react'
import { validateBillingData } from '../../pages/shop/shopUtils'

const useAddParticipantSubmitButtonStatus = (
  billingData,
  setBillingDataValid,
  maximumCreditSum,
  setSubmitButtonDisabled
) => {
  useEffect(() => {
    if (maximumCreditSum === 0) {
      setSubmitButtonDisabled(false)
      return
    } else {
      const isValid = validateBillingData(billingData)
      setBillingDataValid(isValid)
      isValid ? setSubmitButtonDisabled(false) : setSubmitButtonDisabled(true)
    }
  }, [billingData, setBillingDataValid, maximumCreditSum, setSubmitButtonDisabled])
}

export default useAddParticipantSubmitButtonStatus
