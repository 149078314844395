import { useContext } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import DataTable from '../../../components/table/DataTable'
import {
  capabilitiesPreviewColumn,
  getContactCols,
  Tile,
  TileContainer,
  TileHeadline
} from '../../../components/table/tableElements'
import { AppContext } from '../../../utils/context/AppContext'
import { PageHeadline } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { addPreview } from '../changeCapabilitiesUtils'
import { getSpacerCol } from '../../../components/table/tableCols/tableCols'

const ChangeCapabilitiesCheckInput = ({ data, selectedProcesses }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contacts = context.completeDataSet.contacts
  const columns = getContactCols(t)
  columns.push(capabilitiesPreviewColumn, getSpacerCol())

  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer>
          <Tile>
            <TileHeadline content={t('newPermissionsReview', contacts.length)} />

            <DataTable
              data={addPreview(contacts, data, t)}
              columns={columns}
              selectedRows={{}}
              showRowSelection={false}
              hideResultCount
              hideArchiveButton
            />
          </Tile>
          <Tile width="200px">
            <TileHeadline content="affectedProcesses" />

            <ul style={{ paddingLeft: 'var(--space-5)' }}>
              {selectedProcesses.map((process, i) => (
                <li style={{ marginBottom: 'var(--space-3)' }} key={process.processName + '_' + i}>
                  {process.processName}
                </li>
              ))}
            </ul>
          </Tile>
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default ChangeCapabilitiesCheckInput
