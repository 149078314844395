import { useContext } from 'react'
import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'
import { AppContext } from '../../utils/context/AppContext'

const SystemInfosOverview = () => {
  const context = useContext(AppContext)
  const systemInfosData = getSystemInfos(context.completeDataSet)
  const t = useTranslate()
  return (
    <OuterContainer>
      {systemInfosData.map((entry) => (
        <Container key={entry.label}>
          <Label className="uppercase-label">{t(entry.label)}</Label>
          <DataEntry className="highlight">
            {entry.content.visible.toLocaleString()}
            {entry.content.archived > 0 && (
              <ArchivedEntries> ({entry.content.archived.toLocaleString()})</ArchivedEntries>
            )}
          </DataEntry>
        </Container>
      ))}
    </OuterContainer>
  )
}

export default SystemInfosOverview

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-6);
  width: 200px;
  overflow: hidden;
  @media (max-width: 900px) {
    gap: var(--space-4);
  }
`

const Container = styled.div`
  display: grid;
`

const Label = styled.span`
  line-height: var(--lh-2);
`

const DataEntry = styled.span`
  font-size: var(--fs-6);
  font-weight: var(--fw-1);
  line-height: var(--lh-2);
`

const ArchivedEntries = styled.span`
  color: var(--text-color-secondary);
  font-size: var(--fs-3);
`

const getFilteredCounts = (entities) => ({
  visible: entities.filter((entity) => !entity.isArchived()).length,
  archived: entities.filter((entity) => entity.isArchived()).length
})

const getSystemInfos = (completeDataSet) => [
  {
    label: 'processes',
    content: getFilteredCounts(completeDataSet.processes)
  },
  {
    label: 'Tests',
    content: getFilteredCounts(completeDataSet.assessments)
  },
  {
    label: 'participants',
    content: getFilteredCounts(completeDataSet.participants)
  },
  {
    label: 'pts',
    content: getFilteredCounts(completeDataSet.pts)
  }
]
