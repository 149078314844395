import React, { useContext, useMemo } from 'react'
import DataTable from '../../components/table/DataTable'
import { ContentContainer } from '../../components/table/tableElements'
import { AppContext } from '../../utils/context/AppContext'
import { ENTITIES } from '../../utils/constants/constants'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { getColsForCreditsTable } from './creditUtils'

const Credits = () => {
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const { creditBookings, customFieldDefinitions } = completeDataSet
  const filteredCreditBookings = useMemo(
    () => creditBookings.filter((booking) => booking.creditsValue !== 0),
    [creditBookings.length]
  )
  const customTableSettings = context.completeDataSet.customTableSettings.creditBookings
  const columns = useMemo(
    () => getColsForCreditsTable(customFieldDefinitions, customTableSettings),
    [customFieldDefinitions, customTableSettings]
  )

  filteredCreditBookings.forEach((booking) => booking.addRelatedElements(completeDataSet))

  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <DataTable
          data={filteredCreditBookings}
          columns={columns}
          showRowSelection
          tableName={ENTITIES.bookings}
          hideArchiveButton
          showFilters
          showAddButton
          showColumnController
        />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Credits
