import Cookies from 'universal-cookie'
import React, { useState } from 'react'
import { DEFAULT_DISPLAY_MODE, DEFAULT_LANGUAGE } from '../constants/constants'

export const AppContext = React.createContext(null)

const cookies = new Cookies()

export const ContextWrapper = (props) => {
  const [completeDataSet, setCompleteDataSet] = useState({})
  const [loggedInAsAdmin, setLoggedInAsAdmin] = useState(false)
  const [token, setToken] = useState(null)
  const [loginMessage, setLoginMessage] = useState('')
  const [statusBarVisible, setStatusBarVisible] = useState(false)
  const [statusBarContent, setStatusBarContent] = useState()
  const [statusBarType, setStatusBarType] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalProps, setModalProps] = useState({})
  const [lastRequestTimeOutId, setLastRequestTimeOutId] = useState(null)
  const [onboardingTourVisible, setOnboardingTourVisible] = useState(false)
  const [language, setLanguage] = useState(cookies.get('perseo-language') || DEFAULT_LANGUAGE)
  const [displayMode, setDisplayMode] = useState(cookies.get('perseo-displayMode') || DEFAULT_DISPLAY_MODE)
  const [archiveLoaded, setArchiveLoaded] = useState(false)
  const [updatesArePaused, setUpdatesArePaused] = useState(false)

  const [maintenanceMode, setMaintenanceMode] = useState(false)
  const [maintenanceModeText, setMaintenanceModeText] = useState(false)

  const [loadingMessage, setLoadingMessage] = useState('loadingData')
  const [showArchivedItems, setShowArchivedItems] = useState(false)

  const statusBarController = {
    statusBarVisible,
    setStatusBarVisible,
    statusBarContent,
    setStatusBarContent,
    statusBarType,
    setStatusBarType
  }

  return (
    <AppContext.Provider
      value={{
        completeDataSet,
        setCompleteDataSet,
        loggedInAsAdmin,
        setLoggedInAsAdmin,
        statusBarController,
        modalOpen,
        setModalOpen,
        modalProps,
        setModalProps,
        lastRequestTimeOutId,
        setLastRequestTimeOutId,
        token,
        setToken,
        loginMessage,
        setLoginMessage,
        onboardingTourVisible,
        setOnboardingTourVisible,
        language,
        setLanguage,
        displayMode,
        setDisplayMode,
        maintenanceMode,
        setMaintenanceMode,
        maintenanceModeText,
        setMaintenanceModeText,
        archiveLoaded,
        setArchiveLoaded,
        loadingMessage,
        setLoadingMessage,
        showArchivedItems,
        setShowArchivedItems,
        updatesArePaused,
        setUpdatesArePaused
      }}>
      {props.children}
    </AppContext.Provider>
  )
}
