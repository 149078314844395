import styled from 'styled-components'
import { forwardRef, useContext, useEffect, useState } from 'react'
import { FilterContext } from '../../../utils/context/FilterContext'
import FilterBuilder from '../../filter/FilterBuilder/FilterBuilder'
import { GreyButton } from '../../../utils/elements/miscElements'
import ActiveFilter from './ActiveFilter'
import FreeTextFilter from '../../filter/FreeTextFilter'
import ToggleSwitch from '../../toggleSwitch/ToggleSwitch'
import { FilterLabel, LabelFilterContainer } from '../../filter/filterUtils'
import useTranslate from '../../../utils/hooks/useTranslate'
import { useRef } from 'react'
import useFilterOverflowDetection from '../../../utils/hooks/useFilterOverflowDetection'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const FilterBar = ({ columns, showCustomConfigFilter, showCustomConfigs, setShowCustomConfigs }) => {
  const { regularFilters } = useContext(FilterContext)
  const t = useTranslate()

  const buttonRef = useRef(null)

  const [showNewFilter, setShowNewFilter] = useState(false)
  const [editFilterId, setEditFilterId] = useState(null)

  const hasOverflow = useFilterOverflowDetection(buttonRef, regularFilters)

  useEffect(() => {
    buttonRef.current.style.transitionDuration = '150ms'
  }, [regularFilters.length])

  return (
    <FilterContainer>
      <li>
        <FreeTextFilter />
      </li>
      {showCustomConfigFilter && (
        <li>
          <LabelFilterContainer>
            <FilterLabel>{t('customTestsOnly')}</FilterLabel>
            <ToggleSwitch isChecked={showCustomConfigs} handleClick={() => setShowCustomConfigs(!showCustomConfigs)} />
          </LabelFilterContainer>
        </li>
      )}
      {regularFilters.map((af) => (
        <li key={af.id} style={{ position: 'relative' }}>
          <ActiveFilter
            filter={af}
            setEditFilterId={setEditFilterId}
            editFilterId={editFilterId}
            setShowNewFilter={setShowNewFilter}
          />
          <TransitionGroup>
            {af.id === editFilterId && (
              <CSSTransition key="editFilter" in={showNewFilter} timeout={150} classNames="fade" unmountOnExit>
                <FilterBuilder columns={columns} filter={af} setEditFilterId={setEditFilterId} />
              </CSSTransition>
            )}
          </TransitionGroup>
        </li>
      ))}
      <li style={{ position: 'relative' }}>
        <AddFilterButton {...{ setShowNewFilter, showNewFilter, setEditFilterId }} ref={buttonRef} />
        <TransitionGroup>
          {showNewFilter && (
            <CSSTransition key="addFilter" in={showNewFilter} timeout={150} classNames="fade" unmountOnExit>
              <FilterBuilder {...{ columns, setShowNewFilter, hasOverflow, buttonRef }} />
            </CSSTransition>
          )}
        </TransitionGroup>
      </li>
    </FilterContainer>
  )
}

export default FilterBar

const FilterContainer = styled.ul`
  margin-bottom: var(--space-3);
  position: relative;
  z-index: 1;
  transition: transform 300ms;
  padding: 0;
  display: flex;
  gap: var(--space-3);
  flex-wrap: wrap;
  align-items: flex-end;
  li {
    list-style: none;
  }
`

const AddFilterButton = forwardRef((props, ref) => {
  const { showNewFilter, setEditFilterId, setShowNewFilter } = props
  return (
    <GreyButton
      ref={ref}
      className={`open-filter-button ${showNewFilter ? 'active' : ''}`}
      onClick={() => {
        setEditFilterId(null)
        setShowNewFilter(!showNewFilter)
      }}>
      <span>+ Filter</span>
    </GreyButton>
  )
})
