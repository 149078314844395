import { selectStyles } from '../../../utils/elements/miscElements'

import Select from 'react-select'
import { getOptionByValue, setState } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
const PreviewSelector = ({ data, setData }) => {
  const options = getPreviewOptions(data.participants)
  const t = useTranslate()
  return (
    <div style={{ marginBottom: 'var(--space-5)' }}>
      <span>{t('dataForPreview')}</span>
      <Select
        options={options}
        onChange={(selectedOption) => setState(setData, 'previewParticipantNr', selectedOption.value)}
        styles={selectStyles}
        value={getOptionByValue(options, data.previewParticipantNr)}
        noOptionsMessage={() => t('noResults')}
      />
    </div>
  )
}

export default PreviewSelector

const getPreviewOptions = (participants) => {
  return participants.map((p) => ({
    label: p.pFullName || p.pMail,
    value: p.pNr
  }))
}
