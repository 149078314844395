import React, { useContext } from 'react'
import styled from 'styled-components'
import { DEFAULT_LANGUAGE } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { replaceSpanWithBold } from '../../utils/helper/Helper'

const PtNotes = ({ result }) => {
  const context = useContext(AppContext)
  const notes = result.notes[context.language] || result.notes[DEFAULT_LANGUAGE]
  const style = { paddingLeft: 0, listStyle: 'none' }

  return (
    <ul style={style}>
      {notes.map((note, i) => {
        const isLastNote = i < notes.length - 1
        return (
          <React.Fragment key={result.resultNr + i}>
            <Note>{replaceSpanWithBold(note)}</Note>
            {isLastNote && <br />}
          </React.Fragment>
        )
      })}
    </ul>
  )
}

export default PtNotes

const Note = styled.li`
  line-height: var(--lh-3);
  color: var(--text-color-highlight);
`
