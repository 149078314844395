import TabGroup from '../../components/tabGroup/TabGroup'
import { getContactCols, TileContainer } from '../table/tableElements'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { ENTITIES } from '../../utils/constants/constants'
import DataTable from '../table/DataTable'
import React, { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import RowActionTile from '../tiles/RowActionTile'
import UserDataTile from '../tiles/UserDataTile'
import EditCapabilitiesTile from '../tiles/EditCapabilitiesTile'
import { SettingsContainer } from './sharedElements/sharedElements'
import useTranslate from '../../utils/hooks/useTranslate'
import { getSpacerCol, toggleCol } from '../table/tableCols/tableCols'

const UserManagementComponent = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const userList = completeDataSet.contacts
  const columns = React.useMemo(() => getContactCols(t), [])
  columns.push(getSpacerCol(), toggleCol)

  return (
    <SettingsContainer className="user-management">
      <ScrollContainer type="settings">
        <h2>{t('userAdministration')}</h2>
        <DataTable
          data={[...userList]}
          columns={columns}
          RowSubComponent={RowSubComponent}
          tableName={ENTITIES.users}
          hasSubrows
          hideArchiveButton
          showAddButton
        />
      </ScrollContainer>
    </SettingsContainer>
  )
}

export default UserManagementComponent

const RowSubComponent = (row) => {
  return (
    <div>
      <TabGroup
        tabs={[
          {
            label: 'capabilities',
            id: 'capabilities',
            content: <EditCapabilitiesTile contact={row.original} />
          },
          {
            label: 'settings',
            id: 'userData',
            content: <EditUserData contact={row.original} />
          }
        ]}
      />
    </div>
  )
}

const EditUserData = ({ contact }) => (
  <TileContainer>
    <UserDataTile {...{ contact }} />
    <RowActionTile width="220px" row={contact} entity={ENTITIES.users} />
  </TileContainer>
)
