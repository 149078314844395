import React, { useContext } from 'react'
import ReactTooltip from 'react-tooltip'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'
import { NavBarSpacer, NavLink, NavLinkLabel, NavMenuInnerContainer, NavMenuItem } from './NavbarElements'
import usePageUrl from '../../utils/hooks/usePageUrl'

const NavMenuEntry = ({ entry, index }) => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const showEntry = (context.loggedInAsAdmin && entry.page.adminOnly) || !entry.page.adminOnly
  const getUrlFromPage = usePageUrl()

  return (
    <>
      {showEntry && (
        <React.Fragment>
          <NavMenuItem data-tip="" data-for={`navtip_${index}`} className={entry.className}>
            <NavLink to={getUrlFromPage(entry.page)}>
              <NavMenuInnerContainer className="link-container">
                <span className={`svg-icon ${entry.icon}`} />
                <NavLinkLabel color="var(--grey-150)">{t(entry.label)}</NavLinkLabel>
              </NavMenuInnerContainer>
            </NavLink>
            <ReactTooltip
              id={`navtip_${index}`}
              offset={{ right: 8 }}
              aria-haspopup="true"
              effect="solid"
              place="right"
              backgroundColor="var(--c-tooltip-bg)"
              textColor="var(--tooltip-text-color)"
              className="navbar-tooltip"
              getContent={() => <span>{t(entry.label)}</span>}
            />
          </NavMenuItem>
          {entry.hasSpacer && <NavBarSpacer />}
        </React.Fragment>
      )}
    </>
  )
}

export default NavMenuEntry
