import { API_DATA, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import {
  fetchData,
  getLoggedInContact,
  isLastElement,
  setStatusBar,
  updateEntities
} from '../../../utils/helper/Helper'

export const saveData = ({ key, value }, context) => {
  const contact = getLoggedInContact(context.completeDataSet)

  const payload = {
    contactUuid: [contact.contactUuid],
    [key]: value
  }

  const endPoint = API_DATA.contacts.endPointEdit

  fetchData(payload, endPoint, context).then((responseData) => {
    updateEntities(responseData.response.data, context)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'settingsSaved'
    })
  })
}

export const handleWelcomeNotification = (notifications) => {
  const welcomeNotificationIndex = notifications.findIndex((notification) => notification.isWelcomeNotification)
  if (!isLastElement(welcomeNotificationIndex, notifications)) {
    return notifications.filter((n) => !n.isWelcomeNotification)
  }
  return notifications
}
