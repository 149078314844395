import TabGroup from '../../components/tabGroup/TabGroup'
import ParticipantSettingsTab from '../../components/tabs/ParticipantSettingsTab'
import ParticipantAccessDataTab from '../../components/tabs/ParticipantAccessDataTab'
import PtResultTab from '../../components/tabs/PtResultTab'
import useTranslate from '../../utils/hooks/useTranslate'
import ActionsTab from '../../components/tabs/ActionsTab'
import { ENTITIES } from '../../utils/constants/constants'

const ParticipantRowSubComponent = (row) => {
  const tabs = [
    {
      label: 'accessData',
      id: 'participantInviteLink',
      content: <ParticipantAccessDataTab participant={row.original} />
    },
    {
      label: 'settings',
      id: 'participantInfos',
      content: <ParticipantSettingsTab participant={row.original} />
    },
    {
      label: 'actions',
      id: 'actions',
      content: <ActionsTab row={row.original} entity={ENTITIES.participants} />
    }
  ]

  row.original.ptList
    .filter((pt) => pt.hasResult())
    .map((pt, i) => {
      pt.relatedParticipant = row.original
      return tabs.unshift({
        label: <ResultTabLabel pt={pt} />,
        id: 'result' + i,
        content: <PtResultTab pt={pt} config={pt.relatedConfig} barChartWidth={'450px'} />
      })
    })

  return <TabGroup tabs={tabs} />
}

const ResultTabLabel = ({ pt }) => {
  const t = useTranslate()
  return (
    <>
      {t('result')}: {pt.relatedAssessment.assessmentName}
    </>
  )
}

export default ParticipantRowSubComponent
