import React from 'react'
import ToggleButton from '../../../components/toggleButton/ToggleButton'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import {
  WizardForm,
  Label,
  OptionEntry,
  OptionDescription,
  PageHeadline,
  SubHeadline,
  selectStyles
} from '../../../utils/elements/miscElements'
import { FIELD_LABELS, SELECT_OPTIONS } from '../../../utils/constants/constants'
import { getLanguageOptions, getOptionByValue, isPotentials, setState } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import TestLanguagesSelect from '../../../components/testLanguagesSelect/TestLanguagesSelect'
import Select from 'react-select'
import TabTrackingHelp from '../../../components/helpModal/TabTrackingHelp'
import CameraHelp from '../../../components/helpModal/CameraHelp'
import ChildItemsHelp from '../../../components/helpModal/ChildItemsHelp'
const AdvancedSettingsForm = ({ data, setData, optionDescriptions }) => {
  const t = useTranslate()
  const languageOptions = getLanguageOptions(data?.parentConfig?.availableLanguages)

  return (
    <>
      <PageHeadline>{t('additionalOptions')}</PageHeadline>
      <ScrollContainer>
        <SubHeadline>{t('settingsAdjustableLater')}</SubHeadline>
        <WizardForm>
          {data.parentConfig && languageOptions.length > 1 && (
            <OptionEntry hasHint>
              <Label htmlFor="languages" fullWidth>
                {t(FIELD_LABELS.testLanguages)}
              </Label>
              <TestLanguagesSelect
                languageIds={data.languageIds}
                languageOptions={languageOptions}
                setterFunction={setData}
                stateKey="languageIds"
              />
              <OptionDescription description={optionDescriptions.language} />
            </OptionEntry>
          )}
          <OptionEntry hasHint>
            <Label htmlFor="inHub" fullWidth>
              {t(FIELD_LABELS.inHub)}
            </Label>
            <ToggleButton options={SELECT_OPTIONS.inHub} data={data} setData={setData} dataKey="inHub" />
            <OptionDescription description={optionDescriptions.inHub} />
          </OptionEntry>

          {isPotentials(data.parentConfig.testType) && (
            <>
              <OptionEntry hasHint>
                <Label htmlFor="useChildItems" fullWidth>
                  {t(FIELD_LABELS.useChildItems)}
                </Label>
                <ToggleButton
                  options={SELECT_OPTIONS.useChildItems}
                  data={data}
                  setData={setData}
                  dataKey="useChildItems"
                />
                <OptionDescription
                  description={optionDescriptions.useChildItems}
                  helpHeadline={FIELD_LABELS.useChildItems}
                  helpContent={<ChildItemsHelp />}
                />
              </OptionEntry>

              <OptionEntry hasHint>
                <Label fullWidth>{t(FIELD_LABELS.cameraSupervision)}</Label>
                <Select
                  options={t(SELECT_OPTIONS.cameraSupervision)}
                  styles={selectStyles}
                  onChange={(selectOption) => setState(setData, 'useCameraSupervision', selectOption.value)}
                  value={getOptionByValue(t(SELECT_OPTIONS.cameraSupervision), data.useCameraSupervision)}
                  isSearchable={false}
                />
                <OptionDescription
                  description={optionDescriptions.cameraSupervision}
                  helpHeadline={FIELD_LABELS.cameraSupervision}
                  helpContent={<CameraHelp />}
                />
              </OptionEntry>

              <OptionEntry hasHint>
                <Label fullWidth>{t(FIELD_LABELS.tabTracking)}</Label>
                <ToggleButton
                  options={SELECT_OPTIONS.tabTracking}
                  data={data}
                  setData={setData}
                  dataKey="tabTracking"
                />
                <OptionDescription
                  description={optionDescriptions.tabTracking}
                  helpHeadline={FIELD_LABELS.tabTracking}
                  helpContent={<TabTrackingHelp />}
                />
              </OptionEntry>

              <OptionEntry hasHint>
                <Label fullWidth>{t(FIELD_LABELS.isSupervised)}</Label>
                <ToggleButton
                  options={SELECT_OPTIONS.isSupervised}
                  data={data}
                  setData={setData}
                  dataKey="isSupervised"
                />
                <OptionDescription
                  description={optionDescriptions.isSupervised}
                  style={{ marginBottom: 'var(--space-6)' }}
                />
              </OptionEntry>
            </>
          )}
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default AdvancedSettingsForm
