import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { PageHeadline } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import EmailTemplate from './EmailTemplate'

const EmailTemplateWrapper = ({ data, setData }) => {
  const t = useTranslate()
  return (
    <>
      <PageHeadline>{t('emailTemplates')}</PageHeadline>
      <ScrollContainer>
        <EmailTemplate {...{ data, setData }} />
      </ScrollContainer>
    </>
  )
}

export default EmailTemplateWrapper
