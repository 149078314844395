import React from 'react'
import { API_DATA } from '../../utils/constants/constants'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import { isDate } from '../../utils/helper/Helper'
import CustomInput from '../customInput/CustomInput'
import DateDisplay from '../dateDisplay/DateDisplay'
import { Entry, InnerContainer, TimeLineContainer } from './timeLineElements'
import { getTimeLineData } from './timeLineUtils'

const TimeLine = ({ pt }) => {
  const timeLineData = getTimeLineData(pt)

  return (
    <TimeLineContainer>
      {timeLineData.map((date, i) => {
        const content = isDate(date.value) ? <DateDisplay date={date.value} format="dateAndTimeString" /> : date.value
        const value = isDate(date.value) ? date.value : ''
        const isDisabled = getDisabledStatus(date, pt)
        return (
          <React.Fragment key={'timeline' + pt.ptNumber + i}>
            {date.value && (
              <Entry>
                <InnerContainer>
                  <CustomInput
                    label={date.label}
                    content={content}
                    value={value}
                    dataKey={date.dataKey}
                    type="calendar"
                    idValue={pt.ptNumber}
                    apiData={API_DATA.pts}
                    disabled={isDisabled}
                    position="timeline"
                  />
                </InnerContainer>
              </Entry>
            )}
          </React.Fragment>
        )
      })}
    </TimeLineContainer>
  )
}

export default TimeLine

const getDisabledStatus = (date, pt) => {
  if (!date.dataKey) return true
  if (date.dataKey === 'validUntil') {
    return !CapabilitiesHelper.canBulkEditPtValidUntil([pt])
  }
  return !CapabilitiesHelper.canBulkEditPtTimeSettings([pt])
}
