import ActionButtons from './ActionButtons'
import BulkSettings from './BulkSettings'
import BulkSidebarHeadline from './BulkSidebarHeadline'
import { getEntityLabel } from '../../utils/helper/Helper'
import {
  OuterContainer,
  ButtonContainer,
  InnerContainer,
  BulkMenuVisibilityButton,
  HeaderButtonContainer
} from './BulkEditStyles'
import { getBulkModalProps, scrollToTop, validateBulkInput } from './BulkUtils'
import { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import { FIELD_LABELS, GLOBAL_STATUS_KEYS } from '../../utils/constants/constants'
import useEventListener from '../../utils/hooks/useEventListener'
import useTranslate from '../../utils/hooks/useTranslate'
import { TableContext } from '../table/DataTable'

const BulkEdit = ({ rowData, isVisible, toggleAllRowsSelected, visibleColumns }) => {
  const context = useContext(AppContext)
  const { tableName } = useContext(TableContext)
  const entity = tableName
  const cancelButtonRef = useRef(null)
  const bulkContainerRef = useRef(null)
  const t = useTranslate()

  const [formIsValid, setFormIsValid] = useState(false)
  const [isExpandedMobileView, setIsExpandedMobileView] = useState(false)
  const [fromAndUntilDatesValid, setFromAndUntilDatesValid] = useState(true)
  const [startedDurationValid, setStartedDurationValid] = useState(true)

  const [elektryonTemplate, setElektryonTemplate] = useState({
    name: 'elektryonTemplate',
    label: t(FIELD_LABELS.testDesign),
    value: 1
  })
  const [validFrom, setValidFrom] = useState({ name: 'validFrom', label: FIELD_LABELS.validFrom })
  const [validUntil, setValidUntil] = useState({ name: 'validUntil', label: FIELD_LABELS.until })
  const [invitedDuration, setInvitedDuration] = useState({
    name: 'invitedDuration',
    label: t(FIELD_LABELS.invitedDuration),
    value: 0
  })
  const [startedDuration, setStartedDuration] = useState({
    name: 'startedDuration',
    label: t(FIELD_LABELS.startedDuration),
    value: 0
  })
  const [invitationStatus, setInvitationStatus] = useState({
    name: GLOBAL_STATUS_KEYS.invitationStatus,
    label: t(FIELD_LABELS.invitationStatus),
    isGlobalStatus: true,
    value: 0
  })
  const customFieldsDefinitions = context.completeDataSet.customFieldDefinitions
  const cfdInitialState = customFieldsDefinitions.map((cfd) => ({
    name: cfd.label,
    label: cfd.label,
    isCustomField: true,
    value: '',
    publicKey: cfd.publicKey
  }))

  const [customFields, setCustomFields] = useState(cfdInitialState)

  const setterFunctions = {
    setValidFrom,
    setValidUntil,
    setInvitedDuration,
    setStartedDuration,
    setElektryonTemplate,
    setInvitationStatus,
    setCustomFields
  }

  const bulkData = useMemo(
    () => ({
      validFrom,
      validUntil,
      invitedDuration,
      startedDuration,
      elektryonTemplate,
      invitationStatus,
      customFields
    }),
    [validFrom, validUntil, invitedDuration, startedDuration, elektryonTemplate, invitationStatus, customFields]
  )

  const selectRowCount = Object.entries(rowData).length
  const entityLabel = getEntityLabel(entity, selectRowCount)
  const bulkModalProps = getBulkModalProps(context, bulkData, entity, rowData, toggleAllRowsSelected, t)

  const resetState = () => {
    setValidFrom({ name: 'validFrom', label: t(FIELD_LABELS.validFrom) })
    setValidUntil({ name: 'validUntil', label: t(FIELD_LABELS.validUntil) })
    setInvitedDuration({ name: 'invitedDuration', label: t(FIELD_LABELS.invitedDuration), value: 0 })
    setStartedDuration({ name: 'startedDuration', label: t(FIELD_LABELS.startedDuration), value: 0 })
    setElektryonTemplate({ name: 'elektryonTemplate', label: t(FIELD_LABELS.testDesign), value: 1 })
    setInvitationStatus({
      name: GLOBAL_STATUS_KEYS.invitationStatus,
      label: t(FIELD_LABELS.invitationStatus),
      isGlobalStatus: true,
      value: 0
    })
    setCustomFields(cfdInitialState)
  }

  useEffect(() => {
    resetState()
    scrollToTop()
    setIsExpandedMobileView(false)
    if (isVisible) {
      bulkContainerRef.current.style.visibility = 'visible'
    }
  }, [isVisible])

  useEffect(() => {
    scrollToTop()
  }, [isExpandedMobileView])

  useEffect(() => {
    validateBulkInput(bulkData, setFormIsValid, setFromAndUntilDatesValid, rowData, entity, startedDurationValid)
  }, [bulkData, rowData, entity, startedDurationValid])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        event.preventDefault()
        cancelButtonRef.current.focus()
        toggleAllRowsSelected(false)
      }
    },
    [toggleAllRowsSelected]
  )

  useEventListener('keydown', handleKeyDown)

  const setHidden = () => {
    if (!isVisible) {
      bulkContainerRef.current.style.visibility = 'hidden'
    }
  }

  return (
    <OuterContainer
      onTransitionEnd={setHidden}
      {...{ isVisible, isExpandedMobileView }}
      className="bulk-edit-sidebar"
      ref={bulkContainerRef}>
      <div style={{ height: '100%', position: 'relative' }}>
        <InnerContainer>
          <HeaderButtonContainer>
            <BulkSidebarHeadline {...{ selectRowCount, entityLabel }} />
            <BulkMenuVisibilityButton onClick={() => setIsExpandedMobileView(!isExpandedMobileView)}>
              {isExpandedMobileView ? t('hide') : t('editDotDot')}
            </BulkMenuVisibilityButton>
          </HeaderButtonContainer>
          <ScrollContainer
            fadingEdgeBottomOffset="76px"
            marginBottom="76px"
            type="bulk-edit-sidebar"
            className="bulk-sidebar-scroll-container">
            <ActionButtons {...{ rowData, entity, toggleAllRowsSelected, visibleColumns }} />
            <BulkSettings
              {...{
                bulkData,
                setterFunctions,
                rowData,
                entity,
                fromAndUntilDatesValid,
                setStartedDurationValid
              }}
            />
          </ScrollContainer>
          <ButtonContainer className="bulk-sidebar-button-container">
            <ButtonSecondary
              onClick={() => toggleAllRowsSelected(false)}
              ref={cancelButtonRef}
              autoFocus
              darker
              style={{ height: '40px' }}
              content="cancel"
              disabled={!isVisible}
            />
            <ButtonPrimary
              onClick={() => {
                context.setModalProps(bulkModalProps)
                context.setModalOpen(true)
              }}
              disabled={!formIsValid}
              style={{ height: '40px', padding: 'var(--space-2)' }}
              content="apply"
            />
          </ButtonContainer>
        </InnerContainer>
      </div>
    </OuterContainer>
  )
}

export default BulkEdit
