import {
  ErrorMessage,
  Label,
  OptionDescription,
  OptionEntryWide,
  WizardForm
} from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { setEmailSubject } from '../sendEmailUtils'
import TemplateManager from '../subComponents/TemplateManager'
import styled from 'styled-components'
import SalutationSelection from '../subComponents/SalutationSelection'
import { FORM_ERRORS } from '../../../utils/constants/constants'

const EmailTemplate = ({ data, setData }) => {
  const t = useTranslate()
  const subjectEmpty = data.emailSubject.length === 0
  return (
    <WizardForm>
      <OptionEntryWide hasHint>
        <Label fullWidth>{t('template')}</Label>
        <TemplateManager {...{ data, setData }} />
        <OptionDescription description="createAndSaveNewTemplates" />
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <Label fullWidth style={{ lineHeight: '18px' }}>
          {t('subject')}
        </Label>
        <div>
          <input
            className={subjectEmpty ? 'has-error' : ''}
            value={data.emailSubject}
            onChange={(e) => setEmailSubject(e, setData)}
            type="text"
            style={{ width: '100%' }}
          />
          <ErrorMessage visible={subjectEmpty}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
        </div>
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <Label fullWidth style={{ lineHeight: '18px' }}>
          {t('salutation')}
        </Label>
        <SalutationSelection {...{ data, setData }} />
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <Label fullWidth>{t('freeTextTop')}</Label>
        <TextArea rows={5} onChange={(e) => handleInputChange(e, setData, 'topText')} value={data.topText} />
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <AccessDataPlaceholder>{t('accessDataPlaceholder')}</AccessDataPlaceholder>
      </OptionEntryWide>

      <OptionEntryWide hasHint>
        <Label fullWidth>{t('freeTextBottom')}</Label>
        <TextArea rows={5} onChange={(e) => handleInputChange(e, setData, 'bottomText')} value={data.bottomText} />
      </OptionEntryWide>
    </WizardForm>
  )
}

export default EmailTemplate

const handleInputChange = (e, setData, dataKey) => {
  setData((prev) => ({
    ...prev,
    [dataKey]: e.target.value,
    isDirty: true
  }))
}

const AccessDataPlaceholder = styled.div`
  border-radius: var(--bdr-2);
  padding: var(--space-3);
  line-height: 18px;
  text-align: center;
  background-color: var(--box-background);
  font-weight: var(--fw-2);
`

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid var(--border-grey);
  border-radius: var(--bdr-2);
  padding: var(--space-3);
  line-height: var(--lh-3);
  resize: none;
`
