import React, { useEffect, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import PageGroup from '../../components/pageGroup/PageGroup'
import { getTaxRateByCountry, validateBillingData } from './shopUtils'
import { setState } from '../../utils/helper/Helper'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { useHistory } from 'react-router'
import { PAGES } from '../../utils/constants/pages'
import CreditSelectionPage from './subpages/CreditSelectionPage'
import CreditShopSuccessPage from './subpages/CreditShopSuccessPage'
import PaymentOptions from './subpages/PaymentOptions'
import CreditShopCheckInput from './subpages/CreditShopCheckInput'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import BuyButton from './subcomponents/BuyButton'
import { paypalOptions } from './paypalUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { createPaymentMethod } from './stripe/stripeUtils'
import { PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_INVOICE } from '../../utils/constants/constants'
import useRedirect from '../../utils/hooks/useRedirect'

const Shop = () => {
  const stripe = useStripe()
  const elements = useElements()

  const t = useTranslate()
  const redirect = useRedirect()

  const context = useContext(AppContext)
  const history = useHistory()
  const billingData = context.completeDataSet.billing
  const [activePageId, setActivePageId] = useState(0)
  const [buyButtonDisabled, setBuyButtonDisabled] = useState(false)
  const [billingDataValid, setBillingDataValid] = useState(false)
  const [selectedButton, setSelectedButton] = useState(null)
  const [products, setProducts] = useState([{}])
  const [total, setTotal] = useState(0)
  const [waitingForPaymentMethod, setWaitingForPaymentMethod] = useState(false)

  const [orderData, setOrderData] = useState({
    numberCredits: 0,
    discountRate: 0 || context.completeDataSet.customer.discountRate,
    taxRate: getTaxRateByCountry(billingData.billingCountry),
    subTotal: 0,
    discount: 0,
    total: 0,
    paymentMethod: PAYMENT_METHOD_INVOICE,
    stripePayMethodId: '',
    creditCard: {}
  })

  useEffect(() => {
    const PAGE_ID_PAYMENTOPTIONS = 1
    if (activePageId === PAGE_ID_PAYMENTOPTIONS) {
      setBuyButtonDisabled(false)
    }
  }, [activePageId])

  const submitPaymentOptions = (e) => {
    e.preventDefault()

    if (orderData.paymentMethod === PAYMENT_METHOD_CREDIT_CARD) {
      setWaitingForPaymentMethod(true)
      createPaymentMethod(stripe, elements, context)
        .then((payMethod) => {
          setActivePageId(activePageId + 1)
          setOrderData((prev) => ({
            ...prev,
            creditCard: payMethod.paymentMethod.card,
            stripePayMethodId: payMethod.paymentMethod.id
          }))
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setWaitingForPaymentMethod(false)
        })
    } else {
      setActivePageId(activePageId + 1)
    }
  }

  useEffect(() => {
    const isValid = validateBillingData(billingData)
    setBillingDataValid(isValid)
  }, [billingData])

  useEffect(() => {
    setState(setOrderData, 'taxRate', getTaxRateByCountry(billingData.billingCountry))
  }, [billingData.billingCountry])

  const pages = {
    creditSelection: {
      content: (
        <CreditSelectionPage
          orderDataState={{ orderData, setOrderData }}
          selectedButtonState={{ selectedButton, setSelectedButton }}
          productsState={{ products, setProducts }}
          totalState={{ total, setTotal }}
        />
      ),
      title: t('creditCount'),
      buttonSecondary: <ButtonSecondary content="cancel" onClick={history.goBack} />,
      buttonPrimary: (
        <ButtonPrimary
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
          disabled={orderData.numberCredits === 0}
        />
      )
    },
    paymentOptions: {
      content: (
        <PaymentOptions
          orderDataState={{ orderData, setOrderData }}
          billingData={billingData}
          billingDataValid={billingDataValid}
        />
      ),
      title: t('payment'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          content="next"
          onClick={submitPaymentOptions}
          disabled={waitingForPaymentMethod || !billingDataValid}
        />
      )
    },
    checkInput: {
      content: <CreditShopCheckInput {...{ orderData, billingData, billingDataValid }} />,
      title: t('reviewOrder'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <BuyButton
          buyButtonState={{ buyButtonDisabled, setBuyButtonDisabled }}
          activePageState={{ activePageId, setActivePageId }}
          orderData={orderData}
        />
      )
    },

    successPage: {
      content: <CreditShopSuccessPage />,
      hidePageIndicator: true,
      buttonPrimary: (
        <ButtonPrimary content="goToOverview" style={{ gridColumn: 2 }} onClick={() => redirect(PAGES.dashboard)} />
      )
    }
  }

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <PageGroup
        pages={[pages.creditSelection, pages.paymentOptions, pages.checkInput, pages.successPage]}
        activePageId={activePageId}
      />
    </PayPalScriptProvider>
  )
}

export default Shop
