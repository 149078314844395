import { API_DATA, NO_CHANGE_LABEL, SELECT_OPTIONS } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { fetchData, getOptionByValue, updateEntities } from '../../utils/helper/Helper'
import { getParamsJson } from '../../utils/helper/urlParamsHelper'

export const changeCapabilities = async (data, context) => {
  const endPoint = API_DATA.contactProcesses.endPointBulkCapabilities
  const payload = {
    contacts: data.contacts,
    processUuids: data.processUuids
  }

  const responseData = await fetchData(payload, endPoint, context, 'permissionsNotChanged')

  try {
    updateEntities(responseData.response.data, context)
  } catch (e) {
    console.error(e)
  }
}

export const prepareTableData = (rowData, data, setData) => {
  rowData.forEach((row) => {
    const newCapabilitiesForRow = data.contacts.find((contact) => contact.contactUuid === row.contactUuid).capabilities

    row.newCapabilities = newCapabilitiesForRow
    row.setData = setData
    row.stateData = data
    row.entity = 'contacts'
    row.key = 'contactUuid'
    row.selectDisabled = row.contactIsAdmin
  })

  return rowData
}

export const getNullCapabilities = (contacts) =>
  contacts.map((contact) => ({
    contactUuid: contact.contactUuid,
    capabilities: null
  }))

export const addPreview = (contacts, data, t) => {
  contacts.forEach((contact) => {
    const capabilities = data.contacts.find(
      (dataContact) => dataContact.contactUuid === contact.contactUuid
    ).capabilities
    const rolePreview = getPreview(capabilities, contact, t)
    contact.capabilitiesPreview = rolePreview
  })

  return contacts
}

const getPreview = (capabilities, contact, t) => {
  if (contact.contactIsAdmin) return 'Editor'
  return getOptionByValue(t(SELECT_OPTIONS.userCapabilities), capabilities).label || t(NO_CHANGE_LABEL)
}

export const getProcessUuids = (location, redirect) => {
  const urlParams = getParamsJson(location)
  const processUuids = urlParams.find((param) => param.id === 'processUuid')?.value || []
  if (processUuids.length === 0) {
    redirect(PAGES.processes)
  }
  if (!Array.isArray(processUuids)) {
    return [processUuids]
  } else {
    return processUuids
  }
}
