import styled from 'styled-components'
import Privacy from '../privacy'
import Imprint from '../imprint'
import AGB from '../agb'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'

const LegalLinks = () => {
  const context = useContext(AppContext)
  const modalProps = getModalProps()
  const t = useTranslate()

  return (
    <LegalLinksWrapper className="legal-footer" id="legal-footer">
      {modalProps.map((prop) => (
        <ModalLink
          key={prop.headline}
          onClick={() => {
            context.setModalProps({
              headline: prop.headline,
              content: prop.content,
              showCloseButton: true,
              hideFooter: true
            })
            context.setModalOpen(true)
          }}
        >
          {t(prop.linkText)}
        </ModalLink>
      ))}
    </LegalLinksWrapper>
  )
}

export default LegalLinks

export const LegalLinksWrapper = styled.footer`
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  bottom: 15px;
  left: 0;
  right: 0;
  justify-content: center;
  gap: var(--space-6);
  font-size: var(--fs-2);
`

export const ModalLink = styled.button`
  background-color: transparent;
  padding: 0;
  font-size: var(--fs-2);
  text-decoration: none;
  color: var(--link-color);
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
    color: var(--link-color);
  }
`

const getModalProps = () => [
  {
    headline: 'dataSecurity',
    linkText: 'dataSecurity',
    content: <Privacy showInModal />
  },
  {
    headline: 'imprint',
    linkText: 'imprint',
    content: <Imprint showInModal />
  },
  {
    headline: 'termsAndConditions',
    linkText: 'termsAndConditions',
    content: <AGB showInModal />
  }
]
