import styled from 'styled-components'

const CapslockIndicator = ({ capslockOn }) => {
  if (!capslockOn) {
    return <></>
  }
  return <Icon className={`svg-icon icon-capslock`} />
}

const Icon = styled.span`
  position: absolute;
  right: var(--space-8);
`

export default CapslockIndicator
