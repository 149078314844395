import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'

const TimerHelp = () => {
  const context = useContext(AppContext)
  const lang = context.language
  if (lang === 'en') {
    return <TimerHelpEnglish />
  } else {
    return <TimerHelpGerman />
  }
}

export default TimerHelp

const TimerHelpGerman = () => (
  <div>
    <p>
      Mit der Funktion <em>Timer</em> können Sie den Testzeitraum individuell begrenzen. Dies ist z.B. dann sinnvoll,
      wenn Sie Ihre Bewerber nicht gesammelt, sondern in unregelmäßigen Abständen zum Test einladen und der Testzeitraum
      für alle Teilnehmer gleich lang sein soll.
    </p>
    <p>
      Sobald Sie den Timer gesetzt haben, stehen Ihnen die Aktionen <em>Timer starten</em> und{' '}
      <em>Timer zurücksetzen</em> zur Verfügung. Wenn Sie den Timer starten, wir das Feld <em>Verfügbar bis</em> auf
      23:59 Uhr des aktuellen Tages plus die eingestellte Dauer gesetzt.
    </p>
    <div className="textbox">
      <p>
        Wenn Sie den Timer z.B. auf 7 Tage gesetzt haben und am 01. Januar um 15:00 Uhr starten, kann der Test bis zum
        08. Januar 23:59 Uhr bearbeitet werden.
      </p>
    </div>
    <p>
      Wenn Sie Testeinladungen direkt aus dem Kundenbereich versenden, können Sie den Timer automatisch starten, sobald
      die Einladungsmail verschickt wurde.
    </p>
  </div>
)

const TimerHelpEnglish = () => (
  <div>
    <p>
      The <em>Timer</em> feature allows you to individually limit the test period. This is useful, for example, if you
      invite your applicants to the test at irregular intervals rather than all at once, and you want the test period to
      be the same length for all participants.
    </p>
    <p>
      Once you have set the timer, the actions <em>Start Timer</em> and <em>Reset Timer</em> will be available to you.
      When you start the timer, the <em>Available until</em> field will be set to 11:59 PM of the current day plus the
      specified duration.
    </p>
    <div className="textbox">
      <p>
        For example, if you set the timer to 7 days and start it on January 1st at 3:00 PM, the test can be taken until
        January 8th at 11:59 PM.
      </p>
    </div>
    <p>
      If you send test invitations directly from the customer area, you can automatically start the timer as soon as the
      invitation email is sent.
    </p>
  </div>
)
