import { useState } from 'react'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { FIELD_LABELS, FORM_ERRORS, SELECT_OPTIONS } from '../../../utils/constants/constants'
import {
  ErrorMessage,
  FormSection,
  Label,
  OptionDescription,
  OptionEntry,
  PageHeadline,
  SectionTitle,
  selectStyles,
  WizardForm
} from '../../../utils/elements/miscElements'
import { getOptionByValue, setState } from '../../../utils/helper/Helper'
import Select from 'react-select'
import ToggleButton from '../../../components/toggleButton/ToggleButton'
import useTranslate from '../../../utils/hooks/useTranslate'

const UserDataInputForm = ({
  data,
  setData,
  optionDescriptions,
  mailIsDuplicate,
  mailIsInvalid,
  setMailIsDuplicate
}) => {
  const [showErrors, setShowErrors] = useState(false)
  const t = useTranslate()

  const handleEmailChange = (e) => {
    setMailIsDuplicate(false)
    setState(setData, 'contactEmail', e.target.value)
  }

  const handleBlur = () => {
    data.contactEmail.length > 0 ? setShowErrors(true) : setShowErrors(false)
  }

  return (
    <>
      <PageHeadline>{t('userData')}</PageHeadline>
      <ScrollContainer type="pageGroupInSettings">
        <WizardForm>
          <FormSection>
            <SectionTitle title="basicInformation" />

            <OptionEntry hasHint>
              <Label htmlFor="contactEmail" fullWidth>
                {t(FIELD_LABELS.email)} *
              </Label>
              <div style={{ display: 'grid' }}>
                <input
                  id="contactEmail"
                  type="email"
                  autoFocus
                  className={(mailIsInvalid || mailIsDuplicate) && showErrors ? 'has-error' : ''}
                  onChange={handleEmailChange}
                  onBlur={handleBlur}
                  value={data.contactEmail}
                />
                <ErrorMessage visible={mailIsDuplicate && showErrors}>{t(FORM_ERRORS.emailAlreadyInUse)}</ErrorMessage>
                <ErrorMessage visible={mailIsInvalid && showErrors}>{t(FORM_ERRORS.emailInvalid)}</ErrorMessage>
              </div>
              <OptionDescription description={optionDescriptions.contactEmail} />
            </OptionEntry>
            <OptionEntry hasHint>
              <Label htmlFor="contactRole" fullWidth>
                {t(FIELD_LABELS.contactIsAdmin)}
              </Label>
              <ToggleButton options={SELECT_OPTIONS.yesNo} data={data} setData={setData} dataKey="contactIsAdmin" />
              <OptionDescription description={optionDescriptions.contactIsAdmin} />
            </OptionEntry>
          </FormSection>

          <FormSection>
            <SectionTitle isOptional title="additionalInformation" />

            <OptionEntry hasHint>
              <div style={{ display: 'grid', gap: 'var(--space-5)' }}>
                <Label key="contactGender" style={{ width: '100px' }}>
                  <span>{t(FIELD_LABELS.salutation)}</span>
                  <Select
                    options={t(SELECT_OPTIONS.contactSalutations)}
                    onChange={(selectedOption) => setState(setData, 'contactGender', selectedOption.value)}
                    value={getOptionByValue(t(SELECT_OPTIONS.contactSalutations), data.contactGender)}
                    styles={selectStyles}
                    placeholder=""
                    isSearchable={false}
                  />
                </Label>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 'var(--space-5)' }}>
                  <Label key="contactFirstname" style={{ flexGrow: 1 }}>
                    <span>{t(FIELD_LABELS.firstName)}</span>
                    <input
                      type="text"
                      value={data.contactFirstName}
                      onChange={(e) => setState(setData, 'contactFirstName', e.target.value)}
                    />
                  </Label>
                  <Label key="contactLastname" style={{ flexGrow: 1 }}>
                    <span>{t(FIELD_LABELS.lastName)}</span>
                    <input
                      type="text"
                      value={data.contactLastName}
                      onChange={(e) => setState(setData, 'contactLastName', e.target.value)}
                    />
                  </Label>
                </div>
              </div>
              <OptionDescription style={{ marginTop: '22px' }} description={optionDescriptions.contactName} />
            </OptionEntry>
          </FormSection>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default UserDataInputForm
