import { useContext } from 'react'
import { DEFAULT_LANGUAGE, PARTICIPANT_UPLOAD_HEADERS } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { GreyButton } from '../../utils/elements/miscElements'
import { containsSubstring } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'

export const getInitialHeaderMap = (uploadedHeaders, customFieldDefinitions) => {
  const firstNameMap = getFirstNameMap(uploadedHeaders)
  const lastNameMap = getLastNameMap(uploadedHeaders)
  const emailMap = getEmailMap(uploadedHeaders)
  const genderMap = getGenderMap(uploadedHeaders)
  const anonymousMap = getAnonymousMap(uploadedHeaders)
  const customFieldMaps = getCustomFieldMaps(uploadedHeaders, customFieldDefinitions)
  return Object.assign({}, firstNameMap, lastNameMap, emailMap, genderMap, anonymousMap, ...customFieldMaps)
}

const getFirstNameMap = (uploadedHeaders) => {
  const possibleHeaders = ['vorname', 'first name']
  const header = uploadedHeaders.find((uploadedHeader) => possibleHeaders.includes(uploadedHeader.toLowerCase())) || ''
  return { firstname: header }
}

const getLastNameMap = (uploadedHeaders) => {
  const possibleHeaders = ['nachname', 'name', 'last name']
  const header = uploadedHeaders.find((uploadedHeader) => possibleHeaders.includes(uploadedHeader.toLowerCase())) || ''
  return { lastname: header }
}

const getEmailMap = (uploadedHeaders) => {
  const header = uploadedHeaders.find((uploadedHeader) => containsSubstring(uploadedHeader.toLowerCase(), 'mail')) || ''
  return { email: header }
}

const getGenderMap = (uploadedHeaders) => {
  const possibleHeaders = ['geschlecht', 'gender', 'sex', 'anrede']
  const header = uploadedHeaders.find((uploadedHeader) => possibleHeaders.includes(uploadedHeader.toLowerCase())) || ''
  return { gender: header }
}

const getAnonymousMap = (uploadedHeaders) => {
  const possibleHeaders = ['anonym', 'anon', 'anonymisiert', 'datenschutz', 'anonymous']
  const header = uploadedHeaders.find((uploadedHeader) => possibleHeaders.includes(uploadedHeader.toLowerCase())) || ''
  return { anonym: header }
}

const getCustomFieldMaps = (uploadedHeaders, customFieldDefinitions) => {
  return customFieldDefinitions.map((cfd) => {
    const header =
      uploadedHeaders.find((uploadedHeader) => uploadedHeader === cfd.slug || uploadedHeader === cfd.label) || ''
    return { [cfd.slug]: header }
  })
}

export const OptionDescriptionUpload = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const sampleFileNames = {
    de: 'perseo_teilnehmerliste_muster.xlsx',
    en: 'perseo_participant_list_sample.xlsx'
  }
  const localizedFileName = sampleFileNames[context.language] || sampleFileNames[DEFAULT_LANGUAGE]
  const downloadPath = `/downloads/${localizedFileName}`
  return (
    <>
      <span>{t('uploadCSVInstructions')}</span> <br />
      <a rel="noreferrer" href={downloadPath}>
        <GreyButton type="button" style={{ marginTop: 'var(--space-5)' }}>
          <span className="svg-icon icon-arrow-down" style={{ marginRight: 'var(--space-3)' }} />
          <span style={{ whiteSpace: 'nowrap' }}>{t('downloadSample')}</span>
        </GreyButton>
      </a>
    </>
  )
}

export const formatUploadedData = (uploadedData, headerMap, customFieldDefinitions) => {
  if (!uploadedData) return

  const formattedData = uploadedData.map((row) => ({
    firstname: row[headerMap.firstname],
    lastname: row[headerMap.lastname],
    gender: getCsvGenderValue(row[headerMap.gender]),
    email: row[headerMap.email],
    anonym: getCsvAnonymousValue(row[headerMap.anonym]),
    customFields: customFieldDefinitions.map((cfd) => {
      const slug = cfd.slug
      return { customFieldKey: cfd.publicKey, slug: slug, customFieldValue: row[headerMap[slug]] || '' }
    })
  }))

  return formattedData
}

const getCsvAnonymousValue = (rawValue) => {
  const trueValues = ['ja', 'anonym', 'y', 'anonymous']
  if (trueValues.includes(rawValue?.toLowerCase())) {
    return 1
  }
  return 0
}

const getCsvGenderValue = (rawValue) => {
  const femaleValues = ['f', 'w', 'weiblich', 'frau']
  const maleValues = ['m', 'männlich', 'herr']
  const diverseValues = ['d', 'divers']

  if (femaleValues.includes(rawValue?.toLowerCase())) {
    return 'f'
  }
  if (maleValues.includes(rawValue?.toLowerCase())) {
    return 'm'
  }
  if (diverseValues.includes(rawValue?.toLowerCase())) {
    return 'd'
  }
  return ''
}

export const getUploadHeaders = (customFieldDefinitions) => {
  const customFieldHeaders = customFieldDefinitions.map((cfd) => ({
    androName: cfd.slug,
    label: cfd.label
  }))
  return [...PARTICIPANT_UPLOAD_HEADERS, ...customFieldHeaders]
}
