import React, { useContext } from 'react'
import {
  LinkContainer,
  WizardTileContainer,
  TopContainer,
  DetailsContainer,
  LinkDescription,
  DetailElement,
  Arrow
} from './styles'
import { ContentContainer, AddButton } from '../../components/table/tableElements'
import { AppContext } from '../../utils/context/AppContext'
import { PageHeadline } from '../../utils/elements/miscElements'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { PAGES } from '../../utils/constants/pages'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'

const Wizard = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const isAdmin = context.loggedInAsAdmin

  const wizardTiles = [
    {
      icon: 'add-process',
      page: PAGES.newProcess,
      headline: 'createProcess',
      buttonText: `+ ${t('newProcess')}`,
      buttonDisabled: !isAdmin,
      description: t('processExplanation'),
      details: [t('addProcessHint1'), t('addProcessHint2'), t('addProcessHint3')]
    },
    {
      icon: 'add-test',
      page: PAGES.newTest,
      headline: 'createTest',
      buttonText: `+ ${t('newTest')}`,
      description: t('testExplanation'),
      details: [t('addTestHint1'), t('addTestHint2')]
    },
    {
      icon: 'add-pt',
      page: PAGES.newParticipant,
      headline: 'createParticipants',
      buttonText: `+ ${t('newParticipants')}`,
      description: t('testLinkCreationExplanation'),
      details: [t('addParticipantHint1'), t('addParticipantHint2')]
    }
  ]

  return (
    <ScrollContainer type="main">
      <ContentContainer className="wizard-overview">
        <PageHeadline>{t('testLinkInstructions')}</PageHeadline>

        <LinkContainer>
          {wizardTiles.map((tile, i) => (
            <React.Fragment key={i}>
              <WizardTile
                key={tile.headline}
                page={tile.page}
                headline={tile.headline}
                description={tile.description}
                buttonText={tile.buttonText}
                buttonDisabled={tile.buttonDisabled}
                details={tile.details}
              />
              {i < wizardTiles.length - 1 && <Arrow />}
            </React.Fragment>
          ))}
        </LinkContainer>
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Wizard

const WizardTile = ({ page, headline, description, buttonText, details, buttonDisabled }) => {
  const t = useTranslate()
  const redirect = useRedirect()
  return (
    <WizardTileContainer>
      <TopContainer>
        <h3>{t(headline)}</h3>
        <LinkDescription>{description}</LinkDescription>
      </TopContainer>
      <DetailsContainer>
        {details.map((detail, i) => (
          <DetailElement key={detail + i}>{detail} </DetailElement>
        ))}
      </DetailsContainer>
      <AddButton
        style={{ marginTop: 'auto', padding: 'var(--space-4)', width: '100%' }}
        disabled={buttonDisabled}
        onClick={() => redirect(page)}>
        <span>{t(buttonText)}</span>
      </AddButton>
    </WizardTileContainer>
  )
}
