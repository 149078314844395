import { SettingsContainer } from './sharedElements/sharedElements'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import useTranslate from '../../utils/hooks/useTranslate'
import {
  ButtonPrimary,
  Label,
  OptionDescription,
  OptionEntry,
  WizardForm,
  selectStyles
} from '../../utils/elements/miscElements'
import { useContext, useState } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { API_DATA, SELECT_OPTIONS, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { fetchData, getOptionByValue, setState, setStatusBar } from '../../utils/helper/Helper'
import Select from 'react-select'

const DataManagementComponent = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const customer = context.completeDataSet.customer
  const [anonymizeTimeSpan, setAnonymizeTimeSpan] = useState(customer?.anonymizeTimeSpan)
  const [archiveTimeSpan, setArchiveTimeSpan] = useState(customer?.archiveTimeSpan)

  const onClick = (e) => {
    const data = {
      anonymizeTimeSpan: anonymizeTimeSpan,
      archiveTimeSpan: archiveTimeSpan
    }
    e.preventDefault()
    saveTimespans(data, context)
  }

  const content = (
    <SettingsContainer>
      <ScrollContainer type="settings">
        <h2>{t('dataMaintenance')}</h2>
        <WizardForm style={{ maxWidth: '800px' }}>
          <TimespanSelection
            data={anonymizeTimeSpan}
            setData={setAnonymizeTimeSpan}
            label="anonymizationDeadline"
            description="anonymizationExplanation"
          />
          <TimespanSelection
            data={archiveTimeSpan}
            setData={setArchiveTimeSpan}
            label="archiveDeadline"
            description="archiveExplanation"
          />
          <ButtonPrimary style={{ width: '100px', marginBottom: 'var(--space-7)' }} content="save" onClick={onClick} />
        </WizardForm>
      </ScrollContainer>
    </SettingsContainer>
  )

  return content
}

export default DataManagementComponent

const TimespanSelection = ({ data, setData, label, description }) => {
  const t = useTranslate()
  const options = t(SELECT_OPTIONS.anonymizeTimeSpans)
  return (
    <OptionEntry hasHint>
      <Label fullWidth>{t(label)}</Label>
      <Select
        options={options}
        onChange={(selectedOption) => setData(selectedOption.value)}
        value={getOptionByValue(options, data)}
        styles={selectStyles}
        placeholder=""
      />
      <OptionDescription description={description} />
    </OptionEntry>
  )
}

const saveTimespans = async (data, context) => {
  const payload = { ...data }

  const responseData = await fetchData(
    payload,
    API_DATA.customers.endPointEditAnonymizeTimespan,
    context,
    'dataChangeFailed'
  )

  try {
    setState(context.setCompleteDataSet, 'customer', responseData.response.customer)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'dataSuccessfullyChanged',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}
