import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  z-index: 2;
  flex-basis: ${(props) => (props.type === 'settings' ? 'var(--settings-nav-width)' : 'var(--main-nav-width)')};
  flex-grow: 0;
  flex-shrink: 0;
  margin: ${(props) => (props.type === 'settings' ? 'var(--inset) 0 var(--inset) var(--inset)' : 0)};
  background-color: ${(props) => (props.type === 'settings' ? 'transparent' : 'var(--main-nav-bg-color)')};
  padding: ${(props) => (props.type === 'settings' ? 0 : 'var(--space-3)')};
  @media screen and (max-width: 600px) {
    margin: ${(props) => (props.type === 'settings' ? 'var(--inset) var(--inset) 0 var(--inset)' : 0)};
    flex-basis: auto;
  }
`

export const SettingsNav = styled(Nav)`
  flex-basis: 214px;
  @media screen and (max-width: 768px) {
    flex-basis: unset;
  }
  &:hover {
    z-index: 3;
  }
`

export const MainNav = styled(Nav)`
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: var(--header-border-color);
  &:hover {
    z-index: 3;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--fs-3);
  padding: 10px var(--space-4);
  border-radius: var(--bdr-2);
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  &.active {
    background-color: ${(props) =>
      props.type === 'settings' ? 'var(--settings-nav-color-active)' : 'var(--main-nav-color-active)'};
    &:hover {
      background-color: ${(props) =>
        props.type === 'settings' ? 'var(--settings-nav-color-active)' : 'var(--main-nav-color-active)'};
    }
  }
  &:hover {
    background-color: ${(props) =>
      props.type === 'settings' ? 'var(--settings-nav-color-hover)' : 'var(--main-nav-hover-color)'};
  }
`

export const NavButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px var(--space-4);
  cursor: pointer;
  border-radius: var(--bdr-2);
  font-size: var(--fs-3);
  user-select: none;
  background-color: var(--nav-wizard-button-bg-color);
  box-shadow: var(--sh-1);
  transition: background-color var(--hover-duration);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  &:hover {
    background-color: var(--nav-wizard-button-hover-color);
    transition: background-color var(--hover-duration);
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`

export const NavMenuUl = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  &.main {
    margin-top: calc(var(--header-height) + var(--inset) - 8px);
  }
`

export const NavMenuItem = styled.li`
  list-style: none;
  font-size: var(--fs-4);
`

export const NavBarSpacer = styled.li`
  border-bottom: 1px solid var(--nav-bar-spacer-border-color);
  box-shadow: 0 1px 0 0 var(--nav-bar-spacer-shadow);
  margin: var(--space-3) var(--space-2);
  display: flex;
`

export const NavLinkLabel = styled.span`
  @media screen and (max-width: 768px) {
    display: none;
  }
  color: ${(props) => props.color || 'var(--text-color-primary)'};
`

export const NavMenuSubHeadline = styled.li.attrs({
  className: 'uppercase-label'
})`
  list-style: none;
  padding: 0 var(--space-4);
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavMenuInnerContainer = styled.div`
  display: grid;
  grid-template-columns: var(--space-5) 1fr;
  gap: var(--space-4);
  align-items: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    grid-template-columns: unset;
    width: auto;
  }
`

export const SettingsMenuTopIcon = styled.span`
  display: none;
  margin: var(--space-6) auto var(--space-6);
  @media screen and (max-width: 768px) {
    display: block;
  }
`

export const NavMenuAddButton = styled.div.attrs({
  className: 'add-button'
})`
  display: grid;
  grid-template-columns: var(--space-5) 1fr;
  gap: var(--space-4);
  align-items: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    grid-template-columns: unset;
    width: auto;
  }
`

export const CreditDisplay = styled(NavMenuItem)`
  padding: 10px var(--space-4);
  margin-top: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
export const MenuGroup = styled.li`
  &:not(:last-child):after {
    content: '';
    border-bottom: 1px solid var(--main-menu-spacer-border-color);
    margin: var(--context-menu-padding) -6px;
    display: flex;
    box-shadow: 0 1px 0 0 var(--main-menu-spacer-shadow-color);
  }
`

export const LinkContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: var(--main-nav-width);
  top: 0;
  height: calc(var(--header-height) + var(--inset));

  @media (max-width: 768px) {
    height: var(--header-height);
  }
`
