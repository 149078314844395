import React, { useContext } from 'react'
import styled from 'styled-components'
import { SmallDescription } from '../../components/table/tableElements'
import { TableContentContainer, TableLink } from '../../components/table/tableElements'
import { FIELD_CATEGORIES, FIELD_LABELS } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { getTableLinkParams } from '../../utils/helper/Helper'
import { LargeGreyText, DownloadPdfButtonLight } from '../../utils/elements/miscElements'
import useTranslate from '../../utils/hooks/useTranslate'
import { FilterContext } from '../../utils/context/FilterContext'
import useRedirect from '../../utils/hooks/useRedirect'

export const getParticipantNumberCol = () => ({
  Header: FIELD_LABELS.pNumber,
  id: 'pNr',
  accessor: 'pNr',
  canExport: true,
  disableSortBy: true,
  filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.participant },
  Cell: ({ row }) => {
    const redirect = useRedirect()
    const t = useTranslate()
    const { addLinkFilters } = useContext(FilterContext)
    const participant = row.original.relatedParticipant ? row.original.relatedParticipant : row.original
    const nameLabel = t(participant.nameLabel)
    const tableLinkParams = getTableLinkParams(participant, 'pNr', 'participants', FIELD_LABELS.pNumber)

    return (
      <TableContentContainer style={{ maxWidth: '200px' }}>
        <TableLink
          style={{ display: 'inline-grid' }}
          className={tableLinkParams.className}
          onClick={() => {
            addLinkFilters(tableLinkParams.filterParams)
            redirect(PAGES.participants)
          }}>
          {participant.pNr}
        </TableLink>
        <SmallDescription style={{ overflow: 'hidden', maxWidth: '150px', whiteSpace: 'nowrap' }}>
          {nameLabel}
        </SmallDescription>
      </TableContentContainer>
    )
  }
})

export const getTestAndProcessCol = () => ({
  Header: 'testAndProcess',
  accessor: (row) => row.relatedAssessment.assessmentName,
  disableSortBy: true,
  Cell: ({ row }) => {
    const redirect = useRedirect()
    const { addLinkFilters } = useContext(FilterContext)
    const process = row.original.relatedProcess
    const assessment = row.original.relatedAssessment
    const processName = process.processName
    const assessmentName = assessment.assessmentName
    const tableLinkParamsProcess = getTableLinkParams(process, 'processName', 'processes')
    const tableLinkParamsAssessment = getTableLinkParams(assessment, 'assessmentName', 'assessments')

    const processLinkForAssessmentTable = {
      field: { value: 'processName' },
      value: { value: process.processName },
      tableName: 'assessments'
    }

    return (
      <div style={{ overflow: 'hidden', maxWidth: '250px' }}>
        <TableLink
          style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}
          className={tableLinkParamsAssessment.className}
          onClick={() => {
            addLinkFilters([tableLinkParamsAssessment.filterParams, processLinkForAssessmentTable])
            redirect(PAGES.assessments)
          }}>
          {assessmentName}
        </TableLink>
        <SmallDescription>
          <TableLink
            className={tableLinkParamsProcess.className}
            onClick={() => {
              addLinkFilters(tableLinkParamsProcess.filterParams)
              redirect(PAGES.processes)
            }}>
            <OverflowEllipsis>{processName}</OverflowEllipsis>
          </TableLink>
        </SmallDescription>
      </div>
    )
  }
})

const OverflowEllipsis = styled.span`
  white-space: nowrap;
`

export const getResultDownloadCol = () => ({
  Header: 'Report',
  accessor: 'report',
  disableSortBy: true,
  Cell: ({ row }) => (
    <>
      {row.original.hasResult() ? (
        <div style={{ marginLeft: '-8px' }}>
          <DownloadPdfButtonLight resultNr={row.original.relatedResult.resultNr} />
        </div>
      ) : (
        <></>
      )}
    </>
  )
})

export const DashboardNotice = ({ text, offset }) => {
  const t = useTranslate()
  return (
    <DashboardNoticeContainer {...{ offset }}>
      <LargeGreyText>{t(text)}</LargeGreyText>
    </DashboardNoticeContainer>
  )
}

const DashboardNoticeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => props.offset + 'px'};
`

export const DashboardItem = styled.div`
  background-color: var(--dashboard-item-bg-color);
  padding: var(--space-6);
  border-radius: var(--bdr-3);
  box-shadow: var(--sh-1);
  overflow: auto;
`

export const DashboardHeadline = styled.h2`
  margin-bottom: var(--space-5);
  font-weight: var(--fw-2);
  font-size: var(--fs-6);
  line-height: var(--lh-3);
  letter-spacing: -0.2px;
`

export const DashboardItemsContainer = styled.div`
  display: grid;
  gap: var(--space-5);
  grid-template-rows: auto 1fr;
  grid-template-columns: minmax(500px, 750px) 240px;
  @media (max-width: 900px) {
    grid-template-columns: unset;
  }
`

export const HeadlineButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const OpenFullScreenButton = styled.button`
  background-color: var(--small-button-bg-color);
  padding: var(--space-2) var(--space-3);
  border-radius: var(--bdr-2);
  font-size: var(--fs-2);
  font-weight: var(--fw-2);
  &:hover {
    background-color: var(--small-button-hover-color);
  }
`

export const SubContainer = styled.div`
  display: grid;
  gap: var(--space-5);
  ${DashboardItem} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: unset;
  }
`
