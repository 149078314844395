import { ENTITIES, FIELD_CATEGORIES, MissingReferenceNotice } from '../../../utils/constants/constants'
import { getCustomFieldContent, showMissingReferenceNotice } from '../../../utils/helper/Helper'
import { TableContentContainer } from '../tableElements'

export const getCustomCols = (customFieldDefinitions, isDefaultVisible, link, entity) => {
  return customFieldDefinitions
    .filter((cfd) => cfd.model === ENTITIES.participants)
    .map((cfd) => ({
      Header: cfd.label,
      id: cfd.slug,
      show: isDefaultVisible,
      canExport: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.customFields },
      accessor: (row) => getCustomFieldContent(link ? row[link] : row, cfd),
      Cell: ({ row }) => {
        if (
          entity === ENTITIES.bookings &&
          showMissingReferenceNotice(row.original.relatedParticipant, row.original.bookingKey)
        ) {
          return <MissingReferenceNotice />
        } else {
          return (
            <TableContentContainer style={{ overflow: 'scroll', maxWidth: '300px' }}>
              {getCustomFieldContent(link ? row.original[link] : row.original, cfd)}
            </TableContentContainer>
          )
        }
      }
    }))
}
