import { TileHeadline, Tile } from '../table/tableElements'
import { InfoList } from '../../utils/elements/miscElements'
import BillingOverview from '../../pages/shop/subcomponents/BillingOverview'

const BillingAddressTile = ({ billingData, billingDataValid, setBillingDataValid }) => (
  <Tile width="300px">
    <TileHeadline content="invoiceData" />
    <InfoList type="billing">
      <BillingOverview
        billingData={billingData}
        billingDataValid={billingDataValid}
        setBillingDataValid={setBillingDataValid}
        isOnConfirmationPage
      />
    </InfoList>
  </Tile>
)

export default BillingAddressTile
