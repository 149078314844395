import ConfigInfos from '../configInfos/ConfigInfos'
import ModuleOverview from '../moduleOverview/ModuleOverview'
import { Tile, TileContainer, TileHeadline } from '../table/tableElements'

const AssessmentDetailsTab = ({ assessment }) => {
  return (
    <TileContainer style={{ maxWidth: '950px' }}>
      <Tile width="350px">
        <TileHeadline content="Details" />
        <ConfigInfos config={assessment.relatedConfig} assessment={assessment} showModules position="tab" />
      </Tile>
      <Tile>
        <TileHeadline content="testContent" />
        <ModuleOverview config={assessment.relatedConfig} assessment={assessment} />
      </Tile>
    </TileContainer>
  )
}
export default AssessmentDetailsTab
