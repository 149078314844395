import { useContext } from 'react'
import useTranslate from '../../utils/hooks/useTranslate'
import { AppContext } from '../../utils/context/AppContext'
import DataTable from '../../components/table/DataTable'
import { getFormattedPrice } from '../../utils/helper/Helper'
import { createDate, formatDate } from '../../utils/helper/dateTimeHelper'
import {
  CONTENT_TYPES,
  FIELD_CATEGORIES,
  FILTER_TYPES,
  INVOICE_STATUS_NAMES,
  PAYMENT_OPTIONS
} from '../../utils/constants/constants'
import { DownloadInvoiceButtonLight } from '../../utils/elements/miscElements'
import { ContentContainer } from '../../components/table/tableElements'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { getSpacerCol } from '../../components/table/tableCols/tableCols'

const Invoices = () => {
  const t = useTranslate()
  const { completeDataSet } = useContext(AppContext)
  const invoices = completeDataSet.invoices
  const columns = getCols(t)
  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <DataTable
          data={invoices}
          columns={columns}
          tableName="invoices"
          showFilters
          hideArchiveButton
          showRowSelection
          showColumnController
        />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Invoices

const getCols = (t) => [
  {
    Header: 'freeTextFilter',
    id: 'freeText',
    accessor: (row) => row.invoiceNo + t(INVOICE_STATUS_NAMES[row.status]) + row.billingMail + row.billingMailCc,
    filterOptions: { type: FILTER_TYPES.textInput },
    canExport: false,
    show: false
  },
  {
    Header: 'invoiceNo',
    accessor: 'invoiceNo',
    id: 'invoiceNo',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice }
  },
  {
    Header: 'dateBilled',
    accessor: (row) => createDate(row.dateBilled),
    id: 'dateBilled',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice, contentType: CONTENT_TYPES.date },
    Cell: ({ value }) => <>{formatDate(value).dateOnly}</>
  },
  {
    Header: 'status',
    accessor: 'status',
    id: 'status',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice, dataLabels: INVOICE_STATUS_NAMES },
    Cell: ({ value }) => <InvoiceStatusDisplay {...{ value }} />
  },
  {
    Header: 'net',
    accessor: 'net',
    id: 'net',
    textAlign: 'right',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice, contentType: CONTENT_TYPES.number },
    Cell: ({ value }) => {
      const { language } = useContext(AppContext)
      return <div style={{ textAlign: 'right' }}>{getFormattedPrice(value, language, 2)}</div>
    }
  },
  {
    Header: 'vat',
    accessor: 'vatSum',
    id: 'vatSum',
    textAlign: 'right',
    canExport: true,
    show: false,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice, contentType: CONTENT_TYPES.number },
    Cell: ({ value }) => {
      const { language } = useContext(AppContext)
      return <div style={{ textAlign: 'right' }}>{getFormattedPrice(value, language, 2)}</div>
    }
  },
  {
    Header: 'gross',
    accessor: 'gross',
    id: 'gross',
    textAlign: 'right',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice, contentType: CONTENT_TYPES.number },
    Cell: ({ value }) => {
      const { language } = useContext(AppContext)
      return <div style={{ textAlign: 'right' }}>{getFormattedPrice(value, language, 2)}</div>
    }
  },

  {
    Header: 'paymentMethod',
    accessor: 'paymentMethod',
    id: 'paymentMethod',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice },
    Cell: ({ value }) => {
      const t = useTranslate()
      return <>{t(PAYMENT_OPTIONS.find((opt) => opt.title === value)?.content || t('invoice'))}</>
    }
  },
  {
    Header: 'recipient',
    id: 'recipient',
    canExport: true,
    accessor: 'billingMail',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice }
  },
  {
    Header: 'recipientCc',
    id: 'recipientCc',
    canExport: true,
    show: false,
    accessor: 'billingMailCc',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.invoice }
  },
  getSpacerCol(),
  {
    Header: 'Download',
    id: 'actions',
    accessor: 'downloadInvoice',
    disableSortBy: true,
    className: 'actions-col',

    Cell: ({ row }) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DownloadInvoiceButtonLight invoiceNo={row.original.invoiceNo} />
      </div>
    )
  }
]

const InvoiceStatusDisplay = ({ value }) => {
  const t = useTranslate()
  return <span className="capitalizeFirstLetter">{t(INVOICE_STATUS_NAMES[value]) || 'unknown status'}</span>
}
