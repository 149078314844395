import {
  EMAIL_TYPES,
  FIELD_LABELS,
  NO_VALID_FROM_SET_TEXT,
  NO_VALID_UNTIL_SET_TEXT,
  THE_FUTURE,
  THE_PAST
} from '../../utils/constants/constants'
import { createDate } from '../../utils/helper/dateTimeHelper'

export const getTimeLineData = (pt) => {
  const dates = {
    validFrom: pt.ptValidFrom ? createDate(pt.ptValidFrom) : null,
    validUntil: pt.ptValidUntil ? createDate(pt.ptValidUntil) : null,
    ptInvited: pt.datePtInvited ? createDate(pt.datePtInvited) : null,
    ptStarted: pt.datePtStarted ? createDate(pt.datePtStarted) : null,
    ptFinished: pt.datePtFinished ? createDate(pt.datePtFinished) : null
  }

  const timeLineData = [
    {
      dataKey: 'validFrom',
      sortValue: dates.validFrom || createDate(THE_PAST),
      value: dates.validFrom || NO_VALID_FROM_SET_TEXT,
      label: FIELD_LABELS.validFrom
    },
    {
      dataKey: 'validUntil',
      sortValue: dates.validUntil || createDate(THE_FUTURE),
      value: dates.validUntil || NO_VALID_UNTIL_SET_TEXT,
      label: FIELD_LABELS.validUntil
    },
    {
      sortValue: dates.ptInvited,
      value: dates.ptInvited,
      label: 'timerStarted'
    },
    {
      sortValue: dates.ptStarted,
      value: dates.ptStarted,
      label: 'testStarted'
    },
    {
      sortValue: dates.ptFinished,
      value: dates.ptFinished,
      label: 'testFinished'
    }
  ]

  const invitations = getInvitations(pt.ptInvitations)
  const merged = invitations ? timeLineData.concat(invitations) : timeLineData
  const filteredData = merged
    .filter((entry) => entry.value)
    .filter((entry) =>
      !pt.isReady() && entry.value === NO_VALID_FROM_SET_TEXT ? entry.dataKey !== 'validFrom' : entry
    )
    .filter((entry) =>
      pt.hasResult() && entry.value === NO_VALID_UNTIL_SET_TEXT ? entry.dataKey !== 'validUntil' : entry
    )
    .sort((a, b) => a.sortValue - b.sortValue)

  return filteredData
}

const getInvitations = (ptInvitations) => {
  if (!Array.isArray(ptInvitations)) return null
  const invitations = ptInvitations.map((invitation) => {
    const obj = {
      sortValue: createDate(invitation.created),
      value: createDate(invitation.created),
      label: getInvitationLabel(invitation.emailType)
    }
    return obj
  })
  return invitations
}

const getInvitationLabel = (type) => {
  return type === EMAIL_TYPES.invitation ? 'invitationSent' : 'reminderSent'
}
