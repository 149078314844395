import React from 'react'
import TimePicker from '../../../components/timePicker/TimePicker'
import ToggleButton from '../../../components/toggleButton/ToggleButton'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import styled from 'styled-components'
import {
  WizardForm,
  Label,
  OptionEntry,
  OptionDescription,
  PageHeadline,
  SubHeadline
} from '../../../utils/elements/miscElements'
import Calendar from '../../../components/calendar/Calendar'
import { FIELD_LABELS, SELECT_OPTIONS } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
import TimerHelp from '../../../components/helpModal/TimerHelp'
import StartedDurationHelp from '../../../components/helpModal/StartedDurationHelp'

const TimeSettingsForm = ({ data, setData, optionDescriptions, isValid, setStartedDurationIsValid }) => {
  const t = useTranslate()
  return (
    <>
      <PageHeadline>{t('testDurationAndTiming')}</PageHeadline>
      <ScrollContainer>
        <SubHeadline>{t('settingsAdjustableLater')}</SubHeadline>
        <WizardForm>
          <OptionEntry hasHint>
            <Label fullWidth>{t(FIELD_LABELS.validFrom)}</Label>
            <div>
              <ToggleButton
                options={SELECT_OPTIONS.setValidFrom}
                data={data}
                setData={setData}
                controls="validFrom"
                dataKey="showValidFrom"
              />
              <OuterContainer className={data.showValidFrom ? 'slide-down' : 'slide-up'}>
                <InnerContainer>
                  <Calendar
                    controls="validFrom"
                    type="text"
                    setData={setData}
                    value={data.validFrom}
                    fieldName="validFrom"
                    hasError={!isValid.startAndEndDates}
                  />
                </InnerContainer>
              </OuterContainer>
            </div>
            <OptionDescription description={optionDescriptions.validFrom} />
          </OptionEntry>
          <OptionEntry hasHint>
            <Label fullWidth>{t(FIELD_LABELS.validUntil)}</Label>
            <div>
              <ToggleButton
                options={SELECT_OPTIONS.setValidUntil}
                data={data}
                setData={setData}
                controls="validUntil"
                dataKey="showValidUntil"
              />
              <OuterContainer className={data.showValidUntil ? 'slide-down' : 'slide-up'}>
                <InnerContainer>
                  <Calendar
                    controls="validUntil"
                    type="text"
                    setData={setData}
                    value={data.validUntil}
                    fieldName="validUntil"
                    hasError={!isValid.startAndEndDates}
                  />
                </InnerContainer>
              </OuterContainer>
            </div>
            <OptionDescription description={optionDescriptions.validUntil} />
          </OptionEntry>
          <OptionEntry hasHint>
            <Label fullWidth>{t(FIELD_LABELS.invitedDuration)}</Label>
            <div>
              <ToggleButton
                options={SELECT_OPTIONS.setTimer}
                data={data}
                setData={setData}
                controls="invitedDuration"
                dataKey="showInvitedDuration"
              />
              <OuterContainer className={data.showInvitedDuration ? 'slide-down' : 'slide-up'}>
                <InnerContainer>
                  <TimePicker
                    seconds={data.invitedDuration}
                    setData={setData}
                    controls="invitedDuration"
                    field="invitedDuration"
                    useMenuPortal
                  />
                </InnerContainer>
              </OuterContainer>
            </div>
            <OptionDescription
              description={optionDescriptions.invitedDuration}
              helpHeadline="invitedDuration"
              helpContent={<TimerHelp />}
            />
          </OptionEntry>
          <OptionEntry hasHint>
            <Label fullWidth>{t(FIELD_LABELS.startedDuration)}</Label>
            <div>
              <ToggleButton
                options={SELECT_OPTIONS.setStartedDuration}
                data={data}
                setData={setData}
                controls="startedDuration"
                dataKey="showStartedDuration"
              />
              <OuterContainer className={data.showStartedDuration ? 'slide-down' : 'slide-up'}>
                <InnerContainer>
                  <TimePicker
                    seconds={data.startedDuration}
                    setData={setData}
                    controls="startedDuration"
                    field="startedDuration"
                    useMenuPortal
                    minDuration={getConfigDuration(data.parentConfig, data.configUuid)}
                    setIsValid={setStartedDurationIsValid}
                  />
                </InnerContainer>
              </OuterContainer>
            </div>
            <OptionDescription
              description={optionDescriptions.startedDuration}
              helpHeadline="startedDuration"
              helpContent={<StartedDurationHelp />}
            />
          </OptionEntry>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default TimeSettingsForm

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: var(--space-3);
  position: relative;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
const InnerContainer = styled.div`
  grid-column: 2;
  @media screen and (max-width: 1000px) {
    grid-column: 1/3;
  }
`

const getConfigDuration = (parentConfig, configUuid) => {
  const config = parentConfig.availableConfigs.find((config) => config.configUuid === configUuid)
  return config.configDuration
}
