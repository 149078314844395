import { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import Config from '../constants/config'
import { getRequestOptions } from '../helper/Helper'
import sessionHandler from '../helper/sessionHandler'

const useMaintenanceMode = () => {
  const context = useContext(AppContext)
  useEffect(() => {
    checkMaintenanceMode(context)
  }, [])
}

export default useMaintenanceMode

const MAINTENANCE_MODE_CODE = 99

const isInMaintenanceMode = (code) => code === MAINTENANCE_MODE_CODE

const fetchMaintenanceInfo = () => {
  const url = `${Config.baseUrl}/system_status_customers.json`
  const requestOptions = getRequestOptions()
  return fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error('Error fetching JSON:', error))
}

export const checkMaintenanceMode = (context) => {
  fetchMaintenanceInfo().then((data) => {
    if (isInMaintenanceMode(data?.maintenance_mode)) {
      context.setMaintenanceMode(true)
      context.setMaintenanceModeText(data.maintenance_text)
      sessionHandler.logout(context)
    }
  })
}
