import { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'

const LanguageSelection = () => {
  const context = useContext(AppContext)
  const t = useTranslate()

  return (
    <div style={{ position: 'relative' }}>
      <GlobeIcon className="svg-icon icon-globe" />
      <LangSelect
        value={context.language}
        onChange={(e) => context.setLanguage(e.target.value)}
        className="lang-select"
      >
        <option value="de">{t('german')}</option>
        <option value="en">{t('english')}</option>
      </LangSelect>
    </div>
  )
}

export default LanguageSelection

const GlobeIcon = styled.span`
  position: absolute;
  width: var(--space-5);
  height: var(--space-5);
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
  left: 4px;
`

const LangSelect = styled.select`
  border: none;
  background-color: transparent;
  border-radius: var(--bdr-2);
  font-size: var(--fs-3);
  color: var(--text-color-primary);
  padding: 6px 4px 4px 28px;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: var(--space-7);
  cursor: pointer;
  transition: border var(--hover-duration);
  &:hover {
    background-color: var(--lang-select-hover-bg-color);
    transition: border var(--hover-duration);
  }
`
