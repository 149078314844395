import Assessments from '../../pages/assessments/Assessments'
import Participants from '../../pages/participants/Participants'
import Processes from '../../pages/processes/Processes'
import Pts from '../../pages/pts/Pts'
import Privacy from '../../pages/privacy'
import Imprint from '../../pages/imprint'
import Help from '../../pages/help'
import Downloads from '../../pages/downloads'
import Wizard from '../../pages/wizard/wizard'
import Credits from '../../pages/credits/credits'
import Dashboard from '../../pages/dashboard/Dashboard'
import AddToTestWizard from '../../wizards/addToTestWizard/AddToTestWizard'
import AddAssessmentWizard from '../../wizards/addAssessmentWizard/AddAssessmentWizard'
import AddParticipantWizard from '../../wizards/addParticipantWizard/AddParticipantWizard'
import AddProcessWizard from '../../wizards/addProcessWizard/AddProcessWizard'
import AccountSettingsComponent from '../../components/settings/AccountSettingsComponent'
import UserManagementComponent from '../../components/settings/UserManagementComponent'
import AddUserWizard from '../../wizards/addUserWizard/AddUserWizard'
import ChangeCapabilitiesWizard from '../../wizards/changeCapabilitiesWizard/ChangeCapabilitiesWizard'
import NotificationSettingsComponent from '../../components/settings/notifications/NotificationSettingsComponent'
import Login from '../../pages/loginArea/loginContent/login'
import Signup from '../../pages/loginArea/loginContent/signup'
import RestorePassword from '../../pages/loginArea/loginContent/restorePassword'
import SetNewPassword from '../../pages/loginArea/loginContent/setNewPassword'
import SendEmailWizard from '../../wizards/sendEmailWizard/SendEmailWizard'
import Support from '../../pages/support'
import CustomFieldsComponent from '../../components/settings/customFieldDefinitions/CustomFieldDefinitionsComponent'
import LiveDataFullScreen from '../../pages/liveDataFullScreen'
import UserSettingsComponent from '../../components/settings/UserSettingsComponent'
import DisplayAndLanguageComponent from '../../components/settings/DisplayAndLanguageComponent'
import EmailTemplateSettingsComponent from '../../components/settings/EmailTemplateSettingsComponent'
import ShopWrapper from '../../pages/shop/ShopWrapper'
import Invoices from '../../pages/invoices/Invoices'
import DataManagementComponent from '../../components/settings/DataManagementComponent'
import ScoringSettings from '../../components/settings/ScoringSettings'

export const PAGES = {
  dashboard: {
    urlSlugs: {
      de: '/dashboard',
      en: '/dashboard'
    },
    documentTitle: 'Dashboard',
    navLevels: ['Dashboard'],
    component: <Dashboard />,
    isPublic: false,
    adminOnly: false
  },
  processes: {
    urlSlugs: {
      de: '/verfahren/uebersicht',
      en: '/processes/overview'
    },
    documentTitle: 'processes',
    navLevels: ['processes', 'overview'],
    component: <Processes />,
    isPublic: false,
    adminOnly: false
  },
  assessments: {
    urlSlugs: {
      de: '/tests/uebersicht',
      en: '/tests/overview'
    },
    documentTitle: 'Tests',
    navLevels: ['Tests', 'overview'],
    component: <Assessments />,
    isPublic: false,
    adminOnly: false
  },
  participants: {
    urlSlugs: {
      de: '/teilnehmer/uebersicht',
      en: '/participants/overview'
    },
    documentTitle: 'participants',
    navLevels: ['participants', 'overview'],
    component: <Participants />,
    isPublic: false,
    adminOnly: false
  },
  pts: {
    urlSlugs: {
      de: '/testungen/uebersicht',
      en: '/testings/overview'
    },
    documentTitle: 'pts',
    navLevels: ['pts', 'overview'],
    component: <Pts />,
    isPublic: false,
    adminOnly: false
  },
  help: {
    urlSlugs: {
      de: '/hilfe',
      en: '/help'
    },
    documentTitle: 'help',
    navLevels: ['help'],
    component: <Help />,
    isPublic: false,
    adminOnly: false
  },
  support: {
    urlSlugs: {
      de: '/support',
      en: '/support'
    },
    documentTitle: 'Support',
    navLevels: ['Support'],
    component: <Support />,
    isPublic: false,
    adminOnly: false
  },
  shop: {
    urlSlugs: {
      de: '/credit-shop',
      en: '/credit-shop'
    },
    documentTitle: 'Credit Shop',
    navLevels: ['Credit Shop'],
    component: <ShopWrapper />,
    isPublic: false,
    adminOnly: true
  },
  credits: {
    urlSlugs: {
      de: '/buchungen/uebersicht',
      en: '/bookings/overview'
    },
    documentTitle: 'bookings',
    navLevels: ['bookings', 'overview'],
    component: <Credits />,
    isPublic: false,
    adminOnly: true
  },
  privacy: {
    urlSlugs: {
      de: '/datenschutz',
      en: '/privacy'
    },
    documentTitle: 'dataSecurity',
    navLevels: ['dataSecurity'],
    component: <Privacy />,
    isPublic: false,
    adminOnly: false
  },
  quickSetup: {
    urlSlugs: {
      de: '/erstellen',
      en: '/create'
    },
    documentTitle: 'create',
    navLevels: ['create'],
    component: <Wizard />,
    isPublic: false,
    adminOnly: false
  },
  newProcess: {
    urlSlugs: {
      de: '/verfahren/verfahren-anlegen',
      en: '/processes/create-process'
    },
    documentTitle: 'createProcess',
    navLevels: ['processes', 'createProcess'],
    component: <AddProcessWizard />,
    isPublic: false,
    adminOnly: true
  },
  newTest: {
    urlSlugs: {
      de: '/tests/test-anlegen',
      en: '/tests/create-test'
    },
    documentTitle: 'createTest',
    navLevels: ['Tests', 'createTest'],
    component: <AddAssessmentWizard />,
    isPublic: false,
    adminOnly: false
  },
  newParticipant: {
    urlSlugs: {
      de: '/teilnehmer/teilnehmer-anlegen',
      en: '/participants/create-participant'
    },
    documentTitle: 'createParticipants',
    navLevels: ['participants', 'createParticipants'],
    component: <AddParticipantWizard />,
    isPublic: false,
    adminOnly: false
  },
  sendEmails: {
    urlSlugs: {
      de: '/emails-senden',
      en: '/send-emails'
    },
    documentTitle: 'sendMails',
    navLevels: ['sendMails'],
    component: <SendEmailWizard />,
    isPublic: false,
    adminOnly: false
  },
  imprint: {
    urlSlugs: {
      de: '/impressum',
      en: '/legal-notice'
    },
    documentTitle: 'imprint',
    navLevels: ['imprint'],
    component: <Imprint />,
    isPublic: false,
    adminOnly: false
  },
  downloads: {
    urlSlugs: {
      de: '/dokumente',
      en: '/documents'
    },
    documentTitle: 'documents',
    navLevels: ['documents'],
    component: <Downloads />,
    isPublic: false,
    adminOnly: false
  },
  users: {
    urlSlugs: {
      de: '/einstellungen/nutzerverwaltung',
      en: '/settings/user-administration'
    },
    documentTitle: 'userAdministration',
    navLevels: ['settings', 'userAdministration'],
    component: <UserManagementComponent />,
    isPublic: false,
    adminOnly: true
  },
  accountSettings: {
    urlSlugs: {
      de: '/einstellungen/kontoeinstellungen',
      en: '/settings/account-settings'
    },
    documentTitle: 'accountSettings',
    navLevels: ['settings', 'accountSettings'],
    component: <AccountSettingsComponent />,
    isPublic: false,
    adminOnly: true
  },
  userSettings: {
    urlSlugs: {
      de: '/einstellungen/nutzereinstellungen',
      en: '/settings/user-settings'
    },
    documentTitle: 'userSettings',
    navLevels: ['settings', 'userSettings'],
    component: <UserSettingsComponent />,
    isPublic: false,
    adminOnly: false
  },
  displayAndLanguageSettings: {
    urlSlugs: {
      de: '/einstellungen/darstellung-und-sprache',
      en: '/settings/display-and-language'
    },
    documentTitle: 'displayAndLanguage',
    navLevels: ['settings', 'displayAndLanguage'],
    component: <DisplayAndLanguageComponent />,
    isPublic: false,
    adminOnly: false
  },
  notifications: {
    urlSlugs: {
      de: '/einstellungen/benachrichtigungen',
      en: '/settings/notifications'
    },
    documentTitle: 'notifications',
    navLevels: ['settings', 'notifications'],
    component: <NotificationSettingsComponent />,
    isPublic: false,
    adminOnly: false
  },
  dataSecurity: {
    urlSlugs: {
      de: '/einstellungen/datenpflege',
      en: '/settings/data-maintenance'
    },
    documentTitle: 'dataMaintenance',
    navLevels: ['settings', 'dataMaintenance'],
    component: <DataManagementComponent />,
    isPublic: false,
    adminOnly: true
  },
  newUser: {
    urlSlugs: {
      de: '/einstellungen/nutzerverwaltung/nutzer-anlegen',
      en: '/settings/user-administration/add-user'
    },
    documentTitle: 'createUser',
    navLevels: ['settings', 'userAdministration', 'createUser'],
    component: <AddUserWizard />,
    isPublic: false,
    adminOnly: true
  },
  customFieldDefinitions: {
    urlSlugs: {
      de: '/einstellungen/eigene-felder',
      en: '/settings/custom-fields'
    },
    documentTitle: 'customFields',
    navLevels: ['settings', 'customFields'],
    component: <CustomFieldsComponent />,
    isPublic: false,
    adminOnly: true
  },
  emailTemplates: {
    urlSlugs: {
      de: '/einstellungen/email-vorlagen',
      en: '/settings/email-templates'
    },
    documentTitle: 'emailTemplates',
    navLevels: ['settings', 'emailTemplates'],
    component: <EmailTemplateSettingsComponent />,
    isPublic: false,
    adminOnly: true
  },
  changeCapabilities: {
    urlSlugs: {
      de: '/verfahren/zugriffsrechte-aendern',
      en: '/processes/change-permissions'
    },
    documentTitle: 'changeCapabilities',
    navLevels: ['processes', 'changeCapabilities'],
    component: <ChangeCapabilitiesWizard />,
    isPublic: false,
    adminOnly: false
  },
  addExisting: {
    urlSlugs: {
      de: '/weitere-tests-zuweisen',
      en: '/assign-additional-tests'
    },
    documentTitle: 'addParticipantsToTests',
    navLevels: ['participants', 'addParticipantsToTests'],
    component: <AddToTestWizard />,
    isPublic: false,
    adminOnly: false
  },
  signup: {
    urlSlugs: {
      de: '/zugang-anfordern',
      en: '/get-access'
    },
    documentTitle: 'getAccess',
    navLevels: ['getAccess'],
    component: <Signup />,
    isPublic: true,
    adminOnly: false
  },
  login: {
    urlSlugs: {
      de: '/login',
      en: '/login'
    },
    documentTitle: 'Login',
    navLevels: ['Login'],
    component: <Login />,
    isPublic: true,
    adminOnly: false
  },
  restorePassword: {
    urlSlugs: {
      de: '/passwort-vergessen',
      en: '/forgot-password'
    },
    documentTitle: 'forgotPassword',
    navLevels: ['forgotPassword'],
    component: <RestorePassword />,
    isPublic: true,
    adminOnly: false
  },
  newPassword: {
    urlSlugs: {
      de: '/neues-passwort',
      en: '/new-password'
    },
    documentTitle: 'newPassword',
    navLevels: ['newPassword'],
    component: <SetNewPassword />,
    isPublic: true,
    adminOnly: false
  },
  liveData: {
    urlSlugs: {
      de: '/dashboard/live',
      en: '/dashboard/live'
    },
    documentTitle: 'liveData',
    navLevels: ['Dashboard', 'liveData'],
    component: <LiveDataFullScreen />,
    isPublic: false,
    adminOnly: false
  },
  invoices: {
    urlSlugs: {
      de: '/rechnungen',
      en: '/invoices'
    },
    documentTitle: 'invoices',
    navLevels: ['invoices'],
    component: <Invoices />,
    isPublic: false,
    adminOnly: true
  },
  scoring: {
    urlSlugs: {
      de: '/einstellungen/benotungsregeln',
      en: '/settings/gradingrules'
    },
    documentTitle: 'gradingRules',
    navLevels: ['settings', 'gradingRules'],
    component: <ScoringSettings />,
    isPublic: false,
    adminOnly: true
  }
}
