import styled from 'styled-components'

const Arrow = (props) => {
  const style = {
    position: props.sortArrow ? 'absolute' : 'relative',
    transition: 'transform 300ms, background-color var(--hover-duration)',
    transform: getDirection(props.direction),
    backgroundColor: props.color ? props.color : 'var(--small-action-icon-color)'
  }

  return (
    <ArrowContainer {...props}>
      <span style={style} className="svg-icon icon-caret" />
    </ArrowContainer>
  )
}

export default Arrow

const getDirection = (direction) => {
  switch (direction) {
    case 'right':
    default:
      return 'rotate(-90deg)'
    case 'down':
      return 'rotate(0deg)'
    case 'up':
      return 'rotate(-180deg)'
    case 'left':
      return 'rotate(90deg)'
  }
}

const ArrowContainer = styled.span`
  display: ${(props) => (props.sortArrow ? 'inline' : 'flex')};
`
