import styled from 'styled-components'
import Select from 'react-select'
import { selectStyles } from '../../../utils/elements/miscElements'
import { setState } from '../../../utils/helper/Helper'
import { formatUploadedData, getUploadHeaders } from '../uploadUtils'
import { useContext, useEffect } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'

const HeaderMapping = ({ data, setData, headerMap, setHeaderMap }) => {
  const context = useContext(AppContext)
  const uploadHeaders = getUploadHeaders(context.completeDataSet.customFieldDefinitions)
  const t = useTranslate()

  const options = data.uploadedHeaders.map((header) => ({
    label: header,
    value: header
  }))

  const updateHeaderMap = (selectedOption, header) => {
    setState(setHeaderMap, header.androName, selectedOption?.label)
  }

  useEffect(() => {
    const formattedUploadedData = formatUploadedData(
      data.parsedCsvData,
      headerMap,
      context.completeDataSet.customFieldDefinitions
    )
    setState(setData, 'uploadedData', formattedUploadedData)
  }, [headerMap, data.parsedCsvData, setData, context.completeDataSet.customFieldDefinitions])

  return (
    <HeaderMapContainer style={{}}>
      {uploadHeaders.map((header) => (
        <div key={header.androName}>
          <HeaderLabel>{t(header.label)}</HeaderLabel>
          <Select
            options={options}
            styles={selectStyles}
            isSearchable={false}
            placeholder=""
            value={options.find((option) => option.label === headerMap[header.androName])}
            onChange={(selectedOption) => updateHeaderMap(selectedOption, header)}
            isClearable
          />
        </div>
      ))}
    </HeaderMapContainer>
  )
}

export default HeaderMapping

const HeaderLabel = styled.span`
  color: var(--text-color-secondary);
  font-size: var(--fs-0);
  text-transform: uppercase;
  font-weight: 600;
`

const HeaderMapContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: var(--space-3);
`
