import useTranslate from '../../../utils/hooks/useTranslate'
import ScrollContainer from '../../scrollContainer/ScrollContainer'
import TabGroup from '../../tabGroup/TabGroup'
import { SettingsContainer } from '../sharedElements/sharedElements'
import EmailNotifications from './EmailNotifications'
import Newsletter from './Newsletter'
import StartupNotificationList from './StartupNotificationList'

const NotificationSettingsComponent = () => {
  const t = useTranslate()
  return (
    <SettingsContainer>
      <ScrollContainer type="settings">
        <h2>{t('notifications')}</h2>
        <TabGroup
          position="settings-nav"
          tabs={[
            {
              label: 'completedTests',
              id: 'emailNotifications',
              content: <EmailNotifications />
            },
            {
              label: 'Newsletter',
              id: 'newsletter',
              content: <Newsletter />
            },
            {
              label: 'startupNotifications',
              id: 'startupNotifications',
              content: <StartupNotificationList />
            }
          ]}
        />
      </ScrollContainer>
    </SettingsContainer>
  )
}

export default NotificationSettingsComponent
