import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MenuContainer = styled.ul`
  display: grid;
  position: absolute;
  top: ${(props) => (props.coords.top ? props.coords.top + 'px' : '')};
  right: ${(props) => (props.coords.right ? props.coords.right + 'px' : '')};
  bottom: ${(props) => (props.coords.bottom ? props.coords.bottom + 'px' : '')};
  left: ${(props) => (props.coords.left ? props.coords.left + 'px' : '')};
  padding: var(--context-menu-padding);
  list-style: none;
  background-color: var(--main-menu-bg-color);
  margin: 0;
  border-radius: var(--bdr-3);
  box-shadow: 0 1px 2px 1px #00000040;
  z-index: 999;
  width: 180px;
  * {
    user-select: none;
  }
`

export const MenuInnerContainer = styled.ul`
  list-style: none;
  padding: 0;
`

export const Icon = styled.span`
  transition: background-color var(--hover-duration);
  background-color: ${(props) => props.background || ''};
  margin-right: var(--space-4);
`

export const MenuLink = styled(Link)`
  padding: var(--space-3);
  border-radius: var(--space-2);
  width: 100%;
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: var(--fs-3);
  color: var(--text-color-primary);
  display: inline-flex;
  align-items: flex-end;
  transition: background-color var(--hover-duration);
  &:hover {
    background-color: var(--main-menu-hover-color);
    transition: background-color var(--hover-duration);
  }
`

export const MenuButton = styled.button`
  padding: var(--space-3);
  width: 100%;
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: var(--fs-3);
  display: inline-grid;
  grid-template-columns: 22px 1fr;
  align-items: center;
  border: none;
  background-color: transparent;
  border-radius: var(--bdr-2);
  &:hover {
    background-color: ${(props) => (props.isWarning ? 'var(--menu-warning-bg)' : 'var(--main-menu-hover-color)')};
  }
`

export const MenuLabel = styled.span`
  text-align: left;
`
