import { useState, useContext, useEffect } from 'react'
import { fetchData } from '../../../utils/helper/Helper'
import { AppContext } from '../../../utils/context/AppContext'
import { FormWrapper, Form, Label, FieldWrapper } from '../../../utils/elements/FormElements'
import { FIELD_LABELS, FORM_ERRORS, PASSWORD_MIN_LENGTH } from '../../../utils/constants/constants'
import { ButtonPrimary, ErrorMessage } from '../../../utils/elements/miscElements'
import { useLocation } from 'react-router-dom'
import { getUrlParam } from '../../../utils/helper/urlParamsHelper'
import { CenteredFlexDiv } from '../../../components/modal/modalStyles'
import { PasswordVisibilityToggle } from '../../../components/passwordVisibilityToggle/PasswordVisibilityToggle'
import useTranslate from '../../../utils/hooks/useTranslate'
import CapslockIndicator from '../../../components/capslockIndicator/CapslockIndicator'
import { PAGES } from '../../../utils/constants/pages'
import useRedirect from '../../../utils/hooks/useRedirect'

const SetNewPassword = () => {
  const t = useTranslate()
  const location = useLocation()
  const context = useContext(AppContext)
  const redirect = useRedirect()

  const [password, setPassword] = useState('')
  const [passwordNotValid, setPasswordNotValid] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [capslockOn, setCapslockOn] = useState(false)

  const validateNewPassword = () => {
    if (password.length > 0 && password.length < PASSWORD_MIN_LENGTH) {
      setPasswordNotValid(true)
      setFormValid(false)
    } else {
      setPasswordNotValid(false)
    }
  }

  useEffect(() => {
    setFormValid(password.length >= PASSWORD_MIN_LENGTH)
  }, [password])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const param = getUrlParam(location)

    const payload = {
      recovery_key: param.recovery_key,
      new_password: password
    }

    const data = await fetchData(payload, 'password_recovery', context, null, false)

    try {
      const message = data.response.status === 1 ? 'passwordSuccessfullyChanged' : 'passwordRecoveryFailed'
      context.setLoginMessage(message)
      redirect(PAGES.login)
    } catch (e) {
      console.error(e)
    }
  }

  const handleKeyPress = (e) => {
    setCapslockOn(e.getModifierState && e.getModifierState('CapsLock'))
    if (password.length >= PASSWORD_MIN_LENGTH) {
      setPasswordNotValid(false)
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      if (formValid) {
        handleSubmit(e)
      }
    }
  }

  return (
    <FormWrapper>
      <h1>{t('setNewPassword')}</h1>
      <Form onSubmit={handleSubmit}>
        <FieldWrapper>
          <Label htmlFor="newPassword" fullWidth>
            {t(FIELD_LABELS.newPassword)}
          </Label>
          <div style={{ display: 'grid' }}>
            <CenteredFlexDiv style={{ position: 'relative' }}>
              <input
                id="newPassword"
                style={{ width: '100%' }}
                type={password.length > 0 && !passwordVisible ? 'password' : 'text'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={validateNewPassword}
                className={passwordNotValid ? 'has-error' : ''}
                onKeyDown={handleKeyPress}
                onKeyUp={handleKeyPress}
              />
              <CapslockIndicator {...{ capslockOn }} />
              <PasswordVisibilityToggle {...{ passwordVisible, setPasswordVisible }} />
            </CenteredFlexDiv>
            <ErrorMessage visible={passwordNotValid}>{t(FORM_ERRORS.newPasswordTooShort)}</ErrorMessage>
          </div>
          <span className="text-secondary">{t('minChars', PASSWORD_MIN_LENGTH)}</span>
        </FieldWrapper>

        <ButtonPrimary type="submit" disabled={!formValid} position="login" content="changePassword" />
      </Form>
    </FormWrapper>
  )
}

export default SetNewPassword
