import React, { useContext } from 'react'
import styled from 'styled-components'
import { downloadReport } from '../../pages/pts/ptUtils'
import { ENTITIES, MODAL_TYPES } from '../constants/constants'
import { AppContext } from '../context/AppContext'
import { copyToClipboard, getEntityLabel } from '../helper/Helper'
import useTranslate from '../hooks/useTranslate'
import { getCustomTableCheckboxBgColor, getCustomTableCheckboxBorderColor } from '../../components/table/tableElements'
import { downloadInvoice } from '../../pages/invoices/invoiceUtils'

const BREAKPOINT_BILLING_FORM = '1000px'

export const WizardForm = styled.form`
  display: grid;
  grid-gap: var(--space-5);
`
export const Label = styled.div.attrs({
  className: 'form-label'
})`
  grid-column: ${(props) => (props.fullWidth ? '1/3' : 'unset')};
  gap: ${(props) => (props.fullWidth ? 0 : 'var(--space-1)')};
  font-size: var(--fs-3);
  color: var(--text-color-secondary);
  display: inline-grid;
  line-height: var(--lh-2);
  span {
    line-height: var(--lh-2);
  }
  @media screen and (max-width: ${(props) => (props.isBillingForm ? BREAKPOINT_BILLING_FORM : '768px')}) {
    grid-column: unset;
  }
`

export const CloseButton = styled.button`
  width: 100px;
  padding: var(--space-2);
`

export const InfoList = styled.ul`
  display: grid;
  gap: ${(props) => (props.type === 'billing' ? 0 : 'var(--space-3)')};
  padding: 0;
  margin: 0;
  * {
    line-height: var(--lh-2);
  }
`

export const InfoListElement = styled.li`
  display: grid;
  font-weight: ${(props) => (props.bold ? 'var(--fw-3)' : '')};
  span:last-child {
    line-height: ${(props) => (props.large ? 'var(--lh-4)' : '')};
    font-size: ${(props) => (props.large ? 'var(--fs-5)' : '')};
  }
`

export const InfoElement = ({ label, value, highlightValue }) => {
  const t = useTranslate()
  return (
    <InfoListElement>
      <span className="text-secondary">{t(label)}</span>
      <span style={{ fontWeight: highlightValue ? 'var(--fw-3)' : '' }}>{t(value)}</span>
    </InfoListElement>
  )
}

export const InfoElementBillPreview = ({ label, value, bold, large }) => {
  const t = useTranslate()
  return (
    <InfoListElement {...{ bold, large }} style={{ gridTemplateColumns: '1fr auto', alignItems: 'baseline' }}>
      <span>{t(label)}</span>
      <span style={{ textAlign: 'right' }}>{value}</span>
    </InfoListElement>
  )
}

export const PageHeadline = styled.h2`
  margin-top: 0;
  margin-bottom: var(--inset);
  border-bottom: none;
  box-shadow: none;
  padding-bottom: 0;
  font-size: var(--fs-8);
  @media screen and (max-width: 768px) {
    margin-bottom: var(--space-4);
    padding-bottom: var(--space-3);
  }
  @media screen and (max-width: 600px) {
    font-size: var(--fs-7);
  }
`

export const SubHeadline = styled.p`
  font-size: var(--fs-4);
  margin-top: 0;
  margin-bottom: var(--space-6);
`

const ButtonPrimaryStyle = styled.button`
  min-width: ${(props) => (props.position === 'login' ? '50%' : '100px')};
  font-size: ${(props) => (props.position === 'login' ? 'var(--fs-4)' : 'var(--fs-3)')};
  padding: var(--space-3) var(--space-4);
  font-weight: var(--fw-2);
  background-color: ${(props) => (props.warning ? 'var(--warning-bg-color)' : 'var(--primary-bg-color)')};
  border-radius: ${(props) => (props.position === 'login' ? 'var(--bdr-7)' : 'var(--bdr-2)')};
  margin: ${(props) => (props.position === 'login' ? 'var(--space-5) auto' : '')};
  color: #fff;
  min-height: ${(props) => (props.modalButton ? '36px' : '40px')};
  &:hover {
    background-color: ${(props) => (props.warning ? 'var(--warning-bg-hover-color)' : 'var(--primary-bg-hover-color)')};
  }
  &:disabled {
    background-color: var(--button-disabled-bg-color);
  }
`

const ButtonSecondaryStyle = styled.button`
  min-width: 100px;
  padding: var(--space-3) var(--space-4);
  font-size: var(--fs-3);
  font-weight: var(--fw-2);
  background-color: ${(props) => (props.darker ? 'var(--button-secondary-bg-darker)' : 'var(--button-secondary-bg)')};
  border-radius: var(--bdr-2);
  min-height: ${(props) => (props.modalButton ? '36px' : '40px')};
  flex-grow: 1;
  &:hover {
    background-color: ${(props) =>
      props.darker ? 'var(--button-secondary-hover-darker)' : 'var(--button-secondary-hover)'};
  }
`

const ButtonTertiaryStyle = styled.button`
  min-width: 100px;
  padding: var(--space-3) var(--space-4);
  font-size: var(--fs-3);
  font-weight: var(--fw-1);
  background-color: transparent;
  border-radius: var(--bdr-2);
  border: 1px solid var(--border-grey);
  min-height: ${(props) => (props.modalButton ? '36px' : '40px')};
  flex-grow: 1;
  &:hover {
    background-color: var(--button-tertiary-hover);
  }
`

export const ButtonPrimary = React.forwardRef((props, ref) => {
  const t = useTranslate()
  return (
    <ButtonPrimaryStyle ref={ref} style={props.style} onClick={props.onClick} {...props}>
      {t(props.content)}
    </ButtonPrimaryStyle>
  )
})

export const ButtonSecondary = React.forwardRef((props, ref) => {
  const t = useTranslate()
  return (
    <ButtonSecondaryStyle ref={ref} style={props.style} onClick={props.onClick} {...props}>
      {t(props.content)}
    </ButtonSecondaryStyle>
  )
})

export const ButtonTertiary = React.forwardRef((props, ref) => {
  const t = useTranslate()
  return (
    <ButtonTertiaryStyle ref={ref} style={props.style} onClick={props.onClick} {...props}>
      {t(props.content)}
    </ButtonTertiaryStyle>
  )
})

export const Modalfooter = styled.div`
  display: flex;
  margin-top: ${(props) => (props.type === 'settings' ? 'var(--space-5)' : 'auto')};
  margin-bottom: ${(props) => (props.type === 'settings' ? 'var(--space-7)' : 0)};
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-3);
  position: relative;
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space-3);
  flex-grow: ${(props) =>
    [MODAL_TYPES.alert, MODAL_TYPES.notification, MODAL_TYPES.payment, MODAL_TYPES.deleteAccount].includes(props.type)
      ? 1
      : 0};
  > div > div {
    // overwrite paypal button styles
    min-width: unset !important;
    display: block !important;
  }
`

export const OptionEntry = styled.div.attrs({
  className: 'option-entry'
})`
  display: grid;
  grid-gap: var(--space-1) var(--space-7);
  grid-template-columns: ${(props) => (props.hasHint ? '1fr 1fr' : '1fr')};
  align-items: flex-start;
  @media screen and (max-width: ${(props) => (props.isBillingForm ? BREAKPOINT_BILLING_FORM : '768px')}) {
    grid-template-columns: 1fr;
  }
`

export const FormSection = styled.section`
  display: grid;
  gap: var(--space-5);
`

export const SectionTitle = ({ title, isOptional }) => {
  const t = useTranslate()
  return (
    <SectionTitleH3>
      {t(title)} {isOptional && <OptionalTagSpan>(optional)</OptionalTagSpan>}
    </SectionTitleH3>
  )
}

export const SectionTitleH3 = styled.h3`
  margin: 0 !important;
  font-size: var(--fs-5);
`

const OptionalTagSpan = styled.span`
  font-weight: var(--fw-2);
  color: var(--text-color-secondary);
`

export const OptionEntryWide = styled.div.attrs({
  className: 'option-entry'
})`
  display: grid;
  grid-gap: var(--space-2) var(--space-7);
  grid-template-columns: ${(props) => (props.hasHint ? '2fr 1fr' : '1fr')};
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const OptionDescription = ({ description, style, showInModal, isBillingForm, helpHeadline, helpContent }) => {
  const t = useTranslate()
  return (
    <OptionDescriptionContainer {...{ isBillingForm, style, showInModal }}>
      <InfoIcon className="svg-icon icon-info" {...{ showInModal, isBillingForm }} />
      <OptionDescriptionContent showInModal={showInModal}>
        {t(description)} {helpHeadline && <MoreInfosButton {...{ helpHeadline, helpContent }} />}
      </OptionDescriptionContent>
    </OptionDescriptionContainer>
  )
}

const MoreInfosButton = ({ helpHeadline, helpContent }) => {
  const context = useContext(AppContext)
  const t = useTranslate()

  return (
    <button
      className="textlink light"
      onClick={(e) => {
        e.preventDefault()
        context.setModalProps({
          headline: helpHeadline,
          content: helpContent,
          showCloseButton: true,
          hideFooter: true,
          type: MODAL_TYPES.help
        })
        context.setModalOpen(true)
      }}>
      {t('moreInfos')}
    </button>
  )
}

const OptionDescriptionContainer = styled.div.attrs({
  className: 'option-description-container'
})`
  font-size: ${(props) => (props.showInModal ? 'var(--fs-2)' : 'var(--fs-3)')};
  line-height: var(--lh-3);
  display: grid;
  grid-template-columns: ${(props) => (props.showInModal ? '1fr' : '30px 1fr')};
  margin-top: ${(props) => (props.showInModal ? 'var(--space-2)' : '')};
  span {
    line-height: ${(props) => (props.showInModal ? 'var(--lh-2)' : 'var(--lh-3)')};
  }

  @media screen and (max-width: ${(props) => (props.isBillingForm ? BREAKPOINT_BILLING_FORM : '768px')}) {
    display: ${(props) => (props.hideInMobile ? 'none' : 'grid')};
    grid-template-columns: 1fr;
    font-size: var(--fs-2);
    line-height: var(--lh-2);
    margin-top: var(--space-2) !important;
    span {
      line-height: var(--lh-2);
    }
  }
`

const OptionDescriptionContent = styled.div.attrs({
  className: 'option-description-content'
})`
  transform: translateY(-4px);
  line-height: ${(props) => (props.showInModal ? 'var(--lh-2)' : 'var(--lh-3)')};
  color: var(--text-color-secondary);
  @media screen and (max-width: 768px) {
    line-height: var(--lh-2);
  }
`

export const InfoIcon = styled.span`
  background-color: var(--info-icon-color);
  width: 18px;
  height: 18px;
  display: ${(props) => (props.showInModal ? 'none' : 'block')};
  @media screen and (max-width: ${(props) => (props.isBillingForm ? BREAKPOINT_BILLING_FORM : '768px')}) {
    display: none;
  }
`

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    transitionDuration: '0s'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '13px',
    transform: state.isDisabled ? 'translate(-10px, -50%)' : 'translateY(-50%)'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%',
    paddingLeft: 'var(--space-3)',
    backgroundColor: 'transparent'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'var(--text-color-primary)',
    transform: state.isDisabled ? 'translate(-10px, -50%)' : 'translateY(-50%)',
    lineHeight: 'var(--lh-2)'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
    marginTop: 0,
    backgroundColor: 'var(--input-bg-color)',
    borderTopWidth: '0',
    boxShadow: 'var(--sh-dropdown)'
  }),
  menuList: (provided) => ({
    ...provided,
    position: 'absolute',
    left: 0,
    right: 0,
    backgroundColor: 'var(--filter-menu-color)',
    borderRadius: 'var(--bdr-2)',
    boxShadow: 'var(--sh-dropdown)',
    marginTop: 'var(--space-2)'
  }),
  control: (provided, state) => ({
    ...provided,
    height: state.selectProps.isSignUp ? '40px' : state.selectProps.height || '36px',
    minHeight: '32px',
    backgroundColor: getControlBackgroundColor(state),
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all 0s',
    borderRadius: 'var(--bdr-2)',
    border: getBorder(state),
    boxShadow: 'none',
    '&:hover': {
      border: state.selectProps.hasError ? '1px solid var(--input-error-border-color)' : '1px solid var(--border-grey)'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 'var(--space-3) var(--space-4)',
    fontSize: '13px',
    backgroundColor: state.isFocused ? 'var(--react-select-option-hover-color)' : 'transparent',
    color: state.isDisabled ? 'var(--text-color-secondary)' : 'var(--text-color-primary)',
    cursor: 'pointer',
    fontWeight: state.isSelected ? '600' : 'normal',
    '&:hover': {
      backgroundColor: 'var(--react-select-option-hover-color)'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: state.hasValue && state.selectProps.isClearable ? 'block' : 'none',
    backgroundColor: 'var(--small-action-icon-color)'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
    backgroundColor: 'transparent',
    display: state.isDisabled ? 'none' : 'flex'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 8px 8px 0',
    color: 'var(--small-action-icon-color)',
    '&:hover': {
      color: 'var(--small-action-icon-hover-color)'
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: 'var(--small-action-icon-color)',
    '&:hover': {
      color: 'var(--small-action-icon-hover-color)'
    },
    backgroundColor: 'var(--input-bg-color)'
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  input: (provided) => ({
    ...provided,
    height: 'auto',
    color: 'var(--text-color-primary)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: 'var(--text-color-secondary)',
    textAlign: 'left'
  })
}

const getControlBackgroundColor = (state) => {
  if (state.selectProps.hasError) return 'var(--input-error-bg-color)'
  if (state.selectProps.isSignUp) return 'var(--login-input-bg-color)'
  return 'var(--input-bg-color)'
}

const getBorder = (state) => {
  if (state.isDisabled) return 'none'
  if (state.selectProps.hasError) return '1px solid var(--input-error-border-color)'
  if (state.isFocused) return '1px solid var(--color-focus-outline) !important'
  return '1px solid var(--border-grey)'
}

export const ErrorMessage = styled.span`
  font-size: var(--fs-2);
  color: var(--error-message-color);
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const Hint = styled.span`
  font-size: 10px;
  line-height: 16px;
  color: var(--text-color-secondary);
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const HoverConnector = styled.div`
  pointer-events: none;
  @media (hover: hover) {
    &:hover .checkbox-element {
      cursor: ${(props) => (props.disabled ? '' : 'pointer')};
      background-color: ${(props) => (props.disabled ? '' : 'var(--box-background-hover)')};
      transition: background-color var(--hover-duration);
    }
    &:hover .custom-checkmark {
      cursor: ${(props) => (props.disabled ? '' : 'pointer')};
      box-shadow: ${(props) => (props.disabled ? '' : 'var(--custom-checkbox-shadow)')};
      transition: box-shadow var(--hover-duration);
    }
  }
`

export const CustomCheckmark = styled.div.attrs((props) => ({
  className: props.checked
    ? `custom-checkmark svg-icon icon-${props.type === 'radio' ? 'circle' : 'check-noborder'} large`
    : 'custom-checkmark'
}))`
  visibility: ${(props) => (props.isAlwaysChecked || props.isDisabled ? 'hidden' : 'initial')};
  border-radius: ${(props) => (props.type === 'radio' ? 'var(--bdr-round)' : 'var(--bdr-2)')};
  background-color: ${(props) => getCustomTableCheckboxBgColor(props)};
  border-width: ${(props) => (props.type === 'radio' ? '1.5px' : '1px')};
  border-style: solid;
  border-color: ${(props) => getCustomTableCheckboxBorderColor(props)};
  width: 32px;
  height: 32px;
  justify-self: center;
  transition: box-shadow var(--hover-duration);
  pointer-events: auto;
  &:hover {
    cursor: ${(props) => (props.disabled ? '' : 'pointer')};
    box-shadow: ${(props) => (props.disabled ? '' : 'var(--custom-checkbox-shadow)')};
    transition: box-shadow var(--hover-duration);
  }
`

export const CheckboxElement = styled.button.attrs({
  className: 'checkbox-element'
})`
  pointer-events: auto;
  display: grid;
  user-select: none;
  justify-items: left;
  padding: var(--space-5);
  background-color: var(--box-background);
  border-radius: var(--bdr-2);
  transition: background-color var(--hover-duration);
  flex-grow: 1;
  border: ${(props) => (props.checked ? `1px solid var(--active-checkbox-border-color)` : '1px solid transparent')};
  &:hover {
    cursor: ${(props) => (props.disabled ? '' : 'pointer')};
    background-color: ${(props) => (props.disabled ? '' : 'var(--box-background-hover)')};
    transition: background-color var(--hover-duration);
  }
`

export const TestVariantCheckboxElement = styled(CheckboxElement)`
  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 80px;
    align-items: baseline;
    gap: var(--space-3);
    padding: var(--space-3);
    h4 {
      margin-bottom: 0;
      font-size: var(--fs-4);
    }
    .card-price-container {
      margin: 0;
    }
    .credit-amount-highlight {
      font-size: var(--fs-6);
    }
  }
`

export const Emphasized = styled.span`
  font-weight: 600;
`

export const GreyButton = styled.button`
  font-size: var(--fs-3);
  height: 32px;
  display: inline-flex;
  align-items: center;
  background-color: var(--grey-button-bg-color);
  padding: var(--space-3) var(--space-4);
  border-radius: var(--bdr-2);
  border: 1px solid var(--grey-button-border-color);
  position: relative;
  color: var(--text-color-primary);
  transition: background-color var(--toggle-button-duration), border-color var(--toggle-button-duration),
    color var(--toggle-button-duration);
  .svg-icon {
    background-color: var(--icon-on-buton-color);
    transition: background-color var(--toggle-button-duration), border-color var(--toggle-button-duration);
  }
  &:hover {
    background-color: var(--grey-button-hover-color);
    transition: background-color var(--toggle-button-duration), border-color var(--toggle-button-duration),
      color var(--toggle-button-duration);
  }
  &.active {
    background-color: var(--grey-button-active-color);
    border-color: var(--grey-button-active-color);
    transition: background-color var(--toggle-button-duration), border-color var(--toggle-button-duration),
      color var(--toggle-button-duration);
    color: #fff;
    .svg-icon {
      background-color: #fff;
    }
  }
`

export const LightButton = styled(GreyButton)`
  border: none;
  background-color: transparent;
  padding: var(--space-3);
`

export const ReportModulePreviewButton = styled.div`
  font-size: var(--fs-2);
  height: 24px;
  margin-left: auto;
  border-radius: var(--bdr-2);
  padding: var(--space-2) var(--space-3);
  &:hover {
    background-color: var(--preview-button-hover-bg-color);
  }
  &.active {
    background-color: var(--preview-button-active-bg-color);
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const TertiaryButton = styled.button`
  background-color: transparent;
  padding: 0 4px;
  margin-left: -4px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  border-radius: var(--bdr-2);
  &:hover {
    background-color: ${(props) =>
      props.isWarning ? 'var(--tertiary-button-warning-hover-color)' : 'var(--tertiary-button-hover-color)'};
  }
`

export const BulletList = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  gap: var(--space-3);
`

export const TabHintContainer = styled.p.attrs({
  className: 'text-secondary'
})`
  margin-bottom: var(--space-6);
`

export const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--box-background);
  padding: var(--space-7);
  border-radius: var(--bdr-2);
  max-width: 500px;
  margin: var(--space-6) auto;
  span {
    line-height: var(--lh-4);
  }
`

export const SuccessMessageHeadline = styled.h2`
  margin-bottom: var(--space-6);
  text-align: center;
  line-height: var(--lh-5);
`

export const SuccessMessageText = styled.span`
  font-size: var(--fs-6);
  text-align: center;
`

export const NewBalanceLabel = styled.span`
  margin-bottom: var(--space-6);
  color: var(--text-color-secondary);
  font-size: var(--fs-4);
  font-weight: var(--fw-2);
`

export const SuccessMessageIcon = styled.span`
  width: 80px;
  height: 80px;
  margin-top: var(--space-7);
  background-color: var(--success-message-icon-color);
  border-radius: var(--bdr-round);
`

export const SuccessMessageBalance = ({ balance }) => {
  const formattedBalance = balance.toLocaleString()

  return (
    <span>
      <span style={{ fontSize: 'var(--fs-9)', fontWeight: 'var(--fw-2)' }}>{formattedBalance}</span>
      <span style={{ fontSize: 'var(--fs-2)', marginLeft: 'var(--space-2)' }} className="uppercase-label">
        {getEntityLabel(ENTITIES.credits, balance)}
      </span>
    </span>
  )
}

export const Notice = styled.div`
  font-size: var(--fs-2);
  font-weight: 500;
  margin-top: var(--space-5);
`

export const TileHeadlineContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: flex-end;
`

export const DownloadPdfButton = ({ resultNr }) => (
  <GreyButton onClick={() => downloadReport({ type: 'default', key: resultNr })}>
    <span className="svg-icon icon-pdf" style={{ marginRight: 'var(--space-3)' }} />
    <span>PDF Report</span>
  </GreyButton>
)

export const DownloadPdfButtonLight = ({ resultNr }) => (
  <LightButton onClick={() => downloadReport({ type: 'default', key: resultNr })}>
    <span
      className="svg-icon icon-pdf"
      style={{ marginRight: 'var(--space-3)', backgroundColor: 'var(--dashboard-pdf-icon-color)' }}
    />
    <span>PDF</span>
  </LightButton>
)

export const DownloadInvoiceButtonLight = ({ invoiceNo, showLabel = true }) => {
  const { statusBarController } = useContext(AppContext)
  return (
    <LightButton style={{ gap: 'var(--space-3)' }} onClick={() => downloadInvoice(invoiceNo, statusBarController)}>
      <span className="svg-icon icon-pdf" style={{ backgroundColor: 'var(--dashboard-pdf-icon-color)' }} />
      {showLabel && <span>PDF</span>}
    </LightButton>
  )
}

const CopyButton = styled.button`
  background-color: transparent;
  padding: var(--space-2);
  border-radius: var(--bdr-2);
  display: flex;
  &:hover {
    background-color: var(--row-action-button-hover-color);
  }
`

export const CopyIcon = ({ content, elementLabel, position }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  return (
    <CopyButton
      data-tip={t('copyToClipboard')}
      data-for="copy-icon"
      onClick={() => copyToClipboard(content, t(elementLabel), context, t)}>
      <span className={`svg-icon icon-link copy-icon ${position}`} />
    </CopyButton>
  )
}

export const SmallNotice = styled.span.attrs({ className: 'text-secondary' })`
  margin-top: var(--space-5);
  display: inline-block;
`

export const CardHeadline = styled.h4`
  font-size: var(--fs-5);
  line-height: var(--lh-3);
  margin-bottom: var(--space-3);
  font-weight: var(--fw-2);
  text-align: left;
`

export const CardDescription = styled.div`
  text-align: left;
  font-size: var(--fs-2);
  line-height: var(--lh-2);
`

export const CreditAmountHighlight = styled.span`
  font-size: var(--fs-7);
  margin-right: var(--space-2);
`

export const CardPriceContainer = styled.div`
  margin-top: var(--space-4);
  font-size: larger;
  width: 100%;
  display: flex;
  align-items: baseline;
`

export const LargeGreyText = styled.span`
  color: var(--grey-400);
  font-weight: var(--fw-2);
  font-size: var(--fs-7);
  line-height: var(--lh-4);
  text-align: center;
`
