import React, { useEffect } from 'react'
import { checkDemoStatus, getEntityLabel, setState } from '../../../utils/helper/Helper'
import {
  CustomCheckmark,
  CheckboxElement,
  CardHeadline,
  CardDescription,
  CreditAmountHighlight,
  CardPriceContainer,
  HoverConnector,
  ReportModulePreviewButton
} from '../../../utils/elements/miscElements'
import { ENTITIES } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'

const ReportModuleSelection = ({ reportModulePreview, setReportModulePreview, data, setData, availableModules }) => {
  const t = useTranslate()
  useEffect(() => {
    const mandatoryModule = availableModules.find((module) => module.isOptional === false)

    if (mandatoryModule) {
      const tmpModules = data.reportModulesArray
      if (!data.reportModulesArray.includes(mandatoryModule.key)) {
        tmpModules.push(mandatoryModule.key)
        setState(setData, 'reportModulesArray', tmpModules)
      }
    }
  }, [availableModules, data.reportModulesArray, setData])

  const toggleSelectionState = (module) => {
    if (module.isDisabled && !isDemo) return
    const selectedModules = getSelectedModules(data.reportModulesArray, module.key)
    setState(setData, 'reportModulesArray', selectedModules)
  }

  const isDemo = checkDemoStatus(data.parentConfig, data.configUuid)

  const styles = {
    outerContainer: { display: 'grid', gridGap: 'var(--space-5)' },
    innerContainer: {
      display: 'grid',
      gridGap: 'var(--space-3)',
      gridTemplateColumns: '1fr 40px',
      alignItems: 'center'
    }
  }

  return (
    <div style={styles.outerContainer}>
      {availableModules.map((module, i) => {
        const isAlwaysChecked = module.isOptional === false
        const isChecked = data.reportModulesArray.includes(module.key) || isAlwaysChecked

        return (
          <React.Fragment key={module.key}>
            {i === 0 && <h3 style={{ margin: 0 }}>{t('basicModule')}</h3>}
            {i === 1 && <h3 style={{ margin: 'var(--space-4) 0 0 0' }}>{t('optionalModules')}</h3>}
            <HoverConnector>
              <div style={styles.innerContainer} key={'container' + i}>
                <CheckboxElement key={'preview' + i} onClick={() => toggleSelectionState(module)} checked={isChecked}>
                  <CardHeadline>{t(module.headline)}</CardHeadline>
                  <Description {...{ module, i }} />
                  <PriceAndPreview {...{ module, isDemo, i, reportModulePreview, setReportModulePreview }} />
                </CheckboxElement>
                <CustomCheckmark
                  type="checkbox"
                  style={{ backgroundColor: module.isDisabled && !isDemo ? '#ff000033' : '' }}
                  key={'CustomCheckmark' + i}
                  onClick={() => toggleSelectionState(module)}
                  checked={isChecked}
                  isAlwaysChecked={isAlwaysChecked}
                  isDisabled={module.isDisabled && !isDemo}
                />
              </div>
            </HoverConnector>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default ReportModuleSelection

const Description = ({ module, i }) => {
  const t = useTranslate()
  return <CardDescription key={'description' + i}>{t(module.description)}</CardDescription>
}

const PriceAndPreview = ({ module, isDemo, i, reportModulePreview, setReportModulePreview }) => {
  const t = useTranslate()
  const handlePreview = (e, i) => {
    e.stopPropagation()
    setReportModulePreview(i)
  }

  const handleKeyDown = (e, i) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setReportModulePreview(i)
    }
  }

  return (
    <CardPriceContainer key={'price' + i}>
      <PriceTag {...{ module, isDemo }} />
      <ReportModulePreviewButton
        tabIndex="0"
        className={reportModulePreview === i && 'active'}
        onClick={(e) => handlePreview(e, i)}
        onKeyDown={(e) => handleKeyDown(e, i)}>
        {t('preview')}
      </ReportModulePreviewButton>
    </CardPriceContainer>
  )
}

const getSelectedModules = (modulesArray, key) => {
  if (modulesArray.includes(key)) {
    return modulesArray.filter((module) => module !== key)
  }
  return modulesArray.concat(key)
}

const PriceTag = ({ module, isDemo }) => {
  const t = useTranslate()
  return (
    <>
      {module.price === 0 && <span>{t('inclusive')}</span>}

      {module.price > 0 && (
        <>
          {isDemo ? (
            <span style={{ textAlign: 'left', fontSize: 'var(--fs-4)' }}>{t('freeInDemo')}</span>
          ) : (
            <>
              <CreditAmountHighlight>{module.price}</CreditAmountHighlight>{' '}
              <span className="uppercase-label">{getEntityLabel(ENTITIES.credits, module.price)}</span>
            </>
          )}
        </>
      )}
    </>
  )
}
