import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import ToggleButton from '../../../components/toggleButton/ToggleButton'
import { MAX_CHARS } from '../../../utils/constants/constants'
import { getOptionByValue, setState } from '../../../utils/helper/Helper'
import {
  OptionEntry,
  PageHeadline,
  WizardForm,
  Label,
  ErrorMessage,
  OptionDescription,
  selectStyles
} from '../../../utils/elements/miscElements'
import { useState } from 'react'
import { validateProcessNameInput } from '../addProcessUtils'
import useTranslate from '../../../utils/hooks/useTranslate'
import Select from 'react-select'

const ProcessNameAndDesign = ({ data, setData, designOptions, setSubmitButtonDisabled }) => {
  const t = useTranslate()
  const optionDescriptions = getOptionDescriptions(t)

  const handleNameChange = (e, setErrorMsg, setShowErrors, setSubmitButtonDisabled) => {
    const input = e.target.value
    validateProcessNameInput(input, setErrorMsg, setSubmitButtonDisabled, t)
    setShowErrors(true)
    setState(setData, 'processName', e.target.value)
  }

  return (
    <>
      <PageHeadline>{t('nameAndDesign')}</PageHeadline>
      <ScrollContainer>
        <WizardForm>
          <OptionEntry hasHint>
            <Label htmlFor="processName" fullWidth>
              {t('newProcessName')} *
            </Label>
            <ProcessNameInput {...{ handleNameChange, data, setSubmitButtonDisabled }} />
            <OptionDescription description={optionDescriptions.processName} />
          </OptionEntry>

          <OptionEntry hasHint>
            <Label htmlFor="processDesign" fullWidth>
              {t('chooseTestDesign')}
            </Label>
            {designOptions.length > 2 ? (
              <Select
                options={designOptions}
                onChange={(selectedOption) => setState(setData, 'elektryonTemplate', selectedOption.value)}
                styles={selectStyles}
                value={getOptionByValue(designOptions, data.elektryonTemplate)}
                menuPortalTarget={document.body}
                noOptionsMessage={() => t('noResults')}
              />
            ) : (
              <ToggleButton options={designOptions} data={data} setData={setData} dataKey="elektryonTemplate" />
            )}
            <OptionDescription description={optionDescriptions.processDesign} />
          </OptionEntry>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default ProcessNameAndDesign

const ProcessNameInput = ({ handleNameChange, data, setSubmitButtonDisabled }) => {
  const [errorMsg, setErrorMsg] = useState('')
  const [showErrors, setShowErrors] = useState(false)

  return (
    <div style={{ display: 'grid' }}>
      <input
        autoFocus
        id="processName"
        type="text"
        onChange={(e) => handleNameChange(e, setErrorMsg, setShowErrors, setSubmitButtonDisabled)}
        value={data.processName}
        className={showErrors && errorMsg ? 'has-error' : ''}
      />
      <ErrorMessage visible={showErrors && errorMsg}>{errorMsg}</ErrorMessage>
    </div>
  )
}

const getOptionDescriptions = (t) => ({
  processName: (
    <span>
      {t('processExplanationWithExample')} ({t('maxChars', MAX_CHARS.processName)})
    </span>
  ),

  processDesign: <span>{t('customDesignOption')}</span>
})
