import { useContext } from 'react'
import DataTable from '../../components/table/DataTable'
import { createDate, getFilteredDateArray } from '../../utils/helper/dateTimeHelper'
import useTranslate from '../../utils/hooks/useTranslate'
import { AppContext } from '../../utils/context/AppContext'

const FinishedPtsOverview = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const pts = context.completeDataSet.pts
  const finishedPtData = getFinishedPtData(pts)
  const finishedPtCols = getCols(t)
  return (
    <DataTable
      data={finishedPtData}
      columns={finishedPtCols}
      tableName="finishedPts"
      hideArchiveButton
      hideResultCount
      hideHeader
    />
  )
}

export default FinishedPtsOverview

const getFinishedPtData = (pts) => {
  const allFinishedDates = pts.map((pt) => pt.datePtFinished)
  const allDates = allFinishedDates.map((date) => createDate(date))
  const filteredPtFinishedDates = getFilteredDateArray(allDates)
  return [
    {
      label: 'today',
      content: filteredPtFinishedDates.today.length
    },
    {
      label: 'thisWeek',
      content: filteredPtFinishedDates.thisWeek.length
    },
    {
      label: 'thisMonth',
      content: filteredPtFinishedDates.thisMonth.length
    },
    {
      label: 'thisYear',
      content: filteredPtFinishedDates.thisYear.length
    },
    {
      label: 'overall',
      content: pts.filter((pt) => pt.datePtFinished).length
    }
  ]
}

const getCols = (t) => [
  {
    Header: '',
    accessor: 'label',
    Cell: ({ value }) => <div style={{ textTransform: 'capitalize' }}>{t(value)}</div>
  },
  {
    Header: '',
    accessor: 'content',
    Cell: ({ value }) => <div>{value.toLocaleString()}</div>
  }
]
