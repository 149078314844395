import {
  DEFAULT_NORM_NR,
  DEFAULT_NORM_NR_TRAITS,
  NEW_NORM_NR,
  STATUS_BAR_TYPES
} from '../../../utils/constants/constants'
import { setStatusBar } from '../../../utils/helper/Helper'
import { createDate } from '../../../utils/helper/dateTimeHelper'
import useTranslate from '../../../utils/hooks/useTranslate'
import DateDisplay from '../../dateDisplay/DateDisplay'

const getDefaultNorms = (t) => {
  return [
    {
      name: t('standardTemplate'),
      normNr: DEFAULT_NORM_NR,
      disabled: true,
      id: DEFAULT_NORM_NR,
      createdBy: 'PERSEO',
      normTable: [
        {
          lower_limit: 0,
          upper_limit: 30,
          grade: '5',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann nicht empfohlen werden.'
        },
        {
          lower_limit: 30,
          upper_limit: 40,
          grade: '4',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann mit großen Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 40,
          upper_limit: 50,
          grade: '3',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann mit Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 50,
          upper_limit: 60,
          grade: '2',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann empfohlen werden.'
        },
        {
          lower_limit: 60,
          upper_limit: 100,
          grade: '1',
          verbalisation: 'Eine weitere Teilnahme am Verfahren kann bedenkenlos empfohlen werden.'
        }
      ]
    },
    {
      name: t('standardTemplateTraits'),
      normNr: DEFAULT_NORM_NR_TRAITS,
      disabled: true,
      id: DEFAULT_NORM_NR_TRAITS,
      createdBy: 'PERSEO',
      normTable: [
        {
          lower_limit: 0,
          upper_limit: 30,
          grade: '5',
          verbalisation:
            'Sehr schlechte Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann nicht empfohlen werden.'
        },
        {
          lower_limit: 30,
          upper_limit: 50,
          grade: '4',
          verbalisation:
            'Schlechte Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann mit großen Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 50,
          upper_limit: 70,
          grade: '3',
          verbalisation:
            'Mittlere Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann mit Einschränkungen empfohlen werden.'
        },
        {
          lower_limit: 70,
          upper_limit: 90,
          grade: '2',
          verbalisation:
            'Gute Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann empfohlen werden.'
        },
        {
          lower_limit: 90,
          upper_limit: 100,
          grade: '1',
          verbalisation:
            'Sehr gute Passung zwischen Persönlichkeitsprofil und Stellenanforderungen. Eine weitere Teilnahme am Verfahren kann bedenkenlos empfohlen werden.'
        }
      ]
    }
  ]
}

export const getAllNorms = (customNorms, t) => {
  const defaultNorms = getDefaultNorms(t)
  return [...defaultNorms, ...customNorms].map((n) => ({
    ...n,
    isDirty: false
  }))
}

const NormDetails = ({ dateCreated, createdBy }) => {
  const t = useTranslate()
  const date = dateCreated instanceof Date ? dateCreated : createDate(dateCreated)

  return (
    <span>
      {t('created')}
      {dateCreated && (
        <>
          {' '}
          {t('onDate')} <DateDisplay date={date} />
        </>
      )}
      <>
        {' '}
        {t('by')} {createdBy}
      </>
    </span>
  )
}

export const getNormOptionsWithDetails = (allNorms, t) => {
  return allNorms.map((norm) => ({
    label: (
      <>
        <div style={{ display: 'grid' }}>
          <span>
            {norm.name}{' '}
            {norm.isDirty ? <span style={{ color: 'var(--text-color-secondary)' }}> ({t('changed')})</span> : ''}
          </span>
          <span style={{ color: 'var(--text-color-secondary)', fontSize: 'var(--fs-1)', transform: 'translateY(1px)' }}>
            <NormDetails dateCreated={norm.created} createdBy={norm.createdBy} />
          </span>
        </div>
      </>
    ),
    value: norm.normNr || norm.dimLogicNr
  }))
}

export const getNormOptions = (allNorms) => {
  return allNorms.map((norm) => ({
    label: (
      <>
        {norm.name} {norm.isDirty ? <span style={{ color: 'var(--text-color-secondary)' }}> (geändert)</span> : ''}
      </>
    ),
    value: norm.normNr
  }))
}

export const getCorrectNorm = (assessment, normNr, customNorms) => {
  const customNorm = customNorms.find((cn) => cn.normNr === normNr)
  const defaultNorm = assessment.relatedConfig.norm

  return customNorm || defaultNorm
}

export const showLoadingIndicator = (context) => {
  setStatusBar({
    controller: context.statusBarController,
    type: STATUS_BAR_TYPES.loading,
    text: 'processingInProgress',
    setVisible: true
  })
}

export const isNewNorm = (normNr) => normNr === NEW_NORM_NR

export const NAME_MAX_CHARS = 45

export const shakeElement = (element) => {
  element.classList.add('shake')
  setTimeout(() => {
    element.classList.remove('shake')
  }, 200)
}
