import { MainEntity } from './MainEntity'

export class Invoice extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'Invoice'
    this.billingMail = props.billingMail
    this.billingMailCc = props.billingMailCc
    this.dateBilled = props.dateBilled
    this.discountRate = props.discountRate
    this.gross = props.gross
    this.invoiceNo = props.invoiceNo
    this.net = props.net
    this.netDays = props.netDays
    this.paymentMethod = props.paymentMethod
    this.status = props.status
    this.vatSum = props.vatSum
  }
}
