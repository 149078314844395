import dict from '../../../utils/constants/translations'
import CameraHelp from '../../helpModal/CameraHelp'
import ChildItemsHelp from '../../helpModal/ChildItemsHelp'
import TabTrackingHelp from '../../helpModal/TabTrackingHelp'
import { Entry, TimeLineContainer } from '../../timeline/timeLineElements'
import { B, Image, Table, WizardStep, WizardStepContainer } from '../userManualElements'

const Chapter2English = () => (
  <>
    <h2 id="chapter-2">2 Basic Functions</h2>
    <h3 id="chapter-2-1">2.1 Working with Tables</h3>
    <h4 id="chapter-2-1-1">2.1.1 Filtering and Sorting Tables</h4>
    <h5>Filtering</h5>
    <p>
      Filters help you keep track of large amounts of tests, participants, and results. To filter the data in a table,
      you can either use the free text filter or create filter queries.
    </p>
    <p> To add a new filter query, please follow these steps:</p>
    <ul>
      <li>
        Click on <B>+ Filter</B> to display the filter options.
      </li>

      <li>
        Select the <B>Field</B> you wish to filter by from the dropdown menu. The field refers to the specific data
        category, such as <em>Participant Name</em> or <em>Result</em>.
      </li>
      <li>
        After selecting the field, determine the <B>Relationship</B> that should be applied. The relationship defines
        how the value you enter is compared to the data. Examples of relationships are <em>equals</em>,{' '}
        <em>contains</em>, and <em>is later than</em>.
      </li>
      <li>
        In the final step, enter the <B>Value</B> to be used for the filter. The value depends on the selected field and
        relationship. For example, if you have chosen the field <em>Created</em> with the relationship{' '}
        <em>is later than</em>, you can enter a date to display all tests that were created after this date.
      </li>
      <li>After selecting the field, relationship, and value, confirm the filter by clicking on the checkmark.</li>
    </ul>
    <p>
      You can apply multiple filters at the same time to further refine your search. To remove an applied filter, click
      on the X icon next to the filter name.
    </p>

    <Image src="user_manual_images/filter-bar.png" caption="Fig. 4: Filter Bar" />
    <h5>Sorting</h5>
    <p>
      To sort a table in ascending order based on a column, click once on the respective column header. Click the column
      header again to sort the table in descending order.
    </p>
    <h4 id="chapter-2-1-2">2.1.2 Quick Navigation</h4>
    <p>
      To quickly see the items you want to edit, many table entries have a link function. For example, if you are on the{' '}
      <em>Processes</em> page and want to edit all participants in this process, you can click on the number in the
      Participants column. This will open the <em>Participants</em> page with the <em>Process</em> filter preselected.
      Table entries with link functions are highlighted with color and underlining when you move the cursor over them.
    </p>
    <h4 id="chapter-2-1-3">2.1.3 Detail View</h4>
    <p>
      Many tables have a detail view. To show or hide the detail view of a row, click on the row. The information in the
      detail view is organized in tabs. To switch between tabs, click on the respective tab.
    </p>
    <h4 id="chapter-2-1-4">2.1.4 Action Menu</h4>
    <p>
      In the main tables <em>Processes</em>, <em>Tests</em>, <em>Participants</em>, and <em>Testings</em>, you will find
      the <em>Actions</em> column on the right-hand side of the table. It contains a button with three dots <B>...</B>,
      which opens the action menu for a row. The actions available there are specific to the respective row. The
      available actions are also found in the detail view under <em>Settings</em> {'>'} <em>Actions</em>.
    </p>
    <h4 id="chapter-2-1-5">2.1.5 Archive, Delete, and Restore</h4>
    <p>
      You can archive, unarchive, or permanently delete processes, tests, participants, and testings. All three actions
      can be performed via the action menu or batch processing.
    </p>
    <p>
      To display archived items, click on <B>Archive</B> in the table actions. Archived items are hidden by default and
      are only loaded and displayed when accessed. Linked items in the archive (e.g., a test in the archive that is
      associated with a visible procedure) are shown in gray.
    </p>
    <p>You can unarchive items at any time. Deleted items cannot be restored.</p>
    <p>To ensure data integrity, linked items are also archived, deleted, and restored.</p>
    <h4 id="chapter-2-1-6">2.1.6 Customize Table Columns</h4>
    <p>
      To customize the table columns, click on <B>Table Columns</B> in the table actions. A dialog window will open
      where you can change the visibility and order of the columns. Then click <B>Save</B> to confirm your selection, or{' '}
      <B>Cancel</B> to discard your changes.
    </p>
    <Image src="user_manual_images/column-settings.png" caption="Fig. 5: Customize Table Columns" width="300" />
    <h4 id="chapter-2-1-7">2.1.7 Batch Processing</h4>
    <p>
      In the main tables <em>Processes</em>, <em>Tests</em>, <em>Participants</em>, and <em>Testings</em>, you can edit
      multiple items at once. To do this, select the desired rows by clicking on the checkboxes on the left-hand side of
      the table. Once a row is selected, the batch processing options appear on the right side of the screen.
    </p>
    <p>
      Depending on the table, user permissions, and selected items, various actions and settings are available here.
    </p>
    <div className="textbox important">
      <p>
        Note that only actions and settings that are applicable to all selected items will be displayed. For example,
        test times cannot be edited for completed testings. Therefore, if you have both completed and incomplete
        testings selected, the test time settings will not be available.
      </p>
    </div>
    <p>
      To change a setting, click on <B>Change...</B>. An input field will open where you can adjust the value. Then
      click on <B>Apply</B>. A dialog window will open where you need to confirm the changes.
    </p>
    <Image src="user_manual_images/confirm-changes.png" caption="Fig. 6: Confirm Changes" width="300" />

    <h3 id="chapter-2-2">2.2 Inviting Participants to Tests</h3>
    <p>
      In order for your applicants to participate in a test, they need access data. You can either export the access
      data from the customer area and send it yourself (e.g., via Outlook or an applicant management system), or you can
      send emails with the access data directly from the customer area.
    </p>
    <h4 id="chapter-2-2-1">2.2.1 Exporting and Manually Sending Access Data</h4>
    <h5>Invitation Link and Login Code</h5>
    <p>
      Your participants can either log in with a login code at{' '}
      <a className="textlink" rel="noreferrer" target="_blank" href="app.perseo-assessment.de">
        app.perseo-assessment.de
      </a>{' '}
      (Fig. 7) or use an invitation link. You only need to send one of the two pieces of information to your
      participants.
    </p>
    <p>
      The login code is useful if you want to conduct a test on-site. In this case, you only need to provide your
      participants with the code (e.g., on a piece of paper) and you don&apos;t have to copy the link to each device.
    </p>
    <Image src="user_manual_images/participant-login.png" caption="Fig. 7: Participant Login Page" />

    <h5>Using the Test Overview</h5>
    <p>
      If participants enter their participant number (e.g., P-12345-67890) as the login code, they will be directed to
      the <em>Test Overview</em> page (Fig. 8). From there, they can start all the tests assigned to them and visible to
      them in any order. Here they also get more information about the tests, such as duration and scope. You can
      recognize the invitation link to the test overview by the presence of the characters <em>?hub=</em>.
    </p>

    <Image src="user_manual_images/test-overview.png" caption="Fig. 8: Test Overview" />
    <div className="textbox important" style={{ marginTop: 'var(--space-5)' }}>
      <p>
        Make sure that only tests with the option <em>Show in Test Overview</em> enabled are displayed in the test
        overview for selection.
      </p>
    </div>
    <h5>Inviting to a Single Test</h5>
    <p>
      If you want participants to be directed to the test immediately after logging in, they must use the TAN (e.g.,
      PAS-ABCDE-12345) as the login code. You can recognize the invitation link for a single test by the presence of the
      characters <em>?token=</em>.
    </p>
    <p>
      This option is useful when there are multiple tests planned, and you want to send the invitations separately. For
      example, you could invite all participants to a performance test initially and wait for the results. Then you
      could invite the best participants to a personality test.
    </p>
    <h5>Finding and Exporting Access Data</h5>
    <p>You can find the access data in various places:</p>
    <ul>
      <li>
        Processes {'>'} Actions {'>'} Download CSV table
      </li>
      <li>
        Tests {'>'} Actions {'>'} Download CSV table
      </li>
      <li>
        Participants {'>'} Detail view {'>'} Access data
      </li>
      <li>
        Participants {'>'} Select participants {'>'} Batch processing {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Export as CSV</span>
      </li>
      <li>
        Testings {'>'} Detail view {'>'} Access data
      </li>
      <li>
        Testings {'>'} Select testings {'>'} Batch processing {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Export as CSV</span>
      </li>
    </ul>
    <h4 id="chapter-2-2-2">2.2.2 Sending Invitations from the Customer Area</h4>
    <p>
      Perform one of the following steps to open the <em>Send Emails</em> process:
    </p>
    <ul>
      <li>
        Select a participant with an email address, open the action menu, and click on <B>Send Email</B>.
      </li>
      <li>
        Select at least one participant with an email address and click on <B>Send Email</B> in the batch processing on
        the right side.
      </li>
      <li>
        Select a testing of a participant with an email address, open the action menu, and click on <B>Send Email</B>.
      </li>
      <li>
        Select at least one testing of a participant with an email address and click on <B>Send Email</B> in the batch
        processing.
      </li>
    </ul>
    <TimeLineContainer className="wizard">
      <Entry>
        <WizardStepContainer>
          <WizardStep>1. Email Templates</WizardStep>
          <ul>
            <li>Select an existing email template from the list.</li>
            <li>You can customize the subject, salutation, and two free-text fields.</li>
            <li>The login credentials will be automatically inserted between the two free-text fields.</li>
            <li>
              Save your changes as a new template by clicking on <B>Save...</B> at the top.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>2. Preview</WizardStep>
          <ul>
            <li>This page displays a preview of your email.</li>
            <li>
              You can modify the preview data under <em>Data for Preview</em>.
            </li>
            <li>
              If you want to send a sample email, enter an email address in the corresponding field in the sidebar and
              click <B>Send Now</B>.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>3. Settings</WizardStep>
          <ul>
            <li>Choose whether it is an invitation or reminder email.</li>
            <li>
              If a timer is set, you can choose whether the timer should start automatically after email delivery.
            </li>
            <li>
              Optionally, you can enter a name and an email address for the <em>Reply To</em> field. When the recipient
              clicks <B>Reply</B>, the reply will be sent to the address specified here.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
      <Entry>
        <WizardStepContainer>
          <WizardStep>4. Review Inputs</WizardStep>
          <ul>
            <li>Review your inputs.</li>
            <li>
              Click <B>Send...</B>.
            </li>
            <li>
              Confirm the send by clicking <B>Send</B>.
            </li>
          </ul>
        </WizardStepContainer>
      </Entry>
    </TimeLineContainer>

    <h3 id="chapter-2-3">2.3 Retrieving and Exporting Results</h3>
    <h4 id="chapter-2-3-1">2.3.1 Results as PDF Report</h4>
    <p>
      An automatic PDF report is generated for each completed test. You can open and download this report from various
      locations.
    </p>
    <h5>Opening a Single Report in the Browser</h5>
    <ul>
      <li>
        Dashboard {'>'} Latest Test Results {'>'} PDF Report
      </li>
      <li>
        Participants {'>'} Detail view {'>'} Results {'>'} PDF Report
      </li>
      <li>
        Participants {'>'} Actions {'>'} Open Report
      </li>
      <li>
        Testings {'>'} Detail view {'>'} Results {'>'} PDF Report
      </li>
      <li>
        Testings {'>'} Actions {'>'} Open Report
      </li>
    </ul>
    <h5>Downloading Multiple Reports as a Zip Folder</h5>
    <ul>
      <li>
        Tests {'>'} Actions {'>'} Download Reports
      </li>
      <li>
        Participants {'>'} Select participants {'>'} Batch processing {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Download Reports</span>
      </li>
      <li>
        Testings {'>'} Select testings {'>'} Batch processing {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Download Reports</span>
      </li>
    </ul>
    <h4 id="chapter-2-3-2">2.3.2 Results as CSV Table</h4>
    <p>
      You can download the test results in CSV format (comma-separated values) and open them in software like MS Excel
      or Open Office Calc. The semicolon is used as the delimiter.
    </p>
    <ul>
      <li>
        Processes {'>'} Actions {'>'} Download CSV table
      </li>
      <li>
        Tests {'>'} Actions {'>'} Download CSV table
      </li>
      <li>
        Participants {'>'} Detail view {'>'} Results {'>'} CSV Raw Data
      </li>
      <li>
        Testings {'>'} Detail view {'>'} Results {'>'} CSV Raw Data
      </li>
      <li>
        Testings {'>'} Select testings {'>'} Batch processing {'>'}{' '}
        <span style={{ whiteSpace: 'nowrap' }}>Export as CSV</span>
      </li>
    </ul>
    <h3 id="chapter-2-4">2.4 Setting Test Times</h3>
    <h4 id="chapter-2-4-1">2.4.1 Overview</h4>
    <p>You have various options for setting test times.</p>
    <p>
      The following fields can be set at the test level and the testings level. Values at the test level serve as
      default values for all associated testings. However, you can override the values at the testings level with more
      specific values (e.g., to grant an individual participant an extension).
    </p>
    <TimeLimitsTable />
    <h4 id="chapter-2-4-2">2.4.2 Starting and Resetting Timers</h4>
    <p>
      When the <em>Timer</em> field is set, you have access to the <em>Start Timer</em> and <em>Reset Timer</em>{' '}
      functions. You can find these functions here:
    </p>
    <ul>
      <li>Participants {'>'} Action menu</li>
      <li>Participants {'>'} Batch processing</li>
      <li>Testings {'>'} Action menu </li>
      <li>Testings {'>'} Batch processing</li>
    </ul>
    <p>
      When you start/reset the timer at the participant level, the timers for all associated testings will be
      started/reset.
    </p>
    <h3 id="chapter-2-5">2.5 {dict.testSecurity.en}</h3>
    <h4 id="chapter-2-5-1">2.5.1 {dict.useChildItems.en}</h4>
    <ChildItemsHelp />
    <h4 id="chapter-2-5-2">2.5.2 {dict.tabTracking.en}</h4>
    <TabTrackingHelp />
    <h4 id="chapter-2-5-3">2.5.3 {dict.cameraSupervision.en}</h4>
    <CameraHelp />
  </>
)

export default Chapter2English

const TimeLimitsTable = () => {
  const header = ['Field', 'Description', 'Default Value']
  const data = [
    [
      dict.validFrom.en,
      'Date and time from when the test can be conducted. If a date is selected without a specific time, the default is 00:00.',
      'immediate'
    ],
    [
      dict.validUntil.en,
      'Date and time until when the test can be taken. If a date is selected without a specific time, the default is 23:59.',
      'unlimited'
    ],
    [
      dict.invitedDuration.en,
      <span key="TimeLimitsTable-timer">
        Using the Timer function, you can individually limit the test duration per participant. To do this, set the
        Timer to the desired duration (1 to 30 days). When you click <B>Start Timer</B>, the <em>Available until</em>{' '}
        field will be set to the current time plus the specified duration. The time period always extends until 23:59.
      </span>,
      'not set'
    ],
    [
      dict.startedDuration.en,
      <span key="TimeLimitsTable-startedDuration">
        With the <em>Time from Test Start</em> function, you can limit the time available to a participant for the
        entire test. Once the test is started, the <em>Available until</em> field will be set to the current time plus
        the specified duration. If you use this function, participants will be notified separately about the time limit
        before starting the test.
      </span>,
      'not set'
    ]
  ]
  return <Table name="time-limits" {...{ header, data }} />
}
