import ReactTooltip from 'react-tooltip'
import useTranslate from '../../utils/hooks/useTranslate'

const CameraAnomaliesLabel = () => {
  const t = useTranslate()
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span>{t('yes')}</span>
      <span
        data-tip=""
        data-for={'cameraAnomalies'}
        className={`svg-icon icon-exclamation`}
        style={{
          backgroundColor: 'var(--error-message-color)',
          marginLeft: 'var(--space-2)',
          cursor: 'initial'
        }}
      />
      <ReactTooltip
        id="cameraAnomalies"
        aria-haspopup="true"
        effect="solid"
        place="bottom"
        backgroundColor="var(--c-tooltip-bg)"
        textColor="var(--tooltip-text-color)"
        getContent={() => t('cameraAnomalieExplanation')}
      />
    </div>
  )
}

export default CameraAnomaliesLabel
