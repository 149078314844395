import { PayPalButtons } from '@paypal/react-paypal-js'
import { useContext } from 'react'
import {
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_PAYPAL,
  PAYPAL_SUCCESS_STATUS,
  STATUS_BAR_TYPES
} from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import { setStatusBar } from '../../../utils/helper/Helper'
import { addCredits } from '../shopUtils'
import { approvePayPalOrder, createPayPalOrder } from '../paypalUtils'
import StripeButton from '../stripe/StripeButton'

const BuyButton = ({ buyButtonState, activePageState, orderData }) => {
  const context = useContext(AppContext)
  const { buyButtonDisabled, setBuyButtonDisabled } = buyButtonState
  const { activePageId, setActivePageId } = activePageState

  const checkout = async () => {
    setBuyButtonDisabled(true)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.loading,
      text: 'creditChargeInProgress',
      setVisible: true
    })

    const reponseData = await addCredits(orderData, context)
    const SUCCESS_STATUS = 1
    if (reponseData?.response?.status === SUCCESS_STATUS) {
      setActivePageId(activePageId + 1)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: 'creditsSuccessfullyCharged'
      })
    } else {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: 'unableToChargeCredits'
      })
    }
  }

  switch (orderData.paymentMethod) {
    case PAYMENT_METHOD_CREDIT_CARD:
      return <StripeButton {...{ orderData, checkout, buyButtonState }} />
    case PAYMENT_METHOD_PAYPAL:
      return (
        <PayPalButtons
          fundingSource="paypal"
          createOrder={() => createPayPalOrder(orderData, context)}
          style={{ height: 40 }}
          onApprove={async (data) => {
            const status = await approvePayPalOrder(data, context)
            if (status === PAYPAL_SUCCESS_STATUS) {
              checkout()
            } else {
              setStatusBar({
                controller: context.statusBarController,
                type: STATUS_BAR_TYPES.error,
                text: 'unableToChargeCredits',
                setVisible: true
              })
            }
          }}
        />
      )
    default:
      return <ButtonPrimary disabled={buyButtonDisabled} content="buy" onClick={checkout} />
  }
}

export default BuyButton
