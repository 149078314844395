import DeleteAccountWarning from './DeleteAccountWarning'
import { API_DATA, MODAL_TYPES, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import { fetchData, getCreditBalance, getFormattedNumber, setStatusBar } from '../../../utils/helper/Helper'
import sessionHandler from '../../../utils/helper/sessionHandler'

export const getStats = (context) => {
  const creditBookings = context.completeDataSet.creditBookings
  const creditBalance = getCreditBalance(creditBookings)
  return {
    numberTests: context.completeDataSet.assessments.length,
    numberParticipants: context.completeDataSet.participants.length,
    numberPts: context.completeDataSet.pts.length,
    numberContacts: context.completeDataSet.contacts.length,
    numberCredits: getFormattedNumber(creditBalance, context.language)
  }
}

export const updateModalProps = (context, confirmationText, setConfirmationText, canDelete) => {
  context.setModalProps({
    headline: 'deleteCompanyAccount',
    showCloseButton: true,
    content: <DeleteAccountWarning {...{ confirmationText, setConfirmationText }} />,
    buttonPrimary: (
      <ButtonPrimary
        warning
        modalButton
        disabled={!canDelete}
        content="delete"
        onClick={() => {
          deleteAccount(context)
          context.setModalOpen(false)
        }}
      />
    ),
    type: MODAL_TYPES.deleteAccount
  })
}

export const deleteAccount = (context) => {
  const SUCCESS_STATUS = 1
  const endPoint = API_DATA.customers.endPointDelete

  fetchData({}, endPoint, context).then((responseData) => {
    const success = responseData?.response?.status === SUCCESS_STATUS
    if (success) {
      sessionHandler.logout(context)
    } else {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: 'accountDeletionFailed'
      })
    }
  })
}
