import { getGlobalStatusByKey, getOptionByValue, isPotentials } from '../../utils/helper/Helper'
import {
  API_DATA,
  NOT_SET_TEXT,
  ERROR_MSG,
  FIELD_LABELS,
  SELECT_OPTIONS,
  GLOBAL_STATUS_KEYS
} from '../../utils/constants/constants'
import CustomInput from '../customInput/CustomInput'
import { SmallNotice } from '../../utils/elements/miscElements'
import { secondsToDHM } from '../../utils/helper/dateTimeHelper'
import CapabilitiesHelper from '../../utils/helper/CapabilitiesHelper'
import { CustomInputList } from '../table/tableElements'
import useTranslate from '../../utils/hooks/useTranslate'
import StartedDurationHelp from '../helpModal/StartedDurationHelp'
import TimerHelp from '../helpModal/TimerHelp'

const PtSettings = ({ pt }) => {
  const t = useTranslate()
  const disabledNotice = getDisabledNotice(pt)
  const invitationStatus = getGlobalStatusByKey(pt.globalStatus, GLOBAL_STATUS_KEYS.invitationStatus)
  const invitationStatusValue = invitationStatus.statusValue || 0

  return (
    <CustomInputList>
      {isPotentials(pt.relatedConfig.configType) && (
        <CustomInput
          label={FIELD_LABELS.durationMultiplier}
          content={getOptionByValue(SELECT_OPTIONS.durationMultiplier, pt.durationMultiplier).label || ERROR_MSG}
          value={pt.durationMultiplier}
          dataKey="durationMultiplier"
          type="select"
          options={SELECT_OPTIONS.durationMultiplier}
          idValue={pt.ptNumber}
          apiData={API_DATA.pts}
          disabled={!CapabilitiesHelper.canBulkEditPtDurationMultiplier([pt])}
        />
      )}

      <CustomInput
        label={FIELD_LABELS.invitedDuration}
        content={pt.ptInvitedDuration ? t('dhmString', ...secondsToDHM(pt.ptInvitedDuration)) : t(NOT_SET_TEXT)}
        dataKey="invitedDuration"
        type="time-picker"
        value={pt.ptInvitedDuration}
        idValue={pt.ptNumber}
        apiData={API_DATA.pts}
        disabled={!CapabilitiesHelper.canBulkEditPtTimer([pt])}
        helpContent={<TimerHelp />}
      />

      <CustomInput
        label={FIELD_LABELS.startedDuration}
        content={pt.ptStartedDuration ? t('dhmString', ...secondsToDHM(pt.ptStartedDuration)) : t(NOT_SET_TEXT)}
        dataKey="startedDuration"
        type="time-picker"
        value={pt.ptStartedDuration}
        idValue={pt.ptNumber}
        apiData={API_DATA.pts}
        disabled={!CapabilitiesHelper.canBulkEditPtStartedDuration([pt])}
        minDuration={pt.relatedConfig.configDuration}
        helpContent={<StartedDurationHelp />}
      />

      <CustomInput
        label={FIELD_LABELS.invitationStatus}
        content={getOptionByValue(SELECT_OPTIONS.invitationStatus, invitationStatusValue).label}
        dataKey={GLOBAL_STATUS_KEYS.invitationStatus}
        type="select"
        value={invitationStatusValue}
        options={SELECT_OPTIONS.invitationStatus}
        idValue={pt.ptNumber}
        apiData={API_DATA.pts}
        disabled={!CapabilitiesHelper.canBulkEditPtInvitedStatus([pt])}
        isGlobalStatus
      />

      {disabledNotice && <SmallNotice>{t(disabledNotice)}</SmallNotice>}
    </CustomInputList>
  )
}

export default PtSettings

const getDisabledNotice = (pt) => {
  if (pt.isArchived()) return 'testInArchiveCannotBeEdited'
  if (!pt.relatedProcess.isEditor()) return 'noEditorRightsForTest'
  if (pt.datePtFinished) return 'settingsCannotBeChangedAfterCompletion'
  if (pt.isDeactivated()) return 'settingsCannotBeChangedAfterDeactivation'
  if (pt.datePtStarted) return 'settingsCannotBeChangedAfterStart'
}
