import styled from 'styled-components'

const PenButton = ({ onClick }) => (
  <Button className="pen-button" onClick={onClick}>
    <PenIcon className="svg-icon icon-pen custom-input" />
  </Button>
)

export default PenButton

const Button = styled.button`
  display: none;
  background-color: transparent;
  padding: 0;
  justify-self: end;
  align-self: end;
`

const PenIcon = styled.span`
  transform: scale(0.9) translateY(2px);
`
