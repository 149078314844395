import { TertiaryButton } from '../../utils/elements/miscElements'
import useTranslate from '../../utils/hooks/useTranslate'
import { BulkActionButtonContent, BulkActionIcon } from '../bulkEdit/BulkEditStyles'

const RowActionButtonList = ({ entries }) => {
  const t = useTranslate()
  return (
    <div style={{ display: 'grid', gap: 'var(--space-2)' }}>
      {entries.flat().map((button) => (
        <TertiaryButton
          className="menu-button"
          isWarning={button.isWarning}
          key={button.label}
          onClick={button.clickHandler}>
          <BulkActionButtonContent>
            <BulkActionIcon style={button.iconStyle} className={`svg-icon ${button.icon}`} />
            <span style={{ color: button.color }}>
              {t(button.label)}
              {button.hasEllipsis ? '…' : ''}
            </span>
          </BulkActionButtonContent>
        </TertiaryButton>
      ))}
    </div>
  )
}

export default RowActionButtonList
