import { useContext } from 'react'
import { DEFAULT_LANGUAGE } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import BarDiagram from '../charts/BarDiagram'
import { Tile, TileHeadline } from '../table/tableElements'
import styled from 'styled-components'

const PtResultPreviewTile = ({ pt, barChartWidth }) => {
  const context = useContext(AppContext)
  return (
    <Tile width={barChartWidth || '500px'}>
      <TileHeadline content="individualResults" />
      <BarDiagramContainer>
        <BarDiagram
          data={getResultData(pt.relatedResult, pt.relatedConfig.dimensions, context.language)}
          layout="horizontal"
          enableGridX
          enableGridY={false}
          maxValue={100}
          margin={{ top: 0, right: 2, bottom: 30, left: 180 }}
          useDefaultColors={!pt.relatedAssessment.hasMatching()}
          keys={getKeys(pt.relatedResult)}
          isResultPreview
        />
      </BarDiagramContainer>
    </Tile>
  )
}

export default PtResultPreviewTile

const BarDiagramContainer = styled.div`
  height: 250px;
  svg {
    overflow: visible;
  }
`

const getResultData = (result, dimensions, language) => {
  return result?.crosstestResult
    ? prepareCrosstestData(result, language)
    : prepareNormalData(result, dimensions, language)
}

const getKeys = (result) => (result?.crosstestResult ? ['Vortest', 'Nachtest'] : ['value'])

const prepareCrosstestData = (result, language) => {
  const subtests = result?.crosstestResult?.subtests || []
  return subtests.map((subtest) => ({
    group: subtest.title_translated[language] || subtest.title,
    Vortest: subtest.subtest_ref.score,
    Nachtest: subtest.subtest_cross.score
  }))
}

const prepareNormalData = (result, dimensions, language) => {
  const arr = []
  const visibleDimensions = result.dimensionScores.filter((dim) => dim.visibility === 1)

  visibleDimensions.map((dim) => {
    const matchingNorm = dimensions.find((d) => d.dimensionNr === parseInt(dim.dimensionNr))?.dimensionMatchingNorm
    const val = dim.score !== 'n/a' ? dim.score : 0
    return arr.push({
      group: dim.alias[language] || dim.alias[DEFAULT_LANGUAGE] || dim.alias,
      value: val,
      matchingPoints: matchingNorm
        ? matchingNorm.find((norm) => val >= norm.lower_limit && val < norm.upper_limit).value
        : null
    })
  })
  return arr.reverse()
}
