import { FixedSizeList as List } from 'react-window'
import useTranslate from '../../utils/hooks/useTranslate'

const MenuList = (props) => {
  const t = useTranslate()
  const { options, children, maxHeight, getValue } = props
  const [value] = getValue()
  const OPTION_ROW_HEIGHT = 32
  const initialOffset = options.indexOf(value) * OPTION_ROW_HEIGHT

  if (!Array.isArray(children)) {
    return <div className="no-options-message">{t('noResults')}</div>
  }

  return (
    <List
      style={{ height: 'max-content', maxHeight: maxHeight }}
      height={maxHeight}
      itemCount={children.length}
      itemSize={OPTION_ROW_HEIGHT}
      initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}
export default MenuList
