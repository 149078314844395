import React, { useContext, useEffect, useState } from 'react'
import ReportModuleSelection from './ReportModuleSelection'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { PageHeadline } from '../../../utils/elements/miscElements'
import styled from 'styled-components'
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AppContext } from '../../../utils/context/AppContext'
import { DEFAULT_LANGUAGE, REPORT_MODULES } from '../../../utils/constants/constants'
import { isGoals, isPotentials, isPotentialsCrossTest, isTraits } from '../../../utils/helper/Helper'

const ReportConfigForm = ({ data, setData }) => {
  const t = useTranslate()
  const [reportModulePreview, setReportModulePreview] = useState(0)
  const availableModules = getAvailableReportModules(data.configType)
  const context = useContext(AppContext)

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  return (
    <>
      <PageHeadline>{t('selectReportModules')}</PageHeadline>
      <Container>
        <ScrollContainer>
          <ReportModuleSelection
            reportModulePreview={reportModulePreview}
            setReportModulePreview={setReportModulePreview}
            data={data}
            setData={setData}
            availableModules={availableModules}
          />
        </ScrollContainer>
        <ScrollContainer className="scroll-container hide-on-mobile">
          <h3 style={{ marginTop: 0, marginBottom: 'var(--space-5)' }}>{t('preview')}</h3>
          {availableModules.map((module, i) => {
            const previewFile = module.previewFile[context.language] || module.previewFile[DEFAULT_LANGUAGE]
            return (
              <div key={module + i} className={reportModulePreview === i ? 'is-visible' : 'is-invisible'}>
                <Document file={previewFile} loading="">
                  {Array.from(new Array(module.previewPageCount), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </div>
            )
          })}
        </ScrollContainer>
      </Container>
    </>
  )
}

export default ReportConfigForm

const Container = styled.div`
  display: grid;
  gap: var(--space-7);
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
  @media screen and (max-width: 600px) {
    grid-template-columns: unset;
    gap: 0;
  }
`

const getAvailableReportModules = (configType) => {
  const availableModules = []
  if (isPotentials(configType)) {
    availableModules.push('potentialsProfile', 'potentialsStatistics', 'potentialsFeedback')
  }
  if (isPotentialsCrossTest(configType)) {
    availableModules.push('potentialsCrossTest', 'potentialsStatistics', 'potentialsFeedback')
  }
  if (isTraits(configType)) {
    availableModules.push(
      'traitsFeedback',
      'traitsInterview',
      'traitsMatching',
      'traitsPotentialsRisks',
      'traitsProfile',
      'traitsVerbalisation'
    )
  }
  if (isGoals(configType)) {
    availableModules.push('goalsMatching', 'goalsProfile', 'goalsVerbalisation', 'goalsRadar')
  }

  const moduleData = REPORT_MODULES.filter((module) => availableModules.includes(module.key))

  return moduleData
}
