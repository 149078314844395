import { ResponsivePie } from '@nivo/pie'
import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'
import { pieChartColors } from './chartUtils'

const PieDiagram = ({ data, valueFormat, showTotal, innerRadius, content }) => {
  const t = useTranslate()

  let noDataToDisplay = false
  if (data.length === 0) {
    data = [{ id: null, label: '', value: true }]
    noDataToDisplay = true
  }

  const dataForDisplay = data.map((date) => ({
    id: date.label,
    label: date.label,
    value: date.value
  }))

  const colors = getPieSliceColors(data.length)
  const colorsReduced = colors.slice(0, data.length)

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    dataWithArc.forEach((datum) => {
      total += datum.value
    })

    if (noDataToDisplay) {
      total = 0
    }

    return (
      <>
        <text
          x={centerX}
          y={centerY - 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: 'var(--fs-8)',
            fontWeight: 500,
            fill: 'var(--text-color-primary)'
          }}>
          {total}
        </text>

        <text
          x={centerX}
          y={centerY + 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: 'var(--fs-3)',
            fontWeight: 600,
            fill: 'var(--text-color-secondary)'
          }}>
          {t('pts')}
        </text>
      </>
    )
  }

  const theme = {
    tooltip: {
      container: {
        background: 'var(--pie-chart-tooltip-color)'
      }
    }
  }

  return (
    <ResponsivePie
      theme={theme}
      data={dataForDisplay}
      margin={getMargin(content)}
      enableArcLabels={!noDataToDisplay}
      enableArcLinkLabels={false}
      valueFormat={valueFormat}
      innerRadius={innerRadius}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={5}
      borderWidth={0}
      sortByValue={false}
      colorBy="index"
      colors={colorsReduced}
      arcLabelsTextColor="#fff"
      arcLabelsSkipAngle={10}
      isInteractive={data.length && !noDataToDisplay}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', showTotal ? CenteredMetric : 'null']}
    />
  )
}

export default PieDiagram

export const Legend = styled.ul`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  padding: 0;
  width: ${(props) => (props.row ? '100%' : '250px')};
  justify-content: ${(props) => (props.row ? 'center' : 'flex-start')};
  margin: 0;
  margin-top: var(--space-3);
  gap: ${(props) => getLegendGap(props)};
  flex-wrap: wrap;
`

const getLegendGap = (props) => {
  if (props.row) return '12px'
  if (props.showModules) return 'var(--space-4)'
  return 'var(--space-2)'
}

export const DataLabel = styled.li`
  list-style: none;
  align-items: center;
  display: ${(props) => (props.row ? 'inline' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
`
export const Circle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
  transform: translateY(-1px);
  display: inline-flex;
`

const getMargin = (content) => {
  switch (content) {
    case 'modules':
      return { top: 10, right: 10, bottom: 20, left: 10 }
    case 'participationStats':
    default:
      return { top: 10, right: 40, bottom: 20, left: 40 }
  }
}

const getPieSliceColors = (numberEntries) => {
  if (numberEntries <= 2) {
    return pieChartColors.colors2
  }
  if (numberEntries <= 4) {
    return pieChartColors.colors4
  }
  if (numberEntries <= 6) {
    return pieChartColors.colors6
  }
  if (numberEntries <= 8) {
    return pieChartColors.colors8
  }
  return pieChartColors.colors12
}
