import Select from 'react-select'
import { useState, useEffect } from 'react'
import { getEntityLabel, getOptionByValue, setState } from '../../utils/helper/Helper'
import { ErrorMessage, selectStyles } from '../../utils/elements/miscElements'
import { getSecondsFromDHM, secondsToDHM } from '../../utils/helper/dateTimeHelper'
import useTranslate from '../../utils/hooks/useTranslate'

export const TimePicker = ({
  seconds = 0,
  setData,
  controls = null,
  flexDirection = 'row',
  field,
  useMenuPortal,
  minDuration,
  setIsValid
}) => {
  const t = useTranslate()
  const [d, h, m] = secondsToDHM(seconds)
  const [days, setDays] = useState(d)
  const [minutes, setMinutes] = useState(m)
  const [hours, setHours] = useState(h)
  const [errorMsg, setErrorMsg] = useState('')

  const fields = getFields(field)
  const dayOptions = getDayOptions(t)
  const hourOptions = getHourOptions(t)
  const minuteOptions = getMinuteOptions(t)

  useEffect(() => {
    const selectedDuration = getSecondsFromDHM(days, hours, minutes)
    validateDuration(selectedDuration, minDuration, setErrorMsg, setIsValid, t)
    if (controls) {
      setState(setData, controls, selectedDuration)
    } else {
      setData(selectedDuration)
    }
  }, [controls, setData, days, hours, minutes, minDuration, setIsValid])

  return (
    <div>
      <div style={{ display: 'flex', gap: 'var(--space-2)', flexDirection: flexDirection }}>
        {fields.useDays && (
          <Select
            options={dayOptions}
            placeholder={t('days')}
            styles={selectStyles}
            isSearchable={false}
            value={getOptionByValue(dayOptions, days)}
            onChange={(selectedOption) => setDays(selectedOption.value)}
            maxMenuHeight={100}
            menuPortalTarget={useMenuPortal ? document.body : ''}
          />
        )}

        {fields.useHours && (
          <Select
            options={hourOptions}
            placeholder={t('hours')}
            styles={selectStyles}
            isSearchable={false}
            value={getOptionByValue(hourOptions, hours)}
            onChange={(selectedOption) => setHours(selectedOption.value)}
            maxMenuHeight={100}
            menuPortalTarget={useMenuPortal ? document.body : ''}
            hasError={errorMsg}
          />
        )}

        {fields.useMinutes && (
          <Select
            options={minuteOptions}
            placeholder={t('minutes')}
            styles={selectStyles}
            isSearchable={false}
            value={getOptionByValue(minuteOptions, minutes)}
            onChange={(selectedOption) => setMinutes(selectedOption.value)}
            maxMenuHeight={100}
            menuPortalTarget={useMenuPortal ? document.body : ''}
            hasError={errorMsg}
          />
        )}
      </div>
      <ErrorMessage visible={errorMsg}>{errorMsg}</ErrorMessage>
    </div>
  )
}

export default TimePicker

const validateDuration = (selectedDuration, minDuration, setErrorMsg, setIsValid, t) => {
  if (!setIsValid) return

  const safeMinDuration = getSafeMinDuration(minDuration)

  if (!minDuration || selectedDuration === 0 || selectedDuration >= safeMinDuration) {
    setErrorMsg('')
    setIsValid(true)
    return
  }

  const errorMsg = t('minimumDurationIs') + ' ' + t('dhmString', ...secondsToDHM(safeMinDuration))
  setErrorMsg(errorMsg)
  setIsValid(false)
}

const getSafeMinDuration = (minDuration) => {
  const TESTDURATION_SAFETY_INCREASE_FACTOR = 1.2
  const TESTDURATION_SAFETY_INCREASE_ABSOLUTE_SECONDS = 600

  const minDurationInSeconds = Math.max(
    minDuration * TESTDURATION_SAFETY_INCREASE_FACTOR,
    minDuration + TESTDURATION_SAFETY_INCREASE_ABSOLUTE_SECONDS
  )

  return Math.floor(minDurationInSeconds / 600) * 600
}

const getFields = (field) => {
  switch (field) {
    case 'invitedDuration':
      return {
        useDays: true,
        useHours: false,
        useMinutes: false
      }
    case 'startedDuration':
      return {
        useDays: false,
        useHours: true,
        useMinutes: true
      }
    default:
      return {
        useDays: true,
        useHours: true,
        useMinutes: true
      }
  }
}

const getDayOptions = (t) => {
  return [...Array(31).keys()].map((option) => ({
    label: option + ' ' + t(getEntityLabel('days', option)),
    value: option
  }))
}

const getHourOptions = (t) => {
  return [...Array(24).keys()].map((option) => ({
    label: option + ' ' + t('hoursShort'),
    value: option
  }))
}

const getMinuteOptions = (t) => {
  return [...Array(12).keys()].map((option) => ({
    label: option * 5 + ' ' + t('minutesShort'),
    value: option * 5
  }))
}
