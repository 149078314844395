import Select from 'react-select'
import useTranslate from '../../../utils/hooks/useTranslate'
import { LabelFilterContainer, formatOptionLabel, isSearchable } from '../filterUtils'
import { getOptionByValue } from '../../../utils/helper/Helper'
import { createFilter } from 'react-select/dist/react-select.cjs.prod'
import { getFilterStyles } from '../filterStyles'
import { getRelationOptions } from './RelationSelector'
import { useContext } from 'react'
import { FilterContext } from '../../../utils/context/FilterContext'

const FieldSelector = ({ field, setField, columns, setValue, setRelation }) => {
  const t = useTranslate()
  const { activeFilters } = useContext(FilterContext)

  const options = getFieldOptions(columns, activeFilters, t, field)
  const useCategories = options.some((option) => option.filterOptions?.category)

  const handleFieldChange = (selectedOption) => {
    setField(selectedOption)
    setValue({ value: null, label: '' })
    const defaultRelationOption = getRelationOptions(selectedOption.filterOptions)[0]
    setRelation(defaultRelationOption)
  }

  return (
    <LabelFilterContainer>
      <Select
        options={useCategories ? groupOptionsByCategory(options, t) : options}
        onChange={handleFieldChange}
        styles={getFilterStyles({ size: 'auto' })}
        value={getOptionByValue(options, field.value)}
        menuPortalTarget={document.body}
        filterOption={createFilter({ ignoreAccents: false })}
        placeholder={t('field')}
        classNamePrefix="react-select"
        className="react-select"
        blurInputOnSelect
        noOptionsMessage={() => t('noResults')}
        formatOptionLabel={formatOptionLabel}
        isSearchable={isSearchable(options)}
      />
    </LabelFilterContainer>
  )
}
export default FieldSelector

const groupOptionsByCategory = (options, t, defaultCategory = '') => {
  const grouped = options.reduce((acc, option) => {
    const cat = option.filterOptions.category || defaultCategory
    acc[cat] = acc[cat] || []
    acc[cat].push(option)
    return acc
  }, {})

  return Object.keys(grouped).map((category) => ({
    label: t(category),
    options: grouped[category]
  }))
}

const getFieldOptions = (columns, activeFilters, t, field) => {
  const deactivatedFields = activeFilters.filter((af) => af.field.value !== field.value).map((af) => af.field.value)

  return columns
    .filter((col) => col?.filterOptions?.showInFieldSelector)
    .map((col) => ({
      value: col.id,
      label: t(col.filterOptions?.label || col.Header),
      filterOptions: col.filterOptions,
      isDisabled: deactivatedFields.includes(col.id)
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}
