import News from './News'
import LegalLinks from './LegalLinks'
import { OuterContainer, InnerContainer, PerseoLogo, MiddleContainer } from './loginAreaElements'
import VersionDisplay from '../../components/versionDisplay/VersionDisplay'
import Routes from '../../components/routes/Routes'
import { useContext, useLayoutEffect } from 'react'
import useCurrentRect from '../../utils/hooks/useCurrentRect'
import { AppContext } from '../../utils/context/AppContext'

const LoginArea = () => {
  const height = useCurrentRect().height
  const context = useContext(AppContext)

  useLayoutEffect(() => {
    const Y_OFFSET = 50
    const loginContainer = document.getElementById('login-container')
    const legalFooter = document.getElementById('legal-footer')

    const loginContainerRect = loginContainer.getBoundingClientRect()
    const legalFooterRect = legalFooter.getBoundingClientRect()

    if (legalFooterRect.top - Y_OFFSET < loginContainerRect.bottom) {
      legalFooter.classList.add('has-overlap')
    }
  }, [height])

  return (
    <>
      <OuterContainer className="logged-out-area">
        <MiddleContainer>
          <PerseoLogo />
          <News text={context.maintenanceModeText} />
          <InnerContainer id="login-container">
            <Routes />
          </InnerContainer>
          <VersionDisplay />
          <LegalLinks />
        </MiddleContainer>
      </OuterContainer>
    </>
  )
}

export default LoginArea
