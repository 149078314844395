import { SmallDescriptionContent } from '../../pages/participants/participantUtils'
import { PAGES } from '../../utils/constants/pages'
import { getTableLinkParams } from '../../utils/helper/Helper'
import Badge from '../badge/Badge'
import { SmallDescription, TableLink } from '../table/tableElements'
import styled from 'styled-components'
import { useContext } from 'react'
import { FilterContext } from '../../utils/context/FilterContext'
import useRedirect from '../../utils/hooks/useRedirect'

const PtListForTable = ({ ptList }) => (
  <PtListContainer>
    {ptList.map((pt) => (
      <PtListEntry pt={pt} key={pt.ptNumber} />
    ))}
  </PtListContainer>
)

export default PtListForTable

const PtListEntry = ({ pt }) => {
  const redirect = useRedirect()
  const assessment = pt.relatedAssessment
  const ptisArchived = pt.isArchived()
  const assessmentName = assessment.assessmentName

  const { addLinkFilters } = useContext(FilterContext)
  const params = getTableLinkParams(pt, 'ptNumber', 'pts')

  return (
    <PtListEntryElement className={ptisArchived ? 'archived' : ''}>
      <Badge status={pt.ptStatus} />
      <TableLink
        className={params.className}
        onClick={() => {
          addLinkFilters(params.filterParams)
          redirect(PAGES.pts)
        }}>
        {assessmentName}
      </TableLink>
      <SmallDescription style={{ gridRow: 2, gridColumn: 2, minWidth: '90px' }}>
        <SmallDescriptionContent pt={pt} />
      </SmallDescription>
    </PtListEntryElement>
  )
}

const PtListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: var(--space-3);
`

const PtListEntryElement = styled.li`
  display: inline-grid;
  width: fit-content;
  grid-template-columns: 16px 1fr;
  align-items: center;
`
