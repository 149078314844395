import RowActionButtonList from '../rowActionButtonList/RowActionButtonList'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { getEntries } from '../table/RowActions'
import { TileHeadline, Tile } from '../table/tableElements'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'

const RowActionTile = ({ width, row, entity }) => {
  const t = useTranslate()
  const redirect = useRedirect()
  const context = useContext(AppContext)
  const entries = getEntries(row, entity, context, t, redirect)

  return (
    <>
      {entries.flat().length > 0 && (
        <Tile {...{ width }}>
          <TileHeadline content="actions" />
          <RowActionButtonList {...{ entries }} />
        </Tile>
      )}
    </>
  )
}

export default RowActionTile
