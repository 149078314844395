import PageGroup from '../../components/pageGroup/PageGroup'
import CapabilitiesSelectionPage from './subPages/CapabilitiesSelectionPage'
import ChangeCapabilitiesCheckInput from './subPages/ChangeCapabilitiesCheckInput'
import React, { useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { setStatusBar } from '../../utils/helper/Helper'
import { AppContext } from '../../utils/context/AppContext'
import { PAGES } from '../../utils/constants/pages'
import { changeCapabilities, getNullCapabilities, getProcessUuids } from './changeCapabilitiesUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'

const ChangeCapabilitiesWizard = () => {
  const t = useTranslate()
  const location = useLocation()
  const context = useContext(AppContext)
  const redirect = useRedirect()

  const completeDataSet = context.completeDataSet
  const processes = completeDataSet.processes
  const processUuids = getProcessUuids(location, redirect)
  const selectedProcesses = processes.filter((process) => processUuids.includes(process.processUuid))
  const nullCapabilities = getNullCapabilities(completeDataSet.contacts)

  const [activePageId, setActivePageId] = useState(0)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [data, setData] = useState({
    processUuids: processUuids,
    contacts: nullCapabilities
  })

  const handleCapabilitiesChange = () => {
    setSubmitButtonDisabled(true)
    changeCapabilities(data, context).then(() => {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: 'permissionsChangedSuccessfully'
      })
      redirect(PAGES.processes)
    })
  }

  const pages = {
    capabilitiesSelectionPage: {
      content: <CapabilitiesSelectionPage data={data} setData={setData} />,
      buttonSecondary: <ButtonSecondary content="cancel" onClick={() => redirect(PAGES.processes)} />,
      title: t('capabilities'),
      buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />,
      largeButtons: true
    },
    changeCapabilitiesCheckInput: {
      content: <ChangeCapabilitiesCheckInput data={data} selectedProcesses={selectedProcesses} />,
      title: t('checkInputs'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          disabled={submitButtonDisabled}
          content="changeCapabilities"
          onClick={() => handleCapabilitiesChange()}
        />
      ),
      largeButtons: true
    }
  }

  return (
    <PageGroup
      pages={[pages.capabilitiesSelectionPage, pages.changeCapabilitiesCheckInput]}
      activePageId={activePageId}
    />
  )
}

export default ChangeCapabilitiesWizard
