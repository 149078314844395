import styled from 'styled-components'

export const TimeLineContainer = styled.ul`
  width: 100%;
  transform: translateY(5px);
  margin-bottom: 0;
  padding: 0;
  align-items: center;
`

export const Entry = styled.li.attrs({
  className: 'time-line-entry'
})`
  list-style-type: none;
  border-left: 1px solid var(--timeline-border-color);
  &:last-child {
    border-color: transparent;
  }
  padding-left: var(--space-6);
  padding-bottom: var(--space-7);
  margin-left: var(--space-3);
  position: relative;
  &:last-child {
    padding-bottom: 0;
  }
  &:before {
    position: absolute;
    background-color: var(--timeline-circle-color);
    width: 12px;
    height: 12px;
    content: '';
    left: -7px;
    border-radius: var(--bdr-round);
    border: 1px solid var(--timeline-border-color);
    top: 0px;
  }
`

export const InnerContainer = styled.ul`
  display: grid;
  transform: translateY(-5px);
  padding: 0;
`
