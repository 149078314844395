import styled from 'styled-components'
import useTranslate from '../../utils/hooks/useTranslate'

export const PasswordVisibilityToggle = ({ passwordVisible, setPasswordVisible }) => {
  const t = useTranslate()
  const togglePasswordVisibility = (e) => {
    e.preventDefault()
    setPasswordVisible(!passwordVisible)
  }

  const icon = passwordVisible ? 'eye-closed' : 'eye-open'
  return (
    <EyeButton aria-label={t('togglePasswordVisibility')} onClick={togglePasswordVisibility}>
      <span className={`svg-icon icon-${icon}`} />
    </EyeButton>
  )
}

const EyeButton = styled.button`
  position: absolute;
  right: var(--space-4);
  padding: 0;
  background-color: transparent;
  border-radius: var(--bdr-1);
  transform: translateY(1px);
`

export default PasswordVisibilityToggle
