import ReactTooltip from 'react-tooltip'
import { InfoIcon, TileHeadlineContainer } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { TileHeadline } from '../../table/tableElements'

const ProcessResultSettingsHeadline = () => {
  const t = useTranslate()
  const tooltipText = <div style={{ width: '200px', lineHeight: 'var(--lh-2)' }}>{t('processResultToolTip')}</div>

  return (
    <TileHeadlineContainer className="headline-container">
      <TileHeadline content="processResultHeadline" />
      <InfoIcon
        data-tip="processResultInfo"
        data-for="processResultInfo"
        className="svg-icon icon-info"
        style={{ position: 'absolute', right: '0', bottom: '20px' }}
      />
      <ReactTooltip
        id="processResultInfo"
        aria-haspopup="true"
        effect="solid"
        place="bottom"
        backgroundColor="var(--c-tooltip-bg)"
        textColor="var(--tooltip-text-color)"
        getContent={() => tooltipText}
      />
    </TileHeadlineContainer>
  )
}

export default ProcessResultSettingsHeadline
