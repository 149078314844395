import { useLayoutEffect } from 'react'

const useAdjustHeadlineHeight = () => {
  useLayoutEffect(() => {
    const headlines = Array.from(document.querySelectorAll('.headline-container'))
    const heights = headlines.map((h) => h.clientHeight)
    const maxHeight = Math.max(...heights)
    headlines.forEach((h) => (h.style.height = `${maxHeight}px`))
  }, [])
}

export default useAdjustHeadlineHeight
