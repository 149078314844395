import { useContext, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { selectStyles } from '../../../utils/elements/miscElements'
import { getEntityLabel, getFormattedNumber } from '../../../utils/helper/Helper'
import { ENTITIES, NUMBER_INPUT_ALLOWED_KEYS } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AppContext } from '../../../utils/context/AppContext'

const CreditCalculator = ({ setCalculatedSum, productsState, totalState }) => {
  const context = useContext(AppContext)
  const lang = context.language
  const t = useTranslate()
  const options = getOptions(PRODUCT_LIST, t)
  const { products, setProducts } = productsState
  const { total, setTotal } = totalState

  useEffect(() => {
    const credits = products.map((product) => product.amount * product.value || 0)
    setTotal(credits.reduce((prev, curr) => prev + curr, 0))
    setCalculatedSum(total)
  }, [total, setCalculatedSum, products, setTotal])

  const handleOptionChange = (selectedOption, index) => {
    const tmp = products
    tmp[index] = selectedOption
    setProducts([...tmp])
  }

  const handleAmountChange = (e, index) => {
    const value = Number(e.target.value)
    if (value > 999) return
    const tmp = products
    const amount = { amount: value }
    tmp[index] = Object.assign(tmp[index], amount)
    setProducts([...tmp])
  }

  const deleteRow = (index) => {
    const tmp = products
    tmp.splice(index, 1)
    setProducts([...tmp])
  }

  const addRow = () => {
    const tmp = products
    tmp.push({})
    setProducts([...tmp])
  }

  const handleKeydown = (e) => {
    if (e.key === ',' || e.key === '.' || !NUMBER_INPUT_ALLOWED_KEYS.includes(e.key)) e.preventDefault()
  }

  return (
    <div className="table-outer-container" style={{ zIndex: 1, border: 'none' }}>
      <div className="table-inner-container" style={{ overflow: 'visible' }}>
        <table className="credit-calculator">
          <thead>
            <tr>
              <th>{t('product')}</th>
              <th>{t('amount')}</th>
              <th style={{ textAlign: 'right' }}>Credits</th>
              <th style={{ width: '100px' }} />
            </tr>
          </thead>
          <tbody>
            {Array.from(new Array(products.length || 1), (el, index) => (
              <tr key={index}>
                <td>
                  <Select
                    options={options}
                    styles={selectStyles}
                    placeholder={t('pleaseSelect') + '…'}
                    value={options.find((option) => option.label === products[index]?.label)}
                    onChange={(selectedOption) => handleOptionChange(selectedOption, index)}
                    noOptionsMessage={() => t('noResults')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    pattern="\d*"
                    style={{ width: '50px', textAlign: 'right' }}
                    onChange={(e) => handleAmountChange(e, index)}
                    onKeyDown={handleKeydown}
                    value={products[index]?.amount || ''}
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <span>{getFormattedNumber(products[index]?.value * products[index]?.amount || 0, lang)}</span>
                </td>
                <td>
                  <TableButtonContainer>
                    <TableButtonDarker
                      isVisible={index > 0}
                      onClick={(e) => {
                        e.preventDefault()
                        deleteRow(index)
                      }}>
                      <span className="svg-icon icon-trash"></span>
                    </TableButtonDarker>
                    {products.length < 10 && (
                      <TableButtonDarker
                        isVisible={index === products.length - 1}
                        onClick={(e) => {
                          e.preventDefault()
                          addRow()
                        }}>
                        <span className="svg-icon icon-plus"></span>
                      </TableButtonDarker>
                    )}
                  </TableButtonContainer>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td>{t('sum')}:</td>
              <td style={{ textAlign: 'right' }}>{getFormattedNumber(total, lang)}</td>
              <td />
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default CreditCalculator

const PRODUCT_LIST = [
  {
    name: 'Potentials Standard',
    credits: 10
  },
  {
    name: 'Potentials QuickScan',
    credits: 7
  },
  {
    name: 'Potentials Crosstest',
    credits: 5
  },
  {
    name: 'Traits Junior',
    credits: 6
  },
  {
    name: 'Traits Professional',
    credits: 9
  },
  {
    name: 'Traits Leadership',
    credits: 12
  },
  {
    name: 'Traits Sales',
    credits: 12
  },
  {
    name: 'Goals',
    credits: 6
  },
  {
    name: 'reportModuleInterviewQuestionsForTraits',
    credits: 1
  },
  {
    name: 'reportModulePotentialsAndRisksForTraits',
    credits: 1
  },
  {
    name: 'reportModuleJobMatching',
    credits: 1
  },
  {
    name: 'feedbackReport',
    credits: 1
  },
  {
    name: 'customDesign',
    credits: 100
  }
]

const getOptions = (products, t) => {
  return products.map((product) => {
    const label = `${t(product.name)} (${product.credits} ${getEntityLabel(ENTITIES.credits, product.credits)})`
    const value = product.credits
    return { label: label, value: value }
  })
}

export const TableButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-2);
  height: 100%;
`

export const TableButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--table-button-bg-color);
  padding: 0;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  border-radius: var(--bdr-2);
  &:hover {
    background-color: ${(props) =>
      props.warning ? 'var(--table-button-bg-color-hover-warning)' : 'var(--table-button-bg-color-hover)'};
  }
`

const TableButtonDarker = styled(TableButton)`
  background-color: var(--table-button-darker-bg-color);
  &:hover {
    background-color: var(--table-button-darker-bg-color-hover);
  }
`
