import { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../utils/context/AppContext'

export const B = styled.span`
  hyphens: none;
  background-color: var(--user-manual-button-bg-color);
  padding: 0 var(--space-2);
  border-radius: var(--bdr-2);
  font-family: 'Courier';
  border: 1px solid var(--user-manual-button-border-color);
  margin: 0 var(--space-1);
  display: ${(props) => props.large && 'inline-block'};
`

export const E = styled.span`
  font-style: italic;
  hyphens: none;
`

export const WizardStep = styled.p`
  margin: 0;
  font-size: var(--fs-3);
  font-weight: 600;
`
export const WizardStepContainer = styled.div`
  transform: translateY(-6px);
  & ul {
    margin: 0;
    padding-left: var(--space-5);
  }
  & li {
    list-style-type: disc;
    &::marker{
      color: var(--text-color-secondary);
    }
  }
`

export const Table = ({ name, header, data }) => (
  <div className={`table-container ${name}`}>
    <div className={`table-inner-container ${name}`}>
      <table>
        <thead>
          <tr>
            {header.map((head) => (
              <th key={name + head}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={name + 'row' + i}>
              {header.map((_, i) => (
                <td className="closed" key={name + 'field' + i}>
                  {row[i]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)

export const Image = ({ src, caption, width, maxWidth }) => {
  const context = useContext(AppContext)
  const displayMode = context.displayMode
  return (
    <div className="user-manual-image" style={{ width: width + 'px', maxWidth: maxWidth + 'px' }}>
      <img src={getImagePath(src, displayMode)} alt={caption} />
      <span className="caption">{caption}</span>
    </div>
  )
}

function getImagePath(filename, mode) {
  if (mode === 'dark') {
    return filename.replace(/\.(?=[^.]*$)/, '-dark.')
  }
  return filename
}
