import { B, Table } from '../userManualElements'

const Chapter0English = () => (
  <>
    <h2 id="chapter-0">0 Quick Guide</h2>
    <p>
      In this quick guide, we will show you how to create your first test, send out access credentials, and retrieve the
      results.
    </p>
    <p>
      Please note that you may not be able to perform all steps if you do not have the necessary permissions (see{' '}
      <em>Limitations</em> column).
    </p>
    <QuickStartTable />
  </>
)

export default Chapter0English

const QuickStartTable = () => {
  const header = ['#', 'Step', 'Description', 'Limitations']
  const data = [
    [
      1,
      'Create Process',
      <span key="QuickStartTable-addProcess">
        Click on <B large>Create</B> in the sidebar, then select <B large>New Process</B>. Give the process a name,
        click on <B large>Next</B>, and then on <B large>Create Process</B>. Now click on <B large>Add Test</B>.
      </span>,
      'Administrator'
    ],
    [
      2,
      'Create Test',
      <span key="QuickStartTable-addTest">
        Choose the appropriate test for your professional group. Navigate through the following pages using{' '}
        <B large>Next</B>, then click on <B large>Create Test</B>. Now click on <B large>Add Participants</B>.
      </span>,
      'Editor'
    ],
    [
      3,
      'Create Participants',
      <span key="QuickStartTable-addParticipant">
        Select the option <em>Create Blank Participants</em>, click on <B>Next</B>, and set the number of participants.
        Turn to the next page and provide your payment information if necessary. Now click on <B large>Add</B>.
      </span>,
      'Editor'
    ],
    [
      4,
      'Download Access Credentials',
      <span key="QuickStartTable-getLink">
        Click on <B large>Access Credentials</B> to download a list of access credentials.
      </span>
    ],
    [5, 'Invite Participants', 'Send a test link to each of your applicants.'],
    [
      6,
      'Retrieve Results',
      <span key="QuickStartTable-downloadResult">
        Test results are available immediately after the test is completed. You can find them in sections such as{' '}
        <em>Participants</em>, <em>Testing</em>, and <em>Dashboard</em>.
      </span>
    ]
  ]
  return <Table name="quick-start" {...{ header, data }} />
}
