import { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import { FilterContext } from '../../utils/context/FilterContext'
import useTranslate from '../../utils/hooks/useTranslate'
import { FilterLabel, Separator } from './filterUtils'
import { TableContext } from '../table/DataTable'

const FreeTextFilter = () => {
  const { updateFreeTextFilter, activeFilters, removeFilter } = useContext(FilterContext)
  const { tableName, gotoPage } = useContext(TableContext)
  const currentFilter = activeFilters.find((af) => af.id === 'freeText')
  const preFillValue = currentFilter?.tableName === tableName ? currentFilter?.value?.value : ''
  const [value, setValue] = useState(preFillValue)
  const t = useTranslate()
  const inputRef = useRef(null)

  const applyFilter = (value) => {
    gotoPage(0)
    updateFreeTextFilter(value, tableName)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    if (value.length === 0) {
      clearFilter()
    }

    setValue(value)
  }

  const clearFilter = () => {
    setValue('')
    removeFilter('freeText')
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!value.trim()) {
        clearFilter()
      } else {
        applyFilter(value.trim())
      }
      inputRef.current.blur()
    }
  }

  return (
    <FreeTextFilterContainer>
      <FilterLabel>
        {t('freeTextFilter')} {currentFilter && `(${t('active')})`}
      </FilterLabel>
      <InputContainer>
        <input ref={inputRef} onKeyDown={handleKeyPress} onChange={handleInputChange} value={value} type="text" />
        {value.length > 0 && (
          <>
            <FilterControlButton style={{ marginRight: 0 }} onClick={() => clearFilter()}>
              <IconContainer>
                <Icon className="svg-icon icon-x-noborder-mask" />
              </IconContainer>
            </FilterControlButton>
            <Separator />
          </>
        )}

        <FilterControlButton onClick={() => applyFilter(value)}>
          <IconContainer>
            <Icon className="svg-icon icon-search" style={{ transform: 'scale(1.3) translateY(1px)' }} />
          </IconContainer>
        </FilterControlButton>
      </InputContainer>
    </FreeTextFilterContainer>
  )
}

export default FreeTextFilter

const FreeTextFilterContainer = styled.div`
  display: grid;
  width: 160px;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-grey);
  background-color: var(--input-bg-color);
  border-radius: var(--bdr-2);
  height: 32px;
  font-size: 13px;
  &:focus-within {
    border-color: var(--color-focus-outline);
  }
  & input {
    border: none;
    background-color: transparent;
    height: 100%;
    width: 70%;
  }
`

const FilterControlButton = styled.button`
  background-color: transparent;
  border-radius: 3px;
  padding: 2px 4px;
  margin: 2px;
`

const Icon = styled.span`
  background-color: var(--small-action-icon-color);
  transition-duration: var(--hover-duration);
  &:hover {
    background-color: var(--small-action-icon-hover-color);
    transition-duration: var(--hover-duration);
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
