import { WizardForm, Label, OptionDescription, PageHeadline, OptionEntry } from '../../../utils/elements/miscElements'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import PayMethodSelection from '../subcomponents/PayMethodSelection'
import BillingOverview from '../subcomponents/BillingOverview'
import useTranslate from '../../../utils/hooks/useTranslate'
import CreditCardForm from '../stripe/CreditCardForm'
import { PAYMENT_METHOD_CREDIT_CARD } from '../../../utils/constants/constants'

const PaymentOptions = ({ orderDataState, billingData, billingDataValid }) => {
  const t = useTranslate()
  const showCreditCardForm = orderDataState.orderData.paymentMethod === PAYMENT_METHOD_CREDIT_CARD

  return (
    <>
      <PageHeadline>{t('paymentMethodAndInvoiceData')}</PageHeadline>
      <ScrollContainer>
        <WizardForm style={{ gridGap: 'var(--space-8)' }}>
          <OptionEntry hasHint>
            <Label fullWidth>{t('paymentMethodLabel')}</Label>
            <PayMethodSelection orderDataState={orderDataState} />
          </OptionEntry>
          {showCreditCardForm && (
            <OptionEntry hasHint>
              <Label fullWidth>{t('creditCardData')}</Label>
              <CreditCardForm />
              <OptionDescription description="enterCreditCardData" />
            </OptionEntry>
          )}
          <OptionEntry hasHint>
            <Label fullWidth>{t('invoiceData')}</Label>
            <BillingOverview
              billingData={billingData}
              orderData={orderDataState.orderData}
              billingDataValid={billingDataValid}
            />
            <OptionDescription description="validBillingAddress" />
          </OptionEntry>
        </WizardForm>
      </ScrollContainer>
    </>
  )
}

export default PaymentOptions
