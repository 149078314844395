import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'
import { ActionButtonContainer, ActionsContainer } from './BulkEditStyles'
import { getBulkActions } from './BulkUtils'
import getActionButtons from './getActionButtons'
import useRedirect from '../../utils/hooks/useRedirect'

const ActionButtons = ({ rowData, entity, toggleAllRowsSelected, visibleColumns }) => {
  const t = useTranslate()
  const redirect = useRedirect()
  const context = useContext(AppContext)
  const actions = getBulkActions(rowData, entity, context.loggedInAsAdmin, context.completeDataSet.contacts)
  const actionButtons = getActionButtons(
    actions,
    redirect,
    rowData,
    context,
    entity,
    toggleAllRowsSelected,
    visibleColumns,
    t
  )
  const showActionButtons = actionButtons.length > 0

  return (
    <>
      {showActionButtons && (
        <ActionsContainer>
          <h3>{t('actions')}</h3>
          <ActionButtonContainer>{actionButtons}</ActionButtonContainer>
        </ActionsContainer>
      )}
    </>
  )
}

export default ActionButtons
