import React from 'react'
import { Link } from 'react-router-dom'
import {
  NavMenuUl,
  NavMenuItem,
  NavLinkLabel,
  NavButton,
  NavMenuAddButton,
  NavMenuInnerContainer,
  CreditDisplay,
  NavBarSpacer,
  MainNav,
  LinkContainer
} from './NavbarElements'
import { PAGES } from '../../utils/constants/pages'
import { getMainNavData } from './navbarUtils'
import NavMenuEntry from './NavMenuEntry'
import ReactTooltip from 'react-tooltip'
import CreditsCounter from '../creditsCounter/CreditsCounter'
import useTranslate from '../../utils/hooks/useTranslate'
import usePageUrl from '../../utils/hooks/usePageUrl'

const mainNavData = getMainNavData()

const Navbar = ({ isLoading }) => {
  const t = useTranslate()
  const getUrlFromPage = usePageUrl()

  return (
    <MainNav className="main-nav">
      <LinkContainer>
        <Link className="svg-icon nav-icon perseo-logo" to={getUrlFromPage(PAGES.dashboard)} />
        <Link className="svg-icon nav-icon perseo-logo-small" to={getUrlFromPage(PAGES.dashboard)} />
      </LinkContainer>
      <NavMenuUl className="nav-menu main">
        <NavMenuItem data-tip={t('create')} data-for="create">
          <ReactTooltip
            id="create"
            offset={{ right: 8 }}
            aria-haspopup="true"
            effect="solid"
            place="right"
            backgroundColor="var(--c-tooltip-bg)"
            textColor="var(--tooltip-text-color)"
            className="navbar-tooltip"
          />
          <NavButton to={getUrlFromPage(PAGES.quickSetup)}>
            <NavMenuAddButton>
              <span style={{ backgroundColor: '#fff' }} className="svg-icon icon-add" />
              <NavLinkLabel color="#fff">{t('create')}</NavLinkLabel>
            </NavMenuAddButton>
          </NavButton>
        </NavMenuItem>
        <NavBarSpacer />
        {mainNavData.map((entry, i) => (
          <NavMenuEntry key={entry.label} entry={entry} index={i} />
        ))}
        {!isLoading && (
          <CreditDisplay>
            <NavMenuInnerContainer style={{ alignItems: 'baseline' }} className="link-container">
              <span className={`svg-icon icon-money-bag-light`} />
              <CreditsCounter position="navbar" />
            </NavMenuInnerContainer>
          </CreditDisplay>
        )}
      </NavMenuUl>
    </MainNav>
  )
}

export default Navbar
