import {
  FIELD_LABELS,
  NOT_SET_TEXT,
  NO_VALID_FROM_SET_TEXT,
  NO_VALID_UNTIL_SET_TEXT
} from '../../utils/constants/constants'
import { InfoElement, InfoList } from '../../utils/elements/miscElements'
import { secondsToDHM } from '../../utils/helper/dateTimeHelper'
import useTranslate from '../../utils/hooks/useTranslate'
import DateDisplay from '../dateDisplay/DateDisplay'
import { Tile, TileHeadline } from '../table/tableElements'

const AddAssessmentTimeSettingsTile = ({ data }) => {
  const t = useTranslate()
  return (
    <Tile width="200px">
      <TileHeadline content="testDurationAndTiming" />
      <InfoList>
        <InfoElement
          label={FIELD_LABELS.validFrom}
          value={
            data.validFrom ? (
              <DateDisplay date={data.validFrom} format="dateAndTimeString" />
            ) : (
              t(NO_VALID_FROM_SET_TEXT)
            )
          }
        />
        <InfoElement
          label={FIELD_LABELS.validUntil}
          value={
            data.validUntil ? (
              <DateDisplay date={data.validUntil} format="dateAndTimeString" />
            ) : (
              t(NO_VALID_UNTIL_SET_TEXT)
            )
          }
        />
        <InfoElement
          label={FIELD_LABELS.invitedDuration}
          value={data.invitedDuration ? t('dhmString', ...secondsToDHM(data.invitedDuration)) : t(NOT_SET_TEXT)}
        />
        <InfoElement
          label={FIELD_LABELS.startedDuration}
          value={data.startedDuration ? t('dhmString', ...secondsToDHM(data.startedDuration)) : t(NOT_SET_TEXT)}
        />
      </InfoList>
    </Tile>
  )
}

export default AddAssessmentTimeSettingsTile
