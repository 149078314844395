import { useContext } from 'react'
import { DEFAULT_LANGUAGE } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { SmallDescription } from '../table/tableElements'

const DateDisplay = ({ date, format }) => {
  const context = useContext(AppContext)
  const locale = context.language || DEFAULT_LANGUAGE
  format = format || 'ddmmyyyy'

  if (!date) {
    return <></>
  }

  const formattedDate = getFormattedDate(date, locale, format)
  const formattedTime = getFormattedTime(date, locale)

  const dateAndTime = (
    <>
      <div>{formattedDate}</div>
      <SmallDescription>{formattedTime}</SmallDescription>
    </>
  )

  switch (format) {
    case 'dateAndTime':
      return dateAndTime
    case 'dateAndTimeString':
      return formattedDate + ', ' + formattedTime
    case 'dd_month_yyyy':
    case 'ddmmyyyy':
    default:
      return formattedDate
  }
}

export default DateDisplay

const getFormattedDate = (date, locale, format) => {
  const dateFormats = {
    dd_month_yyyy: { day: 'numeric', month: 'short', year: 'numeric' },
    ddmmyyyy: { day: '2-digit', month: '2-digit', year: 'numeric' },
    dateAndTime: { day: '2-digit', month: '2-digit', year: '2-digit' },
    dateAndTimeString: { day: '2-digit', month: '2-digit', year: 'numeric' }
  }

  return date.toLocaleDateString(locale, dateFormats[format])
}

const getFormattedTime = (date, locale) => {
  const timeOptions = {
    de: { hour: '2-digit', minute: '2-digit' },
    en: { hour: '2-digit', minute: '2-digit', hour12: true }
  }

  const timeAppendix = locale === 'de' ? 'Uhr' : ''
  return date.toLocaleTimeString(locale, timeOptions[locale]) + ' ' + timeAppendix
}
