import React, { useEffect } from 'react'
import { CustomTableCheckbox } from './tableElements'

const IndeterminateCheckbox = React.forwardRef(
  ({ row, toggleAllRowsSelected, isToggleAllCheckbox, indeterminate, type, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    const className = getClassName(rest, indeterminate, type)
    rest.title = ''

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const handleKeyPress = (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        row?.toggleRowSelected()
        if (isToggleAllCheckbox) {
          toggleAllRowsSelected()
        }
      }
    }

    return (
      <label
        className="table-checkbox-label no-touch"
        htmlFor={`row-${row?.index}`}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <CustomTableCheckbox
          className={'custom-checkbox ' + className}
          checked={rest.checked}
          ref={resolvedRef}
          indeterminate={indeterminate}
          type={type}
          {...rest}
        />
        <input
          id={`row-${row?.index}`}
          style={{ margin: 0 }}
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          className="hidden-checkbox"
        />
      </label>
    )
  }
)

export default IndeterminateCheckbox

const getClassName = (rest, indeterminate, type) => {
  if (type === 'radio' && rest.checked) return 'svg-icon icon-circle'
  if (indeterminate) return 'svg-icon icon-minus'
  return rest.checked ? 'svg-icon icon-check-noborder' : ''
}
