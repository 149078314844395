import { API_DATA, STATUS_BAR_TYPES, USER_CAPABILITIES } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import {
  fetchData,
  getLoggedInContact,
  mailIsUnique,
  setState,
  setStatusBar,
  updateEntities
} from '../../utils/helper/Helper'

export const prepareTableData = (rowData, data, setData) => {
  rowData
    .filter((contact) => contact.isVisible())
    .forEach((row) => {
      const newCapabilitiesForRow = data.contactCapabilities.find(
        (entry) => entry.processUuid === row.processUuid
      ).capabilities

      row.newCapabilities = newCapabilitiesForRow
      row.setData = setData
      row.stateData = data
      row.entity = 'contactCapabilities'
      row.key = 'processUuid'
      row.selectDisabled = data.contactIsAdmin
    })

  return rowData
}

export const addUser = async (data, context, redirect) => {
  const loggedInContact = getLoggedInContact(context.completeDataSet)

  const payload = {
    contactFirstName: data.contactFirstName.trim(),
    contactLastName: data.contactLastName.trim(),
    contactGender: data.contactGender || null,
    contactEmail: data.contactEmail.trim(),
    contactTitle: data.contactTitle,
    contactIsAdmin: data.contactIsAdmin,
    contactCapabilities: data.contactCapabilities,
    addedByFirstName: loggedInContact.contactFirstName,
    addedByLastName: loggedInContact.contactLastName,
    addedByMail: loggedInContact.contactEmail
  }

  const responseData = await fetchData(payload, API_DATA.contacts.endPointAdd, context, 'userAdditionFailed')

  try {
    const updates = {
      contacts: [responseData.response.data.contact],
      contactProcesses: responseData.response.data.contactProcesses
    }
    updateEntities(updates, context)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'userInvitedByEmail',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  } finally {
    redirect(PAGES.users)
  }
}

export const getPageArray = (data, pages) => {
  if (data.contactIsAdmin) return [pages.userData, pages.checkInput]
  return [pages.userData, pages.setCapabilities, pages.checkInput]
}

export const validateInputForm = (data) => {
  return mailIsUnique(data.contactEmail, false)
}

export const getInitialCapabilities = (processes) => {
  return processes.map((process) => ({
    processUuid: process.processUuid,
    capabilities: USER_CAPABILITIES.none
  }))
}

export const setCapabilitiesToEdit = (processes, setData) => {
  const newCapabilities = processes.map((process) => ({
    processUuid: process.processUuid,
    capabilities: USER_CAPABILITIES.edit
  }))
  setState(setData, 'contactCapabilities', newCapabilities)
}

export const getProcessCol = () => [
  {
    Header: 'processes',
    accessor: 'processName',
    Cell: ({ row }) => (
      <>
        <div>{row.original.processName}</div>
        {row.original.processDescription && (
          <div style={{ fontSize: 'var(--fs-1)', marginTop: 'var(--space-2)', maxWidth: '200px' }}>
            {row.original.processDescription}
          </div>
        )}
      </>
    )
  }
]
