import React from 'react'
import ReactDOM from 'react-dom'
import './utils/css/vars.scss'
import './utils/css/index.scss'
import './utils/css/icons.scss'
import './utils/css/typo.scss'
import './fonts/fonts.css'
import App from './components/app/App'
import { ContextWrapper } from './utils/context/AppContext'
import { BrowserRouter as Router } from 'react-router-dom'
import Config from './utils/constants/config'
import { disableConsoleLog } from './utils/helper/Helper'

if (!Config.showLog) {
  disableConsoleLog()
}

ReactDOM.render(
  <ContextWrapper>
    <Router>
      <App />
    </Router>
  </ContextWrapper>,
  document.getElementById('root')
)
