import ReactJoyride, { ACTIONS } from 'react-joyride'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { CloseButtonContainer, SmallHeadline, CenteredFlexDiv } from '../modal/modalStyles'
import styled from 'styled-components'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { MODAL_TYPES } from '../../utils/constants/constants'
import useTranslate from '../../utils/hooks/useTranslate'

const OnboardingTour = () => {
  const context = useContext(AppContext)
  const t = useTranslate()

  const handleJoyrideCallback = (data) => {
    const { action } = data
    if ([ACTIONS.CLOSE, ACTIONS.RESET].includes(action)) {
      context.setOnboardingTourVisible(false)
    }
  }

  const steps = [
    {
      target: '.add-button',
      title: t('create'),
      icon: 'icon-add',
      content: t('addButtonExplanation'),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '.dashboard .link-container',
      title: 'Dashboard',
      icon: 'icon-dashboard',
      content: t('dashboardExplanation'),
      placement: 'right'
    },
    {
      target: '.processes .link-container',
      title: t('processes'),
      icon: 'icon-folder',
      content: t('processesExplanation'),
      placement: 'right'
    },
    {
      target: '.tests .link-container',
      title: 'Tests',
      icon: 'icon-tests',
      content: t('testsExplanation'),
      placement: 'right'
    },
    {
      target: '.participants .link-container',
      title: t('participants'),
      icon: 'icon-participants-light',
      content: t('participantsExplanation'),
      placement: 'right'
    },
    {
      target: '.pts .link-container',
      title: t('pts'),
      icon: 'icon-pt',
      content: t('ptsExplanation'),
      placement: 'right'
    },
    {
      target: '.shop .link-container',
      title: 'Credit Shop',
      icon: 'icon-shop',
      content: t('shopExplanation'),
      placement: 'right'
    },
    {
      target: '#mainMenu',
      title: t('menu'),
      icon: 'icon-menu',
      content: t('mainMenuExplanation'),
      placement: 'bottom'
    },
    {
      target: 'tbody tr:first-child .custom-checkbox',
      title: t('bulkEdit'),
      icon: 'icon-check-noborder-rounded',
      content: t('bulkEditExplanation'),
      placement: 'auto'
    },
    {
      target: 'tbody tr:first-child .expander-container',
      title: t('detailedView'),
      icon: 'icon-small-arrow',
      content: t('detailedViewExplanation'),
      placement: 'auto'
    },
    {
      target: 'tbody tr:first-child .row-actions-toggle-button',
      title: t('actionMenu'),
      icon: 'icon-ellipsis-onboarding',
      content: t('actionMenuExplanation'),
      placement: 'auto'
    }
  ]

  return (
    <ReactJoyride
      steps={steps}
      locale={{ back: t('back'), close: t('close'), last: t('letsGo'), next: t('next') }}
      tooltipComponent={Tooltip}
      floaterProps={{
        options: {
          computeStyle: {
            gpuAcceleration: false
          }
        }
      }}
      beaconComponent={HiddenBeacon}
      run={context.onboardingTourVisible}
      callback={handleJoyrideCallback}
      continuous
      disableScrolling
      disableScrollParentFix
    />
  )
}

export default OnboardingTour

const Tooltip = ({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) => {
  const t = useTranslate()
  primaryProps.title = ''
  backProps.title = ''
  closeProps.title = ''
  return (
    <div
      {...tooltipProps}
      className="notification-modal"
      style={{ backgroundColor: '#fff', padding: 'var(--space-6)', borderRadius: 'var(--bdr-4)', position: 'relative' }}
    >
      <TooltipHeader>
        <SmallHeadline>{t('introduction')}</SmallHeadline>
        <CloseButtonContainer type={MODAL_TYPES.notification} content={closeProps['aria-label']} {...closeProps}>
          <CenteredFlexDiv>
            <span
              style={{ backgroundColor: 'var(--text-color-secondary)' }}
              className="svg-icon icon-x-noborder-mask"
            />
          </CenteredFlexDiv>
        </CloseButtonContainer>
      </TooltipHeader>
      <TooltipBody className="notification onboarding">
        {step.icon && (
          <p style={{ marginBottom: 0 }}>
            <span className={`svg-icon ${step.icon}`} />
          </p>
        )}
        {step.title && <h3>{t(step.title)}</h3>}
        <p>{step.content}</p>
      </TooltipBody>
      <TooltipFooter>
        {index > 0 && <ButtonSecondary content={backProps['aria-label']} {...backProps} />}
        {continuous && (
          <ButtonPrimary style={{ gridColumn: 2 }} content={primaryProps['aria-label']} {...primaryProps} />
        )}
      </TooltipFooter>
    </div>
  )
}

const TooltipBody = styled.div`
  height: auto !important;
`

const TooltipHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TooltipFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-3);
  margin-top: var(--space-5);
`

const HiddenBeacon = styled.div`
  display: none;
`
