import PageIndicator from './PageIndicator'
import { ButtonContainer } from '../../utils/elements/miscElements'
import { PageGroupContentContainer, PageGroupFooter, PageGroupOuterContainer } from './pageGroupElements'
import { useEffect } from 'react'

const PageGroup = ({ pages, activePageId, isInNotification, isInSettings }) => {
  const activePage = pages[activePageId]

  useEffect(() => {
    setTimeout(() => {
      const headline = document.querySelector('h2')
      if (headline) {
        headline.tabIndex = -1
        headline.focus()
      }
    }, 50)
  }, [activePageId])

  return (
    <PageGroupOuterContainer className="page-group-outer-container" {...{ isInNotification, isInSettings }}>
      <PageGroupContentContainer
        className="page-group"
        isInNotification={isInNotification}
        margin={isInNotification ? 0 : activePage.margin}>
        {activePage?.content}
        {!isInNotification && (
          <PageGroupFooter className="page-group-footer" largeButtons={activePage.largeButtons}>
            <PageIndicator {...{ pages, activePageId }} />
            <ButtonContainer>
              {activePage?.buttonSecondary}
              {activePage?.buttonPrimary}
            </ButtonContainer>
          </PageGroupFooter>
        )}
      </PageGroupContentContainer>
      {isInNotification && pages.length > 1 && <PageIndicator {...{ pages, activePageId, isInNotification }} />}
    </PageGroupOuterContainer>
  )
}

export default PageGroup
