import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { PAGES } from '../../utils/constants/pages'
import usePageUrl from '../../utils/hooks/usePageUrl'
import ErrorFallback from '../errorFallback/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'

const Routes = () => {
  const context = useContext(AppContext)
  const isLoggedIn = context.token
  const isAdmin = context.loggedInAsAdmin
  const visiblePages = getVisiblePages(isAdmin, isLoggedIn)
  const backupPage = getBackupPage(isLoggedIn)
  const getUrlFromPage = usePageUrl()

  return (
    <Switch>
      {visiblePages.map((page) => (
        <Route key={page[0]} exact path={getUrlFromPage(page[1])}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>{page[1].component}</ErrorBoundary>
        </Route>
      ))}

      <Route path="*">
        <Redirect to={getUrlFromPage(backupPage)} />
      </Route>
    </Switch>
  )
}
export default Routes

const getVisiblePages = (isAdmin, isLoggedIn) => {
  const pagesArray = Object.entries(PAGES)

  if (isLoggedIn) {
    return isAdmin
      ? pagesArray.filter((page) => !page[1].isPublic)
      : pagesArray.filter((page) => !page[1].isPublic && !page[1].adminOnly)
  } else {
    return pagesArray.filter((page) => page[1].isPublic)
  }
}

const getBackupPage = (isLoggedIn) => (isLoggedIn ? PAGES.dashboard : PAGES.login)
