import { createFilter } from 'react-select/dist/react-select.cjs.prod'
import { getOptionByValue } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getFilterStyles } from '../filterStyles'
import { LabelFilterContainer, formatOptionLabel } from '../filterUtils'
import Select from 'react-select'
import { CONTENT_TYPES } from '../../../utils/constants/constants'

const RelationSelector = ({ relation, setRelation, filterOptions }) => {
  const t = useTranslate()
  const options = getRelationOptions(filterOptions)

  return (
    <LabelFilterContainer>
      <Select
        options={t(options)}
        onChange={(selectedOption) => setRelation(selectedOption)}
        styles={getFilterStyles({ size: 'auto' })}
        value={getOptionByValue(t(options), relation.value)}
        menuPortalTarget={document.body}
        filterOption={createFilter({ ignoreAccents: false })}
        placeholder={t('relation')}
        classNamePrefix="react-select"
        className="react-select"
        blurInputOnSelect
        noOptionsMessage={() => t('noResults')}
        formatOptionLabel={formatOptionLabel}
      />
    </LabelFilterContainer>
  )
}

export default RelationSelector

export const getRelationOptions = (filterOptions) => {
  switch (filterOptions?.contentType) {
    case CONTENT_TYPES.date:
      return [...DATE_RELATION_OPTIONS, ...EMPTY_RELATION_OPTIONS]
    case CONTENT_TYPES.number:
      return [...EXACT_RELATION_OPTIONS, ...NUMERIC_RELATION_OPTIONS, ...EMPTY_RELATION_OPTIONS]
    default:
      return [...EXACT_RELATION_OPTIONS, ...CONTAINS_RELATION_OPTIONS, ...EMPTY_RELATION_OPTIONS]
  }
}

const EXACT_RELATION_OPTIONS = [
  {
    value: 'exact',
    label: 'is'
  },
  {
    value: 'exactNot',
    label: 'isNot',
    hasDivider: true
  }
]

const CONTAINS_RELATION_OPTIONS = [
  {
    value: 'like',
    label: 'contains'
  },
  {
    value: 'notLike',
    label: 'containsNot',
    hasDivider: true
  }
]

const NUMERIC_RELATION_OPTIONS = [
  {
    value: 'lt',
    label: 'lessThan'
  },
  {
    value: 'lte',
    label: 'lessThanOrEqualTo'
  },
  {
    value: 'betweenNumbers',
    label: 'isBetween'
  },

  {
    value: 'gte',
    label: 'greaterThanOrEqualTo'
  },
  {
    value: 'gt',
    label: 'greaterThan',
    hasDivider: true
  }
]

const DATE_RELATION_OPTIONS = [
  {
    value: 'isInDateRange',
    label: 'is'
  },
  {
    value: 'isNotInDateRange',
    label: 'isNot',
    hasDivider: true
  },
  {
    value: 'before',
    label: 'isBefore'
  },
  {
    value: 'betweenDates',
    label: 'isBetween'
  },
  {
    value: 'laterThan',
    label: 'isLaterThan',
    hasDivider: true
  }
]

export const EMPTY_RELATION_OPTIONS = [
  {
    value: 'empty',
    label: 'isEmpty'
  },
  {
    value: 'notEmpty',
    label: 'isNotEmpty'
  }
]
