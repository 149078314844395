import React from 'react'
import { TileContainer } from '../table/tableElements'
import { isPotentials } from '../../utils/helper/Helper'
import ParticipationStatsTile from '../tiles/ParticipationStatsTile'
import ResultsTile from '../tiles/ResultsTile'
import { getTestStats } from '../../pages/assessments/assessmentUtils'

const AssessmentDashboardTab = ({ assessment }) => {
  const type = assessment.relatedConfig.configType
  const showResultsTile = isPotentials(type) || assessment.reportModules.matching === 1
  const stats = getTestStats(assessment.relatedPts)

  return (
    <TileContainer>
      <ParticipationStatsTile {...{ assessment, stats }} />
      {showResultsTile && <ResultsTile {...{ assessment, stats }} />}
    </TileContainer>
  )
}

export default AssessmentDashboardTab
