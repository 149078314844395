import { Modalfooter, ButtonContainer, ButtonSecondary } from '../../utils/elements/miscElements'
import { useCallback, useContext } from 'react'
import {
  ModalBackgroundColor,
  ModalOuterContainer,
  ModalContainer,
  ModalIcon,
  CloseButton,
  AlertModalContent,
  SmallHeadline,
  HeaderContainer
} from './modalStyles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import { AppContext } from '../../utils/context/AppContext'
import useEventListener from '../../utils/hooks/useEventListener'
import { MODAL_TYPES } from '../../utils/constants/constants'
import { getModalContainerClassName } from './modalUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import useTrapFocus from '../../utils/hooks/useTrapFocus'

const Modal = () => {
  const context = useContext(AppContext)
  const setModalOpen = context.setModalOpen
  const modalOpen = context.modalOpen
  const modalProps = context.modalProps
  const modalContainerClassName = getModalContainerClassName(modalProps)

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        event.preventDefault()
        setModalOpen(false)
      }
    },
    [setModalOpen]
  )

  if (!modalOpen) {
    document.body.classList.remove('focus-trapped')
  }

  useEventListener('keydown', handleKeyDown)

  return (
    <TransitionGroup>
      {modalOpen && (
        <CSSTransition key="background" in={modalOpen} timeout={300} classNames="slow-fade" unmountOnExit>
          <ModalBackgroundColor />
        </CSSTransition>
      )}
      {modalOpen && (
        <CSSTransition key="modal" in={modalOpen} timeout={300} classNames="pop" unmountOnExit>
          <ModalOuterContainer type={modalProps.type} className="modal">
            <ModalContainer
              type={modalProps.type}
              isWelcomeNotification={modalProps.isWelcomeNotification}
              fontSize={modalProps.fontSize}
              className={modalContainerClassName}>
              <Header props={modalProps} setModalOpen={setModalOpen} />
              <Content props={modalProps} />
              <Footer props={modalProps} setModalOpen={setModalOpen} />
            </ModalContainer>
          </ModalOuterContainer>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

export default Modal

const Content = ({ props }) => {
  const t = useTranslate()
  const fadingEdgeBottomOffset = props.hideFooter ? '20px' : '84px'
  const marginBottom = 0
  const content = t(props.content)
  const contentStyle = {
    fontSize: props?.type === MODAL_TYPES.alert ? '14px' : '13px',
    lineHeight: 'var(--lh-3)',
    paddingTop: 'var(--space-4)'
  }

  switch (props.type) {
    case MODAL_TYPES.alert:
      return <AlertModalContent>{content}</AlertModalContent>
    case MODAL_TYPES.notification:
    case MODAL_TYPES.deleteAccount:
      return <div>{content}</div>
    default:
      return (
        <ScrollContainer
          style={contentStyle}
          fadingEdgeBottomOffset={fadingEdgeBottomOffset}
          marginBottom={marginBottom}
          type="modal">
          {content}
        </ScrollContainer>
      )
  }
}

const getButtonSecondary = (modalProps, setModalOpen) => {
  if (modalProps.buttonSecondary) return modalProps.buttonSecondary
  if (modalProps.hideCancelButton) return <span></span>
  return <ButtonSecondary content="cancel" modalButton onClick={() => setModalOpen(false)} />
}

const Header = ({ props, setModalOpen }) => {
  const t = useTranslate()
  useTrapFocus()

  return (
    <HeaderContainer type={props.type}>
      {props.smallHeadline && <SmallHeadline>{props.smallHeadline}</SmallHeadline>}
      {props.icon && <ModalIcon className={`svg-icon ${props.icon}`} />}
      {props.headline && <h2>{t(props.headline)}</h2>}
      {props.showCloseButton && <CloseButton setModalOpen={setModalOpen} type={props.type} />}
    </HeaderContainer>
  )
}

const Footer = ({ props, setModalOpen }) => {
  const buttonSecondary = getButtonSecondary(props, setModalOpen)

  return (
    <>
      {!props.hideFooter && (
        <Modalfooter style={{ paddingTop: 'var(--space-7)', paddingBottom: 0 }}>
          <ButtonContainer type={props.type}>
            {buttonSecondary}
            {props.buttonPrimary}
          </ButtonContainer>
        </Modalfooter>
      )}
    </>
  )
}
