import React from 'react'
import { TileContainer } from '../table/tableElements'
import MatchingTile from '../tiles/dimLogicTile/MatchingTile'
import NormsTile from '../tiles/NormsTile'
import PotentialsWeightTile from '../tiles/dimLogicTile/PotentialsWeightTile'

const AssessmentScoringTab = ({ assessment }) => {
  return (
    <TileContainer style={{ maxWidth: '950px' }}>
      {assessment.hasMatching() ? (
        <MatchingTile assessment={assessment} />
      ) : (
        <PotentialsWeightTile assessment={assessment} />
      )}
      <NormsTile assessment={assessment} />
    </TileContainer>
  )
}
export default AssessmentScoringTab
